import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { InventoryService } from "app/apiServices/inventory.service";
import { SalesService } from "app/apiServices/sales.service";
import { NotificationsComponent } from "app/components/alerts/notifications/notifications.component";
import { FormValidatorService } from "app/core/service/form-validator.service";
import { InTankRes } from "app/interfaces/tank.interface";
import { Purchase } from "app/models/purchase";
import {
  PurchaseLabOrder,
  PurchaseLabOrderDetail,
} from "app/models/purchase-lab-order";
import { GetTank } from "app/models/tank";
import { DataService } from "app/services/data.service";

@Component({
  selector: "app-unloading-order",
  templateUrl: "./unloading-order.component.html",
  styleUrls: ["./unloading-order.component.css"],
})
export class UnloadingOrderComponent {
  form: FormGroup;
  purchaseLabOrder: PurchaseLabOrder;
  purchase: Purchase;
  tanks: GetTank[] = [];
  tanksPipe: GetTank[] = [];
  editPushedProducts = [];
  selectedTanks: PurchaseLabOrderDetail[] = [];
  suppliedQuantity: number;
  suppliedPercentage: number;
  missingQuantity: boolean = true;
  currentSelectedPipe: string = "pipe_one";
  isNotMixedProduct: boolean = false;
  missingPercentage: boolean = true;
  edit: boolean = false;
  totalQuantity: number = 0.0;
  totalPercentage: number = 0.0;
  canEdit: boolean = true;
  ColumnMode = ColumnMode;
  authorizedUser: boolean = false;
  currentTankQuantities: Map<number, number> = new Map();
  tmpTankQuantities: Array<any> = [];

  @ViewChild("tankId", { static: true })
  tankSelect: ElementRef;

  selectedTank: GetTank;
  selectOptions = {
    selectFilter: true,
    selectNoResultText: "No se encontraron resultados",
    selectSearchPlaceholder: "Buscar...",
  };

  selectCommonClasses = {
    optionsList: "list-none m-0 p-0 w-fit",
    selectInput:
      "peer block min-h-[auto] w-full rounded border-0 bg-transparent outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-gray-200 dark:placeholder:text-gray-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0 cursor-pointer data-[te-input-disabled]:bg-[#e9ecef] data-[te-input-disabled]:cursor-default group-data-[te-was-validated]/validation:mb-4 dark:data-[te-input-disabled]:bg-zinc-600 pr-8",
  };

  constructor(
    private dialogRef: MatDialogRef<UnloadingOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public formService: FormValidatorService,
    private inventoryService: InventoryService,
    private salesService: SalesService,
    private dataService: DataService,
    private _snack: MatSnackBar
  ) {
    if (data.purchaseLabOrder) {
      this.purchaseLabOrder = data.purchaseLabOrder;
    }

    if (data.purchase) {
      this.purchase = data.purchase;
      //this.isNotMixedProduct = this.purchase.product.full_mix;
      //this.suppliedQuantity = this.edit ? 0.0 : this.purchase.sold_liters;
      this.missingPercentage = !this.isNotMixedProduct;
      this.edit = data.edit;
      //this.suppliedQuantity = this.edit ? 0.0 : this.purchase.sold_liters;
      this.missingQuantity = !this.edit;
      this.suppliedPercentage = !this.edit ? 0.0 : 100.0;
      this.authorizedUser = this.isAuthorizedUser();
      this.canEdit =
        (!this.purchaseLabOrder?.approved &&
          this.purchase?.purchase_tank_readings?.length == 0) ||
        (this.purchaseLabOrder?.approved &&
          this.authorizedUser &&
          this.purchase?.purchase_tank_readings?.length == 0);
      this.form = this.buildForm();
      this.getTanks();
    }
  }

  buildForm(): FormGroup {
    return this.fb.group({
      approved: [this.edit ? this.purchaseLabOrder?.approved : undefined],
      observations: [
        this.edit ? this.purchaseLabOrder?.observations : undefined,
      ],
    });
  }

  getTanks(): void {
    this.inventoryService.getTanks().subscribe({
      next: (res: InTankRes) => {
        if (res.status === "Éxito") {
          this.tanks = res.data;

          if (this.edit) {
            const pushed = [];
            const details = this.purchaseLabOrder?.purchase_lab_order_details;

            details.forEach((detail) => {
              if (!pushed.includes(detail.product.id)) {
                pushed.push(detail.product.id);
              } else this.editPushedProducts.push(detail.id);
            });
            this.selectedTanks = details.map((detail) => {
              const quantity = this.purchase.purchase_order_details.find(
                (po) => po.product.id === detail.product.id
              )?.quantity;
              return {
                id: detail?.id,
                product_id: detail?.product.id,
                product: detail?.product,
                quantity,
                required_quantity: detail?.quantity,
                percentage: 100,
                tank_id: detail?.tank?.id,
                tank: detail?.tank,
              };
            });

            this.tanksPipe = this.selectedTanks.map((labDetail) => {
              return {
                id: labDetail?.tank?.id,
                tank: labDetail?.tank?.tank,
                capacity: labDetail?.tank?.capacity,
                quantity: labDetail?.quantity,
                active: labDetail?.tank?.active,
                products: [labDetail?.product],
                percentage: labDetail?.percentage,
                required_quantity: labDetail?.required_quantity ?? 0,
                purchase_detail_product_id: labDetail?.product?.id,
              };
            });

            this.selectedTanks = [...this.selectedTanks];
            this.tanksPipe = [...this.tanksPipe];
          } else {
            const details = this.purchase.purchase_order_details.filter(
              (purchase) => purchase.product != null
            );

            this.selectedTanks = details.map((detail) => {
              return {
                purchase_detail_product_id: detail?.product.id,
                product_id: detail?.product.id,
                product: detail?.product,
                quantity: detail?.quantity,
                percentage: 100,
                tank_id: null,
              };
            });

            this.selectedTanks = [...this.selectedTanks];
          }
        }
      },
      error: (e) => {},
    });
  }

  isAuthorizedUser(): boolean {
    const role = this.dataService.userValue.roles[0]?.id;
    return role == 1 || role == 2 || role == 7;
  }

  onSelectTank(
    event: Event,
    tankId: number,
    productId: number,
    labOrderDetail: PurchaseLabOrder,
    detailsId: number
  ): void {
    const target = event.target as HTMLSelectElement;
    target.value = "";
    const tank = this.tanks.slice().find((tank) => tank.id === Number(tankId));
    const requiredTotalProduct = this.calculatePercentage(labOrderDetail) ?? 0;
    this.selectedTanks[detailsId].tank_id = tankId;
    this.calculateTankTotal(tank, productId, requiredTotalProduct);
  }

  calculateTankTotal(
    tank: GetTank,
    productId: number,
    requiredTotalProduct: number,
    update: boolean = false
  ): number {
    const currProductTanks =
      this.tanksPipe.length > 0
        ? this.tanksPipe.filter(
            (tanks) => tanks?.purchase_detail_product_id === productId
          )
        : [];
    const currRequiredTotalProduct =
      currProductTanks.length > 0
        ? currProductTanks
            .map((tank) => {
              if (
                isNaN(tank.required_quantity) ||
                typeof tank.required_quantity == "string"
              )
                return this.convertToNumber(tank.required_quantity?.toString());
              return tank.required_quantity;
            })
            ?.reduce((acc, tank) => acc + tank)
        : 0;

    if (currRequiredTotalProduct < requiredTotalProduct && !update) {
      this.tanksPipe = [
        ...this.tanksPipe,
        {
          ...tank,
          priority: this.tanksPipe.length + 1,
          required_quantity: 0,
          purchase_detail_product_id: productId,
        },
      ];
    } else if (update) {
      return currRequiredTotalProduct;
    } else {
      this.openSnack(
        "snack-error",
        "Los litros requeridos del producto en la mezcla estan completos."
      );
    }
  }

  getCurrentTotalInventoryTank(productId: number): number {
    let total = 0;
    const currProductTanksPipe =
      this.tanksPipe.length > 0
        ? this.tanksPipe.filter((tanks) => tanks.products[0]?.id === productId)
        : [];
    const currProductTanks = [...currProductTanksPipe];
    const currRequiredTotalProduct =
      currProductTanks.length > 0
        ? currProductTanks
            .map((tank) => {
              if (
                isNaN(tank.required_quantity) ||
                typeof tank.required_quantity == "string"
              )
                return this.convertToNumber(tank.required_quantity?.toString());
              return tank.required_quantity;
            })
            ?.reduce((acc, tank) => acc + tank)
        : 0;
    total = currRequiredTotalProduct;

    return total;
  }

  preventOnKeyPress(event: KeyboardEvent) {
    if (event.key !== "Enter") event.preventDefault();
  }

  focusNext(index: number, type: string) {
    const nextElementSiblingId = `${type}${index + 1}`;
    const nextElement = document.querySelector(
      `#${nextElementSiblingId}`
    ) as HTMLElement;
    nextElement?.focus();
  }

  saveInputValue(
    event: Event,
    fromTank: boolean = false,
    tankId: number = null,
    pipeNumber: number = 1
  ): void {
    const currentPipeTank = this.tanksPipe[Number(tankId)];
    const currentTank = this.tanks.find(
      (tank) => tank.id === currentPipeTank?.id
    );

    const tmpTankQuantities = this.tmpTankQuantities.find(
      (tank) => tank.id === currentTank.id && tankId === tank.tankId
    );

    if (!tmpTankQuantities) {
      this.tmpTankQuantities.push({
        id: currentTank.id,
        tankId,
        required_quantity: this.leadingZero(
          currentPipeTank?.required_quantity?.toString()
        ),
        pipeNumber,
      });
    } else {
      const index = this.tmpTankQuantities.indexOf(tmpTankQuantities);
      this.tmpTankQuantities[index].required_quantity = this.leadingZero(
        currentPipeTank?.required_quantity?.toString()
      );
    }
  }

  leadingZero(num: string): string {
    if (num == null) return "0.00";

    const dec = num.split(".")[1];

    if (!dec && num == "0") return "0.00";
    else if (!dec && num != "0") return `${num}.00`;

    const len = dec && dec.length > 2 ? dec.length : 2;
    const fixed = Number(num).toFixed(len);
    return fixed;
  }

  onSelectPipe(pipeName: string): void {
    this.currentSelectedPipe = pipeName;
  }

  allMixProductsAreSelected(): boolean {
    const allSelected = this.purchase.purchase_order_details.every((detail) =>
      this.selectedTanks.find(
        (labDetail) => labDetail.product?.id === detail?.product.id
      )
    );

    return allSelected;
  }

  allProductsAreSelected(): boolean {
    return this.purchase.purchase_order_details.every((detail) =>
      this.selectedTanks.find(
        (labDetail) => labDetail.product?.id === detail?.product.id
      )
    );
  }

  onSupplyChange(
    event: Event,
    tankId: number,
    productId: number,
    labOrderDetail: PurchaseLabOrder
  ): void {
    const element = event.target as HTMLInputElement;
    const currentPipeTank = this.tanksPipe[Number(tankId)];
    const currentTank = this.tanks.find(
      (tank) => tank.id === currentPipeTank?.id
    );
    const requiredTotalProduct = this.calculatePercentage(labOrderDetail) ?? 0;
    const total = this.calculateTankTotal(
      currentTank,
      productId,
      requiredTotalProduct,
      true
    );

    if (total > requiredTotalProduct) {
      event.preventDefault();
      this.backQtyValidation(
        "Los litros requeridos no pueden ser mayores al porcentaje de la mezcla.",
        element,
        tankId
      );
      return;
    }

    const currentCapacity =
      currentTank?.capacity - this.getCurrentTotalInventoryTank(productId);

    if (currentCapacity < 0) {
      this.backQtyTankValidation(
        tankId,
        "Ya no hay disponibilidad del producto en el tanque."
      );
      return;
    }

    this.saveInputValue(event, false, tankId);
    this.tanksPipe = [...this.tanksPipe];
  }

  backQtyValidation(msg: string, element: HTMLInputElement, tankId: number) {
    this.openSnack("snack-error", msg);
    const dataValue =
      this.convertToNumber(element.getAttribute("data-value")) ?? 0;

    this.tanksPipe[Number(tankId)].required_quantity = dataValue;
  }

  backQtyTankValidation(tankId: number, msg: string): void {
    this.openSnack("snack-error", msg);
    const currentPipeTank = this.tanksPipe[Number(tankId)];
    this.tanksPipe[Number(tankId)].required_quantity = this.convertToNumber(
      this.leadingZero(currentPipeTank?.required_quantity?.toString())
    );
  }

  calculatePercentage(purchaseDetail: PurchaseLabOrderDetail): number {
    return Math.round(
      (this.convertToNumber(purchaseDetail?.percentage.toString()) / 100) *
        purchaseDetail.quantity
    );
  }

  calculateRequiredQuantityAndPercentage(
    dataSource: PurchaseLabOrderDetail[]
  ): any {
    let totalQuantity = 0;

    for (const tank of dataSource) {
      if (
        String(tank.quantity).includes(".") ||
        String(tank.quantity).includes(",")
      ) {
        const val = String(tank.quantity).replace(",", "");
        const required = Number(parseFloat(val));
        totalQuantity += required;
      }
    }

    return { totalQuantity };
  }

  convertToNumber(value: string): number {
    if (String(value).includes(".") || String(value).includes(",")) {
      const val = String(value).replace(",", "");
      return Number(parseFloat(val));
    } else return Number(parseFloat(value));
  }

  hasSelectedTanks(): boolean {
    return this.tanksPipe.length > 0;
  }

  hasRequiredQuantity(): boolean {
    const pipeAllQuantities = this.tanksPipe.every((tank) => {
      if (
        String(tank.required_quantity).includes(".") ||
        String(tank.required_quantity).includes(",")
      ) {
        const val = String(tank.required_quantity).replace(",", "");
        const required = Number(parseFloat(val));
        return required > 0;
      } else return tank.required_quantity > 0;
    });

    return pipeAllQuantities;
  }

  getTotal(): number {
    return this.calculateRequiredQuantityAndPercentage(this.selectedTanks)
      .totalQuantity;
  }

  onEditPipeLts(event: Event, pipeNumber: number) {
    /*const total = this.isTransportFull
      ? this.convertToNumber(this.currentTypeTransportPipeOne.toString()) +
        this.convertToNumber(this.currentTypeTransportPipeTwo.toString())
      : this.convertToNumber(this.currentTypeTransportPipeOne.toString());

    if (total > this.purchase.sold_liters) {
      this.openSnack(
        "snack-error",
        "Los litros transportados no pueden ser mayores a los total vendido."
      );

      if (pipeNumber === 1) {
        this.currentTypeTransportPipeOne = this.tmpTypeTransportPipeOne;
      } else {
        this.currentTypeTransportPipeTwo = this.tmpTypeTransportPipeTwo;
      }

      event.preventDefault();
      return;
    }

    const el = event.target as HTMLInputElement;
    const value = el.value;

    if (pipeNumber === 1) {
      this.tmpTypeTransportPipeOne = this.convertToNumber(value);
    } else {
      this.tmpTypeTransportPipeTwo = this.convertToNumber(value);
    }*/
  }

  deleteTank(tankId: number): void {
    this.selectedTanks = [
      ...this.selectedTanks.filter((tank) => tank.id !== tankId),
    ];
  }

  sendData(): void {
    if (!this.hasSelectedTanks()) {
      this.openSnack("snack-error", "Debe seleccionar al menos un tanque");
      return;
    }

    if (this.hasRequiredQuantity()) {
      const ladOrderDetailsId = [];
      const labDetails = this.tanksPipe.map((tank) => {
        const currentDetail = this.selectedTanks.find((selectedTank) => {
          return (
            selectedTank?.product?.id === tank?.products[0]?.id &&
            selectedTank?.tank?.id == tank?.id
          );
        });

        let labDetailId = currentDetail?.id ?? null;

        if (ladOrderDetailsId.includes(labDetailId)) {
          labDetailId = null;
        }

        if (currentDetail) ladOrderDetailsId.push(currentDetail.id);

        return {
          purchase_lab_order_id: this.purchaseLabOrder?.id ?? null,
          lab_detail_id: labDetailId,
          product_id: tank.products[0]?.id,
          tank_id: tank.id,
          quantity: tank.required_quantity,
          percentage: currentDetail?.percentage ?? 100,
        };
      });

      if (this.purchase && this.form.valid) {
        const purchaseLabOrder = {
          ...this.form.value,
          lab_order_id: this.purchaseLabOrder?.id ?? null,
          purchase_order_id: this.purchase.id ?? null,
          active: this.purchaseLabOrder?.active ?? true,
          attendant_id: this.dataService.userValue.id,
          pump: this.purchaseLabOrder?.pump ?? 0,
          approved: this.form.value.approved ?? false,
          observations: this.form.value.observations ?? "N/A",
          lab_details: labDetails,
          purchase_lab_order_details: labDetails,
          lab_comments: null,
        };

        let products_services = this.purchase.purchase_order_details.map(
          (detail) => {
            return {
              ...detail,
              purchase_order_detail_id: detail?.id ?? null,
              product_id: detail?.product?.id ?? null,
              service_id: detail?.service?.id ?? null,
            };
          }
        );

        this.purchase = {
          ...this.purchase,
          buyer_id: this.purchase.buyer.id,
          main_company_id: this.purchase.main_company.id,
          order_status_id: this.purchase.order_status.id,
          payment_method_id: this.purchase.payment_method.id,
          supplier_id: this.purchase.supplier.id,
          transport_type_id: this.purchase.transport_type.id,
          products_services,
          purchase_lab_orders: { ...purchaseLabOrder },
          lab_order: { ...purchaseLabOrder },
        };

        this.update();
      } else this.openSnack("snack-error", "Revisa los datos");
    } else this.openSnack("snack-error", "Completa las cantidades requeridas");
  }

  update(): void {
    this.salesService.typedUpdatePurchase(this.purchase).subscribe({
      next: (v) => {
        this.openSnack("snack-success", v.message);
        this.dialogRef.close({ status: 200 });
      },
      error: (e) => {
        this.openSnack("snack-error", e.error.message);
      },
    });
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
      verticalPosition: "top",
    });
  }
}
