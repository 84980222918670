<div class="">
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-200 border-0"
  >
    <div class="flex mt-4">
      <div class="container px-2 w-1/2">
        <label
          class="block uppercase text-slate-600 text-xs font-bold mb-2"
          htmlFor="role_id"
        >
          Compañia
        </label>
        <select
          name="companie_id"
          #company_id
          (change)="getSelectedCompany($event.target.value)"
          class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        >
          <option value="">Selecciona una compañia</option>
          <option *ngFor="let item of objects" [value]="item.id">
            {{ item.legal_name }}
          </option>
        </select>
      </div>
      <div class="container px-2 w-1/2">
        <label
          class="block uppercase text-slate-600 text-xs font-bold mb-2"
          htmlFor="role_id"
        >
          Cliente
        </label>
        <select
          name="companie_id"
          #supplier_id
          class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          (change)="getSelectedClient($event.target.value)"
        >
          <option value="">Selecciona un cliente</option>
          <option *ngFor="let item of clients" [value]="item.id">
            {{ item.legal_name }}
          </option>
        </select>
      </div>
    </div>
    <div class="container mt-3  items-center flex">
      <div class="flex justify-end w-full">
        <button
          class="bg-sky-600 text-white active:bg-slate-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 ease-linear transition-all duration-150"
          type="button"
        >
          <i class="fas fa-trash"></i>
        </button>
        <button
          (click)="sender()"
          class="bg-sky-600 text-white active:bg-slate-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 ease-linear transition-all duration-150"
          type="button"
        >
          <i class="fas fa-search"></i> Filtrar
        </button>
      </div>
    </div>
    <div class="relative flex flex-col min-w-0 break-words w-full rounded">
      <div
        class="grid grid-cols-2 gap-2 rounded-t mb-0 px-4 py-3 border-0 pt-6ss"
      >
        <div class="basis-1/2 flex justify-start">
          <button
            class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            (click)="openAssignInvoiceDialog(true)"
            [ngClass]="{ 'disabled:opacity-25': !selected.length }"
            [disabled]="!selected.length">
            Asignar factura
          </button>
        </div>

        <div class="basis-1/2 flex justify-end">
          <div class="basis-1/2 flex justify-start">
            <span
              class="leading-snug font-normal absolute text-center text-slate-300 bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3"
            >
              <i class="fa fa-search"></i>
            </span>

            <input
              type="text"
              class="w-48 pl-12 py-3 rounded-full border text-sm border-gray-300 focus:outline-none focus:border-blue-400"
              (keyup)="updateFilter($event)"
              placeholder="Buscar"
            />
          </div>
        </div>
      </div>

      <div class="grid">
        <div class="block w-full overflow-x-auto rounded-lg">
          <ngx-datatable
            #table
            class="material"
            [rows]="rows"
            [columnMode]="ColumnMode.force"
            [headerHeight]="50"
            [footerHeight]="50"
            rowHeight="auto"
            [limit]="10"
            [selected]="selected"
            [selectionType]="SelectionType.checkbox"
            [selectAllRowsOnPage]="false"
            [scrollbarH]="true"
            [loadingIndicator]="loading"
            (activate)="onActivate($event)"
            (select)="onSelect($event)"
            (sort)="onSort($event)"
            [rowClass]="getRowClass"
            
          >
            <ngx-datatable-column
              [width]="30"
              [sortable]="false"
              [canAutoResize]="false"
              [draggable]="false"
              [resizeable]="false"
              [headerCheckboxable]="true"
              [checkboxable]="true"
            >
            </ngx-datatable-column>
            <ngx-datatable-column name="Cliente">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.client?.company?.legal_name }}
              </ng-template>
            </ngx-datatable-column>
          
            <ngx-datatable-column name="Folio">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.folio }}
              </ng-template>
            </ngx-datatable-column>
          
            <ngx-datatable-column name="Producto">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.product?.name }}
              </ng-template>
            </ngx-datatable-column>
          
            <ngx-datatable-column name="Fecha">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.created_at }}
              </ng-template>
            </ngx-datatable-column>
          
            <ngx-datatable-column name="Ver factura" [sortable]="false" >
              <ng-template let-row="row" ngx-datatable-cell-template>
                <button *ngIf="row.facturada" mat-menu-item (click)="openAssignInvoiceDialog(false, row.id)">
                  <i class="fas fa-eye mx-2"> </i>
                </button>
              </ng-template>
            </ngx-datatable-column>
          
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
