<div class="container bg-white mx-auto px-4 h-full py-4">
    <div class="justify-between rounded-t mb-0 px-4 py-3 border-0 pt-6 bg-white">
        <div class="text-center">
            <h3 class="font-semibold text-lg">
                {{ dataSource.legal_name +  ": " +"Actualizando CFID"}}
            </h3>
        </div>
    </div>
    <form class="bg-white" [formGroup]="form">
        <div class="relative w-full mb-3 flex">
            <div class="container px-2 py-1">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="uso_cfdi_id">
                    CFID
                </label>
                <select name="uso_cfdi_id" formControlName="uso_cfdi_id"
                class="border-2 px-3 py-3
                placeholder-slate-300
                text-slate-600 bg-white rounded border-gray-300
                text-sm focus:outline-none focus:ring
                w-full ease-linear transition-all duration-150">
                    <option *ngFor="let item of cfid" [ngValue]="item.id">{{ item.uso_cfdi + ", " + item.descripcion }}</option>
                </select>
            </div>
        </div>
  
        <div class="flex w-full justify-end">
            <button
                class="bg-slate-500 text-white 
                active:bg-slate-500 font-bold uppercase 
                text-xs px-4 py-2 rounded shadow hover:shadow-md 
                outline-none focus:outline-none mr-1 ease-linear 
                transition-all duration-150"
                (click)="closeModal()">
                Cancelar
            </button>
            <button
                class="bg-sky-600 text-white 
                active:bg-sky-600 font-bold uppercase 
                text-xs px-4 py-2 rounded shadow hover:shadow-md 
                outline-none focus:outline-none mr-1 ease-linear 
                transition-all duration-150"
                [ngClass]="{ 'disabled:opacity-25': form.invalid }" [disabled]="form.invalid" (click)="save()">
                Actualizar
            </button>
        </div>
  
    </form>
  </div>