import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CatalogServicesService } from "app/apiServices/catalog-services.service";
import { FormValidatorService } from "app/core/service/form-validator.service";
import { Category } from "app/models/category";
import { Role } from "app/models/role";
import { RoleView, RoleViews } from "app/models/role-view";
import { View } from "app/models/view";
import { DataService } from "app/services/data.service";

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.css"],
})
export class FormComponent implements OnInit {
  form: FormGroup;
  dataSource: RoleView;
  edit = false;
  dialogTitle: string;

  validationMessages = {
    role_id: [{ type: "required", message: "Ingrese un rol" }],
    view_id: [{ type: "required", message: "Ingrese una vista" }],
  };

  roles: Role[] = [];
  views: any[] = [];

  array0: any[];
  array1: any[];

  selectedItems = [];
  selectedRolItems = [];
  dropdownSettings = {};
  dropdownRoleSettings: any = {};
  disableRole = false;

  constructor(
    private dialogRef: MatDialogRef<FormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dataService: DataService,
    public formService: FormValidatorService,
    public apiService: CatalogServicesService
  ) {
    this.data = data;
    this.dropdownSettings = {
      singleSelection: data.action === "edit" ? true : false,
      idField: "id",
      textField: "name",
      noDataAvailablePlaceholderText: "No hay datos",
      selectAllText: "Seleccionar Todas",
      unSelectAllText: "Deseleccionar Todas",
      itemsShowLimit: 4,
      allowSearchFilter: true,
    };
    this.dropdownRoleSettings = { ...this.dropdownSettings };
    this.dropdownRoleSettings.singleSelection = true;
    this.dropdownRoleSettings.itemsShowLimit = 1;

    if (data.action === "edit") {
      this.dialogTitle = "Editar Rol Vista";
      this.dataSource = data.data;
      this.selectedItems = [this.dataSource.view];
      this.selectedRolItems = [this.dataSource.role];
      this.edit = true;
    } else {
      this.dialogTitle = "Agregar Rol Vista";
      this.dataSource = new RoleView({});
    }
    this.form = this.createForm(data.action);
  }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.apiService.readRoles().subscribe(
      (r) => {
        r.data.forEach((element) => {
          if (element.active == true) this.roles.push(element);
          this.array0 = this.roles;
        });
      },
      (error) => {}
    );

    this.apiService.readViews().subscribe(
      (r) => {
        r.data.forEach((element) => {
          if (element.active) this.views.push(element);
          this.array1 = this.views.filter((view) => view.active && !view.path.includes('existence-types'));
        });
      },
      (error) => {}
    );
  }

  onItemSelect(item: any) {}

  onRoleSelect(item: any) {}
  onSelectAll(items: any) {}

  createForm(action: string): FormGroup {
    if (action === "edit" && this.dataSource.role_id == 1) {
      this.disableRole = true;
    }

    return this.fb.group({
      id: [this.dataSource.id],
      role_id: [
        action === "edit" ? this.selectedRolItems : this.dataSource.role_id,
        [Validators.required],
      ],
      view_id: [
        action === "edit" ? this.selectedItems : this.dataSource.view_id,
        [Validators.required],
      ],
    });
  }

  save(): void {
    if (this.form.valid) {
      var view_ids = [];

      this.form.value.view_id.forEach((element) => {
        view_ids.push(element.id);
      });

      const object = {
        role: this.form.value.role_id[0].id,
        views: view_ids,
      };

      this.dataSource = object;
      this.storeUpdate(this.edit ? "update" : "store");
    } else {
      this.formService.allFields(this.form);
    }
  }

  storeUpdate(method: string): void {
    if (method === "store") {
      this.apiService.updateRoleView(this.dataSource).subscribe((res) => {
        this.closeModal();

        if (res.status === "Éxito") this.getRoleViews();
      });
    } else {
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  private getRoleViews(): void {
    this.apiService.readRoleViews().subscribe(
      (res) => {
        const currentRoleViews = res.data.find(
          (role: RoleViews) =>
            role.id === this.dataService.userValue.roles[0].id
        );
        const currentCategories = currentRoleViews.views.map(
          (view: View) => view.category
        );
        const sortedCategories =
          this.distinctAndSortCategories(currentCategories);
        this.viewsByCategory(sortedCategories, currentRoleViews.views);
      },
      (error) => {
        //console.log(error);
      }
    );
  }

  private distinctAndSortCategories(categories: Category[]): Category[] {
    const result = [];
    const map = new Map();
    for (const item of categories) {
      if (!map.has(item.id)) {
        map.set(item.id, true);
        result.push({
          id: item.id,
          name: item.name,
          order: item.order,
        });
      }
    }

    return result.sort((a: Category, b: Category) => {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    });
  }

  viewsByCategory(categories: Category[], views: View[]) {
    categories.forEach((category) => {
      category.views = views.filter(
        (view: View) =>
          view.category.id === category.id &&
          !view.path.includes("existence-types")
      );

      category.views = category.views.sort((a: View, b: View) => {
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      });
    });

    this.dataService.setCategoriesValue(categories);
  }
}
