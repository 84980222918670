import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ReportService } from "app/apiServices/report.service";
import { SalesService } from "app/apiServices/sales.service";
import { NotificationsComponent } from "app/components/alerts/notifications/notifications.component";
import { EvidenceComponent } from "app/components/purcase/dialogs/evidence/evidence.component";
import { Attachment } from "app/models/attachment";

@Component({
  selector: "app-sale-evidences",
  templateUrl: "./sale-evidences.component.html",
  styleUrls: ["./sale-evidences.component.css"],
})
export class SaleEvidencesComponent {
  attachments: Attachment[] = [];
  permission: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SaleEvidencesComponent>,
    private _snack: MatSnackBar
  ) {
    this.attachments = data.attachments;
  }

  ngOnInit(): void {}

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
      verticalPosition: "top",
    });
  }

  closeModal() {
    this.dialogRef.close();
  }

  gotoUrl(url: string) {
    window.open(url);
  }
}
