<div class="container bg-white mx-auto px-4 h-full py-4">
  <div class="justify-between rounded-t mb-0 px-4 py-3 border-0 pt-6 bg-white">
    <div class="text-center">
      <h3 class="font-semibold text-lg">
        Agregar comisión al cliente "{{ dataSource.legal_name }}"
      </h3>
    </div>
  </div>
  <form class="bg-white" [formGroup]="form">
    <div class="relative w-full mb-3 flex">
      <div class="container px-2">
        <label
          class="block uppercase text-slate-600 text-xs font-bold mb-2"
          htmlFor="product_id"
        >
          Producto
        </label>
        <select
          name="product_id"
          formControlName="product_id"
          [ngClass]="{
            'border-red-500': form.controls.product_id.invalid,
            'focus:border-red-500': form.controls.product_id.invalid,
            'focus:ring-red-500': form.controls.product_id.invalid
          }"
          class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        >
          <option *ngFor="let item of products" [ngValue]="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>

      <div class="container px-2">
        <label
          class="block uppercase text-slate-600 text-xs font-bold mb-2"
          htmlFor="user_id"
        >
          Vendedor
        </label>
        <select
          name="user_id"
          formControlName="user_id"
          [ngClass]="{
            'border-red-500': form.controls.user_id.invalid,
            'focus:border-red-500': form.controls.user_id.invalid,
            'focus:ring-red-500': form.controls.user_id.invalid
          }"
          class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        >
          <option *ngFor="let vendor of vendors" [ngValue]="vendor.id">
            {{ vendor.name }} {{ vendor.last_name }}
          </option>
        </select>
      </div>

      <div class="container px-2">
        <label
          class="block uppercase text-slate-600 text-xs font-bold mb-2"
          htmlFor="cost_per_liter"
        >
          Comisión base
        </label>
        <input
          type="number"
          name="cost_per_liter"
          formControlName="cost_per_liter"
          [ngClass]="{
            'border-red-500': form.controls.cost_per_liter.invalid,
            'focus:border-red-500': form.controls.cost_per_liter.invalid,
            'focus:ring-red-500': form.controls.cost_per_liter.invalid
          }"
          class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          prefix="$"
        />
        <div *ngFor="let validation of validationMessages.cost_per_liter">
          <span
            *ngIf="formService.field(form, validation.type, 'cost_per_liter')"
            class="mt-2 text-sm text-red-500"
          >
            {{ validation.message }}
          </span>
        </div>
      </div>
    </div>

    <div class="flex w-full justify-end">
      <button
        class="bg-slate-500 text-white active:bg-slate-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        (click)="closeModal()"
      >
        Cancelar
      </button>
      <button
        class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        [ngClass]="{ 'disabled:opacity-25': form.invalid }"
        [disabled]="form.invalid"
        (click)="save()"
      >
        Agregar
      </button>
    </div>
  </form>
</div>
