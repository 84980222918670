<div class="container bg-white mx-auto px-4 h-full py-4">
  <div class="justify-between rounded-t mb-0 px-4 py-3 border-0 pt-6 bg-white">
    <div class="text-center">
      <h3 class="font-semibold text-lg">
        {{ dialogTitle }}
      </h3>
    </div>
  </div>

  <form class="mx-4 my-6 bg-white" [formGroup]="form">
    <div class="flex flex-row">
      <div class="w-full md:w-1/2">
        <div class="container px-2">
          <label
            class="block uppercase text-slate-600 text-xs font-bold mb-2"
            htmlFor="name"
          >
            Nombre
          </label>
          <input
            type="text"
            name="name"
            formControlName="name"
            [ngClass]="{
              'focus:border-red-500': form.controls.name.invalid,
              'focus:ring-red-500': form.controls.name.invalid
            }"
            class="border-gray-300 px-3 py-3 placeholder-slate-300 border-1 text-slate-600 bg-white rounded text-sm focus:ring-1-1 focus:outline-none w-full ease-linear transition-all duration-150"
          />
          <div *ngFor="let validation of validationMessages.name">
            <span
              *ngIf="formService.field(form, validation.type, 'name')"
              class="mt-2 text-sm text-red-500"
            >
              {{ validation.message }}
            </span>
          </div>
        </div>
      </div>

      <div class="w-full md:w-1/2">
        <div class="container px-2">
          <label
            class="block uppercase text-slate-600 text-xs font-bold mb-2"
            htmlFor="unit_key"
          >
            Clave de unidad
          </label>
          <input
            type="text"
            name="unit_key"
            formControlName="unit_key"
            [ngClass]="{
              'focus:border-red-500': form.controls.unit_key.invalid,
              'focus:ring-red-500': form.controls.unit_key.invalid
            }"
            class="border-gray-300 px-3 py-3 placeholder-slate-300 border-1 text-slate-600 bg-white rounded text-sm focus:ring-1-1 focus:outline-none w-full ease-linear transition-all duration-150"
          />
          <div *ngFor="let validation of validationMessages.unit_key">
            <span
              *ngIf="formService.field(form, validation.type, 'unit_key')"
              class="mt-2 text-sm text-red-500"
            >
              {{ validation.message }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-row mt-3">
      <div class="w-full md:w-1/2">
        <div class="container px-2">
          <label
            class="block uppercase text-slate-600 text-xs font-bold mb-2"
            htmlFor="unit_key"
          >
            IVA
          </label>
          <input
            type="number"
            name="iva"
            formControlName="iva"
            [ngClass]="{
              'focus:border-red-500': form.controls.iva.invalid,
              'focus:ring-red-500': form.controls.iva.invalid
            }"
            class="border-gray-300 px-3 py-3 placeholder-slate-300 border-1 text-slate-600 bg-white rounded text-sm focus:ring-1-1 focus:outline-none w-full ease-linear transition-all duration-150"
          />
          <div *ngFor="let validation of validationMessages.iva">
            <span
              *ngIf="formService.field(form, validation.type, 'iva')"
              class="mt-2 text-sm text-red-500"
            >
              {{ validation.message }}
            </span>
          </div>
        </div>
      </div>

      <div class="w-full md:w-1/2">
        <div class="container px-2">
          <label
            class="block uppercase text-slate-600 text-xs font-bold mb-2"
            htmlFor="iva_retain"
          >
            Retención de IVA
          </label>
          <input
            type="number"
            name="iva_retain"
            formControlName="iva_retain"
            [ngClass]="{
              'focus:border-red-500': form.controls.iva_retain.invalid,
              'focus:ring-red-500': form.controls.iva_retain.invalid
            }"
            class="border-gray-300 px-3 py-3 placeholder-slate-300 border-1 text-slate-600 bg-white rounded text-sm focus:ring-1-1 focus:outline-none w-full ease-linear transition-all duration-150"
          />
          <div *ngFor="let validation of validationMessages.iva_retain">
            <span
              *ngIf="formService.field(form, validation.type, 'iva_retain')"
              class="mt-2 text-sm text-red-500"
            >
              {{ validation.message }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-row mt-3">
      <div class="w-full md:w-1/2">
        <div class="container px-2">
          <label
            class="block uppercase text-slate-600 text-xs font-bold mb-2"
            htmlFor="unit_key"
          >
            ISR
          </label>
          <input
            type="number"
            name="isr"
            formControlName="isr"
            [ngClass]="{
              'focus:border-red-500': form.controls.isr.invalid,
              'focus:ring-red-500': form.controls.isr.invalid
            }"
            class="border-gray-300 px-3 py-3 placeholder-slate-300 border-1 text-slate-600 bg-white rounded text-sm focus:ring-1-1 focus:outline-none w-full ease-linear transition-all duration-150"
          />
          <div *ngFor="let validation of validationMessages.isr">
            <span
              *ngIf="formService.field(form, validation.type, 'isr')"
              class="mt-2 text-sm text-red-500"
            >
              {{ validation.message }}
            </span>
          </div>
        </div>
      </div>

      <div class="w-full md:w-1/2">
        <div class="container px-2">
          <label
            class="block uppercase text-slate-600 text-xs font-bold mb-2"
            htmlFor="iva_retain"
          >
            Retención de ISR
          </label>
          <input
            type="number"
            name="isr_retain"
            formControlName="isr_retain"
            [ngClass]="{
              'focus:border-red-500': form.controls.isr_retain.invalid,
              'focus:ring-red-500': form.controls.isr_retain.invalid
            }"
            class="border-gray-300 px-3 py-3 placeholder-slate-300 border-1 text-slate-600 bg-white rounded text-sm focus:ring-1-1 focus:outline-none w-full ease-linear transition-all duration-150"
          />
          <div *ngFor="let validation of validationMessages.isr_retain">
            <span
              *ngIf="formService.field(form, validation.type, 'isr_retain')"
              class="mt-2 text-sm text-red-500"
            >
              {{ validation.message }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="relative w-full mb-3 flex mt-3">
      <div class="container px-2">
        <label
          class="block uppercase text-slate-600 text-xs font-bold mb-2"
          htmlFor="iva_retain"
        >
          Código SAT
        </label>
        <input
          type="text"
          name="clave_sat"
          formControlName="clave_sat"
          [ngClass]="{
            'focus:border-red-500': form.controls.clave_sat.invalid,
            'focus:ring-red-500': form.controls.clave_sat.invalid
          }"
          class="border-gray-300 px-3 py-3 placeholder-slate-300 border-1 text-slate-600 bg-white rounded text-sm focus:ring-1-1 focus:outline-none w-full ease-linear transition-all duration-150"
        />
        <div *ngFor="let validation of validationMessages.clave_sat">
          <span
            *ngIf="formService.field(form, validation.type, 'clave_sat')"
            class="mt-2 text-sm text-red-500"
          >
            {{ validation.message }}
          </span>
        </div>
      </div>
    </div>

    <div class="relative w-full mb-3 flex mt-3">
      <div class="container px-2">
        <label
          class="block uppercase text-slate-600 text-xs font-bold mb-2"
          htmlFor="iva_retain"
        >
          Objeto Impuesto
        </label>
        <input
          type="text"
          name="obj_tax"
          formControlName="obj_tax"
          maxlength="2"
          [ngClass]="{
            'focus:border-red-500': form.controls.obj_tax.invalid,
            'focus:ring-red-500': form.controls.obj_tax.invalid
          }"
          class="border-gray-300 px-3 py-3 placeholder-slate-300 border-1 text-slate-600 bg-white rounded text-sm focus:ring-1-1 focus:outline-none w-full ease-linear transition-all duration-150"
        />
        <div *ngFor="let validation of validationMessages.obj_tax">
          <span
            *ngIf="formService.field(form, validation.type, 'obj_tax')"
            class="mt-2 text-sm text-red-500"
          >
            {{ validation.message }}
          </span>
        </div>
      </div>
    </div>

    <div class="relative w-full mb-3 flex mt-3">
      <div class="container px-2">
        <label
          class="block uppercase text-slate-600 text-xs font-bold mb-2"
          htmlFor="observations"
        >
          Observaciones
        </label>
        <textarea
          name="observations"
          formControlName="observations"
          class="border-gray-300 px-3 py-3 placeholder-slate-300 border-1 text-slate-600 bg-white rounded text-sm focus:ring-1-1 focus:outline-none w-full ease-linear transition-all duration-150"
          [ngClass]="{
            'focus:border-red-500': form.controls.observations.invalid,
            'focus:ring-red-500': form.controls.observations.invalid
          }"
        >
        </textarea>
      </div>
    </div>
  </form>

  <div class="flex items-center justify-end">
    <button
      class="bg-slate-500 text-white active:bg-slate-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      (click)="closeModal(null)"
    >
      Cancelar
    </button>
    <button
      class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      [ngClass]="{ 'disabled:opacity-25': form.invalid }"
      [disabled]="form.invalid"
      (click)="save()"
    >
      {{ edit ? "Actualizar" : "Agregar" }}
    </button>
  </div>
</div>
