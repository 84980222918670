export class ExistenceChangeTypes {
    id?: number;
    name?: string;
    description?: string;
  
    constructor(existenceChangeTypes: ExistenceChangeTypes) {
      {
        this.id = existenceChangeTypes.id || undefined;
        this.name = existenceChangeTypes.name || '';
        this.description = existenceChangeTypes.description || '';
      }
    }
  }
  