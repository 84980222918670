import { Component, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";
import { FormComponent } from "./form/form.component";
import { ModalConfirmationComponent } from "../modals/modal-confirmation/modal-confirmation.component";
import { ClientsService } from "app/apiServices/clients.service";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ClientsForCompanyComponent } from "./clients-for-company/clients-for-company.component";
import { InfoComponent } from "./info/info.component";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { AddressesDialogComponent } from "./addresses-dialog/addresses-dialog.component";

@Component({
  selector: "app-companies",
  templateUrl: "./companies.component.html",
  styleUrls: ["./companies.component.css"],
})
export class CompaniesComponent {
  objects: any[] = [];
  rows: any[];

  temp: any[] = [];
  form: FormGroup;

  @ViewChild(DatatableComponent) table: DatatableComponent;
  ColumnMode = ColumnMode;
  columnMapValues = [
    ["id", "id"],
    ["razNSocial", "legal_name"],
    ["principal", "main"],
  ] as const;
  columnMap: Map<string, string> = new Map(this.columnMapValues);
  loading: boolean = false;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    public apiService: ClientsService,
    private route: Router,
  ) {
    this.form = this.fb.group({
      rowsPerPage: [10],
    });
    this.temp = this.rows;
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.loading = true;
    this.rows = [];
    this.objects = [];

    this.apiService.readCompanies().subscribe(
      (r) => {
        this.loading = false;
        r.data.forEach((element) => {
          if (element.active) this.objects.push(element);
        });
        this.rows = this.objects;
        this.temp = this.rows;
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  updateFilter(event: Event) {
    const val: string = (event.target as HTMLInputElement).value.toLowerCase();
    const temp: any[] = this.temp.filter((d: any) => {
      return d.legal_name.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows = temp;
    this.table.offset = 0;
  }

  openDialog(data: any, action: string) {
    const dialogRef = this.dialog.open(FormComponent, {
      width: "600px",
      data: {
        action,
        data,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.status == 200) this.getData();
    });
  }

  openDialogClients(data: any) {
    const dialogRef = this.dialog.open(ClientsForCompanyComponent, {
      width: "600px",
      data: {
        data,
        companies: this.objects,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.status == 200) this.getData();
    });
  }

  openDialogInfo(data: any) {
    const dialogRef = this.dialog.open(InfoComponent, {
      width: "600px",
      data: {
        data,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.status == 200) this.getData();
    });
  }

  openDialogDelete(Id: number) {
    const dialogRef = this.dialog.open(ModalConfirmationComponent, {
      panelClass: ["dialog-responsive"],
      data: {
        title: "¿Estas seguro de desactivar esta Compañia?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.apiService.deleteCompany(Id).subscribe((r) => {
          this.getData();
        });
      }
    });
  }

  onLimitChange(event: Event): void {
    const limit = (event.target as HTMLSelectElement).value;
    this.changePageLimit(limit);
    this.table.limit = this.form.controls.rowsPerPage.value;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor(
          (this.table.rowCount - 1) / this.table.limit
        );
      }
    });
  }

  onSort(event: any): void {
    const sort = event.sorts[0];
    const column = this.columnMap.get(sort.prop);

    if (column) {
      this.rows.sort((a, b) => {
        if (typeof a[column] === "number" || typeof a[column] === "boolean") {
          if (sort.dir === "asc") return a[column] - b[column];
          else return b[column] - a[column];
        } else if (typeof a[column] === "string") {
          return (
            a[column].localeCompare(b[column]) * (sort.dir === "desc" ? -1 : 1)
          );
        } else if (typeof a[column] === "object") {
          if ("product") {
            return (
              a[column].name.localeCompare(b[column].name) *
              (sort.dir === "desc" ? -1 : 1)
            );
          } else {
            return (
              a[column].legal_name.localeCompare(b[column].legal_name) *
              (sort.dir === "desc" ? -1 : 1)
            );
          }
        }
      });

      this.rows = [...this.rows];
    }
  }

  changePageLimit(limit: any): void {
    this.form.controls.rowsPerPage.setValue(parseInt(limit, 10));
  }

  delete(roleId: number) {
    this.openDialogDelete(roleId);
  }

  redirectClients() {
    this.route.navigate(["/admin/clients"]);
  }

  redirectTransportist() {
    this.route.navigate(["/admin/transportist"]);
  }

  redirectSuppliers() {
    this.route.navigate(["/admin/suppliers"]);
  }

  addAdresses(row: any): void {
    const dialogRef = this.dialog.open(AddressesDialogComponent, {
      // panelClass: ["dialog-responsive"],
      width: "60%",
      data: { company_id: row.id },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.status == 200) this.getData();
    });
  }
}
