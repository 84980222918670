<div
  role="status"
  class="w-full py-8 space-y-4 divide-y divide-gray-200 shadow animate-pulse"
>
  <div class="flex items-center justify-between">
    <div>
      <div
        class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"
      ></div>
      <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
    </div>
    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
  </div>
  <div class="flex items-center justify-between pt-4">
    <div>
      <div
        class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"
      ></div>
      <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
    </div>
    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
  </div>
  <div class="flex items-center justify-between pt-4">
    <div>
      <div
        class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"
      ></div>
      <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
    </div>
    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
  </div>
  <div class="flex items-center justify-between pt-4">
    <div>
      <div
        class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"
      ></div>
      <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
    </div>
    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
  </div>
  <div class="flex items-center justify-between pt-4">
    <div>
      <div
        class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"
      ></div>
      <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
    </div>
    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
  </div>
  <span class="sr-only">Loading...</span>
</div>
