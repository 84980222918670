<div class="">
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-200 border-0">
    <div class="relative flex flex-col min-w-0 break-words w-full rounded">
      <!--  Top Lane  -->
      <div class="grid grid-cols-2 gap-2 rounded-t mb-0 px-4 py-3 border-0 pt-6">
        <div class="basis-1/2 flex justify-start">
          <span
            class="z-10 h-full leading-snug font-normal absolute text-center text-slate-300 bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
            <i class="fa fa-search"></i>
          </span>
          <input
            type="text"
            class="w-48 pl-12 py-3 rounded-full border text-sm border-gray-300 focus:outline-none focus:border-blue-400"
            (keyup)="updateFilter($event)"
            placeholder="Buscar"/>
        </div>

        <div class="flex justify-end">
          <!-- <div
            id="dateRangePickerId"
            date-rangepicker
            class="w-full flex items-center">
            <div class="w-full relative">
              <div
                class="w-full pl-3 absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  class="w-4 h-4 text-black"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20">
                  <path
                    d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"
                  />
                </svg>
              </div>
              <input
                name="start"
                type="text"
                class="bg-gray-50 border border-gray-300 text-gray-900 placeholder-current pl-12 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
                placeholder="Fecha de inicio"
                (changeDate)="changeDate($event)"/>
            </div>
            <span class="mx-4 text-black font-medium text-base">a</span>
            <div class="w-full relative">
              <div
                class="w-full pl-3 absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  class="w-4 h-4 text-black"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20">
                  <path
                    d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
                </svg>
              </div>
              <input
                name="end"
                type="text"
                class="bg-gray-50 border border-gray-300 pl-12 text-gray-900 placeholder-current text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
                placeholder="Fecha de fin"
                (changeDate)="changeDate($event)"/>
            </div>
          </div>
          <div class="flex justify-center items-center mx-3">
            <i class="fas fa-undo cursor-pointer" (click)="undo()"></i>
          </div> -->
          <button
            class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-sm px-6 py-3 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            (click)="openDialog(null, 'store')">
            <i class="fa fa-plus"></i>
          </button>
        </div>

        <div class="basis-1/2 flex justify-end">
        </div>
      </div>

      <div class="grid flex">
        
        <!-- Date Pickers -->
        <!-- <div class="px-4 py-3 flex justify-end">
          <div class="w-1/4">
            <div class="px-2 rounded-full">
              <app-date idName="init_date" title="Fecha inicio" (dateChange)="changeDate($event, 'init_date')"></app-date>
            </div>
          </div>
          <div class="w-1/4">
            <div class="px-2 rounded-full">
              <app-date idName="final_date" title="Fecha final" (dateChange)="changeDate($event, 'final_date')"></app-date>
            </div>
          </div>

          <div class="w-1/4">
            <button
              class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-sm px-6 py-3 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              (click)="clearDate()">
              <i class="fas fa-eraser"></i>
            </button>
          </div>
        </div> -->

        <div class="block w-full overflow-x-auto rounded-lg">
          <ngx-datatable
            #table
            class="material expandable"
            [columnMode]="ColumnMode.force"
            [headerHeight]="50"
            [footerHeight]="50"
            rowHeight="auto"
            [limit]="10"
            [scrollbarH]="true"
            [rows]="temp">
            <!-- Only works with an extra column Check why -->
            <ngx-datatable-column
              [width]="10"
              [resizeable]="false"
              [sortable]="false"
              [draggable]="false"
              [canAutoResize]="false">

            </ngx-datatable-column>

            <!-- Row Details Template -->
            <ngx-datatable-row-detail
              [rowHeight]="'auto'"
              (toggle)="onDetailToggle($event)">
              <ng-template
                let-row="row"
                let-expanded="expanded"
                ngx-datatable-row-detail-template>
                <div style="padding-left: 55px">
                  <div class="w-full">
                    <div class="flex justify-end pr-8">
                      <button
                        class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-sm px-3 py-2 rounded-full shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                        type="button"
                        (click)="openDialog(row, 'sub_oc')">
                        <i class="fa fa-plus"></i>
                      </button>
                    </div>
                    <div class="flex justify-start pl-12">
                      <b>Servicios Relacionados:</b>
                    </div>
                  </div>
                  <div class="row flex-row">
                    <div class="flex w-full">
                      <table class="table-fixed w-11/12">
                        <thead *ngIf="row.subPurchases.length > 0">
                          <tr>
                            <th class="border-b border-r">Folio</th>
                            <th class="border-b border-r">
                              Compania Encargada
                            </th>
                            <th class="border-b border-r">Comprador</th>
                            <th class="border-b border-r">Servicio</th>
                            <th class="border-b border-r">Creado</th>
                            <th class="border-b border-r">Opciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let sub of row.subPurchases">
                            <td class="border-b border-r text-center">
                              {{ sub.folio }}
                            </td>
                            <td class="border-b border-r text-center">
                              {{ sub.supplier?.legal_name ?? "" }}
                            </td>
                            <td class="border-b border-r text-center">
                              {{ sub.buyer.name + " " + sub.buyer.lastname }}
                            </td>
                            <td class="border-b border-r text-center">
                              {{ sub.purchase_order_details[0]?.service?.name }}
                            </td>
                            <td class="border-b border-r text-center">
                              {{ sub.creation_date }}
                            </td>
                            <td class="border-b border-r text-center">
                              <button
                                class="bg-emerald-500 text-white px-2 py-1 rounded-full text-center"
                                (click)="openDialog(sub, 'edit_subOC')">
                                <i class="fas fa-edit"> </i>
                              </button>

                              <button
                                class="bg-red-500 text-white mx-8 px-2 py-1 rounded-full text-center"
                                (click)="delete(sub.id)">
                                <i class="fas fa-trash-alt"> </i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngx-datatable-row-detail>

            <!-- Row Expander -->
            <ngx-datatable-column
              [width]="50"
              [resizeable]="false"
              [sortable]="false"
              [draggable]="false"
              [canAutoResize]="false">
              <ng-template
                let-row="row"
                let-expanded="expanded"
                ngx-datatable-cell-template>
                <div class="flex items-center h-full">
                  <a
                    href="javascript:void(0)"
                    [class.datatable-icon-right]="!expanded"
                    [class.datatable-icon-down]="expanded"
                    title="OC"
                    (click)="toggleExpandRow(row)">
                  </a>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <!-- Normal Table -->
            <ngx-datatable-column name="Folio">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.folio }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Comprador">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.buyer.name }} {{ row.buyer.lastname }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Creado">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.created_at }}
              </ng-template>
            </ngx-datatable-column>

            <!-- Buttons -->
            <ngx-datatable-column name="Opciones">
              <ng-template
                #buttonsTemplate
                let-row="row"
                let-value="value"
                ngx-datatable-cell-template>
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  aria-label="Example icon-button with a menu">
                  <i class="fas fa-ellipsis-v"></i>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="openDialog(row, 'edit')">
                    <i class="fas fa-edit mx-2"></i>
                    <span>Editar</span>
                  </button>
                  <button mat-menu-item (click)="delete(row.id)">
                    <i class="fas fa-trash-alt mx-2"></i>
                    <span>Borrar</span>
                  </button>
                  <button
                    mat-menu-item
                    (click)="addOrder(row)"
                    *ngIf="row?.order_status?.id >= 2">
                    <i class="fas fa-file-alt mx-2"></i>
                    <span
                      >{{
                        row?.order_status?.id > 2 ||
                        (row?.purchase_lab_orders?.length > 0 ||
                          row?.purchase_lab_orders[0]?.approved)
                          ? "Ver Orden de Descarga"
                          : "Orden de Descarga"
                      }}
                    </span>
                  </button>
                  <button mat-menu-item (click)="openCosting(row)">
                    <i class="fas fa-money-check-alt mx-2"></i>
                    <span>Costos</span>
                  </button>
                  <button mat-menu-item (click)="openDocModal(row)">
                    <i class="fas fa-file-pdf mx-2"></i>
                    <span>Evidencias</span>
                  </button>
                </mat-menu>
              </ng-template>
            </ngx-datatable-column>

            <!-- Footer Table -->
            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible">
                <div class="page-count hide">{{ rowCount }} total</div>
                <div>
                  <form [formGroup]="form">
                    <div>
                      <div
                        class="flex justify-content-between align-items-center">
                        <label
                          class="flex align-items-center mx-4 my-2"
                          for="rowsPerPage"
                          >Mostrar por página:
                        </label>

                        <select
                          class="rounded form-control mx"
                          formControlName="rowsPerPage"
                          (change)="onLimitChange($event)"
                          id="rowsPerPage"
                          name="rowsPerPage">
                          <option [value]="5">5</option>
                          <option [value]="10">10</option>
                          <option [value]="25">25</option>
                          <option [value]="50">50</option>
                        </select>
                      </div>
                    </div>
                  </form>
                </div>

                <datatable-pager
                  [pagerLeftArrowIcon]="'datatable-icon-left'"
                  [pagerRightArrowIcon]="'datatable-icon-right'"
                  [pagerPreviousIcon]="'datatable-icon-prev'"
                  [pagerNextIcon]="'datatable-icon-skip'"
                  [page]="curPage"
                  [size]="pageSize"
                  [count]="rowCount"
                  (change)="table.onFooterPage($event)">
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
