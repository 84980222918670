import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";
import { ModalConfirmationComponent } from "../modals/modal-confirmation/modal-confirmation.component";
import { FormComponent } from "./form/form.component";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ClientsService } from "app/apiServices/clients.service";

@Component({
  selector: "app-transportist-route-price",
  templateUrl: "./transportist-route-price.component.html",
  styleUrls: ["./transportist-route-price.component.css"],
})
export class TransportistRoutePriceComponent implements OnInit {
  rows: any[] = [];

  temp: any[] = [];
  showAlert = false;
  alertTitle: string;
  form: FormGroup;

  @ViewChild(DatatableComponent) table: DatatableComponent;
  ColumnMode = ColumnMode;
  columnMapValues = [
    ["id", "id"],
    ["producto", "product"],
    ["ruta", "route"],
    ["transportista", "data"],
    ["costo", "cost"],
    ["costroDeDistribucion", "distribution_cost"],
    ["estatus", "active"],
  ] as const;
  columnMap: Map<string, string> = new Map(this.columnMapValues);
  loading: boolean = false;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private apiService: ClientsService
  ) {
    this.form = this.fb.group({
      rowsPerPage: [10],
    });
    this.temp = this.rows;
  }

  ngOnInit() {
    this.getData();
  }

  getData(): void {
    this.loading = true;
    let PRT: any[] = [];
    let companies: any[] = [];

    let objectsToPush: any[] = [];

    this.apiService.readPRT().subscribe(
      (r) => {
        //console.log(r.data)
        r.data.forEach((price) => {
          if (price.active) {
            PRT.push(price);
          }
        });

        this.apiService.readCompanies().subscribe(
          (r) => {
            this.loading = false;
            companies = r.data;
            PRT.forEach((price) => {
              companies.forEach((company) => {
                if (company.transportists.length >= 1) {
                  if (price.transportist.id == company.transportists[0].id) {
                    price["data"] = company;
                    objectsToPush.push(price);
                  }
                }
              });
            });
            this.rows = objectsToPush;
            this.temp = this.rows;
          },
          () => {
            this.loading = false;
          }
        );
      },
      () => {
        this.loading = false;
      }
    );
  }

  updateFilter(event: Event) {
    const val: string = (event.target as HTMLInputElement).value.toLowerCase();
    const temp: any[] = this.temp.filter((d: any) => {
      return (
        d.data.legal_name.toLowerCase().indexOf(val) !== -1 ||
        d.product.name.toLowerCase().indexOf(val) !== -1 ||
        d.route.name.toLowerCase().indexOf(val) !== -1 ||
        !val
      );
    });
    this.rows = temp;
    this.table.offset = 0;
  }

  openDialog(data: any, action: string) {
    this.alertTitle =
      action == "store" ? "Ruta agregada con éxito" : "Ruta editada con éxito";

    const dialogRef = this.dialog.open(FormComponent, {
      width: "600px",
      data: {
        action,
        data,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getData();
    });
  }

  openDialogDelete(id: number) {
    const dialogRef = this.dialog.open(ModalConfirmationComponent, {
      width: "600px",
      data: {
        title: "¿Estas seguro de desactivar este precio?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.apiService.deletePRT(id).subscribe((r) => {
          this.getData();
        });
      }
    });
  }

  onLimitChange(event: Event): void {
    const limit = (event.target as HTMLSelectElement).value;
    this.changePageLimit(limit);
    this.table.limit = this.form.controls.rowsPerPage.value;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor(
          (this.table.rowCount - 1) / this.table.limit
        );
      }
    });
  }

  onSort(event: any): void {
    const sort = event.sorts[0];
    const column = this.columnMap.get(sort.prop);

    if (column) {
      this.rows.sort((a, b) => {
        if (typeof a[column] === "number" || typeof a[column] === "boolean") {
          if (sort.dir === "asc") return a[column] - b[column];
          else return b[column] - a[column];
        } else if (typeof a[column] === "string") {
          return (
            a[column].localeCompare(b[column]) * (sort.dir === "desc" ? -1 : 1)
          );
        } else if (typeof a[column] === "object") {
          if (column === "product" || column === "route") {
            return (
              a[column].name.localeCompare(b[column].name) *
              (sort.dir === "desc" ? -1 : 1)
            );
          } else {
            return (
              a[column].legal_name.localeCompare(b[column].legal_name) *
              (sort.dir === "desc" ? -1 : 1)
            );
          }
        }
      });

      this.rows = [...this.rows];
    }
  }

  changePageLimit(limit: any): void {
    this.form.controls.rowsPerPage.setValue(parseInt(limit, 10));
  }

  closeAlert(event: boolean) {
    this.showAlert = event;
  }

  delete(roleId: number) {
    this.openDialogDelete(roleId);
  }
}
