import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-delete-lab-detail-product',
  templateUrl: './delete-lab-detail-product.component.html',
  styleUrls: ['./delete-lab-detail-product.component.css']
})
export class DeleteLabDetailProductComponent {
  indexproduct:number;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DeleteLabDetailProductComponent>
  ){
    this.indexproduct = data.productIndex;
  }

  confirmDelete(): void {
    this.dialogRef.close(true);
  }

  close(): void {
    this.dialogRef.close();
  }


}
