import { Component, OnInit, OnDestroy} from '@angular/core';
import { ClientsService } from 'app/apiServices/clients.service';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Company } from 'app/models/company';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { FormValidatorService } from "app/core/service/form-validator.service";
@Component({
  selector: 'app-invoice-filter',
  templateUrl: './invoice-filter.component.html',
  styleUrls: ['./invoice-filter.component.css']
})
export class InvoiceFilterComponent implements OnInit, OnDestroy {
  objects: Company[];
  objectsSuppliers: Company[]
  selectedCompany: Company;
  rows = [];
  selected = [];
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  company_id = new FormControl('', [Validators.required]);
  supplier_id = new FormControl('', [Validators.required])
  constructor(public apiService: ClientsService,
    public formService: FormValidatorService) { }

  ngOnInit(): void {
    this.getData();

  }

  getData():void {
    this.apiService.readCompanies().subscribe((r) => {
      this.objects = r.data;
      this.objectsSuppliers = r.data.filter(company => company.suppliers.length > 0);
      console.log(this.objects);
      console.log(this.objectsSuppliers); 
    },
    (error) => {});
  }
  
  getSelectedCompany(id: number): void {
    this.selectedCompany = this.objects.find(company => company.id === id);
    console.log(this.selectedCompany);
  }

  updateFilter(event: Event) {
    /*const val: string = (event.target as HTMLInputElement).value.toLowerCase();
    const temp: Tank[] = this.tanks.filter((d: Tank) => {
      return d.id.toString().indexOf(val) !== -1 ||
            d.tank.toLowerCase().indexOf(val) !== -1 ||
            d.products[0]?.name.toLowerCase().indexOf(val) !== -1 ||
            !val;
    });
    this.temp = temp;
    this.table.offset = 0;*/
  }

  onActivate(event) {
    console.log('Activate Event', event);
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  ngOnDestroy(): void {
      
  }
}
