import { Component, OnInit } from "@angular/core";
import { HAMMER_LOADER } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { AuthService } from "app/core/auth.service";
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FormValidatorService } from "app/core/service/form-validator.service";
import { CookieService } from "ngx-cookie-service";
import { UserServicesService } from "app/apiServices/user-services.service";
import { NotificationsComponent } from "app/components/alerts/notifications/notifications.component";
import { DataService } from "app/services/data.service";
import { CatalogServicesService } from "app/apiServices/catalog-services.service";
import { Category } from "app/models/category";
import { View } from "app/models/view";
import { RoleViews } from "app/models/role-view";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
  password: string;
  username: string;

  user: any = {};
  formGroup: FormGroup;

  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";

  validationMessages = {
    email: [{ type: "required", message: "Ingrese su correo de Usuario" }],
    password: [{ type: "required", message: "Ingrese su Contraseña" }],
  };

  constructor(
    private router: Router,
    private auth: AuthService,
    private alert: MatSnackBar,
    private fb: FormBuilder,
    public apiService: UserServicesService,
    private categoryService: CatalogServicesService,
    public formService: FormValidatorService,
    private dataService: DataService,
    private _snack: MatSnackBar
  ) {
    this.formGroup = this.createForm();
  }

  ngOnInit(): void {}

  createForm(): FormGroup {
    return this.fb.group({
      email: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
  }

  logIn(): void {
    if (this.formGroup.invalid) return;

    this.user = this.formGroup.value;

    this.apiService.login(this.user).subscribe(
      (r) => {
        localStorage.setItem("token", r.data.token);
        this.dataService.setLoggedValue(localStorage.getItem("token") !== null);
        this.getUserData();
      },
      (err) => {
        this.openSnack("snack-error", err.error.message);
      }
    );
  }

  getUserData(): void {
    this.apiService.getMyself().subscribe(
      (res) => {
        this.dataService.setUserValue(res.data);
        this.getRoleViews();
      },
      (err) => {
        this.openSnack("snack-error", "No se ha podido obtener la información del usuario.");
      }
    );
  }

  private getRoleViews(): void {
    this.categoryService.readRoleViews().subscribe(
      (res) => {
        const currentRoleViews = res.data.find(
          (role: RoleViews) =>
            role.id === this.dataService.userValue.roles[0].id
        );
        const currentCategories = currentRoleViews.views.map(
          (view: View) => view.category
        );
        const sortedCategories =
          this.distinctAndSortCategories(currentCategories);
        const categories = this.viewsByCategory(sortedCategories, currentRoleViews.views);

        if (categories.length > 0 && categories[0].views.length > 0) {
          const firstView = categories[0].views[0];
          this.redirection(firstView.path);
        } else {
          this.openSnack("snack-error", "No tiene permisos.");
        }

      },
      (error) => {
        this.openSnack("snack-error", "No se ha podido obtener la información del usuario.");
      }
    );
  }

  redirection(path: string): void {
    if (this.auth.getAuthToken()) this.router.navigate([path]);
  }

  showAlert(message: string) {
    const config = {
      duration: 5000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    };
    this.alert.open(message, "Cerrar", config);
  }

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  distinctAndSortCategories(categories: Category[]): Category[] {
    const result = [];
    const map = new Map();
    for (const item of categories) {
      if (!map.has(item.id)) {
        map.set(item.id, true);
        result.push({
          id: item.id,
          name: item.name,
          order: item.order,
        });
      }
    }

    return result.sort((a: Category, b: Category) => {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    });
  }

  viewsByCategory(categories: Category[], views: View[]): Category[] {
    categories.forEach((category) => {
      category.views = views.filter(
        (view: View) => view.category.id === category.id &&
        !view.path.includes('existence-types')
      );

      category.views.sort((a: View, b: View) => {
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      });
    });

    this.dataService.setCategoriesValue(categories);
    return categories;
  }
}
