export class Role {
  id?: number;
  name?: string;
  description?: string;
  acive?: boolean;

  constructor(role: Role) {
    {
      this.id = role.id || undefined;
      this.name = role.name || '';
      this.description = role.description || '';
      this.acive = role.acive || false;
    }
  }
}
