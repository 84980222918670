<div class="">
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-200 border-0">
    <div class="relative flex flex-col min-w-0 break-words w-full rounded">
      <div
        class="grid grid-cols-2 gap-2 rounded-t mb-0 px-4 py-3 border-0 pt-6">
        <div class="basis-1/2 flex justify-end">
          <button
            class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-sm px-6 py-3 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            *ngIf="addAuthorizedPrices"
            type="button"
            (click)="openDialog(null, 'store')">
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>

      <div class="grid">
        <div class="block w-full overflow-x-auto rounded-lg">
          <ngx-datatable
            #table
            class="material"
            [columnMode]="ColumnMode.force"
            [headerHeight]="50"
            [footerHeight]="50"
            rowHeight="auto"
            [limit]="10"
            [rows]="rows"
            [loadingIndicator]="loading"
            [scrollbarH]="true"
            (sort)="onSort($event)">
            <ngx-datatable-column name="Fecha">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.created_at }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Usuario">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.user }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Productos">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.productsDate.length }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Opciones" [sortable]="false">
              <ng-template
                #buttonsTemplate
                let-row="row"
                let-value="value"
                ngx-datatable-cell-template>
                <div class="flex">
                  <button
                    class="bg-emerald-500 text-white px-2 py-1 rounded-full text-center"
                    (click)="openDialog(row, 'edit')">
                    <i class="fas fa-eye"> </i>
                  </button>
                </div>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
