<div>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-200 border-0"
  >
    <div class="relative flex flex-col min-w-0 break-words w-full rounded">
      <div
        class="grid grid-cols-2 gap-2 rounded-t mb-0 px-4 py-3 border-0 pt-6"
      >
        <div class="basis-1/2 flex justify-start">
          <span
            class="h-full leading-snug font-normal absolute text-center text-slate-300 bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3"
          >
            <i class="fa fa-search"></i>
          </span>
          <input
            type="text"
            class="w-48 pl-12 py-3 rounded-full border text-sm border-gray-300 focus:outline-none focus:border-blue-400"
            (keyup)="updateFilter($event)"
            placeholder="Buscar"
          />
        </div>
      </div>
      <div class="grid">
        <div class="block w-full overflow-x-auto rounded-lg">
          <ngx-datatable
            #table
            class="material"
            [rows]="rows"
            [columnMode]="ColumnMode.force"
            [headerHeight]="50"
            [footerHeight]="50"
            rowHeight="auto"
            [loadingIndicator]="loading"
            [externalPaging]="true"
            [count]="page.totalElements"
            [offset]="page.pageNumber"
            [limit]="page.size"
            [scrollbarH]="true"
            (page)="setPage($event)"
            (sort)="onSort($event)"
          >
          <ngx-datatable-column name="Id">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.id }}
            </ng-template>
          </ngx-datatable-column>


            <ngx-datatable-column name="Usuario">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.user?.email }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Tabla">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.table }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Tipo">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.type }}
              </ng-template>
            </ngx-datatable-column>

              <ngx-datatable-column name="Creado">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.created_at | date: "yyyy-MM-dd HH:mm:ss" }}
                </ng-template>
              </ngx-datatable-column>

            <ngx-datatable-column name="Ver diferencia" [sortable]="false">
              <ng-template
                #buttonsTemplate
                let-row="row"
                let-value="value"
                ngx-datatable-cell-template
              >
                <div class="flex">
                  <button
                    class="bg-emerald-500 text-white px-2 py-1 rounded-full text-center"
                    (click)="show(row)"
                  >
                    <i class="far fa-eye"></i>
                  </button>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible"
              >
                <div class="page-count hide">{{ rowCount }} total</div>

                <div>
                  <form [formGroup]="form">
                    <div>
                      <div
                        class="flex justify-content-between align-items-center"
                      >
                        <label
                          class="flex align-items-center mx-4 my-2"
                          for="rowsPerPage"
                          >Mostrar por página:
                        </label>

                        <select
                          class="rounded form-control mx"
                          formControlName="rowsPerPage"
                          (change)="onLimitChange($event)"
                          id="rowsPerPage"
                          name="rowsPerPage"
                        >
                          <option [value]="5">5</option>
                          <option [value]="10">10</option>
                          <option [value]="25">25</option>
                          <option [value]="50">50</option>
                        </select>
                      </div>
                    </div>
                  </form>
                </div>

                <datatable-pager
                  [pagerLeftArrowIcon]="'datatable-icon-left'"
                  [pagerRightArrowIcon]="'datatable-icon-right'"
                  [pagerPreviousIcon]="'datatable-icon-prev'"
                  [pagerNextIcon]="'datatable-icon-skip'"
                  [page]="curPage"
                  [size]="pageSize"
                  [count]="rowCount"
                  (change)="table.onFooterPage($event)"
                >
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
