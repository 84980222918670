export class Service {
    id?: number;
    name?: string;
    unit_key?: string;
    iva?: number;
    iva_retain?: number;
    isr?: number;
    isr_retain?: number;
    clave_sat?: string;
    obj_tax?: number;
    observations?: string;

    constructor(service: Service) {
      this.id = service.id || undefined;
      this.name = service.name || "";
      this.unit_key = service.unit_key || "";
      this.iva = service.iva || undefined;
      this.iva_retain = service.iva_retain || undefined;
      this.isr = service.isr || undefined;
      this.isr_retain = service.isr_retain || undefined;
      this.clave_sat = service.clave_sat || "";
      this.obj_tax = service.obj_tax || undefined;
      this.observations = service.observations || "";
    }
  }
