<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-200 border-0">
  <div class="relative flex flex-col min-w-0 break-words w-full rounded">
    <div class="grid">
      <div class="block w-full overflow-x-auto rounded-lg">

        <ngx-datatable
          #table class="material" [columnMode]="ColumnMode.force"
          [headerHeight]="50" [footerHeight]="50" rowHeight="auto"
          [limit]="10" [rows]="data">

          <ngx-datatable-column name="Id">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.id }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Producto">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.product.name }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Costo por litro">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.cost_per_liter }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-footer>
            <ng-template
              ngx-datatable-footer-template
              let-rowCount="rowCount"
              let-pageSize="pageSize"
              let-selectedCount="selectedCount"
              let-curPage="curPage"
              let-offset="offset"
              let-isVisible="isVisible">

              <div class="page-count">{{ rowCount }} total</div>

              <div>
                <form [formGroup]="form">
                  <div>
                    <div
                      class="flex justify-content-between align-items-center">
                      <label
                        class="flex align-items-center mx-4 my-2"
                        for="rowsPerPage"
                        >Mostrar por página:
                      </label>

                      <select
                        class="rounded form-control mx-25"
                        formControlName="rowsPerPage"
                        (change)="onLimitChange($event)"
                        id="rowsPerPage"
                        name="rowsPerPage">
                        <option [value]="5">5</option>
                        <option [value]="10">10</option>
                        <option [value]="25">25</option>
                        <option [value]="50">50</option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>

              <datatable-pager
                [pagerLeftArrowIcon]="'datatable-icon-left'"
                [pagerRightArrowIcon]="'datatable-icon-right'"
                [pagerPreviousIcon]="'datatable-icon-prev'"
                [pagerNextIcon]="'datatable-icon-skip'"
                [page]="curPage"
                [size]="pageSize"
                [count]="rowCount"
                (change)="table.onFooterPage($event)">
              </datatable-pager>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>
