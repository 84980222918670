<div class="bg-white px-4 h-full py-4 overflow-y-auto w-full">
  <div class="justify-between rounded-t mb-0 px-4 py-4 border-0 pt-6 bg-white">
    <div class="text-center">
      <h3 class="font-semibold text-lg">Precios Autorizados</h3>
    </div>
  </div>
  <div>
    <form class="bg-white" [formGroup]="form" style="overflow-y: auto">
      <ng-container formArrayName="authorized_prices">
        <ng-container
          *ngFor="let item of authorized_prices.controls; let i = index"
        >
          <div [formGroup]="item">
            <div class="relative w-full mb-3 flex overflow-auto px-2">
              <!-- PRODUCTO -->
              <div class="container px-2">
                <label
                  class="sm:w-auto block uppercase text-slate-600 text-xs font-bold mb-2"
                  data-te-toggle="tooltip"
                  [title]="item.controls.name.value"
                  for="name"
                >
                  Producto
                </label>
                <input
                  type="text"
                  name="name"
                  formControlName="name"
                  data-te-toggle="tooltip"
                  [title]="item.controls.name.value"
                  [ngClass]="{
                    'border-red-500': item.controls.name.invalid,
                    'focus:border-red-500': item.controls.name.invalid,
                    'focus:ring-red-500': item.controls.name.invalid
                  }"
                  class="w-auto md:w-full border-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-gray-200 rounded border-gray-300 text-sm focus:outline-none focus:ring ease-linear transition-all duration-150"
                />
              </div>
              <!-- Costo de Compra -->
              <div class="container px-4">
                <label
                  class="sm:w-auto block uppercase text-slate-600 text-xs font-bold mb-2"
                  for="list_price"
                >
                  Costo promedio
                </label>
                <input
                  type="text"
                  name="list_price"
                  formControlName="list_price"
                  [ngClass]="{
                    'border-red-500': item.controls.list_price.invalid,
                    'focus:border-red-500': item.controls.list_price.invalid,
                    'focus:ring-red-500': item.controls.list_price.invalid,
                    'bg-gray-200': item.controls.list_price.disabled,
                    'bg-white': !item.controls.list_price.disabled
                  }"
                  class="w-auto md:w-full border-2 px-3 py-3 placeholder-slate-300 text-slate-600 rounded border-gray-300 text-sm focus:outline-none focus:ring ease-linear transition-all duration-150"
                  prefix="$ "
                  mask="separator.6"
                  (change)="onLeadingZero($event)"
                />
              </div>

              <!-- Costo Físico Ponderado -->
              <div class="md:container px-4">
                <label
                  class="sm:w-auto block uppercase text-slate-600 text-xs font-bold mb-2"
                  for="list_price"
                >
                  Costo promedio físico
                </label>
                <input
                  type="text"
                  name="physical_cost"
                  formControlName="physical_cost"
                  [ngClass]="{
                    'border-red-500': item.controls.physical_cost.invalid,
                    'focus:border-red-500': item.controls.physical_cost.invalid,
                    'focus:ring-red-500': item.controls.physical_cost.invalid,
                    'bg-gray-200': item.controls.physical_cost.disabled,
                    'bg-white': !item.controls.physical_cost.disabled
                  }"
                  class="w-auto md:w-full border-2 px-3 py-3 placeholder-slate-300 text-slate-600 rounded border-gray-300 text-sm focus:outline-none focus:ring ease-linear transition-all duration-150"
                  prefix="$ "
                  mask="separator.6"
                  (change)="
                    onCalculateRecommendedPrice($event, 'physical_cost', i)
                  "
                />
              </div>

              <!-- Costo de Abastecimiento -->
              <div class="container px-4">
                <label
                  class="sm:w-auto block uppercase text-slate-600 text-xs font-bold mb-2"
                  for="list_price"
                >
                  Costo reposición
                </label>
                <input
                  type="text"
                  name="supply_price"
                  formControlName="supply_price"
                  [ngClass]="{
                    'border-red-500': item.controls.supply_price.invalid,
                    'focus:border-red-500': item.controls.supply_price.invalid,
                    'focus:ring-red-500': item.controls.supply_price.invalid,
                    'bg-gray-200': item.controls.supply_price.disabled,
                    'bg-white': !item.controls.supply_price.disabled
                  }"
                  class="w-auto md:w-full border-2 px-3 py-3 placeholder-slate-300 text-slate-600 rounded border-gray-300 text-sm focus:outline-none focus:ring ease-linear transition-all duration-150"
                  prefix="$ "
                  mask="separator.6"
                  (change)="onLeadingZero($event)"
                />
              </div>

              <!-- Utilidad -->
              <div class="container px-2">
                <label
                  class="sm:w-auto block uppercase text-slate-600 text-xs font-bold mb-2"
                  for="recommended_price"
                >
                  Costo operativo
                </label>
                <input
                  type="text"
                  name="operative_cost"
                  formControlName="operative_cost"
                  [ngClass]="{
                    'border-red-500': item.controls.operative_cost.invalid,
                    'focus:border-red-500': item.controls.operative_cost.invalid,
                    'focus:ring-red-500': item.controls.operative_cost.invalid,
                    'bg-gray-200': item.controls.operative_cost.disabled,
                    'bg-white': !item.controls.operative_cost.disabled
                  }"
                  class="w-auto md:w-full border-2 px-3 py-3 placeholder-slate-300 text-slate-600 rounded border-gray-300 text-sm focus:outline-none focus:ring ease-linear transition-all duration-150"
                  prefix="$ "
                  mask="separator.6"
                  (change)="
                    onCalculateRecommendedPrice($event, 'operative_cost', i)
                  "
                />
              </div>

              <div class="container px-2">
                <label
                  class="block uppercase text-slate-600 text-xs font-bold mb-2"
                  for="recommended_price"
                >
                  Utilidad
                </label>
                <input
                  type="text"
                  name="profit_margin"
                  formControlName="profit_margin"
                  [ngClass]="{
                    'border-red-500': item.controls.profit_margin.invalid,
                    'focus:border-red-500': item.controls.profit_margin.invalid,
                    'focus:ring-red-500': item.controls.profit_margin.invalid,
                    'bg-gray-200': item.controls.profit_margin.disabled,
                    'bg-white': !item.controls.profit_margin.disabled
                  }"
                  class="w-auto md:w-full border-2 px-3 py-3 placeholder-slate-300 text-slate-600 rounded border-gray-300 text-sm focus:outline-none focus:ring ease-linear transition-all duration-150"
                  prefix="$ "
                  mask="separator.6"
                  (change)="
                    onCalculateRecommendedPrice($event, 'profit_margin', i)
                  "
                />
              </div>

              <!-- PRECIO RECOMENDADO -->
              <div class="container px-2">
                <label
                  class="sm:w-auto block uppercase text-slate-600 text-xs font-bold mb-2"
                  for="recommended_price"
                >
                  Precio recomendado
                </label>
                <input
                  type="text"
                  name="recommended_price"
                  formControlName="recommended_price"
                  [ngClass]="{
                    'border-red-500': item.controls.recommended_price.invalid,
                    'focus:border-red-500':
                      item.controls.recommended_price.invalid,
                    'focus:ring-red-500':
                      item.controls.recommended_price.invalid,
                    'bg-gray-200': item.controls.recommended_price.disabled,
                    'bg-white': !item.controls.recommended_price.disabled
                  }"
                  class="w-auto md:w-full border-2 px-3 py-3 placeholder-slate-300 text-slate-600 rounded border-gray-300 text-sm focus:outline-none focus:ring ease-linear transition-all duration-150"
                  prefix="$ "
                  mask="separator.2"
                  (change)="onLeadingZero($event)"
                />
              </div>

              <!-- PRECIO DEL DIA -->
              <div class="container px-2">
                <label
                  class="sm:w-auto block uppercase text-slate-600 text-xs font-bold mb-2"
                  for="authorized_price"
                >
                  Precio del dia
                </label>
                <input
                  type="text"
                  name="authorized_price"
                  formControlName="authorized_price"
                  [ngClass]="{
                    'border-red-500': item.controls.authorized_price.invalid,
                    'focus:border-red-500':
                      item.controls.authorized_price.invalid,
                    'focus:ring-red-500':
                      item.controls.authorized_price.invalid,
                    'bg-gray-200': item.controls.authorized_price.disabled,
                    'bg-white': !item.controls.authorized_price.disabled
                  }"
                  class="w-auto md:w-full border-2 px-3 py-3 placeholder-slate-300 text-slate-600 rounded border-gray-300 text-sm focus:outline-none focus:ring ease-linear transition-all duration-150"
                  prefix="$ "
                  mask="separator.6"
                  (change)="onLeadingZero($event)"
                />
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </form>
    <!-- Buttons -->
    <div class="flex w-full justify-end py-4">
      <button
        class="bg-slate-500 text-white active:bg-slate-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        (click)="closeModal()"
      >
        Cancelar
      </button>

      <button
        class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        [ngClass]="{ disabled: form.invalid }"
        (click)="save()"
        *ngIf="!edit"
      >
        Guardar
      </button>

      <button
        class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        [ngClass]="{ disabled: form.invalid }"
        (click)="save()"
        *ngIf="edit && today"
      >
        Actualizar
      </button>
    </div>
  </div>
</div>
