<div class="container bg-white mx-auto px-4 h-full py-4">
    <div class="justify-between rounded-t mb-0 px-4 py-3 border-0 pt-6 bg-white">
        <div class="text-center">
            <h3 class="font-semibold text-lg">
                Formulario de Vista
            </h3>
        </div>
    </div>
    <form class="bg-white" [formGroup]="form">

        <div class="relative w-full mb-3 flex">
            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="name">
                    Nombre
                  </label>
                  <input type="text" name="name" formControlName="name"
                    [ngClass]="{ 
                        'border-red-500': form.controls.name.invalid, 
                        'focus:border-red-500': form.controls.name.invalid, 
                        'focus:ring-red-500': form.controls.name.invalid 
                    }"
                    class="border-2 px-3 py-3
                    placeholder-slate-300
                    text-slate-600 bg-white rounded border-gray-300
                    text-sm focus:outline-none focus:ring
                    w-full ease-linear transition-all duration-150" />
                  <div *ngFor="let validation of validationMessages.name">
                    <span *ngIf="formService.field(form, validation.type, 'name')" class="mt-2 text-sm text-red-500">
                      {{ validation.message }}
                    </span>
                </div>
            </div>
            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="order">
                    Orden
                </label>
                <input type="number" name="order" formControlName="order"
                [ngClass]="{ 
                    'border-red-500': form.controls.order.invalid, 
                    'focus:border-red-500': form.controls.order.invalid, 
                    'focus:ring-red-500': form.controls.order.invalid 
                }"
                class="border-2 px-3 py-3
                placeholder-slate-300
                text-slate-600 bg-white rounded border-gray-300
                text-sm focus:outline-none focus:ring
                w-full ease-linear transition-all duration-150"/>
                <div *ngFor="let validation of validationMessages.order">
                    <span *ngIf="formService.field(form, validation.type, 'order')" class="mt-2 text-sm text-red-500">
                        {{ validation.message }}
                    </span>
                </div>
            </div>
        </div>

        <div class="relative w-full mb-3 flex">
            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="category_id">
                    Categoria
                </label>
                <select name="category_id" formControlName="category_id"
                  [ngClass]="{
                  'border-red-500': form.controls.category_id.invalid,
                  'focus:border-red-500': form.controls.category_id.invalid,
                  'focus:ring-red-500': form.controls.category_id.invalid
                }"
                class="border-2 px-3 py-3
                placeholder-slate-300
                text-slate-600 bg-white rounded border-gray-300
                text-sm focus:outline-none focus:ring
                w-full ease-linear transition-all duration-150">
                    <option *ngFor="let item of categories" [ngValue]="item.id">{{ item.name }}</option>
                </select>
            </div>
            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="icon">
                    Icono
                </label>
                <input type="text" name="icon" formControlName="icon"
                  [ngClass]="{
                  'border-red-500': form.controls.icon.invalid,
                  'focus:border-red-500': form.controls.icon.invalid,
                  'focus:ring-red-500': form.controls.icon.invalid
                }"
                class="border-2 px-3 py-3
                placeholder-slate-300
                text-slate-600 bg-white rounded border-gray-300
                text-sm focus:outline-none focus:ring
                w-full ease-linear transition-all duration-150">
                <div *ngFor="let validation of validationMessages.icon">
                    <span *ngIf="formService.field(form, validation.type, 'icon')" class="mt-2 text-sm text-red-500">
                        {{ validation.message }}
                    </span>
                </div>
            </div>
        </div>
        
        <div class="relative w-full mb-3 flex">
            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="path">
                    Ruta
                </label>
                <input type="text" name="path" formControlName="path"
                  [ngClass]="{
                  'border-red-500': form.controls.path.invalid,
                  'focus:border-red-500': form.controls.path.invalid,
                  'focus:ring-red-500': form.controls.path.invalid
                }"
                class="border-2 px-3 py-3
                placeholder-slate-300
                text-slate-600 bg-white rounded border-gray-300
                text-sm focus:outline-none focus:ring
                w-full ease-linear transition-all duration-150">
                <div *ngFor="let validation of validationMessages.path">
                    <span *ngIf="formService.field(form, validation.type, 'path')" class="mt-2 text-sm text-red-500">
                        {{ validation.message }}
                    </span>
                </div>
            </div>
            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="component">
                    Componente
                </label>
                <input type="text" name="component" formControlName="component"
                  [ngClass]="{
                  'border-red-500': form.controls.component.invalid,
                  'focus:border-red-500': form.controls.component.invalid,
                  'focus:ring-red-500': form.controls.component.invalid
                }"
                class="border-2 px-3 py-3
                placeholder-slate-300
                text-slate-600 bg-white rounded border-gray-300
                text-sm focus:outline-none focus:ring
                w-full ease-linear transition-all duration-150">
                <div *ngFor="let validation of validationMessages.component">
                    <span *ngIf="formService.field(form, validation.type, 'component')" class="mt-2 text-sm text-red-500">
                        {{ validation.message }}
                    </span>
                </div>
            </div>
        </div>

        <div class="flex w-full justify-end">
            <button
                class="bg-slate-500 text-white 
                active:bg-slate-500 font-bold uppercase 
                text-xs px-4 py-2 rounded shadow hover:shadow-md 
                outline-none focus:outline-none mr-1 ease-linear 
                transition-all duration-150"
                (click)="closeModal()">
                Cancelar
            </button>
            <button
                class="bg-sky-600 text-white 
                active:bg-sky-600 font-bold uppercase 
                text-xs px-4 py-2 rounded shadow hover:shadow-md 
                outline-none focus:outline-none mr-1 ease-linear 
                transition-all duration-150"
                [ngClass]="{ 'disabled:opacity-25': form.invalid }" [disabled]="form.invalid" (click)="save()">
                {{ edit ? 'Actualizar' : 'Agregar' }}
            </button>
        </div>
    </form>
</div>