import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from "@angular/common";
import { ClientsService } from 'app/apiServices/clients.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ColumnMode, DatatableComponent, id } from '@swimlane/ngx-datatable';
import { ModalConfirmationComponent } from 'app/components/modals/modal-confirmation/modal-confirmation.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.css']
})
export class AddressesComponent implements OnInit{
  object:any
  addresses: any[] = []

  form: FormGroup

  @ViewChild(DatatableComponent) table: DatatableComponent
  ColumnMode = ColumnMode
  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    public addService: ClientsService,
    public fb: FormBuilder,
    public dialog: MatDialog
  ) {
    if(this.route.queryParams){
      this.route.queryParams.subscribe(params =>{
        this.object = params
      })

      this.form = this.fb.group({
        rowsPerPage: [10]
      })
    } else{
      this.router.navigate(['admin/categories'])
    }
  }

  ngOnInit(): void {
    this.getData()
  }

  getData(){
    var temp:any[] = []
    var objectsToPush: any[] = []
    this.addService.getAddress().subscribe(r => {
      temp = r.data
      temp.forEach(address => {
        if(address.company.id == this.object.id && address.active)
          objectsToPush.push(address)
      })
      this.addresses = objectsToPush
    })
  }

  onLimitChange(event: Event): void {
    const limit = (event.target as HTMLSelectElement).value;
    this.changePageLimit(limit);
    this.table.limit = this.form.controls.rowsPerPage.value;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor(
          (this.table.rowCount - 1) / this.table.limit
        );
      }
    });
  }

  changePageLimit(limit: any): void {
    this.form.controls.rowsPerPage.setValue(parseInt(limit, 10));
  }

  return(){
    this.location.back()
  }

  delete(id: number){
    this.openDialogDelete(id);
  }

  openDialogDelete(id: number){
    const dialogRef = this.dialog.open(ModalConfirmationComponent, {
      width: "600px",
      data: {
        title: "¿Estas seguro de desactivar esta direccion?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if(result){
        this.addService.deleteAddress(id).subscribe({
          next: (response) =>{
            this.addresses = []
            this.getData()
          }
        })
      }
    })
  }
}
