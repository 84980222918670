import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DatatableComponent, ColumnMode } from "@swimlane/ngx-datatable";
import { ModalConfirmationComponent } from "app/components/modals/modal-confirmation/modal-confirmation.component";
import { Role } from "app/models/role";
import { RoleView } from "app/models/role-view";
import { FormComponent } from "./dialogs/form/form.component";
import { CatalogServicesService } from "app/apiServices/catalog-services.service";
import { Role_View } from "app/models/role_view";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ViewsFormComponent } from "./dialogs/views-form/views-form.component";

@Component({
  selector: "app-roles-views",
  templateUrl: "./roles-views.component.html",
  styleUrls: ["./roles-views.component.css"],
})
export class RolesViewsComponent {
  objects: Role_View[] = [];

  back: Role_View[];
  temp: Role_View[];

  showAlert = false;
  alertTitle: string;
  form: FormGroup;

  @ViewChild(DatatableComponent) table: DatatableComponent;
  ColumnMode = ColumnMode;
  columnMapValues = [
    ["id", "id"],
    ["categoria", "name"],
    ["estatus", "active"],
  ] as const;
  columnMap: Map<string, string> = new Map(this.columnMapValues);
  loading: boolean = false;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    public apiService: CatalogServicesService
  ) {
    this.form = this.fb.group({
      rowsPerPage: [10],
    });
  }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.loading = true;
    this.apiService.readRoleView().subscribe(
      (r) => {
        this.back = r.data;
        this.temp = r.data;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  openViews(data: any) {
    const dialogRef = this.dialog.open(ViewsFormComponent, {
      width: "600px",
      data: {
        data,
      },
    });

    dialogRef.afterClosed().subscribe((r) => {});
  }

  updateFilter(event: Event) {
    const val: string = (event.target as HTMLInputElement).value.toLowerCase();
    const temp: Role_View[] = this.temp.filter((d: any) => {
      //console.log(d)
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.back = temp;
    this.table.offset = 0;
  }

  openDialog(data: Role, action: string) {
    this.alertTitle =
      action == "store"
        ? "Rol Vista agregado con éxito"
        : "Rol Vista editado con éxito";

    const dialogRef = this.dialog.open(FormComponent, {
      width: "600px",
      data: {
        action,
        data,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.objects = [];
      this.back = [];
      this.temp = [];
      this.getData();
    });
  }

  openDialogDelete(roleId: number) {
    const dialogRef = this.dialog.open(ModalConfirmationComponent, {
      width: "600px",
      data: {
        title: "¿Estas seguro de desactivar esta Rol Vista?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getData();
      }
    });
  }

  onLimitChange(event: Event): void {
    const limit = (event.target as HTMLSelectElement).value;
    this.changePageLimit(limit);
    this.table.limit = this.form.controls.rowsPerPage.value;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor(
          (this.table.rowCount - 1) / this.table.limit
        );
      }
    });
  }

  changePageLimit(limit: any): void {
    this.form.controls.rowsPerPage.setValue(parseInt(limit, 10));
  }

  onSort(event: any): void {
    const sort = event.sorts[0];
    const column = this.columnMap.get(sort.prop);

    if (column) {
      this.back.sort((a, b) => {
        if (typeof a[column] === "number") {
          if (sort.dir === "asc") return a[column] - b[column];
          else return b[column] - a[column];
        } else if (typeof a[column] === "string") {
          return (
            a[column].localeCompare(b[column]) * (sort.dir === "desc" ? -1 : 1)
          );
        }
      });

      this.back = [...this.back];
    }
  }

  closeAlert(event: boolean) {
    this.showAlert = event;
  }

  delete(roleId: number) {
    this.openDialogDelete(roleId);
  }
}
