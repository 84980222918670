import { Injectable } from "@angular/core";
import { Category } from "app/models/category";
import { User } from "app/models/user";
import { View } from "app/models/view";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DataService {
  // User Data
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
  private loggedSubject: BehaviorSubject<boolean>;
  public logged: Observable<boolean>;

  // Role categories
  private categoriesSubject: BehaviorSubject<Category[]>;
  public categories: Observable<Category[]>;

  private viewSubject: BehaviorSubject<string>;
  public view: Observable<string>;

  private loadingSubject: BehaviorSubject<boolean>;
  public loading: Observable<boolean>;

  constructor() {
    this.setData();
  }

  private setData(): void {
    this.userSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("user"))
    );
    this.user = this.userSubject.asObservable();
    this.loggedSubject = new BehaviorSubject<boolean>(
      localStorage.getItem("token") != null
    );
    this.logged = this.loggedSubject.asObservable();

    this.categoriesSubject = new BehaviorSubject<Category[]>(
      JSON.parse(localStorage.getItem("categories"))
    );
    this.categories = this.categoriesSubject.asObservable();

    this.viewSubject = new BehaviorSubject<string>(
      localStorage.getItem("table")
    );
    this.view = this.viewSubject.asObservable();

    this.loadingSubject = new BehaviorSubject<boolean>(
      localStorage.getItem("loading") === "true"
    );
    this.loading = this.loadingSubject.asObservable();
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  public setUserValue(user: User): void {
    localStorage.setItem("user", JSON.stringify(user));
    this.userSubject.next(user);
  }

  public get categoriesValue(): Category[] {
    return this.categoriesSubject.value;
  }

  public setCategoriesValue(categories: Category[]): void {
    localStorage.setItem("categories", JSON.stringify(categories));
    this.categoriesSubject.next(categories);
  }

  public get viewValue(): string {
    return this.viewSubject.value;
  }

  public setViewValue(view: string): void {
    localStorage.setItem("table", view);
    this.viewSubject.next(view);
  }

  public get loggedValue(): boolean {
    return this.loggedSubject.value;
  }

  public setLoggedValue(logged: boolean): void {
    this.loggedSubject.next(logged);
  }

  public get loadingValue(): boolean {
    return this.loadingSubject.value;
  }

  public setLoadingValue(loading: boolean): void {
    localStorage.setItem("loading", loading.toString());
    this.loadingSubject.next(loading);
  }

  public clear(): void {
    this.setLoggedValue(false);
    localStorage.clear();
    localStorage.clear();
  }
}
