<div class="">
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-200 border-0">
        <div class="basis-1/2 flex justify-end">
            <button
              class="text-neutral-800
              font-bold uppercase text-sm px-6 py-3 outline-none focus:outline-none
              mr-1 mb-1 ease-linear"
              (click)="return()" type="button">
              <i class="fas fa-backspace"></i>
            </button>
          </div>
      <div class="relative flex flex-col min-w-0 break-words w-full rounded">
          <div class="grid">
          <div class="block w-full overflow-x-auto rounded-lg">
  
            <ngx-datatable #table class="material" [columnMode]="ColumnMode.force" [headerHeight]="50" [footerHeight]="50"
              rowHeight="auto" [limit]="10" [rows]="addresses">
  
              <ngx-datatable-column name="Id" [width]="1">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.id }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Calle" [width]="1">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.street }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Localidad" [width]="1">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.locality.name }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Municipio" [width]="1">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.locality.municipality.name }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Municipio" [width]="1">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.locality.municipality.name }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Estado" [width]="1">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.locality.state.name }}
                </ng-template>
              </ngx-datatable-column>
              
              <ngx-datatable-column name="C.P." [width]="1">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.locality.zip_code }}
                </ng-template>
              </ngx-datatable-column>
  
              <!-- <ngx-datatable-column name="Informacion" [width]="4">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <button class="bg-blue-500 text-white px-2 py-1 rounded-full text-center" (click)="openDialogInfo(row)">
                    <i class="fas fa-info-circle"> </i>
                  </button>
                </ng-template>
              </ngx-datatable-column> -->
  
              <ngx-datatable-column name="Opciones">
                <ng-template #buttonsTemplate let-row="row" let-value="value" ngx-datatable-cell-template>
                  <div class="flex">
                    <button class="bg-red-500 text-white mx-8 px-2 py-1 rounded-full text-center"
                      (click)="delete(row.id)">
                      <i class="fas fa-trash-alt">
                      </i>
                    </button>
                  </div>
                </ng-template>
              </ngx-datatable-column>
  
              <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                  let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
                  <div class="page-count">{{ rowCount }} total</div>
  
                  <div>
                    <form [formGroup]="form">
                      <div>
                        <div class="flex justify-content-between align-items-center">
                          <label class="flex align-items-center mx-4 my-2" for="rowsPerPage">Mostrar por página:
                          </label>
  
                          <select class="rounded form-control mx-25" formControlName="rowsPerPage"
                            (change)="onLimitChange($event)" id="rowsPerPage" name="rowsPerPage">
                            <option [value]="5">5</option>
                            <option [value]="10">10</option>
                            <option [value]="25">25</option>
                            <option [value]="50">50</option>
                          </select>
                        </div>
                      </div>
                    </form>
                  </div>
  
                  <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                    [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                    [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
                    (change)="table.onFooterPage($event)">
                  </datatable-pager>
                </ng-template>
              </ngx-datatable-footer>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>