<div class="">
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 border-0">
            <div class="flex mt-4">
            <div class="container px-2 w-1/2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="role_id">
                    Compañia
                </label>
                <select [(ngModel)]="selectedCompany" name="companie_id"
                [formControl]="company_id"
                class="border-2 px-3 py-3
                placeholder-slate-300
                text-slate-600 bg-white rounded border-gray-300
                text-sm focus:outline-none focus:ring
                w-full ease-linear transition-all duration-150">
                    <option  *ngFor="let item of objects" [ngValue]="item">{{ item.contact_name }}</option>
                </select>
            </div>
            <div class="container px-2 w-1/2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="role_id">
                    Provedor
                </label>
                <select (change)="getSelectedCompany($event.target.value)" name="companie_id"
                class="border-2 px-3 py-3
                placeholder-slate-300
                text-slate-600 bg-white rounded border-gray-300
                text-sm focus:outline-none focus:ring
                w-full ease-linear transition-all duration-150">
                    <option *ngFor="let item of selectedCompany?.suppliers" [ngValue]="item.id">{{item.id}}</option>
                </select>
            </div>
        </div>
        <div class="container mt-4  justify-end jutify-between items-center flex">
            <button class="bg-slate-600 text-white active:bg-slate-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150" type="button">
                <i class="fas fa-trash"></i>
            </button>
            <button class="bg-slate-600 text-white active:bg-slate-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150" type="button">
                <i class="fas fa-search"></i>Buscar
            </button>
        </div>
    </div>
</div>