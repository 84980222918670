import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ServiceService } from "app/apiServices/service.service";
import { NotificationsComponent } from "app/components/alerts/notifications/notifications.component";
import { FormValidatorService } from "app/core/service/form-validator.service";
import { InGlobalRes } from "app/interfaces/globalRes.interface";
import { Service } from "app/models/service";

@Component({
  selector: "app-service-form",
  templateUrl: "./service-form.component.html",
  styleUrls: ["./service-form.component.css"],
})
export class ServiceFormComponent {
  form: FormGroup;
  dataSource: Service;
  edit = false;
  dialogTitle: string;
  validationMessages = {
    name: [{ type: "required", message: "Ingrese un nombre" }],
    unit_key: [{ type: "required", message: "Ingrese una clave de unidad" }],
    iva: [{ type: "required", message: "Ingresa el IVA" }],
    iva_retain: [{ type: "required", message: "Ingresa la retención de IVA" }],
    isr: [{ type: "required", message: "Ingresa el ISR" }],
    isr_retain: [{ type: "required", message: "Ingresa la retención del ISR" }],
    clave_sat: [{ type: "required", message: "Ingresa la clave SAT" }],
    obj_tax: [{ type: "required", message: "Ingresa el objeto impuesto" }],
  };

  constructor(
    private dialogRef: MatDialogRef<ServiceFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private _snack: MatSnackBar,
    public formService: FormValidatorService,
    public apiService: ServiceService
  ) {
    this.data = data;

    if (data.action === "edit") {
      this.dialogTitle = "Editar Servicio";
      this.dataSource = data.data;
      this.edit = true;
    } else {
      this.dialogTitle = "Agregar un servicio";
      this.dataSource = new Service({});
    }
    this.form = this.createForm();
  }

  createForm(): FormGroup {
    return this.fb.group({
      id: [this.dataSource.id],
      name: [this.dataSource.name, [Validators.required]],
      unit_key: [this.dataSource.unit_key, [Validators.required]],
      iva: [this.dataSource.iva, [Validators.required]],
      iva_retain: [this.dataSource.iva_retain, [Validators.required]],
      isr: [this.dataSource.isr, [Validators.required]],
      isr_retain: [this.dataSource.isr_retain, [Validators.required]],
      clave_sat: [this.dataSource.clave_sat, [Validators.required]],
      obj_tax: [this.dataSource.obj_tax, [Validators.required, Validators.maxLength(2)]],
      observations: [this.dataSource.observations, []],
    });
  }

  save(): void {
    if (this.form.valid) {
      this.dataSource = this.form.value;
      this.storeUpdate(this.edit ? "update" : "store");
    } else {
      this.formService.allFields(this.form);
    }
  }

  storeUpdate(method: string): void {
    const data = {
      message: "",
      data: this.dataSource,
      status: "200",
    };

    if (method === "store") {
      this.apiService.create(this.dataSource).subscribe({
        next: (v) => {
          this.openSnack("snack-success", v.message);
          this.closeModal(data);
        },
        error: (e) => {
          this.openSnack("snack-error", e.error.message);
          throw e;
        },
      });
    } else {
      this.apiService.update(this.dataSource).subscribe({
        next: (v) => {
          this.openSnack("snack-success", v.message);
          this.closeModal(data);
        },
        error: (e) => {
          this.openSnack("snack-error", e.error.message);
          throw e;
        },
      });
    }
  }

  

  closeModal(data: InGlobalRes): void {
    this.dialogRef.close(data);
  }

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
    });
  }
}
