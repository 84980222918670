<div class="container mx-auto px-4 h-full">
  <div class="flex content-center items-center justify-center h-full">
    <div class="w-full lg:w-4/12 px-4">
      <div
        class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-200 border-0">
        <div class="rounded-t mb-0 px-6 py-6">
          <div class="text-center mb-3">
            <img src="../../../../assets/img/logoHeader.png" alt="" class="shadow-lg">
          </div>
          <hr class="mt-6 border-b-1 border-slate-300" />
        </div>

        <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form (keydown.enter)="logIn()" [formGroup]="formGroup">

            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="grid-password">
                Correo
              </label>
              <input
                type="text" name="email" formControlName="email"
                class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 
                bg-white rounded text-sm shadow-lg border-gray-300 focus:outline-none focus:ring
                w-full ease-linear transition-all duration-150"
                placeholder="Correo" />
                <div *ngFor="let validation of validationMessages.email">
                  <span *ngIf="formService.field(formGroup, validation.type, 'email')" class="mt-2 text-sm text-red-500 ">
                    {{ validation.message }}
                  </span>
                </div>
            </div>

            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="grid-password">
                Contraseña
              </label>
              <input
                type="password" name="password" formControlName="password"
                class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 
                bg-white rounded text-sm shadow-lg border-gray-300 focus:outline-none focus:ring
                w-full ease-linear transition-all duration-150"
                placeholder="Contraseña" />
                <div *ngFor="let validation of validationMessages.password">
                  <span *ngIf="formService.field(formGroup, validation.type, 'password')" class="mt-2 text-sm text-red-500 ">
                    {{ validation.message }}
                  </span>
                </div>
            </div>
            
            <!-- <div class="relative w-full mb-3">
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="grid-password">
                Contraseña
              </label>
              <input
                type="password" name="password" [(ngModel)]="password"
                class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 
                bg-white rounded text-sm shadow-lg border-gray-300 focus:outline-none 
                focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Contraseña" 
              class="border-2 px-3 py-3
              placeholder-slate-300
              text-slate-600 bg-white rounded border-gray-300
              text-sm focus:outline-none focus:ring
              w-full ease-linear transition-all duration-150"/>
            </div> -->

            <div class="text-center mt-6">
              <button (click)="logIn()" (keydown.enter)="logIn()"
                class="bg-slate-800 text-white active:bg-slate-600 text-sm 
                font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg 
                outline-none focus:outline-none mr-1 mb-1 w-full ease-linear 
                transition-all duration-150"
                [ngClass]="{ 'disabled:opacity-25': formGroup.invalid }"
                [disabled]="formGroup.invalid"
                type="button">
                Acceder
              </button>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</div>
