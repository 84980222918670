import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { UserServicesService } from "app/apiServices/user-services.service";
import { User } from "app/models/user";
import { ChangePasswordComponent } from "./form/change-password/change-password.component";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.css"],
})
export class UserComponent implements OnInit {
  user: User;

  constructor(
    public dialogRef: MatDialogRef<UserComponent>,
    public apiService: UserServicesService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.apiService.getMyself().subscribe(
      (r) => {
        this.user = r.data;
      },
      (err) => {

      }
    );
  }

  openChangePasswordModal(data: any, action: string){
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: "45%",
      height: "auto",
      data: {
        data, action
      }
    })
  }
}
