import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserServicesService } from 'app/apiServices/user-services.service';
import { NotificationsComponent } from 'app/components/alerts/notifications/notifications.component';
import { FormValidatorService } from 'app/core/service/form-validator.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  form: FormGroup
  validationMessages = { 
    currentPassword: [{
      type: "required", message: "Ingresa la contraseña actual"
    }],
    newPassword: [{
      type: "required", message: "Ingresa la nueva contraseña"
    }], 
    confirmPassword: [{
      type: "required", message: "Ingresa nueva contraseña de nuevo"
    }]
  }

  validationMessagesUser = {
    newPassword: [{
      type: "required", message: "Escriba su nueva contraseña"
    }],
  }

  newPassword: boolean = false
  dataSource: any
  user: any = {}
  self: boolean = true

  constructor(
    public formValidator: FormValidatorService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ChangePasswordComponent>,
    private userService: UserServicesService,
    private _snack: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if(this.data.action === "User") {
      this.user = this.data.data
      this.self = false
    }
    
    this.form = this.createForm()
  }

  ngOnInit(): void { }

  createForm(): FormGroup{
    if(this.data.action === "Self"){
      return this.fb.group({
        currentPassword: ['', [Validators.required]],
        newPassword: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]]
      }, {
        validators: this.checkNewPassword
      })
    }else{
      return this.fb.group({
        newPassword: ['', [Validators.required]]
      })
    }
  }

  checkNewPassword(control: AbstractControl){
    return control.get('newPassword')?.value == control.get('confirmPassword')?.value ? null : { mismatch: true }
  }
  
  save(){
    if(this.form.valid){
      this.dataSource = this.form.value;
      if(this.data.action === "Self"){
        this.selfPassword()
      }else{
        this.userPassword()
      }
    }
  }

  selfPassword(){
    this.userService.changePassword(this.dataSource).subscribe({
      next: (response) => {
        this.openSnack('snack-success', response.message)
        this.closeModal()
      }, error: (errors) => {
        this.openSnack('snack-error', errors.error.message)
      }
    })
  }

  userPassword(){
    this.userService.updatePassword(this.user.id, this.dataSource).subscribe({
      next: (response) => {
        this.openSnack('snack-success', response.message)
        this.closeModal()
      }, error: (errors) => {
        this.openSnack('snack-error', errors.error.message)
      }
    })
  }

  closeModal(){
    this.dialogRef.close();
  }

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000
    })
  }
}
