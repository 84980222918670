import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { ReportService } from 'app/apiServices/report.service';
import { NotificationsComponent } from 'app/components/alerts/notifications/notifications.component';
import { DateBody } from 'app/interfaces/date.interface';
import { environment } from 'environments/environment';
import { DateTime } from 'luxon';

declare let DateRangePicker: any;

@Component({
  selector: 'app-purchases-sales-report',
  templateUrl: './purchases-sales-report.component.html',
  styleUrls: ['./purchases-sales-report.component.css']
})
export class PurchasesSalesReportComponent implements OnInit{
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild("container", { static: true }) container: ElementRef;
  purchase: boolean = true;

  form: FormGroup;
  rows: any[] = [];
  temp: any[] = [];
  ColumnMode = ColumnMode;
  dateRangePicker: any;
  loading: boolean = false;

  constructor(private reportService: ReportService, public _snack: MatSnackBar) {}

  ngOnInit(): void {
    const dateRangePickerEl = document.getElementById("dateRangePickerId");
    this.dateRangePicker = new DateRangePicker(dateRangePickerEl, {
      allowOneSidedRange: true,
      language: "es",
      format: "yyyy-mm-dd",
      orientation: "bottom",
    });

    this.setInitialDate();
  }

  purchaseCheck(event: any){
    this.purchase = true
  }

  sellingCheck(event: any){
    this.purchase = false
  }

  onDetailToggle(event: any){

  }

  openDialog(){
    this.getReport();
  }

  setInitialDate() {
    this.dateRangePicker.setDates(
      DateTime.local().minus({ days: 15 }).toISODate(),
      DateTime.local().toISODate()
    );
  }

  changeDate(event: any) {
    console.log("Event", event)
    const [startDate, endDate] = this.dateRangePicker.getDates();

    if (startDate && endDate) {
      this.dateRangePicker.datepickers[0].element?.click();
      this.container?.nativeElement?.click();
      this.dateRangePicker.datepickers[1].element?.click();
      this.container?.nativeElement?.click();
      const formattedStartDate = DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd");
      const formattedEndDate = DateTime.fromJSDate(endDate).toFormat("yyyy-MM-dd");

      console.log("Date_initial", formattedStartDate)
      console.log("Date_final", formattedEndDate)
    }
  }

  undo(): void {
    this.setInitialDate();
  }

  updateFilter(event: Event) {
    const val: string = (event.target as HTMLInputElement).value.toLowerCase();
    const temp: any[] = this.temp.filter((d: any) => {
      return d.folio.toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.temp = temp;
    this.table.offset = 0;
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row)
  }

  onLimitChange(event: Event): void {
    const limit = (event.target as HTMLSelectElement).value;
    this.changePageLimit(limit);
    this.table.limit = this.form.controls.rowsPerPage.value;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor(
          (this.table.rowCount - 1) / this.table.limit
        );
      }
    });
  }

  changePageLimit(limit: any): void {
    this.form.controls.rowsPerPage.setValue(parseInt(limit, 10));
  }

  getReport(): void {
    this.loading = true;
    const [startDate, endDate] = this.dateRangePicker.getDates();
    const dateBody: DateBody = {
      start_date: DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd"),
      end_date: DateTime.fromJSDate(endDate).toFormat("yyyy-MM-dd"),
    };

    if (this.purchase) this.getPurchasesReport(dateBody);
    else this.getSalesReport(dateBody);
  }

  getSalesReport(dateBody: DateBody): void {
    this.reportService
      .getSalesReport(dateBody)
      .subscribe(
        res => {
          this.loading = false;
          this.fileDownload(res, "text/csv", "ventas" + new Date().getTime());
        },
        error => {
          console.log("Error", error)
          this.loading = false;
          this.openSnack(error.error.message? "snack-success" : "snack-error", error.status == 400 ? error.error.message : "Ocurrió un error")
        }
      );
  }

  getPurchasesReport(dateBody: DateBody): void {
    this.reportService
      .getPurchasesReport(dateBody)
      .subscribe(
        res => {
          this.loading = false;
          this.fileDownload(res, "text/csv", "compras" + new Date().getTime());
        },
        error => {
          console.log("Error", error);
          this.loading = false;
          this.openSnack(error.error.message? "snack-success" : "snack-error", error.status == 400 ? error.error.message : "Ocurrió un error")
        }
      );
  }

  fileDownload(data: any, type: string, name: string): void {
    console.log("data", data.message)
    let blob = new Blob([data], { type: type});
    const downloadURL = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = name;
    link.click();
  }

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000
    })
  }
}
