<div class="container bg-white mx-auto px-4 h-full py-4">
    <div class="flex justify-between rounded-t mb-0 px-4 py-3 border-0 pt-6 bg-white">
        <div class="flex w-full justify-center">
            <div>
                <h3 class="font-semibold text-lg">
                    {{dialogTitle}}
                </h3>
            </div>
        </div>
    </div>
    <form class="bg-white" [formGroup]="form">
        <!-- Existence Change Type  -->
        <div class="relative w-full mb-3 flex">
            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="existence_change_type_id">
                    Tipo de Existencia
                </label>
                <select name="existence_change_type_id" formControlName="existence_change_type_id" [ngClass]="{
                    'border-red-500': form.controls.existence_change_type_id.invalid,
                    'focus:border-red-500': form.controls.existence_change_type_id.invalid,
                    'focus:ring-red-500': form.controls.existence_change_type_id.invalid
                  }" class="border-2 px-3 py-3
                  placeholder-slate-300 mb-3
                  text-slate-600 bg-white rounded border-gray-300
                  text-sm focus:outline-none focus:ring
                  w-full ease-linear transition-all duration-150">
                    <option *ngFor="let item of existence_change_type" [ngValue]="item.id">{{ item.name }}</option>
                </select>
                <div *ngFor="let validation of validationMessages.name">
                    <span *ngIf="formService.field(form, validation.type, 'existence_change_type_id')"
                        class="mt-2 text-sm text-red-500 ">
                        {{ validation.message }}
                    </span>
                </div>
            </div>
        </div>

        <!-- Product -->
        <div class="relative w-full mb-3 flex">
            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="product_id">
                    Producto
                </label>
                <select name="product_id" formControlName="product_id" [ngClass]="{
                    'border-red-500': form.controls.product_id.invalid,
                    'focus:border-red-500': form.controls.product_id.invalid,
                    'focus:ring-red-500': form.controls.product_id.invalid
                  }" class="border-2 px-3 py-3
                  placeholder-slate-300 mb-3
                  text-slate-600 bg-white rounded border-gray-300
                  text-sm focus:outline-none focus:ring
                  w-full ease-linear transition-all duration-150">
                    <option *ngFor="let item of products" [ngValue]="item.id">{{ item.name }}</option>
                </select>
                <div *ngFor="let validation of validationMessages.name">
                    <span *ngIf="formService.field(form, validation.type, 'product_id')"
                        class="mt-2 text-sm text-red-500 ">
                        {{ validation.message }}
                    </span>
                </div>
            </div>
        </div>

        <!-- TANK -->
        <div class="relative w-full mb-3 flex">
            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="tank_id">
                    Tanque
                </label>
                <select name="tank_id" formControlName="tank_id" [ngClass]="{
                    'border-red-500': form.controls.tank_id.invalid,
                    'focus:border-red-500': form.controls.product_id.invalid,
                    'focus:ring-red-500': form.controls.tank_id.invalid
                  }" class="border-2 px-3 py-3
                  placeholder-slate-300 mb-3
                  text-slate-600 bg-white rounded border-gray-300
                  text-sm focus:outline-none focus:ring
                  w-full ease-linear transition-all duration-150">
                    <option *ngFor="let item of tanks" [ngValue]="item.id">{{ item.tank }}</option>
                </select>
                <div *ngFor="let validation of validationMessages.name">
                    <span *ngIf="formService.field(form, validation.type, 'tank_id')"
                        class="mt-2 text-sm text-red-500 ">
                        {{ validation.message }}
                    </span>
                </div>
            </div>
        </div>

        <!-- Users  -->
        <!-- <div class="relative w-full mb-3 flex">
            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="user_id">
                    Usuarios
                </label>
                <select name="user_id" formControlName="user_id" [ngClass]="{
                    'border-red-500': form.controls.user_id.invalid,
                    'focus:border-red-500': form.controls.user_id.invalid,
                    'focus:ring-red-500': form.controls.user_id.invalid
                  }" class="border-2 px-3 py-3
                  placeholder-slate-300 mb-3
                  text-slate-600 bg-white rounded border-gray-300
                  text-sm focus:outline-none focus:ring
                  w-full ease-linear transition-all duration-150">
                    <option *ngFor="let item of users" [ngValue]="item.id">{{ item.email }}</option>
                </select>
                <div *ngFor="let validation of validationMessages.name">
                    <span *ngIf="formService.field(form, validation.type, 'user_id')"
                        class="mt-2 text-sm text-red-500 ">
                        {{ validation.message }}
                    </span>
                </div>
            </div>
        </div> -->

        <!-- Quantity  -->
        <div class="relative w-full mb-3 flex">
            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="quantity">
                    Cantidad
                </label>
                <input type="number" name="quantity" formControlName="quantity" [ngClass]="{ 
                    'border-red-500': form.controls.quantity.invalid, 
                    'focus:border-red-500': form.controls.quantity.invalid, 
                    'focus:ring-red-500': form.controls.quantity.invalid 
                }" class="border-2 px-3 py-3
                placeholder-slate-300
                text-slate-600 bg-white rounded border-gray-300
                text-sm focus:outline-none focus:ring
                w-full ease-linear transition-all duration-150" />
                <div *ngFor="let validation of validationMessages.name">
                    <span *ngIf="formService.field(form, validation.type, 'quantity')"
                        class="mt-2 text-sm text-red-500 ">
                        {{ validation.message }}
                    </span>
                </div>
            </div>
        </div>

        <!-- Description -->
        <div class="relative w-full mb-3 flex">
            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2 " htmlFor="description">
                    Descripcion
                </label>
                <textarea type="text" name="description" formControlName="description" [ngClass]="{ 
                        'border-red-500': form.controls.description.invalid, 
                        'focus:border-red-500': form.controls.description.invalid, 
                        'focus:ring-red-500': form.controls.description.invalid 
                    }" class="border-2 px-3 py-3
                    placeholder-slate-300 
                    text-slate-600 bg-white rounded border-gray-300
                    text-sm focus:outline-none focus:ring
                    w-full ease-linear transition-all duration-150">
                  </textarea>
            </div>
        </div>

        <!-- Buttons -->
        <div class="flex w-full justify-end">
            <button class="bg-slate-500 text-white 
                active:bg-slate-500 font-bold uppercase 
                text-xs px-4 py-2 rounded shadow hover:shadow-md 
                outline-none focus:outline-none mr-1 ease-linear 
                transition-all duration-150" (click)="closeModal()">
                Cancelar
            </button>
            <button class="bg-sky-600 text-white 
                active:bg-sky-600 font-bold uppercase 
                text-xs px-4 py-2 rounded shadow hover:shadow-md 
                outline-none focus:outline-none mr-1 ease-linear 
                transition-all duration-150" [ngClass]="{ 'disabled:opacity-25': form.invalid }"
                [disabled]="form.invalid" (click)="save()">
                {{ edit ? 'Actualizar' : 'Agregar' }}
            </button>
        </div>
    </form>
</div>