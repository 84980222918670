<div #container class="">
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-200 border-0">
    <div class="relative flex flex-col min-w-0 break-words w-full rounded">
      <div
        class="md:grid md:grid-cols-2 md:gap-2 rounded-t mb-0 px-4 py-3 border-0 pt-6">
        <div class="basis-1/2 flex justify-start">
          <span
            class="z-10 h-full leading-snug font-normal absolute text-center text-slate-300 bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
            <i class="fa fa-search"></i>
          </span>

          <input
            type="text"
            class="w-full md:w-48 pl-12 py-3 rounded-full border text-sm border-gray-300 focus:outline-none focus:border-blue-400"
            (keyup)="updateFilter($event)"
            placeholder="Buscar"
          />
        </div>
        <div class="flex justify-end">
          <div
            id="dateRangePickerId"
            date-rangepicker
            class="w-full flex items-center"
          >
            <div class="w-full relative">
              <div
                class="w-full pl-3 absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"
              >
                <svg
                  class="w-4 h-4 text-black"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"
                  />
                </svg>
              </div>
              <input
                name="start"
                type="text"
                class="bg-gray-50 border border-gray-300 text-gray-900 placeholder-current pl-12 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
                placeholder="Selecciona la fecha de inicio"
                (changeDate)="changeDate($event)"
              />
            </div>
            <span class="mx-4 text-black font-medium text-base">a</span>
            <div class="w-full relative">
              <div
                class="w-full pl-3 absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"
              >
                <svg
                  class="w-4 h-4 text-black"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"
                  />
                </svg>
              </div>
              <input
                name="end"
                type="text"
                class="bg-gray-50 border border-gray-300 pl-12 text-gray-900 placeholder-current text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
                placeholder="Selecciona la fecha de fin"
                (changeDate)="changeDate($event)"
              />
            </div>
          </div>
          <div class="flex justify-center items-center mx-3">
            <i class="fas fa-undo cursor-pointer" (click)="undo()"></i>
          </div>
        </div>
      </div>

      <div class="grid">
        <div class="block w-full overflow-x-auto rounded-lg">
          <ngx-datatable
            #table
            class="material expandable"
            [columnMode]="ColumnMode.force"
            [headerHeight]="50"
            [footerHeight]="50"
            rowHeight="auto"
            [limit]="10"
            [rows]="temp"
            [loadingIndicator]="loading"
            [scrollbarH]="true"
            (sort)="onSort($event)"
          >
            <ngx-datatable-column
              [width]="10"
              [resizeable]="false"
              [sortable]="false"
              [draggable]="false"
              [canAutoResize]="false"
            >
            </ngx-datatable-column>

            <!-- Row Details Template -->
            <ngx-datatable-row-detail
              [rowHeight]="'auto'"
              (toggle)="onDetailToggle($event)"
            >
              <ng-template
                let-row="row"
                let-expanded="expanded"
                ngx-datatable-row-detail-template
              >
                <div style="padding-left: 55px">
                  <div class="w-full">
                    <div class="flex justify-start pl-12">
                      <b>Costos:</b>
                    </div>
                  </div>
                  <div class="row flex-row ml-2">
                    <div class="flex w-full">
                      <table
                        class="table-fixed w-11/12"
                        aria-describedby="costs"
                      >
                        <thead *ngIf="row.order_costs.length > 0">
                          <tr>
                            <th class="border-b border-r">Tipo de Costo</th>
                            <th class="border-b border-r">Vendedor</th>
                            <th class="border-b border-r">Proveedor</th>
                            <th class="border-b border-r">Costo</th>
                            <th class="border-b border-r">Cantidad</th>
                            <th class="border-b border-r">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let cost of row.order_costs">
                            <td class="border-b border-r text-center">
                              {{ cost.order_cost_type?.name }}
                            </td>
                            <td class="border-b border-r text-center">
                              {{ cost?.user?.name }} {{ cost.user?.lastname }}
                            </td>
                            <td class="border-b border-r text-center">
                              {{ cost?.company_supplier?.legal_name }}
                            </td>
                            <td class="border-b border-r text-center">
                              $ {{ cost.cost | number : "1.2-2" }}
                            </td>
                            <td class="border-b border-r text-center">
                              {{ cost.quantity | number : "1.0-2" }}
                            </td>
                            <td class="border-b border-r text-center">
                              $
                              {{ cost.quantity * cost.cost | number : "1.2-2" }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngx-datatable-row-detail>

            <ngx-datatable-column
              [width]="50"
              [resizeable]="false"
              [sortable]="false"
              [draggable]="false"
              [canAutoResize]="false"
            >
              <ng-template
                let-row="row"
                let-expanded="expanded"
                ngx-datatable-cell-template
              >
                <div class="flex items-center h-full">
                  <a
                    href="javascript:void(0)"
                    [class.datatable-icon-right]="!expanded"
                    [class.datatable-icon-down]="expanded"
                    title="Costos"
                    (click)="toggleExpandRow(row)"
                  >
                  </a>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Folio">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.folio }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="C. Vendedora">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.seller.legal_name }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Tipo de venta">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.type_code == "DIR" ? "Directa" : "Normal" }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Vendedor">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.vendor.name }} {{ row.vendor.last_name }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Producto">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.product.name }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Estatus">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{
                  row.order_status.id == 2
                    ? "Prioridad de carga"
                    : row.order_status.name
                }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Creado">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.created_at }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Cliente">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.client.company.legal_name }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Transportista">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{
                  row.check_own_transportist
                    ? row.own_transportist_name
                    : row.transportist.company.legal_name
                }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Opciones" [sortable]="false">
              <ng-template
                #buttonsTemplate
                let-row="row"
                let-value="value"
                ngx-datatable-cell-template
              >
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  aria-label="Menu"
                >
                  <i class="fas fa-ellipsis-v"></i>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="openDetailDialog(row)">
                    <i class="fas fa-dollar-sign mx-2"></i>
                    <span>Modificar costos</span>
                  </button>
                </mat-menu>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible"
              >
                <div class="page-count hide">{{ rowCount }} total</div>

                <div>
                  <form [formGroup]="form">
                    <div>
                      <div
                        class="flex justify-content-between align-items-center"
                      >
                        <label
                          class="flex align-items-center mx-4 my-2"
                          for="rowsPerPage"
                          >Mostrar por página:
                        </label>

                        <select
                          class="rounded form-control mx"
                          formControlName="rowsPerPage"
                          (change)="onLimitChange($event)"
                          id="rowsPerPage"
                          name="rowsPerPage"
                        >
                          <option [value]="5">5</option>
                          <option [value]="10">10</option>
                          <option [value]="25">25</option>
                          <option [value]="50">50</option>
                        </select>
                      </div>
                    </div>
                  </form>
                </div>

                <datatable-pager
                  [pagerLeftArrowIcon]="'datatable-icon-left'"
                  [pagerRightArrowIcon]="'datatable-icon-right'"
                  [pagerPreviousIcon]="'datatable-icon-prev'"
                  [pagerNextIcon]="'datatable-icon-skip'"
                  [page]="curPage"
                  [size]="pageSize"
                  [count]="rowCount"
                  (change)="table.onFooterPage($event)"
                >
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
