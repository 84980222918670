<div class="">
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-200 border-0">
      <div class="relative flex flex-col min-w-0 break-words w-full rounded">
        
        <!-- First row -->
        <div class="grid grid-cols-2 gap-2 rounded-t mb-0 px-4 py-3 border-0 pt-4">

            <!-- Card 1 -->
            <div class="">
                <div class="text-cyan-600 text-lg text-center"><b>Inventarios</b></div>
                <div class="flex justify-center items-center pt-4">
                    <div class="w-2/3 flex justify-center items-center pl-10">
                        <div class="w-2/6">
                            <div class="text-lg text-left"> <i class="fas fa-gas-pump"></i> Regular</div>
                            <div class="text-lg text-left"> <i class="fas fa-gas-pump"></i> Premium</div>
                            <div class="text-lg text-left"> <i class="fas fa-gas-pump"></i> Diesel</div>
                            <div class="text-lg text-left"> <i class="fas fa-gas-pump"></i> P.A.</div>
                        </div>
                        <div class="w-2/6">
                            <div class="text-lg text-left"> <b class="text-cyan-600">132,000</b></div>
                            <div class="text-lg text-left"> <b class="text-cyan-600">146,000</b></div>
                            <div class="text-lg text-left"> <b class="text-cyan-600">390,000</b></div>
                            <div class="text-lg text-left"> <b class="text-cyan-600">89,000</b></div>
                        </div>
                        <div class="w-2/6">
                            <div class="text-lg text-left">Litros</div>
                            <div class="text-lg text-left">Litros</div>
                            <div class="text-lg text-left">Litros</div>
                            <div class="text-lg text-left">Litros</div>
                        </div>
                    </div>
                </div>
                <div class="flex justify-center items-center pt-6">
                    <button
                        class="bg-sky-600 text-white 
                        active:bg-sky-600 font-bold uppercase 
                        text-xs px-4 py-2 rounded shadow hover:shadow-md 
                        outline-none focus:outline-none mr-1 ease-linear 
                        transition-all duration-150" (click)="toRoute(1)">
                        Detalle de inventarios
                    </button>
                </div>
            </div>
            
            <!-- Card 2 -->
            <div>
                <div class="text-emerald-600 text-lg text-center"><b>Ventas del día</b></div>
                <div class="flex justify-center items-center">
                    <div class="w-3/4">
                        <div class="text-center text-md">Total de ventas <br>del dia de hoy</div>
                        <div class="pt-4 text-center text-6xl text-emerald-600">$14,535,420</div>
                    </div>
                </div>
                <div class="flex justify-center items-center pt-6">
                    <button
                        class="bg-sky-600 text-white 
                        active:bg-sky-600 font-bold uppercase 
                        text-xs px-4 py-2 rounded shadow hover:shadow-md 
                        outline-none focus:outline-none mr-1 ease-linear 
                        transition-all duration-150" (click)="toRoute(2)">
                        Detalle de venta del día
                    </button>
                </div>
            </div>
        </div>
        
        <!-- Second Row -->
        <div class="grid grid-cols-2 gap-2 rounded-t mb-0 px-4 py-3 border-0 pt-4">

            <!-- Card 3 -->
            <div>
                <div class="text-yellow-500 text-lg text-center"><b>Unidades en planta</b></div>
                <div class="flex justify-center items-center">
                    <div class="w-3/4">
                        <div class="text-center text-md">Número de unidades <br>en este momento</div>
                        <div class="flex justify-center items-center w-full">
                            <div class="w-1/2 pt-4 text-right text-8xl text-yellow-500 pr-8"><b>9</b></div>
                            <div class="w-1/2 pt-4"><img src="../../../assets/img/truck.png" alt="" class="mirrowImg smallerImg pl-8"></div>
                        </div>
                    </div>
                </div>
                <div class="flex justify-center items-center pt-6">
                    <button
                        class="bg-sky-600 text-white 
                        active:bg-sky-600 font-bold uppercase 
                        text-xs px-4 py-2 rounded shadow hover:shadow-md 
                        outline-none focus:outline-none mr-1 ease-linear 
                        transition-all duration-150" (click)="toRoute(3)">
                        Detalle de unidades en planta
                    </button>
                </div>
            </div>

            <!-- Cart 4 -->
            <div>
                <div class="text-red-600 text-lg text-center"><b>Unidades en tránsito</b></div>
                <div class="flex justify-center items-center">
                    <div class="w-3/4">
                        <div class="text-center text-md">Número de unidades <br>en este momento</div>
                        <div class="flex justify-center items-center w-full">
                            <div class="w-1/2 pt-4 text-right text-8xl text-red-600 pr-8"><b>5</b></div>
                            <div class="w-1/2 pt-4"><img src="../../../assets/img/truck.png" alt="" class="mirrowImg smallerImg pl-8"></div>
                        </div>
                    </div>
                </div>
                <div class="flex justify-center items-center pt-6">
                    <button
                        class="bg-sky-600 text-white 
                        active:bg-sky-600 font-bold uppercase 
                        text-xs px-4 py-2 rounded shadow hover:shadow-md 
                        outline-none focus:outline-none mr-1 ease-linear 
                        transition-all duration-150" (click)="toRoute(4)">
                        Detalle de unidades en tránsito
                    </button>
                </div>
            </div>
        </div>
      </div>

    </div>
  </div>