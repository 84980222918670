import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { Product, ProductMixes } from "app/models/product";
import { InventoryService } from "app/apiServices/inventory.service";

@Component({
  selector: "app-compose-product",
  templateUrl: "./compose-product.component.html",
  styleUrls: ["./compose-product.component.css"],
})
export class ComposeProductComponent implements OnInit {

  listDataProducts: Product[] = [];
  productMixes: ProductMixes[] = [];
  productDataCompose: Product[] = [];

  @Output() onDrop = new EventEmitter<Product[]>();
  @Input() productData: any[] = [];
  @Input() productID: number;
  @Input() edit: boolean = false;

  constructor(private inventorySvc: InventoryService) { this.getData() }

  ngOnInit(): void {

  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      /*const productsId = this.productDataCompose.map((p) => p.id);
      this.productMixes = this.productMixes.filter((p) =>
        productsId.includes(p.product_id)
      );*/
      this.productMixes = this.productMixes.length == 0 ? this.mapProductToMix() : this.mapProductToMix(true);
      this.onDrop.emit(this.productMixes);
    }
  }

  mapProductToMix(recovery: boolean = false): ProductMixes[] {
    return this.productDataCompose.map((p) => {

      let percentage = 0;

      if (recovery) {
        const productMix = this.productMixes.find(
          (pm) => pm.element_product_id === p.id
        );
        // console.log(productMix)
        if (productMix) {
          percentage = productMix.percentage;
        }
      }

      return {
        element_product_id: p.id,
        percentage: percentage,
        active: true,
      };
    });
  }

  getPercentage(id: number): number {
    const index = this.productMixes.findIndex((p) => p.element_product_id === id);
    return this.productMixes[index].percentage;
  }

  onBindProductMix(event: Event, product: Product) {
    const val: number = Number((event.target as HTMLInputElement).value);
    const index = this.productMixes.findIndex(
      (p) => p.element_product_id === product.id
    );
    this.productMixes[index].percentage = val;
    this.onDrop.emit(this.productMixes);
  }

  getData(): void {
    this.inventorySvc.getProducts().subscribe({
      next: (v) => {
        this.listDataProducts = v.data.filter(item => item.active && item.full_mix)

        if (this.edit && this.productData.length > 0) {
          this.productMixes = this.productData;
          this.productDataCompose = this.productData.map(
            (productMix: ProductMixes) => {

              let name = this.listDataProducts.findIndex(item => item.id === productMix.element_product_id)

              return {
                id: productMix.element_product_id,
                name: this.listDataProducts[name].name,
              };
            }
          );
          this.onDrop.emit(this.productMixes);

          // FILTRAR PRODUCTOS
          const ids = this.productData.map(p => p.element_product_id);
          ids.push(this.productID);
          this.listDataProducts = this.listDataProducts.filter(p => !ids.includes(p.id));
          // FIN

        } else {
          const ids = [this.productID];
          this.listDataProducts = this.listDataProducts.filter(p => !ids.includes(p.id));
        }
      },
      error: (e) => {
        //console.log(e)
      }
    })
  }
}
