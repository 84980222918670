<div class="container bg-white mx-auto px-4 h-full py-4">
    <div class="justify-between rounded-t mb-0 px-4 py-3 border-0 pt-6 bg-white">
        <div class="text-center">
            <h3 class="font-semibold text-lg">
                Informacion de Compañia
            </h3>
        </div>
    </div>
    <form class="bg-white" [formGroup]="">
        <div class="relative w-full mb-3 flex">
            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="legal_id">
                    RCF empresarial
                  </label>
                  <input type="text" name="legal_id"
                    class="border-2 px-3 py-3
                    placeholder-slate-300
                    text-slate-600 bg-white rounded border-gray-300
                    text-sm focus:outline-none focus:ring
                    w-full ease-linear transition-all duration-150" 
                    value="{{dataSource.legal_id}}" disabled/>
            </div>
            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="contact_name">
                    Nombre del Contacto
                </label>
                <input type="text" name="contact_name"
                class="border-2 px-3 py-3
                placeholder-slate-300
                text-slate-600 bg-white rounded border-gray-300
                text-sm focus:outline-none focus:ring
                w-full ease-linear transition-all duration-150"
                value="{{dataSource.contact_name}}" disabled/>
            </div>
        </div>
  
        <div class="relative w-full mb-3 flex">
            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="contact_phone">
                    Numero del Contacto
                </label>
                <input type="text" name="contact_phone"
                class="border-2 px-3 py-3
                placeholder-slate-300
                text-slate-600 bg-white rounded border-gray-300
                text-sm focus:outline-none focus:ring
                w-full ease-linear transition-all duration-150"
                value="{{dataSource.contact_phone}}" disabled/>
            </div>
            
            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="legal_name">
                        Razón Social
                </label>
                <input type="text" name="legal_name"
                class="border-2 px-3 py-3
                placeholder-slate-300
                text-slate-600 bg-white rounded border-gray-300
                text-sm focus:outline-none focus:ring
                w-full ease-linear transition-all duration-150"
                value="{{dataSource.legal_name}}" disabled/>
            </div>

        </div>
        
        
        <div class="relative w-full mb-3 flex">
            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="legal_name">
                        Regimen Fiscal
                </label>
                <input type="text" name="legal_name"
                class="border-2 px-3 py-3
                placeholder-slate-300
                text-slate-600 bg-white rounded border-gray-300
                text-sm focus:outline-none focus:ring
                w-full ease-linear transition-all duration-150"
                value="{{dataSource.regimen_fiscal.regimen_fiscal + ', ' + dataSource.regimen_fiscal.descripcion}}" disabled/>
            </div>
        </div>

        <div class="relative w-full mb-3 flex">
            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="folio">
                    Folio
                </label>
                <input type="text" name="folio"

                class="border-2 px-3 py-3
                placeholder-slate-300
                text-slate-600 bg-white rounded border-gray-300
                text-sm focus:outline-none focus:ring
                w-full ease-linear transition-all duration-150"
                value="{{dataSource.folio}}" disabled/>
            </div>
        </div>

        <div class="relative w-full mb-3 flex text-center">
            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="Cliente">
                    Cliente
                </label>
                <input [checked]="client" disabled
                type="checkbox" 
                    class="border-2 px-3 py-3
                    placeholder-slate-300
                    text-slate-600 bg-white rounded border-gray-300
                    text-sm focus:outline-none focus:ring
                    w-full ease-linear transition-all duration-150" 
                />
            </div>

            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="transportists">
                    Transportista
                </label>
                <input [checked]="transportist" disabled
                type="checkbox" 
                    class="border-2 px-3 py-3
                    placeholder-slate-300
                    text-slate-600 bg-white rounded border-gray-300
                    text-sm focus:outline-none focus:ring
                    w-full ease-linear transition-all duration-150" 
                />
            </div>

            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="supplier">
                    Proveedor
                </label>
                <input [checked]="supplier" disabled
                type="checkbox" 
                    class="border-2 px-3 py-3
                    placeholder-slate-300
                    text-slate-600 bg-white rounded border-gray-300
                    text-sm focus:outline-none focus:ring
                    w-full ease-linear transition-all duration-150" 
                />
            </div>
        </div>
  
        <div class="flex w-full justify-end">
            <button
                class="bg-green-500 text-white 
                active:bg-green-800 font-bold uppercase 
                text-xs px-4 py-2 rounded shadow hover:shadow-md 
                outline-none focus:outline-none mr-1 ease-linear 
                transition-all duration-150"
                (click)="showDirections(dataSource)">
                Direcciones
            </button>

            <button
                class="bg-slate-500 text-white 
                active:bg-slate-500 font-bold uppercase 
                text-xs px-4 py-2 rounded shadow hover:shadow-md 
                outline-none focus:outline-none mr-1 ease-linear 
                transition-all duration-150"
                (click)="closeModal()">
                Cerrar
            </button>
        </div>
    </form>
  </div>