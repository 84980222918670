<div class="container bg-white mx-auto px-4 h-full py-4">
    <div class="flex justify-between rounded-t mb-0 px-4 py-3 border-0 pt-6 bg-white">
        <div class="flex w-full justify-center">
            <div>
                <h3 class="font-semibold text-lg">
                    Actualizar contraseña
                </h3>
            </div>
        </div>
    </div>
    <div class="overflow-y">
        <form class="bg-white" [formGroup]="form">
            <div class="flex column">
                <div *ngIf="this.self">
                    <div class="relative w-full mb-3 flex">
                        <!-- Current Password -->
                        <div class="container px-2">
                            <label class="block uppercase text-slate-600 text-xs font-bold mb-2 " htmlFor="currentPassword">
                                Contraseña Actual
                            </label>
                            <input type="password" name="currentPassword" formControlName="currentPassword" [ngClass]="{ 
                                'border-red-500': !form.controls.currentPassword.valid, 
                                'focus:border-red-500': !form.controls.currentPassword.invalid, 
                                'focus:ring-red-500': !form.controls.currentPassword.invalid 
                                }" *ngIf="form.controls.currentPassword.invalid ? 'prueba' : 'error'" class="border-2 px-3 py-3
                                placeholder-slate-300 
                                text-slate-600 bg-white rounded border-gray-300
                                text-sm focus:outline-none focus:ring
                                w-full ease-linear transition-all duration-150"/>
                            <div *ngFor="let validation of validationMessages.currentPassword">
                                <span *ngIf="formValidator.field(form, validation.type, 'currentPassword')" class="mt-2 text-sm text-red-500 ">
                                    {{ validation.message }}
                                </span>
                            </div>
                        </div>
                    </div>
                    
                    <div class="relative w-full mb-3 flex">
                        <div class="flex column">
                            <!-- New Password -->
                            <div class="container px-2">
                                <label class="block uppercase text-slate-600 text-xs font-bold mb-2 " htmlFor="newPassword">
                                    Nueva Contraseña
                                </label>
                                <input type="password" name="newPassword" formControlName="newPassword" [ngClass]="{ 
                                    'border-red-500': !form.controls.newPassword.valid, 
                                    'focus:border-red-500': !form.controls.newPassword.invalid, 
                                    'focus:ring-red-500': !form.controls.newPassword.invalid 
                                    }" *ngIf="form.controls.newPassword.invalid ? 'prueba' : 'error'" class="border-2 px-3 py-3
                                    placeholder-slate-300 
                                    text-slate-600 bg-white rounded border-gray-300
                                    text-sm focus:outline-none focus:ring
                                    w-full ease-linear transition-all duration-150"/>
                                <div *ngFor="let validation of validationMessages.newPassword">
                                    <span *ngIf="formValidator.field(form, validation.type, 'newPassword')" class="mt-2 text-sm text-red-500 ">
                                        {{ validation.message }}
                                    </span>
                                </div>
                            </div>
                
                            <!-- Repeat New Password -->
                            <div class="container px-2">
                                <label class="block uppercase text-slate-600 text-xs font-bold mb-2 " htmlFor="confirmPassword">
                                    Confirmar nueva contraseña
                                </label>
                                <input type="password" name="confirmPassword" formControlName="confirmPassword" [ngClass]="{ 
                                    'border-red-500': !form.controls.confirmPassword.valid, 
                                    'focus:border-red-500': !form.controls.confirmPassword.invalid, 
                                    'focus:ring-red-500': !form.controls.confirmPassword.invalid 
                                    }" *ngIf="form.controls.confirmPassword.invalid ? 'prueba' : 'error'" class="border-2 px-3 py-3
                                    placeholder-slate-300 
                                    text-slate-600 bg-white rounded border-gray-300
                                    text-sm focus:outline-none focus:ring
                                    w-full ease-linear transition-all duration-150"/>
                                <div *ngFor="let validation of validationMessages.confirmPassword">
                                    <span *ngIf="formValidator.field(form, validation.type, 'confirmPassword')" class="mt-2 text-sm text-red-500 ">
                                        {{ validation.message }}
                                    </span>
                                </div>
                                <div *ngFor="let validation of validationMessages.confirmPassword">
                                    <span *ngIf="form.errors?.['mismatch'] && form.get('newPassword')?.touched && !formValidator.field(form, validation.type, 'confirmPassword')" class="mt-2 text-sm text-red-500 ">
                                        No coincide la nueva contraseña
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div *ngIf="!this.self">
                    <div class="relative w-full mb-3 flex">
                        <!-- New Password -->
                        <div class="container px-2">
                            <label class="block uppercase text-slate-600 text-xs font-bold mb-2 " htmlFor="newPassword">
                                Nueva Contraseña
                            </label>
                            <input type="password" name="newPassword" formControlName="newPassword" [ngClass]="{ 
                                'border-red-500': !form.controls.newPassword.valid, 
                                'focus:border-red-500': !form.controls.newPassword.invalid, 
                                'focus:ring-red-500': !form.controls.newPassword.invalid 
                                }" *ngIf="form.controls.newPassword.invalid ? 'prueba' : 'error'" class="border-2 px-3 py-3
                                placeholder-slate-300 
                                text-slate-600 bg-white rounded border-gray-300
                                text-sm focus:outline-none focus:ring
                                w-full ease-linear transition-all duration-150" (input)="newPassword = true"/>
                            <div *ngFor="let validation of validationMessagesUser.newPassword">
                                <span *ngIf="formValidator.field(form, validation.type, 'newPassword')" class="mt-2 text-sm text-red-500 ">
                                    {{ validation.message }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- Buttons -->
            <div class="flex w-full justify-end">
                <div class="flex column">
                    <button class="bg-slate-500 text-white 
                        active:bg-slate-500 font-bold uppercase 
                        text-xs px-4 py-2 rounded shadow hover:shadow-md 
                        outline-none focus:outline-none mr-1 ease-linear 
                        transition-all duration-150" (click)="closeModal()">
                        Cancelar
                    </button>
                    <button class="bg-sky-600 text-white 
                        active:bg-sky-600 font-bold uppercase 
                        text-xs px-4 py-2 rounded shadow hover:shadow-md 
                        outline-none focus:outline-none mr-1 ease-linear 
                        transition-all duration-150" [ngClass]="{ 'disabled:opacity-25': form.invalid }"
                        [disabled]="form.invalid" (click)="save()">
                        Enviar
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
