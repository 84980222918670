import {
  Component,
  ElementRef,
  Inject,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Select, initTE } from "tw-elements";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { InventoryService } from "app/apiServices/inventory.service";
import { SalesService } from "app/apiServices/sales.service";
import { NotificationsComponent } from "app/components/alerts/notifications/notifications.component";
import { FormValidatorService } from "app/core/service/form-validator.service";
import { InTankRes } from "app/interfaces/tank.interface";
import { Sale } from "app/models/sale";
import { GetTank } from "app/models/tank";
import { DataService } from "app/services/data.service";
import { LabOrder, LabOrderDetail } from "app/models/labOrder";
import { Validation } from "app/interfaces/validation.interface";
import { Product } from "app/models/product";
import { AddLabDetailProductComponent } from "../sales/dialogs/add-lab-detail-product/add-lab-detail-product.component";
import { InProductRes } from "app/interfaces/product.interface";
import { DeleteLabDetailProductComponent } from "../sales/dialogs/delete-lab-detail-product/delete-lab-detail-product.component";

@Component({
  selector: "app-lab-order",
  templateUrl: "./lab-order.component.html",
  styleUrls: ["./lab-order.component.css"],
})
export class LabOrderComponent {
  form: FormGroup;
  labOrder: LabOrder;
  order: Sale;
  products: Product[] = [];
  tanks: GetTank[] = [];
  tanksPipeOne: GetTank[] = [];
  tanksPipeTwo: GetTank[] = [];
  selectedTanks: LabOrderDetail[] = [];
  selectedTanksPipeTwo: LabOrderDetail[] = [];
  editPushedProductsPipeOne: number[] = [];
  editPushedProductsPipeTwo: number[] = [];
  suppliedQuantity: number;
  suppliedPercentage: number;
  missingQuantity: boolean = true;
  currentSelectedPipe: string = "pipe_one";
  isNotMixedProduct: boolean = false;
  missingPercentage: boolean = true;
  isTransportFull: boolean = false;
  edit: boolean = false;
  totalQuantity: number = 0.0;
  totalPercentage: number = 0.0;
  currentTotalPipeOne: number = 30000;
  currentTotalPipeTwo: number = 30000;
  canEdit: boolean = true;
  typeTransportCapacitiesValues = [];
  typeTransporCapacitiesMap: Map<number, number>;
  currentTypeTransportPipeOne: number;
  tmpTypeTransportPipeOne: number;
  currentTypeTransportPipeTwo: number = 30000;
  tmpTypeTransportPipeTwo: number;
  ColumnMode = ColumnMode;
  authorizedUser: boolean = false;
  currentTankQuantities: Map<number, number> = new Map();
  tmpTankQuantities: Array<any> = [];
  selectedTank: GetTank;
  selectOptions = {
    selectFilter: true,
    selectNoResultText: "No se encontraron resultados",
    selectSearchPlaceholder: "Buscar...",
  };

  selectCommonClasses = {
    optionsList: "list-none m-0 p-0 w-fit",
    selectInput:
      "peer block min-h-[auto] w-full rounded border-0 border-width-0 bg-white px-3 py-[0.32rem] leading-[1.6] outline-none bg-transparent transition-all duration-200 ease-linear data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0 cursor-pointer data-[te-input-disabled]:bg-[#e9ecef] data-[te-input-disabled]:cursor-default group-data-[te-was-validated]/validation:mb-4 pr-8",
  };

  @ViewChildren("productPipeOne")
  private productsPipeOne: QueryList<ElementRef>;
  @ViewChildren("productPipeTwo")
  private productsPipeTwo: QueryList<ElementRef>;

  constructor(
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<LabOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public formService: FormValidatorService,
    private inventoryService: InventoryService,
    private salesService: SalesService,
    private dataService: DataService,
    private _snack: MatSnackBar
  ) {
    this.getProducts();

    if (data.labOrder) {
      this.labOrder = data.labOrder;
      
    }

    if (data.order) {
      console.log("Order",data.order);
      this.order = data.order;
      this.isTransportFull = this.order.transport_type.id == 3;
      const hasPipeOne = this.order.pipe_one != 0;
      const hasPipeTwo = this.order.pipe_two != 0;

      if (!this.isTransportFull) {
        this.order.sold_liters = this.order.pipe_one;
      } else if (this.isTransportFull) {
        this.order.sold_liters = this.order.pipe_one + this.order.pipe_two;
      }

      this.typeTransportCapacitiesValues = [
        [6, this.order.sold_liters],
        [5, 20000],
        [4, 15000],
        [3, 60000],
        [2, 40000],
        [1, 30000],
      ];
      this.typeTransporCapacitiesMap = new Map<number, number>(
        this.typeTransportCapacitiesValues
      );
      this.currentTypeTransportPipeOne =
        this.order.pipe_one != 0
          ? this.order.pipe_one
          : this.typeTransporCapacitiesMap.get(this.order.transport_type.id);
      this.tmpTypeTransportPipeOne = this.currentTypeTransportPipeOne;

      if (this.order.transport_type.id == 3) {
        this.currentTypeTransportPipeTwo =
          this.order.pipe_two != 0
            ? this.order.pipe_two
            : this.currentTypeTransportPipeTwo;
        this.tmpTypeTransportPipeTwo = this.currentTypeTransportPipeTwo;
      }

      this.isNotMixedProduct = this.order.product.full_mix;
      this.suppliedQuantity = this.edit ? 0.0 : this.order.sold_liters;
      this.missingPercentage = !this.isNotMixedProduct;
      this.edit = data.edit;
      this.suppliedQuantity = this.edit ? 0.0 : this.order.sold_liters;
      this.missingQuantity = !this.edit;
      this.suppliedPercentage = !this.edit ? 0.0 : 100.0;
      this.authorizedUser = this.isAuthorizedUser();
      this.canEdit =
        ((!this.labOrder?.approved ?? false) /*&&
          this.order.sent_liters <= 0 &&
          this.order.tank_readings.length == 0*/) ||
        ((this.labOrder?.approved ?? false) &&
          this.authorizedUser /*&&
          this.order.sent_liters <= 0 &&
          this.order.tank_readings.length == 0*/);
      this.form = this.buildForm();
      this.getTanks();
    }
  }

  buildForm(): FormGroup {
    return this.fb.group({
      approved: [this.edit ? this.labOrder.approved : undefined],
      observations: [this.edit ? this.labOrder.observations : undefined],
      quantity: [{ value: this.order?.sold_liters }],
      suppliedQuantity: [
        {
          value: this.edit ? this.suppliedQuantity : this.order.sold_liters,
          disabled: true,
        },
      ],
      suppliedPercentage: [
        {
          value: this.edit ? 100.0 : 0.0,
          disabled: true,
        },
      ],
    });
  }

  /*ngAfterViewInit(): void {
    initTE({ Select });

    this.productsPipeOne.changes.subscribe((queryList) => {
      queryList.toArray().forEach((select: ElementRef) => {
        new Select(
          select.nativeElement,
          this.selectOptions,
          this.selectCommonClasses
        );
      });
    });

    if (this.isTransportFull) {
      this.productsPipeTwo.changes.subscribe((queryList) => {
        queryList.toArray().forEach((select: ElementRef) => {
          new Select(
            select.nativeElement,
            this.selectOptions,
            this.selectCommonClasses
          );
        });
      });
    }
  }*/

  onChangeProduct(event: Event, pipeNumber: number, index: number) {
    const el = event.target as HTMLSelectElement;
    const productId = Number(el.value);
    const product = this.products.find((product) => product.id === productId);

    if (product) {
      if (pipeNumber === 1) {
        this.selectedTanks[index] = {
          id: this.selectedTanks[index]?.id,
          priority: this.selectedTanks[index]?.priority,
          product: product,
          quantity: 0,
          percentage: 0,
          tank_id: null,
          pipe_number: 1,
        };
        this.selectedTanks = [...this.selectedTanks];
      } else {
        this.selectedTanksPipeTwo[index] = {
          id: this.selectedTanksPipeTwo[index]?.id,
          priority: this.selectedTanksPipeTwo[index]?.priority,
          product: product,
          quantity: 0,
          percentage: 0,
          tank_id: null,
          pipe_number: 1,
        };
        this.selectedTanksPipeTwo = [...this.selectedTanksPipeTwo];
      }
    }
  }

  getTanks(): void {
    this.inventoryService.getTanks().subscribe({
      next: (res: InTankRes) => {
        if (res.status === "Éxito") {
          this.tanks = res.data;
          console.log("Tanques",this.tanks);
          if (this.edit) {
            if (this.order.product.full_mix) {
              const pushed = [];
              this.labOrder?.lab_order_details.forEach((detail) => {
                if (
                  detail.pipe_number === 1 &&
                  !pushed.includes(detail.product.id)
                ) {
                  pushed.push(detail.product.id);
                } else this.editPushedProductsPipeOne.push(detail.id);
              });

              this.selectedTanks = this.labOrder?.lab_order_details.filter(
                (labDetail) => labDetail.pipe_number === 1
              );

              this.tanksPipeOne = this.tanks
                .filter((tank) =>
                  this.labOrder.lab_order_details.find(
                    (t) => t.tank.id === tank.id && t.pipe_number === 1
                  )
                )
                .map((tank) => {
                  const labDetail = this.labOrder.lab_order_details.find(
                    (t) => t.tank.id === tank.id && t.pipe_number === 1
                  );
                  return {
                    ...tank,
                    required_quantity: labDetail?.quantity ?? 0,
                    priority: labDetail?.priority ?? 0,
                  };
                });
            } else {
              const pushed = [];
              this.labOrder?.lab_order_details.sort(
                (a, b) => a.priority - b.priority
              );
              this.labOrder?.lab_order_details.forEach((detail) => {
                if (
                  detail.pipe_number === 1 &&
                  !pushed.includes(detail.product.id)
                ) {
                  pushed.push(detail.product.id);
                } else this.editPushedProductsPipeOne.push(detail.id);
              });
              console.log("Pushed", pushed);
              console.log("EditPushedProductsPipeOne",this.editPushedProductsPipeOne);
              this.selectedTanks = this.labOrder?.lab_order_details.filter(
                (detail) => {
                  return detail.pipe_number === 1;
                }
              );
              
              this.order?.product?.compound_products.forEach(
                (compoundProduct) => {
                  const existProduct = this.selectedTanks.find(
                    (detail) =>
                      detail.product.id === compoundProduct.element_product_id
                  );

                  // if (!existProduct) {
                  //   const product = this.order?.product?.products.find(
                  //     (product) =>
                  //       product.id === compoundProduct.element_product_id
                  //   );

                  //   this.selectedTanks.push({
                  //     lab_detail_id: null,
                  //     product_id: product?.id,
                  //     product: product,
                  //     quantity: 0,
                  //     percentage: 0,
                  //     tank_id: null,
                  //     tank: null,
                  //     pipe_number: 1,
                  //   });
                  // }
                }
              );
              
              const doneProductExists = this.selectedTanks.find(
                (detail) => detail?.product?.id === this.order?.product?.id
              );

              if (!doneProductExists) {
                this.selectedTanks.splice(0, 0, {
                  lab_detail_id: null,
                  product_id: this.order?.product?.id,
                  product: this.order?.product,
                  quantity: 0,
                  percentage: 0,
                  tank_id: null,
                  tank: null,
                  pipe_number: 1,
                });
              }

              this.tanksPipeOne = this.selectedTanks.map((labDetail) => {
                return {
                  id: labDetail?.tank?.id,
                  tank: labDetail?.tank?.tank,
                  capacity: labDetail?.tank?.capacity,
                  quantity: labDetail?.quantity,
                  active: labDetail?.tank?.active,
                  products: [labDetail?.product],
                  pipe_number: labDetail?.pipe_number,
                  percentage: labDetail?.percentage,
                  required_quantity: labDetail?.quantity ?? 0,
                  priority: labDetail?.priority ?? 0,
                };
              });
            }

            this.selectedTanks = [...this.selectedTanks];
            this.tanksPipeOne = [...this.tanksPipeOne];

            if (this.isTransportFull) {
              if (this.order.product.full_mix) {
                const pushed = [];
                this.labOrder?.lab_order_details.forEach((detail) => {
                  if (
                    detail.pipe_number === 2 &&
                    !pushed.includes(detail.product.id)
                  ) {
                    pushed.push(detail.product.id);
                  } else this.editPushedProductsPipeTwo.push(detail.id);
                });

                this.selectedTanksPipeTwo =
                  this.labOrder?.lab_order_details.filter(
                    (labDetail) => labDetail.pipe_number === 2
                  );

                this.tanksPipeTwo = this.tanks
                  .filter((tank) =>
                    this.labOrder.lab_order_details.find(
                      (t) => t.tank.id === tank.id && t.pipe_number === 2
                    )
                  )
                  .map((tank) => {
                    const labDetail = this.labOrder.lab_order_details.find(
                      (t) => t.tank.id === tank.id && t.pipe_number === 2
                    );

                    return {
                      ...tank,
                      required_quantity: labDetail?.quantity ?? 0,
                      priority: labDetail?.priority ?? 0,
                    };
                  });
              } else {
                const pushed = [];
                this.labOrder?.lab_order_details.sort(
                  (a, b) => a.priority - b.priority
                );
                this.labOrder?.lab_order_details.forEach((detail) => {
                  if (
                    detail.pipe_number === 2 &&
                    !pushed.includes(detail.product.id)
                  ) {
                    pushed.push(detail.product.id);
                  } else this.editPushedProductsPipeTwo.push(detail.id);
                });

                this.selectedTanksPipeTwo =
                  this.labOrder?.lab_order_details.filter((detail) => {
                    return detail.pipe_number === 2;
                  });

                this.order?.product?.compound_products.forEach(
                  (compoundProduct) => {
                    const existProduct = this.selectedTanksPipeTwo.find(
                      (detail) =>
                        detail.product.id === compoundProduct.element_product_id
                    );

                    if (!existProduct) {
                      const product = this.order?.product?.products.find(
                        (product) =>
                          product.id === compoundProduct.element_product_id
                      );

                      this.selectedTanksPipeTwo.push({
                        lab_detail_id: null,
                        product_id: product?.id,
                        product: product,
                        quantity: 0,
                        percentage: 0,
                        tank_id: null,
                        tank: null,
                        pipe_number: 2,
                      });
                    }
                  }
                );

                const doneProductExists = this.selectedTanksPipeTwo.find(
                  (detail) => detail?.product?.id === this.order?.product?.id
                );

                if (!doneProductExists) {
                  this.selectedTanksPipeTwo.splice(0, 0, {
                    lab_detail_id: null,
                    product_id: this.order?.product?.id,
                    product: this.order?.product,
                    quantity: 0,
                    percentage: 0,
                    tank_id: null,
                    tank: null,
                    pipe_number: 2,
                  });
                }

                this.tanksPipeTwo = this.selectedTanksPipeTwo.map(
                  (labDetail) => {
                    return {
                      id: labDetail?.tank?.id,
                      tank: labDetail?.tank?.tank,
                      capacity: labDetail?.tank?.capacity,
                      quantity: labDetail?.quantity,
                      active: labDetail?.tank?.active,
                      products: [labDetail?.product],
                      pipe_number: labDetail?.pipe_number,
                      percentage: labDetail?.percentage,
                      required_quantity: labDetail?.quantity ?? 0,
                      priority: labDetail?.priority ?? 0,
                    };
                  }
                );
              }

              this.selectedTanksPipeTwo = [...this.selectedTanksPipeTwo];
              this.tanksPipeTwo = [...this.tanksPipeTwo];
            }
          } else {
            if (!this.isTransportFull) {
              if (!this.order.product.full_mix) {
                this.selectedTanks = this.order.product.compound_products.map(
                  (compoundProduct) => {
                    const detail = {
                      product_id: compoundProduct.element_product_id,
                      product: this.order.product.products.find(
                        (product) =>
                          product.id === compoundProduct.element_product_id
                      ),
                      quantity: 0,
                      percentage: compoundProduct.percentage,
                      tank_id: null,
                      pipe_number: 1,
                    };
                    return detail;
                  }
                );
                this.selectedTanks.splice(0, 0, {
                  product_id: this.order.product.id,
                  product: this.order.product,
                  quantity: 0,
                  percentage: 100,
                  tank_id: null,
                  pipe_number: 1,
                  priority: 1,
                });
                this.selectedTanks = [...this.selectedTanks];
              } else {
                this.selectedTanks.splice(0, 0, {
                  product_id: this.order.product.id,
                  product: this.order.product,
                  quantity: 0,
                  percentage: 100,
                  tank_id: null,
                  pipe_number: 1,
                  priority: 1,
                });
              }
            } else {
              if (this.order.product.full_mix) {
                this.selectedTanks.splice(0, 0, {
                  product_id: this.order.product.id,
                  product: this.order.product,
                  quantity: 0,
                  percentage: 100,
                  tank_id: null,
                  pipe_number: 1,
                  priority: 1,
                });

                this.selectedTanksPipeTwo.splice(0, 0, {
                  product_id: this.order.product.id,
                  product: this.order.product,
                  quantity: 0,
                  percentage: 100,
                  tank_id: null,
                  pipe_number: 2,
                  priority: 1,
                });
              } else {
                this.selectedTanks = this.order.product.compound_products.map(
                  (compoundProduct) => {
                    return {
                      product_id: compoundProduct.element_product_id,
                      product: this.order.product.products.find(
                        (product) =>
                          product.id === compoundProduct.element_product_id
                      ),
                      quantity: 0,
                      percentage: compoundProduct.percentage,
                      tank_id: null,
                      pipe_number: 1,
                    };
                  }
                );
                this.selectedTanks.splice(0, 0, {
                  product_id: this.order.product.id,
                  product: this.order.product,
                  quantity: 0,
                  percentage: 100,
                  tank_id: null,
                  pipe_number: 1,
                  priority: 1,
                });
                this.selectedTanks = [...this.selectedTanks];

                this.selectedTanksPipeTwo =
                  this.order.product.compound_products.map(
                    (compoundProduct) => {
                      return {
                        product_id: compoundProduct.element_product_id,
                        product: this.order.product.products.find(
                          (product) =>
                            product.id === compoundProduct.element_product_id
                        ),
                        quantity: 0,
                        percentage: compoundProduct.percentage,
                        tank_id: null,
                        pipe_number: 2,
                      };
                    }
                  );
                this.selectedTanksPipeTwo.splice(0, 0, {
                  product_id: this.order.product.id,
                  product: this.order.product,
                  quantity: 0,
                  percentage: 100,
                  tank_id: null,
                  pipe_number: 2,
                  priority: 1,
                });
                this.selectedTanksPipeTwo = [...this.selectedTanksPipeTwo];
              }
            }
          }
        }
      },
      error: (e) => { },
    });
  }

  isAuthorizedUser(): boolean {
    const role = this.dataService.userValue.roles[0]?.id;
    return role == 1 || role == 2 || role == 7;
  }

  onLeadingZero(event: Event): void {
    const num = (event.target as HTMLInputElement).value
      .replace("%", "")
      .replace(",", "")
      .replace(" ", "");
    (event.target as HTMLInputElement).value = `${Number(parseFloat(num))} %`;
  }

  onSelectTank(
    event: Event,
    tankId: number,
    pipeNumber: number,
    productId: number,
    labOrderDetail: LabOrder
  ): void {
    const target = event.target as HTMLSelectElement;
    target.value = "";
    const tank = this.tanks.slice().find((tank) => tank.id === Number(tankId));
    const requiredTotalProduct =
      this.calculatePercentage(labOrderDetail, pipeNumber) ?? 0;
    this.calculateTankTotal(tank, pipeNumber, productId, requiredTotalProduct);
  }

  calculateTankTotal(
    tank: GetTank,
    pipeNumber: number,
    productId: number,
    requiredTotalProduct: number,
    update: boolean = false
  ): number {
    if (pipeNumber === 1) {
      const currProductTanks =
        this.tanksPipeOne.length > 0
          ? this.tanksPipeOne.filter(
            (tanks) => tanks.products[0]?.id === productId
          )
          : [];
      const currRequiredTotalProduct =
        currProductTanks.length > 0
          ? currProductTanks
            .map((tank) => {
              if (!tank.required_quantity) tank.required_quantity = 0;

              if (
                isNaN(tank.required_quantity) ||
                typeof tank.required_quantity == "string"
              )
                return this.convertToNumber(
                  tank.required_quantity?.toString()
                );

              return tank.required_quantity;
            })
            ?.reduce((acc, tank) => acc + tank)
          : 0;

      if (currRequiredTotalProduct < requiredTotalProduct && !update) {
        this.tanksPipeOne.push({
          ...tank,
          priority: this.tanksPipeOne.length + 1,
          required_quantity: 0,
        });
        this.tanksPipeOne = [...this.tanksPipeOne];
      } else if (update) {
        return currRequiredTotalProduct;
      } else {
        this.openSnack(
          "snack-error",
          "Los litros requeridos del producto en la mezcla estan completos."
        );
      }
    }

    if (pipeNumber === 2) {
      const currProductTanks =
        this.tanksPipeTwo.length > 0
          ? this.tanksPipeTwo.filter(
            (tanks) => tanks.products[0]?.id === productId
          )
          : [];
      const currRequiredTotalProduct =
        currProductTanks.length > 0
          ? currProductTanks
            ?.filter((tanks) => tanks.products[0]?.id === productId)
            ?.map((tank) => {
              if (
                isNaN(tank.required_quantity) ||
                typeof tank.required_quantity == "string"
              )
                return this.convertToNumber(
                  tank.required_quantity.toString()
                );
              return tank.required_quantity;
            })
            ?.reduce((acc, tank) => acc + tank)
          : 0;

      if (currRequiredTotalProduct < requiredTotalProduct && !update) {
        this.tanksPipeTwo.push({
          ...tank,
          priority: this.tanksPipeTwo.length + 1,
          required_quantity: 0,
        });
        this.tanksPipeTwo = [...this.tanksPipeTwo];
      } else {
        return currRequiredTotalProduct;
      }
    }
  }

  hasAllCurrentRequiredTotalProduct(pipeNumber: number): Validation {
    const productTanks =
      pipeNumber === 1 ? this.tanksPipeOne : this.tanksPipeTwo;
    const toleranceLiters = 5;
    const tolerancePercentage = 0.005;

    if (this.order?.product?.full_mix) {
      const requiredQuantity = this.calculatePercentageByNumber(
        100,
        pipeNumber
      );
      const currRequiredTotalProduct = this.getCurrentTotalProduct(
        productTanks,
        this.order?.product?.id
      );

      if (
        currRequiredTotalProduct >= requiredQuantity - toleranceLiters &&
        currRequiredTotalProduct <= requiredQuantity + toleranceLiters &&
        currRequiredTotalProduct >= requiredQuantity * (1 - tolerancePercentage) &&
        currRequiredTotalProduct <= requiredQuantity * (1 + tolerancePercentage)
      )
        return { isValid: true, message: "" };
      else
        return {
          isValid: false,
          message: "La cantidad del producto no está dentro de la tolerancia requerida",
        };
    } else {
      const details =
        pipeNumber === 1
          ? this.selectedTanks.slice()
          : this.selectedTanksPipeTwo.slice();
      const hasDoneProduct = details.find(
        (detail) => detail.product_id == this.order?.product?.id
      );
      let currRequiredTotalProductDoneProduct = 0;

      if (hasDoneProduct) {
        const indexDoneProduct = details.findIndex(
          (detail) => detail.product_id == hasDoneProduct.product.id
        );
        details.splice(indexDoneProduct, 1);
        currRequiredTotalProductDoneProduct = this.getCurrentTotalProduct(
          productTanks,
          hasDoneProduct.product.id
        );

        if (
          currRequiredTotalProductDoneProduct == (pipeNumber == 1 ? this.convertToNumber(this.currentTypeTransportPipeOne.toString()) : this.convertToNumber(this.currentTypeTransportPipeTwo.toString()))
        ) {
          return { isValid: true, message: "" };
        }
      }
      const allCorrectValues = details.every((product) => {
        if (product.percentage == 0) return true;

        const requiredQuantity = this.calculatePercentageByNumber(
          product.percentage,
          pipeNumber
        );
        const currRequiredTotalProduct = this.getCurrentTotalProduct(
          productTanks,
          product.product.id
        );

        if (
          hasDoneProduct &&
          currRequiredTotalProductDoneProduct != 0 &&
          currRequiredTotalProduct > requiredQuantity
        )
          return false;
        else if (
          hasDoneProduct &&
          currRequiredTotalProductDoneProduct != 0 &&
          currRequiredTotalProduct ==
          requiredQuantity - currRequiredTotalProductDoneProduct
        )
          return true;
        else if (currRequiredTotalProduct > requiredQuantity) return false;
        else if (currRequiredTotalProduct == requiredQuantity) return true;
        else if (currRequiredTotalProduct >= (requiredQuantity - toleranceLiters)) return true;
        else if (currRequiredTotalProduct <= (requiredQuantity + toleranceLiters)) return true;
        else return false;
      });

      if (allCorrectValues) {
        return { isValid: true, message: "" };
      } else {
        return {
          isValid: false,
          message:
            "Revisa que los litros de los producto(s) tengan la cantidad necesaria.",
        };
      }
    }
  }

  getCurrentTotalProduct(productTanks: GetTank[], productId: number): number {
    productTanks = productTanks.filter(
      (tanks) => tanks.products[0]?.id === productId
    );
    const currRequiredTotalProduct =
      productTanks.length > 0
        ? productTanks
          ?.map((tank) => {
            if (
              isNaN(tank.required_quantity) ||
              typeof tank.required_quantity == "string"
            )
              return this.convertToNumber(tank.required_quantity.toString());
            return tank.required_quantity;
          })
          ?.reduce((acc, tank) => acc + tank)
        : 0;
    return currRequiredTotalProduct;
  }

  getCurrentTotalInventoryTank(productId: number): number {
    let total = 0;
    const currProductTanksPipeOne =
      this.tanksPipeOne.length > 0
        ? this.tanksPipeOne.filter(
          (tanks) => tanks.products[0]?.id === productId
        )
        : [];
    const currProductTanksPipeTwo =
      this.tanksPipeTwo.length > 0
        ? this.tanksPipeTwo.filter(
          (tanks) => tanks.products[0]?.id === productId
        )
        : [];
    const currProductTanks = [
      ...currProductTanksPipeOne,
      ...currProductTanksPipeTwo,
    ];
    const currRequiredTotalProduct =
      currProductTanks.length > 0
        ? currProductTanks
          .map((tank) => {
            if (
              isNaN(tank.required_quantity) ||
              typeof tank.required_quantity == "string"
            )
              return this.convertToNumber(tank.required_quantity?.toString());
            return tank.required_quantity;
          })
          ?.reduce((acc, tank) => acc + tank)
        : 0;
    total = currRequiredTotalProduct;

    return total;
  }

  preventOnKeyPress(event: KeyboardEvent) {
    if (event.key !== "Enter") event.preventDefault();
  }

  focusNext(index: number, type: string) {
    const nextElementSiblingId = `${type}${index + 1}`;
    const nextElement = document.querySelector(
      `#${nextElementSiblingId}`
    ) as HTMLElement;
    nextElement?.focus();
  }

  saveInputValue(
    event: Event,
    fromTank: boolean = false,
    tankId: number = null,
    pipeNumber: number = 1
  ): void {
    const currentPipeTank =
      pipeNumber === 1
        ? this.tanksPipeOne[Number(tankId)]
        : this.tanksPipeTwo[Number(tankId)];
    const currentTank = this.tanks.find(
      (tank) => tank.id === currentPipeTank?.id
    );
    const tmpTankQuantities = this.tmpTankQuantities.find(
      (tank) => tank.id === currentTank?.id && tankId === tank?.tankId
    );

    if (!tmpTankQuantities) {
      this.tmpTankQuantities.push({
        id: currentTank?.id,
        tankId,
        required_quantity: this.leadingZero(
          currentPipeTank?.required_quantity?.toString()
        ),
        pipeNumber,
      });
    } else {
      const index = this.tmpTankQuantities.indexOf(tmpTankQuantities);
      this.tmpTankQuantities[index].required_quantity = this.leadingZero(
        currentPipeTank?.required_quantity?.toString()
      );
    }
  }

  leadingZero(num: string): string {
    if (num == null) return "0.00";

    const dec = num.split(".")[1];

    if (!dec && num == "0") return "0.00";
    else if (!dec && num != "0") return `${num}.00`;

    const len = dec && dec.length > 2 ? dec.length : 2;
    const fixed = Number(parseFloat(num)).toFixed(len);
    return fixed;
  }

  onSelectPipe(pipeName: string): void {
    this.currentSelectedPipe = pipeName;
  }

  allMixProductsAreSelected(): boolean {
    const allMixInPipeOne = this.order.product.compound_products.every(
      (product) =>
        this.selectedTanks.find(
          (labDetail) => labDetail.product.id === product.element_product_id
        )
    );

    if (!this.isTransportFull) return allMixInPipeOne;
    else {
      const allMixInPipeTwo = this.order.product.compound_products.every(
        (product) =>
          this.selectedTanksPipeTwo.find(
            (labDetail) => labDetail.product.id === product.element_product_id
          )
      );

      return allMixInPipeOne && allMixInPipeTwo;
    }
  }

  allPipeOneProductsAreSelected(): boolean {
    return this.order.product.compound_products.every((product) =>
      this.selectedTanks.find(
        (labDetail) => labDetail.product.id === product.element_product_id
      )
    );
  }

  allPipeTwoProductsAreSelected(): boolean {
    return this.order.product.compound_products.every((product) =>
      this.selectedTanksPipeTwo.find(
        (labDetail) => labDetail.product.id === product.element_product_id
      )
    );
  }

  onSupplyChange(
    event: Event,
    tankId: number,
    pipeNumber: number,
    productId: number,
    labOrderDetail: LabOrder
  ): void {
    const element = event.target as HTMLInputElement;
    const currentPipeTank =
      pipeNumber === 1
        ? this.tanksPipeOne[Number(tankId)]
        : this.tanksPipeTwo[Number(tankId)];
    const currentTank = this.tanks.find(
      (tank) => tank.id === currentPipeTank?.id
    );
    const requiredTotalProduct =
      this.calculatePercentage(labOrderDetail, pipeNumber) ?? 0;
    const total = this.calculateTankTotal(
      currentTank,
      pipeNumber,
      productId,
      requiredTotalProduct,
      true
    );

    if (productId == this.order.product.id) {
      if (requiredTotalProduct < 0) {
        event.preventDefault();
        this.backQtyValidation(
          "Los litros requeridos de mezcla terminada no pueden ser mayor a los vendidos.",
          element,
          tankId,
          pipeNumber,
          currentPipeTank
        );
      }

      return;
    }

    if (total > requiredTotalProduct) {
      event.preventDefault();
      this.backQtyValidation(
        "Los litros requeridos no pueden ser mayores al porcentaje de la mezcla.",
        element,
        tankId,
        pipeNumber,
        currentPipeTank
      );
      return;
    }

    const currentCapacity =
      currentTank?.quantity - this.getCurrentTotalInventoryTank(productId);

    if (currentCapacity < 0) {
      this.backQtyTankValidation(
        tankId,
        pipeNumber,
        "Ya no hay disponibilidad del producto en el tanque."
      );
      return;
    }

    this.saveInputValue(event, false, tankId, pipeNumber);
    this.tanksPipeOne = [...this.tanksPipeOne];
    if (this.isTransportFull) {
      this.tanksPipeTwo = [...this.tanksPipeTwo];
    }
  }

  backQtyValidation(
    msg: string,
    element: HTMLInputElement,
    tankId: number,
    pipeNumber: number,
    currentPipeTank: GetTank
  ) {
    this.openSnack("snack-error", msg);
    const dataValue =
      this.convertToNumber(element.getAttribute("data-value")) ?? 0;

    if (pipeNumber == 1)
      this.tanksPipeOne[Number(tankId)].required_quantity = dataValue;
    else this.tanksPipeTwo[Number(tankId)].required_quantity = dataValue;
  }

  backQtyTankValidation(tankId: number, pipeNumber: number, msg: string): void {
    this.openSnack("snack-error", msg);

    const currentPipeTank =
      pipeNumber === 1
        ? this.tanksPipeOne[Number(tankId)]
        : this.tanksPipeTwo[Number(tankId)];

    if (pipeNumber == 1)
      this.tanksPipeOne[Number(tankId)].required_quantity =
        this.convertToNumber("0.0");
    else
      this.tanksPipeTwo[Number(tankId)].required_quantity =
        this.convertToNumber("0.0");
  }

  calculatePercentage(orderDetail: LabOrderDetail, numberPipe: number): number {
    const hasDoneProduct =
      numberPipe === 1
        ? this.selectedTanks.find(
          (detail) => this.order.product.id === detail.product.id
        )
        : this.selectedTanksPipeTwo.find(
          (detail) => this.order.product.id === detail.product.id
        );
    const tmpSoldLts =
      numberPipe === 1
        ? this.currentTypeTransportPipeOne
        : this.currentTypeTransportPipeTwo;
    let currentSoldLts = null;

    if (hasDoneProduct && tmpSoldLts !== 0) {
      const currentDoneProductTanks =
        numberPipe === 1
          ? this.tanksPipeOne.filter(
            (tank) => tank.products[0]?.id === hasDoneProduct.product.id
          )
          : this.tanksPipeTwo.filter(
            (tank) => tank.products[0]?.id === hasDoneProduct.product.id
          );
      const currentRequiredQtyDoneProduct = currentDoneProductTanks.map(
        (tank) => this.convertToNumber(tank?.required_quantity?.toString())
      );
      const currentSumRequiredQtyDoneProduct =
        currentRequiredQtyDoneProduct.length > 0
          ? currentRequiredQtyDoneProduct.reduce((a, b) => a + b)
          : 0;
      currentSoldLts = hasDoneProduct
        ? this.convertToNumber(tmpSoldLts?.toString()) -
        currentSumRequiredQtyDoneProduct
        : this.convertToNumber(tmpSoldLts?.toString());
    }

    if (currentSoldLts) {
      return Math.round(
        (this.convertToNumber(orderDetail?.percentage.toString()) / 100) *
        currentSoldLts
      );
    }

    const soldLts = this.isTransportFull
      ? this.order.sold_liters / 2
      : this.order.sold_liters;

    if (numberPipe === 1) {
      return Math.round(
        (this.convertToNumber(orderDetail?.percentage.toString()) / 100) *
        (this.order.pipe_one == 0
          ? soldLts
          : this.currentTypeTransportPipeOne)
      );
    } else {
      return Math.round(
        (this.convertToNumber(orderDetail?.percentage.toString()) / 100) *
        (this.order.pipe_two == 0
          ? soldLts
          : this.currentTypeTransportPipeTwo)
      );
    }
  }

  calculatePercentageByNumber(quantity: number, numberPipe: number): number {
    const hasDoneProduct =
      numberPipe === 1
        ? this.selectedTanks.find(
          (detail) => this.order.product.id === detail.product.id
        )
        : this.selectedTanksPipeTwo.find(
          (detail) => this.order.product.id === detail.product.id
        );
    const tmpSoldLts =
      numberPipe === 1
        ? this.currentTypeTransportPipeOne
        : this.currentTypeTransportPipeTwo;
    let currentSoldLts = null;

    if (hasDoneProduct && tmpSoldLts !== 0) {
      const currentDoneProductTanks =
        numberPipe === 1
          ? this.tanksPipeOne.filter(
            (tank) => tank.products[0]?.id === hasDoneProduct.product.id
          )
          : this.tanksPipeTwo.filter(
            (tank) => tank.products[0]?.id === hasDoneProduct.product.id
          );
      const currentRequiredQtyDoneProduct = currentDoneProductTanks.map(
        (tank) => this.convertToNumber(tank?.required_quantity?.toString())
      );
      const currentSumRequiredQtyDoneProduct =
        currentRequiredQtyDoneProduct.length > 0
          ? currentRequiredQtyDoneProduct.reduce((a, b) => a + b)
          : 0;
      currentSoldLts = hasDoneProduct
        ? this.convertToNumber(tmpSoldLts?.toString()) -
        currentSumRequiredQtyDoneProduct
        : this.convertToNumber(tmpSoldLts?.toString());
    }

    if (currentSoldLts) {
      return Math.round((quantity / 100) * currentSoldLts);
    }

    const soldLts = this.isTransportFull
      ? Math.round(this.order.sold_liters / 2)
      : Math.round(this.order.sold_liters);

    if (numberPipe === 1) {
      return Math.round(
        (quantity / 100) *
        (this.order.pipe_one == 0
          ? soldLts
          : this.currentTypeTransportPipeOne)
      );
    } else {
      return Math.round(
        (quantity / 100) *
        (this.order.pipe_two == 0
          ? soldLts
          : this.currentTypeTransportPipeTwo)
      );
    }
  }

  onChangeSoldLts(event: Event) {
    const soldLts = this.convertToNumber(
      this.form.controls.quantity.value.toString()
    );
    this.order.sold_liters = soldLts;

    if (this.isTransportFull) {
      this.currentTypeTransportPipeOne = soldLts / 2;
      this.currentTypeTransportPipeTwo = soldLts / 2;
    } else {
      this.currentTypeTransportPipeOne = soldLts;
    }

    this.onChangePrice();
  }

  calculateRequiredQuantityAndPercentage(dataSource: LabOrderDetail[]): any {
    let totalQuantity = 0;

    for (const tank of dataSource) {
      if (
        String(tank.quantity).includes(".") ||
        String(tank.quantity).includes(",")
      ) {
        const val = String(tank.quantity).replace(",", "");
        const required = Number(parseFloat(val));
        totalQuantity += required;
      }
    }
    console.log(totalQuantity);
    

    return { totalQuantity };
  }

  onChangePrice(): void {
    const hasExternalComission = this.order.external_commision != 0;
    const agreedPrice = this.convertToNumber(
      this.order.agreed_price?.toString()
    );
    const transportPrice = this.convertToNumber(
      this.order.shipping_price?.toString()
    );
    const internalComission = this.convertToNumber(
      this.order.internal_commision?.toString()
    );
    const distributionCost = this.convertToNumber(
      this.order.distribution_cost?.toString()
    );

    const shippings = this.order.shippings.length - 1;
    const clientDiscount = this.convertToNumber(
      this.order.discount?.toString()
    );
    const quantity = this.convertToNumber(this.order.sold_liters?.toString());

    let tmpPrice = 0.0;
    let tmpPriceWithoutDiscount = 0.0;
    let externalComission = 0.0;

    if (agreedPrice != null && internalComission != null) {
      if (!isNaN(agreedPrice)) tmpPrice += agreedPrice;

      if (!isNaN(transportPrice)) tmpPrice += transportPrice;

      if (!isNaN(internalComission)) tmpPrice += internalComission;

      if (hasExternalComission) {
        externalComission = this.convertToNumber(
          this.order.external_commision?.toString()
        );

        if (externalComission != null && !isNaN(externalComission)) {
          tmpPrice += externalComission;
        }
      } else {
        this.order.external_commision = 0.0;
      }

      let totalShippingsPrice = 0.0;

      if (shippings > 0) {
        totalShippingsPrice = this.order.check_own_transportist
          ? 0.0
          : distributionCost * shippings;
        this.order.total_dist_cost = totalShippingsPrice;

        if (!isNaN(totalShippingsPrice)) tmpPrice += totalShippingsPrice;
      } else {
        this.order.total_dist_cost = 0.0;
      }

      //tmpPriceWithoutDiscount += tmpPrice;
      tmpPrice -= clientDiscount;

      if (!isNaN(tmpPrice)) {
        this.order.total_price_liter = tmpPrice;
      }

      const costs = this.generateCosts(
        internalComission,
        externalComission,
        totalShippingsPrice
      );

      const utility = tmpPrice - costs;
      if (quantity != null && !isNaN(quantity)) {
        const priceWithoutDiscount =
          this.order.total_price_liter - this.order.discount ?? 0.0;
        const priceWithDiscount =
          this.convertToNumber(this.order.total_price_liter.toString()) ?? 0.0;
        const importTotal = quantity * priceWithoutDiscount;
        const finalTotal = quantity * priceWithDiscount;
        const totalDiscount = importTotal - finalTotal;
        this.calculateTax();
      }
    }
  }

  generateCosts(
    internalComission: number,
    externalComission: number,
    totalShippingsPrice: number
  ): number {
    const hasExternalComission = this.order.external_commision != 0;
    const logicPrice = this.order.product?.list_price ?? 0;
    const transportPrice = this.convertToNumber(
      this.order.shipping_price?.toString()
    );

    let sumCosts = 0;

    if (logicPrice != null && !isNaN(logicPrice)) {
      sumCosts += logicPrice;
    }

    if (transportPrice != null) {
      const tmpTransportPrice = !isNaN(internalComission)
        ? transportPrice
        : 0.0;
      sumCosts += this.order.check_own_transportist ? 0.0 : tmpTransportPrice;
    }

    if (internalComission != null && !isNaN(internalComission)) {
      sumCosts += internalComission;
    }

    if (hasExternalComission) {
      if (externalComission != null && !isNaN(externalComission)) {
        sumCosts += externalComission;
      }
    }

    return this.order.shippings.length - 1 > 0
      ? sumCosts + totalShippingsPrice
      : sumCosts;
  }

  calculateTax(): void {
    const quantity = this.convertToNumber(this.order?.sold_liters?.toString());
    const total = quantity * this.order.total_price_liter;

    if (this.order.ieps == 0 || this.order.ieps == null) {
      const subtotal = total / 1.16;
      const totalIva = subtotal * 0.16;
      this.order.ieps = null;
    } else if (this.order.ieps != 0) {
      const iepsCost = this.order.product?.ieps_cost ?? 0;
      const totalIepsCost = quantity * iepsCost;
      const totalWithoutIeps = total - totalIepsCost;
      const subtotal = totalWithoutIeps / 1.16;
      const totalIva = subtotal * 0.16;
      this.order.ieps = totalIepsCost;
    }
  }

  convertToNumber(value: string): number {
    if (String(value).includes(".") || String(value).includes(",")) {
      const val = String(value).replace(",", "");
      return Number(parseFloat(val));
    } else return Number(parseFloat(value));
  }

  hasSelectedTanks(): boolean {
    if (this.isTransportFull) {
      return this.tanksPipeOne.length > 0 && this.tanksPipeTwo.length > 0;
    } else {
      return this.tanksPipeOne.length > 0;
    }
  }

  hasRequiredQuantity(): boolean {
    if (this.isTransportFull) {
      const isValidPipeOne = this.hasAllCurrentRequiredTotalProduct(1);
      if (!isValidPipeOne.isValid) {
        this.openSnack("snack-error", isValidPipeOne.message);
        return false;
      }

      const isValidPipeTwo = this.hasAllCurrentRequiredTotalProduct(2);
      if (!isValidPipeTwo.isValid) {
        this.openSnack("snack-error", isValidPipeTwo.message);
        return false;
      }
    } else {
      const isValidPipeOne = this.hasAllCurrentRequiredTotalProduct(1);
      if (!isValidPipeOne.isValid) {
        this.openSnack("snack-error", isValidPipeOne.message);
        return false;
      }
    }

    return true;
  }

  getPipeOneTotal(): number {
    return this.calculateRequiredQuantityAndPercentage(this.selectedTanks)
      .totalQuantity;
  }

  getPipeTwoTotal(): number {
    return this.calculateRequiredQuantityAndPercentage(
      this.selectedTanksPipeTwo
    ).totalQuantity;
  }

  onEditPipeLts(event: Event, pipeNumber: number) {
    const total = this.isTransportFull
      ? this.convertToNumber(this.currentTypeTransportPipeOne.toString()) +
      this.convertToNumber(this.currentTypeTransportPipeTwo.toString())
      : this.convertToNumber(this.currentTypeTransportPipeOne.toString());

    if (total > this.order.sold_liters) {
      this.openSnack(
        "snack-error",
        "Los litros transportados no pueden ser mayores a los total vendido."
      );

      if (pipeNumber === 1) {
        this.currentTypeTransportPipeOne = this.tmpTypeTransportPipeOne;
      } else {
        this.currentTypeTransportPipeTwo = this.tmpTypeTransportPipeTwo;
      }

      event.preventDefault();
      return;
    }

    const el = event.target as HTMLInputElement;
    const value = el.value;

    if (pipeNumber === 1) {
      this.tmpTypeTransportPipeOne = this.convertToNumber(value);
      this.order.pipe_one = this.currentTypeTransportPipeOne;
      this.selectedTanks = [...this.selectedTanks];
    } else {
      this.tmpTypeTransportPipeTwo = this.convertToNumber(value);
      this.order.pipe_two = this.currentTypeTransportPipeTwo;
      this.selectedTanksPipeTwo = [...this.selectedTanksPipeTwo];
    }
  }

  deleteTank(selectedTankIndex: number, pipeNumber: number): void {
    if (pipeNumber === 1) {
      this.tanksPipeOne.splice(selectedTankIndex, 1);
      this.tanksPipeOne = [...this.tanksPipeOne];
    } else {
      this.tanksPipeTwo.splice(selectedTankIndex, 1);
      this.tanksPipeTwo = [...this.tanksPipeTwo];
    }
  }

  sendData(): void {
    if (!this.hasSelectedTanks()) {
      this.openSnack("snack-error", "Debe seleccionar al menos un tanque");
      return;
    }

    if (!this.hasRequiredQuantity()) return;
    const ladOrderDetailsId = [];
    const labDetails = this.tanksPipeOne
      .map((tank) => {
        const currentDetail = this.selectedTanks.find((selectedTank) => {
          if (this.labOrder) {
            return (
              ((selectedTank?.product?.id === tank?.products[0]?.id &&
                selectedTank?.tank?.id == tank?.id) ||
                selectedTank?.product?.id === tank?.products[0]?.id) &&
              selectedTank?.priority == tank?.priority
            );
          } else {
            return (
              (selectedTank?.product?.id === tank?.products[0]?.id && selectedTank?.tank?.id == tank?.id) ||
              selectedTank?.product?.id === tank?.products[0]?.id
            );
          }
        });
        let tmpPercentage = 0;
        if (!currentDetail) {
          const tmpDetail = this.selectedTanks.find((selectedTank) => {
            (selectedTank?.product?.id === tank?.products[0]?.id && selectedTank?.tank?.id == tank?.id) || selectedTank?.product?.id === tank?.products[0]?.id
          });

          tmpPercentage = tmpDetail?.percentage ?? 0;
        }

        let labDetailId = currentDetail?.id ?? null;

        if (ladOrderDetailsId.includes(labDetailId)) {
          labDetailId = null;
        }

        if (currentDetail) ladOrderDetailsId.push(currentDetail.id);

        return {
          lab_detail_id: labDetailId,
          product_id: tank.products[0].id,
          tank_id: tank.id,
          quantity: tank.required_quantity,
          percentage: currentDetail ? currentDetail?.percentage : tmpPercentage,
          pipe_number: 1,
          priority: tank.priority,
        };
      })
      .filter((detail) => detail.tank_id != undefined);
    const labDetailsPipeTwo = !this.isTransportFull
      ? undefined
      : this.tanksPipeTwo
        .map((tank) => {
          const currentDetail = this.selectedTanksPipeTwo.find((selectedTank) => {
            if (this.labOrder) {
              return (
                ((selectedTank?.product?.id === tank?.products[0]?.id &&
                  selectedTank?.tank?.id == tank?.id) ||
                  selectedTank?.product?.id === tank?.products[0]?.id) &&
                selectedTank?.priority == tank?.priority
              );
            } else {
              return (
                (selectedTank?.product?.id === tank?.products[0]?.id && selectedTank?.tank?.id == tank?.id) ||
                selectedTank?.product?.id === tank?.products[0]?.id
              );
            }
          });
          let labDetailId = currentDetail?.id ?? null;
          if (ladOrderDetailsId.includes(labDetailId)) {
            labDetailId = null;
          }

          let tmpPercentage = 0;
          if (!currentDetail) {
            const tmpDetail = this.selectedTanksPipeTwo.find((selectedTank) => {
              (selectedTank?.product?.id === tank?.products[0]?.id && selectedTank?.tank?.id == tank?.id) || selectedTank?.product?.id === tank?.products[0]?.id
            });

            tmpPercentage = tmpDetail?.percentage ?? 0;
          }

          return {
            lab_detail_id: labDetailId,
            product_id: tank.products[0].id,
            tank_id: tank.id,
            quantity: tank.required_quantity,
            percentage: currentDetail ? currentDetail.percentage : tmpPercentage,
            pipe_number: 2,
            priority: tank.priority,
          };
        })
        .filter((detail) => detail.tank_id != undefined);

    if (this.order && this.form.valid) {
      const labOrder = {
        attendant_id: this.dataService.userValue.id,
        pump: this.order.pump ? this.order.pump.id : null,
        ...this.form.value,
        approved: this.form.value.approved ?? false,
        observations: this.form.value.observations ?? "N/A",
        lab_details: !this.isTransportFull
          ? labDetails
          : [...labDetails, ...labDetailsPipeTwo],
        lab_comments: null,
      };

      this.order = {
        seller_id: this.order.seller.id,
        client_id: this.order.client.id,
        transportist_id: this.order?.transportist?.id,
        order_status_id: this.order.order_status.id,
        transport_type_id: this.order.transport_type.id,
        pump_measure_id: this.order.pump_measure.id,
        loading_type_id: this.order.loading_type.id,
        payment_method_id: this.order.payment_method.id,
        additional_requirement_id: this.order.additional_requirement.id,
        product_id: this.order.product.id,
        product_specifications: "N/A",
        transportist_route_price_id: this.order?.transportist_route_price?.id,
        vendor_id: this.order.vendor.id,
        address_id: this.order.address.id,
        ...this.order,
        shippings: this.order.shippings.map((s) => {
          return {
            address_id: s?.address?.id,
            code: "code",
            unit: "unit",
            quantity: 1,
            end_date: "2023-12-01",
          };
        }),
        order_comments: this.order.order_comments.map((c) => {
          return {
            user_id: c.user.id,
            order_comment_id: c.id,
            comment: c.comment,
            type: 1
          }
        }),
        order_attachments: this.order.order_attachments.map((a) => {
          return {
            active: a.active,
            created_at: a.created_at,
            extension: a.extension,
            folder: a.folder,
            id: a.id,
            order_attachment_id: a.id,
            name: a.name,
            updated_at: a.updated_at,
            url: a.url,
          }
        }),
        tank_readings: this.order.tank_readings.map((t) => {
          return {
            ...t,
            tank_id: t.tank.id
          }
        }),
        lab_order: labOrder,
        pipe_one: this.convertToNumber(
          this.currentTypeTransportPipeOne.toString()
        ),
      };

      if (this.isTransportFull) {
        this.order.pipe_two = this.convertToNumber(
          this.currentTypeTransportPipeTwo.toString()
        );
      }
      this.update();
    } else this.openSnack("snack-error", "Revisa los datos");
  }

  update(): void {
    this.salesService.editSale(this.order).subscribe({
      next: (v) => {
        this.openSnack("snack-success", v.message);
        this.dialogRef.close({ status: 200 });
      },
      error: (e) => {
        this.openSnack("snack-error", e.error.message);
      },
    });
  }

  addProduct(pipeNumber: number, product: Product): void {
    this.order?.product?.compound_products.push({
      id: null,
      product: product,
      element_product_id: product.id,
      compound_product_id: null,
      percentage: 0,
      active: true,
    });

    if (pipeNumber === 1) {
      this.selectedTanks.push({
        product_id: product.id,
        product: product,
        quantity: 0,
        percentage: 0,
        tank_id: null,
        pipe_number: 1,
      });
      this.selectedTanks = [...this.selectedTanks];
    } else {
      this.selectedTanksPipeTwo.push({
        product_id: product.id,
        product: product,
        quantity: 0,
        percentage: 0,
        tank_id: null,
        pipe_number: 2,
      });
      this.selectedTanksPipeTwo = [...this.selectedTanksPipeTwo];
    }
  }

  deleteProduct(pipeNumber: number, productIndex: number): void {
    if (pipeNumber === 1) {
      this.tanksPipeOne = this.tanksPipeOne.filter(tank => tank.products[0]?.id !== productIndex);
      this.selectedTanks = this.selectedTanks.filter(tank => tank.product.id !== productIndex);
    } else {
      this.tanksPipeTwo = this.tanksPipeTwo.filter(tank => tank.products[0]?.id !== productIndex);
      this.selectedTanksPipeTwo = this.selectedTanksPipeTwo.filter(tank => tank.product.id !== productIndex);
    }
  }



  getProducts(): void {
    this.inventoryService.getProducts().subscribe((res: InProductRes) => {
      if (res.status === "Éxito") {
        this.products = res.data.filter((product: Product) => {
          return product.active && product.full_mix;
        });
      }
    });
  }

  openAddSelectProduct(pipeNumber: number): void {
    const dialogRef = this.dialog.open(AddLabDetailProductComponent, {
      data: { products: this.products },
    });
    dialogRef.afterClosed().subscribe((product: Product) => {
      if (product) {
        this.addProduct(pipeNumber, product);
      }
    });
  }

  openDeleteProduct(pipeNumber: number, index:any): void {
    const dialogRef = this.dialog.open(DeleteLabDetailProductComponent, {
      data: { productIndex: index },
    });
    
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteProduct(pipeNumber, index);
      } 
    });
  }


  closeModal(): void {
    this.dialogRef.close();
  }

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
      verticalPosition: "top",
    });
  }
}
