import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from 'app/models/user';
import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserServicesService {
  public emitSubjet = new Subject<any>();
  dataEmitted = this.emitSubjet.asObservable();

  constructor(public http: HttpClient) { }

  /* Auth */
  login(data): Observable<any> {
    return this.http.post<any>(`${environment.url}auth/sign-in`, data, {})
  }

  logOut(): Observable<any> {
    //?revokeAll=true
    return this.http.post<any>(`${environment.url}auth/logout`, {}, {})
  }

  /* Myself */
  getMyself(): Observable<any> {
    return this.http.get<any>(`${environment.url}auth/me`)
  }

  changePassword(data: any): Observable<any> {
    return this.http.put<any>(`${environment.url}admin/passwords/change-my-password`, data, {})
  }

  /* Users */
  createUser(data: User): Observable<any> {
    return this.http.post<any>(`${environment.url}admin/users/create/`, data, {})
  }

  readUsers(): Observable<any> {
    return this.http.get<any>(`${environment.url}admin/users/get/`)
  }

  updateUser(data: User): Observable<any> {
    return this.http.patch<any>(`${environment.url}admin/users/update/${data.id}`, data, {})
  }

  deleteUSer(id: number): Observable<any> {
    return this.http.delete<any>(`${environment.url}admin/users/delete/${id}`)
  }

  updatePassword(id: number, data:any): Observable<any>{
    return this.http.put<any>(`${environment.url}admin/passwords/change-my-password/${id}`, data, {})
  }
}
