import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";
import { ClientsService } from "app/apiServices/clients.service";
import { NotificationsComponent } from "app/components/alerts/notifications/notifications.component";
import { FormValidatorService } from "app/core/service/form-validator.service";
import { CFID } from "app/models/cfid";
import { Company } from "app/models/company";

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.css"],
})
export class FormComponent implements OnInit {
  regimen: any[];
  array: CFID[] = [];
  cfdi: CFID;

  client: boolean = false;
  transportist: boolean = false;
  supplier: boolean = false;

  form: FormGroup;
  dataSource: Company;
  edit = false;
  dialogTitle: string;

  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";

  validationMessages = {
    legal_id: [{ type: "required", message: "Ingrese el RFC" }],
    contact_name: [{ type: "required", message: "Campo necesario" }],
    contact_phone: [{ type: "required", message: "Campo necesario" }],
    legal_name: [{ type: "required", message: "Campo necesario" }],
    folio: [{ type: "required", message: "Campo necesario" }],
  };

  ColumnMode = ColumnMode;
  @ViewChild("tableAdresses") table: DatatableComponent;

  constructor(
    private dialogRef: MatDialogRef<FormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public formService: FormValidatorService,
    public apiService: ClientsService,
    private _snack: MatSnackBar
  ) {
    this.data = data;

    if (data.action === "edit") {
      this.dialogTitle = "Editar Compañia";
      this.dataSource = data.data;
      this.edit = true;
    } else {
      this.dialogTitle = "Agregar Compañia";
      this.dataSource = {};
    }
  }

  ngOnInit() {
    this.cfdi = {
      id: 3,
      uso_cfdi: "G03",
      descripcion: "Gastos en general",
      active: true,
    };
    this.getRegimenFiscal();
    this.getCFDI();
    this.form = this.createForm();
  }

  getRegimenFiscal() {
    this.apiService.getRegimen().subscribe((r) => {
      this.regimen = r.data;
    });
  }

  getCFDI() {
    this.apiService.getCFDI().subscribe((r) => {
      r.data.forEach((element) => {
        if (element.uso_cfdi === "603") {
          this.cfdi = element;
        }
      });
    });
  }

  createForm() {
    if (this.edit) {
      if (this.dataSource.clients.filter((c) => c.active).length > 0) this.client = true;
      if (this.dataSource.suppliers.filter((s) => s.active).length > 0) this.supplier = true;
      if (this.dataSource.transportists.filter((t) => t.active).length > 0) this.transportist = true;

      return this.fb.group({
        id: [this.dataSource.id],
        regimen_fiscal_id: [
          this.dataSource.regimen_fiscal.id,
          [Validators.required],
        ],
        legal_id: [this.dataSource.legal_id, [Validators.required]],
        contact_name: [this.dataSource.contact_name, [Validators.required]],
        contact_phone: [this.dataSource.contact_phone, [Validators.required]],
        legal_name: [this.dataSource.legal_name, [Validators.required]],
        main: [this.dataSource.main || false, []],
        folio: [this.dataSource.folio, []],
        is_supplier: [this.supplier, []],
        is_client: [this.client, []],
        is_transportist: [this.transportist, []],
        seller: [false, []],
        uso_cfdi_id: [this.cfdi.id, []],
      });
    } else {
      return this.fb.group({
        id: [this.dataSource.id],
        regimen_fiscal_id: [
          this.dataSource.regimen_fiscal_id,
          [Validators.required],
        ],
        legal_id: [this.dataSource.legal_id, [Validators.required]],
        contact_name: [this.dataSource.contact_name, [Validators.required]],
        contact_phone: [this.dataSource.contact_phone, [Validators.required]],
        legal_name: [this.dataSource.legal_name, [Validators.required]],
        main: [this.dataSource.main || false, []],
        folio: [this.dataSource.folio, []],
        is_supplier: [this.dataSource.is_supplier || false, []],
        is_client: [this.dataSource.is_client || false, []],
        is_transportist: [this.dataSource.is_transportist || false, []],
        seller: [false, []],
        uso_cfdi_id: [this.cfdi.id, []],
      });
    }
  }

  save(): void {
    if (this.form.valid) {
      this.dataSource = this.form.value;
      this.storeUpdate(this.edit ? "update" : "store");
    } else {
      this.formService.allFields(this.form);
      this.showAlert("Datos incorrectos");
    }
  }

  storeUpdate(method: string): void {
    const data = {
      status: 200,
    };

    if (method === "store") {
      this.apiService.createCompany(this.dataSource).subscribe(
        (r) => {
          this.closeModal(data);
        },
        (error) => {
          this.showAlert(error.error.message);
        }
      );
    } else {
      this.apiService.updateCompany(this.dataSource).subscribe(
        (r) => {
          this.closeModal(data);
        },
        (error) => {
          this.showAlert(error.error.message);
        }
      );
    }
  }

  showAlert(message: string) {
    const config = {
      duration: 5000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    };

    this.openSnack("snack-error", message);
  }

  closeModal(data: any): void {
    this.dialogRef.close(data);
  }

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
}
