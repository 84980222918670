import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-costing-form',
  templateUrl: './costing-form.component.html',
  styleUrls: ['./costing-form.component.css']
})
export class CostingFormComponent implements OnInit{
  purchase: any
  total: any

  constructor(
    @Inject(MAT_DIALOG_DATA) public origin: any,
    private dialogRef: MatDialogRef<CostingFormComponent>,
  ){
    console.log("Origin", this.origin)
    this.purchase = this.origin.purchaseOrder
  }
  ngOnInit(): void {
    this.totalValues()
  } 

  totalValues(){
    var subDetail: number = 0.0
    this.purchase.purchase_order_details.forEach(detail => {
      subDetail += detail.total
    });

    var subPurchases: number = 0.0
    this.purchase.subPurchases.forEach(sub => {
      subPurchases += sub.purchase_order_details[0].total
    });

    this.total = "$ " + (subPurchases + subDetail).toFixed(6)
  } 

  closeModal(): void {
    this.dialogRef.close(true);
  }
}
