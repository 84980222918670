<div class="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
    <div class="bg-white rounded-lg shadow-lg p-6 w-96">
      <h1 class="text-xl font-bold mb-4">Confirmar Eliminación</h1>
      <div class="mb-4">
        <p>¿Estás seguro de que deseas eliminar este producto?</p>
      </div>
      <div class="flex justify-end space-x-4">
        <button class="bg-gray-300 text-gray-700 px-4 py-2 rounded" (click)="close()">Cancelar</button>
        <button class="bg-red-500 text-white px-4 py-2 rounded" (click)="confirmDelete()">Eliminar</button>
      </div>
    </div>
  </div>