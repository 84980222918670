<aside
  id="logo-sidebar"
  class="overflow-y-auto bg-slate-800 fixed top-0 left-0 z-40 w-64 h-screen pt-12 transition-transform -translate-x-full border-r border-gray-200 sm:translate-x-0"
  aria-label="logo-sidebar"
>
  <div  class="h-full px-4 pb-4 bg-slate-800">
    <div
      class="px-3 md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 h-auto items-center flex-1 rounded"
    >
      <ng-container *ngFor="let category of categories; let i = index;">
        <hr *ngIf="i == 0" class="mt-20 mb-4 md:my-4 md:min-w-full" />
        <hr *ngIf="i > 0" class="my-4 md:min-w-full" />
        <div
          (click)="toggleCollapse($event, category.id)"
          class="grid grid-cols-2 cursor-pointer"
        >
          <h6
            class="md:min-w-full text-slate-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline self-center"
          >
            {{ category.name }}
          </h6>
          <i
            [ngClass]="{
              'fa-caret-up': category.id == currentCategoryViewId,
              'fa-caret-down': category.id !== currentCategoryViewId
            }"
            class="fa text-md pb-4 self-center"
          >
          </i>
        </div>

        <ul
          #collapsible
          id="collapsible{{ category.id }}"
          [ngClass]="{ hidden: category.id !== currentCategoryViewId }"
          class="md:flex-col md:min-w-full space-y-2 font-medium flex flex-col list-none"
        >
          <li class="items-center" *ngFor="let view of category.views">
            <a
              [routerLink]="[view.path]"
              class="text-xs uppercase py-3 font-bold block"
              routerLinkActive
              #link="routerLinkActive"
              [ngClass]="
                link.isActive
                  ? 'text-emerald-500 hover:text-emerald-500'
                  : 'text-slate-100 hover:text-slate-500'
              "
              (click)="viewObj(view, category.id)"
            >
              <i
                class="{{ view.icon }} mr-2 text-sm"
                [ngClass]="link.isActive ? 'opacity-75' : 'text-slate-100'"
              >
              </i>
              {{ view.name }}
            </a>
          </li>
        </ul>
      </ng-container>
    </div>
  </div>
  <app-loader-list *ngIf="isLoading"></app-loader-list>
</aside>
