import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { DatatableComponent, ColumnMode } from "@swimlane/ngx-datatable";
import { ClientsService } from "app/apiServices/clients.service";
import { ChangeCFIDComponent } from "../change-cfid/change-cfid.component";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { CommisionsComponent } from "./commisions/commisions.component";
import { ShowComponent } from "./show/show.component";
import { Company } from "app/models/company";
import { NotificationsComponent } from "app/components/alerts/notifications/notifications.component";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-clients",
  templateUrl: "./clients.component.html",
  styleUrls: ["./clients.component.css"],
})
export class ClientsComponent implements OnInit {
  @ViewChild(DatatableComponent) table: DatatableComponent;
  ColumnMode = ColumnMode;
  data: any[] = [];
  tempo: any[] = [];
  temp: any[] = [];
  elements: any[] = [];
  showAlert = false;
  form: FormGroup;
  alertTitle: string;
  constructor(
    private apiService: ClientsService,
    private fb: FormBuilder,
    private route: Router,
    public dialog: MatDialog,
    private location: Location,
    private _snack: MatSnackBar
  ) {
    this.form = this.fb.group({
      rowsPerPage: [10],
    });
    this.temp = this.data;
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.apiService.readCompanies().subscribe((r) => {
      this.elements = r.data;
      this.elements.forEach((element) => {
        if (element.clients.length >= 1) {
          this.tempo.push(element);
        }
      });
      this.data = this.tempo;
      this.temp = this.data;
    });
  }

  updateFilter(event: Event) {
    const val: string = (event.target as HTMLInputElement).value.toLowerCase();
    const temp: any[] = this.temp.filter((d: any) => {
      return d.legal_name.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.data = temp;
    this.table.offset = 0;
  }

  changePageLimit(limit: any): void {
    this.form.controls.rowsPerPage.setValue(parseInt(limit, 10));
  }

  closeAlert(event: boolean) {
    this.showAlert = event;
  }

  onLimitChange(event: Event): void {
    const limit = (event.target as HTMLSelectElement).value;
    this.changePageLimit(limit);
    this.table.limit = this.form.controls.rowsPerPage.value;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor(
          (this.table.rowCount - 1) / this.table.limit
        );
      }
    });
  }

  openCFID(data: any) {
    const dialogRef = this.dialog.open(ChangeCFIDComponent, {
      width: "600px",
      data: {
        data,
      },
    });

    dialogRef.afterClosed().subscribe((r) => {
      this.getData();
    });
  }

  openCommisions(data: Company) {
    const clientId = data.clients.length > 0 ? data.clients[0].id : null;

    if (clientId) {
      this.dialog.open(CommisionsComponent, {
        width: "60%",
        data: {
          data,
          clientId,
        },
      });
    } else {
      this.openSnack("snack-error", "Error al obtener cliente asignado.");
    }
  }

  openData(data: any) {
    const dialogRef = this.dialog.open(ShowComponent, {
      width: "auto",
      data: {
        data,
      },
    });
  }

  return() {
    this.location.back();
    // this.route.navigate([
    //   '/admin/companies'
    // ])
  }

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
      verticalPosition: "top",
    });
  }
}
