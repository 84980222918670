import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ClientsService } from "app/apiServices/clients.service";
import { InventoryService } from "app/apiServices/inventory.service";
import { NotificationsComponent } from "app/components/alerts/notifications/notifications.component";
import { FormValidatorService } from "app/core/service/form-validator.service";
import { Address } from "app/models/address";

@Component({
  selector: "app-addresses-dialog",
  templateUrl: "./addresses-dialog.component.html",
  styleUrls: ["./addresses-dialog.component.css"],
})
export class AddressesDialogComponent {
  form: FormGroup;
  dataLocalities: any;
  item: Address = new Address({});

  // MAPS
  center: google.maps.LatLngLiteral = { lat: 19.42847, lng: -99.12766 };
  markerOptions: google.maps.MarkerOptions = { draggable: false };
  markers: google.maps.LatLngLiteral[] = [];

  validationMessages = {
    zip_code: [{ type: "required", message: "Campo Requerido" }],
    locality_id: [{ type: "required", message: "Campo Requerido" }],
  };

  constructor(
    private dialogRef: MatDialogRef<AddressesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public formService: FormValidatorService,
    private inventorySvc: InventoryService,
    private clientsSvc: ClientsService,
    public _snack: MatSnackBar
  ) {
    console.log(this.data);
    this.item = {
      ...this.item,
      company_id: this.data.company_id,
    };
    console.log(this.item);
    this.form = this.createForm();
  }

  ngOnInit(): void {}

  createForm(): FormGroup {
    return this.fb.group({
      id: [this.item.id, []],
      company_id: [this.item.company_id, []],
      locality_id: [this.item.locality_id, [Validators.required]],
      street: [this.item.street, [Validators.required]],
      ext_number: [this.item.ext_number, [Validators.required]],
      int_number: [this.item.int_number, []],
      zip_code: [this.item.zip_code, [Validators.required]],
      references: [this.item.references, []],
      lat: [this.item.lat, []],
      lng: [this.item.lng, []],
      state: [{ value: "", disabled: true }, []],
      municipality: [{ value: "", disabled: true }, []],
    });
  }

  getData(): void {
    this.inventorySvc.getLocalities(this.form.value).subscribe({
      next: (v) => {
        this.dataLocalities = v.data;

        this.setData();
      },
      error: (e) => {
        //console.log(e)
      },
    });
  }

  setData(): void {
    this.form.controls["state"].setValue(this.dataLocalities.state.name);
    this.form.controls["municipality"].setValue(
      this.dataLocalities.municipality.name
    );
  }

  sendData(): void {
    this.item = {
      ...this.form.value,
      line_2: "n/a"
    };

    this.clientsSvc.sendAddress(this.item).subscribe({
      next: (v) => {
        this.openSnack("snack-success", v.message);
        this.dialogRef.close({ status: 200 });
      },
      error: (e) => {
        this.openSnack("snack-error", e.error.message);
      },
    });
  }

  addMarker($event: google.maps.MapMouseEvent): void {
    if ($event.latLng != null) {
      if (this.markers.length >= 1) {
        this.markers = [];
      }
      this.markers.push($event.latLng.toJSON());
    }
  }

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
    });
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
