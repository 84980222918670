export class Measure {
  id?: number;
  name?: string;
  description?: string;
  size?: number;

  constructor(measure: Measure) {
    {
      this.id = measure.id || undefined;
      this.name = measure.name || '';
      this.description = measure.description || '';
      this.size = measure.size || 0;
    }
  }
}