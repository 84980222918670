import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InRoleViewsRes } from 'app/interfaces/roleviews.interface';
import { Category } from 'app/models/category';
import { Role } from 'app/models/role';
import { View } from 'app/models/view';
import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CatalogServicesService {
  public emitSubjet = new Subject<any>();
  dataEmitted = this.emitSubjet.asObservable();

  constructor(public http: HttpClient) { }

  /* Categories */
  createCategory(data: Category): Observable<any>{
    return this.http.post<any>(`${environment.url}admin/categories/create/`, data, {})
  }

  readCategories(): Observable<any> {
    return this.http.get<any>(`${environment.url}admin/categories/get/`)
  }

  updateCategory(data: Category): Observable<any>{
    return this.http.patch<any>(`${environment.url}admin/categories/update/${data.id}`, data, {})
  }

  deleteCategory(id: number): Observable<any>{
    return this.http.delete<any>(`${environment.url}admin/categories/delete/${id}`)
  }

  /* Views */
  createView(data: View): Observable<any>{
    return this.http.post<any>(`${environment.url}admin/views/create/`, data, {})
  }

  readViews(): Observable<any> {
    return this.http.get<any>(`${environment.url}admin/views/get/`)
  }

  updateView(data: View): Observable<any> {
    return this.http.patch<any>(`${environment.url}admin/views/update/${data.id}`, data, {})
  }

  deleteView(id: number): Observable<any> {
    return this.http.delete<any>(`${environment.url}admin/views/delete/${id}`)
  }

  /* Roles */
  createRole(data: Role): Observable<any> {
    return this.http.post<any>(`${environment.url}admin/roles/create/`, data, {})
  }

  readRoles(): Observable<any> {
    return this.http.get<any>(`${environment.url}admin/roles/get/`)
  }

  updateRole(data: Role): Observable<any> {
    return this.http.patch<any>(`${environment.url}admin/roles/update/${data.id}`, data, {})
  }

  deleteRole(id: number): Observable<any> {
    return this.http.delete<any>(`${environment.url}admin/roles/delete/${id}`)
  }

  /* Roles View */
  readRoleView(): Observable<any> {
    return this.http.get<any>(`${environment.url}admin/roles-views/get/`)
  }

  readRoleViews(): Observable<InRoleViewsRes> {
    return this.http.get<any>(`${environment.url}admin/roles-views/get/`)
  }

  updateRoleView(data: any): Observable<any> {
    return this.http.patch<any>(`${environment.url}admin/roles-views/update/${data.role}`, data, {})
  }
}
