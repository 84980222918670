import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { InServiceRes } from "app/interfaces/service.interface";
import { Page } from "app/models/page";
import { PagedData, CorporateEmployee } from "app/models/pagedData";
import { environment } from "environments/environment";
import { Observable, of } from "rxjs";
import { map, delay } from "rxjs/operators";

@Injectable({
    providedIn: "root",
  })
  export class LogService {

    constructor(public http: HttpClient) {
    }

    get(page: number, limit: number): Observable<any> {
      return this.http.post<any>(`${environment.url}inventory/logs`, {
        page,
        limit
      });
    }

  }