import { Company } from "./company";

export class Address {
  id?: number;
  active?: boolean;
  company_id?: number;
  locality_id?: number;
  street?: string;
  ext_number?: string;
  int_number?: string;
  zip_code?: string;
  line_2?: string;
  references?: string;
  lat?: string;
  lng?: string;
  locality?: Locality;
  company?: Company;

  constructor(address: Address) {
    this.id = address.id || undefined;
    this.active = address.active || true;
    this.company_id = address.company_id || undefined;
    this.locality_id = address.locality_id || undefined;
    this.street = address.street || "";
    this.ext_number = address.ext_number || "";
    this.int_number = address.int_number || "";
    this.zip_code = address.zip_code || "";
    this.line_2 = address.line_2 || "n/a";
    this.references = address.references || undefined;
    this.lat = address.lat || "0";
    this.lng = address.lng || "0";
    this.locality = address.locality || undefined;
    this.company = address.company || undefined;
  }
}

export class Locality {
  id?: number;
  name?: string;
  zone_type?: string;
  zip_code?: string;
  state?: State;
  municipality?: Municipality;

  constructor(locality: Locality) {
    this.id = locality.id || undefined;
    this.name = locality.name || "";
    this.zone_type = locality.zone_type || "";
    this.zip_code = locality.zip_code || "";
    this.state = locality.state || undefined;
    this.municipality = locality.municipality || undefined;
  }
}

export class State {
  id?: number;
  name?: string;
  code?: string;

  constructor(state: State) {
    this.id = state.id || undefined;
    this.name = state.name || "";
    this.code = state.code || "";
  }
}

export class Municipality {
  id?: number;
  name?: string;
  code?: string;

  constructor(municipality: Municipality) {
    this.id = municipality.id || undefined;
    this.name = municipality.name || "";
    this.code = municipality.code || "";
  }
}

export class Shipping {
  id?: number;
  address?: Address;
  shipping_id?: number;
  address_id?: number;
  code: string;
  unit: string;
  quantity: number;
  end_date: string;

  constructor(shipping: Shipping) {
    this.id = shipping.id || undefined;
    this.shipping_id = shipping.shipping_id || undefined;
    this.address_id = shipping.address_id || undefined;
    this.code = shipping.code || "";
    this.unit = shipping.unit || "";
    this.quantity = shipping.quantity || 0;
    this.end_date = shipping.end_date || "";
    this.address = shipping.address || undefined;
  }
}
