import { Component, ViewChild } from "@angular/core";
import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";
import { Role } from "app/models/role";
import { FormComponent } from "./dialogs/form/form.component";
import { MatDialog } from "@angular/material/dialog";
import { ModalConfirmationComponent } from "app/components/modals/modal-confirmation/modal-confirmation.component";
import { CatalogServicesService } from "app/apiServices/catalog-services.service";
import { FormGroup, FormBuilder } from "@angular/forms";

@Component({
  selector: "app-roles",
  templateUrl: "./roles.component.html",
  styleUrls: ["./roles.component.css"],
})
export class RolesComponent {
  rows: Role[] = [
    { id: 2, name: "Caseta Vigilancia", description: "" },
    { id: 3, name: "Vendedor Interno", description: "" },
    { id: 4, name: "Vendedor Externo", description: "" },
    { id: 5, name: "Coordinador de pedidos", description: "" },
    { id: 6, name: "Monitor de patio", description: "" },
    { id: 7, name: "Laboratorio", description: "" },
    { id: 8, name: "Operaciones planta", description: "" },
    { id: 9, name: "Inventarios", description: "" },
    { id: 10, name: "Facturación", description: "" },
    { id: 11, name: "Monitor de tránsito", description: "" },
    { id: 12, name: "Cartera de clientes", description: "" },
    { id: 13, name: "Compras", description: "" },
    { id: 14, name: "Dirección Comercial", description: "" },
    { id: 15, name: "Dirección Abastecimiento", description: "" },
    { id: 16, name: "Dirección Finanzas", description: "" },
    { id: 17, name: "Dirección General", description: "" },
    { id: 18, name: "Dirección Abastecimiento", description: "" },
  ];

  objects: Role[];

  temp: Role[] = [];
  showAlert = false;
  alertTitle: string;
  form: FormGroup;

  @ViewChild(DatatableComponent) table: DatatableComponent;
  columnMapValues = [
    ["id", "id"],
    ["nombre", "name"],
    ["descripciN", "description"],
    ["estatus", "active"],
  ] as const;
  columnMap: Map<string, string> = new Map(this.columnMapValues);
  loading: boolean = false;

  ColumnMode = ColumnMode;
  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    public apiServices: CatalogServicesService
  ) {
    this.form = this.fb.group({
      rowsPerPage: [10],
    });
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.loading = true;
    this.apiServices.readRoles().subscribe(
      (r) => {
        this.loading = false;
        this.objects = r.data;
        this.temp = this.objects;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  updateFilter(event: Event) {
    const val: string = (event.target as HTMLInputElement).value.toLowerCase();
    const temp: Role[] = this.temp.filter((d: Role) => {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.objects = temp;
    this.table.offset = 0;
  }

  openDialog(data: Role, action: string) {
    this.alertTitle =
      action == "store" ? "Rol agregado con éxito" : "Rol editado con éxito";

    const dialogRef = this.dialog.open(FormComponent, {
      width: "600px",
      data: {
        action,
        data,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getData();
      // if (result && result.method == "store") {
      //   if (result.status === 200) this.showAlert = true;
      //   result.data.id = this.rows.length + 2;
      //   this.rows = [...this.rows, result.data];
      // } else {
      //   if (result) {
      //     if (result.status === 200) this.showAlert = true;
      //     const index = this.rows.findIndex((d) => d.id === result.data.id);
      //     this.rows[index] = result.data;
      //     this.rows = [...this.rows];
      //   }
      // }
    });
  }

  openDialogDelete(roleId: number) {
    const dialogRef = this.dialog.open(ModalConfirmationComponent, {
      width: "600px",
      data: {
        title: "¿Estas seguro de desactivar este rol?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.apiServices.deleteRole(roleId).subscribe((r) => {
          this.getData();
        });
        // const index = this.rows.findIndex((d) => d.id === roleId);
        // this.rows.splice(index, 1);
        // this.rows = [...this.rows];
      }
    });
  }

  onLimitChange(event: Event): void {
    const limit = (event.target as HTMLSelectElement).value;
    this.changePageLimit(limit);
    this.table.limit = this.form.controls.rowsPerPage.value;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor(
          (this.table.rowCount - 1) / this.table.limit
        );
      }
    });
  }

  onSort(event: any): void {
    const sort = event.sorts[0];
    const column = this.columnMap.get(sort.prop);

    if (column) {
      this.objects.sort((a, b) => {
        if (typeof a[column] === "number") {
          if (sort.dir === "asc") return a[column] - b[column];
          else return b[column] - a[column];
        } else if (typeof a[column] === "string") {
          return (
            a[column].localeCompare(b[column]) * (sort.dir === "desc" ? -1 : 1)
          );
        }
      });

      this.objects = [...this.objects];
    }
  }

  changePageLimit(limit: any): void {
    this.form.controls.rowsPerPage.setValue(parseInt(limit, 10));
  }

  closeAlert(event: boolean) {
    this.showAlert = event;
  }

  delete(roleId: number) {
    this.openDialogDelete(roleId);
  }
}
