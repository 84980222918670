import { Component, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";
import { Route } from "app/models/route";
import { ModalConfirmationComponent } from "../modals/modal-confirmation/modal-confirmation.component";
import { FormComponent } from "./form/form.component";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ClientsService } from "app/apiServices/clients.service";

@Component({
  selector: "app-routes",
  templateUrl: "./routes.component.html",
  styleUrls: ["./routes.component.css"],
})
export class RoutesComponent {
  rows: Route[] = [];

  temp: Route[] = [];
  showAlert = false;
  alertTitle: string;
  form: FormGroup;

  @ViewChild(DatatableComponent) table: DatatableComponent;
  ColumnMode = ColumnMode;
  columnMapValues = [
    ["id", "id"],
    ["nombre", "name"],
    ["desde", "from_locality"],
    ["hasta", "to_locality"],
    ["costo", "standard_cost"],
    ["estatus", "active"],
  ] as const;
  columnMap: Map<string, string> = new Map(this.columnMapValues);
  loading: boolean = false;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private companiesSrv: ClientsService
  ) {
    this.form = this.fb.group({
      rowsPerPage: [10],
    });
    this.temp = this.rows;
  }
  ngOnInit() {
    this.getData();
  }

  updateFilter(event: Event) {
    const val: string = (event.target as HTMLInputElement).value.toLowerCase();
    const temp: Route[] = this.temp.filter((d: Route) => {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows = temp;
    this.table.offset = 0;
  }

  openDialog(data: Route, action: string) {
    this.alertTitle =
      action == "store" ? "Ruta agregada con éxito" : "Ruta editada con éxito";
    const dialogRef = this.dialog.open(FormComponent, {
      panelClass: ["dialog-responsive"],
      data: {
        action,
        data,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getData();
    });
  }

  openDialogDelete(data: Route) {
    const dialogRef = this.dialog.open(ModalConfirmationComponent, {
      panelClass: ["dialog-responsive"],
      data: {
        title: "¿Estas seguro de desactivar esta Ruta?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.companiesSrv.deleteRoutes(data).subscribe({
          next: (v) => {
            //console.log(v);
            this.getData();
          },
          error: (e) => {
            //console.log(e);
          },
        });
      }
    });
  }

  onLimitChange(event: Event): void {
    const limit = (event.target as HTMLSelectElement).value;
    this.changePageLimit(limit);
    this.table.limit = this.form.controls.tempPerPage.value;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor(
          (this.table.rowCount - 1) / this.table.limit
        );
      }
    });
  }

  onSort(event: any): void {
    const sort = event.sorts[0];
    const column = this.columnMap.get(sort.prop);

    if (column) {
      this.rows.sort((a, b) => {
        if (typeof a[column] === "number" || typeof a[column] === "boolean") {
          if (sort.dir === "asc") return a[column] - b[column];
          else return b[column] - a[column];
        } else if (typeof a[column] === "string") {
          return (
            a[column].localeCompare(b[column]) * (sort.dir === "desc" ? -1 : 1)
          );
        } else if (typeof a[column] === "object") {
          if (column === "from_locality" || column === "to_locality") {
            const localityA = `${a[column].name}, ${a[column].municipality.name}, ${a[column].state.name}`;
            const localityB = `${b[column].name}, ${b[column].municipality.name}, ${b[column].state.name}`;
            return (
              localityA.localeCompare(localityB) *
              (sort.dir === "desc" ? -1 : 1)
            );
          }
        }
      });

      this.rows = [...this.rows];
    }
  }

  changePageLimit(limit: any): void {
    this.form.controls.tempPerPage.setValue(parseInt(limit, 10));
  }

  closeAlert(event: boolean) {
    this.showAlert = event;
  }

  delete(data: Route) {
    this.openDialogDelete(data);
  }

  getData(): void {
    this.loading = true;
    this.companiesSrv.readRoutes().subscribe({
      next: (v) => {
        this.loading = false;
        this.rows = v.data;
        this.temp = this.rows;
      },
      error: (e) => {
        this.loading = false;
      },
    });
  }
}
