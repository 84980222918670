<div class="container bg-white mx-auto px-4 h-full py-4">
    <div class="justify-between rounded-t mb-0 px-4 py-3 border-0 pt-6 bg-white">
        <div class="text-center">
            <h3 class="font-semibold text-lg">
                Información de Usuario
            </h3>
        </div>
        <div class="flex justify-center items-center">
            <img src="../../../assets/img/user.png" alt="" class="h-40 rounded-full">
        </div>
    </div>
    <div class="relative w-full mb-3 flex">
        <div class="container px-2">
            <label
            class="block uppercase text-slate-600 text-xs font-bold mb-2"
            htmlFor="grid-password">
            Nombre
            </label>
            <input
            type="text" name="name" disabled
            class="border-0 px-3 py-3
                placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                value="{{user?.name}}" />
        </div>
        <div class="container px-2">
            <label
            class="block uppercase text-slate-600 text-xs font-bold mb-2"
            htmlFor="grid-password">
            Apellido
            </label>
            <input
            type="text" name="last_name" disabled
            class="border-0 px-3 py-3
                placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                value="{{user?.lastname}}" />
        </div>
    </div>
    <div class="relative w-full mb-3 flex">
        <div class="container px-2">
            <label
            class="block uppercase text-slate-600 text-xs font-bold mb-2"
            htmlFor="grid-password">
            Usuario
            </label>
            <input
            type="text" name="username" disabled
            class="border-0 px-3 py-3
                placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                value="{{user?.username}}" />
        </div>
        <div class="container px-2">
            <label
            class="block uppercase text-slate-600 text-xs font-bold mb-2"
            htmlFor="grid-password">
            Correo
            </label>
            <input
            type="text" name="email" disabled
            class="border-0 px-3 py-3
                placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                value="{{user?.email}}"/>
        </div>
    </div>
    <div class="relative w-full mb-3 flex pr-4">
        <div class="container">
            <label
            class="block uppercase text-slate-600 text-xs font-bold mb-2"
            htmlFor="grid-password">
            Rol Designado
            </label>
            <input
            type="text" name="role" disabled
            class="border-0 px-3 py-3
                placeholder-slate-300 text-slate-600
                bg-white rounded text-sm shadow focus:outline-none
                focus:ring w-full ease-linear transition-all duration-150"
                value="{{user?.roles[0].name}}"/>
        </div>
    </div>
    <div class="flex w-full justify-end">
        <button class="bg-gray-600 text-white
            active:bg-gray-400 font-bold uppercase 
            text-xs px-4 py-2 rounded shadow hover:shadow-md 
            outline-none focus:outline-none mr-1 ease-linear 
            transition-all duration-150" (click)="openChangePasswordModal(null, 'Self')">
            Cambiar contraseña
        </button>
    </div>
</div>
