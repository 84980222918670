import { Component, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Tabs } from "flowbite";
import type { TabsOptions, TabsInterface } from "flowbite";
import { FormValidatorService } from "app/core/service/form-validator.service";
import { Product, ProductMixes } from "app/models/product";
import { InventoryService } from "app/apiServices/inventory.service";
import { NotificationsComponent } from "app/components/alerts/notifications/notifications.component";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.css"],
})
export class FormComponent {
  form: FormGroup;
  dataSource: any;
  edit = false;
  dialogTitle: string;
  isFullMix: boolean = true;
  productMixes: ProductMixes[] = [];
  emptyPercentages: boolean = false;
  validationMessages = {
    name: [{ type: "required", message: "Ingrese un nombre" }],
    code: [{ type: "required", message: "Ingrese un código" }],
    full_mix: [
      { type: "required", message: "Indica si el producto es completo" },
    ],
    on_hand: [
      { type: "required", message: "Ingresa el inventario disponible" },
    ],
    on_order: [
      { type: "required", message: "Ingresa el inventario de pedidos" },
    ],
    list_price: [{ type: "required", message: "Ingresa el precio de lista" }],
    authorized_price: [
      { type: "required", message: "Ingresa el precio del dia" },
    ],
    profit_margin: [
      { type: "required", message: "Ingresa el margen de ganancia" },
    ],
    supply_price: [
      { type: "required", message: "Ingresa el precio de suministro" },
    ],
    description: [{ type: "required", message: "Ingrese una descripción" }],
    sellable: [{ type: "required", message: "Ingresa si se puede vender" }],
    operative_cost: [
      { type: "required", message: "Ingresa el costo operativo" },
    ],
  };
  options: TabsOptions = {
    defaultTabId: "general-data",
    activeClasses:
      "text-blue-600 hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-400 border-blue-600 dark:border-blue-500",
    inactiveClasses:
      "text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300",
    onShow: () => {},
  };
  tabs: TabsInterface;

  constructor(
    private dialogRef: MatDialogRef<FormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public formService: FormValidatorService,
    private inventorySvc: InventoryService,
    private _snack: MatSnackBar
  ) {
    this.data = data;

    if (data.action === "edit") {
      this.dialogTitle = "Editar producto";
      this.dataSource = data.data;
      this.edit = true;
      this.form = this.createForm();
      this.toggleFullMix(undefined, true, this.dataSource.full_mix);
    } else {
      this.dialogTitle = "Agregar producto";
      this.dataSource = new Product({});
      this.form = this.createForm();
    }
  }

  createForm(): FormGroup {
    if (this.data.action === "edit") {
      return this.fb.group({
        id: [this.dataSource.id],
        name: [this.dataSource.name, [Validators.required]],
        description: [this.dataSource.description, [Validators.required]],
        code: [this.dataSource.code, [Validators.required]],
        full_mix: [this.dataSource.full_mix, [Validators.required]],
        on_hand: [this.dataSource.on_hand, [Validators.required]],
        on_order: [this.dataSource.on_order, [Validators.required]],
        ieps: [this.dataSource.ieps, []],
        ieps_cost: [this.leadingZero(this.dataSource.ieps_cost.toString()), []],
        profit_margin: [this.dataSource.profit_margin, [Validators.required]],
        products: [
          this.productMixes,
          this.isFullMix ? [] : [Validators.required],
        ],
        sellable: [this.dataSource.sellable, [Validators.required]],
        clave_sat: [this.dataSource.clave_sat, []],
        unit_key: [this.dataSource.unit_key, []],
      });
    } else {
      return this.fb.group({
        id: [this.dataSource.id],
        name: [this.dataSource.name, [Validators.required]],
        description: [this.dataSource.description, [Validators.required]],
        code: [this.dataSource.code, [Validators.required]],
        full_mix: [this.dataSource.full_mix, [Validators.required]],
        on_hand: [this.dataSource.on_hand, [Validators.required]],
        on_order: [this.dataSource.on_order, [Validators.required]],
        list_price: [this.dataSource.list_price, [Validators.required]],
        authorized_price: [
          this.dataSource.authorized_price,
          [Validators.required],
        ],
        supply_price: [this.dataSource.supply_price, [Validators.required]],
        ieps: [this.dataSource.ieps, []],
        ieps_cost: [null, []],
        profit_margin: [this.dataSource.profit_margin, [Validators.required]],
        products: [
          this.productMixes,
          this.isFullMix ? [] : [Validators.required],
        ],
        sellable: [this.dataSource.sellable, [Validators.required]],
        clave_sat: [this.dataSource.clave_sat, []],
        unit_key: [this.dataSource.unit_key, []],
        operative_cost: [
          this.leadingZero(this.dataSource?.operative_cost?.toString()),
          [Validators.required],
        ],
      });
    }
  }

  onLeadingZero(event: Event): void {
    const num = (event.target as HTMLInputElement).value
      .replace("$", "")
      .replace(",", "")
      .replace(" ", "");
    const dec = num.split(".")[1];
    const len = dec && dec.length > 2 ? dec.length : 2;
    (event.target as HTMLInputElement).value = `$ ${Number(num).toFixed(len)}`;
  }

  leadingZero(num: string): string {
    const dec = num?.split(".")[1];

    if (!dec && num == "0") return "0.00";
    else if (!dec && num != "0") return `${num}.00`;

    const len = dec && dec.length > 2 ? dec.length : 2;
    const fixed = Number(num).toFixed(len);
    return fixed;
  }

  save(): void {
    if (this.form.valid) {
      this.dataSource = this.form.value;
      this.isFullMix ? (this.productMixes = []) : this.productMixes;
      this.storeUpdate(this.edit ? "update" : "store");
    } else {
      this.formService.allFields(this.form);
    }
  }

  storeUpdate(method: string): void {
    const data = {
      method,
      data: {
        ...this.dataSource,
        products: this.productMixes,
      },
      status: 200,
    };

    this.dataSource = {
      ...this.dataSource,
      products: this.productMixes,
      ieps_cost: this.dataSource.ieps_cost
        ? Number(parseFloat(this.dataSource.ieps_cost))
        : null,
    };

    if (method === "store") {
      this.inventorySvc.sendProducts(this.dataSource).subscribe({
        next: (v) => {
          this.openSnack("snack-success", v.message);
          this.dialogRef.close(data);
        },
        error: (e) => {
          // console.log(e)
          this.openSnack("snack-error", e.error.message);
        },
      });
    } else {
      this.inventorySvc.updateProducts(this.dataSource).subscribe({
        next: (v) => {
          this.openSnack("snack-success", v.message);
          this.dialogRef.close(data);
        },
        error: (e) => {
          this.openSnack("snack-error", e.error.message);
        },
      });
    }
  }

  toggleFullMix(
    event: Event,
    manual: boolean = false,
    value: boolean = false
  ): void {
    if (manual) {
      this.isFullMix = value;
    } else {
      const value = (event.target as HTMLInputElement).checked;
      this.isFullMix = value;
    }

    if (!this.isFullMix) {
      setTimeout(() => {
        this.tabs = new Tabs(
          [
            {
              id: "general-data",
              triggerEl: document.querySelector("#general-data-tab"),
              targetEl: document.querySelector("#general-data"),
            },
            {
              id: "composition",
              triggerEl: document.querySelector("#composition-tab"),
              targetEl: document.querySelector("#composition"),
            },
          ],
          this.options
        );
      }, 500);

      this.form.controls.full_mix.clearValidators();
      this.form.controls.full_mix.setValidators([Validators.required]);
      this.form.controls.full_mix.updateValueAndValidity();
    } else {
      this.form.controls.full_mix.clearValidators();
      this.form.controls.full_mix.setValidators([Validators.nullValidator]);
      this.form.controls.full_mix.updateValueAndValidity();
    }
  }

  toggleIeps(event: Event): void {}

  next(): void {
    this.tabs.show("composition");
  }

  back(): void {
    this.tabs.show("general-data");
  }

  drop(composeProducts: ProductMixes[]): void {
    this.productMixes = composeProducts;

    const zeroPercentages = this.productMixes.find((p) => p.percentage === 0);
    this.emptyPercentages = zeroPercentages ? true : false;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
    });
  }
}
