import { Component, OnInit, ViewChild } from "@angular/core";
import { ModalConfirmationComponent } from "../modals/modal-confirmation/modal-confirmation.component";
import { FormComponent } from "./form/form.component";
import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";
import { MatDialog } from "@angular/material/dialog";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ClientsService } from "app/apiServices/clients.service";
import { User } from "app/models/user";
import { UserServicesService } from "app/apiServices/user-services.service";

@Component({
  selector: "app-commision-product",
  templateUrl: "./commision-product.component.html",
  styleUrls: ["./commision-product.component.css"],
})
export class CommisionProductComponent implements OnInit {
  rows: any[] = [];
  obj: any[] = [];
  myself: User;

  temp: any[] = [];
  showAlert = false;
  form: FormGroup;

  @ViewChild(DatatableComponent) table: DatatableComponent;
  ColumnMode = ColumnMode;
  columnMapValues = [
    ["id", "id"],
    ["producto", "product"],
    ["vendedor", "user"],
    ["cliente", "data"],
    ["comisiNBase", "cost_per_liter"],
  ] as const;
  columnMap: Map<string, string> = new Map(this.columnMapValues);
  loading: boolean = false;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    public clientService: ClientsService,
    public userService: UserServicesService
  ) {
    this.form = this.fb.group({
      rowsPerPage: [10],
    });
    this.temp = this.rows;
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.loading = true;
    this.clientService.readCommisions().subscribe((r) => {
      let companies: any[] = [];
      let discounts: any[] = [];

      let valueComplete: any[] = [];

      r.data.forEach((discount) => {
        if (discount.active) discounts.push(discount);
      });

      this.clientService.readCompanies().subscribe(
        (r) => {
          this.loading = false;
          r.data.forEach((company) => {
            if (company.active && company.clients.length >= 1)
              companies.push(company);
          });

          discounts.forEach((discount) => {
            companies.forEach((company) => {
              if (discount.client.id === company.clients[0].id) {
                discount["data"] = company;
                valueComplete.push(discount);
              }
            });
          });

          this.rows = valueComplete;
          this.temp = this.rows;
        },
        (error) => {
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
    });
    this.userService.getMyself().subscribe((r) => {
      this.myself = r.data;
    });
  }

  updateFilter(event: Event) {
    const val: string = (event.target as HTMLInputElement).value.toLowerCase();
    const temp: any[] = this.temp.filter((d: any) => {
      return d.data.legal_name.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows = temp;
    this.table.offset = 0;
  }

  openDialog(data: any, action: string) {
    const dialogRef = this.dialog.open(FormComponent, {
      width: "600px",
      data: {
        action,
        data,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getData();
    });
  }

  openDialogDelete(id: number) {
    const dialogRef = this.dialog.open(ModalConfirmationComponent, {
      width: "600px",
      data: {
        title: "¿Estas seguro de desactivar esta comision?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.clientService.deleteCommision(id).subscribe((r) => {
          this.getData();
        });
      }
    });
  }

  onLimitChange(event: Event): void {
    const limit = (event.target as HTMLSelectElement).value;
    this.changePageLimit(limit);
    this.table.limit = this.form.controls.rowsPerPage.value;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor(
          (this.table.rowCount - 1) / this.table.limit
        );
      }
    });
  }

  changePageLimit(limit: any): void {
    this.form.controls.rowsPerPage.setValue(parseInt(limit, 10));
  }

  onSort(event: any): void {
    const sort = event.sorts[0];
    const column = this.columnMap.get(sort.prop);

    if (column) {
      this.rows.sort((a, b) => {
        if (typeof a[column] === "number" || typeof a[column] === "boolean") {
          if (sort.dir === "asc") return a[column] - b[column];
          else return b[column] - a[column];
        } else if (typeof a[column] === "string") {
          return (
            a[column].localeCompare(b[column]) * (sort.dir === "desc" ? -1 : 1)
          );
        } else if (typeof a[column] === "object") {
          if (column == "user" || "product") {
            return (
              a[column].name.localeCompare(b[column].name) *
              (sort.dir === "desc" ? -1 : 1)
            );
          } else {
            return (
              a[column].legal_name.localeCompare(b[column].legal_name) *
              (sort.dir === "desc" ? -1 : 1)
            );
          }
        }
      });

      this.rows = [...this.rows];
    }
  }

  closeAlert(event: boolean) {
    this.showAlert = event;
  }

  delete(roleId: number) {
    this.openDialogDelete(roleId);
  }
}
