export class Existence {
  id?: number;
  existence_change_type_id?: number;
  product_id?: number;
  // user_id?: number;
  quantity?: number;
  description?: string;
  tank_id?: any;

  constructor(existence: Existence) {
    {
      this.id = existence.id || undefined;
      this.existence_change_type_id || 0;
      this.product_id || 0;
      // this.user_id || 0;
      this.quantity || 0;
      this.description = existence.description || '';
      this.tank_id = existence.tank_id || undefined
    }
  }
}