import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { ClientsService } from "app/apiServices/clients.service";
import { SalesService } from "app/apiServices/sales.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  ColumnMode,
  DatatableComponent,
  SelectionType,
} from "@swimlane/ngx-datatable";
import { Company } from "app/models/company";
import { FormValidatorService } from "app/core/service/form-validator.service";
import { Select, Collapse, Dropdown, initTE } from "tw-elements";
import { MatDialog } from "@angular/material/dialog";
import { AssingSupplierInvoiceComponent } from "../assing-supplier-invoice/assing-supplier-invoice.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NotificationsComponent } from "app/components/alerts/notifications/notifications.component";
import { DateTime } from "luxon";
import { InCompaniesRes } from "app/interfaces/company.interface";
import { DataService } from "app/services/data.service";
import { Purchase } from "app/models/purchase";
import { OrderCost } from "app/models/order-cost";
import { InvoiceService } from "app/apiServices/invoice.service";
import { CostInvoices } from "app/models/cost_invoices";
declare let DateRangePicker: any;

@Component({
  selector: "app-invoice-suppliers",
  templateUrl: "./invoice-suppliers.component.html",
  styleUrls: ["./invoice-suppliers.component.css"],
})
export class InvoiceSuppliersComponent implements OnInit, OnDestroy {
  rows = [];
  selected: OrderCost[] = [];
  orderCosts: OrderCost[] = [];
  costInvoices: CostInvoices[] = [];
  objects: Company[];
  allCompanies: Company[];
  suppliers: any[] = [];
  loading: boolean = false;
  objectsSuppliers: Company[];
  selectedCompany: Company;
  selectedSupplier: Company;
  currentCostOrders: OrderCost[] = [];
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  columnMapValues = [
    ["proveedor", "company_supplier"],
    ["folio", "order"],
    ["servicio", "order_cost_type"],
    ["costo", "cost"],
    ["verFactura", "invoice"],
    ["eliminar", "delete"],
  ] as const;
  columnMap: Map<string, string> = new Map(this.columnMapValues);
  form: FormGroup;

  company_id = new FormControl("", [Validators.required]);
  supplier_id = new FormControl("", [Validators.required]);

  @ViewChild("company_id", { static: true })
  companySelect: ElementRef;
  @ViewChild("supplier_id", { static: true })
  supplierSelect: ElementRef;
  @ViewChild("container", { static: true })
  container: ElementRef;
  @ViewChild(DatatableComponent) table: DatatableComponent;
  dateRangePicker: any;
  selectOptions = {
    selectFilter: true,
    selectNoResultText: "No se encontraron resultados",
    selectSearchPlaceholder: "Buscar...",
  };

  selectedPurchases = [];

  constructor(
    public apiService: ClientsService,
    public formService: FormValidatorService,
    public dialog: MatDialog,
    public snack: MatSnackBar,
    private dataService: DataService,
    public salesService: SalesService,
    private invoiceService: InvoiceService
  ) { }
  ngOnInit(): void {
    this.getData();

    this.getCostInvoices();
  }
  sender() {
    this.loading = true;
    this.dataService.setLoadingValue(this.loading);

    if (!this.selectedCompany) {
      this.openSnack("snack-error", "Selecciona una compañia");
      this.loading = false;
      this.dataService.setLoadingValue(this.loading);
      return;
    }

    if (!this.selectedSupplier) {
      this.openSnack("snack-error", "Selecciona un proveedor");
      this.loading = false;
      this.dataService.setLoadingValue(this.loading);
      return;
    }

    /*const [startDate, endDate] = this.dateRangePicker.getDates();
    const startDateMs = DateTime.fromJSDate(startDate).toMillis();
    const endDateMs = DateTime.fromJSDate(endDate).toMillis();*/
    this.getFilteredOrdersCost(this.selectedCompany.id, this.selectedSupplier.id);
    this.loading = false;
    setTimeout(() => {
      this.dataService.setLoadingValue(this.loading);
    }, 400);
  }

  getFilteredOrdersCost(company_id: number, supplier_id: number) {
    this.salesService.getOrderCosts(supplier_id, company_id).subscribe(
      (r) => {
        this.loading = false;
        if (r.status === "Éxito") {
          this.selected = [];
          this.orderCosts = r.data.filter((costOrder) => {
            return costOrder.total > 0;
          });
          this.filterOrderCostInvoices();
        }
      },
      (e) => {
        this.loading = false;
      }
    );
  }
  getCostInvoices() {
    this.invoiceService.getCostInvoices().subscribe(
      {
        next: (res) => {
          this.loading = false;
          if (res.status === "Éxito") {
            this.costInvoices = res.data;
          }
        },
        error: (e) => {
          this.loading = false;
        },
      }
    );
  }

  getRowClass = (row) => {
    return {
      'facturada': row.facturada
    };
  }

  

  getService(purchase: Purchase): string {
    const service =
      purchase.purchase_order_details.length > 0
        ? purchase.purchase_order_details[0]
        : null;
    const currentService = service.product ? service.product : service.service;
    return currentService.name;
  }

  changeDate(event: any) {
    const [startDate, endDate] = this.dateRangePicker.getDates();

    if (startDate && endDate) {
      this.dateRangePicker.datepickers[0].element?.click();
      this.container?.nativeElement?.click();
      this.dateRangePicker.datepickers[1].element?.click();
      this.container?.nativeElement?.click();
      const formattedStartDate =
        DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd");
      const formattedEndDate =
        DateTime.fromJSDate(endDate).toFormat("yyyy-MM-dd");
    }
  }

  setInitialDate() {
    this.dateRangePicker.setDates(
      DateTime.local().minus({ week: 1 }).toISODate(),
      DateTime.local().toISODate()
    );
  }

  ngAfterViewInit() {
    new Select(this.companySelect.nativeElement, this.selectOptions);
    new Select(this.supplierSelect.nativeElement, this.selectOptions);
  }
  updateFilter(event: Event) {
    const val: string = (event.target as HTMLInputElement).value.toLowerCase();
    const temp: OrderCost[] = this.currentCostOrders.filter((d: OrderCost) => {
      return d.order.folio.toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.rows = temp;
    this.table.offset = 0;
  }

  onActivate(event) { }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected.filter(row => !row.facturada));
  }

  onLimitChange(event: Event): void {
    const limit = (event.target as HTMLSelectElement).value;
    this.changePageLimit(limit);
    this.table.limit = this.form.controls.rowsPerPage.value;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor(
          (this.table.rowCount - 1) / this.table.limit
        );
      }
    });
  }

  changePageLimit(limit: any): void {
    this.form.controls.rowsPerPage.setValue(parseInt(limit, 10));
  }

  getData(): void {
    this.apiService.readCompanies().subscribe(
      (r: InCompaniesRes) => {
        this.objects = r.data.filter((company) => {
          return company.active && company.main;
        });
        this.allCompanies = r.data.filter((company) => {
          return company.active;
        });
      },
      (error) => {
        throw error;
      }
    );
  }

  OnChangeCompany(id: string): void {
    const idNumber = Number(id);
    const company = this.objects.find((company) => company.id === idNumber);
    this.selectedCompany = company;

    if (company && company.main) this.getProvidersbyCompany(company);
  }

  onSort(event: any): void {
    const sort = event.sorts[0];
    const column = this.columnMap.get(sort.prop);

    if (column) {
      this.rows.sort((a, b) => {
        if (typeof a[column] === "number") {
          if (sort.dir === "asc") return a[column] - b[column];
          else return b[column] - a[column];
        } else if (typeof a[column] === "string") {
          return (
            a[column].localeCompare(b[column]) * (sort.dir === "desc" ? -1 : 1)
          );
        } else if (typeof a[column] === "object") {
          if (column == "seller") {
            return (
              a[column]?.legal_name.localeCompare(b[column]?.legal_name) *
              (sort.dir === "desc" ? -1 : 1)
            );
          }

          if (column == "client" || column == "transportist") {
            return (
              a[column]?.company?.legal_name.localeCompare(
                b[column]?.company?.legal_name
              ) * (sort.dir === "desc" ? -1 : 1)
            );
          }

          if (column == "vendor") {
            const nameA = a[column]?.name;
            const nameB = b[column]?.name;
            const lastNameA = a[column]?.lastname;
            const lastNameB = b[column]?.lastname;
            const fullNameA = `${nameA} ${lastNameA}`;
            const fullNameB = `${nameB} ${lastNameB}`;

            return (
              fullNameA.localeCompare(fullNameB) *
              (sort.dir === "desc" ? -1 : 1)
            );
          }

          if (column == "product" || column == "order_status") {
            return (
              a[column]?.name.localeCompare(b[column]?.name) *
              (sort.dir === "desc" ? -1 : 1)
            );
          }

          if (column == "created_at") {
            if (sort.dir === "asc") {
              return (
                new Date(a[column]).getTime() - new Date(b[column]).getTime()
              );
            } else {
              return (
                new Date(b[column]).getTime() - new Date(a[column]).getTime()
              );
            }
          }
        }
      });

      this.rows = [...this.rows];
    }
  }

  getProvidersbyCompany(companyMN: any) {
    const data = this.allCompanies.filter((company) => {
      return (
        (company.active &&
          company.main !== true &&
          company.suppliers.length !== 0 &&
          companyMN.company_suppliers.some((element) => {
            return element.id === company.suppliers[0]?.id;
          })) ||
        company.transportists.length !== 0
      );
    });

    this.suppliers = data;
  }

  getSelectedSupplier(supplierId: number) {
    this.selectedSupplier = this.allCompanies.find(
      (company) => company.id == supplierId
    );
  }
  
  filterOrderCostInvoices(){
    const invoices = this.costInvoices.filter(invoice => {
      return invoice.company_supplier?.id == this.selectedSupplier?.id;
    });
    const FacturadasIDs = invoices.map(invoice => (invoice.cost_invoice_details as any[]).map(detail => detail.order_cost_id)).reduce((acc, val) => acc.concat(val), []);
    this.currentCostOrders = this.orderCosts.map(sale => ({
      ...sale,
      facturada: FacturadasIDs.includes(sale.id)
    }));
    this.rows = this.currentCostOrders;
    this.loading = false;
    setTimeout(() => {
      this.dataService.setLoadingValue(this.loading);
    }, 400);
  }

  openAssignInvoiceDialog() {
    const dialogRef = this.dialog.open(AssingSupplierInvoiceComponent, {
      width: "84vw",
      maxWidth: "84vw",
      height: "80vh",
      data: {
        purchases: this.selected,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.invoiceService.getCostInvoices().subscribe(
          {
            next: (res) => {
              this.loading = false;
              if (res.status === "Éxito") {
                this.selected = [];
                this.costInvoices = res.data;
                this.filterOrderCostInvoices();
                
              }
            },
            error: (e) => {
              this.loading = false;
            },
          }
        );
        
      }
    });
  }
  openSnack(color: string, message: string): void {
    this.snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
      verticalPosition: "top",
    });
  }
  filterOrders(company_id: string, supplier_id: string): void { }

  ngOnDestroy(): void { }
}
