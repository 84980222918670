import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DateBody } from "app/interfaces/date.interface";
import { environment } from "environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ReportService {
  constructor(public http: HttpClient) {}

  getSalesReport(date: DateBody): Observable<Blob> {
    return this.http.post<Blob>(
      `${environment.url}reports/sales/create`,
      date,
      { responseType: "blob" as "json" }
    );
  }

  getPurchasesReport(date: DateBody): Observable<Blob> {
    return this.http.post<Blob>(
      `${environment.url}reports/purchases/create`,
      date,
      { responseType: "blob" as "json" }
    );
  }

  /* Puchase Order reports */
  sendFiles(folder, files: FormData): Observable<Response> {
    return this.http.post<Response>(`${environment.url}s3-files/${folder}`, files, {});
  }

  replaceFile(folder, file: FormData, filename: string): Observable<Response> {
    return this.http.put<Response>(`${environment.url}s3-files/${folder}/${filename}`, file, {});
  }

  deleteFile(folder, file: FormData, name: string): Observable<Response> {
    return this.http.delete<Response>(`${environment.url}s3-files/${folder}/${name}`, {});
  }

  /* Reports Dashboard */
  getUnitsInTransit(): Observable<any>{
      return this.http.get<any>(`${environment.url}reports/transit-units`);
  }

  getUnitsInPlant(): Observable<any>{
    return this.http.get<any>(`${environment.url}reports/plant-units`);
  }
}
