<div class="">
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-200 border-0">
        <div class="relative flex flex-col min-w-0 break-words w-full rounded">
            
            <!-- First row -->
            <div class="grid grid-cols-2 gap-2 rounded-t mb-0 px-4 py-3 border-0 pt-4">
                <div class="flex">
                    <img src="../../../../../assets/img/truck.png" alt="" class="mirrowImg smallerImg">
                    <b class="text-red-600 text-2xl pt-6 pl-2">Unidades en Tránsito</b>
                </div>
                <div class="flex">
                    <b class="text-red-600 text-6xl pt-2 pl-2">{{this.totalNumber}}</b>
                    <b class="pt-6 pl-2 text-lg">Número de unidades en este momento</b>
                </div>
            </div>
            
            <!-- Second Row Table -->
            <div class="rounded-t mb-0 px-4 py-3 border-0 pt-4">
                <div class="">
                    <div class="block w-full overflow-x-auto rounded-lg">
                      <ngx-datatable
                        #table
                        class="material"
                        [columnMode]="columnMode.force"
                        [headerHeight]="50"
                        [footerHeight]="50"
                        rowHeight="auto"
                        [limit]="10"
                        [rows]="dataSource"
                        [loadingIndicator]="loading"
                        [scrollbarH]="true"
                        (sort)="onSort($event)">
                        <ngx-datatable-column name="Pedido">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row.folio }}
                          </ng-template>
                        </ngx-datatable-column>
            
                        <ngx-datatable-column name="Cliente">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row.client }}
                          </ng-template>
                        </ngx-datatable-column>
            
                        <ngx-datatable-column name="Transportista">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row.transportist }}
                          </ng-template>
                        </ngx-datatable-column>
            
                        <ngx-datatable-column name="Unidad">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row.unit }}
                          </ng-template>
                        </ngx-datatable-column>
            
                        <ngx-datatable-column name="Destino">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row.to_locality }}
                          </ng-template>
                        </ngx-datatable-column>
            
                        <ngx-datatable-column name="ETA">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                              {{ row.eta }}
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="Ultimo Comentario">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                              {{ row.last_comment_date }}
                          </ng-template>
                          </ngx-datatable-column>

                        <ngx-datatable-column name="">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row.last_comment }}
                        </ng-template>
                        </ngx-datatable-column>
            
                        <ngx-datatable-footer>
                          <ng-template
                            ngx-datatable-footer-template
                            let-rowCount="rowCount"
                            let-pageSize="pageSize"
                            let-selectedCount="selectedCount"
                            let-curPage="curPage"
                            let-offset="offset"
                            let-isVisible="isVisible">
                            <div class="page-count hide">{{ rowCount }} total</div>
            
                            <div>
                              <form [formGroup]="form">
                                <div>
                                  <div
                                    class="flex justify-content-between align-items-center">
                                    <label
                                      class="flex align-items-center mx-4 my-2"
                                      for="rowsPerPage">
                                      Mostrar por página:
                                    </label>
            
                                    <select
                                      class="rounded form-control mx"
                                      formControlName="rowsPerPage"
                                      (change)="onLimitChange($event)"
                                      id="rowsPerPage"
                                      name="rowsPerPage">
                                      <option [value]="5">5</option>
                                      <option [value]="10">10</option>
                                      <option [value]="25">25</option>
                                      <option [value]="50">50</option>
                                    </select>
                                  </div>
                                </div>
                              </form>
                            </div>
            
                            <datatable-pager
                              [pagerLeftArrowIcon]="'datatable-icon-left'"
                              [pagerRightArrowIcon]="'datatable-icon-right'"
                              [pagerPreviousIcon]="'datatable-icon-prev'"
                              [pagerNextIcon]="'datatable-icon-skip'"
                              [page]="curPage"
                              [size]="pageSize"
                              [count]="rowCount"
                              (change)="table.onFooterPage($event)">
                            </datatable-pager>
                          </ng-template>
                        </ngx-datatable-footer>

                      </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>

    </div>
  </div>