import { Component, OnInit } from "@angular/core";
import { initFlowbite } from "flowbite";
import { DataService } from "./services/data.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  title = "Panel Energroup";
  loading = false;

  constructor(private dataService: DataService) {
    this.dataService.loading.subscribe((value) => {
      this.loading = value;
    });
  }

  ngOnInit(): void {
    initFlowbite();
  }
}
