import { View } from "app/interfaces/view.interface";
import { Role } from "./role";

export class RoleView {
  id?: number;
  role_id?: number;
  role?: Role;
  view_id?: number;
  view?: View;
  active?: boolean;
  created_at?: string;

  constructor(roleview: RoleView) {
    {
      this.id = roleview.id || undefined;
      this.role_id = roleview.role_id || undefined;
      this.role = roleview.role || undefined;
      this.view_id = roleview.view_id || undefined;
      this.view = roleview.view || undefined;
      this.active = roleview.active || false;
      this.created_at = roleview.created_at || undefined;
    }
  }
}

export class RoleViews {
  id?: number;
  name?: string;
  description?: string;
  active?: boolean;
  roles_views?: RoleView[];
  views?: View[];

  constructor(roleviews: RoleViews) {
    {
      this.id = roleviews.id || undefined;
      this.name = roleviews.name || "";
      this.description = roleviews.description || "";
      this.active = roleviews.active || undefined;
      this.roles_views = roleviews.roles_views || [];
      this.views = roleviews.views || [];
    }
  }
}
