import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  status: boolean = false;
  constructor() {}

  getAuthToken(): Observable<boolean> {
    this.status = localStorage.getItem("token") !== null;
    if (localStorage.getItem("token") !== null) return of(true);
    return of(false);
  }
}
