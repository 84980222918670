<div class="container bg-white mx-auto px-4 w-full h-full py-4 overflow-hidden">
    <div class="flex">
      <div class="w-11/12 justify-between rounded-t mb-0 pb-8 border-0 bg-white">
        <div class="text-start">
          <h3 class="font-semibold text-lg">
            {{ dialogTitle }}
          </h3>
        </div>
      </div>
  
      <div class="w-1/12 justify-end mb-0 pb-8">
        <button
          class="text-sky-600 cursor-pointer hover:text-sky-700 text-2xl pl-12 ease-linear transition-all duration-150"
          (click)="closeModal()"
        >
          <i class="fas fa-times-circle"></i>
        </button>
      </div>
    </div>
  
    <div class="mb-4">
      <div class="p-6">
        <form class="bg-white" [formGroup]="form">
          <div class="flex flex-row">
            <div class="w-1/2" *ngIf="dataSource.load_date != null">
              <div class="px-2">
                <label
                  class="block uppercase text-slate-600 text-xs font-bold mb-2"
                  htmlFor="load_date">
                  Fecha carga
                </label>
                <input
                  title="Fecha carga"
                  type="text"
                  formControlName="load_date"
                  class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                />
              </div>
            </div>
  
            <div class="w-1/2" *ngIf="dataSource.end_date != null">
              <div class="px-2">
                <div class="px-2">
                  <label
                    class="block uppercase text-slate-600 text-xs font-bold mb-2"
                    htmlFor="end_date">
                    Fecha ETA
                  </label>
                  <input
                    title="Fecha ETA"
                    type="text"
                    formControlName="end_date"
                    class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
            </div>
          </div>
  
          <div class="flex flex-row w-full mt-6">
            <div class="w-2/6">
              <div class="container px-2">
                <label
                  class="block uppercase text-slate-600 text-xs font-bold mb-2"
                  htmlFor="agreed_price">
                  Precio acordado
                </label>
                <input
                  formControlName="agreed_price"
                  type="text"
                  prefix="$ "
                  mask="separator"
                  thousandSeparator=","
                  class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                />
              </div>
            </div>

  
            <div class="container px-2">
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="route_price">
                Precio ruta
              </label>
              <input
                type="text"
                prefix="$ "
                mask="separator"
                thousandSeparator=","
                formControlName="route_price"
                class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
  
            
        </div>
        
        <div class="flex flex-row mt-6">
              
            <div class="container px-2">
                <label
                  class="block uppercase text-slate-600 text-xs font-bold mb-2"
                  htmlFor="shipping_price">
                  Precio transporte
                </label>
                <input
                  type="text"
                  prefix="$ "
                  mask="separator"
                  thousandSeparator=","
                  formControlName="shipping_price"
                  class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                />
              </div>
            <div class="container px-2">
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="total_price_liter">
                Precio final
              </label>
              <input
                type="text"
                prefix="$ "
                mask="separator"
                thousandSeparator=","
                formControlName="total_price_liter"
                class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
  
            <div class="container px-2">
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="sold_liters">
                Cantidad vendida
              </label>
              <input
                type="text"
                mask="separator"
                thousandSeparator=","
                formControlName="sold_liters"
                class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  