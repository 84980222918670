import { Product } from "./product";

export class Tank {
  id?: number;
  tank?: string;
  active?: boolean;
  capacity?: number;
  quantity?: number;
  products?: number;
  observations?: string;
  measured?: number;
  reasons?: string;

  constructor(tank: Tank) {
    this.id = tank.id || undefined;
    this.tank = tank.tank || "";
    this.active = tank.active || false;
    this.capacity = tank.capacity || 0;
    this.quantity = tank.quantity || 0;
    this.products = tank.products || 0;
    this.observations = tank.observations || "";
    this.measured = tank.measured || 0;
    this.reasons = tank.reasons || "";
  }
}

export class GetTank {
  id?: number;
  tank?: string;
  capacity?: number;
  quantity?: number;
  active?: boolean;
  tank_products?: TankProduct[];
  products?: Product[];
  required_quantity?: number;
  percentage?: number;
  pipe_number?: number;
  measured?: number;
  reasons?: string;
  priority?: number;
  purchase_detail_product_id?: number;

  constructor(tank: GetTank) {
    this.id = tank.id || undefined;
    this.tank = tank.tank || "";
    this.capacity = tank.capacity || 0;
    this.quantity = tank.quantity || 0;
    this.active = tank.active || false;
    this.tank_products = tank.tank_products || [];
    this.products = tank.products || [];
    this.required_quantity = tank.required_quantity || 0;
    this.percentage = tank.percentage || 0;
    this.pipe_number = tank.pipe_number || 1;
    this.measured = tank.measured || 0;
    this.reasons = tank.reasons || "";
    this.priority = tank.priority || 0;
    this.purchase_detail_product_id = tank.purchase_detail_product_id || 0;
  }
}

export class TankProduct {
  id?: number;
  tank_id?: number;
  tank?: Tank;
  product_id?: number;
  active?: boolean;
  observations?: string;
  measured?: number;
  reasons?: string;

  constructor(tankProduct: TankProduct) {
    this.id = tankProduct.id || undefined;
    this.tank_id = tankProduct.tank_id || undefined;
    this.tank = tankProduct.tank || undefined;
    this.product_id = tankProduct.product_id || undefined;
    this.active = tankProduct.active || false;
    this.observations = tankProduct.observations || "";
    this.measured = tankProduct.measured || 0;
    this.reasons = tankProduct.reasons || "";
  }
}
