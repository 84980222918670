import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SupplierPaymentReceipt } from 'app/models/payment_invoices';

@Component({
  selector: 'app-payment-supplier-attachments',
  templateUrl: './payment-supplier-attachments.component.html',
  styleUrls: ['./payment-supplier-attachments.component.css']
})
export class PaymentSupplierAttachmentsComponent {
  attachments: SupplierPaymentReceipt[] = [];
  dialogTitle = "Recibo";

  constructor(
    public dialogRef: MatDialogRef<PaymentSupplierAttachmentsComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.attachments = data.attachments;
  }

  safeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
