
<div class="w-full min-w-48 bg-white p-6">
  <div class="container">
    <label
      class="block uppercase text-slate-600 text-xs font-bold mb-2"
      htmlFor="product_id"
    >
      Producto
    </label>
    <select
      #productId
      class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
      (change)="onSelectProduct($event.target.value)"
    >
      <option value="">Selecciona un producto</option>
      <option *ngFor="let product of products" [value]="product.id">
        {{ product.name }}
      </option>
    </select>
  </div>
</div>