<div class="container bg-white mx-auto px-4 h-full py-4">
    <div class="flex justify-between rounded-t mb-0 px-4 py-3 border-0 pt-6 bg-white">
        <div class="flex w-full justify-center">
            <div>
                <h3 class="font-semibold text-lg">
                    {{dialogTitle}}
                </h3>
            </div>
        </div>
    </div>
    <form class="bg-white" [formGroup]="form">
        <div class="relative w-full mb-3 flex">

            <!-- Name -->
            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2 " htmlFor="name">
                    Nombre
                </label>
                <input type="text" name="name" formControlName="name" [ngClass]="{ 
                    'border-red-500': form.controls.name.invalid, 
                    'focus:border-red-500': form.controls.name.invalid, 
                    'focus:ring-red-500': form.controls.name.invalid 
                    }" *ngIf="form.controls.name.invalid ? 'prueba' : 'error'" class="border-2 px-3 py-3
                    placeholder-slate-300 
                    text-slate-600 bg-white rounded border-gray-300
                    text-sm focus:outline-none focus:ring
                    w-full ease-linear transition-all duration-150" />
                <div *ngFor="let validation of validationMessages.name">
                    <span *ngIf="formService.field(form, validation.type, 'name')" class="mt-2 text-sm text-red-500 ">
                        {{ validation.message }}
                    </span>
                </div>
            </div>

            <!-- Pump Measuare -->
            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="pump_measure_id">
                    Medidas de Bomba
                </label>
                <select name="pump_measure_id" formControlName="pump_measure_id" [ngClass]="{
                    'border-red-500': form.controls.pump_measure_id.invalid,
                    'focus:border-red-500': form.controls.pump_measure_id.invalid,
                    'focus:ring-red-500': form.controls.pump_measure_id.invalid
                  }" class="border-2 px-3 py-3
                  placeholder-slate-300 mb-3
                  text-slate-600 bg-white rounded border-gray-300
                  text-sm focus:outline-none focus:ring
                  w-full ease-linear transition-all duration-150">
                    <option *ngFor="let item of pumpMeasures" [ngValue]="item.id">{{ item.name }}</option>
                </select>
                <div *ngFor="let validation of validationMessages.pump_measure_id">
                    <span *ngIf="formService.field(form, validation.type, 'pump_measure_id')"
                        class="mt-2 text-sm text-red-500 ">
                        {{ validation.message }}
                    </span>
                </div>
            </div>
        </div>

        <!-- Description -->
        <div class="relative w-full mb-3 flex">
            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2 " htmlFor="description">
                    Descripcion
                </label>
                <textarea type="text" name="description" formControlName="description" class="border-2 px-3 py-3
                    placeholder-slate-300
                    text-slate-600 bg-white rounded border-gray-300
                    text-sm focus:outline-none focus:ring
                    w-full ease-linear transition-all duration-150">
                  </textarea>
            </div>
        </div>

        <!-- Mobile -->
        <div class="relative w-full mb-3 flex">
            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="mobile">
                    Movil
                </label>
                <input type="checkbox" name="mobile" formControlName="mobile" [ngClass]="{ 
                        'border-red-500': form.controls.mobile.invalid, 
                        'focus:border-red-500': form.controls.mobile.invalid, 
                        'focus:ring-red-500': form.controls.mobile.invalid 
                    }" class="border-2 px-3 py-3
                    placeholder-slate-300
                    text-slate-600 bg-white rounded-full border-gray-300
                    text-sm focus:outline-none focus:ring
                    ease-linear transition-all duration-150" />
                <div *ngFor="let validation of validationMessages.mobile">
                    <span *ngIf="formService.field(form, validation.type, 'status')" class="mt-2 text-sm text-red-500">
                        {{ validation.message }}
                    </span>
                </div>
            </div>
        </div>

        <!-- Buttons -->
        <div class="flex w-full justify-end">
            <button class="bg-slate-500 text-white 
                active:bg-slate-500 font-bold uppercase 
                text-xs px-4 py-2 rounded shadow hover:shadow-md 
                outline-none focus:outline-none mr-1 ease-linear 
                transition-all duration-150" (click)="closeModal()">
                Cancelar
            </button>
            <button class="bg-sky-600 text-white 
                active:bg-sky-600 font-bold uppercase 
                text-xs px-4 py-2 rounded shadow hover:shadow-md 
                outline-none focus:outline-none mr-1 ease-linear 
                transition-all duration-150" [ngClass]="{ 'disabled:opacity-25': form.invalid }"
                [disabled]="form.invalid" (click)="save()">
                {{ edit ? 'Actualizar' : 'Agregar' }}
            </button>
        </div>
    </form>
</div>