export class Pump {
    id?: number;
    pump_measure_id?: number;
    name?: string;
    mobile?: boolean;
    description?: string;
  
    constructor(pump: Pump) {
      {
        this.id = pump.id || undefined;
        this.pump_measure_id = pump.pump_measure_id || 0;
        this.name = pump.name || '';
        this.mobile = pump.mobile || false;
        this.description = pump.description || '';
      }
    }
  }