import { Component, Inject, OnInit } from "@angular/core";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { FormValidatorService } from "app/core/service/form-validator.service";
import { Company } from "app/models/company";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DataService } from "app/services/data.service";
import { InvoiceService } from "app/apiServices/invoice.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NotificationsComponent } from "app/components/alerts/notifications/notifications.component";
import { CostAttachment } from "app/models/cost_attachment";

@Component({
  selector: "app-assing-supplier-invoice",
  templateUrl: "./assing-supplier-invoice.component.html",
  styleUrls: ["./assing-supplier-invoice.component.css"],
})
export class AssingSupplierInvoiceComponent implements OnInit{
  dialogTitle: string = "Asignar Factura";
  rows = [];
  selected = [];
  ids = [];
  ColumnMode = ColumnMode;

  supplier: Company;
  form: FormGroup;
  orderCosts = [];
  currentCostAttachment: CostAttachment;
  total = 0;
  id_client: number;
  loading: boolean = false;

  validationMessages = {
    amount: [{ type: "required", message: "Ingresa el importe" }],
    date: [{ type: "required", message: "Ingrese la fecha" }],
  };

  constructor(
    private fb: FormBuilder,
    public formService: FormValidatorService,
    private dialogRef: MatDialogRef<AssingSupplierInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataService: DataService,
    private invoiceService: InvoiceService,
    private _snack: MatSnackBar
  ) {
    this.orderCosts = data.purchases;
    this.supplier = new Company({
      legal_name: this.orderCosts[0].company_supplier.legal_name,
    });
    this.id_client = this.orderCosts[0].company_supplier.id;
    this.total = this.orderCosts.reduce(
      (acc, item) => acc + item.total,
      0
    );
    this.ids = this.orderCosts.map((item) => item.id);
    var folios = this.orderCosts.map((item) => item.order.folio);
    
    this.form = this.createForm();
    this.form.get('amount').disable();
    this.form.get('amount').setValue(this.total.toFixed(2));
    this.form.get('observations').setValue(folios.toString());
  }

  ngOnInit(): void {
    let costIDetails = this.form.get('cost_invoice_details') as FormArray;
    for(const cost of this.orderCosts){
      const costInvoiceForm = this.createCostInvoiceDetails(cost);
      costIDetails.push(costInvoiceForm);
    }
  }

  createForm(): FormGroup {
    return this.fb.group({
      company_supplier_id: [this.id_client, [Validators.required]],
      invoice: [, []],
      date: [, [Validators.required]],
      amount: [, [Validators.required]],
      observations: [, []],
      cost_invoice_details: this.fb.array([]),
    });
  }
  createCostInvoiceDetails(invoice : any) {
    return this.fb.group({
      order_cost_id: [invoice.id, [Validators.required]],
      cost: [invoice.cost, [Validators.required]],
      quantity: [invoice.quantity, [Validators.required]],
      total: [invoice.total, [Validators.required]],
    });
  }

  changeDate(date: string, id: string): void {
    this.form.controls.date.patchValue(date);
    this.form.updateValueAndValidity();
  }

  onLeadingZero(event: Event): void {
    const num = (event.target as HTMLInputElement).value
      .replace("$", "")
      .replace(",", "")
      .replace(" ", "");
    const dec = num.split(".")[1];
    const len = dec && dec.length > 2 ? dec.length : 2;
    (event.target as HTMLInputElement).value = `$ ${Number(num).toFixed(len)}`;
  }

  createInvoiceSupplier(): void {
    this.loading = true;
    this.dataService.setLoadingValue(this.loading);
    this.form.get('amount').enable();
    if (this.form.valid && this.currentCostAttachment) {
      const data = {
        ...this.form.value,
        cost_attachments: [this.currentCostAttachment],
      };
      this.invoiceService.createCostInvoice(data).subscribe({
        next: (res) => {
          this.loading = false;
          this.dataService.setLoadingValue(this.loading);
          this.dialogRef.close(true);
          this.openSnack("snack-success", res.message);
        },
        error: (error) => {
          this.loading = false;
          this.dataService.setLoadingValue(this.loading);
          this.openSnack("snack-error", error.error.message);
        },
      });
    } else {
      this.loading = false;
      this.dataService.setLoadingValue(this.loading);
      this.openSnack(
        "snack-error",
        "Revisa el formulario y completa los campos requeridos"
      );
    }
  }

  onFileSelected(event: any): void {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      this.uploadFile(file, "CostInvoices", file.name);
    }
  }

  uploadFile(file: File, folder: string, filename: string): void {
    this.invoiceService.uploadFile(file, folder, filename).subscribe({
      next: (res) => {
        this.currentCostAttachment = res.data;
        this.currentCostAttachment.order_id = this.orderCosts[0]?.order?.id;
        this.currentCostAttachment.name = res.data.filename;
        this.currentCostAttachment.active = true;
      },
      error: (err) => {
        this.openSnack("snack-error", err.error.message);
      },
    });
  }

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
      verticalPosition: "top",
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}
