import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CatalogServicesService } from "app/apiServices/catalog-services.service";
import { FormValidatorService } from "app/core/service/form-validator.service";
import { Role } from "app/models/role";

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.css"],
})
export class FormComponent {
  form: FormGroup;
  dataSource: Role;
  edit = false;
  dialogTitle: string;
  validationMessages = {
    name: [{ type: "required", message: "Ingrese un nombre" }],
    description: [{ type: "required", message: "Ingrese desripción"}]
  };

  constructor(
    private dialogRef: MatDialogRef<FormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public formService: FormValidatorService,
    public apiService: CatalogServicesService
  ) {
    this.data = data;

    if (data.action === "edit") {
      this.dialogTitle = "Editar Rol";
      this.dataSource = data.data;
      this.edit = true;
    } else {
      this.dialogTitle = "Agregar rol";
      this.dataSource = new Role({});
    }
    this.form = this.createForm();
  }

  createForm(): FormGroup {
    return this.fb.group({
      id: [this.dataSource.id],
      name: [this.dataSource.name, [Validators.required]],
      description: [this.dataSource.description, [Validators.required]],
    });
  }

  save(): void {
    if (this.form.valid) {
      this.dataSource = this.form.value;
      this.storeUpdate(this.edit ? "update" : "store");
    } else {
      this.formService.allFields(this.form);
    }
  }

  storeUpdate(method: string): void {
    const data = {
      method,
      data: this.dataSource,
      status: 200,
    };

    if (method === "store") {
      this.apiService.createRole(this.dataSource).subscribe(r => {
        
      })
    } else {
      this.apiService.updateRole(this.dataSource).subscribe(r => {
        
      })
    }
    this.closeModal()
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
