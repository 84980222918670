import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { InventoryService } from "app/apiServices/inventory.service";
import { InProductRes } from "app/interfaces/product.interface";
import { Product } from "app/models/product";
import { Select, initTE } from "tw-elements";

@Component({
  selector: "app-add-lab-detail-product",
  templateUrl: "./add-lab-detail-product.component.html",
  styleUrls: ["./add-lab-detail-product.component.css"],
})
export class AddLabDetailProductComponent {
  @ViewChild("productId", { static: true })
  productSelect: ElementRef;
  products: Array<Product> = [];
  selectOptions = {
    selectFilter: true,
    selectNoResultText: "No se encontraron resultados",
    selectSearchPlaceholder: "Buscar...",
  };

  constructor(
    private inventoryService: InventoryService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddLabDetailProductComponent>
  ) {
    this.products = this.data.products;
  }

  ngAfterViewInit(): void {
    this.createSelect();
  }

  onSelectProduct(productId: number): void {
    const product = this.products.find((product) => {
      return product.id == productId;
    });
    this.dialogRef.close(product);
  }

  createSelect(): void {
    initTE({
      Select: new Select(this.productSelect.nativeElement, this.selectOptions),
    });
  }
}
