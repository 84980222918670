<div class="">
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-200 border-0">
        <div class="justify-center flex w-auto w-full mt-4">
            <div class="flex justify-center w-2/4">
                <h1 class="text-4xl font-bold text-cyan-500">{{tanques.length}}</h1>
                <div class="flex items-center justify-center ml-2">
                    <p class="text-center font-bold text-l">Numero de tanques activos</p>
                </div>
            </div>
            <div class="flex justify-between w-2/4">
                <div class="w-1/3 flex ml-4">
                <div class="flex items-center justify-center">
                    <div class="bg-sky-600 py-2 px-4">
                    </div>
                </div>
                <div class="flex items-center justify-center ml-2">
                    <p class="text-center font-bold text-md">Inicio del dia</p>
                </div>
                </div>
                <div class="w-1/3 flex">
                    <div class="flex items-center justify-center">
                        <div class="bg-lime-500 py-2 px-4">
                        </div>
                    </div>
                    <div class="flex items-center justify-center ml-2">
                        <p class="text-center font-bold text-l">Inventario actual</p>
                    </div>
                </div>
                <div class="w-2/5 flex">
                    <div class="flex items-center justify-center">
                        <div class="bg-orange-500 py-2 px-4 ml-2">
                        </div>
                    </div>
                    <div class="flex items-center justify-center ml-2">
                        <p class="text-center font-bold text-md">Final del dia</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-5" style="overflow-y: auto; height: 400px;">
            <canvas id="chart"></canvas>
        </div>
    </div>
</div>
