import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ClientsService } from "app/apiServices/clients.service";
import { InventoryService } from "app/apiServices/inventory.service";
import { FormValidatorService } from "app/core/service/form-validator.service";
import { Product } from "app/models/product";

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.css"],
})
export class FormComponent implements OnInit {
  form: FormGroup;
  dataSource: any;
  edit = false;
  dialogTitle: string;

  selectedItems = [];
  selectedRolItems = [];
  selectedProducts = [];
  dropdownSettings = {};
  dropdownProductsSettings: any = {};

  validationMessages = {
    product_id: [{ type: "required", message: "Selecciona un producto" }],
    client_id: [{ type: "required", message: "Selecciona un cliente" }],
    cost_per_liter: [{ type: "required", message: "Ingresa el descuento" }],
  };

  clients: any[] = [];

  products: Product[];

  constructor(
    private dialogRef: MatDialogRef<FormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public formService: FormValidatorService,
    private productService: InventoryService,
    private clientService: ClientsService
  ) {
    this.data = data;
    this.dropdownSettings = {
      singleSelection: data.action === "edit" ? true : false,
      idField: "id",
      textField: "name",
      noDataAvailablePlaceholderText: "No hay datos",
      selectAllText: "Seleccionar Todas",
      unSelectAllText: "Deseleccionar Todas",
      searchPlaceholderText: "Buscar",
      itemsShowLimit: 6,
      allowSearchFilter: true,
    };
    this.dropdownProductsSettings = {
      ...this.dropdownSettings,
    };

    if (data.action === "edit") {
      this.dialogTitle = "Editar Registro";
      this.dataSource = data.data;
      this.edit = true;
      this.selectedProducts = [data.data.product];
    } else {
      this.dialogTitle = "Agregar Registro";
      this.dataSource = {};
    }

    this.form = this.createForm();
  }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.productService.getProducts().subscribe((r) => {
      this.products = r.data.filter((p) => p.active);
    });

    this.clientService.readCompanies().subscribe((r) => {
      r.data.forEach((element) => {
        if (element.clients.length >= 1 && element.active)
          this.clients.push(element);
      });
    });
  }

  createForm(): FormGroup {
    //console.log(this.dataSource)
    if (this.edit) {
      return this.fb.group({
        id: [this.dataSource.id],
        product_id: [this.dataSource.product.id, [Validators.required]],
        client_id: [this.dataSource.client.id, [Validators.required]],
        cost_per_liter: [
          this.leadingZero(this.dataSource.cost_per_liter.toString()),
          [Validators.required],
        ],
      });
    } else {
      return this.fb.group({
        id: [this.dataSource.id],
        product_id: [this.dataSource.product, [Validators.required]],
        client_id: [null, [Validators.required]],
        cost_per_liter: [this.dataSource.cost_per_liter, [Validators.required]],
      });
    }
  }

  save(): void {
    if (this.form.valid) {
      this.dataSource = this.form.value;
      this.dataSource.client_id = Number(this.dataSource.client_id);
      this.storeUpdate(this.edit ? "update" : "store");
    } else {
      this.formService.allFields(this.form);
      alert("Datos incorrectos");
    }
  }

  onItemSelect(item: any) {}
  onProductSelect(item: any) {}
  onSelectAll(items: any) {}

  storeUpdate(method: string): void {
    const data = {
      method,
      data: this.dataSource,
      status: 200,
    };

    if (method === "store") {
      const discountProducts = {
        discount_products: this.form.value.product_id.map((product) => {
          return {
            client_id: this.form.value.client_id,
            product_id: product.id,
            cost_per_liter: Number(parseFloat(this.form.value.cost_per_liter)),
          };
        }),
      };
      this.clientService
        .createDiscountProduct(discountProducts)
        .subscribe((r) => {
          this.closeModal();
        });
    } else {
      this.dataSource.product_id = this.form.value.product_id[0]?.id ?? null;
      this.dataSource.cost_per_liter = Number(
        parseFloat(this.form.value.cost_per_liter)
      );

      this.clientService
        .updateDiscountProduct(this.dataSource)
        .subscribe((r) => {
          this.closeModal();
        });
    }
  }

  onLeadingZero(event: Event): void {
    const num = (event.target as HTMLInputElement).value
      .replace("$", "")
      .replace(",", "")
      .replace(" ", "");
    const dec = num.split(".")[1];
    const len = dec && dec.length > 2 ? dec.length : 2;
    (event.target as HTMLInputElement).value = `$ ${Number(num).toFixed(len)}`;
  }

  leadingZero(num: string): string {
    const dec = num.split(".")[1];

    if (!dec && num == "0") return "0.00";
    else if (!dec && num != "0") return `${num}.00`;

    const len = dec && dec.length > 2 ? dec.length : 2;
    const fixed = Number(num).toFixed(len);
    return fixed;
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
