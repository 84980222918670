<div class="container bg-white mx-auto px-4 h-full py-4">
    <div class="flex justify-between rounded-t mb-0 px-4 py-3 border-0 pt-6 bg-white">
        <div class="flex w-full justify-center">
            <div>
                <h3 class="font-semibold text-lg">
                    Dirreciones
                </h3>
            </div>
        </div>
    </div>
    <form class="bg-white" [formGroup]="form">

        <div class="relative w-full mb-3 flex">

            <!-- CP -->
            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="zip_code">
                    Codigo
                </label>
                <input type="text" name="zip_code" formControlName="zip_code" [ngClass]="{
                        'border-red-500': form.get('zip_code').invalid,
                        'focus:border-red-500': form.get('zip_code').invalid,
                        'focus:ring-red-500': form.get('zip_code').invalid
                    }"
                    class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    (input)="form.get('zip_code').value.length === 5 ? getData() : ''" maxlength="5" />
                <div *ngFor="let validation of validationMessages.zip_code">
                    <span *ngIf="formService.field(form, validation.type, 'zip_code')"
                        class="mt-2 text-sm text-red-500">
                        {{ validation.message }}
                    </span>
                </div>
            </div>


            <!-- State -->
            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2 " htmlFor="state">
                    Estado
                </label>
                <input type="text" name="state" formControlName="state" [ngClass]="{ 
                    'border-red-500': form.controls.state.invalid, 
                    'focus:border-red-500': form.controls.state.invalid, 
                    'focus:ring-red-500': form.controls.state.invalid 
                    }" *ngIf="form.controls.state.invalid ? 'prueba' : 'error'" class="border-2 px-3 py-3
                    placeholder-slate-300 
                    text-slate-600 bg-white rounded border-gray-300
                    text-sm focus:outline-none focus:ring
                    w-full ease-linear transition-all duration-150" />
                <!-- <div *ngFor="let validation of validationMessages.cp">
                    <span *ngIf="formService.field(form, validation.type, 'cp')" class="mt-2 text-sm text-red-500 ">
                        {{ validation.message }}
                    </span>
                </div> -->
            </div>

        </div>

        <div class="relative w-full mb-3 flex">

            <!-- Municipality -->
            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2 " htmlFor="municipality">
                    Municipio
                </label>
                <input type="text" name="municipality" formControlName="municipality" [ngClass]="{ 
                    'border-red-500': form.controls.municipality.invalid, 
                    'focus:border-red-500': form.controls.municipality.invalid, 
                    'focus:ring-red-500': form.controls.municipality.invalid 
                    }" *ngIf="form.controls.municipality.invalid ? 'prueba' : 'error'" class="border-2 px-3 py-3
                    placeholder-slate-300 
                    text-slate-600 bg-white rounded border-gray-300
                    text-sm focus:outline-none focus:ring
                    w-full ease-linear transition-all duration-150" />
                <!-- <div *ngFor="let validation of validationMessages.cp">
                    <span *ngIf="formService.field(form, validation.type, 'cp')" class="mt-2 text-sm text-red-500 ">
                        {{ validation.message }}
                    </span>
                </div> -->
            </div>

            <!-- Locality -->
            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2 " htmlFor="locality">
                    Localidad
                </label>
                <select name="locality" formControlName="locality" [ngClass]="{
                  'border-red-500': form.controls.locality.invalid,
                  'focus:border-red-500': form.controls.locality.invalid,
                  'focus:ring-red-500': form.controls.locality.invalid
                }" class="border-2 px-3 py-3
                placeholder-slate-300
                text-slate-600 bg-white rounded border-gray-300
                text-sm focus:outline-none focus:ring
                w-full ease-linear transition-all duration-150">
                    <option *ngFor="let item of dataLocalities?.localities" [ngValue]="item">{{ item.name }}</option>
                </select>
                <!-- <div *ngFor="let validation of validationMessages.cp">
                    <span *ngIf="formService.field(form, validation.type, 'cp')" class="mt-2 text-sm text-red-500 ">
                        {{ validation.message }}
                    </span>
                </div> -->
            </div>

        </div>

        <!-- Buttons -->
        <div class="flex w-full justify-end">
            <button class="bg-slate-500 text-white 
                active:bg-slate-500 font-bold uppercase 
                text-xs px-4 py-2 rounded shadow hover:shadow-md 
                outline-none focus:outline-none mr-1 ease-linear 
                transition-all duration-150" (click)="closeModal()">
                Cancelar
            </button>
            <button class="bg-sky-600 text-white 
                active:bg-sky-600 font-bold uppercase 
                text-xs px-4 py-2 rounded shadow hover:shadow-md 
                outline-none focus:outline-none mr-1 ease-linear 
                transition-all duration-150" [ngClass]="{ 'disabled:opacity-25': form.invalid }"
                [disabled]="form.invalid" (click)="sendData()">
                Agregar
            </button>
        </div>
    </form>
</div>