<div class="bg-white px-4 w-full h-full py-4 overflow-y-auto">
  <div class="flex m-4">
    <div class="w-11/12 justify-between rounded-t mb-0 border-0 bg-white">
      <div>
        <h3 class="text-lg">
          {{ dialogTitle }}
        </h3>
      </div>
    </div>
  </div>
  <div class="flex items-stretch m-4">
 
      <div class="m-4 self-center">
        <form [formGroup]="form">

          <app-date
            idName="date"
            title="Fecha de pago"
            
            (dateChange)="changeDate($event)"
          ></app-date>
        </form>
      <div *ngFor="let validation of validationMessages.date">
        <span
          *ngIf="formService.field(form, validation.type, 'date')"
          class="mt-2 text-sm text-red-500"
        >
          {{ validation.message }}
        </span>
      
      </div> 
     
      <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="total">
               Notas de credito
      </label>
      <select
        #creditNotesId
        class="border-1 py-2.5 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-fit ease-linear transition-all duration-150"
        (change)="onChangeCreditNote($event.target.value)"
      >
        <option value="">Selecciona un nota de crédito</option>
        <option *ngFor="let creditNote of creditNotes" [value]="creditNote.id">
          {{ creditNote.type + " " + creditNote.date }}
        </option>
      </select>
    </div>
    <div class="m-4 self-center">
      <p class="font-bold text-center">
        Saldo inicial: ${{ initialBalance | number : "1.2-2" }}
      </p>
    </div>
    <div class="m-4 self-center">
      <p class="font-bold text-center">
        Saldo sin imputar: ${{ nowBalance | number : "1.2-2" }}
      </p>
    </div>
    <div class="m-4 self-center">
      <p class="font-bold text-center">
        Total imputado: ${{ totalImputed | number : "1.2-2" }}
      </p>
    </div>

    <div class="m-4 self-center">
      <p class="font-bold text-center">
        Total disponible: ${{ totalAvailable | number : "1.2-2" }}
      </p>
    </div>
  </div>

  <div class="flex flex-row">
    <div class="block w-full overflow-x-auto rounded-lg w-2/4 m-4">
      <ngx-datatable
        class="material"
        [rows]="rows"
        [columnMode]="ColumnMode?.force"
        [headerHeight]="50"
        rowHeight="auto"
        [limit]="5"
        [scrollbarH]="true"
      >
        <ngx-datatable-column name="Factura" prop="id"></ngx-datatable-column>
        <ngx-datatable-column name="Total">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.dueTotal | currency }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Ordenes">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div *ngFor="let order of row.orders">
              {{ "Folio " + order.folio }}
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Cantidad a imputar">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <input
            type="text"
            name="payment"
            prefix="$ "
            mask="separator.6"
            (change)="onImputedChange($event, row)"
            [(ngModel)]="row.imputar"
            class="border-gray-300 px-3 py-2 placeholder-slate-300 border-1 text-slate-600 bg-white rounded text-sm focus:ring-1-1 focus:outline-none w-full ease-linear transition-all duration-150"
          />
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Eliminar">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <button class="bg-sky-600  text-white active:bg-slate-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 ease-linear transition-all duration-150" type="button"
            (click)="deleteInvoice(row)">
              <i class="fas fa-trash"></i>
            </button>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>

  <div class="flex items-center justify-end">
    <button
      class="bg-slate-500 text-white active:bg-slate-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      (click)="closeModal()"
    >
      Cancelar
    </button>

    <button
      class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      (click)="updatePaymentInvoices()"
     >
      Asignar
    </button>
  </div>
</div>
