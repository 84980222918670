import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { CostAttachment } from "app/models/cost_attachment";

@Component({
  selector: "app-invoice-dialog",
  templateUrl: "./invoice-dialog.component.html",
  styleUrls: ["./invoice-dialog.component.css"],
})
export class InvoiceDialogComponent {
  attachments: CostAttachment[] = [];
  dialogTitle = "Factura";

  constructor(
    public dialogRef: MatDialogRef<InvoiceDialogComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.attachments = data.attachments;
    console.log(this.attachments);
  }

  safeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
