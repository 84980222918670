<div>
  <app-sidebar></app-sidebar>
  <div class="relative md:ml-64 bg-slate-100">
    <app-admin-navbar></app-admin-navbar>
    <app-header-stats></app-header-stats>
    <div class="px-4 md:px-10 mx-auto w-full -m-24 bg-custom">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
