<div class="bg-white px-4 w-full h-full py-4 overflow-y-auto">
  <div class="flex">
    <div class="w-11/12 justify-between rounded-t mb-0 border-0 bg-white">
      <div>
        <h3 class="text-lg">
          {{ dialogTitle }}
        </h3>
      </div>
    </div>

    <div class="w-1/12 justify-end mb-0">
      <button
        class="text-end text-sky-600 cursor-pointer hover:text-sky-700 text-2xl pl-12 ease-linear transition-all duration-150"
        (click)="close()"
      >
        <i class="fas fa-times-circle"></i>
      </button>
    </div>
  </div>
  <div class="flex pb-8">
    <p class="font-semibold">Proveedor: {{ supplier?.legal_name }}</p>
  </div>
  <div class="flex flex-row">
    <div class="block w-full overflow-x-auto rounded-lg w-2/4 m-4">
      <ngx-datatable
        class="material"
        [rows]="orderCosts"
        [columnMode]="ColumnMode?.force"
        [headerHeight]="50"
        rowHeight="auto"
        [limit]="5"
        [scrollbarH]="true"
      >
        <ngx-datatable-column name="Folio">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.order.folio }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Producto">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.order_cost_type.name }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Costo">
          <ng-template let-row="row" ngx-datatable-cell-template>
            ${{ row?.total }}
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
    <div class="w-2/4 m-4">
      <form [formGroup]="form">
        <div class="flex flex-col">
          <div class="w-full">
            <div class="container">
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="amount"
              >
                Importe
              </label>
              <input
                type="text"
                name="amount"
                formControlName="amount"
                prefix="$ "
                mask="separator.6"
                thousandSeparator=","
                [ngClass]="{
                  'focus:border-red-500': form.controls.amount.invalid,
                  'focus:ring-red-500': form.controls.amount.invalid
                }"
                (change)="onLeadingZero($event)"
                class="border-gray-300 px-3 py-3 placeholder-slate-300 border-1 text-slate-600 bg-white rounded text-sm focus:ring-1-1 focus:outline-none w-full ease-linear transition-all duration-150"
              />
              <div *ngFor="let validation of validationMessages.amount">
                <span
                  *ngIf="formService.field(form, validation.type, 'amount')"
                  class="mt-2 text-sm text-red-500"
                >
                  {{ validation.message }}
                </span>
              </div>
            </div>
          </div>

          <div class="w-full">
            <div class="container">
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="total"
              >
                Factura
              </label>
              <input
                type="text"
                name="invoice"
                formControlName="invoice"
                [ngClass]="{
                  'focus:border-red-500': form.controls.invoice.invalid,
                  'focus:ring-red-500': form.controls.invoice.invalid
                }"
                class="border-gray-300 px-3 py-3 placeholder-slate-300 border-1 text-slate-600 bg-white rounded text-sm focus:ring-1-1 focus:outline-none w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>

          <div class="w-full">
            <div class="container">
              <app-date
                idName="date"
                title="Fecha"
                (dateChange)="changeDate($event, 'date')"
              ></app-date>
              <div *ngFor="let validation of validationMessages.date">
                <span
                  *ngIf="formService.field(form, validation.type, 'load_date')"
                  class="mt-2 text-sm text-red-500"
                >
                  {{ validation.message }}
                </span>
              </div>
            </div>
          </div>
          <div class="w-full">
            <div class="container">
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="observations"
              >
                Descripción
              </label>
              <textarea
                name="description"
                formControlName="observations"
                class="border-gray-300 px-3 py-3 placeholder-slate-300 border-2 text-slate-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              >
              </textarea>
            </div>
          </div>

          <div class="w-full">
            <div class="container">
              <label
                class="block mb-2 text-sm font-medium text-gray-900"
                for="cost_attachments"
                >Subir factura</label
              >
              <input
                class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
                id="cost_attachments"
                type="file"
                (change)="onFileSelected($event)"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="flex items-center justify-end">
    <button
      class="bg-slate-500 text-white active:bg-slate-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      (click)="close()"
    >
      Cancelar
    </button>

    <button
      class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      [ngClass]="{
        'disabled:opacity-25': form.invalid || !currentCostAttachment
      }"
      [disabled]="form.invalid || !currentCostAttachment"
      (click)="createInvoiceSupplier()"
    >
      Facturar
    </button>
  </div>
</div>
