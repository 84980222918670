import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Chart } from 'chart.js';
@Component({
  selector: 'app-day-sales',
  templateUrl: './day-sales.component.html',
  styleUrls: ['./day-sales.component.css']
})
export class DaySalesComponent implements OnInit{
  chart : Chart;
  total = 0;
  constructor(private cdr: ChangeDetectorRef) {}

  //Prueba de cambio

  ngAfterViewInit() {
    setTimeout(() => {
      this.initializeChart();
      this.cdr.detectChanges();
    }, 300);
  }
  ngOnInit(): void {
    this.total = 14535420;
  }

  initializeChart(){
    const data = {
      labels: ['Client 1','Client 2','Client 3','Client 4','Client 5','Client 6','Client 7', 'Client 8', 'Client 9', 'Client 10'],
      datasets: [{
        data: [650000, 590000, 800000, 8100000, 5600000, 5500000, 400000, 500000, 600000, 700000],
        fill: true,
        backgroundColor: [
          'rgba(255, 99, 132)',
          'rgba(255, 159, 64)',
          'rgba(255, 205, 86)',
          'rgba(75, 192, 192)',
          'rgba(54, 162, 235)',
          'rgba(153, 102, 255)',
          'rgba(54, 162, 235)', 
          'rgba(255, 206, 86)',
          'rgba(75, 192, 192)', 
          'rgba(153, 102, 255)' 
        ],
        /*borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(201, 203, 207)'
        ],
        borderWidth: 1*/
      }]
    };
    const options = {
      responsive: false,
      legend: {
        display: false
      },
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          display: true,
          gridLines: {
            display: false
          },
          position: 'top',
          ticks: {
            maxTicksLimit: 2,
            fontColor: 'black',
            fontStyle: 'semibold',
            fontSize: 16,
            callback: function(value, index, values) {
              return 'MX ' + value.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              });
            }
          }
        }],
        yAxes: [{
          gridLines: {
            display: false
          },
          ticks: {

            callback: function(value, index, values) {
              return value;
            },
            fontColor: '#000000',
            fontSize: 16,
            fontStyle: 'bold' 
          }
        }]
      },
      tooltips: {
        callbacks: {
          label: function(tooltipItem, data) {
            const specificData = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            const formattedValue = specificData.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            });
            return "MX " + formattedValue;
          }
        },
        titleFontSize: 16,
        bodyFontSize: 14, 
        displayColors: false
      },
      animation: {
        onComplete: function() {
          var chartInstance = this.chart,
          ctx = chartInstance.ctx;
          ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, 'bold  ', Chart.defaults.global.defaultFontFamily);
          ctx.textAlign = 'center';
          ctx.fillStyle = 'black'; 

          this.data.datasets.forEach(function(dataset, i) {
            var meta = chartInstance.controller.getDatasetMeta(i);
            meta.data.forEach(function(bar, index) {
              var data = dataset.data[index];
              ctx.fillText("MX $" + data, bar._model.x + 50, bar._model.y);
            });
          });
        }
      }
    };
    this.chart = new Chart('chart', {
      type: 'horizontalBar',
      data : data,
      options: options
     });
      

  }
}
