<div class="">
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-200 border-0">
        <div class="relative flex flex-col min-w-0 break-words w-full rounded">
            
            <!-- First row -->
            <div class="grid grid-cols-2 gap-2 rounded-t mb-0 px-4 py-3 border-0 pt-4">
                <div class="flex">
                    <img src="../../../../../assets/img/truck.png" alt="" class="mirrowImg bannerImg">
                    <b class="text-yellow-500 text-2xl pt-6 pl-2">Unidades en Planta</b>
                </div>
                <div class="flex">
                    <b class="text-yellow-500 text-6xl pt-2 pl-2">{{this.total}}</b>
                    <b class="pt-6 pl-2 text-lg">Número de unidades en este momento</b>
                </div>
            </div>
            
            <!-- Second Row Table -->
            <div class="rounded-t mb-0 px-4 py-3 border-0">
                <div class="relative flex flex-col min-w-0 break-words w-full rounded">
                    <div class="grid grid-cols-2 gap-2 rounded-t mb-0 px-4 py-3 border-0">
            
                        <!-- Card 1 -->
                        <div class="">
                            <div class="text-lg text-center">  <i class="far fa-clock text-red-600 pr-2"></i> <b>Unidades en Espera</b> <i class="fas fa-download text-cyan-600 pl-2"></i> <br><b>Descarga</b></div>
                            <div class="flex justify-center items-center">
                                <div class="w-3/4">
                                    <div class="flex justify-center items-center w-full">
                                        <div class="w-1/2 pt-4 text-right text-8xl pr-8"><b>{{this.waiting_for_unloading}}</b></div>
                                        <div class="w-1/2 text-right pt-2">
                                            <img src="../../../../../assets/img/truck.png" alt="" class="mirrowImg smallerImg">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <!-- Card 2 -->
                        <div>
                            <div class="text-lg text-center"> <i class="far fa-clock text-red-600 pr-2"></i> <b>Unidades en Espera </b> <i class="fas fa-upload text-emerald-600 pl-2"></i> <b> <br>Carga</b></div>
                            <div class="flex justify-center items-center">
                                <div class="w-3/4">
                                    <div class="flex justify-center items-center w-full">
                                        <div class="w-1/2 pt-4 text-right text-8xl pr-8"><b>{{this.waiting_for_loading}}</b></div>
                                        <div class="w-1/2 text-right pt-2">
                                            <img src="../../../../../assets/img/truck.png" alt="" class="mirrowImg smallerImg">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Second Row -->
                    <div class="grid grid-cols-2 gap-2 rounded-t mb-0 px-4 py-3 border-0 pt-4">
            
                        <!-- Card 3 -->
                        <div>
                            <div class="text-lg text-center"><b>Unidades en</b> <i class="fas fa-download text-cyan-600 pl-2"></i> <b><br>Carga</b></div>
                            <div class="flex justify-center items-center">
                                <div class="w-3/4">
                                    <div class="flex justify-center items-center w-full">
                                        <div class="w-1/2 pt-4 text-right text-8xl pr-8"><b>{{this.loading}}</b></div>
                                        <div class="w-1/2 text-right pt-2">
                                            <img src="../../../../../assets/img/truck.png" alt="" class="mirrowImg smallerImg">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                        <!-- Cart 4 -->
                        <div>
                            <div class="text-lg text-center"><b>Unidades en</b> <i class="fas fa-upload text-emerald-600 pl-2"></i> <b><br>Descarga</b></div>
                            <div class="flex justify-center items-center">
                                <div class="w-3/4">
                                    <div class="flex justify-center items-center w-full">
                                        <div class="w-1/2 pt-4 text-right text-8xl pr-8"><b>{{this.unloading}}</b></div>
                                        <div class="w-1/2 text-right pt-2">
                                            <img src="../../../../../assets/img/truck.png" alt="" class="mirrowImg smallerImg">
                                        </div>
                                        <!-- <div class="w-1/2 pt-4"><img src="../../../../../assets/img/truck.png" alt="" class="mirrowImg smallerImg pl-8"></div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
            </div>
        </div>

    </div>
  </div>