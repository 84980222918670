import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { InAdditionalRequirementRes } from "app/interfaces/additional.requirement";
import { InGlobalRes } from "app/interfaces/globalRes.interface";
import { InLoadingTypeRes } from "app/interfaces/loading.type";
import {
  InCostTypeRes,
  InOrderCostRes,
} from "app/interfaces/order-cost.interface";
import { InPaymentMethodRes } from "app/interfaces/payment.method";
import { InSalesRes } from "app/interfaces/sale";
import { InTransportTypeRes } from "app/interfaces/transport.type";
import { OrderCost } from "app/models/order-cost";
import { Purchase } from "app/models/purchase";
import { Sale } from "app/models/sale";
import { environment } from "environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SalesService {
  constructor(public http: HttpClient) { }

  getSalesTypes(): Observable<InSalesRes> {
    return this.http.get<any>(`${environment.url}sales/order-statuses/get`);
  }

  getSales(
    starDate: string | null = null,
    endDate: string | null = null,
    statusId: number | undefined = undefined,
    get_deactivated: boolean = false,
    client_id: number | undefined = undefined,
    company_main_id: number | undefined = undefined
  ): Observable<InSalesRes> {
    const statusParamString = statusId ? `status_id=${statusId}` : "";

    if (starDate && endDate) {
      return this.http.get<any>(
        `${environment.url}sales/orders/get?from_date=${starDate}&to_date=${endDate}${get_deactivated ? "&get_deactivated=true" : "&get_deactivated=false"}&${statusParamString}`
      );
    }
    if (client_id && company_main_id && !get_deactivated) {
      return this.http.get<any>(
        `${environment.url}sales/orders/get?client_id=${client_id}&company_main_id=${company_main_id}`
      );
    }

    return this.http.get<any>(
      `${environment.url}sales/orders/get?${statusParamString}${get_deactivated ? "&get_deactivated=true" : "&get_deactivated=false"}`
    );
  }

  getSale(data: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}sales/orders/get/${data.id}`, {});
  }

  createSale(data: Sale): Observable<InGlobalRes> {
    return this.http.post<any>(
      `${environment.url}sales/orders/create`,
      data,
      {}
    );
  }

  editSale(data: Sale): Observable<InGlobalRes> {
    return this.http.patch<any>(
      `${environment.url}sales/orders/update/${data.id}`,
      data,
      {}
    );
  }

  deleteSale(id: number): Observable<InGlobalRes> {
    return this.http.delete<any>(`${environment.url}sales/orders/delete/${id}`);
  }

  getPaymentMethods(): Observable<InPaymentMethodRes> {
    return this.http.get<any>(`${environment.url}payments/payment-methods/get`);
  }

  getLoadingTypes(): Observable<InLoadingTypeRes> {
    return this.http.get<any>(`${environment.url}sales/loading-types/get`);
  }

  getAdditionaRequirements(): Observable<InAdditionalRequirementRes> {
    return this.http.get<any>(
      `${environment.url}sales/additional-requirements/get`
    );
  }

  getTransportTypes(): Observable<InTransportTypeRes> {
    return this.http.get<any>(`${environment.url}sales/transport-types/get`);
  }

  /* Purchases */
  createPurchase(data: any): Observable<any> {
    return this.http.post<any>(
      `${environment.url}purchases/orders/create`,
      data,
      {}
    );
  }

  readPurchases(): Observable<any> {
    return this.http.get<any>(`${environment.url}purchases/orders/get/`);
  }

  updatePurchase(data: any, id: number): Observable<any> {
    return this.http.patch<any>(
      `${environment.url}purchases/orders/update/${id}`,
      data,
      {}
    );
  }

  typedUpdatePurchase(data: Purchase): Observable<InGlobalRes> {
    return this.http.patch<InGlobalRes>(
      `${environment.url}purchases/orders/update/${data.id}`,
      data,
      {}
    );
  }

  deletePurchase(id: number): Observable<any> {
    return this.http.delete<any>(
      `${environment.url}purchases/orders/delete/${id}`
    );
  }

  /* Services */
  getServices(): Observable<any> {
    return this.http.get<any>(`${environment.url}purchases/services/get`);
  }

  getTypeCosts(): Observable<InCostTypeRes> {
    return this.http.get<any>(`${environment.url}costs/types/get`);
  }

  getOrderCosts(client_id: number | undefined = undefined,
    company_main_id: number | undefined = undefined): Observable<InOrderCostRes> {
    if (company_main_id && client_id) {
      return this.http.get<any>(
        `${environment.url}costs/orders/get?client_id=${client_id}&company_main_id=${company_main_id}`
      );
    }
    return this.http.get<any>(`${environment.url}costs/orders/get`);
  }

  createOrderCost(data: OrderCost): Observable<InGlobalRes> {
    return this.http.post<any>(
      `${environment.url}costs/orders/create`,
      data,
      {}
    );
  }

  updateOrderCost(data: OrderCost): Observable<InGlobalRes> {
    return this.http.patch<InGlobalRes>(
      `${environment.url}costs/orders/update/${data.id}`,
      data,
      {}
    );
  }

  deleteOrderCost(id: number): Observable<any> {
    return this.http.delete<any>(`${environment.url}costs/orders/delete/${id}`);
  }
}
