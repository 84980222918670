export class Company {
  id?: number;
  regimen_fiscal_id?: number;
  uso_cfdi_id?: number;
  legal_id?: string;
  contact_name?: string;
  contact_phone?: number;
  legal_name?: string;
  folio?: string;
  main?: boolean;
  seller?: boolean;
  is_client?: boolean;
  is_supplier?: boolean;
  is_transportist?: boolean;
  active?: boolean;
  regimen_fiscal?: any;
  clients?: CompanyClient[];
  suppliers?: CompanyClient[];
  transportists?: CompanyClient[];
  company_clients?: CompanyClient[];
  company_suppliers?: CompanyClient[];

  constructor(company: Company) {
    {
      this.id = company.id || undefined;
      this.regimen_fiscal_id = company.regimen_fiscal_id || 0;
      this.uso_cfdi_id = company.uso_cfdi_id || 0;
      this.legal_id = company.legal_id || "";
      this.contact_name = company.contact_name || "";
      this.contact_phone = company.contact_phone || 0;
      this.legal_name = company.legal_name || "";
      this.folio = company.folio || "";
      this.main = company.main || false;
      this.seller = company.seller || false;
      this.is_client = company.is_client || false;
      this.is_supplier = company.is_supplier || false;
      this.is_transportist = company.is_transportist || false;
      this.active = company.active || false;
      this.regimen_fiscal = company.regimen_fiscal || null;
      this.clients = company.clients || null;
      this.suppliers = company.suppliers || null;
      this.transportists = company.transportists || null;
      this.company_clients = company.company_clients || [];
      this.company_suppliers = company.company_suppliers || [];
    }
  }
}

export class CompanyClient {
  id?: number;
  active?: boolean;
  company?: Company;

  constructor(companyClient: CompanyClient) {
    {
      this.id = companyClient.id || undefined;
      this.active = companyClient.active || false;
      this.company = companyClient.company || undefined;
    }
  }
}
