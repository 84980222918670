import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { InInvoiceRes } from "app/interfaces/invoices.interface";
import { InCostInvoiceRes } from "app/interfaces/cost_invoice.interface";
import { InPaymentInvoiceRes } from "app/interfaces/payment_invoices.interface";
import { InSupplierPaymentRes } from "app/interfaces/supplier_payment.interface";
import { Observable } from "rxjs";
import { environment } from "environments/environment";
import { InUploadedFileRes } from "app/interfaces/upload-file.interface";

@Injectable({
  providedIn: "root",
})
export class InvoiceService {
  constructor(public http: HttpClient) {}

  //INVOICES
  getInvoices(): Observable<InInvoiceRes> {
    return this.http.get<InInvoiceRes>(
      `${environment.url}payments/invoices/get`
    );
  }

  createInvoice(data: any): Observable<InInvoiceRes> {
    return this.http.post<InInvoiceRes>(
      `${environment.url}payments/invoices/create`,
      data
    );
  }

  updateInvoice(data: any): Observable<InInvoiceRes> {
    return this.http.patch<InInvoiceRes>(
      `${environment.url}payments/invoices/update/${data.id}`,
      data
    );
  }

  //COST_INVOICES

  getCostInvoices(): Observable<InCostInvoiceRes> {
    return this.http.get<InCostInvoiceRes>(
      `${environment.url}payments/cost-invoices/get`
    );
  }

  createCostInvoice(data: any): Observable<InCostInvoiceRes> {
    return this.http.post<InCostInvoiceRes>(
      `${environment.url}payments/cost-invoices/create`,
      data
    );
  }

  updateCostInvoice(data: any): Observable<InCostInvoiceRes> {
    return this.http.patch<InCostInvoiceRes>(
      `${environment.url}payments/cost-invoices/update/${data.id}`,
      data
    );
  }

  //PAYMENT_INVOICES

  getPaymentInvoices(): Observable<InPaymentInvoiceRes> {
    return this.http.get<InPaymentInvoiceRes>(
      `${environment.url}payments/payment-invoices/get`
    );
  }

  createPaymentInvoice(data: any): Observable<InPaymentInvoiceRes> {
    return this.http.post<InPaymentInvoiceRes>(
      `${environment.url}payments/payment-invoices/create`,
      data
    );
  }

  updatePaymentInvoice(data: any, id :number): Observable<InPaymentInvoiceRes> {
    return this.http.patch<InPaymentInvoiceRes>(`${environment.url}payments/payment-invoices/update/${id}`, data);
  }

  //SUPPLIER_PAYMENTS

  getSupplierPayments(): Observable<InSupplierPaymentRes> {
    return this.http.get<InSupplierPaymentRes>(
      `${environment.url}payments/supplier-payments/get`
    );
  }

  createSupplierPayment(data: any): Observable<InSupplierPaymentRes> {
    return this.http.post<InSupplierPaymentRes>(
      `${environment.url}payments/supplier-payments/create`,
      data
    );
  }

  updateSupplierPayment(data: any): Observable<InSupplierPaymentRes> {
    return this.http.patch<InSupplierPaymentRes>(
      `${environment.url}payments/supplier-payments/update/${data.id}`,
      data
    );
  }

  uploadFile(
    file: File,
    folder: string,
    filename: string
  ): Observable<any> {
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post<any>(
      `${environment.url}s3-files/${folder}/${filename}`,
      formData
    );
  }
}
