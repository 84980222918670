import { Pipe, PipeTransform } from "@angular/core";
import { GetTank } from "app/models/tank";

@Pipe({
  name: "filter",
  standalone: true,
})
export class FilterPipe implements PipeTransform {
  transform(tanks: GetTank[], productId: number): GetTank[] {
    const filtered = tanks?.filter((tank) => {
      return (
        tank?.products?.filter((product) => {
          return product?.id === productId;
        })?.length > 0
      );
    });
    return filtered;
  }
}

@Pipe({
  name: "filterPurchase",
  standalone: true,
})
export class FilterPurchasePipe implements PipeTransform {
  transform(tanks: GetTank[], productId: number): GetTank[] {
    const filtered = tanks?.filter((tank) => {
      return tank?.purchase_detail_product_id === productId;
    });
    return filtered;
  }
}
