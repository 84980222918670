import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CatalogServicesService } from 'app/apiServices/catalog-services.service';
import { FormValidatorService } from 'app/core/service/form-validator.service';
import { DataService } from 'app/services/data.service';

@Component({
  selector: 'app-views-form',
  templateUrl: './views-form.component.html',
  styleUrls: ['./views-form.component.css']
})
export class ViewsFormComponent implements OnInit{
  form: FormGroup
  dataSource:any
  selectedItems:any = []
  views:any [] = []

  rows: number

  validationMessages = {
    views: [{type: "required", message: "Seleccione las Vistas"}]
  }

  selectiedItems = []
  dropdownSettings = {}

  constructor( 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ViewsFormComponent>,
    private fb: FormBuilder,
    public formService: FormValidatorService,
    public apiService: CatalogServicesService
  ) {
    this.dataSource = data.data

    this.dropdownSettings = {
      idField: "id",
      textField: "name",
      noDataAvailablePlaceholderText: "No hay datos",
      selectAllText: "Seleccionar Todas",
      unSelectAllText: "Deseleccionar Todas",
      itemsShowLimit: 4,
      allowSearchFilter: true
    }

    this.selectedItems = [this.dataSource.views];
  }

  ngOnInit(): void {
    this.getData()
    this.form = this.createForm();
  }

  getData(){
    this.apiService.readViews().subscribe((r)=>{
      this.views = r.data
    })
  }

  onItemSelect(item: any) {}
  onRoleSelect(item: any) {}
  onSelectAll(items: any) {}

  createForm(){
    //console.log(this.dataSource.views.length)
    return this.fb.group({
      id: [this.dataSource.id],
      views: [this.dataSource.views]
    })
  }

  closeModal(){
    this.dialogRef.close()
  }

  modify() {
    var val = []
    if(this.form.valid){
      this.form.value.views.forEach(element => {
        val.push(element.id)
      });

      const obj = {
        role: this.dataSource.id,
        views: val
      }

      this.apiService.updateRoleView(obj).subscribe((r) => {
        this.closeModal()
      })
    }
  }

}
