import { Component, ViewChild, ElementRef, Inject, OnInit } from "@angular/core";
import { Form, FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { MatSnackBar } from "@angular/material/snack-bar";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { InvoiceService } from "app/apiServices/invoice.service";
import { SalesService } from "app/apiServices/sales.service";
import { NotificationsComponent } from "app/components/alerts/notifications/notifications.component";
import { FormValidatorService } from "app/core/service/form-validator.service";
import { InPaymentMethodRes } from "app/interfaces/payment.method";
import { PaymentMethod } from "app/models/paymentMethod";
import { DataService } from "app/services/data.service";
import { Tabs } from "flowbite";
import type { TabsOptions, TabsInterface, TabItem } from "flowbite";
import { Select, initTE } from "tw-elements";
import { ClientsService } from "app/apiServices/clients.service";
import { CostInvoices } from "app/models/cost_invoices";
import { Invoice } from "app/models/invoice";
@Component({
  selector: "app-create-supplier-credit-note",
  templateUrl: "./create-supplier-credit-note.component.html",
  styleUrls: ["./create-supplier-credit-note.component.css"],

})
export class CreateSupplierCreditNoteComponent implements OnInit{
  @ViewChild("paymentMethodId", { static: true })
  paymentMethodSelect: ElementRef;
  @ViewChild("paymentMethodId2", { static: true })
  paymentMethodSelect2: ElementRef;
  @ViewChild("ncType", { static: true })
  ncTypeSelect: ElementRef;




  options: TabsOptions = {
    defaultTabId: "client-data",
    activeClasses: "text-blue-600 hover:text-blue-600 border-blue-600",
    inactiveClasses:
      "text-gray-500 hover:text-gray-600 border-gray-100 hover:border-gray-300",
    onShow: () => {},
  };
  selectOptions = {
    selectFilter: true,
    selectNoResultText: "No se encontraron resultados",
    selectSearchPlaceholder: "Buscar...",
  };
  tabs: TabsInterface;
  date;

  paymentMethods = [];
  formPaymentMethods = [];
  rows = [];
  ColumnMode = ColumnMode;
  form: FormGroup;
  formSupplierPaymentInvoice : FormGroup;
  invoicesids = [];
  totalInvoices: number = 0;
  totalImputed: number = 0;

  supplierId: number;
  user: any;
  user_id: number;
  costInvoices : any[] = [];

  validationMessages = {
    user_id: [{ type: "required", message: "Ingresa el cliente" }],
    client_id: [{ type: "required", message: "Ingresa el cliente" }],
    payment_methods_id: [{ type: "required", message: "Ingresa la forma de pago" }],
    uso_cfdi_id: [{ type: "required", message: "Ingresa el uso de CFDI" }],
    payment: [{ type: "required", message: "Ingresa el monto abonado" }],
    comments: [{ type: "required", message: "Ingresa los comentarios" }],
    date: [{ type: "required", message: "Ingresa la fecha" }],
  };
  constructor(
    private dialogRef: MatDialogRef<CreateSupplierCreditNoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public formService: FormValidatorService,
    private salesService: SalesService,
    private invoiceService: InvoiceService,
    private dataService: DataService,
    private _snack: MatSnackBar,
    private clientsService: ClientsService
  ) {
    this.supplierId = data.supplierId;
    this.costInvoices = data.costInvoices;
    this.user = localStorage.getItem('user');
    this.user_id = JSON.parse(this.user).id;
    this.getPaymentMethods();
    this.form = this.createForm();
    this.costInvoices = this.costInvoices.map((invoice) => {
      let supplieriInvoicePayments = invoice.supplier_invoice_payments;
      let dueTotal = invoice.amount;
      if (supplieriInvoicePayments.length > 0) {
        let lastPayment = supplieriInvoicePayments[supplieriInvoicePayments.length - 1];
        if (lastPayment.due_total) {
          dueTotal = lastPayment.due_total;
        }
      }
      this.totalInvoices += dueTotal;
      return {dueTotal: dueTotal, ...invoice};
    });
    this.invoicesids = this.costInvoices.map((invoice) => invoice.id);
  }
  ngOnInit(): void {
    this.form.get('payment').disable();
    const folios = this.costInvoices.reduce((acc, invoice) => {
      const folios = invoice.cost_invoice_details.map(detail => detail.order_cost.order.folio);
      return acc.concat(folios);
    }, []).join(', ');
    this.form.get('comments').setValue(folios);
    let spInvoicePayment = this.form.get('supplier_invoice_payments') as FormArray;
    for(const invoice of this.costInvoices){
      const invoiceFormGroup = this.createInvoice(invoice);
      spInvoicePayment.push(invoiceFormGroup);
    }
  }

  ngAfterViewInit() {
    this.tabs = new Tabs(
      [
        {
          id: "data-fee",
          triggerEl: document.querySelector("#data-fee-tab"),
          targetEl: document.querySelector("#data-fee"),
        },
        {
          id: "data-transaction",
          triggerEl: document.querySelector("#data-transaction-tab"),
          targetEl: document.querySelector("#data-transaction"),
        },
      ],
      this.options
    );
    this.createSelects();
  }

  createSelects(): void {
    initTE({ Select });
    new Select(this.paymentMethodSelect.nativeElement, this.selectOptions);
    new Select(this.paymentMethodSelect2.nativeElement, this.selectOptions);
    new Select(this.ncTypeSelect.nativeElement, this.selectOptions);
  }

  /*createFormSupplier() : void {
    this.formSupplier = this.fb.group({
      factura: ['', Validators.required],
      amount : ['', Validators.required],
    });

  }*/

  getPaymentMethods(): void {
    this.salesService
      .getPaymentMethods()
      .subscribe((res: InPaymentMethodRes) => {
        if (res.status === "Éxito") {
          this.paymentMethods = res.data.filter((p) => p.type_invoice === true);
        }
      });
      this.clientsService.getFormPaymentMethods().subscribe((res) => {
        this.formPaymentMethods = res.data;
        
      });
  }

  onImputedChange(event: Event, row: any): void {
    let value = (event.target as HTMLInputElement).value;
    value = value.replace("$", "").replace(",", "").replace(/\s/g, "");
    const numValue = Number(value);
    
    const invoicePayments = this.form.get('supplier_invoice_payments') as FormArray;

    const invoiceFormGroup = invoicePayments.at(this.costInvoices.indexOf(row)) as FormGroup;
    const due_before = invoiceFormGroup.get('due_before').value;
  
    if (!isNaN(numValue) && numValue > 0 && numValue <= due_before) {
      if (row.previousImputed) {
        this.totalImputed -= row.previousImputed;
      }
      this.totalImputed += numValue;
      row.previousImputed = numValue;
      invoiceFormGroup.get('due_total').setValue(due_before - numValue);
      invoiceFormGroup.get('payment').setValue(numValue);
      this.form.patchValue({
        payment: this.totalImputed
      });
    } else {
      if (row.previousImputed) {
        this.totalImputed -= row.previousImputed;
        row.previousImputed = 0;
      }
      row.imputar = 0;
      this.openSnack("snack-error", "El monto a imputar debe ser mayor a 0 y menor o igual al saldo");
    }
  }


  getSupplier(costInvoice: CostInvoices) {
    const paymentsInvoice =
      costInvoice.supplier_invoice_payments.length > 0
        ? costInvoice.supplier_invoice_payments[0]
        : null;

    return (
      paymentsInvoice?.supplier_payment.supplier?.company?.legal_name || ""
    );
  }

  deleteInvoice(row: any): void {
    const index = this.costInvoices.findIndex(
      (invoice) => invoice.id === row.id
    );
    if (index !== -1) {
      this.totalInvoices -= this.costInvoices[index]?.amount;
      this.totalImputed -= row.imputar;
      this.costInvoices.splice(index, 1);
    }
  }

  createForm(): FormGroup {
    return this.fb.group({
      user_id: [this.user_id, [Validators.required]],
      supplier_id: [this.supplierId, [Validators.required]],
      type: [, [Validators.required]],
      payment_method_id: [, [Validators.required]],
      forma_pago_id: [, [Validators.required]],
      payment: [, [Validators.required]],
      date: [, [Validators.required]],
      comments: [, [Validators.required]],
      supplier_invoice_payments: this.fb.array([]),
      supplier_payment_receipts: this.fb.array([]),
    });
  }
  createInvoice(invoice : Invoice): FormGroup {
    return this.fb.group({
      supplier_invoice_payment_id: [null],
      cost_invoice_id: [invoice.id, [Validators.required]],
      number: [2, [Validators.required]],
      payment: [invoice.total, [Validators.required]],
      due_before: [invoice.dueTotal, [Validators.required]],
      due_total: [invoice.total, [Validators.required]],
      date: [, [Validators.required]],
    });
  }

  createSupplierPaymentReceipt(costInvoice : CostInvoices): FormGroup {
    return this.fb.group({
      spr_id: [null],
      cost_invoice_id: [costInvoice.id, [Validators.required]],
      url: ['', [Validators.required]],
      name: ['', [Validators.required]],
      folder: ['', [Validators.required]],
      extension: ['', [Validators.required]],
      active: [true, [Validators.required]],
    });
  }

  onPaymentMethodType(paymentMethodId: number) {}

  onFileSelected(event: any): void {
    if (event.target.files.length >= 0) {
      const file: File = event.target.files[event.target.files.length - 1];
      this.uploadFile(file, "SupplierPaymentReceipts", file.name);
      }
    }
  
    
  
    uploadFile(file: File, folder: string, filename: string): void {
      const supplierPaymentReceipts = this.form.get('supplier_payment_receipts') as FormArray;
      if (supplierPaymentReceipts.length < this.costInvoices.length || !supplierPaymentReceipts.length) {
        for (const invoice of this.costInvoices.slice(supplierPaymentReceipts.length)){
          const supplierPaymentReceipt = this.createSupplierPaymentReceipt(invoice);
          supplierPaymentReceipts.push(supplierPaymentReceipt);
        }
      }
      this.invoiceService.uploadFile(file, folder, filename).subscribe({
        next: (res) => {
         const supplierPaymentReceipts = this.form.get('supplier_payment_receipts') as FormArray;
         for(let i = 0; i < supplierPaymentReceipts.length; i++){
          const supplierPaymentReceipt = supplierPaymentReceipts.at(i) as FormGroup;
          supplierPaymentReceipt.get('cost_invoice_id').setValue(this.costInvoices[i].id);
          supplierPaymentReceipt.get('folder').setValue(res.data.folder);
          supplierPaymentReceipt.get('url').setValue(res.data.url);
          supplierPaymentReceipt.get('name').setValue(res.data.filename);
          supplierPaymentReceipt.get('extension').setValue(res.data.extension);
         }
        },
        error: (err) => {
          this.openSnack("snack-error", err.error.message);
        },
      });
    }

  changeDate(date: string): void {
    this.form.controls.date.patchValue(date);
    this.date = date;
    this.form.updateValueAndValidity();
    const invoicePayments = this.form.get('supplier_invoice_payments') as FormArray;
    for (let i = 0; i < invoicePayments.length; i++) {
      const invoiceFormGroup = invoicePayments.at(i) as FormGroup;
      invoiceFormGroup.get('date').patchValue(date);
    }
  }
  

  Createpayment(): void {
    this.form.get('payment').enable();
    if (this.form.valid) {
      this.invoiceService.createSupplierPayment(this.form.value).subscribe({
        next: (res) => {
          this.openSnack("snack-success", res.message);
          this.dialogRef.close(true);
        },
        error: (err) => {
          this.openSnack("snack-error", err.error.message);
        },
      });
    } else {
      this.openSnack("snack-error", "Completa los campos requeridos");
      this.form.get('payment').disable();
    }
  }

  onLeadingZero(event: Event): void {
    const num = (event.target as HTMLInputElement).value
      .replace("$", "")
      .replace(",", "")
      .replace(" ", "");
    const dec = num.split(".")[1];
    const len = dec && dec.length > 2 ? dec.length : 2;
    (event.target as HTMLInputElement).value = `$ ${Number(num).toFixed(len)}`;
  }
  goToDataFee(): void {
    this.tabs.show("data-fee");
  }

  goToDataTransaction(): void {
    this.tabs.show("data-transaction");
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
      verticalPosition: "top",
    });
  }
}
