import { Component, ElementRef, Inject, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { FormValidatorService } from "app/core/service/form-validator.service";
import { Company } from "app/models/company";
import { TabsOptions, TabsInterface, Tabs } from 'flowbite';
import { Select } from "tw-elements";
import { SalesService } from 'app/apiServices/sales.service';
import { Invoice } from 'app/models/invoice';
import { ClientsService } from 'app/apiServices/clients.service';
import { CFID } from 'app/models/cfid';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationsComponent } from 'app/components/alerts/notifications/notifications.component';
import { InvoiceService } from 'app/apiServices/invoice.service';

@Component({
  selector: 'app-create-client-credit-note-worelation',
  templateUrl: './create-client-credit-note-worelation.component.html',
  styleUrls: ['./create-client-credit-note-worelation.component.css']
})
export class CreateClientCreditNoteWorelationComponent implements OnInit {
  @ViewChild("paymentMethodId", { static: true })
  paymentMethodSelect: ElementRef;
  @ViewChild("CFDI_Id", { static: true })
  cfdiselect: ElementRef;
  @ViewChild("type", { static: true })
  typeSelect: ElementRef;
  @ViewChild("paymentMethodId2", { static: true })
  paymentMethodSelect2: ElementRef;

  options: TabsOptions = {
    defaultTabId: "data-transaction",
    activeClasses: "text-blue-600 hover:text-blue-600 border-blue-600",
    inactiveClasses:
      "text-gray-500 hover:text-gray-600 border-gray-100 hover:border-gray-300",
    onShow: () => {},
  };
  selectOptions = {
    selectFilter: true,
    selectNoResultText: "No se encontraron resultados",
    selectSearchPlaceholder: "Buscar...",
  };
  tabs: TabsInterface;
  date;
  paymentMethods = [];
  formPaymentMethods = [];
  rows = [];
  ColumnMode = ColumnMode;
  form: FormGroup;
  cfdi : CFID[] = [];

  type;
  client_id: number;
  user: any;
  user_id: number;
  client: Company;

  validationMessages = {
    user_id: [{ type: "required", message: "Ingresa el cliente" }],
    client_id: [{ type: "required", message: "Ingresa el cliente" }],
    payment_methods_id: [{ type: "required", message: "Ingresa la forma de pago" }],
    uso_cfdi_id: [{ type: "required", message: "Ingresa el uso de CFDI" }],
    payment: [{ type: "required", message: "Ingresa el monto abonado" }],
    comments: [{ type: "required", message: "Ingresa los comentarios" }],
    type : [{ type: "required", message: "Ingresa el tipo" }],
    date: [{ type: "required", message: "Ingresa la fecha" }],
  };

  constructor(
    private fb: FormBuilder,
    private salesService: SalesService,
    private clientsService: ClientsService,
    private invoiceService: InvoiceService,
    public formService: FormValidatorService,
    private _snack: MatSnackBar,
    private dialogRef: MatDialogRef<CreateClientCreditNoteWorelationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.client_id = this.data.client_id;
    this.user = localStorage.getItem('user');
    this.user_id = JSON.parse(this.user).id;
    this.form = this.createForm();
  }

  ngAfterViewInit() {
    this.tabs = new Tabs(
      [
        {
          id: "data-transaction",
          triggerEl: document.querySelector("#data-transaction-tab"),
          targetEl: document.querySelector("#data-transaction"),
        },
      ],
      this.options
    );
    new Select(this.paymentMethodSelect.nativeElement, this.selectOptions);
    new Select(this.cfdiselect.nativeElement, this.selectOptions);
    new Select(this.typeSelect.nativeElement, this.selectOptions);
    new Select(this.paymentMethodSelect2.nativeElement, this.selectOptions);
  }

  ngOnInit(): void {
    this.getPaymentMethodsandCFDI();
  }


  createForm(): FormGroup {
    return this.fb.group({
      user_id: [this.user_id, [Validators.required]],
      client_id: [this.client_id, [Validators.required]],
      type: [, [Validators.required]],
      payment_method_id: [, [Validators.required]],
      forma_pago_id: [, [Validators.required]],
      payment: [, [Validators.required]],
      date: [, [Validators.required]],
      uso_cfdi_id: [, [Validators.required]],
      comments: [, [Validators.required]],
      invoice_payments: this.fb.array([]),
    });
  }
  getPaymentMethodsandCFDI() {
    this.salesService.getPaymentMethods().subscribe((res) => {
      this.paymentMethods = res.data.filter((method) => method.type_invoice === true);
    });
    this.clientsService.getCFDI().subscribe((res) => {
      this.cfdi = res.data;
      this.cfdi.sort((a, b) => a.id - b.id);
    });
    this.clientsService.getFormPaymentMethods().subscribe((res) => {
      this.formPaymentMethods = res.data;
    });
  }
  changeDate(date: string): void {
    this.form.controls.date.patchValue(date);
    this.date = date;
    this.form.updateValueAndValidity();
  }


  onLeadingZero(event: Event): void {
    const num = (event.target as HTMLInputElement).value
      .replace("$", "")
      .replace(",", "")
      .replace(" ", "");
    const dec = num.split(".")[1];
    const len = dec && dec.length > 2 ? dec.length : 2;
    (event.target as HTMLInputElement).value = `$ ${Number(num).toFixed(len)}`;
  }
  createPaymentInvoice(): void {
    console.log(this.form.value);
    if (this.form.valid) {
      this.invoiceService.createPaymentInvoice(this.form.value).subscribe({
        next: (res) => {
          this.openSnack("snack-success", 'Nota de credito sin relacion creada con exito');
          this.dialogRef.close();
        },
        error: (err) => {
          this.openSnack("snack-error", err.error.message);
        },
      });  
    }else{
      this.openSnack("snack-error", "Revise bien el formulario");
    }
    
  }
  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
      verticalPosition: "top",
    });
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}

