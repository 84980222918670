<div class="container bg-white mx-auto px-4 h-full py-4 overflow-hidden">
  <div class="flex justify-between rounded-t mb-0 px-4 py-3 border-0 pt-6 bg-white">
    <div class="flex">
      <div class="text-center">
        <h3 class="font-semibold text-lg">
          Formulario para relacion Roles y Vistas
        </h3>
      </div>
    </div>
  </div>

  <form class="mx-4 my-6 bg-white" [formGroup]="form">
    <div class="w-full mb-3">
      <ng-multiselect-dropdown name="role_id" formControlName="role_id" class="w-2/3 z-70"
        [placeholder]="'Selecciona un rol'" [settings]="dropdownRoleSettings" maxHeight="10" [data]="array0" [disabled]="disableRole"
        noDataLabel="No hay datos" (onSelect)="onRoleSelect($event)">
      </ng-multiselect-dropdown>

      <div *ngFor="let validation of validationMessages.role_id">
        <span *ngIf="formService.field(form, validation.type, 'role_id')" class="mt-2 text-sm text-red-500">
          {{ validation.message }}
        </span>
      </div>
    </div>

    <div class="w-full mb-3">
      <ng-multiselect-dropdown name="view_id" formControlName="view_id" class="w-2/3 z-70"
        [placeholder]="'Selecciona una vista'" [settings]="dropdownSettings" maxHeight="10" [data]="array1"
        noDataLabel="No hay datos" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
      </ng-multiselect-dropdown>

      <div *ngFor="let validation of validationMessages.view_id">
        <span *ngIf="formService.field(form, validation.type, 'view_id')" class="mt-2 text-sm text-red-500">
          {{ validation.message }}
        </span>
      </div>
    </div>
  </form>

  <div class="flex items-center justify-end mt-16">
    <button
      class="bg-slate-500 text-white active:bg-slate-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      (click)="closeModal()">
      Cancelar
    </button>
    <button
      class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      [ngClass]="{ 'disabled:opacity-25': form.invalid }" [disabled]="form.invalid" (click)="save()">
      {{ edit ? 'Actualizar' : 'Agregar' }}
    </button>
  </div>
</div>