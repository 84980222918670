import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.css"],
})
export class AlertComponent {
  body = "";
  showBtnClose = false;
  btnAcceptTxt = "Aceptar";
  btnCancelTxt = "Cancelar";
  isConfirm = false;

  constructor(
    private dialogRef: MatDialogRef<AlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.body) {
      this.body = data.body;
    }

    if (data.showBtnClose) {
      this.showBtnClose = data.showBtnClose;
    }

    if (data.btnAcceptTxt) {
      this.btnAcceptTxt = data.btnAcceptTxt;
    }

    if (data.btnCancelTxt) {
      this.btnCancelTxt = data.btnCancelTxt;
    }

    if (data.isConfirm) {
      this.isConfirm = data.isConfirm;
    }
  }

  closeModal(confirm: boolean): void {
    if (this.isConfirm) {
      this.dialogRef.close(confirm);
    } else {
      this.dialogRef.close();
    }
  }
}
