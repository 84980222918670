import { Component } from '@angular/core';

@Component({
  selector: 'app-loader-list',
  templateUrl: './loader-list.component.html',
  styleUrls: ['./loader-list.component.css']
})
export class LoaderListComponent {

}
