<div class="bg-white py-4 h-full w-full overflow-hidden">
  <!-- Header Container -->
  <div class="flex">
    <!-- Titulo Modal -->
    <div class="w-11/12 justify-between rounded-t mb-0 pb-8 border-0 bg-white">
      <div class="text-start pl-2">
        <h3 class="font-semibold text-lg">Evidencias</h3>
      </div>
    </div>

    <!-- Boton cerrar Modal -->
    <div class="w-1/12 justify-end mb-0 pb-8">
      <button
        class="text-sky-600 cursor-pointer hover:text-sky-700 text-2xl pl-12 ease-linear transition-all duration-150"
        (click)="this.closeModal()"
      >
        <i class="fas fa-times-circle"></i>
      </button>
    </div>
  </div>

  <!-- Contenidos -->
  <div class="overflow-y-auto h-full w-full pl-12">
    <div class="h-48 overflow-y-auto">
      <div class="pt-10">
        <div *ngFor="let attach of attachments" class="pt-4 w-11/12">
          <button
            class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 w-full"
            (click)="gotoUrl(attach.url)"
          >
            {{ attach.name + attach.extension }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Buttons -->
  <div
    class="flex items-center justify-end absolute bottom-0 right-0 pb-3 pr-3 pt-3 w-full bg-white"
  >
    <button
      class="bg-slate-500 text-white active:bg-slate-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      (click)="this.closeModal()"
    >
      Cerrar
    </button>
  </div>
</div>
