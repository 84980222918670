<div class="bg-white px-4 w-full h-full py-4">
  <div class="justify-between rounded-t mb-0 px-4 py-3 border-0 pt-6 bg-white">
    <div class="text-center">
      <h3 class="font-semibold text-lg">
        {{ dialogTitle }}
      </h3>
    </div>
  </div>
  <div
    *ngIf="!isFullMix"
    class="mb-4 border-b border-gray-200 dark:border-gray-700"
  >
    <ul
      class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400"
      id="tab"
      role="tablist"
    >
      <li class="mr-2" role="presentation">
        <button
          disabled
          class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
          id="general-data-tab"
          type="button"
          role="tab"
          aria-controls="general-data"
          aria-selected="false"
        >
          Datos generales
        </button>
      </li>
      <li class="mr-2" role="presentation">
        <button
          disabled
          class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
          id="composition-tab"
          type="button"
          role="tab"
          aria-controls="composition"
          aria-selected="false"
        >
          Composición
        </button>
      </li>
    </ul>
  </div>
  <div id="tabContentProduct">
    <div id="general-data" role="tabpanel" aria-labelledby="general-data-tab">
      <form class="mx-4 my-6 bg-white" [formGroup]="form">
        <!-- first row of data -->
        <div class="flex flex-col md:flex-row">
          <div class="w-full md:w-1/2">
            <div class="container md:px-2">
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="name"
              >
                Nombre
              </label>
              <input
                type="text"
                name="name"
                formControlName="name"
                [ngClass]="{
                  'focus:border-red-500': form.controls.name.invalid,
                  'focus:ring-red-500': form.controls.name.invalid
                }"
                class="border-gray-300 px-3 py-3 placeholder-slate-300 border-1 text-slate-600 bg-white rounded text-sm focus:ring-1-1 focus:outline-none w-full ease-linear transition-all duration-150"
              />
              <div *ngFor="let validation of validationMessages.name">
                <span
                  *ngIf="formService.field(form, validation.type, 'name')"
                  class="mt-2 text-sm text-red-500"
                >
                  {{ validation.message }}
                </span>
              </div>
            </div>
          </div>

          <div class="w-full md:w-1/2">
            <div class="md:px-2">
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="code"
              >
                Código
              </label>
              <input
                type="text"
                name="code"
                formControlName="code"
                [ngClass]="{
                  'focus:border-red-500': form.controls.code.invalid,
                  'focus:ring-red-500': form.controls.code.invalid
                }"
                class="border-gray-300 px-3 py-3 placeholder-slate-300 border-1 text-slate-600 bg-white rounded text-sm focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
              />
              <div *ngFor="let validation of validationMessages.code">
                <span
                  *ngIf="formService.field(form, validation.type, 'code')"
                  class="mt-2 text-sm text-red-500"
                >
                  {{ validation.message }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col md:flex-row mt-8">
          <div class="w-full md:w-2/3 ">
            <div class="md:px-2">
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="description"
              >
                Descripción
              </label>
              <textarea
                name="description"
                formControlName="description"
                rows="1"
                [ngClass]="{
                  'focus:border-red-500': form.controls.description.invalid,
                  'focus:ring-red-500': form.controls.description.invalid
                }"
                class="border-gray-300 px-3 py-3 resize-none placeholder-slate-300 border-1 text-slate-600 bg-white rounded text-sm focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
              >
              </textarea>
              <div *ngFor="let validation of validationMessages.description">
                <span
                  *ngIf="
                    formService.field(form, validation.type, 'description')
                  "
                  class="mt-2 text-sm text-red-500"
                >
                  {{ validation.message }}
                </span>
              </div>
            </div>
          </div>

          <div class="w-full md:w-1/4">
            <label
              class="block uppercase text-slate-600 text-xs font-bold mb-2"
              htmlFor="iva_retain"
            >
              Código SAT
            </label>
            <input
              type="text"
              name="clave_sat"
              formControlName="clave_sat"
              [ngClass]="{
                'focus:border-red-500': form.controls.clave_sat.invalid,
                'focus:ring-red-500': form.controls.clave_sat.invalid
              }"
              class="border-gray-300 px-3 py-3 placeholder-slate-300 border-1 text-slate-600 bg-white rounded text-sm focus:ring-1-1 focus:outline-none w-full ease-linear transition-all duration-150"
            />
          </div>

          <div class="w-full md:w-1/4">
            <div class="container md:px-2">
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="unit_key"
              >
                Clave de unidad
              </label>
              <input
                type="text"
                name="unit_key"
                formControlName="unit_key"
                [ngClass]="{
                  'focus:border-red-500': form.controls.unit_key.invalid,
                  'focus:ring-red-500': form.controls.unit_key.invalid
                }"
                class="border-gray-300 px-3 py-3 placeholder-slate-300 border-1 text-slate-600 bg-white rounded text-sm focus:ring-1-1 focus:outline-none w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>

          <div class="w-1/4">
            <label
              class="px-2 block uppercase text-slate-600 text-xs font-bold mb-2"
              htmlFor="full_mix"
            >
              Mezcla completa
            </label>
            <div class="px-2 my-4">
              <input
                type="checkbox"
                name="full_mix"
                formControlName="full_mix"
                [ngClass]="{
                  'focus:border-red-500': form.controls.full_mix.invalid,
                  'focus:ring-red-500': form.controls.full_mix.invalid
                }"
                class="border-1 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded-full border-gray-300 text-sm focus:outline-none focus:ring-1 ease-linear transition-all duration-150"
                (click)="toggleFullMix($event)"
              />
              <div *ngFor="let validation of validationMessages.full_mix">
                <span
                  *ngIf="formService.field(form, validation.type, 'full_mix')"
                  class="mt-2 text-sm text-red-500"
                >
                  {{ validation.message }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-row mt-8">
          <div class="w-24">
            <label
              class="px-2 block uppercase text-slate-600 text-xs font-bold mb-2"
              htmlFor="sellable"
            >
              Vendible
            </label>
            <div class="px-2 my-4">
              <input
                type="checkbox"
                name="sellable"
                formControlName="sellable"
                [ngClass]="{
                  'focus:border-red-500': form.controls.sellable.invalid,
                  'focus:ring-red-500': form.controls.sellable.invalid
                }"
                class="border-1 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded-full border-gray-300 text-sm focus:border focus:outline-none focus:ring-1 ease-linear transition-all duration-150"
              />
            </div>
          </div>

          <div class="w-24">
            <label
              class="px-2 block uppercase text-slate-600 text-xs font-bold mb-2"
              htmlFor="ieps"
            >
              IEPS
            </label>
            <div class="px-2 my-4">
              <input
                type="checkbox"
                name="ieps"
                formControlName="ieps"
                [ngClass]="{
                  'focus:border-red-500': form.controls.ieps.invalid,
                  'focus:ring-red-500': form.controls.ieps.invalid
                }"
                class="border-1 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded-full border-gray-300 text-sm focus:border focus:outline-none focus:ring-1 ease-linear transition-all duration-150"
                (click)="toggleIeps($event)"
              />
            </div>
          </div>

          <div class="w-36" *ngIf="form.controls.ieps.value">
            <div class="px-2" >
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="ieps_cost"
              >
                Costo de IEPS (lts)
              </label>
              <input
                type="text"
                name="ieps_cost"
                formControlName="ieps_cost"
                prefix="$ "
                mask="separator.6"
                [ngClass]="{
                'focus:border-red-500': form.controls.ieps_cost.invalid,
                'focus:ring-red-500': form.controls.ieps_cost.invalid,
              }"
                (change)="onLeadingZero($event)"
                class="border-1 px-3 py-3 placeholder-slate-300 text-slate-600 rounded border-gray-300 text-sm focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>

          <div class="w-36" *ngIf="!edit">
            <div class="px-2">
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="operative_cost"
              >
                Costo operativo
              </label>
              <input
                type="text"
                name="operative_cost"
                formControlName="operative_cost"
                prefix="$ "
                mask="separator.6"
                [ngClass]="{
                'focus:border-red-500': form.controls.operative_cost.invalid,
                'focus:ring-red-500': form.controls.operative_cost.invalid,
              }"
                (change)="onLeadingZero($event)"
                class="border-1 px-3 py-3 placeholder-slate-300 text-slate-600 rounded border-gray-300 text-sm focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>
        </div>
      </form>

      <div class="flex items-center justify-end">
        <button
          class="bg-slate-500 text-white active:bg-slate-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          (click)="closeModal()"
        >
          Cancelar
        </button>
        <button
          *ngIf="isFullMix"
          class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          [ngClass]="{ 'disabled:opacity-25': form.invalid }"
          [disabled]="form.invalid"
          (click)="save()"
        >
          {{ edit ? "Actualizar" : "Agregar" }}
        </button>

        <button
          *ngIf="!isFullMix"
          class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          [ngClass]="{ 'disabled:opacity-25': form.invalid }"
          [disabled]="form.invalid"
          (click)="next()"
        >
          Siguiente
        </button>
      </div>
    </div>

    <div
      class="hidden"
      id="composition"
      role="tabpanel"
      aria-labelledby="composition-tab"
    >
      <app-compose-product
        (onDrop)="drop($event)"
        [edit]="edit"
        [productData]="dataSource.compound_products"
        [productID]="dataSource.id"
      ></app-compose-product>
      <div class="flex items-center justify-end pt-4">
        <button
          class="bg-slate-500 text-white active:bg-slate-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          (click)="back()"
        >
          Regresar
        </button>
        <button
          class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          [ngClass]="{
            'disabled:opacity-25': form.invalid || productMixes.length === 0,
            'opacity-25': emptyPercentages
          }"
          [disabled]="
            form.invalid || productMixes.length === 0 || emptyPercentages
          "
          (click)="save()"
        >
          {{ edit ? "Actualizar" : "Agregar" }}
        </button>
      </div>
    </div>
  </div>
</div>
