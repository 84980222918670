import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClientsService } from 'app/apiServices/clients.service';
import { AddressComponentComponent } from 'app/components/address-component/address-component.component';
import { NotificationsComponent } from 'app/components/alerts/notifications/notifications.component';
import { FormValidatorService } from 'app/core/service/form-validator.service';
import { Route } from 'app/models/route';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent {
  form: FormGroup;
  dataSource: Route;
  edit = false;
  dialogTitle: string;

  dataFrom: any;
  dataFromTo: any;
  item: any;

  validationMessages = {
    from_locality: [{ type: "required", message: "Necesitamos una localidad de incio" }],
    to_locality: [{ type: "required", message: "Necesitamos una localidad de destino" }],
    name: [{ type: "required", message: "Ingrese el nombre de la Ruta" }],
    standard_cost: [{ type: "required", message: "Ingrese el costo" }]
  };

  constructor(
    private dialogRef: MatDialogRef<FormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any = undefined,
    private fb: FormBuilder,
    public formService: FormValidatorService,
    public dialog: MatDialog,
    private clientsSrc: ClientsService,
    private _snack: MatSnackBar
  ) {
    this.data = data;

    if (data.action === "edit") {
      this.dialogTitle = "Editar Ruta";
      this.dataSource = data.data;
      this.item = data.data;
      this.edit = true;
    } else {
      this.dialogTitle = "Agregar Ruta";
      this.dataSource = new Route({});
    }
    this.form = this.createForm();
    this.setForm()
  }

  createForm(): FormGroup {

    return this.fb.group({
      id: [this.dataSource.id],
      from_locality: ['', [Validators.required]],
      to_locality: ['', [Validators.required]],
      name: [this.dataSource.name, [Validators.required]],
      standard_cost: [this.leadingZero(this.dataSource?.standard_cost?.toString()), [Validators.required]],

      stateFrom: [{ value: '', disabled: true }, []],
      municipalityFrom: [{ value: '', disabled: true }, []],
      stateFromTo: [{ value: '', disabled: true }, []],
      municipalityFromTo: [{ value: '', disabled: true }, []],

    });
  }

  save(): void {
    if (this.form.valid) {
      this.dataSource = this.form.value;
      this.dataSource.standard_cost = Number(parseFloat(this.dataSource?.standard_cost.toString()));
      this.storeUpdate(this.edit ? "update" : "store");
    } else {
      this.formService.allFields(this.form);
    }
  }

  storeUpdate(method: string): void {
    const data = {
      method,
      data: this.dataSource,
      status: 200,
    };

    let obj = {
      id: this.item ? this.item.id : null,
      from_locality_id: this.dataFrom ? this.dataFrom.locality.id : this.item.from_locality.id,
      to_locality_id: this.dataFromTo ? this.dataFromTo.locality.id : this.item.to_locality.id,
      name: this.form.controls['name'].value,
      standard_cost: this.form.controls['standard_cost'].value,
    }

    if (method === "store") {

      this.clientsSrc.createRoutes(obj).subscribe({
        next: (v) => {
          this.openSnack('snack-success', v.message)
        },
        error: (e) => {
          this.openSnack('snack-error', e.error.message)
        }
      })

      this.dialogRef.close(data);
    } else {

      this.clientsSrc.updateRoutes(obj).subscribe({
        next: (v) => {
          this.openSnack('snack-success', v.message)
        },
        error: (e) => {
          this.openSnack('snack-error', e.error.message)
        }
      })
      this.dialogRef.close(data);
    }
  }

  onLeadingZero(event: Event): void {
    const num = (event.target as HTMLInputElement).value
      .replace("$", "")
      .replace(",", "")
      .replace(" ", "");
    const dec = num.split(".")[1];
    const len = dec && dec.length > 2 ? dec.length : 2;
    (event.target as HTMLInputElement).value = `$ ${Number(num).toFixed(len)}`;
  }

  leadingZero(num: string): string {
    const dec = num.split(".")[1];

    if (!dec && num == "0") return "0.00";
    else if (!dec && num != "0") return `${num}.00`;
    
    const len = dec && dec.length > 2 ? dec.length : 2;
    const fixed = Number(num).toFixed(len);
    return fixed;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  openDialog(fromLocality: boolean) {
    const dialogRef = this.dialog.open(AddressComponentComponent, {
      panelClass: ['dialog-responsive'],
    });

    dialogRef.afterClosed().subscribe((result) => {

      fromLocality ? this.dataFrom = result.data : this.dataFromTo = result.data

      this.setForm()

    });
  }

  setForm(): void {

    if (this.data.action == 'edit') {

      this.form.controls['name'].setValue(this.item.name)
      this.form.controls['standard_cost'].setValue(this.item.standard_cost)

      this.form.controls['from_locality'].setValue(this.item.from_locality.name)
      this.form.controls['municipalityFrom'].setValue(this.item.from_locality.municipality.name)
      this.form.controls['stateFrom'].setValue(this.item.from_locality.state.name)

      this.form.controls['to_locality'].setValue(this.item.to_locality.name)
      this.form.controls['municipalityFromTo'].setValue(this.item.to_locality.municipality.name)
      this.form.controls['stateFromTo'].setValue(this.item.to_locality.state.name)

    } else {
      if (this.dataFrom) {
        this.form.controls['from_locality'].setValue(this.dataFrom.locality.name)
        this.form.controls['municipalityFrom'].setValue(this.dataFrom.municipality.name)
        this.form.controls['stateFrom'].setValue(this.dataFrom.state.name)
      }

      if (this.dataFromTo) {
        this.form.controls['to_locality'].setValue(this.dataFromTo.locality.name)
        this.form.controls['municipalityFromTo'].setValue(this.dataFromTo.municipality.name)
        this.form.controls['stateFromTo'].setValue(this.dataFromTo.state.name)
      }
    }
  }

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000
    })
  }
}
