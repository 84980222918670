import { Locality } from "./address";

export class Route {
  id?: number;
  active?: boolean;
  from_locality_id?: number;
  from_locality?: Locality;
  to_locality_id?: number;
  to_locality?: Locality;
  name?: string;
  standard_cost?: number;

  constructor(route: Route) {
    this.id = route.id || undefined;
    this.active = route.active || undefined;
    this.from_locality_id = route.from_locality_id || undefined;
    this.from_locality = route.from_locality || undefined;
    this.to_locality_id = route.to_locality_id || undefined;
    this.to_locality = route.to_locality || undefined;
    this.name = route.name || '';
    this.standard_cost = route.standard_cost || 0;
  }
}