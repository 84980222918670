import { Component, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";
import { FormComponent } from "./form/form.component";
import { InventoryService } from "app/apiServices/inventory.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-auth-prices",
  templateUrl: "./auth-prices.component.html",
  styleUrls: ["./auth-prices.component.css"],
})
export class AuthPricesComponent {
  rows: any[] = [];

  temp: any[] = [];
  showAlert = false;
  addAuthorizedPrices: boolean = true;
  columnMapValues = [
    ["fecha", "created_at"],
    ["usuario", "user"],
    ["productos", "productsDate"],
  ] as const;
  columnMap: Map<string, string> = new Map(this.columnMapValues);
  loading: boolean = false;

  @ViewChild(DatatableComponent) table: DatatableComponent;

  ColumnMode = ColumnMode;
  constructor(
    public dialog: MatDialog,
    private inventorySvc: InventoryService,
    public _snack: MatSnackBar
  ) {}

  ngOnInit() {
    this.getData();
  }

  updateFilter(event: Event) {}

  openDialog(data: any, action: string) {
    if (this.rows.length == 0) {
      this.openSnack("No hay datos", "snack-error");
      return;
    }

    if (action === "store") {
      data = this.rows[0];
    }

    const dialogRef = this.dialog.open(FormComponent, {
      width: "94vw",
      maxWidth: "94vw",
      minHeight: "h-64",
      maxHeight: "90vh",
      data: {
        action,
        data,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.rows = [];
      this.getData();
    });
  }

  closeAlert(event: boolean) {
    this.showAlert = event;
  }

  onSort(event: any): void {
    const sort = event.sorts[0];
    const column = this.columnMap.get(sort.prop);

    if (column) {
      this.rows.sort((a, b) => {
        if (typeof a[column] === "number" || typeof a[column] === "boolean") {
          if (sort.dir === "asc") return a[column] - b[column];
          else return b[column] - a[column];
        } else if (typeof a[column] === "string") {
          if (column == "created_at") {
            const dateA = a[column].split(" ")[0];
            const dateB = b[column].split(" ")[0];
            if (sort.dir === "asc")
              return new Date(dateA).getTime() - new Date(dateB).getTime();
            else return new Date(dateB).getTime() - new Date(dateA).getTime();
          } else {
            return (
              a[column].localeCompare(b[column]) *
              (sort.dir === "desc" ? -1 : 1)
            );
          }
        } else if (typeof a[column] === "object") {
          if (sort.dir === "asc") return a[column].length - b[column].length;
          else return b[column].length - a[column].length;
        }
      });

      this.rows = [...this.rows];
    }
  }

  getData(): void {
    this.loading = true;
    this.inventorySvc.getAuthorizedPrice().subscribe({
      next: (v) => {
        this.loading = false;
        this.temp = v.data;
        this.temp.forEach((item) => {
          const fechaSinHora = item.created_at.split(" ")[0];
          item.date = fechaSinHora;
          item.userString = `${item.user.name} ${item.user.lastname} - ${item.user.email}`;
        });

        const uniqueDates = Array.from(
          new Set(this.temp.map((item) => item.date))
        );

        const resultArray = [];

        uniqueDates.forEach((date) => {
          let obj = {
            user: "",
            created_at: date,
            productsDate: [],
          };

          const itemsForDate = this.temp.filter((item) => item.date === date);
          obj.user = itemsForDate[0].userString;

          itemsForDate.forEach((item) => {
            if (item.product.sellable) {
              const objPush = {
                authorized_price: item.authorized_price,
                profit_margin: item.profit_margin,
                recommended_price: item.recommended_price,
                list_price: item.product.list_price,
                supply_price:
                  item.supply_price_log.supply_price ??
                  item.product.supply_price,
                physical_cost: item.product.physical_cost,
                operative_cost:
                  item.operative_cost ?? item.product.operative_cost,
                name: item.product.name,
                id: item.product.id,
                autorized_price_log_id: item.id,
              };

              obj.productsDate.push(objPush);
            }
          });
          resultArray.push(obj);
        });
        this.rows = resultArray;
        const today = new Date().toISOString().split("T")[0];
        if (uniqueDates.includes(today)) {
          this.addAuthorizedPrices = false;
        }

        this.getProducts();
      },
      error: (e) => {
        this.loading = false;
        console.log(e);
      },
    });
  }

  getProducts() {
    this.inventorySvc.getProducts().subscribe({
      next: (v) => {
        const tmpProducts = v.data.filter((item) => item.sellable);
        const productsDate = this.rows[0]?.productsDate;

        if (this.rows.length > 0) {
          tmpProducts.forEach((item) => {
            const product = productsDate.find((product) => {
              return product.id == item.id;
            });

            if (!product)
              this.rows[0]?.productsDate.splice(0, 0, {
                name: item?.name,
                authorized_price: item?.authorized_price,
                profit_margin: item?.profit_margin,
                list_price: item?.list_price,
                supply_price: item?.supply_price,
                physical_cost: item?.physical_cost,
                id: item?.id,
                autorized_price_log_id: item?.autorized_price_log_id,
              });
          });
        }
      },
      error: (e) => {
        console.log(e);
      },
    });
  }

  openSnack(message: string, color: string): void {
    this._snack.openFromComponent(AuthPricesComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
    });
  }
}
