<div class="">
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-200 border-0">
    <div class="relative flex flex-col min-w-0 break-words w-full rounded">

      <div class="grid grid-cols-2 gap-2 rounded-t mb-0 px-4 py-3 border-0 pt-6">
        <div class="basis-1/2 flex justify-start">
          <span class="z-10 h-full leading-snug font-normal absolute
              text-center text-slate-300 bg-transparent rounded text-base
              items-center justify-center w-8 pl-3 py-3">
            <i class="fa fa-search"></i>
          </span>

          <input type="text" class="w-48 pl-12 py-3 rounded-full border text-sm
              border-gray-300 focus:outline-none focus:border-blue-400" (keyup)="updateFilter($event)"
            placeholder="Buscar" />
        </div>

        <div class="basis-1/2 flex justify-end">
          <button class="bg-sky-600 text-white active:bg-sky-600 font-bold
              uppercase text-sm px-6 py-3 rounded-full shadow hover:shadow-lg
              outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
            (click)="openDialog(null, 'store')">
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>

      <div class="grid">
        <div class="block w-full overflow-x-auto rounded-lg">
          <ngx-datatable #table class="material" [columnMode]="ColumnMode.force" [headerHeight]="50" [footerHeight]="50"
            rowHeight="auto" [limit]="10" [rows]="temp">
            <ngx-datatable-column name="Id">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.id }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Nombre">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.name }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Descripción">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.description }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Estatus">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span
                  [ngClass]="{ 'text-green-600': row.active, 'text-red-600': !row.active, 'bg-green-200': row.active , 'bg-red-200': !row.active }"
                  class="text-xs font-semibold inline-block py-1 px-2 rounded-full uppercase last:mr-0 mr-1">
                  {{ row.active ? 'Activo' : 'Inactivo' }}
                </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Opciones">
              <ng-template #buttonsTemplate let-row="row" let-value="value" ngx-datatable-cell-template>
                <div class="flex">
                  <button class="bg-emerald-500 text-white px-2 py-1 rounded-full text-center"
                    (click)="openDialog(row, 'edit')">
                    <i class="fas fa-edit">
                    </i>
                  </button>

                  <button class="bg-red-500 text-white mx-8 px-2 py-1 rounded-full text-center" (click)="delete(row)">
                    <i class="fas fa-trash-alt">
                    </i>
                  </button>
                </div>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>

        </div>
      </div>
    </div>
  </div>
</div>