<div class="bg-white py-4 h-full w-full overflow-hidden">
  <!-- Header Container -->
  <div class="flex">
      <!-- Titulo Modal -->
      <div class="w-11/12 justify-between rounded-t mb-0 pb-8 border-0 bg-white">
          <div class="text-start pl-2">
              <h3 class="font-semibold text-lg">
                  {{ dialogTitle }}
              </h3>
          </div>
      </div>
      
      <!-- Boton cerrar Modal -->
      <div class="w-1/12 justify-end mb-0 pb-8">
          <button
          class="text-sky-600 cursor-pointer hover:text-sky-700 
          text-2xl pl-12 ease-linear transition-all duration-150"
          (click)="closeModal()">
          <i class="fas fa-times-circle"></i>
          </button>
      </div>
  </div>

  <!-- Contenidos -->
  <div class="overflow-y-auto h-full w-full">

    <!-- Formulario Contenido -->
    <form class="bg-white" [formGroup]="form">
        <!-- First Row -->
        <div class="flex flex-row column">
            <!-- Company Picker -->
            <div class="w-2/6 vh">
                <div class="container px-2">
                  <label
                        class="block uppercase text-slate-600 text-xs font-bold mb-2"
                        htmlFor="main_company_id">
                    Compañia
                  </label>
                  <select
                    #sellerId
                    formControlName="main_company_id"
                    [ngClass]="{
                      'focus:border-red-500': form.controls.main_company_id.invalid,
                      'focus:ring-red-500': form.controls.main_company_id.invalid,
                      'border-red-500': form.controls.main_company_id.invalid && form.controls.main_company_id.touched
                    }"
                    class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 
                    bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring 
                    w-full ease-linear transition-all duration-150">
                    
                    <option value="">Selecciona compañia vendedora</option>
                    <option *ngFor="let mainCompany of mains" [value]="mainCompany.id">
                      {{ mainCompany.legal_name }}
                    </option>
                  </select>
                  <div *ngFor="let validation of validationMessagesFirstPart.main_company_id">
                    <span
                      *ngIf="formService.field(form, validation.type, 'main_company_id')"
                      class="mt-2 text-sm text-red-500">
                      {{ validation.message }}
                    </span>
                  </div>
                </div>
            </div>

            <!-- Folio -->
            <div class="w-1/2 vh">
                <div class="container px-2">
                    <label
                        class="block uppercase text-slate-600 text-xs font-bold mb-2"
                        htmlFor="folio">
                        Folio
                    </label>
                    <input
                        type="text"
                        name="folio"
                        formControlName="folio"
                        [ngClass]="{
                        'focus:border-red-500': form.controls.folio.invalid,
                        'focus:ring-red-500': form.controls.folio.invalid,
                        'border-red-500': form.controls.folio.invalid && form.controls.folio.touched
                        }"
                        class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                    <div *ngFor="let validation of validationMessagesFirstPart.folio">
                        <span
                        *ngIf="formService.field(form, validation.type, 'folio')"
                        class="mt-2 text-sm text-red-500">
                        {{ validation.message }}
                        </span>
                    </div>
                </div>
            </div>

            <!-- Date -->
            <div class="w-1/2 vh">
                <div class="container px-2">
                    <app-date
                        idName="load_date"
                        title="Fecha"
                        [value]="dataSource.load_date"
                        [ngClass]="{
                          'focus:border-red-500': form.controls.load_date.invalid,
                          'focus:ring-red-500': form.controls.load_date.invalid,
                          'border-red-500': form.controls.load_date.invalid && form.controls.load_date.touched
                        }"
                        (dateChange)="changeDate($event, 'load_date')"></app-date>
                    <div *ngFor="let validation of validationMessagesFirstPart.load_date">
                        <span
                        *ngIf="formService.field(form, validation.type, 'load_date')"
                        class="mt-2 text-sm text-red-500">
                        {{ validation.message }}
                        </span>
                    </div>
                </div>
            </div>

            <!-- Payment -->
            <div class="w-2/6 vh">
                <div class="container px-2">
                    <label
                        class="block uppercase text-slate-600 text-xs font-bold mb-2"
                        htmlFor="payment_method_id">
                        Pago
                    </label>
                    <select
                      #paymentTypeId
                      formControlName="payment_method_id"
                      [ngClass]="{
                        'focus:border-red-500':form.controls.payment_method_id.invalid,
                        'focus:ring-red-500':form.controls.payment_method_id.invalid,
                        'border-red-500': form.controls.payment_method_id.invalid && form.controls.payment_method_id.touched
                      }"
                      class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 
                      bg-white rounded border-gray-300 text-sm focus:outline-none 
                      focus:ring w-full ease-linear transition-all duration-150">
                      <option value="">Selecciona un metodo de pago</option>
                      <option *ngFor="let type of paymentMethods" [value]="type.id">
                        {{ type.name }}
                      </option>
                    </select>
                    <div *ngFor="let validation of validationMessagesFirstPart.payment_method_id">
                      <span *ngIf="formService.field(form, validation.type, 'payment_method_id')" class="mt-2 text-sm text-red-500">
                        {{ validation.message }}
                      </span>
                    </div>
                </div>
            </div>

            <!-- Inquisition Number -->
            <div class="w-1/2 vh">
                <div class="container px-2">
                    <label
                        class="block uppercase text-slate-600 text-xs font-bold mb-2 "
                        htmlFor="req_number">
                        Num. Requisición
                    </label>
                    <input
                        type="text"
                        name="req_number"
                        formControlName="req_number"
                        [ngClass]="{
                        'focus:border-red-500': form.controls.req_number.invalid,
                        'focus:ring-red-500': form.controls.req_number.invalid
                        }"
                        class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                    <div *ngFor="let validation of validationMessagesFirstPart.req_number">
                        <span
                        *ngIf="formService.field(form, validation.type, 'req_number')"
                        class="mt-2 text-sm text-red-500">
                        {{ validation.message }}
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <!-- Second Row -->
        <div class="flex flex-row column">
            <!-- Select Provider -->
            <div class="w-1/2 px-2 smoll">
              <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="supplier_id">
                Proveedor
              </label>
              <select #supplier_id
                formControlName="supplier_id"
                [ngClass]="{
                  'focus:border-red-500': form.controls.supplier_id.invalid,
                  'focus:ring-red-500': form.controls.supplier_id.invalid
                }"
                class="border-1 px-3 py-2 placeholder-slate-300 
                text-slate-600 bg-white rounded border-gray-300 
                text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                <option value="">Selecciona un proveedor</option>
                <option *ngFor="let seller of this.sellers" [value]="seller.id">
                  {{ seller.legal_name }}
                </option>
              </select>
              <div *ngFor="let validation of validationMessagesFirstPart.supplier_id">
                <span
                  *ngIf="formService.field(form, validation.type, 'supplier_id')"
                  class="mt-2 text-sm text-red-500">
                  {{ validation.message }}
                </span>
              </div>
            </div>

            <!-- Select Buyer -->
            <div class="w-1/2 px-2 smoll">
              <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="buyer_id">
                Comprador
              </label>
              <select #buyer_id
                formControlName="buyer_id"
                [ngClass]="{
                  'focus:border-red-500': form.controls.buyer_id.invalid,
                  'focus:ring-red-500': form.controls.buyer_id.invalid
                }"
                class="border-1 px-3 py-2 placeholder-slate-300 
                text-slate-600 bg-white rounded border-gray-300 
                text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                <option value="">Selecciona un comprador</option>
                <option *ngFor="let buyer of this.users" [value]="buyer.id">
                  {{ buyer.name }}
                </option>
              </select>
              <div *ngFor="let validation of validationMessagesFirstPart.buyer_id">
                <span
                  *ngIf="formService.field(form, validation.type, 'buyer_id')"
                  class="mt-2 text-sm text-red-500">
                  {{ validation.message }}
                </span>
              </div>
            </div>

            <!-- Date -->
            <div class="w-1/2 smoll">
                <div class="px-2">
                  <app-date
                    idName="end_date"
                    title="Fecha ETA"
                    [value]="dataSource.end_date"
                    (dateChange)="changeDate($event, 'end_date')"></app-date>
                  <div *ngFor="let validation of validationMessagesFirstPart.end_date">
                    <span
                      *ngIf="formService.field(form, validation.type, 'end_date')"
                      class="mt-2 text-sm text-red-500">
                      {{ validation.message }}
                    </span>
                  </div>
                </div>
            </div>

            <!-- Status -->
            <div class="w-2/6 smoll" *ngIf="!this.main">
                <div class="container px-2">
                    <label
                        class="block uppercase text-slate-600 text-xs font-bold mb-2"
                        htmlFor="order_status_id">
                        Estado
                    </label>
                    <select
                      #paymentTypeId
                      formControlName="order_status_id"
                      [ngClass]="{
                        'focus:border-red-500':
                          form.controls.order_status_id.invalid,
                        'focus:ring-red-500':
                          form.controls.order_status_id.invalid
                      }"
                      class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 
                      bg-white rounded border-gray-300 text-sm focus:outline-none 
                      focus:ring w-full ease-linear transition-all duration-150">
                      <option value="">Selecciona un Estado</option>
                      <option *ngFor="let type of statuses" [value]="type.id">
                        {{ type.name }}
                      </option>
                    </select>
                    <div *ngFor="let validation of validationMessagesFirstPart.order_status_id">
                      <span *ngIf="formService.field(form, validation.type, 'order_status_id')" class="mt-2 text-sm text-red-500">
                        {{ validation.message }}
                      </span>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <!-- Button to add form to array -->
    <div class="flex flex-row">
        <div class="flex">
            <!-- Title forms -->
            <div class="w-11/12 justify-between rounded-t mb-0 border-0 bg-white">
                <div class="text-start pl-2">
                    <h3 class="font-semibold text-lg">
                        {{this.detailTitle}}
                    </h3>
                </div>
            </div>
            <!-- New Purchase element -->
            <div class="justify-end mb-0 pl-2" *ngIf="!this.subOrder">
              <button
                class="bg-sky-600 text-white active:bg-sky-600 font-bold
                uppercase text-xs px-1.5 py-1 rounded-full shadow hover:shadow-lg 
                outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"(click)="createFormPurchase('product')">
                <i class="fas fa-plus-circle"></i>
              </button>
            </div>

            <!-- New Service element -->
            <div class="justify-start mb-0 pl-12" *ngIf="this.subOrder">
              <button
                class="bg-sky-600 text-white active:bg-sky-600 font-bold 
                uppercase text-xs px-1.5 py-1 rounded-full shadow hover:shadow-lg 
                outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button" (click)="createFormPurchase('service')">
                <i class="fas fa-plus-circle"></i>
              </button>
            </div>
        </div>
    </div>

    <!-- Form Array -->
    <div class="overflow-y-auto h-64">
        <!-- Forms for purchases -->
        <div *ngFor="let row of detallesCompra">
          <!-- Nuevo Renglon de Objeto -->
          <div class="row flex-col">
            <form class="border-2 border-gray-300 p-6 rounded-lg m-2" [formGroup]="row" (change)="readValors()">
              <!-- Row Formularios -->
              <div class="container">
                <div class="flex justify-end">
                  <!-- Boton Borrar elemento -->
                  <button
                    class="text-sky-600 cursor-pointer hover:text-sky-700 text-md pl-12 ease-linear transition-all duration-150"
                    (click)="deleteform(row)">
                    <i class="fas fa-trash-alt"></i>
                  </button>
                  <!-- <div class="w-1/12 justify-end justify-self-end mb-0 pb-8 flex mx-0 pl-64">
                  </div> -->
                </div>
              </div>
              
              <div class="flex flex-row column">
                <!-- Producto -->
                <!-- row.controls.action.value == true || (this.data.action == 'store' || this.data.acion == 'edit') -->
                <div class="w-1/2 m-2 moreSmoll" *ngIf="!this.subOrder">
                  <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="product_id">
                    Producto
                  </label>
                  <select #product_id
                    formControlName="product_id"
                    [ngClass]="{
                      'focus:border-red-500': row.controls.product_id.invalid,
                      'focus:ring-red-500': row.controls.product_id.invalid,
                      'border-red-500': row.controls.product_id.invalid && row.controls.product_id.touched
                    }"
                    class="border-1 px-3 py-2 placeholder-slate-300 
                    text-slate-600 bg-white rounded border-gray-300 
                    text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    (change)="onIntroduceIVA($event.target.value, row)" (change)="onSelectProduct($event.target.value, row)">
                    <option value="">Selecciona un producto</option>
                    <option *ngFor="let product of this.products" [value]="product.id">
                      {{ product.name }}
                    </option>
                  </select>
                  <div *ngFor="let validation of validationMessagesProductService.product_id">
                    <span
                      *ngIf="formService.field(row, validation.type, 'product_id')"
                      class="mt-2 text-sm text-red-500">
                      {{ validation.message }}
                    </span>
                  </div>
                </div>
                
                <!-- Service -->
                <!-- (!this.main && row.controls.action.value == false) || this.data.action == 'sub_oc' -->
                <div class="w-1/2 m-2 moreSmoll" *ngIf="this.subOrder">
                  <label
                      class="block uppercase text-slate-600 text-xs font-bold mb-2"
                      htmlFor="service_id">
                      Servicio
                    </label>
                    <select
                      #service_id
                      formControlName="service_id"
                      [ngClass]="{
                        'focus:border-red-500': row.controls.service_id.invalid,
                        'focus:ring-red-500': row.controls.service_id.invalid,
                        'border-red-500': row.controls.service_id.invalid && row.controls.service_id.touched
                      }"
                      class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 
                      bg-white rounded border-gray-300 text-sm focus:outline-none 
                      focus:ring w-full ease-linear transition-all duration-150"
                      (change)="onSelectService($event.target.value, row)">
                      <option value="">Selecciona un servicio</option>
                      <option *ngFor="let service of this.services" [value]="service.id">{{ service.name }}</option>
                    </select>
                    <div *ngFor="let validation of validationMessagesProductService.service_id">
                      <span
                        *ngIf="formService.field(row, validation.type, 'service_id')"
                        class="mt-2 text-sm text-red-500">
                        {{ validation.message }}
                      </span>
                    </div>
                    <!-- <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="service_id">
                      Servicio
                    </label>
                    <select #service_id
                      formControlName="service_id"
                      [ngClass]="{
                        'focus:border-red-500': row.controls.service_id.invalid,
                        'focus:ring-red-500': row.controls.service_id.invalid
                      }"
                      class="border-1 px-3 py-2 placeholder-slate-300 
                      text-slate-600 bg-white rounded border-gray-300 
                      text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                      <option value="">Selecciona un servicio</option>
                      <option *ngFor="let service of this.services" [value]="service.id">
                        {{ service.name }}
                      </option>
                    </select>
                    <div *ngFor="let validation of validationMessagesProductService.service_id">
                      <span
                        *ngIf="formService.field(row, validation.type, 'service_id')"
                        class="mt-2 text-sm text-red-500">
                        {{ validation.message }}
                      </span>
                    </div> -->
                </div>

                <!-- Clave Sat -->
                <div class="w-1/2 m-2 moreSmoll">
                  <label class="block uppercase text-slate-600 text-xs font-bold mb-2"
                      htmlFor="clave_sat">
                    Clave SAT
                  </label>
                  <input type="text" name="clave_sat" formControlName="clave_sat"
                  [ngClass]="{
                    'focus:border-red-500': row.controls.clave_sat.invalid,
                    'focus:ring-red-500': row.controls.clave_sat.invalid,
                    'border-red-500': row.controls.clave_sat.invalid && row.controls.clave_sat.touched
                  }" 
                  class="border-1 px-3 py-2 placeholder-slate-300 
                  text-slate-600 bg-white rounded border-gray-300 text-sm 
                  focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                  <div *ngFor="let validation of validationMessagesProductService.clave_sat">
                    <span
                      *ngIf="formService.field(row, validation.type, 'clave_sat')"
                      class="mt-2 text-sm text-red-500">
                      {{ validation.message }}
                    </span>
                  </div>
                </div>

                <!-- Unidad -->
                <div class="w-1/2 m-2 moreSmoll">
                  <label class="block uppercase text-slate-600 text-xs font-bold mb-2"
                      htmlFor="unit_key">
                    Unidad
                  </label>
                  <input type="text" name="unit_key" formControlName="unit_key"
                  [ngClass]="{
                    'focus:border-red-500': row.controls.unit_key.invalid,
                    'focus:ring-red-500': row.controls.unit_key.invalid,
                    'border-red-500': row.controls.unit_key.invalid && row.controls.unit_key.touched
                  }" class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white 
                  rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                  <div *ngFor="let validation of validationMessagesProductService.unit_key">
                    <span
                      *ngIf="formService.field(row, validation.type, 'unit_key')"
                      class="mt-2 text-sm text-red-500">
                      {{ validation.message }}
                    </span>
                  </div>
                </div>

                <!-- Cantidad -->
                <div class="w-1/2 m-2 moreSmoll">
                  <label class="block uppercase text-slate-600 text-xs font-bold mb-2"
                      htmlFor="quantity">
                    Cantidad
                  </label>
                  <input type="text" name="quantity" formControlName="quantity"
                  thousandSeparator=","
                  [ngClass]="{
                    'focus:border-red-500': row.controls.quantity.invalid,
                    'focus:ring-red-500': row.controls.quantity.invalid
                  }" 
                  class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 
                  bg-white rounded border-gray-300 text-sm focus:outline-none 
                  focus:ring w-full ease-linear transition-all duration-150" 
                  (change)="onIntroduceIVA($event.target.value, row)"/>
                  <div *ngFor="let validation of validationMessagesProductService.quantity">
                    <span
                      *ngIf="formService.field(row, validation.type, 'quantity')"
                      class="mt-2 text-sm text-red-500">
                      {{ validation.message }}
                    </span>
                  </div>
                </div>

                <!-- Costo -->
                <div class="w-1/2 m-2 moreSmoll">
                  <label class="block uppercase text-slate-600 text-xs font-bold mb-2"
                      htmlFor="agreed_prize">
                    Costo
                  </label>
                  <input type="text" name="agreed_prize" formControlName="agreed_prize" prefix="$ " mask="separator.8" [leadZero]="false" thousandSeparator=","
                      [ngClass]="{
                        'focus:border-red-500': row.controls.agreed_prize.invalid,
                        'focus:ring-red-500': row.controls.agreed_prize.invalid
                      }" 
                      class="border-1 px-3 py-2 placeholder-slate-300 
                        text-slate-600 bg-white rounded border-gray-300 
                        text-sm focus:outline-none focus:ring w-full 
                        ease-linear transition-all duration-150"
                      (change)="onIntroduceIVA($event.target.value, row)"/>
                  <div *ngFor="let validation of validationMessagesProductService.agreed_prize">
                    <span
                      *ngIf="formService.field(row, validation.type, 'agreed_prize')"
                      class="mt-2 text-sm text-red-500">
                      {{ validation.message }}
                    </span>
                  </div>
                </div>

                <!-- Importe -->
                <div class="w-1/2 m-2 moreSmoll">
                  <label class="block uppercase text-slate-600 text-xs font-bold mb-2"
                      htmlFor="amount">
                    Importe
                  </label>
                  <input type="text" name="amount" formControlName="amount"
                  prefix="$ "
                  mask="separator.6"
                  [leadZero]="false"
                  thousandSeparator=","
                  [ngClass]="{
                    'focus:border-red-500': row.controls.amount.invalid,
                    'focus:ring-red-500': row.controls.amount.invalid
                  }" class="border-1 px-3 py-2 placeholder-slate-300 
                  text-slate-600 bg-white rounded border-gray-300 text-sm 
                  focus:outline-none focus:ring w-full ease-linear transition-all duration-150" readonly/>
                  <div *ngFor="let validation of validationMessagesProductService.amount">
                    <span
                      *ngIf="formService.field(row, validation.type, 'amount')"
                      class="mt-2 text-sm text-red-500">
                      {{ validation.message }}
                    </span>
                  </div>
                </div>

                <!-- TO IVA -->
                 <!-- Sub Total -->
                 <div class="w-1/2 m-2 moreSmoll">
                  <label class="block uppercase text-slate-600 text-xs font-bold mb-2"
                      htmlFor="subtotal">
                    SubTotal
                  </label>
                  <input type="text" name="subtotal" formControlName="subtotal" prefix="$ "
                  mask="separator.6"
                  [leadZero]="false"
                  thousandSeparator=","
                  [ngClass]="{
                    'focus:border-red-500': row.controls.subtotal.invalid,
                    'focus:ring-red-500': row.controls.subtotal.invalid
                  }" class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 
                  bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring
                  w-full ease-linear transition-all duration-150" readonly/>
                  <div *ngFor="let validation of validationMessagesProductService.subtotal">
                    <span
                      *ngIf="formService.field(row, validation.type, 'subtotal')"
                      class="mt-2 text-sm text-red-500">
                      {{ validation.message }}
                    </span>
                  </div>
                </div>

                <!-- Retencion Iva -->
                <div class="w-1/2 m-2 moreSmoll" *ngIf="this.subOrder">
                  <label class="block uppercase text-slate-600 text-xs font-bold mb-2"
                      htmlFor="retention_iva">
                    Ret. IVA
                  </label> 
                  <input type="text" name="retention_iva" formControlName="retention_iva"
                  [ngClass]="{
                    'focus:border-red-500': row.controls.retention_iva.invalid,
                    'focus:ring-red-500': row.controls.retention_iva.invalid
                  }" class="border-1 px-3 py-2 placeholder-slate-300 
                  text-slate-600 bg-white rounded border-gray-300 
                  text-sm focus:outline-none focus:ring w-full 
                  ease-linear transition-all duration-150" readonly
                  (change)="onIntroduceIVA($event.target.value, row)"/>
                  <div *ngFor="let validation of validationMessagesProductService.retention_iva">
                    <span
                      *ngIf="formService.field(row, validation.type, 'retention_iva')"
                      class="mt-2 text-sm text-red-500">
                      {{ validation.message }}
                    </span>
                  </div>
                </div>

                <!-- IEPS -->
                <div class="w-1/2 m-2 moreSmoll" *ngIf="row.controls.ieps.value">
                  <label class="block uppercase text-slate-600 text-xs font-bold mb-2"
                      htmlFor="ieps_cost">
                    IEPS
                  </label> 
                  <input type="text" name="ieps_cost" formControlName="ieps_cost"
                  prefix="$ "
                  mask="separator.6"
                  [leadZero]="false"
                  thousandSeparator=","
                  [ngClass]="{
                    'focus:border-red-500': row.controls.ieps_cost.invalid,
                    'focus:ring-red-500': row.controls.ieps_cost.invalid
                  }" class="border-1 px-3 py-2 placeholder-slate-300 
                  text-slate-600 bg-white rounded border-gray-300 
                  text-sm focus:outline-none focus:ring w-full 
                  ease-linear transition-all duration-150" readonly
                  (change)="onIntroduceIVA($event.target.value, row)"/>
                  <div *ngFor="let validation of validationMessagesProductService.ieps_cost">
                    <span
                      *ngIf="formService.field(row, validation.type, 'ieps_cost')"
                      class="mt-2 text-sm text-red-500">
                      {{ validation.message }}
                    </span>
                  </div>
                </div>

                <!-- Descuento -->
                <div class="w-1/2 m-2 moreSmoll">
                  <label class="block uppercase text-slate-600 text-xs font-bold mb-2"
                      htmlFor="discount_1">
                    Descuento
                  </label> 
                  <input type="text" name="discount_1" formControlName="discount_1" prefix="$ "
                  mask="separator.6"
                  [leadZero]="false"
                  thousandSeparator=","
                  mask="separator.6"
                  thousandSeparator=","
                  [ngClass]="{
                    'focus:border-red-500': row.controls.discount_1.invalid,
                    'focus:ring-red-500': row.controls.discount_1.invalid
                  }" class="border-1 px-3 py-2 placeholder-slate-300 
                  text-slate-600 bg-white rounded border-gray-300 
                  text-sm focus:outline-none focus:ring w-full 
                  ease-linear transition-all duration-150"
                  (change)="onIntroduceIVA($event.target.value, row)"/>
                  <div *ngFor="let validation of validationMessagesProductService.discount_1">
                    <span
                      *ngIf="formService.field(row, validation.type, 'discount_1')"
                      class="mt-2 text-sm text-red-500">
                      {{ validation.message }}
                    </span>
                  </div>
                </div>

                <!-- Iva -->
                <!-- mask="separator.6"
                  [leadZero]="false" -->
                  <div class="w-1/2 m-2 moreSmoll">
                    <label class="block uppercase text-slate-600 text-xs font-bold mb-2"
                        htmlFor="iva">
                      IVA
                    </label> 
                    <input type="text" name="iva" formControlName="iva"
                    [ngClass]="{
                      'focus:border-red-500': row.controls.iva.invalid,
                      'focus:ring-red-500': row.controls.iva.invalid
                    }" class="border-1 px-3 py-2 placeholder-slate-300 
                    text-slate-600 bg-white rounded border-gray-300 
                    text-sm focus:outline-none focus:ring w-full 
                    ease-linear transition-all duration-150"
                    (change)="onIntroduceIVA($event.target.value, row)"/>
  
                    <div *ngFor="let validation of validationMessagesProductService.iva">
                      <span
                        *ngIf="formService.field(row, validation.type, 'iva')"
                        class="mt-2 text-sm text-red-500">
                        {{ validation.message }}
                      </span>
                    </div>
                  </div>

                <!-- Total -->
                <div class="w-1/2 m-2 moreSmoll">
                  <label class="block uppercase text-slate-600 text-xs font-bold mb-2" 
                      htmlFor="total">
                    Total
                  </label>
                  <input type="text" name="total" formControlName="total" prefix="$ "
                  mask="separator.6"
                  [leadZero]="false"
                  thousandSeparator=","
                  [ngClass]="{
                    'focus:border-red-500': row.controls.total.invalid,
                    'focus:ring-red-500': row.controls.total.invalid
                  }" class="border-1 px-3 py-2 placeholder-slate-300 
                  text-slate-600 bg-white rounded border-gray-300 
                  text-sm focus:outline-none focus:ring w-full 
                  ease-linear transition-all duration-150" readonly/>
                  <div *ngFor="let validation of validationMessagesProductService.total">
                    <span
                      *ngIf="formService.field(row, validation.type, 'total')"
                      class="mt-2 text-sm text-red-500">
                      {{ validation.message }}
                    </span>
                  </div>
                </div>

                <!-- Observaciones -->
                <div class="w-1/2 m-2 moreSmoll">
                  <label class="block uppercase text-slate-600 text-xs font-bold mb-2"
                      htmlFor="observations">
                    Observaciones
                  </label>
                  <input type="text" name="observations" formControlName="observations"
                  [ngClass]="{
                    'focus:border-red-500': row.controls.observations.invalid,
                    'focus:ring-red-500': row.controls.observations.invalid
                  }" class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                  <div *ngFor="let validation of validationMessagesProductService.observations">
                    <span
                      *ngIf="formService.field(row, validation.type, 'observations')"
                      class="mt-2 text-sm text-red-500">
                      {{ validation.message }}
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
    </div>

    <!-- Observations and table -->
    <div class="container">
      <form class="bg-white" [formGroup]="totalForm">
        <div class="flex flex-row column">
          <!-- Observaciones -->
          <div class="w-full">
            <div class="container px-2 smollVh">
              <label
                  class="block uppercase text-slate-600 text-xs font-bold mb-2"
                  htmlFor="product_specifications">
                  Observaciones
              </label>
              <textarea
                  name="product_specifications"
                  formControlName="product_specifications" 
                  class="border-1 px-3 py-2 placeholder-slate-300 smollVh
                  text-slate-600 bg-white rounded border-gray-300 
                  text-sm focus:outline-none focus:ring w-full 
                  h-64 ease-linear transition-all duration-150" [ngClass]="{
                    'focus:border-red-500': totalForm.controls.product_specifications.invalid,
                    'focus:ring-red-500': totalForm.controls.product_specifications.invalid,
                    'border-red-500': totalForm.controls.product_specifications.invalid && totalForm.controls.product_specifications.touched
                  }"></textarea>
              <div *ngFor="let validation of validationMessagesLastPart.product_specifications">
                  <span
                  *ngIf="formService.field(totalForm, validation.type, 'product_specifications')"
                  class="mt-2 text-sm text-red-500">
                  {{ validation.message }}
                  </span>
                </div>
              </div>
          </div>

          <!-- Table -->
          <div class="flex w-full smollVh">
            <div class="overflow-x-auto sm:-mx-6 lg:-mx-8 w-full">
              <div class="inline-block py-2 sm:px-6 lg:px-8 w-full pb-32">
                <div class="overflow-hidden rounded w-full">
                  <table
                    class="w-full border text-center text-sm font-light" aria-describedby="totals">
                    <thead>
                      <th></th>
                    </thead>
                    <tbody class="w-full">

                      <!-- Precio -->
                      <tr class="border-b" *ngIf="!this.subOrder">
                        <!-- <td
                          class="px-2 text-start whitespace-nowrap border-r font-medium">
                          Precio
                        </td>
                        <td class="whitespace-nowrap border-r">
                          <input
                            type="text"
                            prefix="$ "
                            mask="separator.2"
                            [leadZero]="true"
                            thousandSeparator=","
                            formControlName="precio" readonly
                            class="w-full border-0 focus:border-0"/>
                        </td>
                      </tr> -->

                      <!-- Importe -->
                      <tr class="border-b">
                        <td
                          class="px-2 text-start whitespace-nowrap border-r font-medium">
                          Importe
                        </td>
                        <td class="whitespace-nowrap border-r">
                          <input
                            type="text"
                            prefix="$ "
                            mask="separator.6"
                            [leadZero]="true"
                            thousandSeparator=","
                            formControlName="amount" readonly
                            class="w-full border-0 focus:border-0"/>
                        </td>
                      </tr>

                      <!-- Litros -->
                      <!-- <tr class="border-b" *ngIf="!this.subOrder">
                        <td
                          class="px-2 text-start whitespace-nowrap border-r font-medium">
                          Litros Totales
                        </td>
                        <td class="whitespace-nowrap border-r">
                          <input
                            type="text"
                            thousandSeparator=","
                            formControlName="litros" readonly
                            class="w-full border-0 focus:border-0"/>
                        </td>
                      </tr> -->

                      <!-- Total Discount -->
                      <tr class="border-b">
                        <td
                          class="px-2 text-start whitespace-nowrap border-r font-medium">
                          Descuento Total
                        </td>
                        <td class="whitespace-nowrap border-r">
                          <input
                            type="text"
                            prefix="$ "
                            mask="separator.6"
                            [leadZero]="true"
                            thousandSeparator=","
                            formControlName="discount" readonly
                            class="w-full border-0 focus:border-0"
                          />
                        </td>
                      </tr>
                    
                      <!-- Subtotal -->
                      <tr class="border-b">
                        <td
                          class="px-2 text-start whitespace-nowrap border-r font-medium">
                          SUBTOTAL
                        </td>
                        <td class="whitespace-nowrap border-r">
                          <input
                            type="text"
                            prefix="$ "
                            mask="separator.6"
                            [leadZero]="true"
                            thousandSeparator=","
                            formControlName="subtotal" readonly
                            class="w-full border-0 focus:border-0"
                          />
                        </td>
                      </tr>

                      <!-- IEPS -->
                      <tr class="border-b">
                        <td
                          class="px-2 text-start whitespace-nowrap border-r font-medium">
                          IEPS
                        </td>
                        <td class="whitespace-nowrap border-r">
                          <input
                            type="text"
                            prefix="$ "
                            mask="separator.6"
                            [leadZero]="true"
                            thousandSeparator=","
                            formControlName="ieps" readonly
                            class="w-full border-0 focus:border-0"
                          />
                        </td>
                      </tr>

                      <!-- Iva -->
                      <tr class="border-b">
                        <td
                          class="px-2 text-start whitespace-nowrap border-r font-medium">
                          IVA
                        </td>
                        <td class="whitespace-nowrap border-r">
                          <input
                            type="text"
                            prefix="$ "
                            mask="separator.6"
                            [leadZero]="true"
                            thousandSeparator=","
                            formControlName="iva" readonly
                            class="w-full border-0 focus:border-0"/>
                        </td>
                      </tr>

                      <!-- RISR -->
                      <tr class="border-b">
                        <td
                          class="px-2 text-start whitespace-nowrap border-r font-medium">
                          Retencion de ISR
                        </td>
                        <td class="whitespace-nowrap border-r">
                          <input
                            type="text"
                            prefix="$ "
                            mask="separator.6"
                            [leadZero]="true"
                            thousandSeparator=","
                            formControlName="retention_isr" readonly
                            class="w-full border-0 focus:border-0"
                          />
                        </td>
                      </tr>

                      <!-- RIVA -->
                      <tr class="border-b">
                        <td
                          class="px-2 text-start whitespace-nowrap border-r font-medium">
                          Retencion de IVA
                        </td>
                        <td class="whitespace-nowrap border-r">
                          <input
                            type="text"
                            prefix="$ "
                            mask="separator.6"
                            [leadZero]="true"
                            thousandSeparator=","
                            formControlName="retention_iva" readonly
                            class="w-full border-0 focus:border-0"
                          />
                        </td>
                      </tr>

                      <!-- TOTAL -->
                      <tr class="border-b">
                        <td
                          class="px-2 text-start whitespace-nowrap border-r font-medium">
                          TOTAL
                        </td>
                        <td class="whitespace-nowrap border-r">
                          <input
                            type="text"
                            prefix="$ "
                            mask="separator.6"
                            [leadZero]="true"
                            thousandSeparator=","
                            formControlName="total" readonly
                            class="w-full border-0 focus:border-0"/>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

  </div>

  <!-- Buttons -->
  <div class="flex items-center justify-end absolute bottom-0 right-0 pb-3 pr-3 pt-3 w-full bg-white">
    <button
      class="bg-slate-500 text-white active:bg-slate-500 font-bold uppercase text-xs 
      px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 
      ease-linear transition-all duration-150"
      (click)="closeModal()">
      Cancelar
    </button>
    
    <!-- Send Data -->
    <button class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-xs 
      px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 
      ease-linear transition-all duration-150"
      (click)="savePayload()">
      Guardar
    </button>
  </div>
</div>