import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReportService } from 'app/apiServices/report.service';
import { SalesService } from 'app/apiServices/sales.service';
import { NotificationsComponent } from 'app/components/alerts/notifications/notifications.component';
import { catchError, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-evidence',
  templateUrl: './evidence.component.html',
  styleUrls: ['./evidence.component.css']
})
export class EvidenceComponent implements OnInit{
  dataSource: any
  permission: boolean = true
  doc: any
  dataDoc: any

  validTypes: string[] = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-word.document.macroEnabled.12',
      'application/docx',
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/bmp',
      'image/tiff'
  ]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EvidenceComponent>,
    private _snack: MatSnackBar, 
    private reportService: ReportService,
    private purchaseService: SalesService
  ){
    this.dataSource = data.data
  }

  ngOnInit(): void {
    console.log("DataSource", this.dataSource, this.dataSource.purchase_order_attachments.length)
  } 

  onFileSelected(sender: any) {
    const file: File = sender.target.files[0]
    console.log("File", file)

    if(file){
      const validExtensions = ['pdf', 'doc', 'docx', 'jpeg', 'jpg', 'png', 'gif', 'bmp', 'tiff'];
      const fileExtension = file.name.split('.').pop().toLowerCase();
      
      console.log('File Extension', fileExtension)
      if (!this.validTypes.includes(file.type)  || !validExtensions.includes(fileExtension)) {
        this.permission = true
        this.openSnack("snack-error", "Tipo de archivo no valido")
      }else{
        this.permission = false
        const formData = new FormData();
        formData.append('file', file)
  
        this.doc = formData
      }
    }
  
  }

  updateObject(data: any){
    data['name'] = data.filename
    data['active'] = true

    if(this.dataSource.purchase_order_attachments.length > 0){
      var attachments = this.dataSource.purchase_order_attachments
      attachments.push(data)
      attachments.forEach(element => {
        if(element.id){
          element['order_attachment_id'] = element.id
        }
      });
      
      console.log("Docs", attachments)
      
      this.dataSource.purchase_order_attachments = []
      this.dataSource.purchase_order_attachments = attachments
    } else {
      this.dataSource.purchase_order_attachments = [data]
    }
    
    this.dataSource['supplier_id'] = this.dataSource.supplier.id
    this.dataSource['main_company_id'] = this.dataSource.main_company.id
    this.dataSource['payment_method_id'] = this.dataSource.payment_method.id
    this.dataSource['order_status_id'] = this.dataSource.order_status.id
    this.dataSource['transport_type_id'] = this.dataSource.transport_type.id
    this.dataSource['buyer_id'] = this.dataSource.buyer.id

    const dataMod = this.transformOrderDetails(this.dataSource.purchase_order_details)
    console.log("DataMod", dataMod)
    this.dataSource['products_services'] = dataMod

    delete this.dataSource.purchase_lab_orders
  }

  transformOrderDetails(details: any): any {
    return details.map(detail => ({
        purchase_order_detail_id: detail.id,
        agreed_prize: detail.agreed_prize,
        amount: detail.amount,
        clave_sat: detail.clave_sat,
        discount_1: detail.discount_1,
        discount_2: detail.discount_2,
        ieps: detail.ieps.toFixed(6),
        iva: detail.iva,
        retention_isr: detail.retention_isr,
        retention_iva: detail.retention_iva,
        retention_isr_percentage: detail.retention_isr_percentage,
        retention_iva_percentage: detail.retention_iva_percentage,
        observations: detail.observations,
        quantity: detail.quantity,
        product_id: detail.product ? detail.product.id : null,
        service_id: detail.service ? detail.service.id : null,
        subtotal: detail.subtotal,
        total: detail.total,
        unit_key: detail.unit_key
    }));
}

  onUpload() {
    const promise = new Promise((resolve, reject) => {
      this.reportService.sendFiles("order_purchase_order", this.doc).pipe(
        switchMap((r: any) => {
          this.updateObject(r.data);
          console.log("DataModified", this.dataSource);
          return this.purchaseService.updatePurchase(this.dataSource, this.dataSource.id);
        })
      ).toPromise().then(resolve).catch(reject);
    });
    
    promise.then(result => {
      if (result) {
        console.log('Final response:', result);
        this.openSnack("snack-success", "Archivo subido con éxito");
        this.closeModal();
      }
    }).catch(error => {
      console.error('Error occurred:', error);
      this.openSnack("snack-error", error.error.message);
    });
    

    // this.reportService.sendFiles("order_purchase_order", this.doc).pipe(
    //   switchMap((r: any) => {
    //     this.updateObject(r.data)
    //     console.log("DataModified", this.dataSource)
    //     return this.purchaseService.updatePurchase(this.dataSource, this.dataSource.id)
    //   }),
    //   catchError(error => {
    //     console.error('Error occurred:', error);
    //     // this.openSnack("snack-error", error.error.message)
    //     return null;
    //   })
    // ).subscribe(result => {
    //   if (result) {
    //     console.log('Final response:', result);
    //     this.openSnack("snack-success", "Archivo subido con exito")
    //     // this.closeModal()
    //   }
    // }); 
  }

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
      verticalPosition: "top",
    });
  }

  closeModal(){
    this.dialogRef.close()
  }

  gotoUrl(url: string){
    window.open(url)
  }
}
