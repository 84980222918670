import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpContextToken } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BYPASS_JW_TOKEN } from 'app/interceptors/jwt.interceptor';

@Injectable({
  providedIn: 'root'
})
export class OcrService {
  cbImage: EventEmitter<any> = new EventEmitter<any>();
  cbText: EventEmitter<any> = new EventEmitter<any>();

  private apiKey = 'AIzaSyDPX8dufZqJAAjBPblpSO5OTxrm8TXT5L4';

  constructor(private http: HttpClient) { }

  getData(data: any): Observable<any> {
    const context = new HttpContext().set(BYPASS_JW_TOKEN, true);
    return this.http.post(`https://vision.googleapis.com/v1/images:annotate?key=${this.apiKey}`, data, { context });
  }

  getBase64(filename): Observable<string> {
    return new Observable((observer) => {
      this.http
        .get(filename, { responseType: 'blob' })
        .subscribe((imageBlob: Blob) => {
          const reader = new FileReader();

          // Cuando se complete la lectura del Blob, se disparará este evento
          reader.onloadend = () => {
            // El resultado de la lectura es una URL Base64
            const base64DataUrl = reader.result as string;

            // console.log(base64DataUrl);
            observer.next(base64DataUrl); // Emitir el Base64
            observer.complete(); // Indicar que la observación está completa
          };

          // Leer el Blob como una URL Base64
          reader.readAsDataURL(imageBlob);
        });
    });
  }

  getBase64File(file: File): Observable<string> {
    return new Observable((observer) => {
      const reader = new FileReader();

      // Cuando se complete la lectura del Blob, se disparará este evento
      reader.onloadend = () => {
        // El resultado de la lectura es una URL Base64
        const base64DataUrl = reader.result as string;

        // console.log(base64DataUrl);
        observer.next(base64DataUrl); // Emitir el Base64
        observer.complete(); // Indicar que la observación está completa
      };

      // Leer el archivo como una URL Base64
      reader.readAsDataURL(file);
    });
  }

  readJSON(rute: string): Observable<any> {
    return this.http.get(rute);
  }
}
