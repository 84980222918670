<div class="container bg-white mx-auto px-4 h-full py-4">
    <div class="flex justify-between rounded-t mb-0 px-4 py-3 border-0 pt-6 bg-white">
        <div class="flex w-full justify-center">
            <div>
                <h3 class="font-semibold text-lg">
                    {{dialogTitle}}
                </h3>
            </div>
        </div>
    </div>
    <form class="bg-white" [formGroup]="form">
        <div class="container px-2 mb-3">
            <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="tank">
                Número de Tanque
            </label>
            <input type="text" name="tank" formControlName="tank" [ngClass]="{ 
                'border-red-500': form.controls.tank.invalid, 
                'focus:border-red-500': form.controls.tank.invalid, 
                'focus:ring-red-500': form.controls.tank.invalid 
            }" class="border-2 px-3 py-3
            placeholder-slate-300
            text-slate-600 bg-white rounded border-gray-300
            text-sm focus:outline-none focus:ring
            w-full ease-linear transition-all duration-150" />
            <div *ngFor="let validation of validationMessages.tank">
                <span *ngIf="formService.field(form, validation.type, 'tank')" class="mt-2 text-sm text-red-500 ">
                    {{ validation.message }}
                </span>
            </div>
        </div>
        <div class="relative w-full mb-3 flex">

            <!-- Capacity -->
            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2 " htmlFor="capacity">
                    Capacidad
                </label>
                <input type="number" name="capacity" formControlName="capacity" [ngClass]="{ 
                        'border-red-500': form.controls.capacity.invalid, 
                        'focus:border-red-500': form.controls.capacity.invalid, 
                        'focus:ring-red-500': form.controls.capacity.invalid 
                    }" class="border-2 px-3 py-3
                    placeholder-slate-300
                    text-slate-600 bg-white rounded border-gray-300
                    text-sm focus:outline-none focus:ring
                    w-full ease-linear transition-all duration-150" />
                <div *ngFor="let validation of validationMessages.capacity">
                    <span *ngIf="formService.field(form, validation.type, 'capacity')"
                        class="mt-2 text-sm text-red-500 ">
                        {{ validation.message }}
                    </span>
                </div>
            </div>
            <!-- Quantity -->
            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="quantity">
                    Inventario Físico
                </label>
                <div class="flex items-center"> <!-- Contenedor flex para alinear icono y entrada -->
                    <input type="number" name="quantity" formControlName="quantity" [ngClass]="{ 
                        'border-red-500': form.controls.quantity.invalid, 
                        'focus:border-red-500': form.controls.quantity.invalid, 
                        'focus:ring-red-500': form.controls.quantity.invalid 
                    }"
                        class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                    <!--<i class="fas fa-camera mx-2" (click)="fileInput.click()"></i>
                    <input #fileInput type="file" name="file" style="display: none;"
                        (change)="onFileSelected($event.target.files[0])" accept="image/*" />-->
                </div>
                <div *ngFor="let validation of validationMessages.quantity">
                    <span *ngIf="formService.field(form, validation.type, 'quantity')"
                        class="mt-2 text-sm text-red-500">
                        {{ validation.message }}
                    </span>
                </div>
            </div>

        </div>

        <div class="relative w-full mb-3 flex">
        <div class="container px-2">
            <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="measured">
                Inventario Medido
            </label>
            <div class="flex items-center"> <!-- Contenedor flex para alinear icono y entrada -->
                <input type="number" name="measured" formControlName="measured" [ngClass]="{ 
                    'border-red-500': form.controls.measured.invalid, 
                    'focus:border-red-500': form.controls.measured.invalid, 
                    'focus:ring-red-500': form.controls.measured.invalid 
                }"
                    class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded 
                    border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all 
                    duration-150" />
            </div>
            <div *ngFor="let validation of validationMessages.measured">
                <span *ngIf="formService.field(form, validation.type, 'measured')"
                    class="mt-2 text-sm text-red-500">
                    {{ validation.message }}
                </span>
            </div>
        </div>

        <div class="container px-2">
            <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="products">
                Producto a almacenar
            </label>
            <select name="products" formControlName="products" [ngClass]="{
              'border-red-500': form.controls.products.invalid,
              'focus:border-red-500': form.controls.products.invalid,
              'focus:ring-red-500': form.controls.products.invalid
            }" class="border-2 px-3 py-3
            placeholder-slate-300 mb-3
            text-slate-600 bg-white rounded border-gray-300
            text-sm focus:outline-none focus:ring
            w-full ease-linear transition-all duration-150">
                <option *ngFor="let item of productsData" [ngValue]="item.id">{{ item.name }}</option>
            </select>
        </div>

        </div>
        <div class="container px-2">
            <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="observations">
                Observaciones
            </label>
            <textarea name="observations" formControlName="observations" class="border-gray-300 px-3 py-3
                  placeholder-slate-300 border-2
                  text-slate-600 bg-white rounded
                  text-sm focus:outline-none focus:ring
                  w-full ease-linear transition-all duration-150">
              </textarea>
        </div>
        <div class="container px-2" *ngIf="edit">
            <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="reasons">
                Razones de Cambio de Medidas
            </label>
            <textarea name="reasons" formControlName="reasons" class="border-gray-300 px-3 py-3
                  placeholder-slate-300 border-2
                  text-slate-600 bg-white rounded
                  text-sm focus:outline-none focus:ring
                  w-full ease-linear transition-all duration-150">
              </textarea>
        </div>
        <!-- Buttons -->
        <div class="flex w-full justify-end">
            <button class="bg-slate-500 text-white 
                active:bg-slate-500 font-bold uppercase 
                text-xs px-4 py-2 rounded shadow hover:shadow-md 
                outline-none focus:outline-none mr-1 ease-linear 
                transition-all duration-150" (click)="closeModal()">
                Cancelar
            </button>
            <button class="bg-sky-600 text-white 
                active:bg-sky-600 font-bold uppercase 
                text-xs px-4 py-2 rounded shadow hover:shadow-md 
                outline-none focus:outline-none mr-1 ease-linear 
                transition-all duration-150" [ngClass]="{ 'disabled:opacity-25': form.invalid }"
                [disabled]="form.invalid" (click)="save()">
                {{ edit ? 'Actualizar' : 'Agregar' }}
            </button>
        </div>
    </form>
</div>