import { Component, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DatatableComponent, ColumnMode } from "@swimlane/ngx-datatable";
import { ModalConfirmationComponent } from "app/components/modals/modal-confirmation/modal-confirmation.component";
import { Product, ProductMixes } from "app/models/product";
import { FormComponent } from "./dialogs/form/form.component";
import { InventoryService } from "app/apiServices/inventory.service";
import { Data } from "@angular/router";
import { FormGroup, FormBuilder } from "@angular/forms";

@Component({
  selector: "app-products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.css", "./products.component.css"],
})
export class ProductsComponent {
  products: Product[] = [
    { id: 1, name: "COPE" },
    { id: 2, name: "ENERHEAT 70" },
    { id: 3, name: "FLAMA 100" },
    { id: 4, name: "NAFTA PESADA" },
    { id: 5, name: "AD GOMAS" },
    { id: 6, name: "AD AZUFRE" },
    { id: 7, name: "REGULAR" },
    { id: 8, name: "NAFTA LIGERA" },
    { id: 9, name: "AD OXIGENANTE" },
    { id: 10, name: "ETANOL" },
    { id: 11, name: "TOLUENO" },
    { id: 12, name: "PREMIUM" },
    { id: 13, name: "QUEROSENO BA" },
    { id: 14, name: "CETANO BA" },
    { id: 15, name: "CETANO AA" },
    { id: 16, name: "SN 100" },
    { id: 17, name: "SN 75" },
    { id: 18, name: "DIESEL" },
    { id: 19, name: "DIE 300PPM" },
  ];
  rows: Product[] = [
    {
      id: 1,
      name: "AD GOMAS",
      description: "",
      code: "AD",
      full_mix: true,
      on_hand: 1800,
      on_order: 2000,
      list_price: 10,
      authorized_price: 11.5,
      supply_price: 11,
      profit_margin: 0.35,
      tankProduct: {
        id: 1,
        tank_id: 1,
        product_id: 1,
        active: true,
        observations: "",
        tank: { id: 1, capacity: 30000, quantity: 18000 },
      },
      products: [],
    },
  ];
  temp: any[] = [];
  showAlert = false;
  alertTitle: string;
  expanded: any = {};
  form: FormGroup;

  @ViewChild(DatatableComponent) table: DatatableComponent;
  ColumnMode = ColumnMode;
  columnMapValues = [
    ["id", "id"],
    ["nombre", "name"],
    ["descripciN", "description"],
    ["code", "code"],
    ["ieps", "ieps"],
    ["vendible", "sellable"],
    ["estatus", "active"],
  ] as const;
  columnMap: Map<string, string> = new Map(this.columnMapValues);
  loading: boolean = false;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private inventorySvc: InventoryService
  ) {
    this.form = this.fb.group({
      rowsPerPage: [10],
    });
  }

  ngOnInit() {
    this.getData();
  }

  updateFilter(event: Event) {
    const val: string = (event.target as HTMLInputElement).value.toLowerCase();
    const temp: Product[] = this.rows.filter((d: Product) => {
      return (
        d.name.toLowerCase().indexOf(val) !== -1 ||
        d.code.toLowerCase().indexOf(val) !== -1 ||
        d.description.toLowerCase().indexOf(val) !== -1 ||
        !val
      );
    });
    this.temp = temp;
    this.table.offset = 0;
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {}

  openDialog(data: Product, action: string) {
    this.alertTitle =
      action == "store"
        ? "Producto agregado con éxito"
        : "Procuto editado con éxito";

    const dialogRef = this.dialog.open(FormComponent, {
      width: "84vw",
      maxWidth: "84vw",
      height: "90vh",
      data: {
        action,
        data,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getData();
    });
  }

  openDialogDelete(data: Data) {
    const dialogRef = this.dialog.open(ModalConfirmationComponent, {
      width: "600px",
      data: {
        title: "¿Estas seguro de desactivar este producto?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.inventorySvc.deleteProducts(data).subscribe({
          next: (v) => {
            this.getData();
          },
          error: (e) => {},
        });
      }
    });
  }

  onLimitChange(event: Event): void {
    const limit = (event.target as HTMLSelectElement).value;
    this.changePageLimit(limit);
    this.table.limit = this.form.controls.rowsPerPage.value;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor(
          (this.table.rowCount - 1) / this.table.limit
        );
      }
    });
  }

  changePageLimit(limit: any): void {
    this.form.controls.rowsPerPage.setValue(parseInt(limit, 10));
  }

  onSort(event: any): void {
    const sort = event.sorts[0];
    const column = this.columnMap.get(sort.prop);

    if (column) {
      this.temp.sort((a, b) => {
        if (typeof a[column] === "number") {
          if (sort.dir === "asc") return a[column] - b[column];
          else return b[column] - a[column];
        } else if (typeof a[column] === "string") {
          return (
            a[column].localeCompare(b[column]) * (sort.dir === "desc" ? -1 : 1)
          );
        }
      });

      this.temp = [...this.temp];
    }
  }

  closeAlert(event: boolean) {
    this.showAlert = event;
  }

  delete(data: Product) {
    this.openDialogDelete(data);
  }

  getData(): void {
    this.loading = true;
    this.inventorySvc.getProducts().subscribe({
      next: (v) => {
        this.loading = false;
        this.rows = v.data;
        this.temp = v.data;

        this.temp.forEach((item) => {
          item.products.forEach((p) => {
            const compoundProduct = item.compound_products.find(
              (compoundProduct) => compoundProduct.element_product_id === p.id
            );
            // Verifica si se encontró un producto compuesto y asigna el porcentaje
            if (compoundProduct) {
              p.percentage = compoundProduct.percentage;
            }
          });
        });

        this.temp = [...this.temp];
      },
      error: (e) => {
        this.loading = false;
      },
    });
  }
}
