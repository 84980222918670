import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InventoryService } from 'app/apiServices/inventory.service';
import { NotificationsComponent } from 'app/components/alerts/notifications/notifications.component';
import { FormValidatorService } from 'app/core/service/form-validator.service';
import { Measure } from 'app/models/measure';
import { Pump } from 'app/models/pump';

@Component({
  selector: 'app-form-pump',
  templateUrl: './form-pump.component.html',
  styleUrls: ['./form-pump.component.css']
})
export class FormPumpComponent {
  form: FormGroup;
  dataSource: Pump;
  edit = false;
  dialogTitle: string;

  pumpMeasures: Measure[] = [];

  validationMessages = {
    name: [{ type: "required", message: "Campo Requerido" }],
    pump_measure_id: [{ type: "required", message: "Campo Requerido" }],
    mobile: [{ type: "required", message: "Campo Requerido" }],
  };

  constructor(
    private dialogRef: MatDialogRef<FormPumpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public formService: FormValidatorService,
    private inventorySvc: InventoryService,
    private _snack: MatSnackBar
  ) {
    this.data = data;

    if (data.action === "edit") {
      this.dialogTitle = "Editar Bomba";
      this.dataSource = {
        pump_measure_id: data.data.pump_measure.id,
        ...data.data
      }
      this.edit = true;
    } else {
      this.dialogTitle = "Agregar Bomba";
      this.dataSource = new Pump({});
    }
    this.form = this.createForm();
    this.getData()
  }

  createForm(): FormGroup {
    return this.fb.group({
      id: [this.dataSource.id],
      pump_measure_id: [this.dataSource.pump_measure_id, [Validators.required]],
      name: [this.dataSource.name, [Validators.required]],
      mobile: [this.dataSource.mobile, [Validators.required]],
      description: [this.dataSource.description, []],
    });
  }

  save(): void {
    if (this.form.valid) {
      this.dataSource = this.form.value;
      this.storeUpdate(this.edit ? "update" : "store");
    } else {
      this.formService.allFields(this.form);
      alert("Datos incorrectos");
    }
  }

  storeUpdate(method: string): void {
    if (method === "store") {
      this.inventorySvc.sendPump(this.dataSource).subscribe({
        next: (v) => {
          // console.log(v)
          this.openSnack('snack-success', v.message)
          this.dialogRef.close();
        },
        error: (e) => {
          this.openSnack('snack-error', e.error.message)
          // console.log(e)
        }
      })
    } else {
      this.inventorySvc.updatePump(this.dataSource).subscribe({
        next: (v) => {
          // console.log(v)
          this.openSnack('snack-success', v.message)
          this.dialogRef.close();
        },
        error: (e) => {
          this.openSnack('snack-error', e.error.message)
          // console.log(e)
        }
      })
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  getData(): void {
    this.inventorySvc.getPumpMeasures().subscribe({
      next: (v) => {
        this.pumpMeasures = v.data
      },
      error: (e) => {
        //console.log(e)
      }
    })
  }

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000
    })
  }
}
