import { Component, Inject, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { InventoryService } from "app/apiServices/inventory.service";
import { NotificationsComponent } from "app/components/alerts/notifications/notifications.component";
import { FormValidatorService } from "app/core/service/form-validator.service";
import { DatePipe } from "@angular/common";

import { Product } from "app/models/product";
import { User } from "app/models/user";
import { DataService } from "app/services/data.service";
import { Tooltip, initTE } from "tw-elements";

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.css"],
})
export class FormComponent implements OnInit {
  users: User[] = [];
  products: Product[] = [];

  today: boolean = false;
  object: any;
  form: FormGroup;
  dataSource: any;
  edit = false;
  dialogTitle: string;
  currentUser: User;
  isUserWithPrivileges: boolean;

  obj: any;

  durationInSeconds = 3;

  validationMessages = {
    user_id: [{ type: "required", message: "Campo Requerido" }],
    product_id: [{ type: "required", message: "Campo Requerido" }],
    authorized_price: [{ type: "required", message: "Campo Requerido" }],
    profit_margin: [{ type: "required", message: "Campo Requerido" }],
    list_price: [{ type: "required", message: "Campo Requerido" }],
    recommended_price: [{ type: "required", message: "Campo Requerido" }],
    physical_cost: [{ type: "required", message: "Campo Requerido" }],
  };

  constructor(
    private dialogRef: MatDialogRef<FormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    public formService: FormValidatorService,
    private dataService: DataService,
    private inventorySvc: InventoryService,
    public _snack: MatSnackBar,
    private date: DatePipe
  ) {
    this.data = data;
    this.currentUser = this.dataService.userValue;
    this.isUserWithPrivileges = this.userWithPrivileges();

    if (this.data.action === "edit") {
      this.edit = true;
    }

    this.dialogTitle = "Precios Autorizados";
    this.getData();
  }

  ngOnInit(): void {
    if (this.getCurrentDate() == this.data?.data?.created_at) this.today = true;
  }

  ngAfterViewInit(): void {
    initTE({ Tooltip });
  }

  getCurrentDate(): string {
    if (this.edit) {
      const currentDate = new Date();
      return this.date.transform(currentDate, "yyyy-MM-dd");
    }
  }

  createForm(): FormGroup {
    return this.fb.group({
      authorized_prices: this.fb.array([]),
    });
  }

  get authorized_prices() {
    return this.form.controls.authorized_prices as FormArray;
  }

  createAuthorizedPriceForm(): FormGroup {
    return this.fb.group({
      //id: [this.data.data.id, []],
      name: [{ value: "", disabled: true }, [Validators.required]],
      product_id: [0, [Validators.required]],
      authorized_price: [{ value: 0 }, [Validators.required]],
      profit_margin: [{ value: 0 }, [Validators.required]],
      physical_cost: [
        {
          value: 0,
          disabled: this.edit && this.isUserWithPrivileges === false,
        },
        [Validators.required],
      ],
      supply_price: [
        {
          value: 0,
          disabled: this.edit && this.isUserWithPrivileges === false,
        },
        [Validators.required],
      ],
      list_price: [
        {
          value: 0,
          disabled: this.edit && this.isUserWithPrivileges === false,
        },
        [Validators.required],
      ],
      recommended_price: [
        { value: 0, disabled: this.isUserWithPrivileges === true },
        [Validators.required],
      ],
      autorized_price_log_id: [null, []],
      operative_cost: [0, [Validators.required]],
    });
  }

  save(): void {
    if (this.form.valid || this.edit) {
      this.dataSource = this.form.value;
      this.storeUpdate(this.edit ? "update" : "store");
    } else {
      this.formService.allFields(this.form);
      this.openSnack("Datos incorrectos", "snack-error");
    }
  }

  storeUpdate(method: string): void {
    const data = {
      method,
      data: this.dataSource,
      status: 200,
    };

    this.form.enable();
    this.dataSource = this.form.value;
    this.form.disable();

    this.dataSource.authorized_prices = this.dataSource.authorized_prices.map(
      (price) => {
        const authorized_price =
          typeof price.authorized_price === "string"
            ? parseFloat(price.authorized_price)
            : price.authorized_price;
        const profit_margin =
          typeof price.profit_margin === "string"
            ? parseFloat(price.profit_margin)
            : price.profit_margin;
        const supply_price =
          typeof price.supply_price === "string"
            ? parseFloat(price.supply_price)
            : price.supply_price;
        const list_price =
          typeof price.list_price === "string"
            ? parseFloat(price.list_price)
            : price.list_price;
        const physical_cost =
          typeof price.physical_cost === "string"
            ? parseFloat(price.physical_cost) ?? 0
            : price.physical_cost ?? 0;
        const operative_cost =
          typeof price.operative_cost === "string"
            ? parseFloat(price.operative_cost)
            : price.operative_cost ?? 0;;

        return {
          ...price,
          authorized_price,
          profit_margin,
          supply_price,
          list_price,
          physical_cost,
          operative_cost,
          recommended_price:
            this.calculateRecommendedPrice(profit_margin, physical_cost, operative_cost) ?? 0,
        };
      }
    );
    const arrg: any[] = this.dataSource.authorized_prices;
    const obj = {
      authorized_prices: arrg,
    };

    if (!this.edit) {
      this.inventorySvc.sendAuthorizedPrice(obj).subscribe({
        next: (v) => {
          this.dialogRef.close(data);
          this.openSnack(v.message, "snack-success");
        },
        error: (e) => {
          this.openSnack(e.error.message, "snack-error");
        },
      });
    } else if (this.edit) {
      this.inventorySvc.updateAuthorizedPrice(obj).subscribe({
        next: (v) => {
          this.openSnack(v.message, "snack-success");
          this.dialogRef.close(data);
        },
        error: (e) => {
          this.openSnack(e.error.message, "snack-error");
        },
      });
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  getData(): void {
    if (this.data.action === "edit") {
      this.form = this.createForm();

      this.products = this.data.data.productsDate;

      this.products.forEach((product) => {
        const authorized_price =
          typeof product.authorized_price === "string"
            ? parseFloat(product.authorized_price)
            : product.authorized_price;
        const profit_margin =
          typeof product.profit_margin === "string"
            ? parseFloat(product.profit_margin)
            : product.profit_margin;
        const supply_price =
          typeof product.supply_price === "string"
            ? parseFloat(product.supply_price)
            : product.supply_price;
        const list_price =
          typeof product.list_price === "string"
            ? parseFloat(product.list_price)
            : product.list_price;
        const physical_cost =
          typeof product.physical_cost === "string"
            ? parseFloat(product.physical_cost)
            : product.physical_cost;
        const operative_cost =
          typeof product.operative_cost === "string"
            ? parseFloat(product.operative_cost)
            : product.operative_cost;
        let p = this.createAuthorizedPriceForm();
        p.patchValue({
          name: product.name,
          autorized_price_log_id: product.autorized_price_log_id,
          authorized_price: this.leadingZero(authorized_price.toString()),
          profit_margin: this.leadingZero(profit_margin.toString()),
          supply_price: this.leadingZero(supply_price.toString()),
          list_price: this.leadingZero(list_price.toString()),
          physical_cost:
            this.leadingZero(physical_cost.toString()) ?? this.leadingZero("0"),
          operative_cost: this.leadingZero(operative_cost?.toString()),
          recommended_price:
            this.leadingZero(
              this.calculateRecommendedPrice(
                product.profit_margin,
                product.physical_cost,
                product.operative_cost
              ).toString()
            ) ?? this.leadingZero("0"),
          product_id: product.id,
        });
        this.authorized_prices.push(p);
      });
    } else {
      this.form = this.createForm();
      this.products = this.data.data.productsDate;
      this.products.forEach((product) => {
        let p = this.createAuthorizedPriceForm();
        const authorized_price =
          typeof product.authorized_price === "string"
            ? parseFloat(product.authorized_price)
            : product.authorized_price;
        const profit_margin =
          typeof product.profit_margin === "string"
            ? parseFloat(product.profit_margin)
            : product.profit_margin;
        const supply_price =
          typeof product.supply_price === "string"
            ? parseFloat(product.supply_price)
            : product.supply_price;
        const list_price =
          typeof product.list_price === "string"
            ? parseFloat(product.list_price)
            : product.list_price;
        const physical_cost =
          typeof product.physical_cost === "string"
            ? parseFloat(product.physical_cost)
            : product.physical_cost ?? 0;
        const operative_cost =
          typeof product.operative_cost === "string"
            ? parseFloat(product.operative_cost)
          : product.operative_cost ?? 0;

        p.patchValue({
          name: product.name,
          product_id: product.id,
          authorized_price: this.leadingZero(authorized_price.toString()),
          profit_margin: this.leadingZero(profit_margin.toString()),
          supply_price: this.leadingZero(supply_price.toString()),
          list_price: this.leadingZero(list_price.toString()),
          physical_cost:
            this.leadingZero(physical_cost.toString()) ?? this.leadingZero("0"),
          operative_cost: this.leadingZero(operative_cost.toString()),
          recommended_price:
            this.leadingZero(
              this.calculateRecommendedPrice(
                profit_margin,
                physical_cost,
                operative_cost
              ).toString()
            ) ?? this.leadingZero("0"),
        });
        this.authorized_prices.push(p);
      });
    }
  }

  setFormEdit() {
    if (this.data.action === "edit") {
      this.inventorySvc.getAuthorizedPriceId(this.dataSource).subscribe({
        next: (v) => {
          this.obj = v.data;

          const authorized_price =
            typeof this.obj.authorized_price === "string"
              ? parseFloat(this.obj.authorized_price)
              : this.obj.authorized_price;
          const profit_margin =
            typeof this.obj.profit_margin === "string"
              ? parseFloat(this.obj.profit_margin)
              : this.obj.profit_margin;
          const supply_price =
            typeof this.obj.supply_price === "string"
              ? parseFloat(this.obj.supply_price)
              : this.obj.supply_price;
          const list_price =
            typeof this.obj.list_price === "string"
              ? parseFloat(this.obj.list_price)
              : this.obj.list_price;
          const physical_cost =
            typeof this.obj.physical_cost === "string"
              ? parseFloat(this.obj.physical_cost) ?? 0
              : this.obj.physical_cost ?? 0;
          const operative_cost =
            typeof this.obj.operative_cost === "string"
              ? parseFloat(this.obj.operative_cost) ?? 0
              : this.obj.operative_cost ?? 0;

          this.dataSource = {
            ...this.dataSource,
            product_id: this.obj.product.id,
            list_price: this.leadingZero(list_price?.toString()),
            authorized_price: this.leadingZero(authorized_price?.toString()),
            profit_margin: this.leadingZero(profit_margin?.toString()),
            supply_price: this.leadingZero(supply_price?.toString()),
            operative_cost: this.leadingZero(operative_cost?.toString()),
            recommended_price:
              this.leadingZero(
                this.calculateRecommendedPrice(
                  profit_margin,
                  physical_cost,
                  operative_cost
                ).toString()
              ) ?? 0,
          };
          this.form.patchValue(this.dataSource);
        },
        error: (e) => {},
      });
    }
  }

  onCalculateRecommendedPrice(
    event: InputEvent,
    key: string,
    position: number
  ): void {
    let value = (event.target as HTMLInputElement).value;
    let numberValue = 0;
    let profitMargin =
      this.authorized_prices.controls[position].get("profit_margin").value;
    let supplyPrice =
      this.authorized_prices.controls[position].get("physical_cost").value;
    let operationCost =
      this.authorized_prices.controls[position].get("operative_cost").value;

    if (typeof value === "string") numberValue = this.convertToNumber(value);

    if (typeof profitMargin === "string")
      profitMargin = this.convertToNumber(profitMargin);

    if (typeof supplyPrice === "string")
      supplyPrice = this.convertToNumber(supplyPrice);

    if (typeof operationCost === "string")
      operationCost = this.convertToNumber(operationCost);

    profitMargin = key == "profit_margin" ? numberValue : profitMargin;
    supplyPrice = key == "physical_cost" ? numberValue : supplyPrice;
    operationCost = key == "operative_cost" ? numberValue : operationCost;

    const priceRecommended = supplyPrice + profitMargin + operationCost;
    this.authorized_prices.controls[position]
      .get("recommended_price")
      .patchValue(priceRecommended ?? 0);
    this.onLeadingZero(event);
  }

  calculateRecommendedPrice(
    profitMargin: number | string,
    physicalCost: number | string,
    operationCost: number | string
  ) {
    if (typeof profitMargin === "string")
      profitMargin = this.convertToNumber(profitMargin);

    if (typeof physicalCost === "string")
      physicalCost = this.convertToNumber(physicalCost);

    if (typeof operationCost === "string")
      operationCost = this.convertToNumber(operationCost);

    const priceRecommended = physicalCost + profitMargin + operationCost;

    if (isNaN(priceRecommended)) return 0;
    return priceRecommended;
  }

  convertToNumber(value: string): number {
    let numberValue = 0;
    if (typeof value === "string") {
      value = value.replace(",", "").replace("$", "").replace(" ", "");
      numberValue = Number(parseFloat(value));
    }
    return numberValue;
  }

  onLeadingZero(event: Event): void {
    const num = (event.target as HTMLInputElement).value
      .replace("$", "")
      .replace(",", "")
      .replace(" ", "");
    const dec = num.split(".")[1];
    const len = dec && dec.length > 2 ? dec.length : 2;
    (event.target as HTMLInputElement).value = `$ ${Number(num).toFixed(len)}`;
  }

  leadingZero(num: string): string {
    const dec = num?.split(".")[1];

    if (!dec && num == "0") return "0.00";
    else if (!dec && num != "0") return `${num}.00`;

    const len = dec && dec?.length > 2 ? dec?.length : 2;
    const fixed = Number(num)?.toFixed(len);
    return fixed;
  }

  openSnack(message: string, color: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
    });
  }

  userWithPrivileges(): boolean {
    const role = this.currentUser.roles[0]?.id;

    return role == 1 || role == 2;
  }
}
