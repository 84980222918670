import { Component, ViewChild } from "@angular/core";
import { ModalConfirmationComponent } from "../modals/modal-confirmation/modal-confirmation.component";
import { Existence } from "app/models/existence";
import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";
import { MatDialog } from "@angular/material/dialog";
import { FormexistenceComponent } from "./formexistence/formexistence.component";
import { InventoryService } from "app/apiServices/inventory.service";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Tank } from "app/models/tank";

@Component({
  selector: "app-existence",
  templateUrl: "./existence.component.html",
  styleUrls: ["./existence.component.css"],
})
export class ExistenceComponent {

  temp: Existence[] = [];
  showAlert = false;
  form: FormGroup;

  @ViewChild(DatatableComponent) table: DatatableComponent;

  ColumnMode = ColumnMode;
  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private inventorySvc: InventoryService
  ) {
    this.form = this.fb.group({
      rowsPerPage: [10],
    });
    // this.temp = [...this.temp];
  }

  ngOnInit() {
    this.getData();
  }

  updateFilter(event: Event) {
    const val: string = (event.target as HTMLInputElement).value.toLowerCase();
    const temp: Existence[] = this.temp.filter((d: any) => {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.temp = temp;
    this.table.offset = 0;
  }

  openDialog(data: Existence, action: string) {
    const dialogRef = this.dialog.open(FormexistenceComponent, {
      width: "600px",
      data: {
        action,
        data,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.method == "store") {
        if (result.status === 200) this.showAlert = true;
        // result.data.id = this.temp.length + 2;
        // this.temp = [...this.temp, result.data];
      } else {
        if (result) {
          if (result.status === 200) this.showAlert = true;
          // const index = this.temp.findIndex((d) => d.id === result.data.id);
          // this.temp[index] = result.data;
          // this.temp = [...this.temp];
        }
      }
      this.getData();
    });
  }

  openDialogDelete(data: Existence) {
    const dialogRef = this.dialog.open(ModalConfirmationComponent, {
      width: "600px",
      data: {
        title: "¿Estas seguro de desactivar esta existencia?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.inventorySvc.deleteExistence(data).subscribe({
          next: (v) => {
            this.getData();
          },
          error: (e) => {
            //console.log(e);
          },
        });
      }
    });
  }

  onLimitChange(event: Event): void {
    const limit = (event.target as HTMLSelectElement).value;
    this.changePageLimit(limit);
    this.table.limit = this.form.controls.rowsPerPage.value;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor(
          (this.table.rowCount - 1) / this.table.limit
        );
      }
    });
  }

  changePageLimit(limit: any): void {
    this.form.controls.rowsPerPage.setValue(parseInt(limit, 10));
  }

  closeAlert(event: boolean) {
    this.showAlert = event;
  }

  delete(data: Existence) {
    this.openDialogDelete(data);
  }

  getData(): void {
    this.inventorySvc.getExistence().subscribe({
      next: (v) => {
        this.temp = v.data;
      },
      error: (e) => {
        //console.log(e);
      },
    });
  }
}
