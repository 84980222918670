<div class="container bg-white mx-auto px-4 h-full py-4 overflow-hidden">
  <div class="justify-between rounded-t mb-0 px-4 py-3 border-0 pt-6 bg-white">
    <div class="text-center">
      <h3 class="font-semibold text-lg">
        {{ edit ? "Actualizar" : "Agregar" }} Comisión
      </h3>
    </div>
  </div>
  <form class="bg-white" [formGroup]="form">
    <div class="relative w-full mb-3 flex">
      <div class="container px-2">
        <label
          class="block uppercase text-slate-600 text-xs font-bold mb-2"
          htmlFor="client_id"
        >
          Cliente
        </label>
        <select
          name="client_id"
          formControlName="client_id"
          [ngClass]="{
            'border-red-500': form.controls.client_id.invalid,
            'focus:border-red-500': form.controls.client_id.invalid,
            'focus:ring-red-500': form.controls.client_id.invalid
          }"
          class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        >
          <option *ngFor="let item of clients" [ngValue]="item.clients[0].id">
            {{ item.legal_name }}
          </option>
        </select>
      </div>

      <div class="container px-2">
        <label
          class="block uppercase text-slate-600 text-xs font-bold mb-2"
          htmlFor="user_id"
        >
          Vendedor
        </label>
        <select
          name="user_id"
          formControlName="user_id"
          [ngClass]="{
            'border-red-500': form.controls.user_id.invalid,
            'focus:border-red-500': form.controls.user_id.invalid,
            'focus:ring-red-500': form.controls.user_id.invalid
          }"
          class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        >
          <option *ngFor="let vendor of vendors" [ngValue]="vendor.id">
            {{ vendor.name }} {{ vendor.lastname }}
          </option>
        </select>
      </div>

      <div class="container px-2">
        <label
          class="block uppercase text-slate-600 text-xs font-bold mb-2"
          htmlFor="cost_per_liter"
        >
          Comisión base
        </label>
        <input
          type="text"
          name="cost_per_liter"
          formControlName="cost_per_liter"
          [ngClass]="{
            'border-red-500': form.controls.cost_per_liter.invalid,
            'focus:border-red-500': form.controls.cost_per_liter.invalid,
            'focus:ring-red-500': form.controls.cost_per_liter.invalid
          }"
          prefix="$ "
          mask="separator.6"
          class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          (change)="onLeadingZero($event)"
        />
        <div *ngFor="let validation of validationMessages.cost_per_liter">
          <span
            *ngIf="formService.field(form, validation.type, 'cost_per_liter')"
            class="mt-2 text-sm text-red-500"
          >
            {{ validation.message }}
          </span>
        </div>
      </div>
    </div>

    <div class="relative w-full mb-3 flex">
      <div class="container px-2">
        <label
          class="block uppercase text-slate-600 text-xs font-bold mb-2"
          htmlFor="product_id"
        >
          Producto
        </label>
        <ng-multiselect-dropdown
          name="product_id"
          formControlName="product_id"
          class="w-2/3 z-70"
          [placeholder]="'Selecciona producto(s)'"
          [(ngModel)]="selectedProducts"
          [settings]="dropdownProductsSettings"
          maxHeight="10"
          [data]="products"
          noDataLabel="No hay datos"
        >
        </ng-multiselect-dropdown>
      </div>
    </div>

    <div class="flex w-full justify-end">
      <button
        class="bg-slate-500 text-white active:bg-slate-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        (click)="closeModal()"
      >
        Cancelar
      </button>
      <button
        class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        [ngClass]="{ 'disabled:opacity-25': form.invalid }"
        [disabled]="form.invalid"
        (click)="save()"
      >
        {{ edit ? "Actualizar" : "Agregar" }}
      </button>
    </div>
  </form>
</div>
