import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-purchase-detail',
  templateUrl: './purchase-detail.component.html',
  styleUrls: ['./purchase-detail.component.css']
})
export class PurchaseDetailComponent {
  dataSource: any;
  form: FormGroup;
  dialogTitle = "Detalle Compra";

  constructor(
    private dialogRef: MatDialogRef<PurchaseDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private fb: FormBuilder
  ) {
    if (data) {
      this.dataSource = data.data;
      this.form = this.createForm();
    }
  }

  createForm() {
    return this.fb.group({
      load_date: [{ value: this.dataSource.load_date, disabled: true }],
      load_time: [{ value: this.dataSource.load_time, disabled: true }],
      end_date: [{ value: this.dataSource.end_date, disabled: true }],
      end_time: [{ value: this.dataSource.end_time, disabled: true }],
      agreed_price: [{ value: this.dataSource.agreed_price, disabled: true }],
      route_price: [
        { value: this.dataSource.transportist_route_price ? this.dataSource.transportist_route_price.cost: 0.0, disabled: true },
      ],
      shipping_price: [
        { value: this.dataSource.transportist_route_price.cost * this.dataSource.sold_liters, disabled: true },
      ],
      total_price_liter: [
        { value: this.dataSource.total_price_liter, disabled: true },
      ],
      sold_liters: [{ value: this.dataSource.sold_liters, disabled: true }],
    });
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
