import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ClientsService } from "app/apiServices/clients.service";
import { InventoryService } from "app/apiServices/inventory.service";
import { NotificationsComponent } from "app/components/alerts/notifications/notifications.component";
import { FormValidatorService } from "app/core/service/form-validator.service";
import { Product } from "app/models/product";

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.css"],
})
export class FormComponent implements OnInit {
  form: FormGroup;
  dataSource: any;
  edit = false;
  dialogTitle: string;

  validationMessages = {
    cost: [{ type: "required", message: "Ingrese el costo" }],
    product_id: [{ type: "required", message: "Seleccione el producto" }],
    route_id: [{ type: "required", message: "Ingrese la Ruta" }],
    transportist_id: [
      { type: "required", message: "Selecciona un Transportista" },
    ],
    distribution_cost: [
      { type: "required", message: "Ingrese el costo de distribucion" },
    ],
  };

  products: Product[];
  routes: any[];
  transportist_values: any[] = [];
  selectedProducts = [];
  dropdownSettings = {};
  dropdownProductsSettings: any = {};

  constructor(
    private dialogRef: MatDialogRef<FormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private _snack: MatSnackBar,
    public formService: FormValidatorService,
    private productService: InventoryService,
    private clientService: ClientsService
  ) {
    this.data = data;
    this.dropdownSettings = {
      singleSelection: data.action === "edit",
      idField: "id",
      textField: "name",
      noDataAvailablePlaceholderText: "No hay datos",
      selectAllText: "Seleccionar Todas",
      unSelectAllText: "Deseleccionar Todas",
      searchPlaceholderText: "Buscar",
      itemsShowLimit: 6,
      allowSearchFilter: true,
    };
    this.dropdownProductsSettings = {
      ...this.dropdownSettings,
    };

    if (data.action === "edit") {
      this.dialogTitle = "Editar Registro";
      this.dataSource = data.data;
      this.selectedProducts = [data.data.product];
      this.edit = true;
    } else {
      this.dialogTitle = "Agregar Registro";
      this.dataSource = {};
    }
    this.form = this.createForm();
  }
  ngOnInit(): void {
    this.productService.getProducts().subscribe((r) => {
      this.products = r.data.filter((p) => p.active);
    });

    this.clientService.readRoutes().subscribe((r) => {
      this.routes = r.data.filter((r) => r.active);
    });

    this.clientService.readCompanies().subscribe((r) => {
      r.data.forEach((element) => {
        if (element.transportists.length > 0 && element.transportists[0].active)
          this.transportist_values.push(element);
      });
    });
  }

  createForm(): FormGroup {
    if (this.edit) {
      return this.fb.group({
        id: [this.dataSource.id],
        product_id: [this.dataSource.product.id, [Validators.required]],
        route_id: [this.dataSource.route.id, [Validators.required]],
        transportist_id: [
          this.dataSource.transportist.id,
          [Validators.required],
        ],
        cost: [
          this.leadingZero(this.dataSource.cost.toString()),
          [Validators.required],
        ],
        distribution_cost: [
          this.leadingZero(this.dataSource.distribution_cost.toString()),
          [Validators.required],
        ],
      });
    } else {
      return this.fb.group({
        id: [this.dataSource.id],
        product_id: [, [Validators.required]],
        route_id: [, [Validators.required]],
        transportist_id: [, [Validators.required]],
        cost: [, [Validators.required]],
        distribution_cost: [, [Validators.required]],
      });
    }
  }

  onLeadingZero(event: Event): void {
    const num = (event.target as HTMLInputElement).value
      .replace("$", "")
      .replace(",", "")
      .replace(" ", "");
    const dec = num.split(".")[1];
    const len = dec && dec.length > 2 ? dec.length : 2;
    (event.target as HTMLInputElement).value = `$ ${Number(num).toFixed(len)}`;
  }

  leadingZero(num: string): string {
    const dec = num.split(".")[1];

    if (!dec && num == "0") return "0.00";
    else if (!dec && num != "0") return `${num}.00`;

    const len = dec && dec.length > 2 ? dec.length : 2;
    const fixed = Number(num).toFixed(len);
    return fixed;
  }

  save(): void {
    if (this.form.valid) {
      this.dataSource = this.form.value;
      this.dataSource.cost = Number(parseFloat(this.dataSource.cost));
      this.dataSource.distribution_cost = Number(
        parseFloat(this.dataSource.distribution_cost)
      );

      this.storeUpdate(this.edit ? "update" : "store");
    } else {
      this.formService.allFields(this.form);
      this.openSnack("Datos incorrectos", "snack-error");
    }
  }

  storeUpdate(method: string): void {
    if (method === "store") {
      const transportistRoutePrices = {
        transportist_route_prices: this.form.value.product_id.map((product) => {
          return {
            route_id: this.form.value.route_id,
            transportist_id: this.form.value.transportist_id,
            product_id: product.id,
            cost: this.form.value.cost,
            distribution_cost: this.form.value.distribution_cost,
          };
        }),
      };

      this.clientService.createPRT(transportistRoutePrices).subscribe(
        (r) => {
          this.closeModal();
        },
        (error) => {
          this.openSnack(
            "Ha ocurrido un error al guardar la ruta del transportista",
            "snack-error"
          );
        }
      );
    } else {
      this.dataSource.product_id = this.form.value.product_id[0]?.id ?? null;
      this.clientService.updatePRT(this.dataSource).subscribe(
        (r) => {
          this.closeModal();
        },
        (error) => {
          this.openSnack(
            "Ha ocurrido un error al actualizar la ruta del transportista",
            "snack-error"
          );
        }
      );
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
      verticalPosition: "top",
    });
  }
}
