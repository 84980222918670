import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit{
  constructor(
    private router: Router,
  ){}

  ngOnInit(): void {
  }

  toRoute(id){
    switch(id){
      case id = 4: {
        this.router.navigate(['admin/units-in-trancit'])
        break;
      }
      case id = 3: {
        this.router.navigate(['admin/units-in-plant'])
        break;
      }
      case id = 2: {
        this.router.navigate(['admin/report-day-sales'])
        break;
      }
      case id = 1: {
        this.router.navigate(['admin/report-inventory'])
        break;
      }
    }
  }
}
