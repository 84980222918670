import { Component, ViewChild } from "@angular/core";
import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";
import { LogService } from "app/apiServices/log.service";
import { Page } from "app/models/page";
import { LogsDiffComponent } from "../logs-diff/logs-diff.component";
import { MatDialog } from "@angular/material/dialog";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Log } from "app/models/log";

@Component({
  selector: "app-logs-list",
  templateUrl: "./logs-list.component.html",
  styleUrls: ["./logs-list.component.css"],
})
export class LogsListComponent {
  page = new Page();
  rows = new Array<Log>();
  tmp = new Array<Log>();
  loading: boolean = false;
  form: FormGroup;

  columnMapValues = [
    ["id", "id"],
    ["usuario", "user_id"],
    ["tabla", "table"],
    ["tipo", "type"],
    ["creado", "created_at"],
  ] as const;
  columnMap: Map<string, string> = new Map(this.columnMapValues);
  ColumnMode = ColumnMode;

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private logService: LogService
  ) {
    this.form = this.fb.group({
      rowsPerPage: [10],
    });
    this.page.pageNumber = 0;
    this.page.size = this.form.controls.rowsPerPage.value;
  }

  ngOnInit() {
    this.setPage({ offset: 0 });
  }

  setPage(pageInfo) {
    this.loading = true;
    this.page.pageNumber = pageInfo.offset;
    this.logService
      .get(this.page.pageNumber + 1, this.page.size)
      .subscribe((pagedData) => {
        const data = pagedData.data.data;
        const meta = pagedData.data.meta;
        const page: Page = {
          size: meta.per_page,
          totalElements: meta.total,
          totalPages: 0,
          pageNumber: meta.current_page - 1,
        };
        this.rows = data;
        this.tmp = data;
        this.page = page;
        this.loading = false;
        console.log(this.rows);
      });
     
  }

  onSort(event: any): void {
    const sort = event.sorts[0];
    const column = this.columnMap.get(sort.prop);

    if (column) {
      this.rows.sort((a, b) => {
        if (typeof a[column] === "number") {
          if (sort.dir === "asc") return a[column] - b[column];
          else return b[column] - a[column];
        } else if (typeof a[column] === "string") {
          return (
            a[column].localeCompare(b[column]) * (sort.dir === "desc" ? -1 : 1)
          );
        }
      });

      this.rows = [...this.rows];
    }
  }

  changePageLimit(limit: any): void {
    this.form.controls.rowsPerPage.setValue(parseInt(limit, 10));
  }

  onLimitChange(event: Event): void {
    const limit = (event.target as HTMLSelectElement).value;
    this.changePageLimit(limit);
    this.table.limit = this.form.controls.rowsPerPage.value;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor(
          (this.table.rowCount - 1) / this.table.limit
        );
      }
    });
  }

  updateFilter(event: Event) {
    const val: string = (event.target as HTMLInputElement).value.toLowerCase();
    const temp: Log[] = this.tmp.filter((d: Log) => {
      return (
        d.table.toLowerCase().indexOf(val) !== -1 ||
        d.type.toLowerCase().indexOf(val) !== -1 ||
        !val
      );
    });
    this.rows = temp;
    this.table.offset = 0;
  }

  show(data: any) {
    const dialogRef = this.dialog.open(LogsDiffComponent, {
      width: "80vw",
      data: {
        data_from: data?.data_from,
        data_to: data?.data_to,
      },
    });
  }
}
