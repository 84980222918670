import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { ClientsService } from 'app/apiServices/clients.service';
import { ChangeCFIDComponent } from '../change-cfid/change-cfid.component';
import { Location } from '@angular/common'

@Component({
  selector: 'app-transportists',
  templateUrl: './transportists.component.html',
  styleUrls: ['./transportists.component.css']
})
export class TransportistsComponent implements OnInit{
  elements: any = [];
  tempo: any = [];
  @ViewChild(DatatableComponent) table: DatatableComponent;
  ColumnMode = ColumnMode;
  data: any [] = []
  temp: any [] = []
  showAlert = false;
  form: FormGroup;
  alertTitle: string;
  constructor (
    private apiService: ClientsService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private location: Location
  ) {
    this.form = this.fb.group({
      rowsPerPage: [10],
    });
    this.temp = this.data
  }
  
  ngOnInit(): void {
    this.getData()
  }

  getData(): void {
    this.apiService.readCompanies().subscribe((r) => {
      this.elements = r.data
      this.elements.forEach(element => {
        if(element.transportists.length >= 1 ){
          this.tempo.push(element)
        }
      });
      this.data = this.tempo
      this.temp = this.data
    })
  }

  updateFilter(event: Event) {
    const val: string = (event.target as HTMLInputElement).value.toLowerCase();
    const temp: any[] = this.temp.filter((d: any) => {
      return d.legal_name.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.data = temp;
    this.table.offset = 0;
  }

  changePageLimit(limit: any): void {
    this.form.controls.rowsPerPage.setValue(parseInt(limit, 10));
  }

  closeAlert(event: boolean) {
    this.showAlert = event;
  }

  onLimitChange(event: Event): void {
    const limit = (event.target as HTMLSelectElement).value;
    this.changePageLimit(limit);
    this.table.limit = this.form.controls.rowsPerPage.value;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor(
          (this.table.rowCount - 1) / this.table.limit
        );
      }
    });
  }

  openCFID(data: any){
    const dialogRef = this.dialog.open(ChangeCFIDComponent, {
      width: "600px",
      data: {
        data
      }
    })

    dialogRef.afterClosed().subscribe((r)=>{
      this.getData()
    })
  }

  return(){
    this.location.back()
  }
}
