import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DiffEditorModel } from "ngx-monaco-editor-v2";

@Component({
  selector: "app-logs-diff",
  templateUrl: "./logs-diff.component.html",
  styleUrls: ["./logs-diff.component.css"],
})
export class LogsDiffComponent {
  options = {
    theme: "vs-dark",
    autoIndent: true,
    formatOnPaste: true,
    formatOnType: true,
    readOnly: false,
  };
  originalModel: DiffEditorModel;
  modifiedModel: DiffEditorModel;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.originalModel = {
      code: JSON.stringify(this.data?.data_from),
      language: "json",
    };

    this.modifiedModel = {
      code: JSON.stringify(this.data?.data_to),
      language: "json",
    };
  }

  onInit(editor: any) {
    if (editor) {
      editor._editors.original.updateOptions({
        readOnly: false,
      });

      setTimeout(() => {
        editor._editors.modified
          .getAction("editor.action.formatDocument")
          .run();
        editor._editors.original
          .getAction("editor.action.formatDocument")
          .run();
      }, 100);
    }
  }
}
