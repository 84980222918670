import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InventoryService } from 'app/apiServices/inventory.service';
import { OcrService } from 'app/apiServices/ocr.service';
import { NotificationsComponent } from 'app/components/alerts/notifications/notifications.component';
import { FormComponent } from 'app/components/sections/admin/roles/dialogs/form/form.component';
import { FormValidatorService } from 'app/core/service/form-validator.service';
import { Product } from 'app/models/product';
import { Tank } from 'app/models/tank';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-form-tank',
  templateUrl: './form-tank.component.html',
  styleUrls: ['./form-tank.component.css']
})
export class FormTankComponent implements OnInit, OnDestroy {
  form: FormGroup;
  dataSource: Tank;
  edit = false;
  dialogTitle: string;
  selectedProducts: number[] = [];

  validationMessages = {
    tank: [{ type: "required", message: "Campo requerido" }],
    capacity: [{ type: "required", message: "Ingrese una cantidad de Capacidad" }],
    measured: [{ type: "required", message: "Ingrese una cantidad de Capacidad" }],
    quantity: [{ type: "required", message: "Ingrese la cantidad que tiene actualmente" }],
    product: [{ type: "required", message: "Seleccione un producto" }]
  };

  productsData: Product[] = []

  public image: any;
  public listSubscribers: any = [];

  obj = {
    requests: [
      {
        image: {
          content: '',
        },
        features: [
          {
            type: 'TEXT_DETECTION',
          },
        ],
      },
    ],
  };

  constructor(
    private dialogRef: MatDialogRef<FormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public formService: FormValidatorService,
    private inventorySvc: InventoryService,
    private _snack: MatSnackBar,
    private ocrService: OcrService
  ) {
    this.data = data;

    if (data.action === "edit") {
      this.dialogTitle = "Editar Tanque";
      this.dataSource = {
        observations: data.data.tank_products[0].observations,
        ...data.data,
        products: data.data.products[0].id
      };
      this.edit = true;
    } else {
      this.dialogTitle = "Agregar Tanque";
      this.dataSource = new Tank({});
    }
    this.form = this.createForm();
    this.getData()
  }

  createForm(): FormGroup {
    if(!this.edit)
      return this.fb.group({
        id: [this.dataSource.id],
        tank: [this.dataSource.tank, [Validators.required]],
        capacity: [this.dataSource.capacity, [Validators.required]],
        quantity: [this.dataSource.quantity, []],
        measured: [this.dataSource.measured, []],
        products: [this.dataSource.products, [Validators.required]],
        observations: [this.dataSource.observations, [Validators.required]],
      });
    else
      return this.fb.group({
        id: [this.dataSource.id],
        tank: [this.dataSource.tank, [Validators.required]],
        capacity: [this.dataSource.capacity, [Validators.required]],
        quantity: [this.dataSource.quantity, []],
        measured: [this.dataSource.measured, []],
        products: [this.dataSource.products, [Validators.required]],
        observations: [this.dataSource.observations, [Validators.required]],
        reasons: [this.dataSource.reasons, []]
      })
  }

  save(): void {
    if (this.form.valid) {
      this.dataSource = this.form.value;
      this.storeUpdate(this.edit ? "update" : "store");
    } else {
      this.formService.allFields(this.form);
      alert("Datos incorrectos");
    }
  }

  storeUpdate(method: string): void {
    const data = {
      method,
      data: this.dataSource,
      status: 200,
    };

    let obj = {
      ...this.dataSource,
      products: [this.dataSource.products]
    }

    if (method === "store") {
      this.inventorySvc.sendTanks(obj).subscribe({
        next: (v) => {
          // console.log(v)
          this.openSnack('snack-success', v.message)
          this.dialogRef.close(data);
        },
        error: (e) => {
          // console.log(e)
          this.openSnack('snack-error', e.error.message)
        }
      })
    } else {
      this.inventorySvc.updateTanks(obj).subscribe({
        next: (v) => {
          // console.log(v)
          this.openSnack('snack-success', v.message)
          this.dialogRef.close(data);
        },
        error: (e) => {
          // console.log(e)
          this.openSnack('snack-error', e.error.message)
        }
      })
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  isSelected(productId: number): boolean {
    return this.selectedProducts.includes(productId);
  }

  toggleSelection(productId: number): void {
    const index = this.selectedProducts.indexOf(productId);

    if (index === -1) {
      this.selectedProducts.push(productId);
    } else {
      this.selectedProducts.splice(index, 1);
    }

    // Actualiza el valor del formulario
    this.form.controls.products.setValue(this.selectedProducts);
  }

  getData(): void {
    this.inventorySvc.getProducts().subscribe({
      next: (v) => {
        this.productsData = v.data.filter(item => item.active)
      },
      error: (e) => {
        //console.log(e)
      }
    })
  }

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000
    })
  }

  ngOnInit(): void {
    this.listObserver()
  }

  ngOnDestroy(): void {
    this.listSubscribers.forEach((a) => a.unsubscribe());
  }

  onFileSelected = (image: any) => {
    this.ocrService.cbImage.emit(image);
  }

  listObserver = () => {
    const observer1$ = this.ocrService.cbImage.subscribe(async (value) => {
      this.image = value;
      await this.initialization();
      // this.sinPeticion()
    });

    const observer2$ = this.ocrService.cbText
      .subscribe(({ text }) => {
        this.form.controls['quantity'].setValue(text);
      });

    this.listSubscribers = [observer1$, observer2$];
  };

  initialization = async () => {
    try {
      const base64Data = await this.ocrService.getBase64File(this.image).toPromise();

      const base64DataShort = base64Data.split(',')[1];
      this.obj.requests[0].image.content = base64DataShort;

      const data = await this.ocrService.getData(this.obj).toPromise();

      // Descomenta esta sección para manejar la respuesta
      const dataReserv = data;
      const linesArray = data.responses[0].textAnnotations[0].description.split('\n');
      const nextIndex = this.findIndexToRetrieveNextValue(linesArray);

      if (nextIndex !== -1 && nextIndex < linesArray.length) {
        const nextValue = linesArray[nextIndex];
        const vertices = data.responses[0].textAnnotations.find((item) => item.description === String(nextValue));

        // this.draw(this.vertices);
        this.ocrService.cbText.emit({ text: nextValue });
        //   this.loadingPercentage = false;
      } else {
        //console.log('No se encontraron valores adecuados en el array.');
      }
    } catch (error) {
      // Manejar errores aquí
      //console.log(error);
    }
  };

  sinPeticion() {
    const rute = 'assets/json/example5.json';

    this.ocrService.readJSON(rute).subscribe({
      next: (v) => {
        // console.log(v);

        // OBTENER DATOS
        const dataReserv = v;
        // ARRAY POR SALTOS DE LINEA
        const linesArray = v.textAnnotations[0].description.split('\n');
        // METODO PARA ENCONTRAR PALABRAS
        const nextIndex = this.findIndexToRetrieveNextValue(linesArray);

        if (nextIndex !== -1 && nextIndex < linesArray.length) {
          const nextValue = linesArray[nextIndex];
          // console.log(`El próximo valor es: ${nextValue}`);

          const vertices = v.textAnnotations.find((item) => {
            // console.log(item);
            // console.log(nextValue);
            return item.description === String(nextValue);
          });

          // this.draw(this.vertices);
          // console.log('FINALIZO ----------------->', data);

          //console.log(nextValue)
          this.ocrService.cbText.emit({ text: nextValue });
          // this.loadingPercentage = false;
        } else {
          //console.log('No se encontraron valores adecuados en el array.');
        }
      },
    });
  }

  findIndexToRetrieveNextValue(arr: string[]): number {
    // DEFINIR TIPO DE IMAGEN
    const typeImage = arr.findIndex(
      (item) => item.includes('Gross L') || item.includes('MODEL TCS')
    );

    // TIPO DE IMAGEN
    if (typeImage !== -1) {
      // console.log('Nuevo typo de imagenes');

      // console.log(arr);

      const firtsC = arr.findIndex((item) => item.includes('pqrs'));

      // SI ENCONTRO PQRS
      if (firtsC !== -1) {
        // console.log('Encontro PQRS');

        let secondC = -1;

        // BUSCA GHI DESDE DEL INDIC DE PQRS HACIA ATRAS
        for (let i = firtsC; i >= 0; i--) {
          if (arr[i].includes('ghi')) {
            // console.log(`Palabra encontrada en el índice ${i}`);
            secondC = i;
            break; // Detener el bucle cuando encuentre la palabra objetivo
          }
        }

        let numberBeforeGhi;

        for (let i = secondC - 1; i >= 0; i--) {
          if (!isNaN(Number(arr[i]))) {
            const parsedNumber = parseFloat(arr[i]);
            if (!Number.isNaN(parsedNumber) && Number.isInteger(parsedNumber) && ![2, 5, 8].includes(parsedNumber)) {
              numberBeforeGhi = i;
              break; // Detener el bucle cuando encuentre el número anterior sin decimales
            }
          }
        }
        return numberBeforeGhi;

      }
      // NO ENCONTRO PQRS
      else {
        // BUSCA GHI(FALTA TERMINAR)
        const secondC = arr.findIndex((item) => item.includes('ghi'));
        // console.log('Encontro GHI');

        for (let i = secondC; i >= 0; i--) {
          const numeroConvertido = parseInt(arr[i], 10);
          if (!isNaN(numeroConvertido) && arr[i] === numeroConvertido.toString()) {
            // console.log(`Número entero encontrado en el índice ${i}: ${numeroConvertido}`);
            return i; // El string representa un número entero
          }
        }
        return -1;

      }
    } else {
      // CASO PARA NUMERO CON DOS DECIMALES
      if (this.floadTwoDecimals(arr[0])) {
        return 1;
      } else {
        // BUSCAR LA PALABA ESTATUS
        const indexEstatus = arr.findIndex(
          (item) => item.includes('ESTATUS') || item.includes('STATUS')
        );
        // VERIFICAR SI LA ENCONTRO
        if (indexEstatus !== -1) {
          // BUSCAR LA PALABRA GASOLINA
          if (
            arr[indexEstatus + 1].includes('GASOLINA') ||
            arr[indexEstatus + 1].includes('GASOLINE')
          ) {
            // CONVERTIR A FLOTANTE
            const valueFloat = parseFloat(arr[indexEstatus + 2]);

            // VERIFICAR SI ES UN FLOTANTE
            if (!isNaN(valueFloat)) {
              // console.log('El string es un número de punto flotante:', valueFloat);
              return indexEstatus + 2;
            } else {
              //console.log('El string no es un número de punto flotante.');
              return -1;
            }
          } else {
            // NO SE ENCONTRO LA PALABA GASOLINA, POR LO TANTO ES UN FLOTANTE EL QUE SIGUE
            const valueFloat = parseFloat(arr[indexEstatus + 1]);

            // VERIFICAR SI ES UN FLOTANTE
            if (!isNaN(valueFloat)) {
              // console.log('El string es un número de punto flotante:', valueFloat);
              return indexEstatus + 1;
            } else {
              //console.log('El string no es un número de punto flotante.');
              return -1;
            }
          }
        } else {
          const indexGasolina = arr.findIndex(
            (item) => item.includes('GASOLINA') || item.includes('GASOLINE')
          );

          if (indexGasolina !== -1) {
            // CONVERTIR A FLOTANTE
            const valueFloat = parseFloat(arr[indexGasolina + 1]);

            // VERIFICAR SI ES UN FLOTANTE
            if (!isNaN(valueFloat)) {
              // console.log('El string es un número de punto flotante:', valueFloat);
              return indexGasolina + 1;
            } else {
              //console.log('El string no es un número de punto flotante.');
              return -1;
            }
          } else {
            let valorFlotante = null;

            for (const elemento of arr) {
              const numero = parseFloat(elemento);
              if (!isNaN(numero)) {
                valorFlotante = numero;
                break; // Salir del bucle una vez que se encuentre el primer valor válido
              }
            }

            if (valorFlotante !== null) {
              // console.log(
              //   `El primer valor de punto flotante encontrado es: ${valorFlotante}`
              // );
              return arr.findIndex((item) => item === String(valorFlotante));
            } else {
              // console.log(
              //   'No se encontró ningún valor de punto flotante en el array.'
              // );
              return -1;
            }
          }
        }
      }
    }
  }

  floadTwoDecimals(inputString: string): boolean {
    // Usar una expresión regular para verificar si el string es un número decimal con dos decimales
    const regex = /^\d+\.\d{2}$/;

    if (regex.test(inputString)) {
      // El string coincide con el formato de número decimal con dos decimales
      return true;
    } else {
      // El string no coincide con el formato deseado
      return false;
    }
  }
}
