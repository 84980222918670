import { Pipe, PipeTransform } from "@angular/core";
import { GetTank } from "app/models/tank";

@Pipe({
  name: "total",
  standalone: true,
})
export class TotalPipe implements PipeTransform {
  transform(tanks: GetTank[], productId: number): number {
    return this.getTotal("required_quantity", tanks, productId);
  }

  getTotal(colName: string, dataSource: GetTank[], productId: number): number {
    const total = dataSource
      ?.filter(
        (row) =>
          row?.products?.filter((product) => product?.id === productId)?.length > 0
      )
      ?.map((row) => {
        let quantity = row[colName];
        if (typeof row[colName] == "string" && row[colName].includes(","))
          quantity = Number(parseFloat(quantity.replace(",")));
        else if (typeof row[colName] == "string")
          quantity = Number(parseFloat(quantity));
        return quantity;
      })
      ?.reduce((acc, value) => (value ? acc + Number(value) : acc), 0);
    return total?.toFixed(2);
  }
}

@Pipe({
  name: "totalPurchase",
  standalone: true,
})
export class TotalPipePurchase implements PipeTransform {
  transform(tanks: GetTank[], productId: number): number {
    return this.getTotal("required_quantity", tanks, productId);
  }

  getTotal(colName: string, dataSource: GetTank[], productId: number): number {
    const total = dataSource
      ?.filter(
        (row) =>
          row?.purchase_detail_product_id === productId
      )
      ?.map((row) => {
        let quantity = row[colName];
        if (typeof row[colName] == "string" && row[colName].includes(","))
          quantity = Number(parseFloat(quantity.replace(",")));
        else if (typeof row[colName] == "string")
          quantity = Number(parseFloat(quantity));
        return quantity;
      })
      ?.reduce((acc, value) => (value ? acc + Number(value) : acc), 0);
    return total?.toFixed(2);
  }

}
