<div>
  <div>
    <label class="block uppercase text-slate-600 text-xs font-bold mb-2">
      {{ title }}
    </label>
  </div>
  <div #timepickertranslated id="{{ idName }}" class="relative mb-3">
    <div>
      <input
        type="text"
        [ngClass]="{
          'focus:border-red-500': invalid,
          'focus:ring-red-500': invalid
        }"
        class="border-1 border-gray-300 block w-full rounded px-3 py-1.5 transition-all duration-200 ease-linear motion-reduce:transition-none"
        [value]="value"
      />
    </div>
  </div>
</div>
