<div class="grid grid-cols-2  h-98 overflow-auto">
  <div class="w-5/6 min-h-full h-full inline-block align-top ml-3.5 mr-3.5 mt-0 mb-3.5">
    <h2 class="block uppercase text-slate-600 text-md font-bold my-4">
      Productos
    </h2>

    <div
      cdkDropList
      #productList="cdkDropList"
      [cdkDropListData]="listDataProducts"
      [cdkDropListConnectedTo]="[productListCompose]"
      class="cdk-list h-full rounded block border-solid 
      border-gray-300 bg-gray-50 ransition-transform duration-250 ease-out"
      (cdkDropListDropped)="drop($event)">
      <div
        class="cdk-box h-1 rounded text-slate-600 flex flex-row 
        items-center justify-between box-border border-2 last:border-4
        cursor-move text-sm px-2.5 py-5 border-solid border-gray-300 bg-white"
        *ngFor="let item of listDataProducts"
        cdkDrag>
        {{ item.name }}
      </div>
    </div>
    
  </div>

  <div class="w-5/6 min-h-full h-full inline-block align-top ml-4.5 mr-3.5 mt-0 mb-3.5">
    <h2 class="block uppercase text-slate-600 text-md font-bold my-4">
      Producto
    </h2>

    <div
      cdkDropList
      #productListCompose="cdkDropList"
      [cdkDropListData]="productDataCompose"
      [cdkDropListConnectedTo]="[productList]"
      class="cdk-list h-full rounded block border-solid 
      border-gray-300 bg-gray-50 ransition-transform duration-250 ease-out"
      (cdkDropListDropped)="drop($event)">
      <div
        class="cdk-box h-1 text-slate-600 flex flex-row 
        items-center rounded box-border border-2 last:border-4 
        cursor-move text-sm px-2.5 py-5 border-solid border-gray-300 bg-white"
        *ngFor="let item of productDataCompose"
        cdkDrag>
        <div class="w-3/4">
          {{ item.name }}
        </div>

        <div class="w-4/12">
          <input
            type="number"
            name="percentage"
            value="{{ getPercentage(item.id) }}"
            (keyup)="onBindProductMix($event, item)"
            class="border-2 px-1 py-1 placeholder-slate-300 text-slate-600 
            bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring 
            w-full ease-linear transition-all duration-150"/>
        </div>
      </div>
    </div>
  </div>
</div>
