import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router, RouterLinkActive } from "@angular/router";
import { CatalogServicesService } from "app/apiServices/catalog-services.service";
import { UserServicesService } from "app/apiServices/user-services.service";
import { Category } from "app/models/category";
import { RoleViews } from "app/models/role-view";
import { View } from "app/models/view";
import { DataService } from "app/services/data.service";
import { Animate } from "tw-elements";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
})
export class SidebarComponent implements OnInit {
  @ViewChild("collapsible") collapsible: ElementRef;
  @ViewChild("link") link: RouterLinkActive;

  categories: Category[];
  dataViews: any;
  dataCategories: any;

  roleId: number = 0;
  currentCategoryViewId: number = 1;
  isLoading: boolean = true;

  constructor(
    private router: Router,
    private userService: UserServicesService,
    private dataService: DataService,
    private apiService: CatalogServicesService
  ) {
    if (localStorage.getItem("categories")) {
      this.isLoading = false;
      this.categories = this.dataService.categoriesValue;
      this.currentView();
    } else {
      this.getRoleViews();
    }
  }

  ngOnInit() {
    this.dataService.categories.subscribe((data) => {
      this.categories = this.dataService.categoriesValue;
    });
  }

  private getRoleViews(): void {
    this.apiService.readRoleViews().subscribe(
      (res) => {
        const currentRoleViews = res.data.find(
          (role: RoleViews) =>
            role.id === this.dataService.userValue.roles[0].id
        );
        const currentCategories = currentRoleViews.views.map(
          (view: View) => view.category
        );
        const sortedCategories =
          this.distinctAndSortCategories(currentCategories);
        this.viewsByCategory(sortedCategories, currentRoleViews.views);
      },
      (error) => {
        //console.log(error);
      }
    );
  }

  private currentView(): void {
    if (!localStorage.getItem("table")) {
      const currentPath = this.router.url;
      for (const category of this.categories) {
        if (category.views.length > 0) {
          const view = category.views.find((view) => view.path === currentPath);
          if (view) {
            this.currentCategoryViewId = category.id;
            this.dataService.setViewValue(view.name);
            break;
          }
        }
      }
    } else {
      this.currentCategoryViewId = this.categories.find((category) =>
        category.views.find((view) => view.name == this.dataService.viewValue)
      ).id;
    }
  }

  distinctAndSortCategories(categories: Category[]): Category[] {
    const result = [];
    const map = new Map();
    for (const item of categories) {
      if (!map.has(item.id)) {
        map.set(item.id, true);
        result.push({
          id: item.id,
          name: item.name,
          order: item.order,
        });
      }
    }

    return result.sort((a: Category, b: Category) => {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    });
  }

  viewsByCategory(categories: Category[], views: View[]) {
    categories.forEach((category) => {
      category.views = views.filter(
        (view: View) =>
          view.category.id === category.id &&
          !view.path.includes("existence-types")
      );

      category.views = category.views.sort((a: View, b: View) => {
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      });
    });

    this.isLoading = false;
    this.categories = categories;
    this.dataService.setCategoriesValue(this.categories);
    this.currentView();
  }

  viewObj(view: View, categoryId: number) {
    this.dataService.setViewValue(view.name);
    this.currentCategoryViewId = categoryId;
  }

  toggleCollapse(event: Event, categoryId: number) {
    const target = event.target as HTMLElement;
    const classes = target.parentElement.nextElementSibling.classList;
    const element = document.querySelector(
      `#collapsible${categoryId}`
    ) as HTMLElement;

    if (classes.contains("hidden")) {
      new Animate(element, {
        animation: "[fade-in_1s_ease-in-out]",
        animationReset: true,
        animationStart: "onLoad",
      });
      classes.remove("hidden");
      classes.add("block");

      if (!target.tagName.includes("I")) {
        target.nextElementSibling.classList.remove("fa-caret-down");
        target.nextElementSibling.classList.add("fa-caret-up");
      } else {
        target.classList.remove("fa-caret-down");
        target.classList.add("fa-caret-up");
      }
    } else if (
      this.currentCategoryViewId != categoryId ||
      categoryId == this.currentCategoryViewId
    ) {
      new Animate(element, {
        animation: "[drop-in_0.5s]",
        animationReset: true,
        animationStart: "onLoad",
      });
      classes.remove("block");
      classes.add("hidden");

      if (!target.tagName.includes("I")) {
        target.nextElementSibling.classList.remove("fa-caret-up");
        target.nextElementSibling.classList.add("fa-caret-down");
      } else {
        target.classList.remove("fa-caret-up");
        target.classList.add("fa-caret-down");
      }
    }
  }
}
