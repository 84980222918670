import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InventoryService } from 'app/apiServices/inventory.service';
import { FormValidatorService } from 'app/core/service/form-validator.service';
import { ExistenceChangeTypes } from 'app/models/existence_types_change';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent {
  form: FormGroup;
  dataSource: ExistenceChangeTypes;
  edit = false;
  dialogTitle: string;
  validationMessages = {
    name: [{ type: "required", message: "Ingrese un nombre" }],
  };

  constructor(
    private dialogRef: MatDialogRef<FormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public formService: FormValidatorService,
    private inventorySvc: InventoryService
  ) {
    this.data = data;

    if (data.action === "edit") {
      this.dialogTitle = "Editar Tipo de Cambio";
      this.dataSource = data.data;
      this.edit = true;
    } else {
      this.dialogTitle = "Agregar Tipo de Cambio";
      this.dataSource = new ExistenceChangeTypes({});
    }
    this.form = this.createForm();
  }

  createForm(): FormGroup {
    return this.fb.group({
      id: [this.dataSource.id],
      name: [this.dataSource.name, [Validators.required]],
      description: [this.dataSource.description, []],
    });
  }

  save(): void {
    if (this.form.valid) {
      this.dataSource = this.form.value;
      this.storeUpdate(this.edit ? "update" : "store");
    } else {
      this.formService.allFields(this.form);
      alert("Datos incorrectos");
    }
  }

  storeUpdate(method: string): void {
    const data = {
      method,
      data: this.dataSource,
      status: 200,
    };

    if (method === "store") {
      this.inventorySvc.sendExistenceChangeTypes(this.dataSource).subscribe({
        next: (v) => {
          // console.log(v)
          this.dialogRef.close(data);
        },
        error: (e) => {
          //console.log(e)
        }
      })
    } else {
      this.inventorySvc.updatetExistenceChangeTypes(this.dataSource).subscribe({
        next: (v) => {
          // console.log(v)
          this.dialogRef.close(data);
        },
        error: (e) => {
          //console.log(e)
        }
      })
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
