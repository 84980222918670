import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { ClientsService } from "app/apiServices/clients.service";
import { InventoryService } from "app/apiServices/inventory.service";
import { SalesService } from "app/apiServices/sales.service";
import { UserServicesService } from "app/apiServices/user-services.service";
import { AlertComponent } from "app/components/alerts/alert/alert.component";
import { NotificationsComponent } from "app/components/alerts/notifications/notifications.component";
import { AddressesDialogComponent } from "app/components/companies/addresses-dialog/addresses-dialog.component";
import { FormValidatorService } from "app/core/service/form-validator.service";
import { InAdditionalRequirementRes } from "app/interfaces/additional.requirement";
import { InAddressRes } from "app/interfaces/address.interface";
import { AuthorizedPriceRes } from "app/interfaces/authorized.price.interface";
import { InComissionProductRes } from "app/interfaces/comission.product";
import { InCompaniesRes } from "app/interfaces/company.interface";
import { InLoadingTypeRes } from "app/interfaces/loading.type";
import { InOrderStatusRes } from "app/interfaces/order.status";
import { InPaymentMethodRes } from "app/interfaces/payment.method";
import { InProductRes } from "app/interfaces/product.interface";
import { InPumpMeasureRes } from "app/interfaces/pump.measure.interface";
import { InRouteRes } from "app/interfaces/route.interface";
import { InRoutePriceTransportistRes } from "app/interfaces/route.price.transportist";
import { InTransportTypeRes } from "app/interfaces/transport.type";
import { InUserRes } from "app/interfaces/user.interface";
import { AdditionalRequirement } from "app/models/additionalRequirement";
import { Address, Shipping } from "app/models/address";
import { AuthPRice } from "app/models/authPrice";
import { Company } from "app/models/company";
import { ComissionProduct } from "app/models/comssionProduct";
import { DiscountProduct } from "app/models/discountProduct";
import { LoadingType } from "app/models/loadingType";
import { Measure } from "app/models/measure";
import { OrderStatus } from "app/models/orderStatus";
import { PaymentMethod } from "app/models/paymentMethod";
import { Product } from "app/models/product";
import { Route } from "app/models/route";
import { RoutePriceTransportist } from "app/models/routePriceTransportist";
import { Sale } from "app/models/sale";
import { TransportType } from "app/models/transportType";
import { User } from "app/models/user";
import { DataService } from "app/services/data.service";
import { Tabs } from "flowbite";
import type { TabsOptions, TabsInterface, TabItem } from "flowbite";
import { Select, initTE } from "tw-elements";

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.css"],
})
export class FormComponent {
  @ViewChild("sellerId", { static: true })
  sellerSelect: ElementRef;
  @ViewChild("clientId", { static: true })
  clientSelect: ElementRef;
  @ViewChild("productId", { static: true })
  productSelect: ElementRef;
  @ViewChild("transportistId", { static: true })
  transportistSelect: ElementRef;
  @ViewChild("transportTypeId", { static: true })
  transportTypeSelect: ElementRef;
  @ViewChild("loadingTypeId", { static: true })
  loadingTypeSelect: ElementRef;
  @ViewChild("paymentTypeId", { static: true })
  paymentTypeSelect: ElementRef;
  @ViewChild("additionalRequirementsId", { static: true })
  additionalRequirementsSelect: ElementRef;
  @ViewChild("pumpMeasureId", { static: true })
  pumpMeasureSelect: ElementRef;
  @ViewChild("addressesId", { static: true })
  addressSelect: ElementRef;
  @ViewChild("typeCode", { static: true })
  typeCodeSelect: ElementRef;
  @ViewChild("orderStatus", { static: true })
  orderStatusSelect: ElementRef;
  @ViewChild("vendorsId", { static: true })
  vendorSelect: ElementRef;
  ColumnMode = ColumnMode;

  selectOptions = {
    selectFilter: true,
    selectNoResultText: "No se encontraron resultados",
    selectSearchPlaceholder: "Buscar...",
  };

  selectCommonClasses = {
    optionsList: "list-none m-0 p-0 w-inherit",
    selectInput:
      "peer block min-h-[auto] w-full rounded border-0 bg-transparent outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-gray-200 dark:placeholder:text-gray-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0 cursor-pointer data-[te-input-disabled]:bg-[#e9ecef] data-[te-input-disabled]:cursor-default group-data-[te-was-validated]/validation:mb-4 dark:data-[te-input-disabled]:bg-zinc-600 pr-8",
  };

  dialogTitle: string = "Nueva venta";
  dataSource: Sale;
  edit = false;
  form: FormGroup;
  formDelivery: FormGroup;
  formAddress: FormGroup;
  formStats: FormGroup;
  formSale: FormGroup;

  tabsElement: HTMLElement = document.getElementById("tabs");
  tabElements: TabItem[];
  tabs: TabsInterface;
  options: TabsOptions;

  companies: Company[] = [];
  sellers: Company[] = [];
  clients: Company[] = [];
  transportists: Company[] = [];
  filteredTransportists: Company[] = [];
  users: User[] = [];
  vendors: User[] = [];
  addresses: Address[] = [];
  clientAddresses: Address[] = [];
  shippings: Shipping[] = [];
  vendorsClient: User[] = [];

  pumpMeasures: Measure[] = [];
  transportTypes: TransportType[] = [];
  additionalRequirements: AdditionalRequirement[] = [];
  loadingTypes: LoadingType[] = [];
  paymentMethods: PaymentMethod[] = [];

  products: Product[];
  routes: Route[] = [];
  statuses: OrderStatus[] = [];
  priceRoutesTransportist: RoutePriceTransportist[] = [];
  commisionsProduct: ComissionProduct[] = [];
  discountProducts: DiscountProduct[] = [];
  authorizedPrices: AuthPRice[] = [];
  filteredAuthorizedPrices: AuthPRice[] = [];
  mainAddress: Shipping;
  currentSellerId: number;
  currentProduct: Product;
  currentRoute: Route;
  currentTransportistId: number = null;
  currentRoutePriceTransportist: RoutePriceTransportist;
  currentAuthorizedPrice: AuthPRice;
  currentClientId: number;
  currentTax: string = "iva";
  currentAgreedPrice: number;
  hasExternalComission: boolean;
  totalDistCost: number = 0.0;
  totalPrice: number = 0.0;
  hasAlarmUtility: boolean = false;
  currentUserIsAVendor: boolean = false;
  showIepsMsg: boolean = false;
  checkedIeps: boolean = false;

  validationMessages = {
    folio: [{ type: "required", message: "Campo requerido" }],
    seller_id: [{ type: "required", message: "Campo requerido" }],
    client_id: [{ type: "required", message: "Campo requerido" }],
    product_id: [{ type: "required", message: "Campo requerido" }],
    type_code: [{ type: "required", message: "Campo requerido" }],
    load_date: [{ type: "required", message: "Campo requerido" }],
    end_date: [{ type: "required", message: "Campo requerido" }],
    total_price_liters: [{ type: "required", message: "Campo requerido" }],
    agreed_price: [{ type: "required", message: "Campo requerido" }],
    shipping_price: [{ type: "required", message: "Campo requerido" }],
    transportist_id: [{ type: "required", message: "Campo requerido" }],
    transport_plate: [{ type: "required", message: "Campo requerido" }],
    transport_type_id: [{ type: "required", message: "Campo requerido" }],
    loading_type_id: [{ type: "required", message: "Campo requerido" }],
    pump_measure_id: [{ type: "required", message: "Campo requerido" }],
    payment_method_id: [{ type: "required", message: "Campo requerido" }],
    additional_requirement_id: [
      { type: "required", message: "Campo requerido" },
    ],
    client_reference: [{ type: "required", message: "Campo requerido" }],
    order_status_id: [{ type: "required", message: "Campo requerido" }],
    vendor_id: [{ type: "required", message: "Campo requerido" }],
    check_own_transportist: [{ type: "required", message: "Campo requerido" }],
    own_transportist_name: [{ type: "required", message: "Campo requerido" }],
  };

  constructor(
    private dialogRef: MatDialogRef<FormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private _snack: MatSnackBar,
    private dataService: DataService,
    private companiesService: ClientsService,
    private inventoryService: InventoryService,
    private userService: UserServicesService,
    private salesService: SalesService,
    public formService: FormValidatorService
  ) {
    if (data.action === "edit") {
      this.getOrder();
    } else {
      this.dialogTitle = "Nueva Venta";
      this.dataSource = new Sale({});
      this.hasExternalComission = true;
      this.initializeForms();
    }

    this.currentUserIsAVendor =
      this.dataService.userValue.roles &&
      this.dataService.userValue.roles[0].id == 4 || this.dataService.userValue.roles[0].id == 20;
  }
  getOrder(): void {
    this.salesService.getSale({ id: this.data.data.id }).subscribe({
      next: (v) => {
        console.log(v);
        this.dialogTitle = "Editar Venta";
        this.dataSource = {
          ...v.data,
        };
        this.edit = true;
        this.hasExternalComission = this.dataSource.type_code == "NOR";
        this.checkedIeps =
          this.dataSource.ieps != null || this.dataSource.ieps != undefined;
        this.currentTax = this.checkedIeps ? "ieps" : "iva";
        this.initializeForms(); // Mueve la inicialización de los formularios aquí
      },
      error: (e) => {
        this.openSnack("snack-error", e.error.message);
      },
    });
  }

  initializeForms(): void {
    this.form = this.createForm();
    this.formDelivery = this.createDeliveryForm();
    this.formStats = this.createStatsForm();
    this.formSale = this.createSaleForm();
  }

  ngAfterViewInit() {
    this.tabElements = [
      {
        id: "general-data",
        triggerEl: document.querySelector("#general-data-tab"),
        targetEl: document.querySelector("#general-data"),
      },
      {
        id: "delivery-data",
        triggerEl: document.querySelector("#delivery-data-tab"),
        targetEl: document.querySelector("#delivery-data"),
      },
      {
        id: "stats-data",
        triggerEl: document.querySelector("#stats-data-tab"),
        targetEl: document.querySelector("#stats-data"),
      },
      {
        id: "sale",
        triggerEl: document.querySelector("#sale-tab"),
        targetEl: document.querySelector("#sale"),
      },
    ];
    this.options = {
      defaultTabId: "settings",
      activeClasses:
        "text-white bg-sky-600 border-sky-600 hover:text-white hover:bg-sky-700",
      inactiveClasses:
        "text-gray-500 hover:text-gray-700 border-gray-100 hover:border-gray-300",
      onShow: () => { },
    };

    this.tabs = new Tabs(this.tabElements, this.options);
    this.createSelects();
    this.tabs.show("general-data");
  }

  createSelects(): void {
    initTE({ Select });
    new Select(this.sellerSelect.nativeElement, this.selectOptions);
    new Select(this.clientSelect.nativeElement, this.selectOptions);
    new Select(this.productSelect.nativeElement, this.selectOptions);
    new Select(this.transportistSelect.nativeElement, this.selectOptions);
    new Select(
      this.transportTypeSelect.nativeElement,
      this.selectOptions,
      this.selectCommonClasses
    );
    new Select(
      this.loadingTypeSelect.nativeElement,
      this.selectOptions,
      this.selectCommonClasses
    );
    new Select(
      this.paymentTypeSelect.nativeElement,
      this.selectOptions,
      this.selectCommonClasses
    );
    new Select(
      this.additionalRequirementsSelect.nativeElement,
      this.selectOptions,
      this.selectCommonClasses
    );
    new Select(
      this.pumpMeasureSelect.nativeElement,
      this.selectOptions,
      this.selectCommonClasses
    );
    new Select(
      this.addressSelect.nativeElement,
      this.selectOptions,
      this.selectCommonClasses
    );
    new Select(this.typeCodeSelect.nativeElement, this.selectOptions);
    if (this.edit) {
      new Select(
        this.orderStatusSelect.nativeElement,
        this.selectOptions,
        this.selectCommonClasses
      );
    }

    if (!this.currentUserIsAVendor) {
      new Select(
        this.vendorSelect.nativeElement,
        this.selectOptions,
        this.selectCommonClasses
      );
    }

    this.getCompanies();
    this.getOrderStatuses();
    this.getTransportTypes();
    this.getLoadingTypes();
    this.getPumpMeasures();
    this.getAdditionaRequirements();
    this.getPaymentMethods();
    this.getComissionsProducts();
    this.getDiscountsProducts();
  }

  createForm(): FormGroup {
    let vendorId = undefined;
    let requiredOwnTransportist = false;

    if (this.edit) {
      requiredOwnTransportist = this.dataSource.check_own_transportist;
      vendorId = this.dataSource?.vendor?.id;
    } else {
      vendorId = this.currentUserIsAVendor
        ? this.dataService?.userValue?.id
        : undefined;
    }

    return this.fb.group({
      id: [this.dataSource.id],
      folio: [this.dataSource.folio, [Validators.required]],
      seller_id: [
        this.edit ? this.dataSource?.seller?.id : undefined,
        [Validators.required],
      ],
      client_id: [
        this.edit ? this.dataSource?.client?.id : undefined,
        [Validators.required],
      ],
      client_reference: [
        this.dataSource.client_reference,
        [Validators.required],
      ],
      company_client_id: [
        {
          value: this.edit ? this.dataSource?.client?.company?.id : undefined,
          disabled: !this.edit,
        },
      ],
      order_status_id: [
        this.edit ? this.dataSource?.order_status?.id : undefined,
        this.edit ? [Validators.required] : [],
      ],
      transport_plate: [this.dataSource.transport_plate, [Validators.required]],
      type_code: [
        this.edit ? this.dataSource.type_code : "NOR",
        [Validators.required],
      ],
      load_date: [this.dataSource.load_date, [Validators.required]],
      end_date: [this.dataSource.end_date, [Validators.required]],
      vendor_id: [
        { value: vendorId, disabled: !this.edit },
        [Validators.required],
      ],
      product_id: [
        this.edit ? this.dataSource?.product?.id : undefined,
        [Validators.required],
      ],
      check_own_transportist: [
        this.dataSource.check_own_transportist,
        [Validators.required],
      ],
      own_transportist_name: [
        this.dataSource.own_transportist_name,
        requiredOwnTransportist ? [Validators.required] : [],
      ],
    });
  }

  createDeliveryForm(): FormGroup {
    let transportistIdRequired = true;

    if (this.edit) {
      transportistIdRequired = !this.dataSource.check_own_transportist;
    }

    return this.fb.group({
      address_id: [
        this.edit ? this.dataSource?.address?.id : undefined,
        [Validators.required],
      ],
      zip_code: [
        this.edit ? this.dataSource?.address?.zip_code : undefined,
        [Validators.required],
      ],
      company_transportist_id: [
        {
          value: this.edit
            ? this.dataSource?.transportist?.company?.id
            : undefined,
          disabled: !this.edit,
        },
      ],
      transportist_id: [
        {
          value: this.edit ? this.dataSource?.transportist?.id : undefined,
          disabled: !this.edit,
        },
        transportistIdRequired ? [Validators.required] : [],
      ],
    });
  }

  createStatsForm(): FormGroup {
    return this.fb.group({
      transport_type_id: [
        this.edit ? this.dataSource?.transport_type?.id : undefined,
        [Validators.required],
      ],
      pump_measure_id: [
        this.edit ? this.dataSource?.pump_measure?.id : undefined,
        [Validators.required],
      ],
      loading_type_id: [
        this.edit ? this.dataSource?.loading_type?.id : undefined,
        [Validators.required],
      ],
      payment_method_id: [
        this.edit ? this.dataSource?.payment_method?.id : undefined,
        [Validators.required],
      ],
      additional_requirement_id: [
        this.edit ? this.dataSource?.additional_requirement?.id : undefined,
        [Validators.required],
      ],
    });
  }

  createSaleForm(): FormGroup {
    let transportistRoutePriceRequired = true;
    let shippingPrice = 0.0;

    if (this.edit) {
      transportistRoutePriceRequired = !this.dataSource.check_own_transportist;
      shippingPrice = this.dataSource.shipping_price ?? 0.0;
    }

    return this.fb.group({
      product_specifications: ["N/A"],
      agreed_price: [
        this.leadingZero(this.dataSource.agreed_price?.toString()),
        [Validators.required],
      ],
      shipping_price: [
        this.leadingZero(shippingPrice?.toString()),
        [Validators.required],
      ],
      sold_liters: [this.dataSource.sold_liters, [Validators.required]],
      authorized_price: [{ value: null, disabled: true }],
      price_without_discount: [{ value: null, disabled: true }],
      internal_commision: [
        {
          value: this.leadingZero(
            this.dataSource.internal_commision?.toString()
          ),
          disabled: true,
        },
        [Validators.required],
      ],
      transportist_route_price_id: [
        this.edit ? this.dataSource?.transportist_route_price?.id : undefined,
        transportistRoutePriceRequired ? [Validators.required] : [],
      ],
      external_commision: [
        this.leadingZero(this.dataSource.external_commision?.toString()) ??
        this.leadingZero("0"),
      ],
      distribution_cost: [
        this.leadingZero(this.dataSource.distribution_cost?.toString()) ??
        this.leadingZero("0"),
      ],
      discount: [
        this.leadingZero(this.dataSource.discount?.toString()) ??
        this.leadingZero("0"),
        [Validators.required],
      ],
      utility: [{ value: null, disabled: true }],
      total_price_liter: [
        { value: this.dataSource.total_price_liter, disabled: true },
        [Validators.required],
      ],
      amount: [{ value: null, disabled: true }],
      total_discount: [{ value: null, disabled: true }],
      total_dist_cost: [this.dataSource.total_dist_cost, [Validators.required]],
      total: [{ value: null, disabled: true }],
      subtotal: [{ value: null, disabled: true }],
      tax: [{ value: this.dataSource.tax, disabled: true }],
      ieps: [{ value: this.dataSource.ieps, disabled: true }],
      final_total: [{ value: null, disabled: true }],
    });
  }

  openNewDialogAddress() {
    if (this.currentSellerId != null) {
      if (this.currentClientId == null) {
        this.openSnack("snack-error", "Debe de seleccionar un cliente");
        return;
      }

      const dialogRef = this.dialog.open(AddressesDialogComponent, {
        width: "1100px",
        data: {
          action: "store",
          company_id: this.clients.find((client) => {
            return (
              client.clients[0]?.active &&
              client.clients[0]?.id == this.currentClientId
            );
          })?.id,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result?.status && result?.status == 200) {
          this.getAdresses();
        }
      });
    } else {
      this.openSnack("snack-error", "Debe de seleccionar un vendedor");
    }
  }

  async addSale(): Promise<void> {
    if (!this.form.valid) {
      this.showValidationModal("Revisa los datos generales.");
      return;
    }

    if (!this.formDelivery.valid) {
      this.showValidationModal("Revisa los datos de entrega.");
      return;
    }

    if (!this.formStats.valid) {
      this.showValidationModal("Revisa los datos estadísticos.");
      return;
    }

    if (!this.formSale.valid) {
      this.showValidationModal("Revisa los datos de venta.");
      return;
    }

    if (this.showAuthorizedPriceAlert()) {
      const value = await this.showConfirmValidationModal(
        "El precio que ha ingresado es menor al precio autorizado. ¿Desea continuar?",
        true,
        true
      );

      if (value) {
        this.onChangePrice();
        this.saveAgreedPriceValue();
      } else {
        this.formSale.controls.agreed_price.setValue(this.currentAgreedPrice);
        return;
      }
    }

    this.dataService.setLoadingValue(true);
    const shippings = [];

    for (const shipping of this.shippings) {
      shippings.push({
        shipping_id: this.edit ? shipping.id : null,
        address_id: shipping.address.id,
        code: "code",
        unit: "unit",
        quantity: 1,
        end_date: "2023-12-01",
      });
    }

    const defaulValues = {
      number: this.dataSource.number ? this.dataSource.number : 0,
      priority: this.dataSource.priority ? this.dataSource.priority : 1,
      alert: this.dataSource.alert ? this.dataSource.alert : false,
      alert_attended: this.dataSource.alert_attended
        ? this.dataSource.alert_attended
        : false,
      credit_debt_confirm: this.dataSource.credit_debt_confirm
        ? this.dataSource.credit_debt_confirm
        : false,
      tax: this.convertToNumber(this.formSale.controls.tax.value),
      ieps: this.formSale.controls.ieps.value ?? null,
      lab_confirm: this.dataSource.lab_confirm
        ? this.dataSource.lab_confirm
        : false,
      order_admin_confirm: this.dataSource.order_admin_confirm
        ? this.dataSource.order_admin_confirm
        : false,
      doc_ready_confirm: this.dataSource.doc_ready_confirm
        ? this.dataSource.doc_ready_confirm
        : false,
      order_comments: this.dataSource.order_comments
        ? this.dataSource.order_comments
        : null,
    };

    this.formSale.controls.internal_commision.enable();
    const internalComission = Number(
      parseFloat(this.formSale.controls.internal_commision.value)
    );
    this.formSale.controls.internal_commision.disable();

    this.dataSource = {
      ...this.form.value,
      ...this.formDelivery.value,
      ...this.formStats.value,
      ...this.formSale.value,
      order_status_id: this.edit
        ? Number(this.form.controls.order_status_id.value)
        : 1,
      type_transport: this.formStats.controls.transport_type_id.value,
      agreed_price: Number(parseFloat(this.formSale.value.agreed_price)),
      shipping_price: Number(parseFloat(this.formSale.value.shipping_price)),
      distribution_cost: Number(
        parseFloat(this.formSale.value.distribution_cost)
      ),
      external_commision: Number(
        parseFloat(this.formSale.value.external_commision)
      ),
      discount: Number(parseFloat(this.formSale.controls.discount.value)),
      route_price:
        this.shippings.length - 1 == 0
          ? 0
          : Number(parseFloat(this.formSale.controls.distribution_cost.value)),
      total_price_liter: Number(
        parseFloat(this.formSale.controls.total_price_liter.value)
      ),
      shippings,
      ...defaulValues,
      internal_commision: internalComission,
      ieps: this.currentTax == "iva" ? null : this.formSale.controls.ieps.value,
      /*supply_with_order: {
        product_id: 1,
        user_id: 1,
        client_id: 1,
        transportist_id: 1,
        folio: "folio",
        client_reference: "client_reference",
        product_specifications: "product_specifications",
        agreed_price: 1,
        transport_plate: "transport_plate",
        transport_type: 1,
        internal_commision: 1,
        external_commision: 1,
        total_price_liter: 1,
      },*/
    };

    console.log(this.dataSource.load_date);
    console.log(this.dataSource.end_date);
    this.storeUpdate(this.edit ? "update" : "store");
  }

  storeUpdate(method: string): void {
    if (method === "store") {
      this.salesService.createSale(this.dataSource).subscribe({
        next: (v) => {
          this.dataService.setLoadingValue(false);
          this.openSnack("snack-success", v.message);
          this.dialogRef.close({ status: 200 });
        },
        error: (e) => {
          this.dataService.setLoadingValue(false);
          this.openSnack("snack-error", e.error.message);
        },
      });
    } else {
      this.salesService.editSale(this.dataSource).subscribe({
        next: (v) => {
          this.dataService.setLoadingValue(false);
          this.openSnack("snack-success", v.message);
          this.dialogRef.close({ status: 200 });
        },
        error: (e) => {
          this.dataService.setLoadingValue(false);
          this.openSnack("snack-error", e.error.message);
        },
      });
    }
  }

  changeDate(date: string, id: string): void {
    if (id === "load-date") {
      this.form.controls.load_date.patchValue(date);
    } else if (id === "end-date") {
      this.form.controls.end_date.patchValue(date);
    }

    this.form.updateValueAndValidity();
  }

  onChangeSeller(sellerId: string): void {
    const parseSellerId = Number(sellerId);
    this.currentSellerId = parseSellerId;
    const currentSeller = this.sellers.find((seller) => {
      return seller.id == parseSellerId;
    });

    if (currentSeller) {
      const clientsId = currentSeller.company_clients
        .filter((client) => client.active)
        .map((client) => {
          return client.id;
        });
      const currentClients = this.companies.filter((company) => {
        if (
          this.currentSellerId != company.id &&
          company.clients.filter((client) => client.active).length > 0
        ) {
          const hasCompany = company.clients.filter((client) => {
            return client.active && clientsId.includes(client.id);
          });

          return hasCompany.length > 0;
        }

        return false;
      });

      this.clients = currentClients;
      if (this.currentUserIsAVendor) { this.getComissionsProductsVendor(this.commisionsProduct, this.clients) };

      if (!this.edit) {
        this.form.controls.company_client_id.enable();
        this.form.updateValueAndValidity();
      }
    }
  }

  onChangeTransportist(transportistId: string): void {
    const parseTransportistId = Number(transportistId);
    const company = this.companies.find((company) => {
      return (
        company.id == parseTransportistId &&
        company.transportists.filter((t) => t.active).length > 0
      );
    });

    if (company && company.transportists.filter((t) => t.active).length > 0) {
      this.currentTransportistId = company.transportists[0].id;
      this.formDelivery.controls.transportist_id.patchValue(
        this.currentTransportistId
      );
      this.formDelivery.updateValueAndValidity();

      if (this.routes.length > 0) {
        const tmpRoutes = this.routes.filter((route) => {
          return (
            route.active &&
            route.to_locality.municipality.id ==
            this.mainAddress?.address?.locality?.municipality?.id
          );
        });
        tmpRoutes.forEach((route) => {
          const priceRoutesTransportist = this.priceRoutesTransportist.find(
            (priceRoute) => {
              return (
                priceRoute.route.id == route.id &&
                priceRoute.product.id == this.currentProduct?.id &&
                priceRoute.transportist.id == this.currentTransportistId
              );
            }
          );

          if (priceRoutesTransportist) {
            this.currentRoute = route;
            this.currentRoutePriceTransportist = priceRoutesTransportist;
          }
        });
      }
    }
  }

  onChangeClient(clientId: string): void {
    const company = this.companies.find((company) => {
      return Number(clientId) == company.id;
    });

    if (company) {
      this.clientAddresses = this.addresses.filter((address) => {
        return address.active && address.company.id == company.id;
      });

      if (company.clients.filter((client) => client.active).length > 0) {
        const client = company.clients[0];
        this.currentClientId = client.id;
        this.form.controls.client_id.patchValue(this.currentClientId);

        const tmpVendorsId = this.commisionsProduct.filter((commision) => {
          return commision.client.id == client.id;
        })

        if (this.currentUserIsAVendor) {
          const tmpProducts = this.products.filter((product) => {
            return product.active && tmpVendorsId.some((commision) => {
              return commision.product.id === product.id;
            });
          });
          this.products = tmpProducts;
        }

        const vendorsId = this.commisionsProduct
          .filter((commision) => {
            return commision.client.id == client.id;
          })
          .map((commision: ComissionProduct) => {
            return commision.user.id;
          });

        this.vendorsClient = this.vendors.filter((user) => {
          if (!user.active) return false;
          if (vendorsId.indexOf(user.id) > -1) {
            return true;
          }
        });

        if (!this.edit) this.form.controls.vendor_id.enable();
        this.form.updateValueAndValidity();
      }
    }
  }

  onChangeTypeCode(typeCode: string): void {
    if (typeCode == "NOR") this.hasExternalComission = true;
    else this.hasExternalComission = false;

    this.onChangePrice();
  }

  onSelectAddress(addressId: string): void {
    const address = this.clientAddresses.find((address) => {
      return address.id == Number(addressId);
    });

    if (address) {
      const exists = this.shippings.some((shipping) => {
        return shipping.address.id == address.id;
      });

      if (exists) return;
      this.shippings = [
        ...this.shippings,
        {
          address_id: address.id,
          address,
          code: "code",
          unit: "unit",
          quantity: 1,
          end_date: "2023-12-01",
        },
      ];

      if (this.shippings.length > 0) {
        if (this.mainAddress?.address?.id != this.shippings[0].address.id) {
          this.filterTransportists();
        }

        this.mainAddress = this.shippings[0];
        this.formDelivery.controls.address_id.patchValue(
          this.mainAddress.address.id
        );
        this.formDelivery.controls.zip_code.patchValue(
          this.mainAddress.address.zip_code
        );
        this.formDelivery.controls.company_transportist_id.enable();
        this.formDelivery.updateValueAndValidity();

        if (this.routes.length > 0) {
          this.currentRoute = this.routes.find((route) => {
            return (
              route.to_locality.id == this.shippings[0]?.address?.locality?.id
            );
          });
          this.currentRoute = this.currentRoute ?? this.routes.find((route) => {
            return (route.to_locality.municipality.id ==
              this.shippings[0]?.address?.locality?.municipality?.id)
          })
          this.getCurrentRoutePrice();
        }
      } else {
        this.formDelivery.controls.company_transportist_id.disable();
        this.formDelivery.updateValueAndValidity();
      }
      this.onChangePrice();
    }
  }

  onChangeTransportType(transportTypeId: number): void {
    if (this.currentProduct)
      this.onSelectProduct(this.currentProduct.id.toString());
    else this.onChangePrice();
  }

  onChangeCheckOwnTransportist(ownTransport: boolean): void {
    if (ownTransport) {
      setTimeout(() => {
        this.formDelivery.controls.transportist_id.patchValue(
          this.dataSource?.transportist?.id ?? undefined
        );
        this.formDelivery.controls.company_transportist_id.patchValue(
          this.dataSource?.transportist?.company?.id ?? undefined
        );
        this.formDelivery.controls.transportist_id.disable();
        this.formDelivery.controls.company_transportist_id.disable();
        this.formDelivery.controls.transportist_id.setValidators(null);
        this.formDelivery.controls.transportist_id.setErrors(null);
        this.formDelivery.controls.company_transportist_id.setValidators(null);
        this.formDelivery.controls.company_transportist_id.setErrors(null);

        this.form.controls.own_transportist_name.patchValue(
          this.dataSource.own_transportist_name ?? null
        );
        this.form.controls.check_own_transportist.addValidators([
          Validators.required,
        ]);
        this.form.controls.own_transportist_name.addValidators([
          Validators.required,
        ]);
        this.formDelivery.updateValueAndValidity();
        this.form.updateValueAndValidity();
      });

      this.formSale.controls.transportist_route_price_id.removeValidators([
        Validators.required,
      ]);
      this.formSale.controls.transportist_route_price_id.patchValue(undefined);
      this.formSale.controls.shipping_price.patchValue(this.leadingZero("0"));
      this.formSale.controls.distribution_cost.patchValue(
        this.leadingZero("0")
      );
      this.formSale.controls.shipping_price.disable();
      this.formSale.controls.distribution_cost.disable();
      this.formSale.updateValueAndValidity();

      if (this.currentProduct)
        this.onSelectProduct(this.currentProduct.id.toString());
      else this.onChangePrice();
    } else {
      setTimeout(() => {
        this.formDelivery.controls.transportist_id.addValidators([
          Validators.required,
        ]);
        this.formDelivery.controls.transportist_id.enable();
        this.formDelivery.controls.company_transportist_id.enable();
        this.form.controls.own_transportist_name.patchValue(undefined);
        this.form.controls.own_transportist_name.setValidators(null);
        this.form.controls.own_transportist_name.setErrors(null);
        this.form.controls.check_own_transportist.setValidators(null);
        this.form.controls.check_own_transportist.setErrors(null);
        this.form.updateValueAndValidity();
        this.formDelivery.updateValueAndValidity();

        this.formSale.controls.transportist_route_price_id.addValidators([
          Validators.required,
        ]);
        this.formSale.controls.shipping_price.enable();
        this.formSale.controls.distribution_cost.enable();
        this.formSale.updateValueAndValidity();

        if (this.currentProduct)
          this.onSelectProduct(this.currentProduct.id.toString());
        else this.onChangePrice();
      });
    }
  }

  onSelectProduct(productId: string): void {
    const product = this.products.find((product) => {
      return product.id == Number(productId);
    });

    if (product) {
      this.currentProduct = product;
      this.showIepsMsg = false;
      this.currentAuthorizedPrice = this.authorizedPrices.find((price) => {
        return price.product.id == product.id;
      });

      this.filterTransportists();
      this.getCurrentRoutePrice();
      this.getCurrentCommissionProduct();
      this.getCurrentDiscountProduct();
      this.formSale.controls.authorized_price.setValue(
        this.currentAuthorizedPrice?.authorized_price ??
        product.authorized_price
      );
      this.formSale.controls.utility.setValue(
        this.currentAuthorizedPrice?.profit_margin ?? product.utility
      );

      if (
        this.dataSource.agreed_price == 0 ||
        this.dataSource.agreed_price == null
      ) {
        if (this.currentAuthorizedPrice?.authorized_price != 0)
          this.formSale.controls.agreed_price.setValue(
            this.leadingZero(
              this.currentAuthorizedPrice?.authorized_price.toString()
            ) ?? this.leadingZero(product.authorized_price.toString())
          );
      }

      this.formSale.updateValueAndValidity();
      this.onChangePrice();
    } else {
      this.currentProduct = null;
    }
  }

  onChangePrice(): void {
    if (this.currentProduct) {
      const agreedPrice = this.convertToNumber(
        this.formSale.controls.agreed_price.value
      );
      const transportPrice = this.convertToNumber(
        this.formSale.controls.shipping_price.value
      );
      const internalComission = this.convertToNumber(
        this.formSale.controls.internal_commision.value
      );
      const distributionCost = this.convertToNumber(
        this.formSale.controls.distribution_cost.value
      );
      const shippings = this.shippings.length - 1;
      const clientDiscount = this.convertToNumber(
        this.formSale.controls.discount.value
      );
      const quantity = this.convertToNumber(
        this.formSale.controls.sold_liters.value
      );

      let tmpPrice = 0.0;
      let tmpPriceWithoutDiscount = 0.0;
      let externalComission = 0.0;

      if (agreedPrice != null && internalComission != null) {
        if (!isNaN(agreedPrice)) tmpPrice += agreedPrice;

        if (!isNaN(transportPrice)) tmpPrice += transportPrice;

        if (!isNaN(internalComission)) tmpPrice += internalComission;

        if (this.hasExternalComission) {
          externalComission = this.convertToNumber(
            this.formSale.controls.external_commision.value
          );

          if (externalComission != null && !isNaN(externalComission)) {
            tmpPrice += externalComission;
          }
        } else {
          this.formSale.controls.external_commision.patchValue(
            this.leadingZero("0")
          );
        }

        let totalShippingsPrice = 0.0;

        if (shippings > 0) {
          totalShippingsPrice = this.form.controls.check_own_transportist.value
            ? 0.0
            : distributionCost * shippings;
          this.formSale.controls.total_dist_cost.patchValue(
            totalShippingsPrice
          );

          if (!isNaN(totalShippingsPrice)) tmpPrice += totalShippingsPrice;
        } else {
          this.formSale.controls.total_dist_cost.patchValue(0.0);
        }

        tmpPriceWithoutDiscount += tmpPrice;
        tmpPrice -= clientDiscount;

        if (!isNaN(tmpPriceWithoutDiscount)) {
          this.formSale.controls.price_without_discount.patchValue(
            tmpPriceWithoutDiscount.toFixed(2)
          );
        }

        if (!isNaN(tmpPrice)) {
          this.formSale.controls.total_price_liter.patchValue(
            tmpPrice.toFixed(2)
          );
        }

        const costs = this.generateCosts(
          internalComission,
          externalComission,
          totalShippingsPrice
        );

        const utility = tmpPrice - costs;
        if (quantity != null && !isNaN(quantity)) {
          const priceWithoutDiscount =
            this.convertToNumber(
              this.formSale.controls.price_without_discount.value
            ) ?? 0.0;
          const priceWithDiscount =
            this.convertToNumber(
              this.formSale.controls.total_price_liter.value
            ) ?? 0.0;
          const importTotal = quantity * priceWithoutDiscount;
          const finalTotal = quantity * priceWithDiscount;
          const totalDiscount = importTotal - finalTotal;
          this.formSale.controls.amount.patchValue(importTotal.toFixed(2));
          this.formSale.controls.total_discount.patchValue(
            totalDiscount.toFixed(2)
          );
          this.formSale.controls.total.patchValue(finalTotal.toFixed(2));
          this.calculateTax(this.currentTax);
        }

        if (!isNaN(utility))
          this.formSale.controls.utility.patchValue(utility.toFixed(2));
        this.formSale.updateValueAndValidity();
      }
    }
  }

  onLeadingZero(event: Event): void {
    const element = event.target as HTMLInputElement;

    this.leadingValueZero(element);
    this.onChangePrice();
    element.setAttribute(
      "data-value",
      this.formSale.controls.agreed_price.value
    );
  }

  leadingValueZero(element: HTMLInputElement, value: string = null) {
    value = value ?? element.value;
    const num = value?.replace("$", "")?.replace(",", "")?.replace(" ", "");
    const dec = num.split(".")[1];
    const len = dec && dec.length > 2 ? dec.length : 2;
    element.value = `$ ${Number(num).toFixed(len)}`;
  }

  leadingZero(num: string): string {
    if (num == null) return "0.00";

    const dec = num.split(".")[1];

    if (!dec && num == "0") return "0.00";
    else if (!dec && num != "0") return `${num}.00`;

    const len = dec && dec.length > 2 ? dec.length : 2;
    const fixed = Number(num).toFixed(len);
    return fixed;
  }

  showAuthorizedPriceAlert(): boolean {
    const authorizedPrice = this.formSale.controls.authorized_price.value;
    const agreedPrice = this.formSale.controls.agreed_price.value;

    return agreedPrice < authorizedPrice;
  }

  saveAgreedPriceValue(): void {
    this.currentAgreedPrice = this.formSale.controls.agreed_price.value;
  }

  changeTax(event: Event, typeTax: string): void {
    this.currentTax = typeTax;

    if (typeTax == "iva") {
      this.showIepsMsg = false;
      this.checkedIeps = false;
    } else if (typeTax == "ieps") {
      this.showIepsMsg = !this.currentProduct?.ieps;
      this.checkedIeps = true;
      if (this.showIepsMsg) event.preventDefault();
    }

    this.calculateTax(typeTax);
  }

  filterTransportists() {
    if (this.shippings.length > 0) {
      if (!this.currentRoute) {
        this.currentRoute = this.routes.find((route) => {
          return (
            route.to_locality.id == this.shippings[0]?.address?.locality?.id
          );
        });
        this.currentRoute = this.currentRoute ?? this.routes.find((route) => {
          return (route.to_locality.municipality.id ==
            this.shippings[0]?.address?.locality?.municipality?.id)
        })
      }

      this.filteredTransportists = this.transportists.filter((transportist) => {
        const routeTransportists = this.priceRoutesTransportist.filter(
          (priceRoute) => {
            const route = this.routes.find(
              (route) => route?.id == priceRoute?.route?.id
            );

            return (
              route?.to_locality?.municipality?.id ==
              this.shippings[0]?.address?.locality?.municipality?.id &&
              priceRoute?.product?.id == this.currentProduct?.id
            );
          }
        );

        const routeTransportist = routeTransportists.find(
          (rt) =>
            rt.active &&
            rt?.transportist?.id == transportist?.transportists[0]?.id &&
            rt?.route?.id == this.currentRoute?.id
        );

        return (
          transportist?.transportists[0]?.active &&
          routeTransportist?.transportist?.id ==
          transportist?.transportists[0]?.id
        );
      });
    }
  }

  calculateTax(typeTax: string = "iva"): void {
    const quantity = this.convertToNumber(
      this.formSale.controls.sold_liters.value
    );
    const total = this.convertToNumber(this.formSale.controls.total.value);

    if (typeTax == "iva") {
      const subtotal = total / 1.16;
      const totalIva = subtotal * 0.16;
      this.formSale.controls.subtotal.patchValue(subtotal.toFixed(2));
      this.formSale.controls.tax.patchValue(totalIva.toFixed(2));
      this.formSale.controls.ieps.patchValue(0.0);
    } else if (typeTax == "ieps") {
      const iepsCost = this.currentProduct?.ieps_cost ?? 0;
      const totalIepsCost = quantity * iepsCost;
      const totalWithoutIeps = total - totalIepsCost;
      const subtotal = totalWithoutIeps / 1.16;
      const totalIva = subtotal * 0.16;

      this.formSale.controls.subtotal.patchValue(subtotal.toFixed(2));
      this.formSale.controls.tax.patchValue(totalIva.toFixed(2));
      this.formSale.controls.ieps.patchValue(totalIepsCost.toFixed(2));
    }

    this.formSale.updateValueAndValidity();
  }

  generateCosts(
    internalComission: number,
    externalComission: number,
    totalShippingsPrice: number
  ): number {
    const logicPrice = this.currentProduct?.list_price ?? 0;
    const transportPrice = this.convertToNumber(
      this.formSale.controls.shipping_price.value
    );

    let sumCosts = 0;

    if (logicPrice != null && !isNaN(logicPrice)) {
      sumCosts += logicPrice;
    }

    if (transportPrice != null) {
      const tmpTransportPrice = !isNaN(internalComission)
        ? transportPrice
        : 0.0;
      sumCosts += this.form.controls.check_own_transportist.value
        ? 0.0
        : tmpTransportPrice;
    }

    if (internalComission != null && !isNaN(internalComission)) {
      sumCosts += internalComission;
    }

    if (this.hasExternalComission) {
      if (externalComission != null && !isNaN(externalComission)) {
        sumCosts += externalComission;
      }
    }

    return this.shippings.length - 1 > 0
      ? sumCosts + totalShippingsPrice
      : sumCosts;
  }

  hasCurrentRoutePrice(): boolean {
    if (
      this.currentRoute &&
      this.currentProduct &&
      this.currentTransportistId != null
    )
      return true;
    else return false;
  }

  getCurrentRoutePrice(): void {
    if (this.hasCurrentRoutePrice()) {
      this.currentRoutePriceTransportist = this.priceRoutesTransportist.find(
        (priceRoute) => {
          return (
            priceRoute.route.id == this.currentRoute?.id &&
            priceRoute.product.id == this.currentProduct?.id &&
            priceRoute.transportist.id == this.currentTransportistId
          );
        }
      );

      if (!this.edit) {
        if (this.currentRoutePriceTransportist) {
          this.formSale.controls.transportist_route_price_id.patchValue(
            this.currentRoutePriceTransportist?.id
          );

          this.formSale.controls.shipping_price.patchValue(
            this.leadingZero(
              this.currentRoutePriceTransportist?.cost?.toString()
            )
          );

          if (this.shippings.length - 1 > 0)
            this.formSale.controls.distribution_cost.patchValue(
              this.leadingZero(
                this.currentRoutePriceTransportist?.distribution_cost?.toString()
              )
            );

          this.formSale.updateValueAndValidity();
        }
      } else {
        if (!this.formSale.controls.transportist_route_price_id.value) {
          if (this.currentRoutePriceTransportist) {
            this.formSale.controls.transportist_route_price_id.patchValue(
              this.currentRoutePriceTransportist?.id
            );

            this.formSale.controls.shipping_price.patchValue(
              this.leadingZero(
                this.currentRoutePriceTransportist?.cost?.toString()
              )
            );

            if (this.shippings.length - 1 > 0)
              this.formSale.controls.distribution_cost.patchValue(
                this.leadingZero(
                  this.currentRoutePriceTransportist?.distribution_cost?.toString()
                )
              );

            this.formSale.updateValueAndValidity();
          }
        }
      }
    }
  }

  getCurrentCommissionProduct(): void {
    const currentVendorId = this.currentUserIsAVendor
      ? this.dataService.userValue.id
      : this.form.controls.vendor_id.value;
    const currentCommissionProduct = this.commisionsProduct.find(
      (commissionProduct) => {
        return (
          commissionProduct.product.id == this.currentProduct.id &&
          this.currentClientId == commissionProduct.client.id &&
          currentVendorId == commissionProduct.user.id
        );
      }
    );

    if (!this.edit) {
      if (currentCommissionProduct) {
        this.formSale.controls.internal_commision.patchValue(
          this.leadingZero(currentCommissionProduct?.cost_per_liter?.toString())
        );
        this.formSale.updateValueAndValidity();
      }
    }
  }

  getCurrentDiscountProduct(): void {
    const currentDiscountProduct = this.discountProducts.find(
      (discountProduct) => {
        return (
          discountProduct.product.id == this.currentProduct.id &&
          this.currentClientId == discountProduct.client.id
        );
      }
    );

    if (!this.edit) {
      if (currentDiscountProduct) {
        this.formSale.controls.discount.patchValue(
          this.leadingZero(currentDiscountProduct?.cost_per_liter?.toString())
        );
        this.formSale.updateValueAndValidity();
      } else {
        this.formSale.controls.discount.patchValue(this.leadingZero("0"));
        this.formSale.updateValueAndValidity();
      }
    }
  }

  deleteShipping(index: number): void {
    const shipping = this.shippings.findIndex((shipping) => {
      return shipping.address.id == index;
    });

    if (shipping < 0) {
      this.formDelivery.controls.company_transportist_id.disable();
      this.formDelivery.updateValueAndValidity();
      return;
    }

    const tempData = this.shippings.slice();
    tempData.splice(shipping, 1);
    this.shippings = [...tempData];
  }

  getCompanies(): void {
    this.companiesService.readCompanies().subscribe((res: InCompaniesRes) => {
      if (res.status === "Éxito") {
        this.companies = res.data.filter((company) => {
          return company.active;
        });

        this.sellers = this.companies.filter((company) => {
          return company.active && company.main;
        });

        this.transportists = this.companies.filter((company) => {
          if (company.active && company.transportists.length > 0) {
            return true;
          }

          return false;
        });

        this.getProducts();
        this.getUsers();
        this.getComissionsProducts();

        if (this.edit) {
          this.onChangeSeller(this.dataSource?.seller?.id?.toString());
        }
      }
    });
  }

  getAdresses(): void {
    this.companiesService.getAddress().subscribe((res: InAddressRes) => {
      if (res.status === "Éxito") {
        this.addresses = res.data;

        if (this.currentClientId) {
          const company = this.companies.find((company) => {
            return company.clients.find((client) => {
              return client.active && client.id == this.currentClientId;
            });
          });
          this.clientAddresses = this.addresses.filter((address) => {
            return address.active && address.company.id == company.id;
          });
        }

        if (this.edit) {
          this.shippings = this.dataSource.shippings.map((shipping) => {
            return {
              ...shipping,
              shipping_id: shipping.id,
            };
          });

          if (this.shippings.length > 0) {
            this.mainAddress = this.shippings[0];
            this.formDelivery.controls.address_id.patchValue(
              this.mainAddress?.address?.id
            );
            this.formDelivery.controls.zip_code.patchValue(
              this.mainAddress?.address?.zip_code
            );
            this.formDelivery.updateValueAndValidity();
          }
        }

        this.getAuthorizedPrices();
      }
    });
  }

  getTransportTypes(): void {
    this.salesService
      .getTransportTypes()
      .subscribe((res: InTransportTypeRes) => {
        if (res.status === "Éxito") {
          this.transportTypes = res.data;
        }
      });
  }

  getAdditionaRequirements(): void {
    this.salesService
      .getAdditionaRequirements()
      .subscribe((res: InAdditionalRequirementRes) => {
        if (res.status === "Éxito") {
          this.additionalRequirements = res.data;
        }
      });
  }

  getLoadingTypes(): void {
    this.salesService.getLoadingTypes().subscribe((res: InLoadingTypeRes) => {
      if (res.status === "Éxito") {
        this.loadingTypes = res.data;
      }
    });
  }

  getPaymentMethods(): void {
    this.salesService.getPaymentMethods().subscribe((res: InPaymentMethodRes) => {
      if (res.status === "Éxito") {
        this.paymentMethods = res.data.filter((paymentMethod) => !paymentMethod.type_invoice);
      }
    });
  }

  getProducts(): void {
    this.inventoryService.getProducts().subscribe((res: InProductRes) => {
      if (res.status === "Éxito") {
        this.products = res.data.filter((product) => {
          return product.active && product.sellable;
        });
        this.getAdresses();
      }
    });
  }

  getPumpMeasures(): void {
    this.inventoryService
      .getPumpMeasures()
      .subscribe((res: InPumpMeasureRes) => {
        if (res.status === "Éxito") {
          this.pumpMeasures = res.data;
        }
      });
  }

  getRoutes(): void {
    this.companiesService.readRoutes().subscribe((res: InRouteRes) => {
      if (res.status === "Éxito") {
        this.routes = res.data;
        this.getTransportistRoutes();

        if (this.edit && this.mainAddress) {
          this.currentRoute = this.routes.find((route) => {
            return (
              route?.to_locality?.municipality?.id ==
              this.mainAddress?.address?.locality?.municipality?.id
            );
          });

          this.currentRoutePriceTransportist =
            this.dataSource.transportist_route_price;
        }
      }
    });
  }

  getUsers(): void {
    this.userService.readUsers().subscribe((res: InUserRes) => {
      if (res.status === "Éxito") {
        this.users = res.data;

        this.vendors = this.users.filter((user) => {
          if (user?.roles) {
            return user.roles[0]?.id === 4 || user.roles[0]?.id === 20;;
          }
        });

        if (this.edit) {
          this.onChangeClient(this.dataSource?.client?.company?.id?.toString());
        }
      }
    });
  }

  getTransportistRoutes(): void {
    this.companiesService
      .readPRT()
      .subscribe((res: InRoutePriceTransportistRes) => {
        if (res.status === "Éxito") {
          this.priceRoutesTransportist = res.data;
          this.formDelivery.controls.transportist_id.enable();
          this.form.controls.check_own_transportist.enable();

          if (this.edit) {
            this.filterTransportists();
            this.onChangeTransportist(this.dataSource?.transportist?.company?.id?.toString());
            this.getCurrentRoutePrice();

            if (!this.dataSource.own_transportist_name) {
              this.onChangeTransportist(
                this.dataSource?.transportist?.company?.id?.toString()
              );
            }
          }
        }
      });
  }

  getComissionsProducts(): void {
    this.companiesService
      .readCommisions()
      .subscribe((res: InComissionProductRes) => {
        if (res.status === "Éxito") {
          this.commisionsProduct = res.data;
          if (this.edit) {
            this.onChangeSeller(this.dataSource?.seller?.id?.toString());
          }
        }
      });
  }

  getDiscountsProducts(): void {
    this.companiesService.readDiscountsProduct().subscribe((res) => {
      if (res.status === "Éxito") {
        this.discountProducts = res.data;
      }
    });
  }

  getOrderStatuses(): void {
    if (this.edit) {
      this.salesService.getSalesTypes().subscribe((res: InOrderStatusRes) => {
        if (res.status === "Éxito") {
          this.statuses = res.data;
        }
      });
    }
  }

  getAuthorizedPrices(): void {
    this.inventoryService
      .getAuthorizedPrices()
      .subscribe((res: AuthorizedPriceRes) => {
        if (res.status === "Éxito") {
          const tmpAuthorizedPrices = res.data;
          const authorizedPrices = [];
          this.products.forEach((product) => {
            const authorizedPrice = tmpAuthorizedPrices.find(
              (authorizedPrice) => {
                return authorizedPrice.product.id == product.id;
              }
            );

            if (authorizedPrice) {
              authorizedPrices.push(authorizedPrice);
            }
          });

          this.authorizedPrices = authorizedPrices;

          if (this.edit) {
            this.onSelectProduct(this.dataSource?.product?.id?.toString());
          }

          this.getRoutes();
        }
      });
  }

  getComissionsProductsVendor(commisions: ComissionProduct[], clients: Company[]): void {

    const tmpCommisions = commisions.filter((commision) => {
      return commision.user.id === this.dataService.userValue.id;
    });

    const tmpClients = clients.filter((client) => {
      return tmpCommisions.find((commision: any) => {
        return commision.client.company.id === client.id;
      });
    });

    this.clients = tmpClients;
    this.commisionsProduct = tmpCommisions;
  }

  goToGeneralData(): void {
    Object.keys(this.form.controls).forEach((key) => {
      const control = this.form.get(key);
      if (control.invalid) {
        console.log(`Campo '${key}' es inválido. Errores:`, control.errors);
      }
    });

    if (this.form.valid) this.tabs.show("general-data");
    else this.showValidationModal();
  }

  goToDeliveryData(): void {
    Object.keys(this.form.controls).forEach((key) => {
      const control = this.form.get(key);
      if (control.invalid) {
        console.log(`Campo '${key}' es inválido. Errores:`, control.errors);
      }
    });

    if (this.form.valid) this.tabs.show("delivery-data");
    else this.showValidationModal();
  }

  goToStatsData(): void {
    if (this.formDelivery.valid) this.tabs.show("stats-data");
    else this.showValidationModal("Debes agregar una dirección.");
  }

  goToSalesData(): void {
    if (this.shippings.length - 1 === 0) {
      this.formSale.controls.distribution_cost.patchValue(
        this.leadingZero("0")
      );
      this.formSale.controls.distribution_cost.disable();
      this.formSale.updateValueAndValidity();
    } else {
      if (this.currentProduct)
        this.formSale.controls.distribution_cost.patchValue(
          this.leadingZero(this.currentProduct.supply_price.toString())
        );
      this.formSale.controls.distribution_cost.enable();
      this.formSale.updateValueAndValidity();
    }

    if (this.formStats.valid) this.tabs.show("sale");
    else this.showValidationModal();
  }

  showValidationModal(
    message: string = "Debe completar todos los campos."
  ): void {
    this.dialog.open(AlertComponent, {
      data: {
        body: message,
      },
    });
  }

  async showConfirmValidationModal(
    body: string,
    showBtnClose: boolean,
    isConfirm: boolean
  ): Promise<boolean> {
    const dialogRef = this.dialog.open(AlertComponent, {
      data: {
        body,
        showBtnClose,
        isConfirm,
      },
    });

    const result = await dialogRef.afterClosed().pipe().toPromise();
    return result;
  }

  convertToNumber(value: string): number {
    let converted: number;
    const val = String(value).replace(",", "");
    converted = Number(parseFloat(val));

    return converted;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
      verticalPosition: "top",
    });
  }
}
