import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  Inject,
  inject,
} from "@angular/core";
import {
  ColumnMode,
  DatatableComponent,
  SelectionType,
} from "@swimlane/ngx-datatable";
import { AssingClientInvoiceComponent } from "../assing-client-invoice/assing-client-invoice.component";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ClientsService } from "app/apiServices/clients.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FormValidatorService } from "app/core/service/form-validator.service";
import { Company } from "app/models/company";
import { SalesService } from "app/apiServices/sales.service";
import { Select, Dropdown, Collapse, initTE } from "tw-elements";
import { DateTime } from "luxon";
import { Sale } from "app/models/sale";
import { InCompaniesRes } from "app/interfaces/company.interface";
import { NotificationsComponent } from "app/components/alerts/notifications/notifications.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DataService } from "app/services/data.service";
import { AlertComponent } from "app/components/alerts/alert/alert.component";
import { InvoiceService } from "app/apiServices/invoice.service";
import { Invoice } from "app/models/invoice";


declare let DateRangePicker: any;
@Component({
  selector: "app-invoice-client-list",
  templateUrl: "./invoice-client-list.component.html",
  styleUrls: ["./invoice-client-list.component.css"],
})
export class InvoiceClientListComponent implements OnInit, OnDestroy {
  rows = [];
  selected = [];
  objects: Company[];
  allCompanies: Company[];
  objectsSuppliers: Company[];
  clients: any[] = [];
  selectedCompany: Company;
  selectedClient: Company;
  currentOrders: Sale[] = [];
  checkBoxes: boolean[] = [];
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  client_id: number;

  columnMapValues = [
    ["cliente", "client"],
    ["folio", "folio"],
    ["producto", "product"],
    ["costo", "costo"],
    ["verFactura", "invoice"],
    ["eliminar", "delete"],
  ] as const;
  columnMap: Map<string, string> = new Map(this.columnMapValues);

  form: FormGroup;

  loading: boolean = false;
  sales: Sale[] = [];
  invoices: Invoice[] = [];
  dateRangePicker: any;

  @ViewChild("company_id", { static: true })
  companySelect: ElementRef;
  @ViewChild("supplier_id", { static: true })
  supplierSelect: ElementRef;
  @ViewChild("container", { static: true })
  container: ElementRef;
  @ViewChild(DatatableComponent) table: DatatableComponent;

  selectOptions = {
    selectFilter: true,
    selectNoResultText: "No se encontraron resultados",
    selectSearchPlaceholder: "Buscar...",
  };

  constructor(
    public dialog: MatDialog,
    public apiService: ClientsService,
    public salesService: SalesService,
    private fb: FormBuilder,
    private _snack: MatSnackBar,
    private dataService: DataService,
    public formService: FormValidatorService,
    public invoiceService: InvoiceService
  ) {
   
  }

  ngOnInit(): void {
    this.getData();
 
    this.getInvoices();
  }



    sender() {
    this.selected = [];
    this.loading = true;
    this.dataService.setLoadingValue(this.loading);
    if (!this.selectedCompany) {
      this.openSnack("snack-error", "Selecciona una compañia");
      this.loading = false;
      this.dataService.setLoadingValue(this.loading);
      return;
    }

    if (!this.selectedClient) {
      this.openSnack("snack-error", "Selecciona un cliente");
      this.loading = false;
      this.dataService.setLoadingValue(this.loading);
      return;
    }
    this.getFilteredSales(this.selectedClient.id, this.selectedCompany.id);
  }

  getRowClass = (row) => {
    return {
      'facturada': row.facturada
    };
  }

  

  getInvoices() {
    this.invoiceService.getInvoices().subscribe(
      (r) => {
        if (r.status === "Éxito") {
          this.invoices = r.data;
          console.log(this.invoices);
        }
      },
      (e) => {
        console.log(e);
      }
    );

  }

  filterSalesInvoices() {
    this.client_id = this.sales[0]?.client?.id;
    const invoices = this.invoices.filter((invoice) => {
      return (
        invoice.client?.id == this.client_id
      );
    });

    const facturadasIds = invoices.map(invoice => invoice.orders.map(order => order.id)).reduce((acc, val) => acc.concat(val), []);

    const sales = this.sales.filter((sale) => {
      return (
        sale.order_status.id > 3
      );
    });
    this.sales = sales.map(sale => ({
      ...sale,
      facturada: facturadasIds.includes(sale.id)
    }));
    this.rows = this.sales;
    this.loading = false;
    setTimeout(() => {
      this.dataService.setLoadingValue(this.loading);
    }, 400);

  }


  getFilteredSales(client_id: number, company_main_id: number) {
    this.salesService.getSales(undefined, undefined, undefined, false, client_id, company_main_id).subscribe(
      (r) => {
        this.loading = false;
        if (r.status === "Éxito") {
          this.sales = r.data;
          this.filterSalesInvoices();
        }
      },
      (e) => {
        this.loading = false;
        console.log(e);
      }
    );
  }

  changeDate(event: any) {
    const [startDate, endDate] = this.dateRangePicker.getDates();

    if (startDate && endDate) {
      this.dateRangePicker.datepickers[0].element?.click();
      this.container?.nativeElement?.click();
      this.dateRangePicker.datepickers[1].element?.click();
      this.container?.nativeElement?.click();
      const formattedStartDate =
        DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd");
      const formattedEndDate =
        DateTime.fromJSDate(endDate).toFormat("yyyy-MM-dd");
    }
  }

  setInitialDate() {
    this.dateRangePicker.setDates(
      DateTime.local().minus({ week: 1 }).toISODate(),
      DateTime.local().toISODate()
    );
  }
  ngOnDestroy(): void {}

  ngAfterViewInit() {
    new Select(this.companySelect.nativeElement, this.selectOptions);
    new Select(this.supplierSelect.nativeElement, this.selectOptions);
  }

  onActivate(event) {}

  onLimitChange(event: Event): void {
    const limit = (event.target as HTMLSelectElement).value;
    this.changePageLimit(limit);
    this.table.limit = this.form.controls.rowsPerPage.value;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor(
          (this.table.rowCount - 1) / this.table.limit
        );
      }
    });
  }

  changePageLimit(limit: any): void {
    this.form.controls.rowsPerPage.setValue(parseInt(limit, 10));
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected.filter(row => !row.facturada));
  }

  onSort(event: any): void {
    const sort = event.sorts[0];
    const column = this.columnMap.get(sort.prop);

    if (column) {
      this.rows.sort((a, b) => {
        if (typeof a[column] === "number") {
          if (sort.dir === "asc") return a[column] - b[column];
          else return b[column] - a[column];
        } else if (typeof a[column] === "string") {
          return (
            a[column].localeCompare(b[column]) * (sort.dir === "desc" ? -1 : 1)
          );
        } else if (typeof a[column] === "object") {
          if (column == "client") {
            return (
              a[column]?.company?.legal_name.localeCompare(
                b[column]?.company?.legal_name
              ) * (sort.dir === "desc" ? -1 : 1)
            );
          }

          if (column == "product") {
            return (
              a[column]?.name.localeCompare(b[column]?.name) *
              (sort.dir === "desc" ? -1 : 1)
            );
          }

          if (column == "created_at") {
            if (sort.dir === "asc") {
              return (
                new Date(a[column]).getTime() - new Date(b[column]).getTime()
              );
            } else {
              return (
                new Date(b[column]).getTime() - new Date(a[column]).getTime()
              );
            }
          }
        }
      });

      this.rows = [...this.rows];
    }
  }

  confirmDelete(id: number): void {
    const dialogRef = this.dialog.open(AlertComponent, {
      data: {
        body: "¿Deseas eliminar esta venta?",
        showBtnClose: true,
        isConfirm: true,
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) this.delete(id);
    });
  }

  delete(id: number): void {
    /*this.salesService.deleteSale(id).subscribe({
      next: (res) => {
        if (res.status === "Éxito") {
          this.sender();
          //this.getSales();
          this.openSnack("snack-success", "Venta eliminado");
        }
      },
      error: (e) => {
        this.openSnack(
          "snack-error",
          "Ha ocurrido un error al eliminar la venta"
        );
      },
    });*/
  }

  updateFilter(event: Event) {
    const val: string = (event.target as HTMLInputElement).value.toLowerCase();
    const temp: Sale[] = this.currentOrders.filter((d: Sale) => {
      return d.folio.toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.rows = temp;
    this.table.offset = 0;
  }

  getSelectedClient(companyId: number) {
    this.selectedClient = this.allCompanies.find(
      (company) => company.active && company.id == companyId
    );
    console.log(this.selectedClient);
  }

  getClientsbyCompany(companyMN: any) {
    const data = this.allCompanies.filter((company) => {
      if (company.clients.length !== 0) {
        return (
          company.active &&
          companyMN.company_clients.some((element) => {
            return element.id === company.clients[0]?.id;
          })
        );
      }
      return false;
    });
    this.clients = data;
    
  }

  openAssignInvoiceDialog(assing : boolean, id_order?: number) {

    const dialogRef = this.dialog.open(AssingClientInvoiceComponent, {
      width: "84vw",
      maxWidth: "84vw",
      height: "80vh",
      data: {
        orders: this.selected,
        assing: assing,
        id_order: id_order,
        invoices : this.invoices,
        allorders: this.sales,
        client_id: this.client_id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.invoiceService.getInvoices().subscribe((r) => {
          if (r.status === "Éxito") {
            this.selected = [];
            this.invoices = r.data;
            this.filterSalesInvoices();
          }
        });
      }
    });
  }

  getData(): void {
    this.apiService.readCompanies().subscribe(
      (r: InCompaniesRes) => {
        this.objects = r.data.filter((company) => {
          return company.active && company.main;
        });
        
        this.allCompanies = r.data.filter((company) => {
          return company.active;
        });
      },
      (error) => {
        throw error;
      }
    );
    
  }

  getSelectedCompany(id: number): void {
    const idNumber = Number(id);
    const company = this.objects.find((company) => company.id === idNumber);
    this.selectedCompany = company;

    if (company && company.main) this.getClientsbyCompany(company);
  }


  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
      verticalPosition: "top",
    });
  }
}
