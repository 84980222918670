import { AdditionalRequirement } from "./additionalRequirement";
import { Address } from "./address";
import { Attachment } from "./attachment";
import { Comment } from "./comment";
import { Company, CompanyClient } from "./company";
import { LabOrder } from "./labOrder";
import { LoadingType } from "./loadingType";
import { Measure } from "./measure";
import { OrderCost } from "./order-cost";
import { OrderStatus } from "./orderStatus";
import { PaymentMethod } from "./paymentMethod";
import { Product } from "./product";
import { RoutePriceTransportist } from "./routePriceTransportist";
import { Status } from "./status";
import { TransportType } from "./transportType";
import { User } from "./user";

export class Sale {
  id?: number;
  active?: boolean;
  folio?: string;
  load_date?: string;
  load_time?: string;
  end_date?: string;
  end_time?: string;
  seller_id?: number;
  client_id?: number;
  client_reference?: string;
  transportist_id?: number;
  transport_plate?: string;
  type_code?: string;
  created_at?: string;
  address_id?: number;
  zip_code?: string;
  vendor_id?: number;
  attendant_id?: number;
  transportist_route_price_id?: number;
  transport_type_id?: number;
  type_transport?: number;
  pump_measure_id?: number;
  loading_type_id?: number;
  payment_method_id?: number;
  additional_requirement_id?: number;
  product_id?: number;
  product_specifications?: string;
  agreed_price?: number;
  shipping_price?: number;
  internal_comission?: number;
  internal_commision?: number;
  external_commision?: number;
  distribution_cost?: number;
  route_price?: number;
  sold_liters?: number;
  discount?: number;
  total_dist_cost?: number;
  total_price_liter?: number;
  order_status_id?: number;
  state?: Status;
  product?: Product;
  company?: Company;
  seller?: Company;
  client?: CompanyClient;
  vendor?: User;
  transportist?: CompanyClient;
  address?: Address;
  number?: number;
  shippings?: any;
  priority?: number;
  alert?: boolean;
  additional_requirement?: AdditionalRequirement;
  loading_type?: LoadingType;
  payment_method?: PaymentMethod;
  pump_measure?: Measure;
  transport_type?: TransportType;
  transportist_route_price?: RoutePriceTransportist;
  order_status?: OrderStatus;
  alert_attended?: boolean;
  credit_debt_confirm?: boolean;
  tax?: number;
  ieps?: number;
  lab_confirm?: boolean;
  order_admin_confirm?: boolean;
  doc_ready_confirm?: boolean;
  order_comments?: Comment[];
  order_attachments?: Attachment[];
  lab_orders?: LabOrder[];
  lab_order?: LabOrder;
  pump?: any;
  check_own_transportist?: boolean;
  own_transportist_name?: string;
  pipe_one?: number;
  pipe_two?: number;
  sent_liters?: number;
  tank_readings?: Array<any>;
  order_costs?: Array<OrderCost>;

  constructor(sale: Sale) {
    this.id = sale.id || undefined;
    this.active = sale.active || true;
    this.folio = sale.folio || null;
    this.load_date = sale.load_date || null;
    this.load_time = sale.load_time || null;
    this.end_date = sale.end_date || null;
    this.end_time = sale.end_time || null;
    this.seller_id = sale.seller_id || undefined;
    this.client_id = sale.client_id || undefined;
    this.client_reference = sale.client_reference || null;
    this.product_id = sale.product_id || undefined;
    this.product_specifications = sale.product_specifications || null;
    this.agreed_price = sale.agreed_price || undefined;
    this.shipping_price = sale.shipping_price || undefined;
    this.transportist_id = sale.transportist_id || undefined;
    this.transport_plate = sale.transport_plate || null;
    this.created_at = sale.created_at || undefined;
    this.order_status_id = sale.order_status_id || undefined;
    this.attendant_id = sale.attendant_id || undefined;
    this.state = sale.state || undefined;
    this.product = sale.product || undefined;
    this.company = sale.company || undefined;
    this.client = sale.client || undefined;
    this.transportist = sale.transportist || undefined;
    this.vendor_id = sale.vendor_id || undefined;
    this.transportist_route_price_id =
      sale.transportist_route_price_id || undefined;
    this.type_transport = sale.type_transport || undefined;
    this.pump_measure_id = sale.pump_measure_id || undefined;
    this.loading_type_id = sale.loading_type_id || undefined;
    this.payment_method_id = sale.payment_method_id || undefined;
    this.additional_requirement_id =
      sale.additional_requirement_id || undefined;
    this.type_code = sale.type_code || undefined;
    this.address_id = sale.address_id || undefined;
    this.zip_code = sale.zip_code || undefined;
    this.internal_comission = sale.internal_comission || undefined;
    this.internal_commision = sale.internal_commision || undefined;
    this.external_commision = sale.external_commision || undefined;
    this.distribution_cost = sale.distribution_cost || undefined;
    this.route_price = sale.route_price || undefined;
    this.sold_liters = sale.sold_liters || undefined;
    this.discount = sale.discount || undefined;
    this.total_dist_cost = sale.total_dist_cost || undefined;
    this.total_price_liter = sale.total_price_liter || undefined;
    this.number = sale.number || 0;
    this.shippings = sale.shippings || undefined;
    this.priority = sale.priority || 0;
    this.alert = sale.alert || false;
    this.address = sale.address || undefined;
    this.additional_requirement = sale.additional_requirement || undefined;
    this.loading_type = sale.loading_type || undefined;
    this.payment_method = sale.payment_method || undefined;
    this.pump_measure = sale.pump_measure || undefined;
    this.transport_type = sale.transport_type || undefined;
    this.transportist_route_price = sale.transportist_route_price || undefined;
    this.order_status = sale.order_status || undefined;
    this.alert_attended = sale.alert_attended || undefined;
    this.credit_debt_confirm = sale.credit_debt_confirm || undefined;
    this.tax = sale.tax || undefined;
    this.ieps = sale.ieps || undefined;
    this.lab_confirm = sale.lab_confirm || undefined;
    this.order_admin_confirm = sale.order_admin_confirm || undefined;
    this.doc_ready_confirm = sale.doc_ready_confirm || undefined;
    this.order_comments = sale.order_comments || undefined;
    this.order_attachments = sale.order_attachments || undefined;
    this.vendor = sale.vendor || undefined;
    this.lab_order = sale.lab_order || undefined;
    this.pump = sale.pump || undefined;
    this.check_own_transportist = sale.check_own_transportist || false;
    this.own_transportist_name = sale.own_transportist_name || undefined;
    this.pipe_one = sale.pipe_one || undefined;
    this.pipe_two = sale.pipe_two || undefined;
    this.sent_liters = sale.sent_liters || undefined;
    this.tank_readings = sale.tank_readings || [];
    this.order_costs = sale.order_costs || [];
  }
}
