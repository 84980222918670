<div class="bg-white py-4 h-full w-full overflow-hidden">
    <!-- Header Container -->
    <div class="flex">
        <!-- Titulo Modal -->
        <div class="w-11/12 justify-between rounded-t mb-0 pb-8 border-0 bg-white">
            <div class="text-start pl-2">
                <h3 class="font-semibold text-lg">
                    Costos
                </h3>
            </div>
        </div>
        
        <!-- Boton cerrar Modal -->
        <div class="w-1/12 justify-end mb-0 pb-8">
            <button
            class="text-sky-600 cursor-pointer hover:text-sky-700 
            text-2xl pl-12 ease-linear transition-all duration-150" (click)="this.closeModal()">
            <i class="fas fa-times-circle"></i>
            </button>
        </div>
    </div>
  
    <!-- Contenidos -->
    <div class="overflow-y-auto h-full w-full pl-12">
        <!-- First Row -->
        <div class="flex flex-row">
            <!-- Folio -->
            <div class="w-1/2">
                <div class="container px-2">
                    <label
                        class="block uppercase text-slate-600 text-lg font-bold mb-2"
                        htmlFor="origin">
                        Compra Origen: {{this.purchase.folio}}
                    </label>
                </div>
            </div>
        </div>

        <!-- Second Row -->
        <label
            class="block uppercase text-slate-600 text-xs font-bold mb-2"
            htmlFor="product_specifications">
            Detalle de compra:
        </label>
        <div class="h-32 overflow-x-auto">
            <div class="flex flex-row pt-3" *ngFor="let detail of this.purchase.purchase_order_details">
                <!-- Producto Principal -->
                <div class="w-1/5">
                    <div class="container px-2">
                        <label
                            class="block uppercase text-slate-600 text-xs font-bold mb-2"
                            htmlFor="product">
                            Producto
                        </label>
                        <input
                            readonly
                            type="text"
                            name="product"
                            value="{{detail.product.name}}"
                            class="border-1 px-3 py-2 
                                placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm 
                                focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                    </div>
                </div>
    
                <!-- Litros -->
                <div class="w-1/5">
                    <div class="container px-2">
                        <label
                            class="block uppercase text-slate-600 text-xs font-bold mb-2"
                            htmlFor="liters">
                            Litros
                        </label>
                        <input
                            readonly
                            type="text"
                            name="liters"
                            value="{{detail.quantity}}"
                            class="border-1 px-3 py-2 
                                placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm 
                                focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                    </div>
                </div>
    
                <!-- Subtotal -->
                <div class="w-1/5">
                    <div class="container px-2">
                        <label
                            class="block uppercase text-slate-600 text-xs font-bold mb-2"
                            htmlFor="subtotal">
                            SubTotal
                        </label>
                        <input
                            readonly
                            type="text"
                            name="subTotal"
                            value="{{'$ '+detail.subtotal}}"
                            class="border-1 px-3 py-2 
                                placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm 
                                focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                    </div>
                </div>
    
                <!-- Costo Total -->
                <div class="w-1/5">
                    <div class="container px-2">
                        <label
                            class="block uppercase text-slate-600 text-xs font-bold mb-2"
                            htmlFor="total">
                            Costo Total
                        </label>
                        <input
                            readonly
                            type="text"
                            name="liters"
                            value="{{'$ '+detail.total}}"
                            class="border-1 px-3 py-2 
                                placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm 
                                focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                    </div>
                </div>
            </div>
        </div>

        <!-- Third Row -->
        <div class="flex flex-col pt-6">
            <label
                  class="block uppercase text-slate-600 text-xs font-bold mb-2"
                  htmlFor="product_specifications">
                  Costos Asociados:  
              </label>
            <!-- Table -->
            <div class="h-64 overflow-x-auto">
                <div class="flex w-full">
                    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8 w-full">
                        <div class="inline-block py-2 sm:px-6 lg:px-8 w-full">
                            <div class="overflow-hidden rounded w-full">
                                <table class="w-full border text-center text-sm font-light" aria-describedby="totals">
                                
                                    <thead class="border-b">
                                    <th class="px-2 text-start whitespace-nowrap border-r font-medium"></th>
                                    <th class="px-2 text-start whitespace-nowrap border-r font-medium">Servicio</th>
                                    <th class="px-2 text-start whitespace-nowrap border-r font-medium">Costo</th>
                                    </thead>
    
                                    <tbody class="w-full">
                                    <!-- Importe -->
                                    <tr class="border-b" *ngFor="let sub of this.purchase.subPurchases">
                                        <td class="px-2 text-start whitespace-nowrap border-r font-medium">
                                            {{sub.folio}}
                                        </td>
                                        <td class="px-2 text-start whitespace-nowrap border-r">
                                            {{sub.purchase_order_details[0].service.name}}
                                        </td>
                                        <td class="px-2 whitespace-nowrap border-r">
                                            {{"$ "+sub.purchase_order_details[0].total}}
                                        </td>
                                    </tr> 
    
                                    <tr class="border-b">
                                        <td class="px-2 text-start whitespace-nowrap border-r font-medium">
                                            
                                        </td>
                                        <td class="px-2 text-start whitespace-nowrap border-r font-medium">
                                            Total
                                        </td>
                                        <td class="px-2 text-start whitespace-nowrap border-r font-medium">
                                            {{this.total}}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                        </div>
                  </div>
                </div>
                </div>
            </div>
        </div>

        <!-- Fourth Row -->
        <!-- <label
            class="block uppercase text-slate-600 text-xs font-bold mb-2 pt-6"
            htmlFor="">
            Detalles extra:
        </label> -->
        <!-- <div class="flex flex-row">
            <div class="w-1/6">
                <div class="container px-2">
                    <label
                        class="block uppercase text-slate-600 text-xs font-bold mb-2"
                        htmlFor="molecula">
                        Molecula
                    </label>
                    <input
                        readonly
                        type="text"
                        name="molecula"
                        class="border-1 px-3 py-2 
                            placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm 
                            focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                </div>
            </div>
            
            <div class="w-1/6">
                <div class="container px-4">
                    <label
                        class="block uppercase text-slate-600 text-xs font-bold mb-2"
                        htmlFor="fletes">
                        Fletes
                    </label>
                    <input
                        readonly
                        type="text"
                        name="fletes"
                        class="border-1 px-3 py-2 
                            placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm 
                            focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                </div>
            </div>

            <div class="w-1/6">
                <div class="container px-4">
                    <label
                        class="block uppercase text-slate-600 text-xs font-bold mb-2"
                        htmlFor="rentaTanque">
                        Renta Tanque
                    </label>
                    <input
                        readonly
                        type="text"
                        name="rentaTanque"
                        class="border-1 px-3 py-2 
                            placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm 
                            focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                </div>
            </div>

            <div class="w-1/6">
                <div class="container px-4">
                    <label
                        class="block uppercase text-slate-600 text-xs font-bold mb-2"
                        htmlFor="importacion">
                        Importacion
                    </label>
                    <input
                        readonly
                        type="text"
                        name="importacion"
                        class="border-1 px-3 py-2 
                            placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm 
                            focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                </div>
            </div>

            <div class="w-1/6">
                <div class="container px-4">
                    <label
                        class="block uppercase text-slate-600 text-xs font-bold mb-2"
                        htmlFor="factura">
                        Costo de Factura
                    </label>
                    <input
                        readonly
                        type="text"
                        name="factura"
                        class="border-1 px-3 py-2 
                            placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm 
                            focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                </div>
            </div>
        </div> -->
    </div>
  
    <!-- Buttons -->
    <div class="flex items-center justify-end absolute bottom-0 right-0 pb-3 pr-3 pt-3 w-full bg-white">
      <button
        class="bg-slate-500 text-white active:bg-slate-500 font-bold uppercase text-xs 
        px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 
        ease-linear transition-all duration-150" (click)="this.closeModal()">
        Cerrar
      </button>
      
      <!-- Send Data -->
      <!-- <button class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-xs 
        px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 
        ease-linear transition-all duration-150">
        Guardar
      </button> -->
    </div>
  </div>