<div class="">
    <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-200 border-0">
      <div class="relative flex flex-col min-w-0 break-words w-full rounded">
        <div
          class="grid grid-cols-2 gap-2 rounded-t mb-0 px-4 py-3 border-0 pt-6">
          <div class="basis-1/2 flex justify-start">
            <span
              class="z-10 h-full leading-snug font-normal
              absolute text-center text-slate-300 bg-transparent
              rounded text-base items-center justify-center w-8 pl-3 py-3">
              <i class="fa fa-search"></i>
            </span>

            <input
              type="text"
              class="w-48 pl-12 py-3 rounded-full border text-sm border-gray-300 focus:outline-none focus:border-blue-400"
              (keyup)="updateFilter($event)"
              placeholder="Buscar"/>
          </div>
          <div class="basis-1/2 flex justify-end">
            <button
              class="text-neutral-800
              font-bold uppercase text-sm px-6 py-3 outline-none focus:outline-none
              mr-1 mb-1 ease-linear"
              (click)="return()" type="button">
              <i class="fas fa-backspace"></i>
            </button>
          </div>
        </div>

        <div class="grid">
          <div class="block w-full overflow-x-auto rounded-lg">

            <ngx-datatable
              #table class="material" [columnMode]="ColumnMode.force"
              [headerHeight]="50" [footerHeight]="50" rowHeight="auto"
              [limit]="10" [rows]="data">

              <ngx-datatable-column name="Id">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.id }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Regimen Fiscal">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.regimen_fiscal.regimen_fiscal }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Razón Social">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.legal_name }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Nombre de Contacto">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.contact_name }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Numero de Contacto">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.contact_phone }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Principal">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span
                    [ngClass]="{
                      'text-green-600': row.main,
                      'text-red-600': !row.main,
                      'bg-green-200': row.main,
                      'bg-red-200': !row.main
                    }"
                    class="text-xs font-semibold
                    inline-block py-1 px-2 rounded-full uppercase last:mr-0 mr-1">
                    {{ row.main ? "Si" : "No" }}
                  </span>
                </ng-template>
              </ngx-datatable-column>

              <!-- <ngx-datatable-column name="Comisiones">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <button
                    class="bg-yellow-500 text-white px-2 py-1 rounded-full text-center"
                    (click)="openData(row)">
                    <i class="fas fa-box"></i>
                  </button>
                </ng-template>
              </ngx-datatable-column> -->

              <ngx-datatable-column name="Comisiones">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <button
                    class="bg-green-500 text-white px-2 py-1 rounded-full text-center"
                    title="Agregar comisión"
                    (click)="openCommisions(row)">
                    <i class="fas fa-money-check"></i>
                  </button>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="CFDI">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <button
                    class="bg-blue-500 text-white px-2 py-1 rounded-full text-center"
                    (click)="openCFID(row)">
                    <i class="fas fa-info-circle"> </i>
                  </button>
                </ng-template>
              </ngx-datatable-column>

              <!-- <ngx-datatable-column name="Clientes">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <button *ngIf="row.main"
                      class="bg-emerald-500 text-white px-2 py-1 rounded-full text-center"
                      (click)="openDialogClients(row)">
                      <i class="fa fa-server"> </i>
                    </button>
                  </ng-template>
                </ngx-datatable-column> -->

              <!-- <ngx-datatable-column name="Opciones">
                <ng-template
                  #buttonsTemplate
                  let-row="row"
                  let-value="value"
                  ngx-datatable-cell-template>
                  <div class="flex">

                    <button
                      class="bg-emerald-500 text-white px-2 py-1 rounded-full text-center"
                      (click)="openDialog(row, 'edit')">
                      <i class="fas fa-edit"> </i>
                    </button>

                    <button class="bg-red-500 text-white mx-8 px-2 py-1 rounded-full text-center"
                        (click)="delete(row.id)">
                        <i class="fas fa-trash-alt">
                        </i>
                      </button>

                  </div>
                </ng-template>
              </ngx-datatable-column> -->

              <ngx-datatable-footer>
                <ng-template
                  ngx-datatable-footer-template
                  let-rowCount="rowCount"
                  let-pageSize="pageSize"
                  let-selectedCount="selectedCount"
                  let-curPage="curPage"
                  let-offset="offset"
                  let-isVisible="isVisible"
                >
                  <div class="page-count">{{ rowCount }} total</div>

                  <div>
                    <form [formGroup]="form">
                      <div>
                        <div
                          class="flex justify-content-between align-items-center"
                        >
                          <label
                            class="flex align-items-center mx-4 my-2"
                            for="rowsPerPage"
                            >Mostrar por página:
                          </label>

                          <select
                            class="rounded form-control mx-25"
                            formControlName="rowsPerPage"
                            (change)="onLimitChange($event)"
                            id="rowsPerPage"
                            name="rowsPerPage"
                          >
                            <option [value]="5">5</option>
                            <option [value]="10">10</option>
                            <option [value]="25">25</option>
                            <option [value]="50">50</option>
                          </select>
                        </div>
                      </div>
                    </form>
                  </div>

                  <datatable-pager
                    [pagerLeftArrowIcon]="'datatable-icon-left'"
                    [pagerRightArrowIcon]="'datatable-icon-right'"
                    [pagerPreviousIcon]="'datatable-icon-prev'"
                    [pagerNextIcon]="'datatable-icon-skip'"
                    [page]="curPage"
                    [size]="pageSize"
                    [count]="rowCount"
                    (change)="table.onFooterPage($event)"
                  >
                  </datatable-pager>
                </ng-template>
              </ngx-datatable-footer>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
