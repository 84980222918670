import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { Form, FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";
import { ClientsService } from "app/apiServices/clients.service";
import { SalesService } from "app/apiServices/sales.service";
import { UserServicesService } from "app/apiServices/user-services.service";
import { AlertComponent } from "app/components/alerts/alert/alert.component";
import { NotificationsComponent } from "app/components/alerts/notifications/notifications.component";
import { FormComponent } from "app/components/auth-prices/form/form.component";
import { FormValidatorService } from "app/core/service/form-validator.service";
import { InCompaniesRes } from "app/interfaces/company.interface";
import { InCostTypeRes } from "app/interfaces/order-cost.interface";
import { InSalesRes } from "app/interfaces/sale";
import { InUserRes } from "app/interfaces/user.interface";
import { Company } from "app/models/company";
import { OrderCost, OrderCostType } from "app/models/order-cost";
import { Product, ProductMixes } from "app/models/product";
import { User } from "app/models/user";
import { DataService } from "app/services/data.service";
import { TabsOptions, TabsInterface, Tabs } from "flowbite";
import { Select, initTE } from "tw-elements";

@Component({
  selector: "app-cost-dialog",
  templateUrl: "./cost-dialog.component.html",
  styleUrls: ["./cost-dialog.component.css"],
})
export class CostDialogComponent {
  dialogTitle: string;
  form: FormGroup;
  formCost: FormGroup;
  formMub: FormGroup;
  edit: boolean = false;
  orderCosts: OrderCost[] = [];
  costs: OrderCostType[] = [];
  suppliers: Company[] = [];
  vendors: User[] = [];
  loggedUser: User;
  isVendor: boolean;
  cost_product: number;
  total_cost_product: number;

  ColumnMode = ColumnMode;
  columnMapValues = [
    ["id", "id"],
    ["vendedor", "user"],
    ["proveedor", "company_supplier"],
    ["costo", "cost"],
    ["cantidad", "quantity"],
    ["total", "quantity"],
  ] as const;
  columnMap: Map<string, string> = new Map(this.columnMapValues);
  loading: boolean = false;

  options: TabsOptions = {
    defaultTabId: "sale-data",
    activeClasses:
      "text-blue-600 hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-400 border-blue-600 dark:border-blue-500",
    inactiveClasses:
      "text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300",
    onShow: () => {},
  };
  tabs: TabsInterface;
  selectOptions = {
    selectFilter: true,
    selectNoResultText: "No se encontraron resultados",
    selectSearchPlaceholder: "Buscar...",
  };

  validationMessages = {
    order_cost_type_id: [
      { type: "required", message: "Ingresa un tipo de costo" },
    ],
    company_supplier_id: [
      { type: "required", message: "Ingresa un proveedor" },
    ],
    user_id: [{ type: "required", message: "Ingresa un vendedor" }],
    quantity: [{ type: "required", message: "Ingresa la cantidad" }],
    cost: [{ type: "required", message: "Ingresa el costo" }],
  };

  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild("supplierId", { static: true })
  suppliersSelect: ElementRef;
  supplierSelecttEl: any;
  @ViewChild("typeCost", { static: true })
  typeCostSelect: ElementRef;
  typeCostSelecttEl: any;
  @ViewChild("userId", { static: true })
  vendorsSelect: ElementRef;
  vendorSelecttEl: any;
  currentOrderCost: OrderCost;

  constructor(
    private dialogRef: MatDialogRef<FormComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public formService: FormValidatorService,
    private orderService: SalesService,
    private companyService: ClientsService,
    private userService: UserServicesService,
    private dataService: DataService,
    private _snack: MatSnackBar
  ) {
    this.loggedUser = this.dataService.userValue;
    this.isVendor = this.loggedUser?.roles[0]?.id === 4;
    this.data = data?.data;
    this.dialogTitle = this.data?.product?.name;
    this.orderCosts = this.data?.order_costs ?? [];
    this.form = this.createForm();
    this.formCost = this.createCostForm();
    this.formMub = this.createMubForm();

    /* Calculus Function */
    let sumPrices = 0;
    let sumQuantity = 0;
    
    console.log("Debug Data", this.data)
    this.data?.product?.products.forEach((product: Product) => {
      const compoundProduct = this.data?.product?.compound_products.find(
        (cp: ProductMixes) => cp.element_product_id == product?.id
      );

      const qty = this.data?.sold_liters * (compoundProduct?.percentage / 100);
      sumQuantity += qty;
      sumPrices += qty * product?.list_price;
    });

    console.log("Checking valors... ", sumPrices, "/", sumQuantity)
    this.cost_product = sumPrices / sumQuantity;
    this.total_cost_product = sumPrices;
    
    console.log("Debugging", this.cost_product, this.total_cost_product)

    this.getTypeCosts();
    this.getCompanies();
    this.getVendors();
  }

  ngAfterViewInit(): void {
    this.tabs = new Tabs(
      [
        {
          id: "sale-data",
          triggerEl: document.querySelector("#sale-data-tab"),
          targetEl: document.querySelector("#sale-data"),
        },
        {
          id: "cost",
          triggerEl: document.querySelector("#cost-tab"),
          targetEl: document.querySelector("#cost"),
        },
        {
          id: "mub",
          triggerEl: document.querySelector("#mub-tab"),
          targetEl: document.querySelector("#mub"),
        },
      ],
      this.options
    );
    initTE({ Select });
    this.typeCostSelecttEl = new Select(
      this.typeCostSelect.nativeElement,
      this.selectOptions
    );
    this.supplierSelecttEl = new Select(
      this.suppliersSelect.nativeElement,
      this.selectOptions
    );

    if (!this.isVendor) {
      this.vendorSelecttEl = new Select(
        this.vendorsSelect.nativeElement,
        this.selectOptions
      );
    }
  }

  createForm(): FormGroup {
    return this.fb.group({
      sold_liters: [{ value: this.data?.sold_liters, disabled: true }],
      total_price_liter: [
        {
          value: this.leadingZero(this.data?.total_price_liter?.toString()),
          disabled: true,
        },
      ],
      total_sale: [
        {
          value: this.leadingZero(
            (this.data?.sold_liters * this.data?.total_price_liter)?.toString()
          ),
          disabled: true,
        },
      ],
    });
  }

  createCostForm(orderCost: OrderCost = null): FormGroup {
    return this.fb.group({
      company_supplier_id: [orderCost?.company_supplier?.id ?? null, null],
      order_cost_type_id: [orderCost?.order?.id ?? null, Validators.required],
      user_id: [orderCost?.user?.id ?? null, null],
      quantity: [orderCost?.quantity ?? 0, Validators.required],
      cost: [
        this.leadingZero(orderCost?.cost?.toString()) ?? this.leadingZero("0"),
        Validators.required,
      ],
      total: [
        {
          value: this.leadingZero(
            (orderCost?.quantity * orderCost?.cost)?.toString()
          ),
          disabled: false,
        },
      ],
    });
  }

  calculus(): number {
    let sumPrices = 0;
    let sumQuantity = 0;

    this.data?.product?.products.forEach((product: Product) => {
      const compoundProduct = this.data?.product?.compound_products.find(
        (cp: ProductMixes) => cp.element_product_id == product?.id
      );

      const qty = this.data?.sold_liters * (compoundProduct?.percentage / 100);
      sumQuantity += qty;
      sumPrices += qty * product?.list_price;
    });
    console.log("SumPrices", sumPrices)
    return sumPrices;
  }

  createMubForm(): FormGroup {
    let calculusV = this.calculus()
    const tmpTotalCosts = this.orderCosts.map(
      (cost) => cost?.quantity * cost?.cost
    );
    const totalCosts =
      tmpTotalCosts.length > 0 ? tmpTotalCosts.reduce((a, b) => a + b) : 0;
    const totalSale = this.data?.sold_liters * this.data?.total_price_liter;
    console.log("Debug", totalSale, totalCosts, calculusV)
    const mub = totalSale - totalCosts - calculusV;
    console.log("MUB", mub)

    return this.fb.group({
      total_sales: [
        {
          value: this.leadingZero(totalSale?.toString()),
          disabled: true,
        },
      ],
      total_costs: [
        {
          value: this.leadingZero(totalCosts.toString()),
          disabled: true,
        },
      ],
      total_mub: [
        {
          value: `$ ${this.leadingZero((mub?.toFixed(2)).toString())}`,
          disabled: true,
        },
      ],
    });
  }

  updateMubForm(): void {
    let calculusV = this.calculus()
    let sumPrices = 0;
    let sumQuantity = 0;
    
    console.log("Debug Data", this.data)
    this.data?.product?.products.forEach((product: Product) => {
      const compoundProduct = this.data?.product?.compound_products.find(
        (cp: ProductMixes) => cp.element_product_id == product?.id
      );

      const qty = this.data?.sold_liters * (compoundProduct?.percentage / 100);
      sumQuantity += qty;
      sumPrices += qty * product?.list_price;
    });

    console.log("Checking valors... ", sumPrices, "/", sumQuantity)
    this.cost_product = sumPrices / sumQuantity;
    const tmpTotalCosts = this.orderCosts.map(
      (cost) => cost?.quantity * cost?.cost
    );
    const totalCosts =
      tmpTotalCosts.length > 0 ? tmpTotalCosts.reduce((a, b) => a + b) : 0;
    const totalSale = this.data?.sold_liters * this.data?.total_price_liter;
    console.log("Debug", totalSale, totalCosts, calculusV)
    const mub = totalSale - totalCosts - calculusV;
    console.log("MUB", mub)

    this.formMub.controls.total_sales.patchValue(
      this.leadingZero(totalSale?.toString())
    );
    this.formMub.controls.total_costs.patchValue(
      this.leadingZero(totalCosts.toString())
    );
    this.formMub.controls.total_mub.patchValue(
      this.leadingZero(mub?.toString())
    );
  }

  getProductCost(product: Product): number {
    const compoundProduct = this.data?.product?.compound_products.find(
      (cp) => cp.element_product_id == product?.id
    );
    const qty = this.data?.sold_liters * (compoundProduct?.percentage / 100);
    return qty * product?.list_price;
  }

  getPercentage(product: Product): number {
    const compoundProduct = this.data?.product?.compound_products.find(
      (cp) => cp.element_product_id == product?.id
    );
    return compoundProduct?.percentage;
  }

  fillToEdit(orderCost: OrderCost): void {
    this.clear();
    this.edit = true;
    this.currentOrderCost = orderCost;
    this.formCost.controls.company_supplier_id.patchValue(
      orderCost?.company_supplier?.id
    );
    this.supplierSelecttEl.setValue(orderCost?.company_supplier?.id);
    this.formCost.controls.user_id.patchValue(orderCost?.user?.id);
    this.vendorSelecttEl?.setValue(orderCost?.user?.id);
    this.formCost.controls.order_cost_type_id.patchValue(
      orderCost?.order_cost_type?.id
    );
    this.typeCostSelecttEl.setValue(orderCost?.order_cost_type?.id);
    this.formCost.controls.quantity.patchValue(orderCost?.quantity);
    this.formCost.controls.cost.patchValue(
      this.leadingZero(orderCost?.cost?.toString())
    );
    this.formCost.controls.total.patchValue(
      this.leadingZero((orderCost?.quantity * orderCost?.cost)?.toString())
    );
    this.formCost.updateValueAndValidity();
  }

  clear(): void {
    this.currentOrderCost = null;
    this.edit = false;
    this.typeCostSelecttEl.setValue(null);
    this.vendorSelecttEl?.setValue(null);
    this.supplierSelecttEl.setValue(null);
    this.formCost.reset();
  }

  save(type: string = "create"): void {
    if (this.formCost.invalid) {
      this.openSnack("error", "Ingresa todos los datos");
      return;
    }

    const data: OrderCost = {
      ...this.formCost.value,
      user_id: this.isVendor
        ? this.loggedUser?.id
        : this.formCost.value.user_id,
      order_id: this.data.id,
      total:
        Number(parseFloat(this.formCost.value.cost.toString())) *
        Number(parseFloat(this.formCost.value.quantity.toString())),
    };

    if (type == "create") {
      this.orderService.createOrderCost(data).subscribe({
        next: (res) => {
          if (res.status === "Éxito") {
            this.openSnack("snack-success", "Costo agregado correctamente");
            const order_cost_type_id = this.costs.find(
              (cost) => cost.id == data.order_cost_type_id
            );
            data.order_cost_type = order_cost_type_id;
            const user = this.isVendor
              ? this.loggedUser
              : this.vendors.find((vendor) => vendor.id == data.user_id);
            data.user = user;
            data.company_supplier = {
              ...this.suppliers.find(
                (company) => company.id == data.company_supplier_id
              ),
            };
            data.total =
              Number(parseFloat(data.cost.toString())) *
              Number(parseFloat(data.quantity.toString()));
            this.orderCosts = [...this.orderCosts, data];
            this.updateMubForm();
            this.clear();
          }
        },
        error: (e) => {
          this.openSnack(
            "snack-error",
            "Ha ocurrido un error al crear el costo"
          );
        },
      });
    } else {
      data.id = this.currentOrderCost?.id;
      this.orderService.updateOrderCost(data).subscribe({
        next: (res) => {
          if (res.status === "Éxito") {
            this.openSnack("snack-success", "Costo actualizado correctamente");

            const index = this.orderCosts.findIndex(
              (cost) => cost.id == data.id
            );

            data.order_cost_type = this.currentOrderCost?.order_cost_type;
            const user = this.isVendor
              ? this.loggedUser
              : this.vendors.find((vendor) => vendor.id == data.user_id);
            data.user = user;
            data.company_supplier = {
              ...this.suppliers.find(
                (company) => company.id == data.company_supplier_id
              ),
            };
            data.total =
              Number(parseFloat(data.cost.toString())) *
              Number(parseFloat(data.quantity.toString()));
            this.orderCosts[index] = {
              ...data,
            };
            this.orderCosts = [...this.orderCosts];
            this.updateMubForm();
            this.clear();
          }
        },
        error: (e) => {
          this.openSnack(
            "snack-error",
            "Ha ocurrido un error al actualizar el costo"
          );
        },
      });
    }
  }

  delete(id: number): void {
    this.orderService.deleteOrderCost(id).subscribe({
      next: (res) => {
        if (res.status === "Éxito") {
          const index = this.orderCosts.findIndex((cost) => cost.id == id);
          this.orderCosts.splice(index, 1);
          this.orderCosts = [...this.orderCosts];
          this.updateMubForm();
        }
      },
      error: (e) => {
        this.openSnack(
          "snack-error",
          "Ha ocurrido un error al eliminar el costo"
        );
      },
    });
  }

  getTypeCosts(): void {
    const hasOrderCosts = this.orderCosts.length > 0;
    const fleetCost =
      hasOrderCosts && this.orderCosts.find((c) => c?.order_cost_type?.id == 1);
    const internComission =
      hasOrderCosts && this.orderCosts.find((c) => c?.order_cost_type?.id == 2);
    const externComission =
      hasOrderCosts && this.orderCosts.find((c) => c?.order_cost_type?.id == 3);

    this.orderService.getTypeCosts().subscribe((res: InCostTypeRes) => {
      this.costs = res.data;
    });
  }

  getCompanies(): void {
    this.companyService.readCompanies().subscribe((res: InCompaniesRes) => {
      this.suppliers = res.data.filter(
        (c) =>
          c.active &&
          (c.transportists.filter((t) => t.active).length > 0 ||
            c.suppliers.filter((s) => s.active).length > 0)
      );
    });
  }

  getVendors(): void {
    this.userService.readUsers().subscribe((res: InUserRes) => {
      this.vendors = res.data.filter(
        (user) => user.active && user?.roles[0]?.id === 4
      );
    });
  }

  onLeadingZero(event: Event): void {
    const num = (event.target as HTMLInputElement).value
      .replace("$", "")
      .replace(",", "")
      .replace(" ", "");
    const dec = num.split(".")[1];
    const len = dec && dec.length > 2 ? dec.length : 2;
    (event.target as HTMLInputElement).value = `$ ${Number(num).toFixed(len)}`;
  }

  leadingZero(num: string): string {
    const dec = num?.split(".")[1];

    if (!dec && !num) return "$ 0.00";
    if (!dec && num == "0") return "0.00";
    else if (!dec && num != "0") return `${num}.00`;

    const len = dec && dec.length > 2 ? dec.length : 2;
    const fixed = Number(num).toFixed(len);
    return fixed;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  confirmDelete(id: number): void {
    const dialogRef = this.dialog.open(AlertComponent, {
      data: {
        body: "¿Deseas eliminar este costo?",
        showBtnClose: true,
        isConfirm: true,
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) this.delete(id);
    });
  }

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
    });
  }
}
