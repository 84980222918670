<div class="container bg-white mx-auto px-4 h-full py-4 overflow-hidden">
  <div
    class="flex justify-between rounded-t mb-0 px-4 py-3 border-0 pt-6 bg-white"
  >
    <div class="flex">
      <div class="text-center">
        <h3 class="font-semibold text-lg">
          Formulario para Relaciones en {{ dataSource.legal_name }}
        </h3>
      </div>
    </div>
  </div>

  <form class="mx-4 my-6 bg-white" [formGroup]="form">
    <div class="w-full mb-3 px-2">
      <label for="company_clients">Clientes</label>
      <ng-multiselect-dropdown
        name="company_clients"
        formControlName="company_clients"
        class="w-2/3"
        [placeholder]="'Selecciona un Cliente'"
        [settings]="dropdownClientSettings"
        maxHeight="10"
        [data]="clientsList"
        [(ngModel)]="selectedClientItems"
        noDataLabel="No hay datos"
        (onSelect)="onClientSelect($event)"
        (onSelectAll)="onSelectAll($event)"
      >
      </ng-multiselect-dropdown>
    </div>

    <div class="w-full mb-3 px-2">
      <label for="company_suppliers">Proveedores</label>
      <ng-multiselect-dropdown
        name="company_suppliers"
        formControlName="company_suppliers"
        class="w-2/3"
        [placeholder]="'Selecciona una Proveedor'"
        [settings]="dropdownSupplierSettings"
        maxHeight="10"
        [data]="suppliersList"
        [(ngModel)]="selectedSupplierItems"
        noDataLabel="No hay datos"
        (onSelect)="onSupplierSelect($event)"
        (onSelectAll)="onSelectAll($event)"
      >
      </ng-multiselect-dropdown>
    </div>
    <div class="w-full mb-3 px-2">
      <div class="flex items-center justify-end mt-16">
        <button
          class="bg-slate-500 text-white active:bg-slate-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          (click)="closeModal({})"
        >
          Cancelar
        </button>
        <button
          class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          (click)="save()"
        >
          Agregar
        </button>
      </div>
    </div>
  </form>
</div>
