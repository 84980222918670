import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { Sale } from "app/models/sale";

@Component({
  selector: "app-sale-detail",
  templateUrl: "./sale-detail.component.html",
  styleUrls: ["./sale-detail.component.css"],
})
export class SaleDetailComponent {
  dataSource: Sale;
  form: FormGroup;
  dialogTitle = "Detalle Venta";

  constructor(
    private dialogRef: MatDialogRef<SaleDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private fb: FormBuilder
  ) {
    if (data) {
      this.dataSource = data.data;
      this.form = this.createForm();
    }
  }

  createForm() {
    return this.fb.group({
      load_date: [{ value: this.dataSource.load_date, disabled: true }],
      load_time: [{ value: this.dataSource.load_time, disabled: true }],
      end_date: [{ value: this.dataSource.end_date, disabled: true }],
      end_time: [{ value: this.dataSource.end_time, disabled: true }],
      agreed_price: [{ value: this.dataSource.agreed_price, disabled: true }],
      distribution_cost: [
        { value: this.dataSource.distribution_cost, disabled: true },
      ],
      internal_commision: [
        { value: this.dataSource.internal_commision, disabled: true },
      ],
      external_commision: [
        { value: this.dataSource.external_commision, disabled: true },
      ],
      route_price: [
        { value: this.dataSource.route_price, disabled: true },
      ],
      shipping_price: [
        { value: this.dataSource.shipping_price, disabled: true },
      ],
      discount: [{ value: this.dataSource.discount, disabled: true }],
      total_dist_cost: [
        { value: this.dataSource.total_dist_cost, disabled: true },
      ],
      total_price_liter: [
        { value: this.dataSource.total_price_liter, disabled: true },
      ],
      sold_liters: [{ value: this.dataSource.sold_liters, disabled: true }],
    });
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
