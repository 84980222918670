<div class="">
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-200 border-0">
      <div class="relative flex flex-col min-w-0 break-words w-full rounded">

        <div class="md:flex md:flex-column w-full pl-6 pt-6"><b>Descarga de reporte</b></div>
        <!-- Top Lane -->
        <div class="flex flex-row column ">

          <div class="sm:flex sm:flex-row md:grid md:grid-cols-2 md:gap-2 rounded-t mb-0 px-4 py-3 border-0 pt-6">
              <div class="basis-1/2 flex justify-start">
                <div class="pl-4 text-center">
                    <label for="compras" class="block uppercase text-slate-600 text-xs font-bold mb-2">
                        Compras
                    </label>
                    <input
                    type="radio" name="check"
                    class="rounded-full border border-gray-300 focus:outline-none focus:border-blue-400"
                    (keyup)="updateFilter($event)" [value]="purchase" (click)="purchaseCheck($event)" [checked]="purchase"
                    placeholder="Compras"/>
                </div>
                <div class="pl-4 text-center">
                      <label for="compras" class="block uppercase text-slate-600 text-xs font-bold mb-2">
                          Ventas
                      </label>
                      <input
                      type="radio" name="check"
                      class="rounded-full border border-gray-300 focus:outline-none focus:border-blue-400"
                      (keyup)="updateFilter($event)" [value]="!purchase" (click)="sellingCheck($event)" [checked]="!purchase"
                      placeholder="Compras"/>
                  </div>
              </div>

              <div class="flex justify-end">
                <div
                  id="dateRangePickerId"
                  date-rangepicker
                  class="w-full flex items-center">
                  <div class="w-full relative">
                    <div
                      class="w-full pl-3 absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                      <svg
                        class="w-4 h-4 text-black"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20">
                        <path
                          d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"
                        />
                      </svg>
                    </div>
                    <input
                      name="start"
                      type="text"
                      class="bg-gray-50 border border-gray-300 text-gray-900 placeholder-current pl-12 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
                      placeholder="Fecha de inicio"
                      (changeDate)="changeDate($event)"/>
                  </div>
                  <span class="mx-4 text-black font-medium text-base">a</span>
                  <div class="w-full relative">
                    <div
                      class="w-full pl-3 absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                      <svg
                        class="w-4 h-4 text-black"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20">
                        <path
                          d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
                      </svg>
                    </div>
                    <input
                      name="end"
                      type="text"
                      class="bg-gray-50 border border-gray-300 pl-12 text-gray-900 placeholder-current text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
                      placeholder="Fecha de fin"
                      (changeDate)="changeDate($event)"/>
                  </div>
                </div>
                <div class="flex justify-center items-center mx-3">
                </div>
                <button
                  class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-sm px-6 py-3 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  (click)="openDialog()">
                  <i class="fas fa-file-download"></i>
                </button>
              </div>

              <div class="basis-1/2 flex justify-end">
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
