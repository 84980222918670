import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { ModalConfirmationComponent } from "app/components/modals/modal-confirmation/modal-confirmation.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FormValidatorService } from "app/core/service/form-validator.service";
import { Category } from "app/models/category";
import { CatalogServicesService } from "app/apiServices/catalog-services.service";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { NotificationsComponent } from "../alerts/notifications/notifications.component";
import { DataService } from "app/services/data.service";
import { View } from "app/models/view";
import { RoleViews } from "app/models/role-view";

@Component({
  selector: "app-categories",
  templateUrl: "./categories.component.html",
  styleUrls: ["../../../../src/styles.css", "./categories.component.css"],
})
export class CategoriesComponent implements OnInit {
  rows: Category[] = [
    { id: 1, name: "Ejemplo1", order: 1 },
    { id: 2, name: "Ejemplo2", order: 2 },
    { id: 3, name: "Ejemplo3", order: 3 },
    { id: 4, name: "Ejemplo4", order: 4 },
    { id: 5, name: "Ejemplo5", order: 5 },
    { id: 6, name: "Ejemplo6", order: 6 },
  ];
  columnMapValues = [
    ["id", "id"],
    ["categoria", "name"],
    ["orden", "order"],
    ["estatus", "active"],
  ] as const;
  columnMap: Map<string, string> = new Map(this.columnMapValues);
  temp: Category[] = [];

  objects: Category[];

  showAlert: boolean = false;
  alertTitle: string;
  form: FormGroup;

  @ViewChild(DatatableComponent) table: DatatableComponent;
  columnMode = ColumnMode;
  loading: boolean = false;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    public apiService: CatalogServicesService
  ) {
    this.form = this.fb.group({
      rowsPerPage: [10],
    });
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.loading = true;
    this.apiService.readCategories().subscribe(
      (r) => {
        this.loading = false;
        this.objects = r.data;
        this.temp = this.objects;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  updateFilter(event: Event) {
    const val: string = (event.target as HTMLInputElement).value.toLowerCase();

    const temp: Category[] = this.temp.filter((d: Category) => {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.objects = temp;
    this.table.offset = 0;
  }

  openDialog(data: Category, action: string) {
    this.alertTitle =
      action == "store"
        ? "Categoria agregada con éxito"
        : "Categoria editada con éxito";

    const dialogRef = this.dialog.open(CategoriesForm, {
      width: "600px",
      data: {
        action,
        data,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getData();
    });
  }

  openDialogDelete(roleId: number) {
    const dialogRef = this.dialog.open(ModalConfirmationComponent, {
      width: "600px",
      data: {
        title: "¿Estas seguro de desactivar esta Categoria?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.apiService.deleteCategory(roleId).subscribe(
          (r) => {
            this.getData();
          },
          (error) => {}
        );
      }
    });
  }

  onLimitChange(event: Event): void {
    const limit = (event.target as HTMLSelectElement).value;
    this.changePageLimit(limit);
    this.table.limit = this.form.controls.rowsPerPage.value;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor(
          (this.table.rowCount - 1) / this.table.limit
        );
      }
    });
  }

  onSort(event: any): void {
    const sort = event.sorts[0];
    const column = this.columnMap.get(sort.prop);

    if (column) {
      this.objects.sort((a, b) => {
        if (typeof a[column] === "number") {
          if (sort.dir === "asc") return a[column] - b[column];
          else return b[column] - a[column];
        } else if (typeof a[column] === "string") {
          return (
            a[column].localeCompare(b[column]) * (sort.dir === "desc" ? -1 : 1)
          );
        }
      });

      this.objects = [...this.objects];
    }
  }

  changePageLimit(limit: any): void {
    this.form.controls.rowsPerPage.setValue(parseInt(limit, 10));
  }

  closeAlert(event: boolean) {
    this.showAlert = event;
  }

  delete(roleId: number) {
    this.openDialogDelete(roleId);
    this.getData();
  }
}

@Component({
  selector: "categories-form",
  templateUrl: "CategoriesForm.html",
  styleUrls: ["../../../../src/styles.css"],
})
export class CategoriesForm implements OnInit {
  form: FormGroup;
  dataSource: any;
  edit = false;
  dialogTitle: string;
  validationMessages = {
    name: [{ type: "required", message: "Ingrese un nombre" }],
    order: [{ type: "required", message: "Debe llevar un order" }],
  };

  apiMessages: string;
  apiMessageShow: boolean = false;

  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";

  constructor(
    private dialogRef: MatDialogRef<CategoriesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dataService: DataService,
    public formService: FormValidatorService,
    public apiService: CatalogServicesService,
    private alert: MatSnackBar,
    private _snack: MatSnackBar
  ) {
    this.data = data;

    if (data.action === "edit") {
      this.dialogTitle = "Editar Categoria";
      this.dataSource = data.data;
      this.edit = true;
    } else {
      this.dialogTitle = "Agregar Categoria";
      this.dataSource = new Category({});
    }
    this.form = this.createForm();
  }

  createForm(): FormGroup {
    return this.fb.group({
      id: [this.dataSource.id],
      name: [this.dataSource.name, [Validators.required]],
      order: [this.dataSource.order, []],
    });
  }

  save(): void {
    if (this.form.valid) {
      this.dataSource = this.form.value;
      this.storeUpdate(this.edit ? "update" : "store");
    } else {
      this.formService.allFields(this.form);
      alert("Datos incorrectos");
    }
  }

  storeUpdate(method: string): void {
    if (method === "store") {
      this.apiService.createCategory(this.dataSource).subscribe(
        (res) => {
          this.closeModal();

          if (res.status === "Éxito") this.getRoleViews();
        },
        (error) => {
          this.apiMessages = error.error.message;
          this.showAlert(this.apiMessages);
        }
      );
    } else {
      this.apiService.updateCategory(this.dataSource).subscribe(
        (res) => {
          this.closeModal();

          if (res.status === "Éxito") this.getRoleViews();
        },
        (error) => {
          this.apiMessages = error.error.message;
          this.showAlert(this.apiMessages);
        }
      );
    }
  }

  showAlert(message: string) {
    const config = {
      duration: 5000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    };

    this.openSnack("snack-error", message);
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  ngOnInit() {}

  openSnack(color: string, message: string): void {
    //console.log(message)
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  private getRoleViews(): void {
    this.apiService.readRoleViews().subscribe(
      (res) => {
        const currentRoleViews = res.data.find(
          (role: RoleViews) =>
            role.id === this.dataService.userValue.roles[0].id
        );
        const currentCategories = currentRoleViews.views.map(
          (view: View) => view.category
        );
        const sortedCategories =
          this.distinctAndSortCategories(currentCategories);
        this.viewsByCategory(sortedCategories, currentRoleViews.views);
      },
      (error) => {
        //console.log(error);
      }
    );
  }

  private distinctAndSortCategories(categories: Category[]): Category[] {
    const result = [];
    const map = new Map();
    for (const item of categories) {
      if (!map.has(item.id)) {
        map.set(item.id, true);
        result.push({
          id: item.id,
          name: item.name,
          order: item.order,
        });
      }
    }

    return result.sort((a: Category, b: Category) => {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    });
  }

  viewsByCategory(categories: Category[], views: View[]) {
    categories.forEach((category) => {
      category.views = views.filter(
        (view: View) =>
          view.category.id === category.id &&
          !view.path.includes("existence-types")
      );

      category.views = category.views.sort((a: View, b: View) => {
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      });
    });

    this.dataService.setCategoriesValue(categories);
  }
}
