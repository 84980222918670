import { PurchaseDetailComponent } from './dialogs/purchase-detail/purchase-detail.component';
import { NotificationsComponent } from '../alerts/notifications/notifications.component';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { FormComponent } from './dialogs/form/form.component';
import { SalesService } from 'app/apiServices/sales.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Sale } from 'app/models/sale';
import { UnloadingOrderComponent } from './dialogs/unloading-order/unloading-order.component';
import { ModalConfirmationComponent } from '../modals/modal-confirmation/modal-confirmation.component';
import { CostingFormComponent } from './dialogs/costing-form/costing-form.component';
import { DateTime } from "luxon";
import { Subscription } from 'rxjs';
import { EvidenceComponent } from './dialogs/evidence/evidence.component';


declare let DateRangePicker: any;

@Component({
  selector: 'app-purcase',
  templateUrl: './purcase.component.html',
  styleUrls: ['./purcase.component.css']
})
export class PurcaseComponent implements OnInit {
  @ViewChild(DatatableComponent) table: DatatableComponent;
  rows: any[] = [];
  temp: any[] = [];
  ColumnMode = ColumnMode;
  form: FormGroup;
  expanded: any = {}
  dateRangePicker: any;

  @ViewChild("container", { static: true }) container: ElementRef;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    //Subject to change : Re => Time later... => Why? --- Time later, what did I meant?
    private salesService: SalesService,
    private _snack: MatSnackBar
  ) {
    this.form = this.fb.group({
      rowsPerPage: [10],
    });
    this.temp = [...this.rows];
  }


  ngOnInit(): void {
    // const dateRangePickerEl = document.getElementById("dateRangePickerId");
    // this.dateRangePicker = new DateRangePicker(dateRangePickerEl, {
    //   allowOneSidedRange: true,
    //   language: "es",
    //   format: "yyyy-mm-dd",
    // });
    this.getPurchases();
    // this.setInitialDate();
  }

  setInitialDate() {
    this.dateRangePicker.setDates(
      DateTime.local().minus({ month: 1 }).toISODate(),
      DateTime.local().toISODate()
    );
  }

  changeDate(event: any) {
    console.log("Event", event)
    const [startDate, endDate] = this.dateRangePicker.getDates();

    if (startDate && endDate) {
      this.dateRangePicker.datepickers[0].element.click();
      this.container.nativeElement.click();
      this.dateRangePicker.datepickers[1].element.click();
      this.container.nativeElement.click();
      const formattedStartDate =DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd");
      const formattedEndDate = DateTime.fromJSDate(endDate).toFormat("yyyy-MM-dd");

      console.log("Date_initial", formattedStartDate)
      console.log("Date_final", formattedEndDate)
    }
  }

  undo(): void {
    this.setInitialDate();
  } 


  onLimitChange(event: Event): void {
    const limit = (event.target as HTMLSelectElement).value;
    this.changePageLimit(limit);
    this.table.limit = this.form.controls.rowsPerPage.value;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor(
          (this.table.rowCount - 1) / this.table.limit
        );
      }
    });
  }

  changePageLimit(limit: any): void {
    this.form.controls.rowsPerPage.setValue(parseInt(limit, 10));
  }

  updateFilter(event: Event) {
    const val: string = (event.target as HTMLInputElement).value.toLowerCase();
    const temp: Sale[] = this.temp.filter((d: Sale) => {
      return d.folio.toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.temp = temp;
    this.table.offset = 0;
  }

  getPurchases(): void {
    var unfiltered:any[]
    var filtered:any[] = []
    var secundaryFiltered:any[] = []

    this.salesService.readPurchases().subscribe({
      next: (res) => {
        if(res.status === "Éxito"){
          unfiltered = res.data
          unfiltered.forEach((purchase) => {
            if(purchase.main_purchase_order_id == null && purchase.active == true)
              filtered.push(purchase)
            else
              secundaryFiltered.push(purchase)
          })

          /* Filtro para Compras asignar arreglo Subcompras */
          filtered.forEach((principal) => {
            principal['subPurchases'] = []
            secundaryFiltered.forEach((secundary) => {
              if(secundary.main_purchase_order_id == principal.id && secundary.active == true){
                principal.subPurchases.push(secundary)
              }
            })
          })
          this.rows = filtered

          console.log("Filtered", this.rows)

          this.temp = [...this.rows]
        }
      }, error: (e) => {
      }
    })

  }

  addOrder(data: any) {
    const purchaseLabOrder =
      data.purchase_lab_orders && data.purchase_lab_orders.length > 0
        ? data.purchase_lab_orders[data.purchase_lab_orders.length - 1]
        : undefined;
    const dialogRef = this.dialog.open(UnloadingOrderComponent, {
      width: "80%",
      data: {
        purchaseLabOrder,
        purchase: data,
        edit: purchaseLabOrder != null,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.status && result.status == 200) {
        this.getPurchases();
      }
    });
  }

  openDialog(data: any, action: string = "edit") {    
    const dialogRef = this.dialog.open(FormComponent, {
      width: "100vw",
      maxWidth: "100vw",
      height: "100%",
      data: {
        action,
        data,
      },
    });
    
    dialogRef.afterClosed().subscribe((result) => {
      this.getPurchases();
    });
  }
  
  openCosting(purchaseOrder: any){
    console.log("Costeos", purchaseOrder)
    
    const dialogRef = this.dialog.open(CostingFormComponent,{
      width: "100%",
      height: "80%",
      data: { 
        purchaseOrder
      }
    })
  }

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
      verticalPosition: "top",
    });
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row)
  }

  onDetailToggle(event) { }

  delete(id: number){
    this.openDialogDelete(id)
  }

  openDialogDelete(id: number) {
    const dialogRef = this.dialog.open(ModalConfirmationComponent, {
      width: "600px",
      data: {
        title: "¿Estas seguro de desactivar esta Compra?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.salesService.deletePurchase(id).subscribe(
          (result) => {
            this.getPurchases()
          },
          (error) => {}
        );
      }
    });
  }


  openDocModal(data: any){
    const dialogRef = this.dialog.open(EvidenceComponent, {
      width: "1000px",
      height: "500px",
      data: {
        data
      }
    })

    dialogRef.afterClosed().subscribe((result) => {
      this.getPurchases()
    })
  }
}
