import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router'
import { on } from 'events';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {
  dataSource: any

  client: boolean = false
  transportist: boolean = false
  supplier: boolean = false

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<InfoComponent>,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.dataSource = data.data
  }

  ngOnInit(): void {
    console.log(this.dataSource)
    if(this.dataSource.clients.filter(c => c.active).length >= 1)
      this.client = true
    if(this.dataSource.transportists.filter(t => t.active).length >= 1)
      this.transportist = true
    if(this.dataSource.suppliers.filter(s => s.active).length >= 1)
      this.supplier = true
  }

  closeModal(): void {
    this.dialogRef.close()
  }

  showDirections(object: any){
    this.router.navigate(['admin/addressesCompany'], {
      queryParams:
        {id: object.id, legal_name: object.legal_name}

    }).then(r => {
      this.closeModal()
    })
  }
}
