import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { FormValidatorService } from "app/core/service/form-validator.service";
import { Company } from "app/models/company";
import { Sale } from "app/models/sale";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NotificationsComponent } from "app/components/alerts/notifications/notifications.component";
import { InvoiceService } from "app/apiServices/invoice.service";
import { DataService } from "app/services/data.service";
import { Client, Invoice } from "app/models/invoice";

@Component({
  selector: "app-assing-client-invoice",
  templateUrl: "./assing-client-invoice.component.html",
  styleUrls: ["./assing-client-invoice.component.css"],
})
export class AssingClientInvoiceComponent {
  dialogTitle: string = "Asignar Factura";
  rows = [];
  selected = [];

  iva : number = 0.00;
  ieps : number = 0.00;
  subtotal : number = 0.00;
  total : number = 0.00;

  assign : boolean ;
  invoices : Invoice[] = [];

  ColumnMode = ColumnMode;
  client_id : Client;
  client: Company;
  orders: any[] = [];

  loading: boolean = false;
  form: FormGroup;
  ids: number[];

  validationMessages = {
    client_id: [{ type: "required", message: "Ingresa el folio" }],
    iva: [{ type: "required", message: "Ingresa el iva" }],
    ieps: [{ type: "required", message: "Ingresa el ieps" }],
    vat: [{ type: "required", message: "Ingresa el vat" }],
    subtotal: [{ type: "required", message: "Ingresa el subtotal" }],
    total: [{ type: "required", message: "Ingresa el total" }],
  };

  constructor(
    private fb: FormBuilder,
    public formService: FormValidatorService,
    private dialogRef: MatDialogRef<AssingClientInvoiceComponent>,
    private _snack: MatSnackBar,
    private dataService: DataService,
    private invoiceService: InvoiceService,
    @Inject(MAT_DIALOG_DATA) public data: any 
  ) {
    this.client_id = data.client_id;
    this.assign = data.assing;
    if(this.assign === true){
      this.dialogTitle = "Asignar Factura";
      this.orders = data.orders;
      this.client = new Company({
        legal_name: this.orders[0].client.company.legal_name,
      });
      this.ids = this.orders.map(order => order.id);
    }
    else {
      var order_id = data.id_order;
      console.log("ORDER ID",order_id)
      this.dialogTitle = "Factura";
      this.orders = data.allorders;
      this.invoices = data.invoices;
      this.client = new Company({
        legal_name: this.orders[0].client.company.legal_name,
      });
      this.invoices = this.invoices.filter(invoice => {
        return invoice.orders.some(order => order.id === order_id);
      });
      var orderIds = this.invoices.reduce((ids, invoice) => ids.concat(invoice.orders.map(order => order.id)), []);
      this.orders = this.orders.filter(order => orderIds.includes(order.id));
    
      this.ids = this.orders.map(order => order.id);
    }
    this.form = this.createForm()
    let ieps = 0;
    let subtotal = 0;
    let iva = 0;
    this.orders = this.orders.map(order => {
      let total = order.sold_liters * order.total_price_liter;
      total -= order.discount;
      ieps += order.ieps;
      this.ieps = order.ieps;
      this.subtotal = total / 1.16;
      this.iva = this.subtotal * 0.16;
      subtotal += this.subtotal;
      iva += this.iva;
      this.total += this.ieps + this.iva + this.subtotal;
      return {
        ...order, 
        total, 
        iva: this.iva ? Number(this.iva.toFixed(2)).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '$0.00', 
        ieps: this.ieps ? Number(this.ieps.toFixed(2)).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '$0.00'
      };
    });
    this.form.controls.vat.patchValue(iva.toFixed(2));
    this.form.controls.ieps.patchValue(ieps.toFixed(2));
    this.form.controls.subtotal.patchValue(subtotal.toFixed(2));
    this.form.controls.total.patchValue(this.total.toFixed(2));

    this.form.get("vat").disable();
    this.form.get("ieps").disable();
    this.form.get("subtotal").disable();
    this.form.get("total").disable(); 

    this.form.get("vat").setValue(iva.toFixed(2));
    this.form.get("ieps").setValue(ieps.toFixed(2));
    this.form.get("subtotal").setValue(subtotal.toFixed(2));
    this.form.get("total").setValue(this.total.toFixed(2));
  }
  
  ngAfterViewInit(): void {
  }

  createForm(): FormGroup {
    return this.fb.group({
      client_id: [this.client_id, [Validators.required]],
      ieps: [, [Validators.required]],
      vat : [, [Validators.required]],
      subtotal: [, [Validators.required]],
      total: [, [Validators.required]],
      orders: [this.ids, [Validators.required]],
    });
  }

  onLeadingZero(event: Event): void {
    const num = (event.target as HTMLInputElement).value
      .replace("$", "")
      .replace(",", "")
      .replace(" ", "");
    const dec = num.split(".")[1];
    const len = dec && dec.length > 2 ? dec.length : 2;
    (event.target as HTMLInputElement).value = `$ ${Number(num).toFixed(len)}`;
  }

  closeModal(): void {
    this.dialogRef.close();
  }
  createInvoiceClient() {
    if (this.form.valid) {
      this.form.get("vat").enable();
      this.form.get("ieps").enable();
      this.form.get("subtotal").enable();
      this.form.get("total").enable(); 
      this.loading = true;
      this.dataService.setLoadingValue(this.loading);
      this.invoiceService.createInvoice(this.form.value).subscribe({
        next: (res) => {
          let ic = true
          this.loading = false;
          this.dataService.setLoadingValue(this.loading);
          this.dialogRef.close(ic);
          this.openSnack("snack-success", res.message);
        },
        error: (error) => {
          this.loading = false;
          this.dataService.setLoadingValue(this.loading);
          this.openSnack("snack-error", error.error.message);
          this.form.get("vat").disable();
          this.form.get("ieps").disable();
          this.form.get("subtotal").disable();
          this.form.get("total").disable(); 
        },
      });
    } else {
      this.openSnack("snack-error", "Revise bien el formulario");
    }
    setTimeout(() => {
      this.loading = false;
      this.dataService.setLoadingValue(this.loading);
    }, 10000);
  }
  

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
      verticalPosition: "top",
    });
  }
}
