<div class="bg-white px-4 w-full h-full py-4 overflow-y-auto">
  <div class="flex">
    <div class="w-11/12 justify-between rounded-t mb-0 pb-8 border-0 bg-white">
      <div class="text-center">
        <h3 class="font-semibold text-lg">
          {{ dialogTitle }} - {{ data?.folio }}
        </h3>
      </div>
    </div>

    <div class="w-1/12 justify-end mb-0 pb-8">
      <button
        class="text-sky-600 cursor-pointer hover:text-sky-700 text-2xl pl-12 ease-linear transition-all duration-150"
        (click)="closeModal()"
      >
        <i class="fas fa-times-circle"></i>
      </button>
    </div>
  </div>
  <div class="mb-4 border-b border-gray-200">
    <ul
      class="text-base font-medium text-center text-gray-500 shadow sm:flex"
      id="tab"
      role="tablist"
    >
      <li class="w-full">
        <button
          class="inline-block w-full cursor-pointer p-2 hover:bg-gray-150 border border-gray-200 hover:bg-gray-200 focus:ring-0 focus:outline-none"
          id="sale-data-tab"
          type="button"
          role="tab"
          aria-controls="sale-data"
          aria-selected="false"
        >
          Venta
        </button>
      </li>

      <li class="w-full">
        <button
          class="inline-block w-full cursor-pointer self-center p-2 hover:bg-gray-150 border border-gray-200 hover:bg-gray-200 focus:ring-0 focus:outline-none"
          id="cost-tab"
          type="button"
          role="tab"
          aria-controls="cost"
          aria-selected="false"
        >
          Costos
        </button>
      </li>

      <li class="w-full">
        <button
          class="inline-block w-full cursor-pointer self-center p-2 hover:bg-gray-150 border border-gray-200 hover:bg-gray-200 focus:ring-0 focus:outline-none"
          id="mub-tab"
          type="button"
          role="tab"
          aria-controls="mub"
          aria-selected="false"
        >
          MUB
        </button>
      </li>
    </ul>
  </div>
  <div id="tabContentCost">
    <form class="bg-white" [formGroup]="form">
      <div id="sale-data" role="tabpanel" aria-labelledby="sale-data-tab">
        <div>
          <div>
            <div class="flex flex-col md:flex-row w-full">
              <div class="overflow-x-auto w-full">
                <div class="inline-block w-full py-2">
                  <div class="overflow-hidden rounded w-full">
                    <table
                      class="w-full border text-center text-sm font-light"
                      aria-describedby="totals"
                    >
                      <thead>
                        <tr>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody class="w-full">
                        <tr class="border-b">
                          <td
                            class="px-2 text-start whitespace-nowrap border-r font-medium"
                          >
                            Litros
                          </td>
                          <td class="whitespace-nowrap border-r">
                            <input
                              type="text"
                              mask="separator.2"
                              thousandSeparator=","
                              [leadZero]="true"
                              formControlName="sold_liters"
                              class="w-full border-none"
                            />
                          </td>
                        </tr>

                        <tr class="border-b">
                          <td
                            class="px-2 text-start whitespace-nowrap border-r font-medium"
                          >
                            Precio Venta
                          </td>
                          <td class="whitespace-nowrap border-r">
                            <input
                              type="text"
                              prefix="$ "
                              mask="separator.2"
                              thousandSeparator=","
                              formControlName="total_price_liter"
                              class="w-full border-none"
                            />
                          </td>
                        </tr>

                        <tr class="border-b">
                          <td
                            class="px-2 text-start whitespace-nowrap border-r font-medium"
                          >
                            Vental Total
                          </td>
                          <td class="whitespace-nowrap border-r">
                            <input
                              type="text"
                              prefix="$ "
                              mask="separator.2"
                              thousandSeparator=","
                              formControlName="total_sale"
                              class="w-full border-none"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div
                class="flex flex-col w-full md:ml-6"
                *ngIf="!data?.product?.full_mix"
              >
                <div class="overflow-x-auto w-full">
                  <div class="inline-block w-full py-2">
                    <div class="overflow-hidden rounded w-full">
                      <table
                        class="w-full border text-center text-sm font-light"
                        aria-describedby="totals"
                      >
                        <thead>
                          <tr>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody class="w-full">
                          <tr
                            class="border-b"
                            *ngFor="let product of data?.product?.products"
                          >
                            <td
                              class="px-2 text-start whitespace-nowrap border-r font-medium"
                            >
                              {{ product?.name }} -
                              {{ getPercentage(product) }}%
                            </td>
                            <td class="whitespace-nowrap border-r">
                              <div
                                class="w-full border-none text-base text-start py-2 px-2"
                              >
                                $
                                {{ getProductCost(product) | number : "1.2-2" }}
                              </div>
                            </td>
                          </tr>

                          <tr class="border-b">
                            <td
                              class="px-2 text-start whitespace-nowrap border-r font-medium">
                              Costo por litro
                            </td>
                            <td class="whitespace-nowrap border-r">
                              <div
                                class="w-full border-none text-base text-start py-2 px-2">
                                $
                                {{ cost_product | number : "1.2-2" }}
                              </div>
                            </td>
                          </tr>

                          <tr class="border-b">
                            <td
                              class="px-2 text-start whitespace-nowrap border-r font-medium"
                            >
                              Costo Total
                            </td>
                            <td class="whitespace-nowrap border-r">
                              <div
                                class="w-full border-none text-base text-start py-2 px-2"
                              >
                                $
                                {{ total_cost_product | number : "1.2-2" }}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="hidden" id="cost" role="tabpanel" aria-labelledby="cost-tab">
      <form class="bg-white" [formGroup]="formCost">
        <div class="flex flex-col md:flex-row w-full">
          <div
            class="w-full md:w-2/6"
            [ngClass]="{
              hidden:
                currentOrderCost?.order_cost_type?.id == 1 ||
                currentOrderCost?.order_cost_type?.id == 2 ||
                currentOrderCost?.order_cost_type?.id == 3
            }"
          >
            <div class="container px-2">
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="order_cost_type_id"
              >
                TIPO DE COSTOS
              </label>
              <select
                #typeCost
                formControlName="order_cost_type_id"
                [ngClass]="{
                  'focus:border-red-500':
                    formCost.controls.order_cost_type_id.invalid,
                  'focus:ring-red-500':
                    formCost.controls.order_cost_type_id.invalid
                }"
                class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              >
                <option [ngValue]="null">Selecciona un costo</option>
                <option *ngFor="let cost of costs" value="{{ cost.id }}">
                  {{ cost.name }}
                </option>
              </select>
              <div
                *ngFor="let validation of validationMessages.order_cost_type_id"
              >
                <span
                  *ngIf="
                    formService.field(
                      formCost,
                      validation.type,
                      'order_cost_type_id'
                    )
                  "
                  class="mt-2 text-sm text-red-500"
                >
                  {{ validation.message }}
                </span>
              </div>
            </div>
          </div>

          <div class="w-full md:w-2/6">
            <div class="container px-2">
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="company_supplier_id"
              >
                PROVEEDOR
              </label>
              <select
                #supplierId
                formControlName="company_supplier_id"
                [ngClass]="{
                  'focus:border-red-500':
                    formCost.controls.company_supplier_id.invalid,
                  'focus:ring-red-500':
                    formCost.controls.company_supplier_id.invalid
                }"
                class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              >
                <option [ngValue]="null">Selecciona un proveedor</option>
                <option
                  *ngFor="let supplier of suppliers"
                  value="{{ supplier.id }}"
                >
                  {{ supplier.legal_name }}
                </option>
              </select>
              <div
                *ngFor="
                  let validation of validationMessages.company_supplier_id
                "
              >
                <span
                  *ngIf="
                    formService.field(
                      formCost,
                      validation.type,
                      'company_supplier_id'
                    )
                  "
                  class="mt-2 text-sm text-red-500"
                >
                  {{ validation.message }}
                </span>
              </div>
            </div>
          </div>

          <div class="w-full md:w-2/6" [ngClass]="{ hidden: isVendor }">
            <div class="container px-2">
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="user_id"
              >
                VENDEDOR
              </label>
              <select
                #userId
                formControlName="user_id"
                [ngClass]="{
                  'focus:border-red-500': formCost.controls.user_id.invalid,
                  'focus:ring-red-500': formCost.controls.user_id.invalid
                }"
                class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              >
                <option value="">Selecciona un vendedor</option>
                <option *ngFor="let vendor of vendors" [value]="vendor.id">
                  {{ vendor.name }} {{ vendor.lastname }}
                </option>
              </select>
              <div *ngFor="let validation of validationMessages.user_id">
                <span
                  *ngIf="
                    formService.field(formCost, validation.type, 'user_id')
                  "
                  class="mt-2 text-sm text-red-500"
                >
                  {{ validation.message }}
                </span>
              </div>
            </div>
          </div>

          <div class="w-full md:w-36">
            <div class="px-2">
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="cost"
              >
                COSTO
              </label>
              <input
                type="text"
                name="cost"
                formControlName="cost"
                prefix="$ "
                mask="separator.6"
                thousandSeparator=","
                (change)="onLeadingZero($event)"
                [ngClass]="{
            'focus:border-red-500': formCost.controls.cost.invalid,
            'focus:ring-red-500': formCost.controls.cost.invalid,
          }"
                (change)="onLeadingZero($event)"
                class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 rounded border-gray-300 text-sm focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>

          <div class="w-full md:w-36">
            <div class="px-2">
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="quantity"
              >
                CANTIDAD
              </label>
              <input
                type="text"
                name="quantity"
                formControlName="quantity"
                mask="separator.2"
                thousandSeparator=","
                [ngClass]="{
            'focus:border-red-500': formCost.controls.quantity.invalid,
            'focus:ring-red-500': formCost.controls.quantity.invalid,
          }"
                class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 rounded border-gray-300 text-sm focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>

          <div class="flex">
            <button
              class="bg-sky-600 text-white active:bg-sky-600 self-center h-8 font-bold uppercase text-xs px-6 mt-4 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              (click)="clear()"
            >
              <i class="fas fa-trash-restore"></i>
            </button>

            <button
              class="bg-sky-600 text-white active:bg-sky-600 self-center h-8 font-bold uppercase text-xs px-6 mt-4 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              (click)="save(edit ? 'update' : 'create')"
            >
              <i *ngIf="edit" class="fas fa-pen"></i>
              <i *ngIf="!edit" class="fas fa-plus"></i>
            </button>
          </div>
        </div>

        <div class="flex flex-row w-full mt-6">
          <div class="grid w-full">
            <div class="block w-full overflow-x-auto rounded-lg">
              <ngx-datatable
                #tableAdresses
                class="material"
                [columnMode]="ColumnMode?.force"
                [headerHeight]="50"
                rowHeight="auto"
                [limit]="5"
                [rows]="orderCosts"
                [scrollbarH]="true"
              >
                <ngx-datatable-column name="Tipo de Costo">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row?.order_cost_type?.name }}
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Vendedor">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row?.user?.name }} {{ row.user?.lastname }}
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Proveedor">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row?.company_supplier?.legal_name }}
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Costo">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    $ {{ row.cost | number : "1.2-2" }}
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Cantidad">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.quantity | number : "1.0-2" }}
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Total">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    $ {{ row.quantity * row.cost | number : "1.2-2" }}
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Opciones">
                  <ng-template
                    #buttonsTemplate
                    let-row="row"
                    let-value="value"
                    ngx-datatable-cell-template
                  >
                    <div class="flex">
                      <button
                        class="bg-sky-500 text-white mx-6 px-2 py-1 rounded-full text-center"
                        (click)="fillToEdit(row)"
                      >
                        <i class="fas fa-edit"></i>
                      </button>

                      <button
                        *ngIf="
                          orderCosts.indexOf(row) != orderCosts.length - 1 &&
                          orderCosts.indexOf(row) != orderCosts.length - 2 &&
                          orderCosts.indexOf(row) != orderCosts.length - 3
                        "
                        class="bg-red-500 text-white mx-6 px-2 py-1 rounded-full text-center"
                        (click)="confirmDelete(row.id)"
                      >
                        <i class="fas fa-trash-alt"> </i>
                      </button>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
              </ngx-datatable>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="hidden" id="mub" role="tabpanel" aria-labelledby="mub-tab">
      <form class="bg-white" [formGroup]="formMub">
        <div class="flex flex-row w-full">
          <div class="overflow-x-auto w-full">
            <div class="inline-block w-full py-2">
              <div class="overflow-hidden rounded w-full">
                <table
                  class="w-full border text-center text-sm font-light"
                  aria-describedby="mub"
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody class="w-full">
                    <tr class="border-b">
                      <td
                        class="px-2 text-start whitespace-nowrap border-r font-medium"
                      >
                        Ventas
                      </td>
                      <td class="whitespace-nowrap border-r">
                        <input
                          type="text"
                          mask="separator.2"
                          prefix="$ "
                          thousandSeparator=","
                          [leadZero]="true"
                          formControlName="total_sales"
                          class="w-full border-none"
                        />
                      </td>
                    </tr>

                    <tr class="border-b">
                      <td
                        class="px-2 text-start whitespace-nowrap border-r font-medium"
                      >
                        Costos
                      </td>
                      <td class="whitespace-nowrap border-r">
                        <input
                          type="text"
                          prefix="$ "
                          mask="separator.2"
                          thousandSeparator=","
                          formControlName="total_costs"
                          class="w-full border-none"
                        />
                      </td>
                    </tr>

                    <tr class="border-b">
                      <td
                        class="px-2 text-start whitespace-nowrap border-r font-medium">
                        Costo de producción 
                      </td>
                      <td class="whitespace-nowrap border-r">
                        <div
                          class="w-full border-none text-base text-start py-2 px-2">
                          $
                          {{ total_cost_product | number : "1.2-2" }}
                        </div>
                      </td>
                    </tr>

                    <tr class="border-b">
                      <td
                        class="px-2 text-start whitespace-nowrap border-r font-medium"
                      >
                        MUB
                      </td>
                      <td class="whitespace-nowrap border-r">
                        <input
                          type="text"
                          prefix="$ "
                          thousandSeparator=","
                          formControlName="total_mub"
                          class="w-full border-none"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
