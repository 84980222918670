import { Component, ElementRef, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";
import { SalesService } from "app/apiServices/sales.service";
import { NotificationsComponent } from "app/components/alerts/notifications/notifications.component";
import { Sale } from "app/models/sale";
import { Collapse, Dropdown, initTE } from "tw-elements";
import { DateTime } from "luxon";
import { CostDialogComponent } from "../cost-dialog/cost-dialog.component";
import { OrderCost } from "app/models/order-cost";
import { Subscription } from "rxjs";

declare let DateRangePicker: any;

@Component({
  selector: "app-cost-list",
  templateUrl: "./cost-list.component.html",
  styleUrls: ["./cost-list.component.css"],
})
export class CostListComponent {
  rows: Sale[] = [];
  temp: Sale[] = [];
  tmpCosts: OrderCost[] = [];
  form: FormGroup;
  ColumnMode = ColumnMode;
  columnMapValues = [
    ["folio", "folio"],
    ["cVendedora", "seller"],
    ["tipoDeVenta", "type_code"],
    ["vendedor", "vendor"],
    ["producto", "product"],
    ["estatus", "order_status"],
    ["creado", "created_at"],
    ["cliente", "client"],
    ["transportista", "transportist"],
  ] as const;
  columnMap: Map<string, string> = new Map(this.columnMapValues);
  loading: boolean = false;
  expanded: any = {};
  dateRangePicker: any;
  disabledPickers: boolean = false;
  salesRequest: Subscription;

  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild("container", { static: true }) container: ElementRef;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private salesService: SalesService,
    private _snack: MatSnackBar
  ) {
    this.form = this.fb.group({
      rowsPerPage: [10],
    });
    this.temp = [...this.rows];
  }

  ngOnInit(): void {
    initTE({ Collapse, Dropdown });
    const dateRangePickerEl = document.getElementById("dateRangePickerId");
    this.dateRangePicker = new DateRangePicker(dateRangePickerEl, {
      allowOneSidedRange: true,
      language: "es",
      format: "yyyy-mm-dd",
      orientation: "bottom",
    });
    this.getOrderCosts();
    this.setInitialDate();
  }

  setInitialDate() {
    this.dateRangePicker.setDates(
      DateTime.local().minus({ weeks: 1 }).toISODate(),
      DateTime.local().toISODate()
    );
  }

  changeDate(event: any) {
    if (this.salesRequest) this.salesRequest.unsubscribe();

    const [startDate, endDate] = this.dateRangePicker.getDates();

    if (startDate && endDate) {
      this.dateRangePicker.datepickers[0].element.click();
      this.container.nativeElement.click();
      this.dateRangePicker.datepickers[1].element.click();
      this.container.nativeElement.click();
      const formattedStartDate =
        DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd");
      const formattedEndDate =
        DateTime.fromJSDate(endDate).toFormat("yyyy-MM-dd");
      this.getSales(formattedStartDate, formattedEndDate);
    }
  }

  undo(): void {
    this.setInitialDate();
  }

  onLimitChange(event: Event): void {
    const limit = (event.target as HTMLSelectElement).value;
    this.changePageLimit(limit);
    this.table.limit = this.form.controls.rowsPerPage.value;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor(
          (this.table.rowCount - 1) / this.table.limit
        );
      }
    });
  }

  changePageLimit(limit: any): void {
    this.form.controls.rowsPerPage.setValue(parseInt(limit, 10));
  }

  onSort(event: any): void {
    const sort = event.sorts[0];
    const column = this.columnMap.get(sort.prop);

    if (column) {
      this.temp.sort((a, b) => {
        if (typeof a[column] === "number") {
          if (sort.dir === "asc") return a[column] - b[column];
          else return b[column] - a[column];
        } else if (typeof a[column] === "string") {
          return (
            a[column].localeCompare(b[column]) * (sort.dir === "desc" ? -1 : 1)
          );
        } else if (typeof a[column] === "object") {
          if (column == "seller") {
            return (
              a[column]?.legal_name.localeCompare(b[column]?.legal_name) *
              (sort.dir === "desc" ? -1 : 1)
            );
          }

          if (column == "client" || column == "transportist") {
            return (
              a[column]?.company?.legal_name.localeCompare(
                b[column]?.company?.legal_name
              ) * (sort.dir === "desc" ? -1 : 1)
            );
          }

          if (column == "vendor") {
            const nameA = a[column]?.name;
            const nameB = b[column]?.name;
            const lastNameA = a[column]?.lastname;
            const lastNameB = b[column]?.lastname;
            const fullNameA = `${nameA} ${lastNameA}`;
            const fullNameB = `${nameB} ${lastNameB}`;

            return (
              fullNameA.localeCompare(fullNameB) *
              (sort.dir === "desc" ? -1 : 1)
            );
          }

          if (column == "product" || column == "order_status") {
            return (
              a[column]?.name.localeCompare(b[column]?.name) *
              (sort.dir === "desc" ? -1 : 1)
            );
          }

          if (column == "created_at") {
            if (sort.dir === "asc") {
              return (
                new Date(a[column]).getTime() - new Date(b[column]).getTime()
              );
            } else {
              return (
                new Date(b[column]).getTime() - new Date(a[column]).getTime()
              );
            }
          }
        }
      });

      this.temp = [...this.temp];
    }
  }

  updateFilter(event: Event) {
    const val: string = (event.target as HTMLInputElement).value.toLowerCase();
    const temp: Sale[] = this.rows.filter((d: Sale) => {
      return d.folio.toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.temp = temp;
    this.table.offset = 0;
  }

  getSales(start: string = "", end: string = ""): void {
    this.loading = true;
    this.disabledPickers = true;

    this.salesRequest = this.salesService.getSales(start, end).subscribe({
      next: (res) => {
        this.loading = false;
        this.disabledPickers = false;

        if (res.status === "Éxito") {
          this.rows = res.data.filter(
            (sale) => sale.active && sale.order_status.id > 3
          );
          this.temp = [...this.rows];
          this.rows.forEach((sale) => {
            sale.order_costs = this.tmpCosts.filter(
              (cost) => cost?.active && cost?.order?.id == sale.id
            );
          });
        }
      },
      error: (e) => {
        this.loading = false;
        this.disabledPickers = false;
      },
    });
  }

  getOrderCosts(): void {
    this.salesService.getOrderCosts().subscribe({
      next: (res) => {
        if (res.status === "Éxito") {
          this.tmpCosts = res.data;
        }
      },
    });
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {}

  compareLuxonDates(a: DateTime, b: DateTime) {
    return a.toMillis() - b.toMillis();
  }

  isBetweenDates(date: DateTime, start: DateTime, end: DateTime) {
    const numericDate = date.toMillis();
    const numericStart = start.toMillis();
    const numericEnd = end.toMillis();

    return numericDate >= numericStart && numericDate <= numericEnd;
  }

  openDetailDialog(data: Sale, action: string = "store") {
    const dialogRef = this.dialog.open(CostDialogComponent, {
      width: "84vw",
      maxWidth: "84vw",
      height: "80vh",
      data: {
        data,
        action,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getOrderCosts();
    });
  }

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
      verticalPosition: "top",
    });
  }
}
