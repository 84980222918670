<div class="container bg-white mx-auto px-4 h-full py-4">
  <div class="flex justify-between rounded-t mb-0 px-4 py-3 border-0 pt-6 bg-white">
    <div class="flex w-full justify-center">
      <div>
        <h3 class="font-semibold text-lg">
          {{ dialogTitle }}
        </h3>
      </div>
    </div>
  </div>

  <div class="flex items-center justify-end mt-16">
    <button
      class="bg-slate-500 text-white active:bg-slate-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      (click)="closeModal()">
      Cancelar
    </button>
    <button
      class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      (click)="save()">
      Aceptar
    </button>
  </div>
</div>