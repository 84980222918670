import { Component } from '@angular/core';

@Component({
  selector: 'app-sellers-for-clients',
  templateUrl: './sellers-for-clients.component.html',
  styleUrls: ['./sellers-for-clients.component.css']
})
export class SellersForClientsComponent {

}
