import { Component, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DatatableComponent, ColumnMode } from '@swimlane/ngx-datatable';
import { ClientsService } from 'app/apiServices/clients.service';

@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.css']
})
export class ShowComponent {
  @ViewChild(DatatableComponent) table: DatatableComponent;
  ColumnMode = ColumnMode;

  dataSource:any
  data: any [] = []
  tempo: any [] = []
  temp: any [] = []
  elements: any [] = []
  showAlert = false;
  form: FormGroup;
  alertTitle: string;

  constructor (
    @Inject(MAT_DIALOG_DATA) public dataS: any,
    private dialogRef: MatDialogRef<ShowComponent>,
    private catalog: ClientsService,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {
    this.form = this.fb.group({
      rowsPerPage: [10],
    });
    this.dataSource = this.dataS.data
  }

  ngOnInit(): void {
    this.getData()
  }

  getData() {
    var temp:any[]
    this.catalog.readCommisions().subscribe((r) => {
      temp = r.data

      temp.forEach((commision) => {
        if(commision.client.id == this.dataSource.clients[0].id){
          this.data.push(commision)
        }
      })
    })
  }

  onLimitChange(event: Event): void {
    const limit = (event.target as HTMLSelectElement).value;
    this.changePageLimit(limit);
    this.table.limit = this.form.controls.rowsPerPage.value;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor(
          (this.table.rowCount - 1) / this.table.limit
        );
      }
    });
  }

  changePageLimit(limit: any): void {
    this.form.controls.rowsPerPage.setValue(parseInt(limit, 10));
  }
}
