import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { InServiceRes } from "app/interfaces/service.interface";
import { Service } from "app/models/service";
import { environment } from "environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ServiceService {
  constructor(public http: HttpClient) {
  }

  get(): Observable<InServiceRes> {
    return this.http.get<any>(`${environment.url}purchases/services/get`);
  }

  show(id: number): Observable<InServiceRes> {
    return this.http.get<any>(`${environment.url}purchases/services/get/${id}`);
  }

  create(data: Service): Observable<InServiceRes> {
    return this.http.post<any>(`${environment.url}purchases/services/create`, data);
  }

  update(data: Service): Observable<InServiceRes> {
    return this.http.patch<any>(`${environment.url}purchases/services/update/${data.id}`, data);
  }

  delete(id: number): Observable<InServiceRes> {
    return this.http.delete<any>(`${environment.url}purchases/services/delete/${id}`);
  }
}
