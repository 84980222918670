import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";
import { ModalConfirmationComponent } from "../modals/modal-confirmation/modal-confirmation.component";
import { User } from "app/models/user";
import { FormValidatorService } from "app/core/service/form-validator.service";
import { CatalogServicesService } from "app/apiServices/catalog-services.service";
import { UserServicesService } from "app/apiServices/user-services.service";
import { Role } from "app/models/role";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { NotificationsComponent } from "../alerts/notifications/notifications.component";
import { ChangePasswordComponent } from "../user/form/change-password/change-password.component";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["../../../../src/styles.css", "./users.component.css"],
})
export class UsersComponent implements OnInit {
  objects: User[];
  myself: User

  temp: any[] = [];
  showAlert: boolean = false;
  alertTitle: string;
  form: FormGroup;

  @ViewChild(DatatableComponent) table: DatatableComponent;
  columnMode = ColumnMode;
  columnMapValues = [
    ["id", "id"],
    ["nombre", "name"],
    ["apellido", "lastname"],
    ["correo", "email"],
    ["rol", "roles"],
    ["estatus", "active"],
  ] as const;
  columnMap: Map<string, string> = new Map(this.columnMapValues);
  loading: boolean = false;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    public apiService: UserServicesService
  ) {
    this.form = this.fb.group({
      rowsPerPage: [10],
    });
  }

  ngOnInit(): void {
    this.getMyself();
    this.getData();
  }

  getData() {
    this.loading = true;
    this.apiService.readUsers().subscribe(
      (r) => {
        this.loading = false;
        this.objects = r.data;
        this.temp = this.objects;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  getMyself(){
    this.apiService.getMyself().subscribe({
      next: (response) =>{
        this.myself = response.data
      }, error: (errors) => {
      }
    })
  }

  updateFilter(event: Event) {
    const val: string = (event.target as HTMLInputElement).value.toLowerCase();
    const temp: User[] = this.temp.filter((d: User) => {
      const name = `${d.name} ${d.lastname}`.toLowerCase();

      return (
        name.indexOf(val) !== -1 ||
        d.email.toLowerCase().indexOf(val) !== -1 ||
        d.roles[0]?.name.toLowerCase().indexOf(val) !== -1 ||
        !val
      );
    });
    this.objects = temp;
    this.table.offset = 0;
  }

  openDialog(data: any, action: string) {
    this.alertTitle =
      action == "store"
        ? "Usuario agregado con éxito"
        : "Usuario editado con éxito";

    const dialogRef = this.dialog.open(usersForm, {
      width: "600px",
      data: {
        action,
        data,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getData();
    });
  }

  openDialogDelete(id: number) {
    const dialogRef = this.dialog.open(ModalConfirmationComponent, {
      width: "600px",
      data: {
        title: "¿Estas seguro de desactivar este Usuario?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.apiService.deleteUSer(id).subscribe((r) => {
          this.getData();
        });
        // const index = this.rows.findIndex((d) => d.id === roleId);
        // this.rows.splice(index, 1);
        // this.rows = [...this.rows];
      }
    });
  }

  onLimitChange(event: Event): void {
    const limit = (event.target as HTMLSelectElement).value;
    this.changePageLimit(limit);
    this.table.limit = this.form.controls.rowsPerPage.value;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor(
          (this.table.rowCount - 1) / this.table.limit
        );
      }
    });
  }

  changePageLimit(limit: any): void {
    this.form.controls.rowsPerPage.setValue(parseInt(limit, 10));
  }

  onSort(event: any): void {
    const sort = event.sorts[0];
    const column = this.columnMap.get(sort.prop);

    if (column) {
      this.objects.sort((a, b) => {
        if (typeof a[column] === "number") {
          if (sort.dir === "asc") return a[column] - b[column];
          else return b[column] - a[column];
        } else if (typeof a[column] === "string") {
          return (
            a[column].localeCompare(b[column]) * (sort.dir === "desc" ? -1 : 1)
          );
        } else if (typeof a[column] === "object") {
          return (
            a[column][0].name.localeCompare(b[column][0].name) *
            (sort.dir === "desc" ? -1 : 1)
          );
        }
      });

      this.objects = [...this.objects];
    }
  }

  closeAlert(event: boolean) {
    this.showAlert = event;
  }

  delete(roleId: number) {
    this.openDialogDelete(roleId);
  }

  openDialogPassword(data: any, action: string){
    const dialogRef = this.dialog.open(ChangePasswordComponent,{
      width: "45%",
      height: "auto",
      data :{
        action, data
      }
    })
  }
}

@Component({
  selector: "users-form",
  templateUrl: "usersForm.html",
  styleUrls: ["../../../../src/styles.css"],
})
export class usersForm implements OnInit {
  roles: Role[] = [];

  form: FormGroup;
  dataSource: User;
  edit = false;
  dialogTitle: string;

  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";

  validationMessages = {
    name: [{ type: "required", message: "Ingrese un nombre" }],
    lastname: [{ type: "required", message: "Ingrese apellidos" }],
    email: [{ type: "required", message: "Necesitamos un correo" }],
    username: [
      { type: "required", message: "Necesitamos el nombre de Usuario" },
    ],
    password: [{ type: "required", message: "Necesitamos la contraseña" }],
    phone: [{ type: "required", message: "Necesitamos el numero" }],
    role_id: [{ type: "required", message: "Seleccione un Rol" }],
  };

  constructor(
    private dialogRef: MatDialogRef<UsersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public formService: FormValidatorService,
    public apiService: CatalogServicesService,
    public userService: UserServicesService,
    private alert: MatSnackBar,
    private _snack: MatSnackBar
  ) {
    this.data = data;

    if (data.action === "edit") {
      this.dialogTitle = "Editar Categoria";
      this.dataSource = data.data;
      this.edit = true;
    } else {
      this.dialogTitle = "Agregar Categoria";
      this.dataSource = new User({});
    }
    this.form = this.createForm();
  }

  ngOnInit() {
    this.getRoles();
  }

  getRoles() {
    this.apiService.readRoles().subscribe((r) => {
      r.data.forEach((element) => {
        if (element.active && element.id != 1) this.roles.push(element);
      });
    });
  }

  createForm(): FormGroup {
    if (this.data.action === "edit")
      return this.fb.group({
        id: [this.dataSource.id, []],
        name: [this.dataSource.name, [Validators.required]],
        lastname: [this.dataSource.lastname, [Validators.required]],
        email: [this.dataSource.email, [Validators.required]],
        phone: [this.dataSource.phone, [Validators.required]],
        role_id: [this.dataSource.roles[0].id, [Validators.required]],
        username: [this.dataSource.username, [Validators.required]],
      });
    else
      return this.fb.group({
        name: [, [Validators.required]],
        lastname: [, [Validators.required]],
        email: [, [Validators.required]],
        phone: [, [Validators.required]],
        role_id: [, [Validators.required]],
        username: [, [Validators.required]],
        password: [, [Validators.required]],
      });
  }

  save(): void {
    if (this.form.valid) {
      this.dataSource = this.form.value;
      this.storeUpdate(this.edit ? "update" : "store");
    } else {
      this.formService.allFields(this.form);
      alert("Datos incorrectos");
    }
  }

  storeUpdate(method: string): void {
    if (method === "store") {
      this.userService.createUser(this.dataSource).subscribe(
        (r) => {
          this.closeModal();
        },
        (error) => {
          this.showAlert(error.error.message);
        }
      );
    } else {
      this.userService.updateUser(this.dataSource).subscribe(
        (r) => {
          this.closeModal();
        },
        (error) => {
          this.showAlert(error.error.message);
        }
      );
    }
  }

  showAlert(message: string) {
    const config = {
      duration: 5000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    };

    this.openSnack("snack-error", message);
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
}
