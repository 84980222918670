<div class="container bg-white mx-auto px-4 w-full h-full py-4 overflow-hidden">
  <div class="flex">
    <div class="w-11/12 justify-between rounded-t mb-0 pb-8 border-0 bg-white">
      <div class="text-start">
        <h3 class="font-semibold text-lg">
          {{ dialogTitle }}
        </h3>
      </div>
    </div>
    <div class="w-1/12 justify-end mb-0 pb-8">
      <button
        class="text-sky-600 cursor-pointer hover:text-sky-700 text-2xl pl-12 ease-linear transition-all duration-150"
        (click)="closeModal()"
      >
        <i class="fas fa-times-circle"></i>
      </button>
    </div>
  </div>
  <div *ngFor="let attachment of attachments">
    <iframe
      *ngIf="attachment.extension === '.pdf'"
      class="w-full"
      height="500px"
      [attr.src]="safeUrl(attachment.url)"
      type="application/pdf"
    ></iframe>
    <img *ngIf="attachment.extension !== '.pdf'" class="w-full" [src]="safeUrl(attachment.url)" />
  </div>
</div>
