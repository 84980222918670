import { Role } from "./role";

export class User {
  id?: number;
  email?: string;
  username?: string;
  name?: string;
  lastname?: string;
  active?: boolean;
  phone?: number;
  picture?: any;
  roles?: Role;
  role?: number

  constructor(user: User) {
    {
      this.id = user.id || 0;
      this.email = user.email || '';
      this.username = user.username || '';
      this.name = user.name || '';
      this.lastname = user.lastname || '';
      this.active = user.active || false;
      this.phone = user.phone || 0;
      this.picture = user.picture || null;
      this.roles = user.roles || {id: 1, name: "Null", description: "", acive: false},
      this.role = user.role || 0;
    }
  }
}