import { Component, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";
import { ServiceService } from "app/apiServices/service.service";
import { Service } from "app/models/service";
import { ServiceFormComponent } from "../dialogs/service-form/service-form.component";
import { ModalConfirmationComponent } from "app/components/modals/modal-confirmation/modal-confirmation.component";

@Component({
  selector: "app-services",
  templateUrl: "./services.component.html",
  styleUrls: ["./services.component.css"],
})
export class ServicesComponent {
  rows: Service[] = [];
  tmpRows: Service[] = [];
  form: FormGroup;

  @ViewChild(DatatableComponent) table: DatatableComponent;
  columnMapValues = [
    ["id", "id"],
    ["name", "Nombre"],
    ["unit_key", "claveDeUnidad"],
    ["iva", "iva"],
    ["iva_retain", "ivaRet"],
    ["isr", "isr"],
    ["isr_retain", "isrRet"],
    ["clave_sat", "cDigoSat"],
    ["obj_tax", "oImpuesto"],
  ] as const;
  columnMap: Map<string, string> = new Map(this.columnMapValues);
  loading: boolean = false;

  ColumnMode = ColumnMode;
  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    public apiServices: ServiceService
  ) {
    this.form = this.fb.group({
      rowsPerPage: [10],
    });
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.loading = true;
    this.apiServices.get().subscribe(
      (r) => {
        this.rows = r.data;
        this.tmpRows = r.data;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    )
  }

  updateFilter(event: Event) {
    const val: string = (event.target as HTMLInputElement).value.toLowerCase();
    const temp: Service[] = this.tmpRows.filter((d: Service) => {
      return (
        d.name.toLowerCase().indexOf(val) !== -1 ||
        d.clave_sat.toLowerCase().indexOf(val) !== -1 ||
        d.isr.toString().toLowerCase().indexOf(val) !== -1 ||
        d.isr_retain.toString().toLowerCase().indexOf(val) !== -1 ||
        d.iva.toString().toLowerCase().indexOf(val) !== -1 ||
        d.iva_retain.toString().toLowerCase().indexOf(val) !== -1 ||
        d.obj_tax.toString().toLowerCase().indexOf(val) !== -1 ||
        d.unit_key.toLowerCase().indexOf(val) !== -1 ||
        !val
      );
    });
    this.rows = temp;
    this.table.offset = 0;
  }

  openDialog(data: Service, action: string) {
    const dialogRef = this.dialog.open(ServiceFormComponent, {
      width: "650px",
      data: {
        action,
        data,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.status === "200") {
        this.getData();
      }
    });
  }

  openDialogDelete(serviceId: number) {
    const dialogRef = this.dialog.open(ModalConfirmationComponent, {
      width: "600px",
      data: {
        title: "¿Estas seguro de desactivar este servicio?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.apiServices.delete(serviceId).subscribe((r) => {
          this.getData();
        });
      }
    });
  }

  onLimitChange(event: Event): void {
    const limit = (event.target as HTMLSelectElement).value;
    this.changePageLimit(limit);
    this.table.limit = this.form.controls.rowsPerPage.value;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor(
          (this.table.rowCount - 1) / this.table.limit
        );
      }
    });
  }

  onSort(event: any): void {
    const sort = event.sorts[0];
    const column = this.columnMap.get(sort.prop);

    if (column) {
      this.rows.sort((a, b) => {
        if (typeof a[column] === "number") {
          if (sort.dir === "asc") return a[column] - b[column];
          else return b[column] - a[column];
        } else if (typeof a[column] === "string") {
          return (
            a[column].localeCompare(b[column]) * (sort.dir === "desc" ? -1 : 1)
          );
        }
      });

      this.rows = [...this.rows];
    }
  }

  changePageLimit(limit: any): void {
    this.form.controls.rowsPerPage.setValue(parseInt(limit, 10));
  }

  delete(serviceId: number) {
    this.openDialogDelete(serviceId);
  }
}
