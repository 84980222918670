import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { ClientsService } from "app/apiServices/clients.service";
import { NotificationsComponent } from "app/components/alerts/notifications/notifications.component";
import { FormValidatorService } from "app/core/service/form-validator.service";
import { Company } from "app/models/company";

@Component({
  selector: "app-clients-for-company",
  templateUrl: "./clients-for-company.component.html",
  styleUrls: ["./clients-for-company.component.css"],
})
export class ClientsForCompanyComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";

  temp: any[] = [];

  clientsList: any[] = [];
  clientsCompany: any[] = [];

  suppliersList: any[] = [];
  suppliersCompany: any[] = [];

  dataSource: any;
  companies: Company[] = [];
  temporalObject: any;

  showAlert = false;
  form: FormGroup;

  selectedClientItems = [];
  selectedSupplierItems = [];

  dropdownClientSettings = {};
  dropdownSupplierSettings = {};

  validationMessages = {
    clients: [
      { type: "required", message: "Seleccione por lo menos una Compañia" },
    ],
    suppliers: [
      { type: "required", message: "Seleccione por lo menos un Proveedor" },
    ],
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ClientsForCompanyComponent>,
    private apiClients: ClientsService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private _snack: MatSnackBar,
    public formService: FormValidatorService
  ) {
    this.dropdownClientSettings = {
      singleSelection: false,
      idField: "clients",
      textField: "legal_name",
      searchPlaceholderText: "Buscar",
      noDataAvailablePlaceholderText: "No hay datos",
      selectAllText: "Seleccionar Todas",
      unSelectAllText: "Deseleccionar Todas",
      itemsShowLimit: 4,
      allowSearchFilter: true,
    };
    this.dropdownSupplierSettings = {
      singleSelection: false,
      idField: "suppliers",
      textField: "legal_name",
      searchPlaceholderText: "Buscar",
      noDataAvailablePlaceholderText: "No hay datos",
      selectAllText: "Seleccionar Todas",
      unSelectAllText: "Deseleccionar Todas",
      itemsShowLimit: 4,
      allowSearchFilter: true,
    };

    this.dataSource = this.data.data;
    this.companies = this.data.companies ?? [];
    this.getClients();
    this.getSuppliers();
    this.form = this.createForm();
  }

  onSupplierSelect(item: any) {}
  onClientSelect(item: any) {}
  onSelectAll(items: any) {}

  ngOnInit(): void {}

  /* Get Clients & Get Suppliers are commented like this to check what the heck is going on */
  getClients() {
    this.clientsList = this.companies.filter((company) => {
      if (company.active && company.clients.length >= 1) {
        return company;
      }
    });
    this.selectedClientItems = this.dataSource.company_clients.map((c) => {
      const client = this.clientsList.find(
        (client: Company) => client?.clients[0]?.id === c?.id
      );
      return {
        ...client,
      };
    });
  }

  /* Get Clients & Get Suppliers are commented like this to check what the heck is going on */
  getSuppliers() {
    this.suppliersList = this.companies.filter((company) => {
      if (company.active && company.suppliers.length >= 1) {
        return company;
      }
    });
    this.selectedSupplierItems = this.dataSource.company_suppliers.map((s) => {
      const supplier = this.suppliersList.find(
        (supplier: Company) => supplier?.suppliers[0]?.id === s?.id
      );
      return {
        ...supplier,
      };
    });
  }

  createForm(): FormGroup {
    return this.fb.group({
      id: [this.dataSource.id],
      company_clients: [this.dataSource.clientsList],
      company_suppliers: [this.dataSource.suppliersList],
    });
  }

  save(): void {
    const relations = {
      clients: this.form.value.company_clients
        ?.filter((c) => c != undefined && c.clients && c.clients.length > 0)
        .map((c) => c.clients[0].id),
      suppliers: this.form.value.company_suppliers
        ?.filter((s) => s != undefined && s.suppliers && s.suppliers.length > 0)
        .map((s) => s.suppliers[0].id),
    };

    this.storeData(relations);
  }

  storeData(object: any) {
    this.apiClients.mainAssing(object, this.dataSource.id).subscribe(
      (r) => {
        this.closeModal({ status: 200 });
      },
      (error) => {
        this.openSnack("snack-error", error.error.message);
      }
    );
  }

  closeModal(data: any): void {
    this.dialogRef.close(data);
  }

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
}
