import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { ColumnMode, SelectionType, id } from "@swimlane/ngx-datatable";
import { AssingClientCreditNoteComponent } from "../assing-client-credit-note/assing-client-credit-note.component";
import { MatDialog } from "@angular/material/dialog";
import { CreateClientCreditNoteComponent } from "../create-client-credit-note/create-client-credit-note.component";
import { ClientsService } from "app/apiServices/clients.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Company } from "app/models/company";
import { Select, Dropdown, Collapse, initTE } from "tw-elements";
import { DateTime } from "luxon";
import { FeeClientComponent } from "../fee-client/fee-client.component";
import { InCompaniesRes } from "app/interfaces/company.interface";
import { Invoice } from "app/models/invoice";
import { InvoiceService } from "app/apiServices/invoice.service";
import { DataService } from "app/services/data.service";
import { NotificationsComponent } from "app/components/alerts/notifications/notifications.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PaymentInvoices } from "app/models/payment_invoices";
import { InvoicePayment } from "app/models/invoice_payment";
import { CreateClientCreditNoteWorelationComponent } from "../create-client-credit-note-worelation/create-client-credit-note-worelation.component";

declare let DateRangePicker: any;

@Component({
  selector: "app-fee-client-list",
  templateUrl: "./fee-client-list.component.html",
  styleUrls: ["./fee-client-list.component.css"],
})
export class FeeClientListComponent implements OnInit, OnDestroy {
  rows = [];
  selected = [];
  objects: Company[];
  clients: any[] = [];
  allCompanies: Company[];
  objectsSuppliers: Company[];
  selectedCompany: Company;
  ColumnMode = ColumnMode;
  form: FormGroup;
  company_id = new FormControl("", [Validators.required]);
  supplier_id = new FormControl("", [Validators.required]);
  SelectionType = SelectionType;
  @ViewChild("company_id", { static: true })
  companySelect: ElementRef;
  @ViewChild("supplier_id", { static: true })
  supplierSelect: ElementRef;
  @ViewChild("container", { static: true })
  container: ElementRef;

  selectOptions = {
    selectFilter: true,
    selectNoResultText: "No se encontraron resultados",
    selectSearchPlaceholder: "Buscar...",
  };
  loading: boolean = false;
  invoices: Invoice[] = [];
  dateRangePicker: any;
  currentCompany: Company;
  currentClient: Company;
  currentInvoices: Invoice[] = [];


  /*columnMapValues = [
    ["cliente", "client"],
    ["montoOriginal", "total"],
    ["fechaFactura", "product"],
    ["pago/abono", "costo"],
    ["verFactura", "invoice"],
    ["opciones", "opciones"],
  ] as const;
  columnMap: Map<string, string> = new Map(this.columnMapValues);*/

  constructor(
    public apiService: ClientsService,
    public dialog: MatDialog,
    private _snack: MatSnackBar,
    private dataService: DataService,
    public invoiceService: InvoiceService
  ) {}

  ngOnInit(): void {
    this.getData();
    this.getInvoices();
  }

  sender() {
    this.loading = true;
    this.dataService.setLoadingValue(this.loading);

    if (!this.currentCompany) {
      this.openSnack("snack-error", "Selecciona una compañia");
      this.loading = false;
      this.dataService.setLoadingValue(this.loading);
      return;
    }

    if (!this.currentClient) {
      this.openSnack("snack-error", "Selecciona un cliente");
      this.loading = false;
      this.dataService.setLoadingValue(this.loading);
      return;
    }


    const invoices = this.invoices.filter((invoice) => {
      return (
        invoice.client.company.id == this.currentClient.id && 
        this.getDueTotal(invoice) !== 0
      );
    });

    this.currentInvoices = invoices;
    this.rows = this.currentInvoices;
    this.loading = false;

    setTimeout(() => {
      this.dataService.setLoadingValue(this.loading);
    }, 400);
  }
  getInvoices() {
    this.invoiceService.getInvoices().subscribe(
      (r) => {
        this.loading = false;
        if (r.status === "Éxito") {
          this.invoices = r.data;
        }
      },
      (e) => {
        this.loading = false;
      }
    );
  }

  changeDate(event: any) {
    const [startDate, endDate] = this.dateRangePicker.getDates();

    if (startDate && endDate) {
      this.dateRangePicker.datepickers[0].element?.click();
      this.container?.nativeElement?.click();
      this.dateRangePicker.datepickers[1].element?.click();
      this.container?.nativeElement?.click();
      const formattedStartDate =
        DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd");
      const formattedEndDate =
        DateTime.fromJSDate(endDate).toFormat("yyyy-MM-dd");
    }
  }

  setInitialDate() {
    this.dateRangePicker.setDates(
      DateTime.local().minus({ week: 1 }).toISODate(),
      DateTime.local().toISODate()
    );
  }
  ngOnDestroy(): void {}

  ngAfterViewInit() {
    new Select(this.companySelect.nativeElement, this.selectOptions);
    new Select(this.supplierSelect.nativeElement, this.selectOptions);
  }

  updateFilter(event: Event) {
    /*const val: string = (event.target as HTMLInputElement).value.toLowerCase();
    const temp: Tank[] = this.tanks.filter((d: Tank) => {
      return d.id.toString().indexOf(val) !== -1 ||
            d.tank.toLowerCase().indexOf(val) !== -1 ||
            d.products[0]?.name.toLowerCase().indexOf(val) !== -1 ||
            !val;
    });
    this.temp = temp;
    this.table.offset = 0;*/
  }

  onActivate(event) {}

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  getPayment(invoice: Invoice): number {
    const paymentsInvoice = invoice.invoice_payments.filter(
      (payment) => payment.payment.type != "NC" && payment.payment.type != "NCE"
    );
  
    return paymentsInvoice.reduce((total, payment) => total + (payment.due_before - payment.due_total), 0);
  }
  
  getNoteCredit(invoice: Invoice): number {
    const paymentsInvoice = invoice.invoice_payments.filter(
      (payment) => payment.payment.type != "P"
    );
  
    return paymentsInvoice.reduce((total, payment) => total + (payment.due_before - payment.due_total), 0);
  }
  
  getTotal(invoice: Invoice): number {
    return this.getPayment(invoice) + this.getNoteCredit(invoice);
  }

  getDueTotal(invoice: Invoice): number {
    const paymentsInvoice =
      invoice.invoice_payments.length > 0 ? invoice.invoice_payments[invoice.invoice_payments.length - 1] : null;
  
    return paymentsInvoice?.due_total != null ? paymentsInvoice.due_total : invoice.total;
  }

  getData(): void {
    this.apiService.readCompanies().subscribe(
      (r: InCompaniesRes) => {
        this.objects = r.data.filter((company) => {
          return company.active && company.main;
        });
        this.allCompanies = r.data.filter((company) => {
          return company.active;
        })
      },
      (error) => {
        throw error;
      }

    );
  }

  getSelectedCompany(id: number): void {
    const idNumber = Number(id);
    const company = this.objects.find((company) => company.id === idNumber);
    this.currentCompany = company;

    if (company && company.main) this.getClientsbyCompany(company);
  }

  getSelectedClient(companyId: number) {
    this.currentClient = this.allCompanies.find(
      (company) => company.active && company.id == companyId
    );
  }

  getClientsbyCompany(companyMN: any) {
    const data = this.allCompanies.filter((company) => {
      if (company.clients.length !== 0) {
        return (
          company.active &&
          companyMN.company_clients.some((element) => {
            return element.id === company.clients[0]?.id;
          })
        );
      }
      return false;
    });
    this.clients = data;
  }

  openCustomerFeeDialog() {
    const dialogRef = this.dialog.open(FeeClientComponent, {
      width: "84vw",
      maxWidth: "84vw",
      height: "80vh",
      data: {
        invoices: this.selected,
        client_id : this.currentClient.id
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.invoiceService.getInvoices().subscribe({
          next: (res) => {
            this.selected = [];
            this.invoices = [];
            this.invoices = res.data;
            this.sender();
          },
          error: (err) => {
            this.openSnack("snack-error", err.error.message);
          },
        });
      }
    });
  }

  openCreateCreditNoteDialog() {
    const dialogRef = this.dialog.open(CreateClientCreditNoteComponent, {
      width: "84vw",
      maxWidth: "84vw",
      height: "80vh",
      data: {
        invoices: this.selected,
        client_id : this.currentClient.id
            },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.invoiceService.getInvoices().subscribe({
          next: (res) => {
            this.selected = [];
            this.invoices = [];
            this.invoices = res.data;
            this.sender();
          },
          error: (err) => {
            this.openSnack("snack-error", err.error.message);
          },
        });
      }
    });
  }
  openCreateCreditNoteNRDialog() {
    const dialogRef = this.dialog.open(CreateClientCreditNoteWorelationComponent, {
      width: "84vw",
      maxWidth: "84vw",
      height: "80vh",
      data: {
        invoices: this.selected,
        client_id : this.currentClient.id
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }


  openAssignCreditNoteDialog() {
    const dialogRef = this.dialog.open(AssingClientCreditNoteComponent, {
      width: "84vw",
      maxWidth: "84vw",
      height: "80vh",
      data: {
        invoices: this.selected,
        client_id : this.currentClient.id
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.invoiceService.getInvoices().subscribe({
          next: (res) => {
            this.selected = [];
            this.invoices = [];
            this.invoices = res.data;
            this.sender();
          },
          error: (err) => {
            this.openSnack("snack-error", err.error.message);
          },
        });
      }
    });
  }


  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
      verticalPosition: "top",
    });
  }
}
