import { TankProduct } from "./tank";

export class Product {
  [x: string]: any;
  id?: number;
  name?: string;
  description?: string;
  code?: string;
  full_mix?: boolean;
  on_hand?: number;
  on_order?: number;
  list_price?: number;
  authorized_price?: number;
  supply_price?: number;
  profit_margin?: number;
  physical_cost?: number;
  ieps?: boolean;
  ieps_cost?: number;
  tankProduct?: TankProduct;
  products?: ProductMixes[];
  compound_products?: ProductMixes[];
  sellable?: boolean;
  clave_sat?: string;
  operative_cost?: number;

  constructor(product: Product) {
    this.id = product.id || undefined;
    this.name = product.name || "";
    this.description = product.description || "";
    this.code = product.code || "";
    this.full_mix = product.full_mix || true;
    this.on_hand = product.on_hand || 0;
    this.on_order = product.on_order || 0;
    this.list_price = product.list_price || 0;
    this.physical_cost = product.physical_cost || 0;
    this.authorized_price = product.authorized_price || 0;
    this.supply_price = product.supply_price || 0;
    this.profit_margin = product.profit_margin || 0;
    this.tankProduct = product.tankProduct || undefined;
    this.products = product.products || undefined;
    this.compound_products = product.compound_products || undefined;
    this.ieps = product.ieps || false;
    this.ieps_cost = product.ieps_cost || 0;
    this.sellable = product.sellable || true;
    this.clave_sat = product.clave_sat || "";
    this.operative_cost = product.operative_cost || 0;
  }
}

export class ProductMixes {
  id?: number;
  element_product_id?: number;
  compound_product_id?: number;
  percentage?: number;
  active?: boolean;
  product?: Product;

  constructor(productMixes: ProductMixes) {
    this.id = productMixes.id || undefined;
    this.element_product_id = productMixes.element_product_id || undefined;
    this.compound_product_id = productMixes.compound_product_id || undefined;
    this.percentage = productMixes.percentage || 0;
    this.active = productMixes.active || true;
    this.product = productMixes.product || undefined;
  }
}
