import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormComponent } from "app/components/sections/admin/roles/dialogs/form/form.component";

@Component({
  selector: "app-modal-confirmation",
  templateUrl: "./modal-confirmation.component.html",
  styleUrls: ["./modal-confirmation.component.css"],
})
export class ModalConfirmationComponent {
  dialogTitle: string;

  constructor(
    private dialogRef: MatDialogRef<FormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialogTitle = data.title;
  }

  save(): void {
    this.dialogRef.close(true);
  }

  closeModal(): void {
    this.dialogRef.close(false);
  }
}
