import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { DataService } from "app/services/data.service";
import { CookieService } from "ngx-cookie-service";

export const authGuard: CanActivateFn = (route, state) => {
  const cookieService = inject(CookieService);
  const dataService = inject(DataService);
  const router: Router = inject(Router);
  const isAuthToken = localStorage.getItem("token") !== null;
  const currentPath = state.url;
  let firstPath = null;
  let currentView = "-1";

  if (isAuthToken) {
    firstPath = getFirstPastView();
    currentView = getCurrentView(currentPath);

    if (currentPath == "/") {
      if (currentView === "-1") return redirectToDefault(firstPath);
    } else {
      if (currentView === "-1") return redirectToDefault(firstPath);
      else return true;
    }
  } else {
    dataService.clear();
    cookieService.delete("table", "/");

    if (currentPath != "/") {
      router.navigate(["/"]);
      return false;
    } else {
      return true;
    }
  }
};

const redirectToDefault = (firstPath: string): boolean => {
  const router = inject(Router);
  const dataService = inject(DataService);
  const cookieService = inject(CookieService);

  if (firstPath != null) {
    router.navigate([firstPath]);
    return true;
  } else {
    dataService.clear();
    localStorage.clear();
    cookieService.delete("table", "/");
  }

  router.navigate(["/"]);
  return false;
};

const getFirstPastView = (): string => {
  const dataService = inject(DataService);
  let firstPath = null;

  if (dataService.viewValue) {
    for (const category of dataService.categoriesValue) {
      for (const view of category.views) {
        if (view.name === dataService.viewValue) {
          firstPath = view.path;
          break;
        }
      }
    }

    if (
      firstPath === null &&
      dataService.categoriesValue &&
      dataService.categoriesValue.length > 0 &&
      dataService.categoriesValue[0].views.length > 0
    )
      firstPath = dataService.categoriesValue[0].views[0].path;
  } else if (
    dataService.categoriesValue &&
    dataService.categoriesValue.length > 0 &&
    dataService.categoriesValue[0].views.length > 0
  )
    firstPath = dataService.categoriesValue[0].views[0].path;

  return firstPath;
};

const getCurrentView = (currentPath: string): string => {
  const dataService = inject(DataService);
  let currentView = "-1";

  if (!dataService.categoriesValue) return currentView;

  for (const category of dataService.categoriesValue) {
    for (const view of category.views) {
      if (view.path === currentPath) {
        currentView = view.path;
        break;
      }
    }
  }

  return currentView;
};
