<div class="">
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-200 border-0">
    <div class="relative flex flex-col min-w-0 break-words w-full rounded">
      <div
        class="grid grid-cols-2 gap-2 rounded-t mb-0 px-4 py-3 border-0 pt-6">
        <div class="basis-1/2 flex justify-start">
          <span
            class="h-full leading-snug font-normal absolute text-center text-slate-300 bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
            <i class="fa fa-search"></i>
          </span>

          <input
            type="text"
            class="w-48 pl-12 py-3 rounded-full border text-sm border-gray-300 focus:outline-none focus:border-blue-400"
            (keyup)="updateFilter($event)"
            placeholder="Buscar"/>
        </div>

        <!-- TypeOf Companies Buttons -->
        <div class="basis-1/2 flex justify-end">
          <div class="py-4 px-16">
            <div
              class="relative"
              data-te-dropdown-ref=""
              data-te-dropdown-alignment="end"
            >
              <a
                class="hidden-arrow flex items-center whitespace-nowrap transition duration-150 ease-in-out motion-reduce:transition-none"
                id="dropdownOptions"
                role="button"
                data-te-dropdown-toggle-ref=""
                aria-expanded="false"
              >
                <i class="fa fa-angle-double-down"></i>
              </a>
              <ul
                class="absolute z-[1000] float-left m-0 hidden w-56 min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700 [&amp;[data-te-dropdown-show]]:block"
                aria-labelledby="dropdownOptions"
                data-te-dropdown-menu-ref=""
              >
                <li class="border-b-2 border-solid border-gray-100">
                  <a
                    class="block w-full whitespace-nowrap cursor-pointer bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                    data-te-dropdown-item-ref=""
                    (click)="redirectTransportist()"
                    ><i class="fa fa-bus w-8"></i> Transportistas
                  </a>
                </li>
                <li class="border-b-2 border-solid border-gray-100">
                  <a
                    class="block w-full whitespace-nowrap cursor-pointer bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                    data-te-dropdown-item-ref=""
                    (click)="redirectClients()"
                    ><i class="fas fa-box-open w-8"></i> Clientes
                  </a>
                </li>
                <li>
                  <a
                    class="block w-full whitespace-nowrap cursor-pointer bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                    data-te-dropdown-item-ref=""
                    (click)="redirectSuppliers()"
                    ><i class="fas fa-money-bill w-8"></i> Vendedores
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <button
            class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-sm px-6 py-3 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            (click)="openDialog(null, 'store')"
          >
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>

      <div class="grid">
        <div class="block w-full overflow-x-auto rounded-lg">
          <ngx-datatable
            #table
            class="material"
            [columnMode]="ColumnMode.force"
            [headerHeight]="50"
            [footerHeight]="50"
            rowHeight="auto"
            [limit]="10"
            [rows]="rows"
            [loadingIndicator]="loading"
            [scrollbarH]="true"
            (sort)="onSort($event)">

            <ngx-datatable-column name="Id">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.id }}
              </ng-template>
            </ngx-datatable-column>


            <ngx-datatable-column name="Razón Social">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.legal_name }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Principal">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span
                  [ngClass]="{
                    'text-green-600': row.main,
                    'text-red-600': !row.main,
                    'bg-green-200': row.main,
                    'bg-red-200': !row.main
                  }"
                  class="text-xs font-semibold inline-block py-1 px-2 rounded-full uppercase last:mr-0 mr-1">
                  {{ row.main ? "Si" : "No" }}
                </span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Informacion" [sortable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <button
                  class="bg-blue-500 text-white px-2 py-1 rounded-full text-center"
                  (click)="openDialogInfo(row)">
                  <i class="fas fa-info-circle"> </i>
                </button>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Relaciones" [sortable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <button
                  *ngIf="row.main"
                  class="bg-yellow-500 text-white px-2 py-1 rounded-full text-center"
                  (click)="openDialogClients(row)">
                  <i class="fa fa-server"></i>
                </button>
              </ng-template>
            </ngx-datatable-column>

            <!-- <ngx-datatable-column name="Opciones" [sortable]="false">
              <ng-template
                #buttonsTemplate
                let-row="row"
                let-value="value"
                ngx-datatable-cell-template>
                <div class="flex">
                  <button
                    class="bg-blue-500 text-white mx-8 px-2 py-1 rounded-full text-center"
                    (click)="addAdresses(row)">
                    <i class="fas fa-map-marker-alt"></i>
                  </button>

                  <button
                    class="bg-emerald-500 text-white px-2 py-1 rounded-full text-center"
                    (click)="openDialog(row, 'edit')">
                    <i class="fas fa-edit"> </i>
                  </button>

                  <button
                    class="bg-red-500 text-white mx-8 px-2 py-1 rounded-full text-center"
                    (click)="delete(row.id)">
                    <i class="fas fa-trash-alt"> </i>
                  </button>
                </div>
              </ng-template>
            </ngx-datatable-column> -->

            <ngx-datatable-column name="Opciones" [sortable]="false">
              <ng-template
                #buttonsTemplate
                let-row="row"
                let-value="value"
                ngx-datatable-cell-template>
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  aria-label="Menu">
                  <i class="fas fa-ellipsis-v"></i>
                </button>
                <mat-menu #menu="matMenu">
                  <button
                    class="bg-blue-500 text-white mx-8 px-2 py-1 rounded-full text-center"
                    (click)="addAdresses(row)">
                    <i class="fas fa-map-marker-alt"></i>
                  </button>

                  <button
                    class="bg-emerald-500 text-white px-2 py-1 rounded-full text-center"
                    (click)="openDialog(row, 'edit')">
                    <i class="fas fa-edit"> </i>
                  </button>

                  <button
                    class="bg-red-500 text-white mx-8 px-2 py-1 rounded-full text-center"
                    (click)="delete(row.id)">
                    <i class="fas fa-trash-alt"> </i>
                  </button>
                </mat-menu>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible"
              >
                <div class="page-count hide">{{ rowCount }} total</div>

                <div>
                  <form [formGroup]="form">
                    <div>
                      <div
                        class="flex justify-content-between align-items-center"
                      >
                        <label
                          class="flex align-items-center mx-4 my-2"
                          for="rowsPerPage"
                          >Mostrar por página:
                        </label>

                        <select
                          class="rounded form-control mx"
                          formControlName="rowsPerPage"
                          (change)="onLimitChange($event)"
                          id="rowsPerPage"
                          name="rowsPerPage"
                        >
                          <option [value]="5">5</option>
                          <option [value]="10">10</option>
                          <option [value]="25">25</option>
                          <option [value]="50">50</option>
                        </select>
                      </div>
                    </div>
                  </form>
                </div>

                <datatable-pager
                  [pagerLeftArrowIcon]="'datatable-icon-left'"
                  [pagerRightArrowIcon]="'datatable-icon-right'"
                  [pagerPreviousIcon]="'datatable-icon-prev'"
                  [pagerNextIcon]="'datatable-icon-skip'"
                  [page]="curPage"
                  [size]="pageSize"
                  [count]="rowCount"
                  (change)="table.onFooterPage($event)"
                >
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
