<div class="container bg-white mx-auto px-4 h-full py-4">
  <div class="justify-between rounded-t mb-0 px-4 py-3 border-0 pt-6 bg-white">
    <div class="text-center">
      <h3 class="font-semibold text-lg">Formulario de Compañia</h3>
    </div>
  </div>
  <form class="bg-white" [formGroup]="form">
    <div class="relative w-full mb-3 flex">
      <div class="container px-2">
        <label
          class="block uppercase text-slate-600 text-xs font-bold mb-2"
          htmlFor="legal_id"
        >
          RCF empresarial
        </label>
        <input
          type="text"
          name="legal_id"
          formControlName="legal_id"
          [ngClass]="{
            'border-red-500': form.controls.legal_id.invalid,
            'focus:border-red-500': form.controls.legal_id.invalid,
            'focus:ring-red-500': form.controls.legal_id.invalid
          }"
          class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        />
        <div *ngFor="let validation of validationMessages.legal_id">
          <span
            *ngIf="formService.field(form, validation.type, 'legal_id')"
            class="mt-2 text-sm text-red-500"
          >
            {{ validation.message }}
          </span>
        </div>
      </div>

      <div class="container px-2">
        <label
          class="block uppercase text-slate-600 text-xs font-bold mb-2"
          htmlFor="legal_name"
        >
          Razón Social
        </label>
        <input
          type="text"
          name="legal_name"
          formControlName="legal_name"
          [ngClass]="{
            'border-red-500': form.controls.legal_name.invalid,
            'focus:border-red-500': form.controls.legal_name.invalid,
            'focus:ring-red-500': form.controls.legal_name.invalid
          }"
          class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        />
        <div *ngFor="let validation of validationMessages.legal_name">
          <span
            *ngIf="formService.field(form, validation.type, 'legal_name')"
            class="mt-2 text-sm text-red-500"
          >
            {{ validation.message }}
          </span>
        </div>
      </div>
    </div>

    <div class="relative w-full mb-3 flex">
      <div class="container px-2">
        <label
          class="block uppercase text-slate-600 text-xs font-bold mb-2"
          htmlFor="contact_name"
        >
          Nombre del Contacto
        </label>
        <input
          type="text"
          name="contact_name"
          formControlName="contact_name"
          [ngClass]="{
            'border-red-500': form.controls.contact_name.invalid,
            'focus:border-red-500': form.controls.contact_name.invalid,
            'focus:ring-red-500': form.controls.contact_name.invalid
          }"
          class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        />
        <div *ngFor="let validation of validationMessages.contact_name">
          <span
            *ngIf="formService.field(form, validation.type, 'contact_name')"
            class="mt-2 text-sm text-red-500"
          >
            {{ validation.message }}
          </span>
        </div>
      </div>

      <div class="container px-2">
        <label
          class="block uppercase text-slate-600 text-xs font-bold mb-2"
          htmlFor="contact_phone"
        >
          Numero del Contacto
        </label>
        <input
          type="text"
          name="contact_phone"
          formControlName="contact_phone"
          [ngClass]="{
            'border-red-500': form.controls.contact_phone.invalid,
            'focus:border-red-500': form.controls.contact_phone.invalid,
            'focus:ring-red-500': form.controls.contact_phone.invalid
          }"
          class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        />
        <div *ngFor="let validation of validationMessages.contact_phone">
          <span
            *ngIf="formService.field(form, validation.type, 'contact_phone')"
            class="mt-2 text-sm text-red-500"
          >
            {{ validation.message }}
          </span>
        </div>
      </div>
    </div>

    <div class="relative w-full mb-3 flex">
      <div class="container px-2 py-1">
        <label
          class="block uppercase text-slate-600 text-xs font-bold mb-2"
          htmlFor="regimen_fiscal_id"
        >
          Regimen Fiscal
        </label>
        <select
          name="regimen_fiscal_id"
          formControlName="regimen_fiscal_id"
          class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        >
          <option *ngFor="let item of regimen" [ngValue]="item.id">
            {{ item.regimen_fiscal + ", " + item.descripcion }}
          </option>
        </select>
      </div>

      <!-- <div class="container px-2 py-1">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="uso_cfdi_id">
                    CFID
                </label>
                <select name="uso_cfdi_id" formControlName="uso_cfdi_id"
                class="border-2 px-3 py-3
                placeholder-slate-300
                text-slate-600 bg-white rounded border-gray-300
                text-sm focus:outline-none focus:ring
                w-full ease-linear transition-all duration-150">
                    <option *ngFor="let item of cfdi" [ngValue]="item.id">{{ item.uso_cfdi + ", " + item.descripcion }}</option>
                </select>
            </div> -->
    </div>

    <div class="relative w-full mb-3 flex">
      <div class="container px-2 text-center">
        <label
          class="block uppercase text-slate-600 text-xs font-bold mb-2"
          htmlFor="is_client"
        >
          Cliente
        </label>
        <input
          type="checkbox"
          name="is_client"
          formControlName="is_client"
          class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded-full border-gray-300 text-sm focus:outline-none focus:ring ease-linear transition-all duration-150"
        />
      </div>

      <div class="container px-2 text-center">
        <label
          class="block uppercase text-slate-600 text-xs font-bold mb-2"
          htmlFor="is_supplier"
        >
          Proveedor
        </label>
        <input
          type="checkbox"
          name="is_supplier"
          formControlName="is_supplier"
          class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded-full border-gray-300 text-sm focus:outline-none focus:ring ease-linear transition-all duration-150"
        />
      </div>

      <div class="container px-2 text-center">
        <label
          class="block uppercase text-slate-600 text-xs font-bold mb-2"
          htmlFor="is_transportist"
        >
          Transportista
        </label>
        <input
          type="checkbox"
          name="is_transportist"
          formControlName="is_transportist"
          class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded-full border-gray-300 text-sm focus:outline-none focus:ring ease-linear transition-all duration-150"
        />
      </div>
    </div>

    <div class="relative w-full mb-3 flex">
      <div class="container px-2 text-center">
        <label
          class="block uppercase text-slate-600 text-xs font-bold mb-2"
          htmlFor="main"
        >
          Principal
        </label>
        <input
          type="checkbox"
          name="main"
          formControlName="main"
          class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded-full border-gray-300 text-sm focus:outline-none focus:ring ease-linear transition-all duration-150"
        />
      </div>
      <!-- <div class="container px-2 text-center" *ngIf="!edit">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="main" *ngIf="!edit">
                    Vendedor
                  </label>
                  <input type="checkbox" name="seller" formControlName="seller"
                    class="border-2 px-3 py-3
                    placeholder-slate-300
                    text-slate-600 bg-white rounded-full border-gray-300
                    text-sm focus:outline-none focus:ring
                    ease-linear transition-all duration-150"/>
            </div> -->
    </div>

    <div class="relative w-full mb-3 flex">
      <div class="container px-2">
        <label
          class="block uppercase text-slate-600 text-xs font-bold mb-2"
          htmlFor="folio"
        >
          Folio
        </label>
        <input
          type="text"
          name="folio"
          formControlName="folio"
          [ngClass]="{
            'border-red-500': form.controls.folio.invalid,
            'focus:border-red-500': form.controls.folio.invalid,
            'focus:ring-red-500': form.controls.folio.invalid
          }"
          class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        />
        <div *ngFor="let validation of validationMessages.folio">
          <span
            *ngIf="formService.field(form, validation.type, 'folio')"
            class="mt-2 text-sm text-red-500"
          >
            {{ validation.message }}
          </span>
        </div>
      </div>
    </div>

    <div class="flex w-full justify-end">
      <button
        class="bg-slate-500 text-white active:bg-slate-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        (click)="closeModal({})"
      >
        Cancelar
      </button>
      <button
        class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        [ngClass]="{ 'disabled:opacity-25': form.invalid }"
        [disabled]="form.invalid"
        (click)="save()"
      >
        {{ edit ? "Actualizar" : "Agregar" }}
      </button>
    </div>
  </form>
</div>
