<!-- <div class="container bg-white mx-auto px-4 h-full py-4">
    <div class="justify-between rounded-t mb-0 px-4 py-3 border-0 pt-6 bg-white">
        <div class="text-center">
            <h3 class="font-semibold text-lg">
                Vistas de Categoria: {{dataSource.name}}
            </h3>
        </div>
    </div>
    <form class="bg-white" [formGroup]="form">
        <div class="relative w-full mb-3 flex">
            <div class="w-full mb-3"> -->
                <!-- <div class="row mat-typography" style="padding: 0 4.5vw; ">
                    <div class="col-6 px-1" *ngFor="let item of views">
                        <input type="checkbox" formControl="views" formControlName="views"
                        class="border-2 px-3 py-3
                        placeholder-slate-300
                        text-slate-600 bg-white rounded-full border-gray-300
                        text-sm focus:outline-none focus:ring
                        ease-linear transition-all duration-150" 
                        color="primary"  [id]="item.id" [value]="item.id">
                        {{item.name}}
                    </div>
                </div> -->
                <!-- 
                <div *ngFor="let e of views">
                    <label class="flex items-center">
                      <input type="checkbox" [formControlName]="e.id"  
                        class="border-2 px-3 py-3
                        placeholder-slate-300
                        text-slate-600 bg-white rounded-full border-gray-300
                        text-sm focus:outline-none focus:ring
                        ease-linear transition-all duration-150"/>
                      <span class="ml-2">{{ e.name }}</span>
                    </label>
                  </div> -->                
                <!-- <ng-multiselect-dropdown name="view" formControlName="view" class="w-2/3 z-70"
                  [placeholder]="'Selecciona una vista'" [settings]="dropdownSettings" maxHeight="10" [data]="this.views"
                  noDataLabel="No hay datos" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
                </ng-multiselect-dropdown>
            </div>
        </div>
        <div class="flex w-full justify-end">
            <button
                class="bg-slate-500 text-white 
                active:bg-slate-500 font-bold uppercase 
                text-xs px-4 py-2 rounded shadow hover:shadow-md 
                outline-none focus:outline-none mr-1 ease-linear 
                transition-all duration-150" (click)="closeModal();"> 
                Cerrar
            </button>
        </div>
    </form>
  </div> -->



  <div class="container bg-white mx-auto px-4 h-full py-4 overflow-hidden">
    <div class="flex justify-between rounded-t mb-0 px-4 py-3 border-0 pt-6 bg-white">
      <div class="flex">
        <div class="text-center">
          <h3 class="font-semibold text-lg">
            Vistas de Categoria: {{dataSource.name}}
          </h3>
        </div>
      </div>
    </div>
  
    <form class="mx-4 my-6 bg-white" [formGroup]="form">
      <div class="w-full mb-3">
        <ng-multiselect-dropdown name="views" formControlName="views" class="w-2/3 z-70"
          [placeholder]="'Selecciona una vista'" [settings]="dropdownSettings" maxHeight="10" [data]="views"
          noDataLabel="No hay datos" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
        </ng-multiselect-dropdown>
  
        <div *ngFor="let validation of validationMessages.views">
          <span *ngIf="formService.field(form, validation.type, 'views')" class="mt-2 text-sm text-red-500">
            {{ validation.message }}
          </span>
        </div>
      </div>
    </form>
  
    <div class="flex items-center justify-end mt-16">
      <button
        class="bg-slate-500 text-white active:bg-slate-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        (click)="closeModal()">
        Cancelar
      </button>
      <button
        class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        [ngClass]="{ 'disabled:opacity-25': form.invalid }" [disabled]="form.invalid" (click)="modify()">
        Actualizar
      </button>
    </div>
  </div>