<div class="flex items-center justify-between">
  <div class="flex items-center justify-start rtl:justify-end">
    <button
      data-drawer-target="logo-sidebar"
      data-drawer-toggle="logo-sidebar"
      aria-controls="logo-sidebar"
      type="button"
      class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
    >
      <span class="sr-only">Open sidebar</span>
      <svg
        class="w-6 h-6"
        aria-hidden="true"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clip-rule="evenodd"
          fill-rule="evenodd"
          d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
        ></path>
      </svg>
    </button>
    <div class="flex ms-2 md:me-24">
      <img
        src="../../../../assets/img/logoHeader.png"
        alt=""
        class="h-8 me-3"
      />
    </div>
  </div>

  <div class="py-5 px-8">
    <div
      class="relative"
      data-te-dropdown-ref=""
      data-te-dropdown-alignment="end"
    >
      <a
        class="hidden-arrow flex items-center whitespace-nowrap transition duration-150 ease-in-out motion-reduce:transition-none"
        id="dropdownOptions"
        role="button"
        data-te-dropdown-toggle-ref=""
        aria-expanded="false"
      >
        <i class="fas fa-cog"></i>
      </a>
      <ul
        class="absolute z-[1000] float-left m-0 hidden w-56 min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700 [&amp;[data-te-dropdown-show]]:block"
        aria-labelledby="dropdownOptions"
        data-te-dropdown-menu-ref=""
        style=""
      >
        <li
          (click)="userInfo()"
          class="border-b-2 border-solid border-gray-100"
        >
          <a
            class="block w-full whitespace-nowrap cursor-pointer bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
            data-te-dropdown-item-ref=""
            ><i class="fas fa-user w-8"></i> Perfil</a
          >
        </li>
        <li (click)="logOut()">
          <a
            class="block w-full whitespace-nowrap cursor-pointer bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
            data-te-dropdown-item-ref=""
            ><i class="fas fa-sign-out-alt w-8"></i> Cerrar sesión</a
          >
        </li>
      </ul>
    </div>
  </div>
</div>
