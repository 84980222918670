import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InAddressRes } from 'app/interfaces/address.interface';
import { InComissionProductRes } from 'app/interfaces/comission.product';
import { InDiscountProductRes } from 'app/interfaces/discount.product';
import { FormPaymentMethod } from 'app/models/FormPaymentMethod';
// import { InCompaniesRes } from 'app/interfaces/company.interface';
import { Company } from 'app/models/company';
import { Route } from 'app/models/route';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(
    public http: HttpClient
  ) { }

  /* Regimen Fiscal */
  getRegimen(): Observable<any> {
    return this.http.get<any>(`${environment.url}sat/regimen-fiscal/get`)
  }

  /* CFDI */
  getCFDI(): Observable<any> {
    return this.http.get<any>(`${environment.url}sat/uso-cfdi/get`)
  }

  /* Payment Methods */
  getFormPaymentMethods(): Observable<any> {
    return this.http.get<any>(`${environment.url}sat/formas-pago/get`)
  }

  /* Localities */
  getLocalities(pc: number): Observable<any> {
    return this.http.get<any>(`${environment.url}clients-addresses/localities/get/${pc}`)
  }

  /* Companies */
  createCompany(data: Company): Observable<any> {
    return this.http.post<any>(`${environment.url}clients-addresses/companies/create`, data, {})
  }

  readCompanies(): Observable<any> {
    return this.http.get<any>(`${environment.url}clients-addresses/companies/get`)
  }

  updateCompany(data: Company): Observable<any> {
    return this.http.patch<any>(`${environment.url}clients-addresses/companies/update/${data.id}`, data, {})
  }

  deleteCompany(id: number): Observable<any> {
    return this.http.delete<any>(`${environment.url}clients-addresses/companies/delete/${id}`)
  }

  /* Discounts per Product */
  createDiscountProduct(data: any): Observable<any> {
    return this.http.post<any>(`${environment.url}clients-addresses/discount-products/create`, data, {})
  }

  readDiscountsProduct(): Observable<InDiscountProductRes> {
    return this.http.get<any>(`${environment.url}clients-addresses/discount-products/get`)
  }

  updateDiscountProduct(data: any): Observable<any> {
    return this.http.patch<any>(`${environment.url}clients-addresses/discount-products/update/${data.id}`, data, {})
  }

  deleteDiscountPRoduct(id: number): Observable<any> {
    return this.http.delete<any>(`${environment.url}clients-addresses/discount-products/delete/${id}`)
  }

  /* Routes */
  createRoutes(data: Route): Observable<any> {
    return this.http.post<any>(`${environment.url}clients-addresses/routes/create`, data, {})
  }

  readRoutes(): Observable<any> {
    return this.http.get<any>(`${environment.url}clients-addresses/routes/get`)
  }

  updateRoutes(data: Route): Observable<any> {
    return this.http.patch<any>(`${environment.url}clients-addresses/routes/update/${data.id}`, data, {})
  }

  deleteRoutes(data: Route): Observable<any> {
    return this.http.delete<any>(`${environment.url}clients-addresses/routes/delete/${data.id}`)
  }

  /* Commisions */
  createCommision(data: any): Observable<any> {
    return this.http.post<any>(`${environment.url}clients-addresses/commision-products/create`, data, {})
  }

  readCommisions(): Observable<InComissionProductRes> {
    return this.http.get<any>(`${environment.url}clients-addresses/commision-products/get`)
  }

  updateCommision(data: any): Observable<any> {
    return this.http.patch<any>(`${environment.url}clients-addresses/commision-products/update/${data.id}`, data, {})
  }

  deleteCommision(id: number): Observable<any> {
    return this.http.delete<any>(`${environment.url}clients-addresses/commision-products/delete/${id}`)
  }

  /* Price per Route for Transportist */
  createPRT(data: any): Observable<any> {
    return this.http.post<any>(`${environment.url}clients-addresses/transportist-route-prices/create`, data, {})
  }

  readPRT(): Observable<any> {
    return this.http.get<any>(`${environment.url}clients-addresses/transportist-route-prices/get`)
  }

  updatePRT(data: any): Observable<any> {
    return this.http.patch<any>(`${environment.url}clients-addresses/transportist-route-prices/update/${data.id}`, data, {})
  }

  deletePRT(id: number): Observable<any> {
    return this.http.delete<any>(`${environment.url}clients-addresses/transportist-route-prices/delete/${id}`)
  }

  /* Main Assing */
  mainAssing(data: any, id: number): Observable<any> {
    return this.http.post<any>(`${environment.url}clients-addresses/companies/main-assign/${id}`, data, {})
  }

  /* Address */
  getAddress(): Observable<InAddressRes> {
    return this.http.get<any>(`${environment.url}clients-addresses/addresses/get`, {})
  }
  sendAddress(data: any): Observable<any> {
    return this.http.post<any>(`${environment.url}clients-addresses/addresses/create`, data, {})
  }
  deleteAddress(id: number): Observable<any> {
    return this.http.delete<any>(`${environment.url}clients-addresses/addresses/delete/${id}`)
  }
}