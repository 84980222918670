import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Chart } from 'chart.js';
import { max } from 'rxjs/operators';
@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent implements OnInit{
  tanques = []
  iday = []
  iactual = []
  ifinal = []
  chart : Chart;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
  this.tanques.length = 34;
  
  }
  ngAfterViewInit() {
  this.iday = [100000, 80000, 70000, 40000, 50000, 60000, 120000, 130000, 140000, 150000];
  this.iactual = [45000, 65000, 75000, 85000, 95000, 105000, 115000, 125000, 135000, 145000];
  this.ifinal = [15000, 20000, 25000, 30000, 35000, 40000, 45000, 50000, 55000, 60000];
    setTimeout(() => {
      this.initializeChart();
      this.cdr.detectChanges();
    }, 300);
  }

  initializeChart(){
    const datasets = [{
      label: 'Al inicio del dia',
      data: this.iday,
      fill: true,
      backgroundColor: 'rgb(2 132 199)',
      borderColor: 'rgb(2 132 199)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(2, 132, 199, 0.8)',
      hoverBorderColor: 'rgb(2 132 199)'
  }, {
      label: 'Inventario Actual',
      fill: true,
      data: this.iactual,
      backgroundColor: 'rgb(132 204 22)',
      borderColor: 'rgb(132 204 22)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(132, 204, 22, 0.8)',
      hoverBorderColor: 'rgb(132 204 22)'
  }, {
      label: 'Estimado final del dia',
      fill: true,
      data: this.ifinal,
      backgroundColor: 'rgb(249 115 22)',
      borderColor: 'rgb(249 115 22)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(249, 115, 22, 0.8)',
      hoverBorderColor: 'rgb(249 115 22)'
  }];
  const data = {
      labels: ['Tanque1', 'Tanque2', 'Tanque3', 'Tanque4', 'Tanque5', 'Tanque6', 'Tanque7', 'Tanque8', 'Tanque9', 'Tanque10'],
      datasets: datasets
    };
    const options = {
      responsive: false,
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          display: true,
          gridLines: {
            display: false
          },
          position: 'top',
          ticks: {
            maxTicksLimit: 2,
            max:150000,
            fontColor: 'black',
            fontStyle: 'semibold',
            fontSize: 16,
            callback: function(value, index, values) {
              return value
            }
          }
        }],
        yAxes: [{
          barpercentage: 0.95,
          categoryPercentage: 0.95,
          gridLines: {
            display: false
          },
          ticks: {

            callback: function(value, index, values) {
              return value;
            },
            fontColor: '#000000',
            fontSize: 16,
            fontStyle: 'bold' 
          }
        }]
      },
      animation: {
        onComplete: function() {
          var ctx = this.chart.ctx;
          ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, 'bold', Chart.defaults.global.defaultFontFamily);
          ctx.textAlign = 'center';
          ctx.textBaseline = 'bottom';
          ctx.fillStyle = 'black'; 
  
          this.data.datasets.forEach(function(dataset, i) {
            var meta = this.chart.getDatasetMeta(i);
            meta.data.forEach(function(bar, index) {
              var data = dataset.data[index];
              ctx.fillText(data, bar._model.x + 20, bar._model.y + 7);
            }, this);
          }, this);
        }
      }
    };
  
    const minHeightPerBar = 185;
    const totalBars = data.datasets.length;
    const totalHeight = minHeightPerBar * totalBars;
  
    const canvas = document.getElementById('chart') as HTMLCanvasElement;
    canvas.style.height = `${totalHeight}px`;
  
    this.chart = new Chart(canvas, {
      type: 'horizontalBar',
      data: data,
      options: options
    });
  }
}
