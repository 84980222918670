import { Component, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";
import { Measure } from "app/models/measure";
import { FormComponent } from "./form/form.component";
import { ModalConfirmationComponent } from "../modals/modal-confirmation/modal-confirmation.component";
import { InventoryService } from "app/apiServices/inventory.service";
import { FormGroup, FormBuilder } from "@angular/forms";

@Component({
  selector: "app-pump-measure",
  templateUrl: "./pump-measure.component.html",
  styleUrls: ["./pump-measure.component.css"],
})
export class PumpMeasureComponent {
  // rows: Measure[] = [
  //   { id: 1, name: 'Liters', active: true, description: 'Litros?', size: 1000 }
  // ]

  temp: any[] = [];
  showAlert: boolean = false;
  form: FormGroup;

  @ViewChild(DatatableComponent) table: DatatableComponent;
  columnMode = ColumnMode;
  columnMapValues = [
    ["id", "id"],
    ["nombre", "name"],
    ["descripciN", "description"],
    ["tamaO", "size"],
  ] as const;
  columnMap: Map<string, string> = new Map(this.columnMapValues);
  loading: boolean = false;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private inventorySvc: InventoryService
  ) {
    this.form = this.fb.group({
      rowsPerPage: [10],
    });
    // this.temp = [...this.temp]
  }

  ngOnInit(): void {
    this.getData();
  }

  updateFilter(event: Event) {
    const val: string = (event.target as HTMLInputElement).value.toLowerCase();
    const temp: Measure[] = this.temp.filter((d: Measure) => {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.temp = temp;
    this.table.offset = 0;
  }

  openDialog(data: any, action: string) {
    const dialogRef = this.dialog.open(FormComponent, {
      width: "600px",
      data: {
        action,
        data,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getData();
    });
  }

  openDialogDelete(roleId: number) {
    const dialogRef = this.dialog.open(ModalConfirmationComponent, {
      width: "600px",
      data: {
        title: "¿Estas seguro de desactivar esta Medida?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  onLimitChange(event: Event): void {
    const limit = (event.target as HTMLSelectElement).value;
    this.changePageLimit(limit);
    this.table.limit = this.form.controls.rowsPerPage.value;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor(
          (this.table.rowCount - 1) / this.table.limit
        );
      }
    });
  }

  changePageLimit(limit: any): void {
    this.form.controls.rowsPerPage.setValue(parseInt(limit, 10));
  }

  onSort(event: any): void {
    const sort = event.sorts[0];
    const column = this.columnMap.get(sort.prop);

    if (column) {
      this.temp.sort((a, b) => {
        if (typeof a[column] === "number" || typeof a[column] === "boolean") {
          if (sort.dir === "asc") return a[column] - b[column];
          else return b[column] - a[column];
        } else if (typeof a[column] === "string") {
          return (
            a[column].localeCompare(b[column]) * (sort.dir === "desc" ? -1 : 1)
          );
        }
      });

      this.temp = [...this.temp];
    }
  }

  closeAlert(event: boolean) {
    this.showAlert = event;
  }

  delete(roleId: number) {
    this.openDialogDelete(roleId);
  }

  getData(): void {
    this.loading = true;
    this.inventorySvc.getPumpMeasures().subscribe({
      next: (v) => {
        this.loading = false;
        this.temp = v.data;
      },
      error: (e) => {
        this.loading = false;
      },
    });
  }
}
