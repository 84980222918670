<div class="bg-white px-4 w-full h-full py-4 overflow-y-auto">
  <div class="flex">
    <div class="w-11/12 justify-between rounded-t mb-0 border-0 bg-white">
      <div>
        <h3 class="text-lg">
          {{ dialogTitle }}
        </h3>
      </div>
    </div>

    <div class="w-1/12 justify-end mb-0">
      <button
        class="text-end text-sky-600 cursor-pointer hover:text-sky-700 text-2xl pl-12 ease-linear transition-all duration-150"
        (click)="closeModal()"
      >
        <i class="fas fa-times-circle"></i>
      </button>
    </div>
  </div>
  <div class="flex pb-8">
    <p class="font-semibold">Cliente: {{ client?.legal_name }}</p>
  </div>
  <div class="flex flex-row">
    <div class="block w-full overflow-x-auto rounded-lg w-2/4 m-4">
      <ngx-datatable
        class="material"
        [rows]="orders"
        [columnMode]="ColumnMode.force"
        [headerHeight]="50"
        rowHeight="auto"
        [limit]="5"
        [scrollbarH]="true"
      >
        <ngx-datatable-column name="Folio" prop="folio"></ngx-datatable-column>
        <ngx-datatable-column name="Producto" prop="product.name"></ngx-datatable-column>
        <ngx-datatable-column name="IEPS" prop="ieps"></ngx-datatable-column>
        <ngx-datatable-column name="IVA" prop="iva"></ngx-datatable-column>
        <ngx-datatable-column name="Total">
          <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.total | currency }}
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
    <div class="w-2/4 m-4">
      <form [formGroup]="form">
        <div class="flex flex-col">
          <div class="w-full">
            <div class="container">
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-1"
                htmlFor="subtotal"
              >
                IVA
              </label>
              <input
                type="text"
                name="iva"
                formControlName="vat"
                prefix="$ "
                mask="separator"
                thousandSeparator=","
                [ngClass]="{
                  'focus:border-red-500': form.controls.vat.invalid,
                  'focus:ring-red-500': form.controls.vat.invalid
                }"
                (change)="onLeadingZero($event)"
                class="border-gray-300 px-3 py-3 placeholder-slate-300 border-1 text-slate-600 bg-white rounded text-sm focus:ring-1-1 focus:outline-none w-full ease-linear transition-all duration-150"
              />
              <div *ngFor="let validation of validationMessages.vat">
                <span
                  *ngIf="formService.field(form, validation.type, 'vat')"
                  class="mt-2 text-sm text-red-500"
                >
                  {{ validation.message }}
                </span>
              </div>
            </div>
          </div>

          <div class="w-full">
            <div class="container">
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-1 mt-2"
                htmlFor="total"
              >
                IEPS
              </label>
              <input
                type="text"
                name="ieps"
                formControlName="ieps"
                prefix="$ "
                mask="separator"
                thousandSeparator=","
                [ngClass]="{
                  'focus:border-red-500': form.controls.ieps.invalid,
                  'focus:ring-red-500': form.controls.ieps.invalid
                }"
                (change)="onLeadingZero($event)"
                class="border-gray-300 px-3 py-3 placeholder-slate-300 border-1 text-slate-600 bg-white rounded text-sm focus:ring-1-1 focus:outline-none w-full ease-linear transition-all duration-150"
              />
              <div *ngFor="let validation of validationMessages.total">
                <span
                  *ngIf="formService.field(form, validation.type, 'ieps')"
                  class="mt-2 text-sm text-red-500"
                >
                  {{ validation.message }}
                </span>
              </div>
            </div>
          </div>

          <div class="w-full">
            <div class="container">
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-1 mt-2"
                htmlFor="subtotal"
              >
                SUBTOTAL
              </label>
              <input
                type="text"
                name="subtotal"
                formControlName="subtotal"
                prefix="$ "
                mask="separator"
                thousandSeparator=","
                [ngClass]="{
                  'focus:border-red-500': form.controls.subtotal.invalid,
                  'focus:ring-red-500': form.controls.subtotal.invalid
                }"
                (change)="onLeadingZero($event)"
                class="border-gray-300 px-3 py-3 placeholder-slate-300 border-1 text-slate-600 bg-white rounded text-sm focus:ring-1-1 focus:outline-none w-full ease-linear transition-all duration-150"
              />
              <div *ngFor="let validation of validationMessages.subtotal">
                <span
                  *ngIf="formService.field(form, validation.type, 'subtotal')"
                  class="mt-2 text-sm text-red-500"
                >
                  {{ validation.message }}
                </span>
              </div>
            </div>
          </div>
          


          <div class="w-full">
            <div class="container">
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-1 mt-2"
                htmlFor="total"
              >
                Total
              </label>
              <input
                type="text"
                name="total"
                formControlName="total"
                prefix="$ "
                mask="separator"
                thousandSeparator=","
                [ngClass]="{
                  'focus:border-red-500': form.controls.total.invalid,
                  'focus:ring-red-500': form.controls.total.invalid
                }"
                (change)="onLeadingZero($event)"
                class="border-gray-300 px-3 py-3 placeholder-slate-300 border-1 text-slate-600 bg-white rounded text-sm focus:ring-1-1 focus:outline-none w-full ease-linear transition-all duration-150"
              />
              <div *ngFor="let validation of validationMessages.total">
                <span
                  *ngIf="formService.field(form, validation.type, 'total')"
                  class="mt-2 text-sm text-red-500"
                >
                  {{ validation.message }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="flex items-center justify-end">
    <button
      class="bg-slate-500 text-white active:bg-slate-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      (click)="closeModal()"
      *ngIf="assign"
    >
      Cancelar
    </button>

    <button
      class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      (click)="createInvoiceClient()"
      *ngIf="assign"
    >
      Facturar
    </button>
  </div>
</div>
