import { Component, OnInit } from '@angular/core';
import { ReportService } from 'app/apiServices/report.service';

@Component({
  selector: 'app-units-in-plant',
  templateUrl: './units-in-plant.component.html',
  styleUrls: ['./units-in-plant.component.css']
})
export class UnitsInPlantComponent implements OnInit {
  total: number
  waiting_for_loading: number
  loading: number
  waiting_for_unloading: number
  unloading: number

  constructor(
    private reportService: ReportService
  ){}
  
  ngOnInit(): void {
    this.getData()
  }

  getData(){
    this.reportService.getUnitsInPlant().subscribe((r)=>{
      this.total = r.data.total
      this.waiting_for_loading = r.data.waiting_for_loading
      this.loading = r.data.loading
      this.waiting_for_unloading = r.data.waiting_for_unloading
      this.unloading = r.data.unloading
    }, (e) =>{

    })
  }
}
