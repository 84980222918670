import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { ReportService } from 'app/apiServices/report.service';

@Component({
  selector: 'app-units-in-trancit',
  templateUrl: './units-in-trancit.component.html',
  styleUrls: ['./units-in-trancit.component.css']
})
export class UnitsInTrancitComponent implements OnInit {
  @ViewChild(DatatableComponent) table: DatatableComponent;
  columnMode = ColumnMode;
  columnMapValues = [
    ["pedido", "pedido"],
    ["cliente", "cliente"],
    ["transportist", "transportista"],
    ["unity", "unidad"],
    ["destinyt", "destino"],
    ["eta", "eta"],
    ["last_comentary", "ultimo comentario"],
    ["status", "status"]
  ] as const

  columnMap: Map<string, string> = new Map(this.columnMapValues)
  loading: boolean = false

  dataSource: any[] = []
  form: FormGroup
  totalNumber: number

  constructor(
    private reportService: ReportService,
    private fb: FormBuilder,
    private datePipe: DatePipe
  ){
    this.form;
    this.form = this.fb.group({
      rowsPerPage: [10],
    });
  }

  ngOnInit(): void {
    this.getData()
  }

  getData() {
    this.reportService.getUnitsInTransit().subscribe((r) => {
      this.totalNumber = r.data.total;
      this.dataSource = r.data.transitUnits.map(unit => ({
        ...unit,
        eta: this.datePipe.transform(unit.eta, 'dd/MM/yyyy HH:mm')
      }));
    }, (e) => {
      console.log(e);
    });
  }

  onLimitChange(event: Event): void {
    const limit = (event.target as HTMLSelectElement).value;
    this.changePageLimit(limit);
    this.table.limit = this.form.controls.rowsPerPage.value;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor(
          (this.table.rowCount - 1) / this.table.limit
        );
      }
    });
  }

  changePageLimit(limit: any): void {
    this.form.controls.rowsPerPage.setValue(parseInt(limit, 10));
  }

  onSort(event: any): void {
    const sort = event.sorts[0];
    const column = this.columnMap.get(sort.prop);

    if (column) {
      this.dataSource.sort((a, b) => {
        if (typeof a[column] === "number") {
          if (sort.dir === "asc") return a[column] - b[column];
          else return b[column] - a[column];
        } else if (typeof a[column] === "string") {
          return (
            a[column].localeCompare(b[column]) * (sort.dir === "desc" ? -1 : 1)
          );
        } else if (typeof a[column] === "object") {
          return (
            a[column][0].name.localeCompare(b[column][0].name) *
            (sort.dir === "desc" ? -1 : 1)
          );
        }
      });

      this.dataSource = [...this.dataSource];
    }
  }
}
