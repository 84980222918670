<div class="container bg-white mx-auto px-4 h-full py-4">
  <div class="justify-between rounded-t mb-0 px-4 py-3 border-0 pt-6 bg-white">
    <div class="text-center">
      <h3 class="font-semibold text-lg">Formulario de Rutas</h3>
    </div>
  </div>
  <form class="bg-white" [formGroup]="form">
    <!-- NAME AND PRICE -->
    <div class="relative w-full mb-3 flex">
      <div class="container px-2">
        <label
          class="block uppercase text-slate-600 text-xs font-bold mb-2"
          htmlFor="name"
        >
          Nombre
        </label>
        <input
          type="text"
          name="name"
          formControlName="name"
          [ngClass]="{
            'border-red-500': form.controls.name.invalid,
            'focus:border-red-500': form.controls.name.invalid,
            'focus:ring-red-500': form.controls.name.invalid
          }"
          class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        />
        <div *ngFor="let validation of validationMessages.name">
          <span
            *ngIf="formService.field(form, validation.type, 'name')"
            class="mt-2 text-sm text-red-500"
          >
            {{ validation.message }}
          </span>
        </div>
      </div>

      <div class="container px-2">
        <label
          class="block uppercase text-slate-600 text-xs font-bold mb-2"
          htmlFor="standard_cost"
        >
          Precio Estandar
        </label>
        <input
          type="text"
          name="standard_cost"
          formControlName="standard_cost"
          prefix="$ "
          mask="separator.6"
          [ngClass]="{
            'border-red-500': form.controls.standard_cost.invalid,
            'focus:border-red-500': form.controls.standard_cost.invalid,
            'focus:ring-red-500': form.controls.standard_cost.invalid
          }"
          class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          (change)="onLeadingZero($event)"
        />
        <div *ngFor="let validation of validationMessages.standard_cost">
          <span
            *ngIf="formService.field(form, validation.type, 'standard_cost')"
            class="mt-2 text-sm text-red-500"
          >
            {{ validation.message }}
          </span>
        </div>
      </div>
    </div>

    <div class="relative w-full mb-3 flex">
      <div class="container px-2">
        <label
          class="block uppercase text-slate-600 text-xs font-bold mb-2"
          htmlFor="from_locality"
        >
          Desde
        </label>
        <input
          type="text"
          name="from_locality"
          formControlName="from_locality"
          class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          (click)="openDialog(true)"
          [readonly]="true"
        />
        <div *ngFor="let validation of validationMessages.from_locality">
          <span
            *ngIf="formService.field(form, validation.type, 'from_locality')"
            class="mt-2 text-sm text-red-500"
          >
            {{ validation.message }}
          </span>
        </div>
      </div>

      <div class="container px-2">
        <label
          class="block uppercase text-slate-600 text-xs font-bold mb-2"
          htmlFor="to_locality"
        >
          Hasta
        </label>
        <input
          type="text"
          name="to_locality"
          formControlName="to_locality"
          class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          (click)="openDialog(false)"
          [readonly]="true"
        />
        <div *ngFor="let validation of validationMessages.to_locality">
          <span
            *ngIf="formService.field(form, validation.type, 'to_locality')"
            class="mt-2 text-sm text-red-500"
          >
            {{ validation.message }}
          </span>
        </div>
      </div>
    </div>
    <!-- DATA DIALOGS ADDRESS-->
    <div *ngIf="dataFrom || dataFromTo || item">
      <div class="relative w-full mb-3 flex">
        <div class="container px-2">
          <label
            class="block uppercase text-slate-600 text-xs font-bold mb-2"
            htmlFor="municipalityFrom"
          >
            Municipio
          </label>
          <input
            type="text"
            name="municipalityFrom"
            formControlName="municipalityFrom"
            class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          />
        </div>

        <div class="container px-2">
          <label
            class="block uppercase text-slate-600 text-xs font-bold mb-2"
            htmlFor="municipalityFromTo"
          >
            Municipio
          </label>
          <input
            type="text"
            name="municipalityFromTo"
            formControlName="municipalityFromTo"
            class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          />
        </div>
      </div>
      <div class="relative w-full mb-3 flex">
        <div class="container px-2">
          <label
            class="block uppercase text-slate-600 text-xs font-bold mb-2"
            htmlFor="stateFrom"
          >
            Estado
          </label>
          <input
            type="text"
            name="stateFrom"
            formControlName="stateFrom"
            class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          />
        </div>

        <div class="container px-2">
          <label
            class="block uppercase text-slate-600 text-xs font-bold mb-2"
            htmlFor="stateFromTo"
          >
            Estado
          </label>
          <input
            type="text"
            name="stateFromTo"
            formControlName="stateFromTo"
            class="border-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          />
        </div>
      </div>
    </div>

    <!-- <div *ngIf="dataFromTo">

            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="municipalityFromTo">
                    Desde
                </label>
                <input type="text" name="municipalityFromTo" formControlName="municipalityFromTo" class="border-2 px-3 py-3
                placeholder-slate-300
                text-slate-600 bg-white rounded border-gray-300
                text-sm focus:outline-none focus:ring
                w-full ease-linear transition-all duration-150" />
            </div>

            <div class="container px-2">
                <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="stateFromTo">
                    Desde
                </label>
                <input type="text" name="stateFromTo" formControlName="stateFromTo" class="border-2 px-3 py-3
                placeholder-slate-300
                text-slate-600 bg-white rounded border-gray-300
                text-sm focus:outline-none focus:ring
                w-full ease-linear transition-all duration-150" />

            </div>

        </div> -->

    <!-- BUTTONS -->
    <div class="flex w-full justify-end">
      <button
        class="bg-slate-500 text-white active:bg-slate-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        (click)="closeModal()"
      >
        Cancelar
      </button>
      <button
        class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        [ngClass]="{ 'disabled:opacity-25': form.invalid }"
        [disabled]="form.invalid"
        (click)="save()"
      >
        {{ edit ? "Actualizar" : "Agregar" }}
      </button>
    </div>
  </form>
</div>
