import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InventoryService } from 'app/apiServices/inventory.service';
import { FormValidatorService } from 'app/core/service/form-validator.service';

@Component({
  selector: 'app-address-component',
  templateUrl: './address-component.component.html',
  styleUrls: ['./address-component.component.css']
})
export class AddressComponentComponent implements OnInit {

  form: FormGroup;
  dataLocalities: any;

  validationMessages = {
    zip_code: [{ type: "required", message: "Campo Requerido" }],
    locality: [{ type: "required", message: "Campo Requerido" }]
  };

  constructor(
    private dialogRef: MatDialogRef<AddressComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public formService: FormValidatorService,
    private inventorySvc: InventoryService
  ) {
    this.form = this.createForm();
  }

  ngOnInit(): void {

  }

  createForm(): FormGroup {
    return this.fb.group({
      zip_code: ['', [Validators.required]],
      state: [{ value: '', disabled: true }, []],
      municipality: [{ value: '', disabled: true }, []],
      locality: ['', [Validators.required]],

    });
  }

  getData(): void {
    this.inventorySvc.getLocalities(this.form.value).subscribe({
      next: (v) => {
        this.dataLocalities = v.data

        this.setData()
      },
      error: (e) => {
        //console.log(e)
      }
    })
  }

  setData(): void {
    this.form.controls['state'].setValue(this.dataLocalities.state.name)
    this.form.controls['municipality'].setValue(this.dataLocalities.municipality.name)
  }

  sendData(): void {
    this.form.enable()

    const data = {
      data: {
        ...this.form.value,
        state: this.dataLocalities.state,
        municipality: this.dataLocalities.municipality
      }
    };

    this.form.controls['state'].disable()
    this.form.controls['municipality'].disable()

    this.dialogRef.close(data);
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
