<div class="">
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-200 border-0">
        <div class="justify-center flex w-auto">
            <div class="flex justify-center ml-20 mr-4">
                <h1 class="text-4xl font-bold text-green-500">MX ${{total | number }}</h1>
            </div>
            <div class="flex items-center justify-center">
                <p class="text-center font-bold text-l">Total de ventas por el día de hoy</p>
            </div>
        </div>
        <div class="mt-5 ">
            <canvas id="chart" width="1100" height="500">{{chart}}</canvas>
        </div>
    </div>
</div>
