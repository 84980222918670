import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InventoryService } from 'app/apiServices/inventory.service';
import { NotificationsComponent } from 'app/components/alerts/notifications/notifications.component';
import { FormValidatorService } from 'app/core/service/form-validator.service';
import { Existence } from 'app/models/existence';
import { ExistenceChangeTypes } from 'app/models/existence_types_change';
import { Product } from 'app/models/product';
import { Tank } from 'app/models/tank';
import { User } from 'app/models/user';

@Component({
  selector: 'app-formexistence',
  templateUrl: './formexistence.component.html',
  styleUrls: ['./formexistence.component.css']
})
export class FormexistenceComponent {
  form: FormGroup;
  dataSource: Existence;
  edit = false;
  dialogTitle: string;
  existence_change_type: ExistenceChangeTypes[] = []
  products: Product[] = []
  users: User[] = []
  tanks: Tank[] = []

  validationMessages = {
    name: [{ type: "required", message: "Campo Requerido" }],
    existence_change_type_id: [{ type: "required", message: "Campo Requerido" }],
    product_id: [{ type: "required", message: "Campo Requerido" }],
    // user_id: [{ type: "required", message: "Campo Requerido" }],
    quantity: [{ type: "required", message: "Campo Requerido" }],
    description: [{ type: "required", message: "Campo Requerido" }]
  };

  constructor(
    private dialogRef: MatDialogRef<FormexistenceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public formService: FormValidatorService,
    private inventorySvc: InventoryService,
    public _snack: MatSnackBar
  ) {
    this.data = data;

    if (data.action === "edit") {
      this.dialogTitle = "Editar Existencia";
      this.dataSource = {
        id: data.data.id,
        existence_change_type_id: data.data.existence_change_type.id,
        product_id: data.data.product.id,
        // user_id: data.data.user.id,
        quantity: data.data.quantity,
        description: data.data.description,
        tank_id: data.data.tank.id
      };
      this.edit = true;
    } else {
      this.dialogTitle = "Agregar Existencia";
      this.dataSource = new Existence({});
    }

    this.form = this.createForm();
    this.getData()
  }

  createForm(): FormGroup {
    return this.fb.group({
      id: [this.dataSource.id],
      existence_change_type_id: [this.dataSource.existence_change_type_id, [Validators.required]],
      product_id: [this.dataSource.product_id, [Validators.required]],
      // user_id: [this.dataSource.user_id, [Validators.required]],
      quantity: [this.dataSource.quantity, [Validators.required]],
      description: [this.dataSource.description, []],
      tank_id: [this.dataSource.tank_id, []]
    });
  }

  save(): void {
    if (this.form.valid) {
      this.dataSource = this.form.value;
      this.storeUpdate(this.edit ? "update" : "store");
    } else {
      this.formService.allFields(this.form);
      alert("Datos incorrectos");
    }
  }

  storeUpdate(method: string): void {
    const data = {
      method,
      data: this.dataSource,
      status: 200,
    };

    if (method === "store") {
      this.inventorySvc.sendExistence(this.dataSource).subscribe({
        next: (v) => {
          // console.log(v)
          this.openSnack('snack-success', v.message)
          this.dialogRef.close(data);
        },
        error: (e) => {
          // console.log(e)
          this.openSnack('snack-error', e.error.message)
        }
      })
    } else {
      this.inventorySvc.updateExistence(this.dataSource).subscribe({
        next: (v) => {
          // console.log(v)
          this.openSnack('snack-success', v.message)
          this.dialogRef.close(data);
        },
        error: (e) => {
          // console.log(e)
          this.openSnack('snack-error', e.error.message)
        }
      })
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  getData(): void {

    this.inventorySvc.getTanks().subscribe(r => {
      this.tanks = r.data
    })

    this.inventorySvc.getProducts().subscribe({
      next: (v) => {
        this.products = v.data
      },
      error: (e) => {
        //console.log(e)
      }
    })

    this.inventorySvc.getExistenceChangeTypes().subscribe({
      next: (v) => {
        this.existence_change_type = v.data
      },
      error: (e) => {
        //console.log(e)
      }
    })

    this.inventorySvc.getUsers().subscribe({
      next: (v) => {
        this.users = v.data
      },
      error: (e) => {
        //console.log(e)
      }
    })
  }

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000
    })
  }
}
