import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { ColumnMode, SelectionType } from "@swimlane/ngx-datatable";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CreateSupplierCreditNoteComponent } from "../create-supplier-credit-note/create-supplier-credit-note.component";
import { ClientsService } from "app/apiServices/clients.service";
import { Company } from "app/models/company";
import { Select, Collapse, Dropdown, initTE } from "tw-elements";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { PaymentSuppliersComponent } from "../payment-suppliers/payment-suppliers.component";
import { SalesService } from "app/apiServices/sales.service";
import { DateTime } from "luxon";
import { DateBody } from "app/interfaces/date.interface";
import { InCompaniesRes } from "app/interfaces/company.interface";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DataService } from "app/services/data.service";
import { InvoiceService } from "app/apiServices/invoice.service";
import { Invoice } from "app/models/invoice";
import { NotificationsComponent } from "app/components/alerts/notifications/notifications.component";
import { CostInvoices } from "app/models/cost_invoices";
import { AssignSupplierCreditNoteComponent } from "../assign-supplier-credit-note/assign-supplier-credit-note.component";
import { SupplierInvoicePayments } from "app/models/supplier_invoice_payment";
import { InvoiceDialogComponent } from "../../components/invoice-dialog/invoice-dialog.component";
import { PaymentSupplierAttachmentsComponent } from "../../components/payment-supplier-attachments/payment-supplier-attachments.component";
declare let DateRangePicker: any;
@Component({
  selector: "app-payment-suppliers-list",
  templateUrl: "./payment-suppliers-list.component.html",
  styleUrls: ["./payment-suppliers-list.component.css"],
})
export class PaymentSuppliersListComponent {
  rows = [];
  selected: CostInvoices[] = [];
  objects: Company[];
  allCompanies: Company[];
  objectsSuppliers: Company[];
  selectedCompany: Company;
  ColumnMode = ColumnMode;
  form: FormGroup;
  SelectionType = SelectionType;
  @ViewChild("company_id", { static: true })
  companySelect: ElementRef;
  @ViewChild("supplier_id", { static: true })
  supplierSelect: ElementRef;
  @ViewChild("container", { static: true })
  container: ElementRef;
  suppliers: any[] = [];

  selectOptions = {
    selectFilter: true,
    selectNoResultText: "No se encontraron resultados",
    selectSearchPlaceholder: "Buscar...",
  };
  dateRangePicker: any;
  currentCompany: Company;
  currentSupplier: Company;
  currentCostInvoices: CostInvoices[] = [];
  loading: boolean = false;
  costInvoices: CostInvoices[] = [];

  constructor(
    public apiService: ClientsService,
    public dialog: MatDialog,
    private _snack: MatSnackBar,
    private dataService: DataService,
    public invoiceService: InvoiceService
  ) {}

  ngOnInit(): void {
    this.getData();
    this.getCostInvoices();
  }

  sender() {
    this.loading = true;
    this.dataService.setLoadingValue(this.loading);

    if (!this.currentCompany) {
      this.openSnack("snack-error", "Selecciona una compañia");
      this.loading = false;
      this.dataService.setLoadingValue(this.loading);
      return;
    }

    if (!this.currentSupplier) {
      this.openSnack("snack-error", "Selecciona un proveedor");
      this.loading = false;
      this.dataService.setLoadingValue(this.loading);
      return;
    }

    const costInvoices = this.costInvoices.filter((invoice) => {
      return (
        invoice.company_supplier?.id === this.currentSupplier.id
      );
    });
    
    this.currentCostInvoices = costInvoices;
    this.rows = this.currentCostInvoices;
    this.loading = false;

    setTimeout(() => {
      this.dataService.setLoadingValue(this.loading);
    }, 400);
  }

  getCostInvoices() {
    this.invoiceService.getCostInvoices().subscribe(
      (r) => {
        this.loading = false;
        if (r.status === "Éxito") {
          this.costInvoices = r.data;
        }
      },
      (e) => {
        this.loading = false;
        console.log(e);
      }
    );
  }

  changeDate(event: any) {
    const [startDate, endDate] = this.dateRangePicker.getDates();

    if (startDate && endDate) {
      this.dateRangePicker.datepickers[0].element?.click();
      this.container?.nativeElement?.click();
      this.dateRangePicker.datepickers[1].element?.click();
      this.container?.nativeElement?.click();
      const formattedStartDate =
        DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd");
      const formattedEndDate =
        DateTime.fromJSDate(endDate).toFormat("yyyy-MM-dd");
    }
  }

  setInitialDate() {
    this.dateRangePicker.setDates(
      DateTime.local().minus({ week: 1 }).toISODate(),
      DateTime.local().toISODate()
    );
  }
  ngOnDestroy(): void {}

  ngAfterViewInit() {
    new Select(this.companySelect.nativeElement, this.selectOptions);
    new Select(this.supplierSelect.nativeElement, this.selectOptions);
  }

  updateFilter(event: Event) {
    /*const val: string = (event.target as HTMLInputElement).value.toLowerCase();
    const temp: Tank[] = this.tanks.filter((d: Tank) => {
      return d.id.toString().indexOf(val) !== -1 ||
            d.tank.toLowerCase().indexOf(val) !== -1 ||
            d.products[0]?.name.toLowerCase().indexOf(val) !== -1 ||
            !val;
    });
    this.temp = temp;
    this.table.offset = 0;*/
  }

  onActivate(event) {}

  onSelect({ selected }) {}
  getData(): void {
    this.apiService.readCompanies().subscribe(
      (r: InCompaniesRes) => {
        this.objects = r.data.filter((company) => {
          return company.active && company.main;
        });

        this.allCompanies = r.data.filter((company) => {
          return company.active;
        });
      },
      (error) => {}
    );
  }

  getPayment(costInvoice: CostInvoices): number {
    const paymentsInvoice = costInvoice.supplier_invoice_payments.filter(
      (payment: any) => payment.payment && payment.supplier_payment.type != "NC" && payment.supplier_payment.type != "NCE"
    );
      
    return paymentsInvoice.reduce((total, payment) => total + (payment.due_before - payment.due_total), 0);
  }

  getNoteCredit(costInvoice: CostInvoices): number {
   const paymentsInvoice = costInvoice.supplier_invoice_payments.filter(
  (payment: any) => payment.payment && payment.supplier_payment.type != "P"
    );
    
    return paymentsInvoice.reduce((total, payment) => total + (payment.due_before - payment.due_total), 0);
  }

  getTotal(costInvoice: CostInvoices): number {

    return this.getPayment(costInvoice) + this.getNoteCredit(costInvoice);
  }

  getDueTotal(costInvoice: CostInvoices): number {
    const paymentsInvoice =
      costInvoice.supplier_invoice_payments.length > 0 ? costInvoice.supplier_invoice_payments[costInvoice.supplier_invoice_payments.length - 1] : null;
    return paymentsInvoice?.due_total != null ? paymentsInvoice.due_total : costInvoice.amount;
  }

  getSelectedCompany(id: number): void {
    const idNumber = Number(id);
    const company = this.objects.find((company) => company.id === idNumber);
    this.selectedCompany = company;
    this.currentCompany = company;

    if (company && company.main) this.getProvidersbyCompany(company);
  }

  getProvidersbyCompany(companyMN: any) {
    const data = this.allCompanies.filter((company) => {
      return (
        (company.active &&
          company.main !== true &&
          company.suppliers.length !== 0 &&
          companyMN.company_suppliers.some((element) => {
            return element.id === company.suppliers[0]?.id;
          })) ||
        company.transportists.length !== 0
      );
    });

    this.suppliers = data;
  }

  getSelectedSupplier(supplierId: number) {
    this.currentSupplier = this.allCompanies.find(
      (company) => company.id == supplierId
    );
  }

  openCreateCreditNoteDialog() {
    const dialogRef = this.dialog.open(CreateSupplierCreditNoteComponent, {
      width: "84vw",
      maxWidth: "84vw",
      height: "80vh",
      data: {
        costInvoices: this.selected,
        supplierId:
          this.currentSupplier.suppliers[0]?.id ??
          this.currentSupplier.transportists[0]?.id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.invoiceService.getCostInvoices().subscribe({
          next: (res) => {
            this.selected = [];
            this.costInvoices = []
            this.costInvoices = res.data;
            this.sender();
          },
          error: (err) => {
            console.log(err);
          },
        });
      }
    });
  }
  openCreatePaymentDialog() {
    const dialogRef = this.dialog.open(PaymentSuppliersComponent, {
      width: "84vw",
      maxWidth: "84vw",
      height: "80vh",
      data: {
        costInvoices: this.selected,
        supplierId:
          this.currentSupplier.suppliers[0]?.id ??
          this.currentSupplier.transportists[0]?.id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.invoiceService.getCostInvoices().subscribe({
          next: (res) => {
            this.selected = [];
            this.costInvoices = []
            this.costInvoices = res.data;
            this.sender();
          },
          error: (err) => {
            console.log(err);
          },
        });
      }
      })
  }

  openFileDialog(invoice: CostInvoices) {
    if (invoice?.cost_attachments.length == 0) {
      this.openSnack("warn", "No hay archivos adjuntos");
      return;
    }

    const dialogRef = this.dialog.open(InvoiceDialogComponent, {
      width: "84vw",
      maxWidth: "84vw",
      height: "80vh",
      data: {
        attachments: invoice?.cost_attachments,
      },
    });
  }

  openPaymentReceiptDialog(invoice: CostInvoices) {
    if (invoice?.supplier_payment_receipt.length == 0) {
      this.openSnack("warn", "No hay recibos");
      return;
    }

    const dialogRef = this.dialog.open(PaymentSupplierAttachmentsComponent, {
      width: "84vw",
      maxWidth: "84vw",
      height: "80vh",
      data: {
        attachments: invoice?.supplier_payment_receipt,
      },
    });
  }

  openAssignCreditNoteDialog() {
    const dialogRef = this.dialog.open(AssignSupplierCreditNoteComponent, {
      width: "84vw",
      maxWidth: "84vw",
      height: "80vh",
      data: {
        invoices: this.selected,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
      verticalPosition: "top",
    });
  }
}
