import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthorizedPriceRes } from "app/interfaces/authorized.price.interface";
import { InPumpMeasureRes } from "app/interfaces/pump.measure.interface";
import { environment } from "environments/environment";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class InventoryService {
  public emitSubjet = new Subject<any>();
  dataEmitted = this.emitSubjet.asObservable();

  constructor(public http: HttpClient) {}

  /* ExistenceChangeTypes */
  getExistenceChangeTypes(): Observable<any> {
    return this.http.get<any>(
      `${environment.url}inventory/existence-change-types/get`
    );
  }
  sendExistenceChangeTypes(data: any): Observable<any> {
    return this.http.post<any>(
      `${environment.url}inventory/existence-change-types/create`,
      data,
      {}
    );
  }
  updatetExistenceChangeTypes(data: any): Observable<any> {
    return this.http.patch<any>(
      `${environment.url}inventory/existence-change-types/update/${data.id}`,
      data,
      {}
    );
  }
  deleteExistenceChangeTypes(data: any): Observable<any> {
    return this.http.delete<any>(
      `${environment.url}inventory/existence-change-types/delete/${data.id}`
    );
  }

  /* Tanks */
  getTanks(): Observable<any> {
    return this.http.get<any>(`${environment.url}inventory/tanks/get`);
  }
  sendTanks(data: any): Observable<any> {
    return this.http.post<any>(
      `${environment.url}inventory/tanks/create`,
      data,
      {}
    );
  }
  updateTanks(data: any): Observable<any> {
    return this.http.patch<any>(
      `${environment.url}inventory/tanks/update/${data.id}`,
      data,
      {}
    );
  }
  deleteTanks(data: any): Observable<any> {
    return this.http.delete<any>(
      `${environment.url}inventory/tanks/delete/${data.id}`
    );
  }

  /* Products */
  getProducts(): Observable<any> {
    return this.http.get<any>(`${environment.url}inventory/products/get`);
  }
  sendProducts(data: any): Observable<any> {
    return this.http.post<any>(
      `${environment.url}inventory/products/create`,
      data,
      {}
    );
  }
  updateProducts(data: any): Observable<any> {
    return this.http.patch<any>(
      `${environment.url}inventory/products/update/${data.id}`,
      data,
      {}
    );
  }
  deleteProducts(data: any): Observable<any> {
    return this.http.delete<any>(
      `${environment.url}inventory/products/delete/${data.id}`
    );
  }

  /* Existence */
  getExistence(): Observable<any> {
    return this.http.get<any>(
      `${environment.url}inventory/existence-changes/get`
    );
  }
  sendExistence(data: any): Observable<any> {
    return this.http.post<any>(
      `${environment.url}inventory/existence-changes/create`,
      data,
      {}
    );
  }
  updateExistence(data: any): Observable<any> {
    return this.http.patch<any>(
      `${environment.url}inventory/existence-changes/update/${data.id}`,
      data,
      {}
    );
  }
  deleteExistence(data: any): Observable<any> {
    return this.http.delete<any>(
      `${environment.url}inventory/existence-changes/delete/${data.id}`
    );
  }

  /* Pump Measures */
  getPumpMeasures(): Observable<InPumpMeasureRes> {
    return this.http.get<any>(`${environment.url}inventory/pump-measures/get`);
  }
  sendPumpMeasures(data: any): Observable<any> {
    return this.http.post<any>(
      `${environment.url}inventory/pump-measures/create`,
      data,
      {}
    );
  }
  updatePumpMeasures(data: any): Observable<any> {
    return this.http.patch<any>(
      `${environment.url}inventory/pump-measures/update/${data.id}`,
      data,
      {}
    );
  }
  deletePumpMeasures(data: any): Observable<any> {
    return this.http.delete<any>(
      `${environment.url}inventory/pump-measures/delete/${data.id}`
    );
  }

  /* Pump */
  getPump(): Observable<any> {
    return this.http.get<any>(`${environment.url}inventory/pumps/get`);
  }
  sendPump(data: any): Observable<any> {
    return this.http.post<any>(
      `${environment.url}inventory/pumps/create`,
      data,
      {}
    );
  }
  updatePump(data: any): Observable<any> {
    return this.http.patch<any>(
      `${environment.url}inventory/pumps/update/${data.id}`,
      data,
      {}
    );
  }
  deletePump(data: any): Observable<any> {
    return this.http.delete<any>(
      `${environment.url}inventory/pumps/delete/${data.id}`
    );
  }

  /* Authorized Price */
  getAuthorizedPrice(): Observable<any> {
    return this.http.get<any>(
      `${environment.url}inventory/authorized-prices/get`
    );
  }

  getAuthorizedPrices(): Observable<AuthorizedPriceRes> {
    return this.http.get<AuthorizedPriceRes>(
      `${environment.url}inventory/authorized-prices/get`
    );
  }
  getAuthorizedPriceId(data: any): Observable<any> {
    return this.http.get<any>(
      `${environment.url}inventory/authorized-prices/get/${data.id}`
    );
  }
  sendAuthorizedPrice(data: any): Observable<any> {
    return this.http.post<any>(
      `${environment.url}inventory/authorized-prices/create`,
      data,
      {}
    );
  }

  updateAuthorizedPrice(data: any): Observable<any> {
    return this.http.patch<any>(
      `${environment.url}inventory/authorized-prices/update`,
      data,
      {}
    );
  }
  deleteAuthorizedPrice(data: any): Observable<any> {
    return this.http.delete<any>(
      `${environment.url}inventory/authorized-prices/delete/${data.id}`
    );
  }

  /* Users */
  getUsers(): Observable<any> {
    return this.http.get<any>(`${environment.url}admin/users/get`);
  }

  /* Address */
  getLocalities(data: any): Observable<any> {
    return this.http.get<any>(
      `${environment.url}clients-addresses/localities/get/${data.zip_code}`,
      {}
    );
  }
}
