import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { ClientsService } from 'app/apiServices/clients.service';
import { NotificationsComponent } from 'app/components/alerts/notifications/notifications.component';
import { FormValidatorService } from 'app/core/service/form-validator.service';

@Component({
  selector: 'app-change-cfid',
  templateUrl: './change-cfid.component.html',
  styleUrls: ['./change-cfid.component.css']
})
export class ChangeCFIDComponent implements OnInit{
  cfid:any [] = []
  dataSource: any
  form: FormGroup

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  validationMessages = {
    uso_cfdi_id: [{type:"required", message: "Seleccione el CFID"}]
  }

  constructor (
    private dialogRef: MatDialogRef<ChangeCFIDComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public formService: FormValidatorService,
    public apiService: ClientsService,
    private _snack: MatSnackBar

  ) { 
    this.dataSource = this.data.data
  }

  ngOnInit(): void {
    this.getData()
    this.form = this.createForm()
  }

  getData(){
    this.apiService.getCFDI().subscribe((r) => {
      this.cfid = r.data
    })
  }

  createForm(){
    //console.log(this.dataSource)
    return this.fb.group({
      id: [this.dataSource.id],
      regimen_fiscal_id: [this.dataSource.regimen_fiscal.id],
      legal_id: [this.dataSource.legal_id],
      contact_name: [this.dataSource.contact_name],
      contact_phone: [this.dataSource.contact_phone],
      legal_name: [this.dataSource.legal_name],
      main: [this.dataSource.main],
      folio: [this.dataSource.folio],
      is_supplier: [null, []],
      is_client: [null, []],
      is_transportist: [null, []],
      seller: [false, []],
      uso_cfdi_id: [this.dataSource.uso_cfdi_id, [Validators.required]]
    })
  }

  closeModal(){
    this.dialogRef.close()
  }

  save(){
    if(this.form.valid){
      const obj = this.form.value
      this.apiService.updateCompany(obj).subscribe((r) =>{
        this.closeModal()
      }, (error) => {
        this.showAlert(error.error.message)
      })
    }
  }

  showAlert(message: string){
    const config = {
      duration: 5000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    }

    this.openSnack('snack-error',message)
  }

  openSnack(color: string, message: string): void {
    //console.log(message)
    this._snack.openFromComponent(NotificationsComponent ,{
      panelClass: [color],
      data: { message },
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    })
  }
}
