import { Component, ElementRef, Inject, ViewChild, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { FormValidatorService } from "app/core/service/form-validator.service";
import { Company } from "app/models/company";
import { Sale } from "app/models/sale";
import { TabsOptions, TabsInterface, Tabs } from "flowbite";
import { Select } from "tw-elements";
import { SalesService } from 'app/apiServices/sales.service';
import { Invoice } from 'app/models/invoice';
import { ClientsService } from 'app/apiServices/clients.service';
import { CFID } from 'app/models/cfid';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationsComponent } from 'app/components/alerts/notifications/notifications.component';
import { InvoiceService } from 'app/apiServices/invoice.service';
import { FormArray } from "@angular/forms";


@Component({
  selector: "app-create-client-credit-note",
  templateUrl: "./create-client-credit-note.component.html",
  styleUrls: ["./create-client-credit-note.component.css"],
})
export class CreateClientCreditNoteComponent implements OnInit {
  @ViewChild("paymentMethodId", { static: true })
  paymentMethodSelect: ElementRef;
  @ViewChild("CFDI_Id", { static: true })
  cfdiselect: ElementRef;
  @ViewChild("type", { static: true })
  typeSelect: ElementRef;
  @ViewChild("paymentMethodId2", { static: true })
  paymentMethodSelect2: ElementRef;


  options: TabsOptions = {
    defaultTabId: "client-data",
    activeClasses: "text-blue-600 hover:text-blue-600 border-blue-600",
    inactiveClasses:
      "text-gray-500 hover:text-gray-600 border-gray-100 hover:border-gray-300",
    onShow: () => {},
  };
  selectOptions = {
    selectFilter: true,
    selectNoResultText: "No se encontraron resultados",
    selectSearchPlaceholder: "Buscar...",
  };
  tabs: TabsInterface;
  date;

  paymentMethods = [];
  formPaymentMethods = [];
  rows = [];
  ColumnMode = ColumnMode;
  form: FormGroup;
  formPaymentInvoice : FormGroup;
  cfdi : CFID[] = [];

  totalInvoices: number = 0;
  totalImputed: number = 0;
  type;
  message: string;
  client_id: number;
  user: any;
  user_id: number;
  invoices : Invoice[] = [];
  client : Company;
  validationMessages = {
    user_id: [{ type: "required", message: "Ingresa el cliente" }],
    client_id: [{ type: "required", message: "Ingresa el cliente" }],
    payment_methods_id: [{ type: "required", message: "Ingresa la forma de pago" }],
    uso_cfdi_id: [{ type: "required", message: "Ingresa el uso de CFDI" }],
    payment: [{ type: "required", message: "Ingresa el monto abonado" }],
    comments: [{ type: "required", message: "Ingresa los comentarios" }],
    type : [{ type: "required", message: "Ingresa el tipo" }],
    date: [{ type: "required", message: "Ingresa la fecha" }],
  };


  constructor(
    private fb: FormBuilder,
    public formService: FormValidatorService,
    private dialogRef: MatDialogRef<CreateClientCreditNoteComponent>,
    private salesService: SalesService,
    private invoiceService: InvoiceService,
    private _snack: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private clientsService: ClientsService
  ) {
    this.client_id = this.data.client_id;
    this.user = localStorage.getItem('user');
    this.user_id = JSON.parse(this.user).id;
    this.form = this.createForm();
    this.invoices = data.invoices;
    this.invoices = this.invoices.map(invoice => {
      let invoicePayments = invoice.invoice_payments;
      let dueTotal = invoice.total;
      if (invoicePayments.length) {
        let lastPayment = invoicePayments[invoicePayments.length - 1];
        if (lastPayment.due_total) {
          dueTotal = lastPayment.due_total;
        }
      }
      this.totalInvoices += dueTotal;
      return {dueTotal: dueTotal, ...invoice};
    });
  }

  ngAfterViewInit() {
    this.tabs = new Tabs(
      [
        {
          id: "data-fee",
          triggerEl: document.querySelector("#data-fee-tab"),
          targetEl: document.querySelector("#data-fee"),
        },
        {
          id: "data-transaction",
          triggerEl: document.querySelector("#data-transaction-tab"),
          targetEl: document.querySelector("#data-transaction"),
        },
      ],
      this.options
    );
    new Select(this.paymentMethodSelect.nativeElement, this.selectOptions);
    new Select(this.cfdiselect.nativeElement, this.selectOptions);
    new Select(this.typeSelect.nativeElement, this.selectOptions);
    new Select(this.paymentMethodSelect2.nativeElement, this.selectOptions);
  }

  ngOnInit(): void {
    this.getPaymentMethodsandCFDI();
    this.form.get('payment').disable();
    const folios = this.invoices.map(invoice => invoice.id).join(', ');
    this.form.get('comments').setValue(folios);
    const invoicePayments = this.form.get('invoice_payments') as FormArray;
    for (const invoice of this.invoices) {
      const invoiceFormGroup = this.createInvoice(invoice);
      invoicePayments.push(invoiceFormGroup);
    }
  }

  createForm(): FormGroup {
    return this.fb.group({
      user_id: [this.user_id, [Validators.required]],
      client_id: [this.client_id, [Validators.required]],
      type: [, [Validators.required]],
      payment_method_id: [, [Validators.required]],
      forma_pago_id: [, [Validators.required]], 
      payment: [, [Validators.required]],
      date: [, [Validators.required]],
      uso_cfdi_id: [, [Validators.required]],
      comments: [, [Validators.required]],
      invoice_payments: this.fb.array([]),
    });
  }
  
  createInvoice(invoice : Invoice): FormGroup {
    return this.fb.group({
      invoice_payment_id: [null],
      invoice_id: [invoice.id, [Validators.required]],
      number: [2, [Validators.required]],
      payment_price: [invoice.total, [Validators.required]],
      due_before: [invoice.dueTotal, [Validators.required]],
      due_total: [invoice.total, [Validators.required]],
      date: [, [Validators.required]],
    });
  }

  addInvoicesToForm(): void {
    const invoicePayments = this.form.get('invoice_payments') as FormArray;
    for (let invoice of this.invoices) {
      const invoiceFormGroup = this.createInvoice(invoice);
      invoicePayments.push(invoiceFormGroup);
    }
  }

  onPaymentMethodType(paymentMethodId: number) {

  }

  getPaymentMethodsandCFDI() {
    this.salesService.getPaymentMethods().subscribe((res) => {
      this.paymentMethods = res.data.filter((method) => method.type_invoice === true);
    });
    this.clientsService.getCFDI().subscribe((res) => {
      this.cfdi = res.data;
      this.cfdi.sort((a, b) => a.id - b.id);
    });
    this.clientsService.getFormPaymentMethods().subscribe((res) => {
      this.formPaymentMethods = res.data;
    });
  }

  changeDate(date: string): void {
    this.form.controls.date.patchValue(date);
    this.date = date;
    this.form.updateValueAndValidity();
    const invoicePayments = this.form.get('invoice_payments') as FormArray;
    for (let i = 0; i < invoicePayments.length; i++) {
      const invoiceFormGroup = invoicePayments.at(i) as FormGroup;
      invoiceFormGroup.get('date').patchValue(date);
    }
  }

  onLeadingZero(event: Event): void {
    const num = (event.target as HTMLInputElement).value
      .replace("$", "")
      .replace(",", "")
      .replace(" ", "");
    const dec = num.split(".")[1];
    const len = dec && dec.length > 2 ? dec.length : 2;
    (event.target as HTMLInputElement).value = `$ ${Number(num).toFixed(len)}`;
  }

  onImputedChange(event: Event, row: any): void {
    let value = (event.target as HTMLInputElement).value;
    value = value.replace("$", "").replace(",", "").replace(/\s/g, "");
    const numValue = Number(value);
    if (!isNaN(numValue) && numValue > 0) {
      if (row.previousImputed) {
        this.totalImputed -= row.previousImputed;
      }
      this.totalImputed += numValue;
      row.previousImputed = numValue;

      const invoicePayments = this.form.get('invoice_payments') as FormArray;
      const invoiceFormGroup = invoicePayments.at(this.invoices.indexOf(row)) as FormGroup;
      const due_before = invoiceFormGroup.get('due_before').value;
      invoiceFormGroup.get('due_total').setValue(due_before - numValue);

      this.form.patchValue({
        payment: this.totalImputed
      });

    } else {
      if (row.previousImputed) {
        this.totalImputed -= row.previousImputed;
        row.previousImputed = 0;
      }
      row.imputar = 0;
    }
  }

  createPaymentInvoice(): void {

    this.form.get('payment').enable();  
    console.log(this.form.value);
    if (this.form.valid) {
      this.invoiceService.createPaymentInvoice(this.form.value).subscribe({
        next: (res) => {
          this.openSnack("snack-success", 'Nota de credito con relacion creada con exito');
          this.dialogRef.close(true);
        },
        error: (err) => {
          this.openSnack("snack-error", err.error.message);
        },
      });  
    }else{
      this.openSnack("snack-error", "Revise bien los formularios");
      this.form.get('payment').disable();
    }
  }
  
  deleteInvoice(row: any): void {
    const index = this.invoices.findIndex(invoice => invoice.id === row.id);
    if (index !== -1) {
      this.totalInvoices -= this.invoices[index].total;
      this.totalImputed -= row.imputar;
      this.invoices.splice(index, 1);
    }
  }

  goToDataFee(): void {
    this.tabs.show("data-fee");
  }

  goToDataTransaction(): void {
    this.tabs.show("data-transaction");
  }

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
      verticalPosition: "top",
    });
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
