import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule, DatePipe } from "@angular/common";
import { MonacoEditorModule } from "ngx-monaco-editor-v2";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

// layouts
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";

// admin views
import { DashboardComponent } from "./views/admin/dashboard/dashboard.component";
import { MapsComponent } from "./views/admin/maps/maps.component";
import { SettingsComponent } from "./views/admin/settings/settings.component";
import { TablesComponent } from "./views/admin/tables/tables.component";

// auth views
import { LoginComponent } from "./views/auth/login/login.component";

// no layouts views
import { IndexComponent } from "./views/index/index.component";
import { ProfileComponent } from "./views/profile/profile.component";

// extras
import { MatDialogModule } from "@angular/material/dialog";
import { MatTableModule } from "@angular/material/table";
import { MatMenuModule } from "@angular/material/menu";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { DataManager, NameTableService } from "./services/nameTable.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { AuthService } from "./core/auth.service";
import { PluckPipe } from "./core/pipes/map.pipe";

// components for views and layouts

import { AdminNavbarComponent } from "./components/navbars/admin-navbar/admin-navbar.component";
import { AuthNavbarComponent } from "./components/navbars/auth-navbar/auth-navbar.component";
import { CardBarChartComponent } from "./components/cards/card-bar-chart/card-bar-chart.component";
import { CardLineChartComponent } from "./components/cards/card-line-chart/card-line-chart.component";
import { CardPageVisitsComponent } from "./components/cards/card-page-visits/card-page-visits.component";
import { CardProfileComponent } from "./components/cards/card-profile/card-profile.component";
import { CardSettingsComponent } from "./components/cards/card-settings/card-settings.component";
import { CardSocialTrafficComponent } from "./components/cards/card-social-traffic/card-social-traffic.component";
import { CardTableComponent } from "./components/cards/card-table/card-table.component";
import { FooterComponent } from "./components/footers/footer/footer.component";
import { FooterSmallComponent } from "./components/footers/footer-small/footer-small.component";
import { HeaderStatsComponent } from "./components/headers/header-stats/header-stats.component";
import { IndexNavbarComponent } from "./components/navbars/index-navbar/index-navbar.component";
import { IndexDropdownComponent } from "./components/dropdowns/index-dropdown/index-dropdown.component";
import { TableDropdownComponent } from "./components/dropdowns/table-dropdown/table-dropdown.component";
import { PagesDropdownComponent } from "./components/dropdowns/pages-dropdown/pages-dropdown.component";
import { MapExampleComponent } from "./components/maps/map-example/map-example.component";
import { NotificationDropdownComponent } from "./components/dropdowns/notification-dropdown/notification-dropdown.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { UserDropdownComponent } from "./components/dropdowns/user-dropdown/user-dropdown.component";
import {
  CategoriesComponent,
  CategoriesForm,
} from "./components/categories/categories.component";
import { BodyComponent } from "./components/body/body.component";
import { UserComponent } from "./components/user/user.component";
import {
  VistasComponent,
  VistasForm,
} from "./components/vistas/vistas.component";
import { UsersComponent, usersForm } from "./components/users/users.component";
import { RolesComponent } from "./components/sections/admin/roles/roles.component";
import { RolesViewsComponent } from "./components/sections/admin/roles-views/roles-views.component";
import { FormComponent } from "./components/sections/admin/roles/dialogs/form/form.component";
import { FormComponent as RoleViewFormComponent } from "./components/sections/admin/roles-views/dialogs/form/form.component";
import { FormComponent as ProductFormComponent } from "./components/sections/admin/products/dialogs/form/form.component";
import { AlertComponent } from "./components/alerts/alert/alert.component";
import { ModalConfirmationComponent } from "./components/modals/modal-confirmation/modal-confirmation.component";
import { MoreComponent } from "./components/alerts/more/more.component";
import { DatetimeComponent } from "./components/inputs/datetime/datetime.component";
import { TanksComponent } from "./components/tanks/tanks.component";
import { FormTankComponent } from "./components/tanks/form-tank/form-tank.component";
import { PumpsComponent } from "./components/pumps/pumps.component";
import { FormPumpComponent } from "./components/pumps/form-pump/form-pump.component";
import { DateComponent } from "./components/inputs/date/date.component";
import { TimeComponent } from "./components/inputs/time/time.component";
import { ExistenceComponent } from "./components/existence/existence.component";
import { FormexistenceComponent } from "./components/existence/formexistence/formexistence.component";
import { AuthPricesComponent } from "./components/auth-prices/auth-prices.component";
import { PumpMeasureComponent } from "./components/pump-measure/pump-measure.component";
import { FormComponent as PumpMeasureFormComponent } from "./components/pump-measure/form/form.component";
import { ExistenceChangeTypesComponent } from "./components/existence-change-types/existence-change-types.component";
import { FormComponent as FormExistenceChangeTypes } from "./components/existence-change-types/form/form.component";
import { ProductsComponent } from "./components/sections/admin/products/products.component";
import { ComposeProductComponent } from "./components/inputs/compose-product/compose-product.component";
import { RoutesComponent } from "./components/routes/routes.component";
import { FormComponent as FormRoute } from "./components/routes/form/form.component";
import { TransportistRoutePriceComponent } from "./components/transportist-route-price/transportist-route-price.component";
import { FormComponent as FormTRP } from "./components/transportist-route-price/form/form.component";
import { CommisionProductComponent } from "./components/commision-product/commision-product.component";
import { FormComponent as FormCP } from "./components/commision-product/form/form.component";
import { DiscountProductComponent } from "./components/discount-product/discount-product.component";
import { FormComponent as FormDPP } from "./components/discount-product/form/form.component";
import { FormComponent as FormAP } from "./components/auth-prices/form/form.component";
import { FormComponent as FormProduct } from "./components/sections/admin/products/dialogs/form/form.component";
import { FormComponent as FormSale } from "./components/sections/orders/sales/dialogs/form/form.component";
import { CompaniesComponent } from "./components/companies/companies.component";
import { FormComponent as FormC } from "./components/companies/form/form.component";
import { FormComponent as FormAuthP } from "./components/auth-prices/form/form.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { JwtInterceptor } from "./interceptors/jwt.interceptor";
import { CookieService } from "ngx-cookie-service";
import { NotificationsComponent } from "./components/alerts/notifications/notifications.component";
import { AddressComponentComponent } from "./components/address-component/address-component.component";
import { LoaderListComponent } from "./components/dropdowns/loader-list/loader-list.component";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { SalesComponent } from "./components/sections/orders/sales/sales.component";
import { ClientsForCompanyComponent } from "./components/companies/clients-for-company/clients-for-company.component";
import { InfoComponent } from "./components/companies/info/info.component";
import { TransportistsComponent } from "./components/subCompanies/transportists/transportists.component";
import { SuppliersComponent } from "./components/subCompanies/suppliers/suppliers.component";
import { ClientsComponent } from "./components/subCompanies/clients/clients.component";
import { AddressesDialogComponent } from "./components/companies/addresses-dialog/addresses-dialog.component";
import { GoogleMapsModule } from "@angular/google-maps";
import { ViewsFormComponent } from "./components/sections/admin/roles-views/dialogs/views-form/views-form.component";
import { ChangeCFIDComponent } from "./components/subCompanies/change-cfid/change-cfid.component";
import { LabOrderComponent } from "./components/sections/orders/lab-order/lab-order.component";
import { SaleDetailComponent } from "./components/sections/orders/sales/dialogs/sale-detail/sale-detail.component";
import { SellersForClientsComponent } from "./components/companies/sellers-for-clients/sellers-for-clients.component";
import { CommisionsComponent } from "./components/subCompanies/clients/commisions/commisions.component";
import { ShowComponent } from "./components/subCompanies/clients/show/show.component";
import { AddressesComponent } from "./components/companies/addresses/addresses.component";
import { PurcaseComponent } from "./components/purcase/purcase.component";
import { PurchaseDetailComponent } from "./components/purcase/dialogs/purchase-detail/purchase-detail.component";
import { FormComponent as PD } from "./components/purcase/dialogs/form/form.component";
import {
  FilterPipe,
  FilterPurchasePipe,
} from "./components/sections/orders/pipes/filter.pipe";
import {
  TotalPipe,
  TotalPipePurchase,
} from "./components/sections/orders/pipes/total.pipe";
import { LoaderComponent } from "./components/loader/loader.component";
import { ChangePasswordComponent } from "./components/user/form/change-password/change-password.component";
import { ServicesComponent } from "./components/sections/admin/services/services/services.component";
import { ServiceFormComponent } from "./components/sections/admin/services/dialogs/service-form/service-form.component";
import { UnloadingOrderComponent } from "./components/purcase/dialogs/unloading-order/unloading-order.component";
import { LogsListComponent } from "./components/sections/admin/logs/components/logs-list/logs-list.component";
import { LogsDiffComponent } from "./components/sections/admin/logs/components/logs-diff/logs-diff.component";
import { CostListComponent } from "./components/sections/costs/components/cost-list/cost-list.component";
import { CostDialogComponent } from "./components/sections/costs/components/cost-dialog/cost-dialog.component";
import { AddLabDetailProductComponent } from "./components/sections/orders/sales/dialogs/add-lab-detail-product/add-lab-detail-product.component";
import { CostingFormComponent } from "./components/purcase/dialogs/costing-form/costing-form.component";
import { InvoiceListComponent } from "./components/sections/invoice/components/invoice-list/invoice-list.component";
import { InvoiceDialogComponent } from "./components/sections/invoice/components/invoice-dialog/invoice-dialog.component";
import { PurchasesSalesReportComponent } from "./components/sections/reports/components/purchases-sales-report/purchases-sales-report.component";
import { BlobErrorHttpInterceptor } from "./interceptors/blob.interceptor";
import { InvoiceSuppliersComponent } from "./components/sections/invoice/suppliers/invoice-suppliers/invoice-suppliers.component";
import { InvoiceFilterComponent } from "./components/sections/invoice/components/invoice-filter/invoice-filter.component";
import { InvoiceClientListComponent } from "./components/sections/invoice/clients/components/invoice-client-list/invoice-client-list.component";
import { CreateClientCreditNoteComponent } from "./components/sections/invoice/clients/components/create-client-credit-note/create-client-credit-note.component";
import { FeeClientListComponent } from "./components/sections/invoice/clients/components/fee-client-list/fee-client-list.component";
import { AssingClientInvoiceComponent } from "./components/sections/invoice/clients/components/assing-client-invoice/assing-client-invoice.component";
import { AssingClientCreditNoteComponent } from "./components/sections/invoice/clients/components/assing-client-credit-note/assing-client-credit-note.component";
import { AssingSupplierInvoiceComponent } from "./components/sections/invoice/suppliers/assing-supplier-invoice/assing-supplier-invoice.component";
import { PaymentSuppliersListComponent } from "./components/sections/invoice/suppliers/payment-suppliers-list/payment-suppliers-list.component";
import { CreateSupplierCreditNoteComponent } from "./components/sections/invoice/suppliers/create-supplier-credit-note/create-supplier-credit-note.component";
import { PaymentSuppliersComponent } from "./components/sections/invoice/suppliers/payment-suppliers/payment-suppliers.component";
import { FeeClientComponent } from "./components/sections/invoice/clients/components/fee-client/fee-client.component";
import { CreateClientCreditNoteWorelationComponent } from "./components/sections/invoice/clients/components/create-client-credit-note-worelation/create-client-credit-note-worelation.component";
import { AssignSupplierCreditNoteComponent } from "./components/sections/invoice/suppliers/assign-supplier-credit-note/assign-supplier-credit-note.component";
import { PaymentSupplierAttachmentsComponent } from "./components/sections/invoice/components/payment-supplier-attachments/payment-supplier-attachments.component";
import { EvidenceComponent } from "./components/purcase/dialogs/evidence/evidence.component";
import { SaleEvidencesComponent } from "./components/sections/orders/sales/dialogs/sale-evidences/sale-evidences.component";
import { DaySalesComponent } from './components/sections/dashboard/day-sales/day-sales.component';
import { InventoryComponent } from './components/sections/dashboard/inventory/inventory.component';
import { UnitsInTrancitComponent } from './components/dashboard/reports/units-in-trancit/units-in-trancit.component';
import { UnitsInPlantComponent } from './components/dashboard/reports/units-in-plant/units-in-plant.component';
import { DeleteLabDetailProductComponent } from './components/sections/orders/sales/dialogs/delete-lab-detail-product/delete-lab-detail-product.component';

@NgModule({
  declarations: [
    AppComponent,
    FormSale,
    SalesComponent,
    ClientsForCompanyComponent,
    InfoComponent,
    TransportistsComponent,
    SuppliersComponent,
    ClientsComponent,
    DashboardComponent,
    CardBarChartComponent,
    CardLineChartComponent,
    IndexDropdownComponent,
    PagesDropdownComponent,
    TableDropdownComponent,
    NotificationDropdownComponent,
    UserDropdownComponent,
    SidebarComponent,
    FooterComponent,
    FooterSmallComponent,
    CardPageVisitsComponent,
    CardProfileComponent,
    CardSettingsComponent,
    CardSocialTrafficComponent,
    CardTableComponent,
    MapExampleComponent,
    HeaderStatsComponent,
    AuthNavbarComponent,
    AdminNavbarComponent,
    IndexNavbarComponent,
    AdminComponent,
    AuthComponent,
    MapsComponent,
    SettingsComponent,
    TablesComponent,
    IndexComponent,
    ProfileComponent,
    CategoriesComponent,
    CategoriesForm,
    BodyComponent,
    UserComponent,
    VistasComponent,
    VistasForm,
    UsersComponent,
    usersForm,
    RolesComponent,
    RolesViewsComponent,
    FormComponent,
    RoleViewFormComponent,
    AlertComponent,
    ModalConfirmationComponent,
    MoreComponent,
    DatetimeComponent,
    TanksComponent,
    FormTankComponent,
    PumpsComponent,
    FormPumpComponent,
    DateComponent,
    TimeComponent,
    ExistenceComponent,
    FormexistenceComponent,
    AuthPricesComponent,
    PumpMeasureComponent,
    PumpMeasureFormComponent,
    ExistenceChangeTypesComponent,
    FormExistenceChangeTypes,
    ProductsComponent,
    ProductFormComponent,
    ComposeProductComponent,
    PluckPipe,
    RoutesComponent,
    FormRoute,
    TransportistRoutePriceComponent,
    FormTRP,
    CommisionProductComponent,
    FormCP,
    DiscountProductComponent,
    FormDPP,
    FormAP,
    FormProduct,
    CompaniesComponent,
    FormC,
    FormAuthP,
    LoginComponent,
    NotificationsComponent,
    AddressComponentComponent,
    LoaderListComponent,
    AddressesDialogComponent,
    ViewsFormComponent,
    ChangeCFIDComponent,
    LabOrderComponent,
    SaleDetailComponent,
    SellersForClientsComponent,
    CommisionsComponent,
    ShowComponent,
    AddressesComponent,
    PurcaseComponent,
    PurchaseDetailComponent,
    PD,
    LoaderComponent,
    ChangePasswordComponent,
    ServicesComponent,
    ServiceFormComponent,
    UnloadingOrderComponent,
    LogsListComponent,
    LogsDiffComponent,
    CostListComponent,
    CostDialogComponent,
    AddLabDetailProductComponent,
    CostingFormComponent,
    InvoiceListComponent,
    InvoiceDialogComponent,
    PurchasesSalesReportComponent,
    InvoiceSuppliersComponent,
    InvoiceFilterComponent,
    InvoiceClientListComponent,
    CreateClientCreditNoteComponent,
    FeeClientListComponent,
    AssingClientInvoiceComponent,
    AssingClientCreditNoteComponent,
    AssingSupplierInvoiceComponent,
    PaymentSuppliersListComponent,
    CreateSupplierCreditNoteComponent,
    PaymentSuppliersComponent,
    FeeClientComponent,
    AssignSupplierCreditNoteComponent,
    CreateClientCreditNoteWorelationComponent,
    PaymentSupplierAttachmentsComponent,

    EvidenceComponent,
    SaleEvidencesComponent,
    DaySalesComponent,
    InventoryComponent,
    UnitsInTrancitComponent,
    UnitsInPlantComponent,
    DeleteLabDetailProductComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatTableModule,
    MatSnackBarModule,
    MatMenuModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxDatatableModule.forRoot({
      messages: {
        emptyMessage: "No hay datos.", // Message to show when array is presented, but contains no values
        totalMessage: "total", // Footer total message
        selectedMessage: "seleccionado", // Footer selected message
      },
    }),
    MonacoEditorModule.forRoot(),
    NgxMaskDirective,
    NgxMaskPipe,
    GoogleMapsModule,
    DragDropModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonModule,
    FilterPipe,
    FilterPurchasePipe,
    TotalPipe,
    TotalPipePurchase,
  ],
  providers: [
    NameTableService,
    PluckPipe,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobErrorHttpInterceptor,
      multi: true,
    },
    DataManager,
    provideNgxMask(),
    DatePipe,
    FilterPipe,
    TotalPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
