import { View } from "./view";

export class Category {
    id?: number;
    name?: string;
    order?: number;
    active?: boolean;
    views?: View[];

    constructor(category: Category) {
      {
        this.id = category.id || undefined;
        this.name = category.name || '';
        this.order = category.order || 0;
        this.active = category.active || false;
        this.views = category.views || [];
      }
    }
  }
