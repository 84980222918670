<div
  class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex items-center justify-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
>
  <div class="relative p-4 w-full max-w-2xl max-h-full">
    <div class="relative bg-white rounded-lg shadow">
      <div class="p-4 md:p-5 space-y-4">
        <p class="text-base leading-relaxed text-gray-500">
          {{ body }}
        </p>
      </div>

      <div class="flex items-center justify-end bottom-0 right-0 pb-3 pr-3">
        <button
          *ngIf="showBtnClose"
          type="button"
          (click)="closeModal(false)"
          class="text-white bg-gray-400 hover:bg-gray-500 focus:ring-0 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        >
          {{ btnCancelTxt }}
        </button>
        <button
          type="button"
          (click)="closeModal(true)"
          class="mx-4 ms-3 bg-sky-600 text-white active:bg-sky-600 hover:bg-sky-700 focus:ring-0 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 focus:z-10"
        >
          {{ btnAcceptTxt }}
        </button>
      </div>
    </div>
  </div>
</div>
