import { Component, ViewChild } from '@angular/core';
import { ExistenceChangeTypes } from 'app/models/existence_types_change';
import { ModalConfirmationComponent } from '../modals/modal-confirmation/modal-confirmation.component';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { MatDialog } from '@angular/material/dialog';
import { FormComponent } from './form/form.component';
import { InventoryService } from 'app/apiServices/inventory.service';

@Component({
  selector: 'app-existence-change-types',
  templateUrl: './existence-change-types.component.html',
  styleUrls: ['./existence-change-types.component.css']
})
export class ExistenceChangeTypesComponent {
  rows: ExistenceChangeTypes[] = [
    { id: 1, name: "Example1", description: "" },
    { id: 2, name: "Example2", description: "" },
  ];
  temp: ExistenceChangeTypes[] = [];
  showAlert = false;
  alertTitle: string;


  @ViewChild(DatatableComponent) table: DatatableComponent;

  ColumnMode = ColumnMode;
  constructor(public dialog: MatDialog, private inventorySvc: InventoryService) {
    // this.temp = [...this.temp];
  }

  ngOnInit() {
    this.getData()
  }

  updateFilter(event: Event) {
    const val: string = (event.target as HTMLInputElement).value.toLowerCase();
    const temp: ExistenceChangeTypes[] = this.temp.filter((d: ExistenceChangeTypes) => {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.temp = temp;
    this.table.offset = 0;
  }

  openDialog(data: ExistenceChangeTypes, action: string) {
    const dialogRef = this.dialog.open(FormComponent, {
      width: "600px",
      data: {
        action,
        data,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.method == "store") {
        if (result.status === 200) this.showAlert = true;
        // result.data.id = this.temp.length + 2;
        // this.temp = [...this.temp, result.data];
      } else {
        if (result) {
          if (result.status === 200) this.showAlert = true;
          // const index = this.temp.findIndex((d) => d.id === result.data.id);
          // this.temp[index] = result.data;
          // this.temp = [...this.temp];
        }
      }
      this.getData()
    });
  }

  openDialogDelete(data: ExistenceChangeTypes) {
    const dialogRef = this.dialog.open(ModalConfirmationComponent, {
      width: "600px",
      data: {
        title: "¿Estas seguro de desactivar este Tipo de Cambio?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.inventorySvc.deleteExistenceChangeTypes(data).subscribe({
          next: (v) => {
            this.getData()
          },
          error: (e) => {
            //console.log(e)
          }
        })
      }
    });
  }

  closeAlert(event: boolean) {
    this.showAlert = event;
  }

  delete(data: ExistenceChangeTypes) {
    this.openDialogDelete(data);
  }

  getData(): void {
    this.inventorySvc.getExistenceChangeTypes().subscribe({
      next: (v) => {
        this.temp = v.data
      },
      error: (e) => {
        //console.log(e)
      }
    })
  }
}
