<div class="bg-white px-4 h-full py-4 overflow-y-auto w-full">
  <div
    class="flex justify-between rounded-t mb-0 px-4 py-3 border-0 pt-3 bg-white"
  >
    <div class="flex w-full justify-center">
      <div>
        <h3 class="font-semibold text-lg">Orden de Producción</h3>
      </div>
    </div>
    <div class="w-1/12 justify-end mb-0 pb-8">
      <button
        class="text-sky-600 cursor-pointer hover:text-sky-700 text-2xl pl-12 ease-linear transition-all duration-150"
        type="button"
        (click)="closeModal()"
      >
        <i class="fas fa-times-circle"></i>
      </button>
    </div>
  </div>
  <form class="bg-white" [formGroup]="form">
    <div class="flex flex-row w-full mt-6">
      <div class="w-72 px-2">
        <label
          class="py-2 block uppercase text-slate-600 text-xs font-bold mb-2"
          htmlFor="quantity"
        >
          Cantidad requerida de producto
        </label>

        <input
          type="text"
          mask="separator.2"
          thousandSeparator=","
          [leadZero]="true"
          formControlName="quantity"
          (change)="onChangeSoldLts($event)"
          class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        />
      </div>
    </div>

    <div class="flex flex-row w-full mt-5">
      <div class="grid w-full mr-6">
        <div class="overflow-hidden rounded w-full">
          <table
            class="w-full border text-center text-sm font-light"
            aria-describedby="orderproduction"
          >
            <thead>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </thead>
            <thead class="w-full border-0">
              <tr class="w-full border-b">
                <th class="text-base text-white">Del</th>
                <th class="border-r text-base">Pipa 1</th>
                <th class="border-r text-base">Producto</th>
                <th class="border-r text-base">
                  {{ order.product.name }}
                </th>
                <th class="border-r">
                  <table
                    class="w-full border text-center text-sm font-light border-none"
                    aria-describedby="tanks"
                  >
                    <thead>
                      <th></th>
                      <th></th>
                    </thead>
                    <tbody class="w-full border-none">
                      <tr class="w-full">
                        <th class="border-r text-base w-2/6">Capacidad</th>
                        <th class="border-r text-base w-2/6">Orden</th>
                        <th class="w-2/6 text-base whitespace-nowrap">
                          <input
                            type="text"
                            mask="separator.2"
                            [leadZero]="true"
                            thousandSeparator=","
                            [(ngModel)]="currentTypeTransportPipeOne"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="onEditPipeLts($event, 1)"
                            class="w-full border-0 px-0 focus:border-0"
                          />
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </th>
              </tr>
            </thead>
            <tbody class="w-full border-0">
              <tr *ngFor="let orderDetail of selectedTanks, let i = index" class="border-b">
                <ng-container
                  *ngIf="!editPushedProductsPipeOne.includes(orderDetail.id)"
                >
                  <td>
                    <button *ngIf="i != 0"><i class="fas fa-times text-red-500" (click)="openDeleteProduct(1, orderDetail?.product?.id)"></i></button>
                  </td>
                  <td class="border-r">
                    <select
                      *ngIf="canEdit && orderDetail.product.full_mix"
                      #productPipeOne
                      class="border-none px-1 py-2 pl-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      (change)="
                        onChangeProduct(
                          $event,
                          1,
                          selectedTanks.indexOf(orderDetail)
                        )
                      "
                    >
                      <option
                        *ngFor="let product of products"
                        [value]="product.id"
                        [selected]="product.id == orderDetail.product.id"
                      >
                        {{ product.name }}
                      </option>
                    </select>

                    <ng-container
                      *ngIf="
                        (!orderDetail.product.full_mix && canEdit) || !canEdit
                      "
                      >{{ orderDetail.product.name }}</ng-container
                    >
                  </td>
                  <td
                    class="text-start align-top whitespace-nowrap border-r text-base"
                  >
                    <input
                      [id]="'inputpercent' + selectedTanks.indexOf(orderDetail)"
                      (keyup.enter)="
                        focusNext(
                          selectedTanks.indexOf(orderDetail),
                          'inputpercent'
                        )
                      "
                      type="text"
                      mask="separator.6"
                      thousandSeparator=","
                      suffix=" %"
                      [(ngModel)]="
                        selectedTanks[selectedTanks.indexOf(orderDetail)]
                          .percentage
                      "
                      [ngModelOptions]="{ standalone: true }"
                      (change)="onLeadingZero($event)"
                      [disabled]="!canEdit"
                      class="w-full border-0 focus:border-0 px-1"
                    />
                  </td>
                  <td
                    class="px-2 text-start align-top whitespace-nowrap border-r pt-2 text-base"
                  >
                    {{ calculatePercentage(orderDetail, 1) | number : "1.2-2" }}
                  </td>
                  <td class="text-start whitespace-nowrap border-0">
                    <table
                      class="w-full border text-center text-sm font-light border-none"
                      aria-describedby="tanksPipeOne"
                    >
                      <thead>
                        <th></th>
                        <th></th>
                      </thead>
                      <tbody class="w-full border-0">
                        <tr
                          *ngFor="
                            let tank of tanksPipeOne
                              | filter : orderDetail.product.id;
                            index as i
                          "
                          class="border-b w-full"
                        >
                          <td class="text-start whitespace-nowrap w-2/6">
                            <div class="flex align-middle">
                              <div class="w-80">
                                {{ tank.tank }}
                              </div>

                              <div class="flex justify-end align-middle">
                                <i
                                  class="fas fa-times-circle cursor-pointer z-10"
                                  (click)="
                                    deleteTank(tanksPipeOne.indexOf(tank), 1)
                                  "
                                ></i>
                              </div>
                            </div>
                          </td>
                          <td class="border-l whitespace-nowrap w-2/6">
                            <input
                              [id]="
                                'inputpriority' + tanksPipeOne.indexOf(tank)
                              "
                              (keyup.enter)="
                                focusNext(
                                  tanksPipeOne.indexOf(tank),
                                  'inputpriority'
                                )
                              "
                              type="number"
                              min="0"
                              [(ngModel)]="tank.priority"
                              [ngModelOptions]="{ standalone: true }"
                              (keypress)="preventOnKeyPress($event)"
                              [disabled]="!canEdit"
                              class="w-full border-0 focus:border-0 px-1"
                            />
                          </td>
                          <td class="border-l w-2/6">
                            <input
                              [id]="'inputqty' + tanksPipeOne.indexOf(tank)"
                              (keyup.enter)="
                                focusNext(
                                  tanksPipeOne.indexOf(tank),
                                  'inputqty'
                                )
                              "
                              type="text"
                              mask="separator.2"
                              thousandSeparator=","
                              [(ngModel)]="tank.required_quantity"
                              [ngModelOptions]="{ standalone: true }"
                              [leadZero]="true"
                              (focus)="
                                saveInputValue(
                                  $event,
                                  true,
                                  tanksPipeOne.indexOf(tank),
                                  1
                                )
                              "
                              (change)="
                                onSupplyChange(
                                  $event,
                                  tanksPipeOne.indexOf(tank),
                                  1,
                                  orderDetail?.product.id,
                                  orderDetail
                                )
                              "
                              [disabled]="!canEdit"
                              class="w-full border-0 focus:border-0 px-1"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td class="w-2/6">
                            <select
                              *ngIf="canEdit"
                              (change)="
                                onSelectTank(
                                  $event,
                                  $event.target.value,
                                  1,
                                  orderDetail?.product.id,
                                  orderDetail
                                )
                              "
                              class="border-none px-1 py-2 pl-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            >
                              <option>Selecciona un tanque</option>
                              <option
                                *ngFor="
                                  let tank of tanks
                                    | filter : orderDetail?.product.id
                                "
                                [value]="tank.id"
                              >
                                {{ tank.tank }}
                              </option>
                            </select>
                          </td>
                          <td class="border-l w-2/6"></td>
                          <td
                            class="border-l h-10 text-base text-end px-1 font-bold w-2/6"
                          >
                            {{
                              tanksPipeOne
                                | total : orderDetail.product?.id
                                | number : "1.2-2"
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="grid w-full">
        <div class="overflow-hidden rounded w-full">
          <table
            class="w-full border text-center text-sm font-light"
            aria-describedby="labproduction"
          >
            <thead class="w-full border-0">
              <tr class="w-full border-b">
                <th class="text-base"></th>
                <th class="text-base text-white">Del</th>
                <th class="border-r text-base">Pipa 2</th>
                <th class="border-r text-base">Producto</th>
                <th class="border-r text-base">
                  {{ order.product.name }}
                </th>
                <th class="border-r">
                  <table
                    class="w-full border text-center text-sm font-light border-none"
                    aria-describedby="tanksPipeTwo"
                  >
                    <tbody class="w-full border-none">
                      <tr>
                        
                        <th class="border-r text-base w-2/6">Capacidad</th>
                        <th class="border-r text-base w-2/6">Orden</th>
                        <th
                          *ngIf="isTransportFull"
                          class="w-2/6 text-base whitespace-nowrap"
                        >
                          <input
                            type="text"
                            mask="separator.2"
                            [leadZero]="true"
                            thousandSeparator=","
                            [(ngModel)]="currentTypeTransportPipeTwo"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="onEditPipeLts($event, 2)"
                            class="w-full border-0 focus:border-0 px-0"
                          />
                        </th>
                        <th *ngIf="!isTransportFull" class="w-2/6 text-base">
                          {{ 0 | number : "1.2-2" }}
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </th>
              </tr>
            </thead>
            <tbody class="w-full border-0">
              <tr
                *ngFor="let orderDetail of selectedTanksPipeTwo, let i = index"
                class="border-b"
              >
                <ng-container
                  *ngIf="!editPushedProductsPipeTwo.includes(orderDetail.id)"
                >
                <td>
                  <button *ngIf="i != 0"><i class="fas fa-times text-red-500" (click)="openDeleteProduct(2, orderDetail?.product?.id)"></i></button>
                </td>
                  <td class="border-r">
                    <select
                      *ngIf="canEdit && orderDetail.product?.full_mix"
                      #productPipeTwo
                      class="border-none px-1 py-2 pl-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      (change)="
                        onChangeProduct(
                          $event,
                          1,
                          selectedTanksPipeTwo.indexOf(orderDetail, 2)
                        )
                      "
                    >
                      <option
                        *ngFor="let product of products"
                        [value]="product.id"
                        [selected]="product.id == orderDetail.product.id"
                      >
                        {{ product.name }}
                      </option>
                    </select>
                    <ng-container
                      *ngIf="
                        (!orderDetail.product?.full_mix && canEdit) || !canEdit
                      "
                      >{{ orderDetail.product.name }}</ng-container
                    >
                  </td>
                  <td
                    class="text-start align-top whitespace-nowrap border-r text-base"
                  >
                    <input
                      [id]="
                        'inputpercent' +
                        selectedTanksPipeTwo.indexOf(orderDetail)
                      "
                      (keyup.enter)="
                        focusNext(
                          selectedTanksPipeTwo.indexOf(orderDetail),
                          'inputpercent'
                        )
                      "
                      type="text"
                      mask="separator.6"
                      thousandSeparator=","
                      suffix=" %"
                      [(ngModel)]="
                        selectedTanksPipeTwo[
                          selectedTanksPipeTwo.indexOf(orderDetail)
                        ].percentage
                      "
                      [ngModelOptions]="{ standalone: true }"
                      (change)="onLeadingZero($event)"
                      [disabled]="!canEdit"
                      class="w-full border-0 focus:border-0 px-1"
                    />
                  </td>
                  <td
                    class="px-2 text-start align-top whitespace-nowrap border-r pt-2 text-base"
                  >
                    {{ calculatePercentage(orderDetail, 2) | number : "1.2-2" }}
                  </td>
                  <td class="text-start whitespace-nowrap border-0">
                    <table
                      class="w-full border text-center text-sm font-light border-none"
                      aria-describedby="tanksPipeTwo"
                    >
                      <thead>
                        <th></th>
                        <th></th>
                      </thead>
                      <tbody class="w-full border-0">
                        <tr
                          *ngFor="
                            let tank of tanksPipeTwo
                              | filter : orderDetail.product.id
                          "
                          class="border-b w-full"
                        >
                          <td class="text-start whitespace-nowrap w-2/6">
                            <div class="flex align-middle">
                              <div class="w-80">
                                {{ tank.tank }}
                              </div>

                              <div class="flex justify-end align-middle">
                                <i
                                  class="fas fa-times-circle cursor-pointer z-10"
                                  (click)="
                                    deleteTank(tanksPipeTwo.indexOf(tank), 2)
                                  "
                                ></i>
                              </div>
                            </div>
                          </td>
                          <td class="border-l whitespace-nowrap w-2/6">
                            <input
                              [id]="
                                'inputpriority' + tanksPipeTwo.indexOf(tank)
                              "
                              (keyup.enter)="
                                focusNext(
                                  tanksPipeTwo.indexOf(tank),
                                  'inputpriority'
                                )
                              "
                              type="number"
                              (keypress)="preventOnKeyPress($event)"
                              [(ngModel)]="tank.priority"
                              [ngModelOptions]="{ standalone: true }"
                              [disabled]="!canEdit"
                              class="w-full border-0 focus:border-0 px-1"
                            />
                          </td>
                          <td class="border-l w-2/6">
                            <input
                              [id]="'inputqty' + tanksPipeTwo.indexOf(tank)"
                              (keyup.enter)="
                                focusNext(
                                  tanksPipeTwo.indexOf(tank),
                                  'inputqty'
                                )
                              "
                              type="text"
                              mask="separator.2"
                              thousandSeparator=","
                              [(ngModel)]="tank.required_quantity"
                              [ngModelOptions]="{ standalone: true }"
                              [leadZero]="true"
                              [disabled]="!canEdit"
                              (focus)="
                                saveInputValue(
                                  $event,
                                  true,
                                  tanksPipeTwo.indexOf(tank),
                                  2
                                )
                              "
                              (change)="
                                onSupplyChange(
                                  $event,
                                  tank.id,
                                  2,
                                  orderDetail?.product.id,
                                  orderDetail
                                )
                              "
                              class="w-full border-0 focus:border-0 px-1"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td class="w-2/6">
                            <select
                              *ngIf="canEdit"
                              (change)="
                                onSelectTank(
                                  $event,
                                  $event.target.value,
                                  2,
                                  orderDetail?.product.id,
                                  orderDetail
                                )
                              "
                              class="border-none px-1 py-2 pl-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            >
                              <option>Selecciona un tanque</option>
                              <option
                                *ngFor="
                                  let tank of tanks
                                    | filter : orderDetail?.product.id
                                "
                                [value]="tank.id"
                              >
                                {{ tank.tank }}
                              </option>
                            </select>
                          </td>
                          <td class="border-l w-2/6"></td>
                          <td
                            class="border-l h-10 text-base text-start px-1 font-bold w-2/6"
                          >
                            {{
                              tanksPipeTwo
                                | total : orderDetail.product?.id
                                | number : "1.2-2"
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="flex flex-row w-full mt-6" *ngIf="canEdit">
      <div class="flex w-1/2 justify-end align-middle pr-6">
        <i
          class="fas fa-plus-circle cursor-pointer"
          (click)="openAddSelectProduct(1)"
        ></i>
      </div>

      <div class="flex w-1/2 justify-end align-middle">
        <i
          *ngIf="isTransportFull"
          class="fas fa-plus-circle cursor-pointer"
          (click)="openAddSelectProduct(2)"
        ></i>
      </div>
    </div>

    <div class="flex flex-row w-full mt-16">
      <div class="w-2/3">
        <div class="container px-2">
          <label
            class="block uppercase text-slate-600 text-xs font-bold mb-2"
            htmlFor="observations"
          >
            Observaciones
          </label>
          <textarea
            name="observations"
            formControlName="observations"
            class="border-gray-300 px-3 py-3 placeholder-slate-300 border-2 text-slate-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          >
          </textarea>
        </div>
      </div>

      <div class="w-2/6">
        <div class="container px-2">
          <label
            class="block uppercase text-slate-600 text-xs font-bold"
            htmlFor="approved"
          >
            Orden aprobada
          </label>
          <input
            type="checkbox"
            name="approved"
            formControlName="approved"
            class="border-2 px-3 py-3 placeholder-slate-300 mt-6 text-slate-600 bg-white rounded-full border-gray-300 text-sm focus:outline-none focus:ring ease-linear transition-all duration-150"
          />
        </div>
      </div>
    </div>

    <!-- BUTTONS -->
    <div class="flex w-full justify-end mt-10">
      <button
        class="bg-slate-500 text-white active:bg-slate-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="button"
        mat-dialog-close
      >
        {{ !canEdit ? "Cancelar" : "Cerrar" }}
      </button>
      <button
        *ngIf="canEdit"
        class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="button"
        [ngClass]="{
          'disabled:opacity-25': form.invalid
        }"
        [disabled]="form.invalid"
        (click)="sendData()"
      >
        {{ !labOrder ? "Generar" : "Actualizar" }}
      </button>
    </div>
  </form>
</div>
