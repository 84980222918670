import { Component, ElementRef, ViewChild, Inject, OnInit} from "@angular/core";
import { MatDialogRef,MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { FormValidatorService } from "app/core/service/form-validator.service";
import { CostInvoices } from "app/models/cost_invoices";
import { Invoice } from "app/models/invoice";
import { Select } from "tw-elements";
import { InvoiceService } from "app/apiServices/invoice.service";
import { MatSnackBar } from "@angular/material/snack-bar";

import { NotificationsComponent } from "app/components/alerts/notifications/notifications.component";

@Component({
  selector: "app-assing-client-credit-note",
  templateUrl: "./assing-client-credit-note.component.html",
  styleUrls: ["./assing-client-credit-note.component.css"],
})
export class AssingClientCreditNoteComponent implements OnInit{
  @ViewChild("creditNotesId", { static: true })
  noteCreditsSelect: ElementRef;
  date;
  dialogTitle = "Facturas seleccionadas";

  creditNotes: any[] = [];
  initialBalance = 0;
  nowBalance = 0;
  totalImputed = 0;
  totalAvailable = 0;
  form: FormGroup;
  payment_invoice_id: number;
  invoices : Invoice[] = [];

  validationMessages = {
    date: [{ type: "required", message: "Ingresa la fecha" }],
  }
  
  selectOptions = {
    selectFilter: true,
    selectNoResultText: "No se encontraron resultados",
    selectSearchPlaceholder: "Buscar...",
  };

  rows: CostInvoices[] = [];
  client_id:number
  ColumnMode = ColumnMode;

  constructor(
    private dialogRef: MatDialogRef<AssingClientCreditNoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private invoiceService: InvoiceService,
    private _snack: MatSnackBar,
    private fb: FormBuilder,
    public formService: FormValidatorService

  ) {
    this.invoices = data.invoices;
    console.log(this.invoices);
    this.invoices = this.invoices.map(invoice => {
      let invoicePayments = invoice.invoice_payments;
      let dueTotal = invoice.total;
      if (invoicePayments.length) {
        let lastPayment = invoicePayments[invoicePayments.length - 1];
        if (lastPayment.due_total) {
          dueTotal = lastPayment.due_total;
        }
      }
      return {dueTotal: dueTotal, ...invoice};
    });
    this.rows = this.invoices;
    this.client_id = data.client_id;
  }
  

  ngAfterViewInit() {
    new Select(this.noteCreditsSelect.nativeElement, this.selectOptions);
  }

  ngOnInit(): void {
    this.form = this.createformupdatePaymentInvoices();
    this.getCreditNotes(this.client_id);
    this.addInvoicesToForm(this.rows);

  }

  getCreditNotes(client_id: number) {
    this.invoiceService.getPaymentInvoices().subscribe(
      (res) => {
        this.creditNotes = res.data.filter(note => {
          return note.payment > 0 && note.client.id === client_id && (note.type === "NC" || note.type === "NCE");
        });
        console.log(this.creditNotes); 
      },
      (err) => {
        console.log(err);
      }
    );

  }

  createformupdatePaymentInvoices() : FormGroup {
    return this.fb.group({
      invoice_payments: this.fb.array([]),
    });
  }

  createInvoice(invoice : Invoice): FormGroup {
    return this.fb.group({
      invoice_payment_id: [null],
      invoice_id: [invoice.id, [Validators.required]],
      payment_price: [0, [Validators.required]],
      number: [2, [Validators.required]],
      due_before: [invoice.dueTotal, [Validators.required]],
      due_total: [0, [Validators.required]],
      date: [, [Validators.required]],
    });
  }

  addInvoicesToForm(invoices: Invoice[]): void {
    const invoicePayments = this.form.get('invoice_payments') as FormArray;
    for (let invoice of invoices) {
      const invoiceFormGroup = this.createInvoice(invoice);
      invoicePayments.push(invoiceFormGroup);
    }
  }

  onChangeCreditNote(noteCreditId: string) {
  const noteCreditIdNumber = Number(noteCreditId);
  const noteCredit = this.creditNotes.find(
      (note) => note.id === noteCreditIdNumber
    );
    this.initialBalance = noteCredit.payment;
    this.payment_invoice_id = noteCredit.id
    var invoicePayments = this.invoices.map(invoice => {
      return invoice.invoice_payments.filter(payment => payment.payment.id === this.payment_invoice_id);
    });
    let total = invoicePayments.reduce((acc, val) => {
      return acc + val.reduce((acc2, val2) => acc2 + (val2.due_before - val2.due_total), 0);
    }, 0);
    this.nowBalance = noteCredit.payment - total;
    this.totalAvailable = this.nowBalance;
    if(this.totalImputed > 0){
      this.totalAvailable = this.nowBalance - this.totalImputed;
    }
  }

  changeDate(date: string): void {
    this.date = date;
    this.form.updateValueAndValidity();
    const invoicePayments = this.form.get('invoice_payments') as FormArray;
    for (let i = 0; i < invoicePayments.length; i++) {
      const invoiceFormGroup = invoicePayments.at(i) as FormGroup;
      invoiceFormGroup.get('date').patchValue(date);
    }
  }

  deleteInvoice(row: any): void {
    const index = this.invoices.findIndex(invoice => invoice.id === row.id);
    if (index !== -1) {
      this.totalImputed -= row.imputar;
      this.invoices.splice(index, 1);
    }
    else{
      this.openSnack("snack-error", "No se puede eliminar la factura");
    }
  }
  
  onImputedChange(event: Event, row: any): void {
    let value = (event.target as HTMLInputElement).value;
    value = value.replace("$", "").replace(",", "").replace(/\s/g, "");
    const numValue = Number(value);
    if (numValue > row.imputar) {
      this.openSnack("snack-error", "El monto imputado no puede ser mayor al saldo");
      (event.target as HTMLInputElement).value = `$ ${row.imputar.toFixed(2)}`;
      return;
    }

    if (row.previousImputed !== undefined) {
      this.totalImputed -= row.previousImputed;
    }
    this.totalImputed += numValue;
    row.previousImputed = numValue;
    
    this.totalAvailable = this.nowBalance - this.totalImputed;
    if (this.totalAvailable < 0) {
      this.openSnack("snack-error", "El monto imputado no puede ser mayor al saldo");
      (event.target as HTMLInputElement).value = `$ ${row.imputar.toFixed(2)}`;
      return;
    }
    const invoicePayments = this.form.get('invoice_payments') as FormArray;
    const invoiceFormGroup = invoicePayments.controls.find((group: FormGroup) => group.get('invoice_id').value === row.id) as FormGroup;
    const due_before = invoiceFormGroup.get('due_before').value;
    invoiceFormGroup.get('due_total').setValue(due_before - numValue);
    invoiceFormGroup.get('payment_price').setValue(numValue);
  }

  closeModal(): void {
    this.dialogRef.close();
  }


  updatePaymentInvoices() {
    console.log(this.form.value);
    if (this.form.valid) {
      this.invoiceService.updatePaymentInvoice(this.form.value, this.payment_invoice_id).subscribe({
        next: (res) => {
          this.openSnack("snack-success", res.message);
          this.dialogRef.close(true);
        },
        error: (err) => {
          console.log(err);
          this.openSnack("snack-error", err.error.message);
        },
      });
    }
  }

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
      verticalPosition: "top",
    });
  }
}
