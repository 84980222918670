<div class="bg-white px-4 w-full h-full py-4 overflow-y-auto">
    <div class="flex w-full justify-end">
      <div class="mb-0">
        <button
          class="text-end text-sky-600 cursor-pointer hover:text-sky-700 text-2xl pl-12 ease-linear transition-all duration-150"
          (click)="closeModal()"
        >
          <i class="fas fa-times-circle"></i>
        </button>
      </div>
    </div>
    <div class="mb-4 border-b border-gray-200">
      <ul
        class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400"
        id="tab"
        role="tablist"
      >
        <li class="mr-2" role="presentation">
          <button
            class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300"
            id="data-transaction-tab"
            type="button"
            role="tab"
            aria-controls="data-transaction"
            aria-selected="false"
          >
            Datos de transacción
          </button>
        </li>
      </ul>
    </div>
    <div
      class="hidden"
      id="data-transaction"
      role="tabpanel"
      aria-labelledby="data-transaction-tab"
    >
        <form [formGroup]="form">
          <div class="flex flex-row w-full">
            <div class="container w-full">
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="payment_type_id"
              >
                Forma de pago
              </label>
              <select
                #paymentMethodId
                formControlName="forma_pago_id"
                class="border-1 py-2.5 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              >
                <option value="">Selecciona una forma de pago</option>
                <option
                  *ngFor="let paymentMethod of formPaymentMethods"
                  [value]="paymentMethod.id"
                >
                {{paymentMethod.codigo +" .- "+paymentMethod.nombre }}
                </option>
              </select>
            </div>
          </div>
        <div class="flex flex-row w-full mt-4">
          <div class="container w-full">
            <label
              class="block uppercase text-slate-600 text-xs font-bold mb-2"
              htmlFor="payment_type_id"
            >
              Metodo de pago
            </label>
            <select
              #paymentMethodId2
              formControlName="payment_method_id"
              class="border-1 py-2.5 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            >
              <option value="">Selecciona un método de pago</option>
              <option
                *ngFor="let paymentMethod of paymentMethods"
                [value]="paymentMethod.id"
              >
                {{ paymentMethod.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="flex flex-row mt-2">
          <div class="w-1/2">
            <div class="container">
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="total"
                
              >
                Monto de la nota
              </label>
              <input
                type="text"
                name="payment"
                formControlName="payment"
                prefix="$ "
                mask="separator.6"
                [ngClass]="{
                  'focus:border-red-500': form.controls.payment.invalid,
                  'focus:ring-red-500': form.controls.payment.invalid
                }"
                (change)="onLeadingZero($event)"
                class="border-gray-300 px-3 py-2 placeholder-slate-300 border-1 text-slate-600 bg-white rounded text-sm focus:ring-1-1 focus:outline-none w-full ease-linear transition-all duration-150"
              />
              <div *ngFor="let validation of validationMessages.payment">
                <span
                  *ngIf="formService.field(form, validation.type, 'payment')"
                  class="mt-2 text-sm text-red-500"
                >
                  {{ validation.message }}
                </span>
              </div>
            </div>
          </div>
          

          <div class="w-1/2">
            <div class="ml-2">
              <app-date
                idName="date"
                title="Fecha de pago"
                (dateChange)="changeDate($event)"
              ></app-date>
              <div *ngFor="let validation of validationMessages.date">
                <span
                  *ngIf="formService.field(form, validation.type, 'date')"
                  class="mt-2 text-sm text-red-500"
                >
                  {{ validation.message }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="w-full">
            <div class="w-full ">
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="payment_type_id"
              >
                CFDI
              </label>
              <select
                #CFDI_Id
                formControlName="uso_cfdi_id"
                class="border-1 py-2.5 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                
              >
                <option value="">Selecciona un CFDI</option>
                <option
                  *ngFor="let cfdil of cfdi"
                  [value]="cfdil.id"
                >
                {{ cfdil.uso_cfdi + ".- " +cfdil.descripcion }}
                </option>
              </select>
            </div>
            <div class="1/2">
            <div class="w-full">
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="payment_type_id"
              >
                Tipo de nota
              </label>
              <select
                #type
                formControlName="type"
                class="border-1 py-2.5 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              >
                <option value="">Selecciona un tipo de nota</option>
                <option value="NC">Nota de crédito</option>
                <option value="NCE">Nota de credito excenta</option>
              </select>
            </div>
          </div>
            <div class="mt-2">
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="comments"
              >
                Observaciones
              </label>
              <textarea
                name="comments"
                formControlName="comments"
                rows="1"
                [ngClass]="{
                  'focus:border-red-500': form.controls.comments.invalid,
                  'focus:ring-red-500': form.controls.comments.invalid
                }"
                class="border-gray-300 px-3 py-3 resize-none placeholder-slate-300 border-1 text-slate-600 bg-white rounded text-sm focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
              >
              </textarea>
            </div>
          </div>
        </div>
   

      <div class="flex items-center justify-end">
        <button
          class="bg-slate-500 text-white active:bg-slate-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          (click)="closeModal()"
        >
          Cancelar
        </button>

        <button
          class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          (click)="createPaymentInvoice()"
          >
          Guardar
        </button>
      </div>
    </form>
    </div>
  </div>
