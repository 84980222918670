<div class="container bg-white mx-auto px-4 w-full h-full py-4 overflow-hidden">
  <div class="flex">
    <div class="w-11/12 justify-between rounded-t mb-0 pb-8 border-0 bg-white">
      <div class="text-start">
        <h3 class="font-semibold text-lg">
          {{ dialogTitle }}
        </h3>
      </div>
    </div>

    <div class="w-1/12 justify-end mb-0 pb-8">
      <button
        class="text-sky-600 cursor-pointer hover:text-sky-700 text-2xl pl-12 ease-linear transition-all duration-150"
        (click)="closeModal()"
      >
        <i class="fas fa-times-circle"></i>
      </button>
    </div>
  </div>
  
  <div class="mb-4 border-b border-gray-200">
    <ul
      class="hidden text-sm font-medium text-center text-gray-500 shadow sm:flex"
      id="tabs"
      role="tablist">
      <li class="w-full ">
        <button
          class="inline-block w-full cursor-pointer p-2 hover:bg-gray-150 border border-gray-200 hover:bg-gray-200 focus:ring-0 focus:outline-none"
          id="general-data-tab"
          type="button"
          role="tab"
          aria-controls="general-data"
          aria-selected="false">
          Datos generales
        </button>
      </li>
      <li class="w-full ">
        <button
          class="inline-block w-full p-2 hover:bg-gray-150 border border-gray-200 hover:bg-gray-200 focus-ring-0 focus:outline-none"
          [ngClass]="{
            'cursor-not-allowed': !form.valid,
            'cursor-pointer': form.valid
          }"
          id="delivery-data-tab"
          type="button"
          role="tab"
          aria-controls="delivery-data"
          aria-selected="false"
          [disabled]="!form.valid"
        >
          Datos de entrega
        </button>
      </li>
      <li class="w-full ">
        <button
          class="inline-block w-full p-2 hover:bg-gray-150 border border-gray-200 hover:bg-gray-200 focus:ring-0 focus:outline-none"
          [ngClass]="{
            'cursor-not-allowed': !formDelivery.valid,
            'cursor-pointer': formDelivery.valid
          }"
          id="stats-data-tab"
          type="button"
          role="tab"
          aria-controls="stats-data"
          aria-selected="false"
          [disabled]="!formDelivery.valid"
        >
          Datos estadísticos
        </button>
      </li>
      <li class="w-full ">
        <button
          class="inline-block w-full p-2 hover:bg-gray-150 border border-gray-200 hover:bg-gray-200 focus:ring-0 focus:ring-blue-300"
          [ngClass]="{
            'cursor-not-allowed':
              form.valid || !formDelivery.valid || !formStats.valid,
            'cursor-pointer':
              form.valid && !formDelivery.valid && !formStats.valid
          }"
          id="sale-tab"
          type="button"
          role="tab"
          aria-controls="sale"
          aria-selected="false"
          [disabled]="!form.valid || !formDelivery.valid || !formStats.valid"
        >
          Venta
        </button>
      </li>
      <div class="">
      </div>
    </ul>
  </div>
  <div id="tabContentExample">
    <div
      class="hidden p-4"
      id="general-data"
      role="tabpanel"
      aria-labelledby="general-data-tab"
    >
    <div class="overflow-y">
      <form class="bg-white" [formGroup]="form">
        <div class="overflow-y-auto h-full w-full">
          <div class="flex flex-row column">
            <div class="w-1/2 fullWidth">
              <div class="container px-2">
                <label
                  class="block uppercase text-slate-600 text-xs font-bold mb-2"
                  htmlFor="folio"
                >
                  Numero de Venta
                </label>
                <input
                  type="text"
                  name="folio"
                  formControlName="folio"
                  [ngClass]="{
                    'focus:border-red-500': form.controls.folio.invalid,
                    'focus:ring-red-500': form.controls.folio.invalid
                  }"
                  class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                />
                <div *ngFor="let validation of validationMessages.folio">
                  <span
                    *ngIf="formService.field(form, validation.type, 'folio')"
                    class="mt-2 text-sm text-red-500"
                  >
                    {{ validation.message }}
                  </span>
                </div>
              </div>
            </div>
  
            <div class="w-1/2 fullWidth">
              <div class="px-2">
                <app-date
                  idName="load-date"
                  title="Fecha carga"
                  [value]="dataSource.load_date"
                  (dateChange)="changeDate($event, 'load-date')"
                ></app-date>
                <div *ngFor="let validation of validationMessages.load_date">
                  <span
                    *ngIf="formService.field(form, validation.type, 'load_date')"
                    class="mt-2 text-sm text-red-500"
                  >
                    {{ validation.message }}
                  </span>
                </div>
              </div>
            </div>
  
            <div class="w-1/2 fullWidth">
              <div class="px-2">
                <app-date
                  idName="end-date"
                  title="Fecha ETA"
                  [value]="dataSource.end_date"
                  (dateChange)="changeDate($event, 'end-date')"
                ></app-date>
                <div *ngFor="let validation of validationMessages.end_date">
                  <span
                    *ngIf="formService.field(form, validation.type, 'end_date')"
                    class="mt-2 text-sm text-red-500"
                  >
                    {{ validation.message }}
                  </span>
                </div>
              </div>
            </div>
          </div>
  
          <div class="flex flex-row w-full column">
            <div class="w-2/6 fullWidth">
              <div class="container px-2">
                <label
                  class="block uppercase text-slate-600 text-xs font-bold mb-2"
                  htmlFor="seller_id"
                >
                  Compañia vendedora
                </label>
                <select
                  #sellerId
                  formControlName="seller_id"
                  [ngClass]="{
                    'focus:border-red-500': form.controls.seller_id.invalid,
                    'focus:ring-red-500': form.controls.seller_id.invalid
                  }"
                  class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  (change)="onChangeSeller($event.target.value)"
                >
                  <option value="">Selecciona un vendedor</option>
                  <option *ngFor="let seller of sellers" [value]="seller.id">
                    {{ seller.legal_name }}
                  </option>
                </select>
                <div *ngFor="let validation of validationMessages.seller_id">
                  <span
                    *ngIf="formService.field(form, validation.type, 'seller_id')"
                    class="mt-2 text-sm text-red-500"
                  >
                    {{ validation.message }}
                  </span>
                </div>
              </div>
            </div>
  
            <div class="w-2/6 fullWidth">
              <div class="container px-2">
                <label
                  class="block uppercase text-slate-600 text-xs font-bold mb-2"
                  htmlFor="client_id"
                >
                  Cliente
                </label>
                <select
                  #clientId
                  [ngClass]="{
                    'focus:border-red-500': form.controls.client_id.invalid,
                    'focus:ring-red-500': form.controls.client_id.invalid
                  }"
                  formControlName="company_client_id"
                  class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  (change)="onChangeClient($event.target.value)"
                >
                  <option value="">Selecciona un cliente</option>
                  <option *ngFor="let client of clients" [value]="client.id">
                    {{ client.legal_name }}
                  </option>
                </select>
                <div *ngFor="let validation of validationMessages.client_id">
                  <span
                    *ngIf="formService.field(form, validation.type, 'client_id')"
                    class="mt-2 text-sm text-red-500"
                  >
                    {{ validation.message }}
                  </span>
                </div>
              </div>
            </div>
  
            <div class="w-2/6 fullWidth">
              <div class="container px-2">
                <label
                  class="block uppercase text-slate-600 text-xs font-bold mb-2"
                  htmlFor="client_reference"
                >
                  Referencia del cliente
                </label>
                <input
                  type="text"
                  name="client_reference"
                  formControlName="client_reference"
                  [ngClass]="{
                    'focus:border-red-500':
                      form.controls.client_reference.invalid,
                    'focus:ring-red-500': form.controls.client_reference.invalid
                  }"
                  class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                />
                <div
                  *ngFor="let validation of validationMessages.client_reference"
                >
                  <span
                    *ngIf="
                      formService.field(form, validation.type, 'client_reference')
                    "
                    class="mt-2 text-sm text-red-500"
                  >
                    {{ validation.message }}
                  </span>
                </div>
              </div>
            </div>
          </div>
  
          <div class="flex flex-row pt-3 column">
            <div class="w-2/6 fullWidth" [ngClass]="{ hidden: currentUserIsAVendor }">
              <div class="container px-2">
                <label
                  class="block uppercase text-slate-600 text-xs font-bold mb-2"
                  htmlFor="vendor_id"
                >
                  Vendedor
                </label>
                <select
                  #vendorsId
                  [ngClass]="{
                    'focus:border-red-500': form.controls.vendor_id.invalid,
                    'focus:ring-red-500': form.controls.vendor_id.invalid
                  }"
                  formControlName="vendor_id"
                  class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                >
                  <option value="">Selecciona un vendedor</option>
                  <option
                    *ngFor="let vendor of vendorsClient"
                    [value]="vendor.id"
                  >
                    {{ vendor.name }} {{ vendor.lastname }}
                  </option>
                </select>
                <div *ngFor="let validation of validationMessages.vendor_id">
                  <span
                    *ngIf="formService.field(form, validation.type, 'vendor_id')"
                    class="mt-2 text-sm text-red-500"
                  >
                    {{ validation.message }}
                  </span>
                </div>
              </div>
            </div>
  
            <div class="w-2/6 fullWidth">
              <div class="container">
                <label
                  class="block uppercase text-slate-600 text-xs font-bold mb-2"
                  htmlFor="product_id"
                >
                  Producto
                </label>
                <select
                  #productId
                  formControlName="product_id"
                  [ngClass]="{
                    'focus:border-red-500': form.controls.product_id.invalid,
                    'focus:ring-red-500': form.controls.product_id.invalid
                  }"
                  class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  (change)="onSelectProduct($event.target.value)"
                >
                  <option value="">Selecciona un producto</option>
                  <option *ngFor="let product of products" [value]="product.id">
                    {{ product.name }}
                  </option>
                </select>
                <div *ngFor="let validation of validationMessages.product_id">
                  <span
                    *ngIf="formService.field(form, validation.type, 'product_id')"
                    class="mt-2 text-sm text-red-500"
                  >
                    {{ validation.message }}
                  </span>
                </div>
              </div>
            </div>
  
            <div class="w-48 fullWidth">
              <div class="container px-2">
                <label
                  class="block uppercase text-slate-600 text-xs font-bold mb-2"
                  htmlFor="transport_plate"
                >
                  Unidad/Placa
                </label>
                <input
                  type="text"
                  name="transport_plate"
                  formControlName="transport_plate"
                  [ngClass]="{
                    'focus:border-red-500': form.controls.transport_plate.invalid,
                    'focus:ring-red-500': form.controls.transport_plate.invalid
                  }"
                  class="border-1 px-3 py-2.5 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                />
                <div
                  *ngFor="let validation of validationMessages.transport_plate"
                >
                  <span
                    *ngIf="
                      formService.field(form, validation.type, 'transport_plate')
                    "
                    class="mt-2 text-sm text-red-500"
                  >
                    {{ validation.message }}
                  </span>
                </div>
              </div>
            </div>
  
            <div class="w-48 fullWidth">
              <div class="container px-2 fullWidth">
                <label
                  class="block uppercase text-slate-600 text-xs font-bold mb-2"
                  htmlFor="transport_plate"
                >
                  Tipo de venta
                </label>
                <select
                  #typeCode
                  formControlName="type_code"
                  [ngClass]="{
                    'focus:border-red-500': form.controls.type_code.invalid,
                    'focus:ring-red-500': form.controls.type_code.invalid
                  }"
                  class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  (change)="onChangeTypeCode($event.target.value)"
                >
                  <option value="NOR">Normal</option>
                  <option value="DIR">Directa</option>
                </select>
                <div *ngFor="let validation of validationMessages.type_code">
                  <span
                    *ngIf="formService.field(form, validation.type, 'type_code')"
                    class="mt-2 text-sm text-red-500"
                  >
                    {{ validation.message }}
                  </span>
                </div>
              </div>
            </div>
          </div>
  
          <div class="flex flex-row pt-3 column">
            <div class="w-48 fullWidth" [ngClass]="{ hidden: !edit }">
              <div class="container px-2">
                <label
                  class="block uppercase text-slate-600 text-xs font-bold mb-2"
                  htmlFor="order_status_id"
                >
                  Estatus
                </label>
                <select
                  #orderStatus
                  formControlName="order_status_id"
                  [ngClass]="{
                    'focus:border-red-500': form.controls.order_status_id.invalid,
                    'focus:ring-red-500': form.controls.order_status_id.invalid
                  }"
                  class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                >
                  <option value="">Selecciona un estatus</option>
                  <option *ngFor="let status of statuses" [value]="status.id">
                    {{ status.name }}
                  </option>
                </select>
                <div
                  *ngFor="let validation of validationMessages.order_status_id"
                >
                  <span
                    *ngIf="
                      formService.field(form, validation.type, 'order_status_id')
                    "
                    class="mt-2 text-sm text-red-500"
                  >
                    {{ validation.message }}
                  </span>
                </div>
              </div>
            </div>
  
            <div>
              <div class="container px-2">
                <label
                  class="block uppercase text-slate-600 text-xs font-bold mb-2"
                  htmlFor="check_own_transportist"
                >
                  Transporte cliente
                </label>
  
                <input
                  type="checkbox"
                  name="check_own_transportist"
                  formControlName="check_own_transportist"
                  [ngClass]="{
                    'focus:border-red-500':
                      form.controls.check_own_transportist.invalid,
                    'focus:ring-red-500':
                      form.controls.check_own_transportist.invalid
                  }"
                  class="border-1 px-3 py-3 mt-1 placeholder-slate-300 text-slate-600 bg-white rounded-full border-gray-300 text-sm focus:outline-none focus:ring ease-linear transition-all duration-150"
                  (change)="onChangeCheckOwnTransportist($event.target.checked)"
                />
                <div
                  *ngFor="
                    let validation of validationMessages.check_own_transportist
                  "
                >
                  <span
                    *ngIf="
                      formService.field(
                        form,
                        validation.type,
                        'check_own_transportist'
                      )
                    "
                    class="mt-2 text-sm text-red-500"
                  >
                    {{ validation.message }}
                  </span>
                </div>
              </div>
            </div>
            <div class="w-1/3 fullWidth" *ngIf="form.controls.check_own_transportist.value">
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="transportist_id"
              >
                Transportista
              </label>
  
              <input
                type="text"
                name="own_transportist_name"
                formControlName="own_transportist_name"
                [ngClass]="{
                  'focus:border-red-500':
                    form.controls.own_transportist_name.invalid,
                  'focus:ring-red-500':
                    form.controls.own_transportist_name.invalid
                }"
                class="border-1 px-3 py-2.5 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
  
              <div
                *ngFor="
                  let validation of validationMessages.own_transportist_name
                "
              >
                <span
                  *ngIf="
                    formService.field(
                      form,
                      validation.type,
                      'own_transportist_name'
                    )
                  "
                  class="mt-2 text-sm text-red-500"
                >
                  {{ validation.message }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

      <div
        class="flex items-center justify-end absolute bottom-0 right-0 pb-3 pr-3"
      >
        <button
          class="bg-slate-500 text-white active:bg-slate-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          (click)="closeModal()"
        >
          Cancelar
        </button>

        <button
          class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          (click)="goToDeliveryData()"
        >
          Siguiente
        </button>
      </div>
    </div>
    <div
      class="hidden py-4"
      id="delivery-data"
      role="tabpanel"
      aria-labelledby="delivery-data-tab"
    >
      <form class="bg-white" [formGroup]="formDelivery">
        <div class="flex flex-row column">
          <div class="w-3/6 px-2 fullWidth">
            <label
              class="py-2 block uppercase text-slate-600 text-xs font-bold mb-2"
              htmlFor="address_id"
            >
              Dirección
            </label>

            <select
              #addressesId
              (change)="onSelectAddress($event.target.value)"
              class="border-1 px-3 py-2 pl-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            >
              <option>Selecciona una dirección</option>
              <option
                *ngFor="let address of clientAddresses"
                [value]="address.id"
              >
                {{ address.street }} #{{ address.ext_number }},
                {{ address.locality.name }},
                {{ address.locality.state.name }}
              </option>
            </select>
          </div>

          <div class="py-11">
            <button
              class="text-sky-600 cursor-pointer hover:text-sky-700 text-2xl ease-linear transition-all duration-150"
              (click)="openNewDialogAddress()"
            >
              <i class="fas fa-plus-circle"></i>
            </button>
          </div>

          <div
            class="w-2/6 px-3 fullWidth"
            [ngClass]="{
              hidden: form.controls.check_own_transportist.value
            }"
          >
            <label
              class="py-2 block uppercase text-slate-600 text-xs font-bold mb-2"
              htmlFor="transportist_id"
            >
              Transportista
            </label>
            <select
              #transportistId
              [ngClass]="{
                'focus:border-red-500':
                  formDelivery.controls.transportist_id.invalid,
                'focus:ring-red-500':
                  formDelivery.controls.transportist_id.invalid
              }"
              formControlName="company_transportist_id"
              class="border-1 py-2.5 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              (change)="onChangeTransportist($event.target.value)"
            >
              <option value="">Selecciona un transportista</option>
              <option
                *ngFor="let transportist of filteredTransportists"
                [value]="transportist.id"
              >
                {{ transportist.legal_name }}
              </option>
            </select>
            <div *ngFor="let validation of validationMessages.transportist_id">
              <span
                *ngIf="
                  formService.field(form, validation.type, 'transportist_id')
                "
                class="mt-2 text-sm text-red-500"
              >
                {{ validation.message }}
              </span>
            </div>
          </div>
        </div>

        <div class="flex flex-row w-full column">
          <div class="grid w-full">
            <div class="block w-full overflow-x-auto rounded-lg">
              <ngx-datatable
                #tableAdresses
                class="material"
                [columnMode]="ColumnMode?.force"
                [headerHeight]="50"
                rowHeight="auto"
                [limit]="5"
                [scrollbarH]="true"
                [rows]="shippings"
              >
                <ngx-datatable-column name="Calle">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.address.street }}
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Numero ext.">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.address.ext_number }}
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Colonia">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.address.locality.name }}
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Municipio">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.address.locality.municipality.name }}
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Estado">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.address.locality.state.name }}
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="C.P.">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.address.zip_code }}
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Tipo">
                  <ng-template
                    let-row="row"
                    let-rowIndex="rowIndex"
                    ngx-datatable-cell-template
                  >
                    <span
                      class="text-xs text-center text-white bg-sky-600 font-semibold inline-block py-1 px-2 rounded-full uppercase last:mr-0 mr-1"
                    >
                      {{ rowIndex === 0 ? "Entrega final" : "Reparto" }}
                    </span>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Opciones">
                  <ng-template
                    #buttonsTemplate
                    let-row="row"
                    let-value="value"
                    ngx-datatable-cell-template
                  >
                    <div class="flex">
                      <button
                        class="bg-red-500 text-white mx-6 px-2 py-1 rounded-full text-center"
                        (click)="deleteShipping(row.address.id)"
                      >
                        <i class="fas fa-trash-alt"> </i>
                      </button>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
              </ngx-datatable>
            </div>
          </div>
        </div>
      </form>

      <div
        class="flex items-center justify-end absolute bottom-0 right-0 pb-3 pr-3"
      >
        <button
          class="bg-slate-500 text-white active:bg-slate-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          (click)="goToGeneralData()"
        >
          Atras
        </button>

        <button
          class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          (click)="goToStatsData()"
        >
          Siguiente
        </button>
      </div>
    </div>
    <div
      class="hidden"
      id="stats-data"
      role="tabpanel"
      aria-labelledby="stats-data-tab">

      <div class="overflow-y-negative">
        <!-- Row Datos Estadisticos -->
        <form class="bg-white" [formGroup]="formStats">
          <div class="column">
            <div class="grid grid-cols-2 gap-2 p-12 fl column">
              <div class="flex flex-row justify-end column">
                <div class="px-2">
                  <label
                    class="py-2 block uppercase text-slate-600 text-xs font-bold mb-2"
                    htmlFor="transport_type_id">
                    Transporte
                  </label>
                </div>
                <div class="w-72 px-2 smallerViewfather">
                  <select
                    #transportTypeId
                    formControlName="transport_type_id"
                    [ngClass]="{
                      'focus:border-red-500':
                        formStats.controls.transport_type_id.invalid,
                      'focus:ring-red-500':
                        formStats.controls.transport_type_id.invalid
                    }"
                    class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    (change)="onChangeTransportType($event.target.value)">
                    <option value="">Selecciona un tipo</option>
                    <option *ngFor="let type of transportTypes" [value]="type.id">
                      {{ type.name }}
                    </option>
                  </select>
                  <div
                    *ngFor="let validation of validationMessages.transport_type_id">
                    <span
                      *ngIf="
                        formService.field(
                          formStats,
                          validation.type,
                          'transport_type_id')"
                      class="mt-2 text-sm text-red-500">
                      {{ validation.message }}
                    </span>
                  </div>
                </div>
              </div>
    
              <div class="flex flex-row column">
                <div class="px-2">
                  <label
                    class="py-2 block uppercase text-slate-600 text-xs font-bold mb-2 w-28"
                    htmlFor="loading_type_id">
                    Tipo de carga
                  </label>
                </div>
    
                <div class="w-72 px-2 smallerViewfather">
                  <select
                    #loadingTypeId
                    formControlName="loading_type_id"
                    [ngClass]="{
                      'focus:border-red-500':
                        formStats.controls.loading_type_id.invalid,
                      'focus:ring-red-500':
                        formStats.controls.loading_type_id.invalid
                    }"
                    class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  >
                    <option value="">Selecciona un tipo</option>
                    <option *ngFor="let type of loadingTypes" [value]="type.id">
                      {{ type.name }}
                    </option>
                  </select>
    
                  <div
                    *ngFor="let validation of validationMessages.loading_type_id"
                  >
                    <span
                      *ngIf="
                        formService.field(
                          formStats,
                          validation.type,
                          'loading_type_id'
                        )
                      "
                      class="mt-2 text-sm text-red-500"
                    >
                      {{ validation.message }}
                    </span>
                  </div>
                </div>
              </div>
    
              <div class="flex flex-row justify-end mt-6 column">
                <div class="px-2">
                  <label
                    class="py-2 block uppercase text-slate-600 text-xs font-bold mb-2"
                    htmlFor="pump_measure_id">
                    Medida de bomba
                  </label>
                </div>
    
                <div class="w-72 px-2 smallerViewfather">
                  <select
                    #pumpMeasureId
                    formControlName="pump_measure_id"
                    [ngClass]="{
                      'focus:border-red-500':
                        formStats.controls.pump_measure_id.invalid,
                      'focus:ring-red-500':
                        formStats.controls.pump_measure_id.invalid
                    }"
                    class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  >
                    <option value="">Selecciona una medida</option>
                    <option
                      *ngFor="let pumpMeasure of pumpMeasures"
                      [value]="pumpMeasure.id"
                    >
                      {{ pumpMeasure.name }}
                    </option>
                  </select>
    
                  <div
                    *ngFor="let validation of validationMessages.pump_measure_id"
                  >
                    <span
                      *ngIf="
                        formService.field(
                          formStats,
                          validation.type,
                          'pump_measure_id'
                        )
                      "
                      class="mt-2 text-sm text-red-500"
                    >
                      {{ validation.message }}
                    </span>
                  </div>
                </div>
              </div>
    
              <div class="flex flex-row mt-6 column">
                <div class="px-2">
                  <label
                    class="block uppercase text-slate-600 text-xs font-bold mb-2 w-28"
                    htmlFor="additional_requirement_id"
                  >
                    <span class="break-words">Requerimientos adicionales </span>
                  </label>
                </div>
    
                <div class="w-72 px-2 smallerViewfather">
                  <select
                    #additionalRequirementsId
                    formControlName="additional_requirement_id"
                    [ngClass]="{
                      'focus:border-red-500':
                        formStats.controls.additional_requirement_id.invalid,
                      'focus:ring-red-500':
                        formStats.controls.additional_requirement_id.invalid
                    }"
                    class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  >
                    <option value="">Selecciona un requerimiento</option>
                    <option
                      *ngFor="let requirement of additionalRequirements"
                      [value]="requirement.id"
                    >
                      {{ requirement.name }}
                    </option>
                  </select>
    
                  <div
                    *ngFor="
                      let validation of validationMessages.additional_requirement_id
                    "
                  >
                    <span
                      *ngIf="
                        formService.field(
                          formStats,
                          validation.type,
                          'additional_requirement_id'
                        )
                      "
                      class="mt-2 text-sm text-red-500"
                    >
                      {{ validation.message }}
                    </span>
                  </div>
                </div>
              </div>
    
              <div class="flex flex-row justify-end mt-6 column">
                <div class="px-2 pt-3">
                  <label
                    class="py-2 block uppercase text-slate-600 text-xs font-bold mb-2"
                    htmlFor="payment_method_id">
                    Pago
                  </label>
                </div>
    
                <div class="w-72 px-2 pt-3 smallerViewfather">
                  <select
                    #paymentTypeId
                    formControlName="payment_method_id"
                    [ngClass]="{
                      'focus:border-red-500':
                        formStats.controls.payment_method_id.invalid,
                      'focus:ring-red-500':
                        formStats.controls.payment_method_id.invalid
                    }"
                    class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                    <option value="">Selecciona un metodo de pago</option>
                    <option *ngFor="let type of paymentMethods" [value]="type.id">
                      {{ type.name }}
                    </option>
                  </select>
    
                  <div
                    *ngFor="let validation of validationMessages.payment_method_id"
                  >
                    <span
                      *ngIf="
                        formService.field(
                          formStats,
                          validation.type,
                          'payment_method_id'
                        )
                      "
                      class="mt-2 text-sm text-red-500"
                    >
                      {{ validation.message }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div
        class="flex items-center justify-end absolute bottom-0 right-0 pb-3 pr-3">
        <button
          class="bg-slate-500 text-white active:bg-slate-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          (click)="goToDeliveryData()">
          Atras
        </button>

        <button
          class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          (click)="goToSalesData()">
          Siguiente
        </button>
      </div>
    </div>
    <div
      class="hidden py-1 px-4"
      id="sale"
      role="tabpanel"
      aria-labelledby="sale-tab"
    >
   
    <form class="bg-white" [formGroup]="formSale">
      
      <!-- First Row Modified for Responsive Design -->
      <div class="grid grid-cols-2 gap-2">
        <div class="flex flex-row column">
          
          <div class="w-32">
            <div class="container">
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="authorized_price">
                Precio del dia
              </label>
              <input
                type="text"
                prefix="$ "
                mask="separator.2"
                thousandSeparator=","
                [leadZero]="true"
                formControlName="authorized_price"
                class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>

          <div class="w-32 lg:ml-6">
            <div class="container">
              <label
                class="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="utility">
                Utilidad
              </label>
              <input
                type="text"
                formControlName="utility"
                prefix="$ "
                mask="separator.2"
                [leadZero]="true"
                thousandSeparator=","
                class="border-1 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>

        </div>
      </div>
      
      <!-- Second Row Tables -->
      <div class="scroll">
        <div class="grid grid-cols-2 gap-2">
          <div class="flex fl">
            <div class="column">
              <div class="flex flex-row w-full smallViewfather">
                <div class="overflow-x-auto w-full">
                  <div class="inline-block w-full py-2">
                    <div class="overflow-hidden rounded w-full">
                      <table
                        class="w-full border text-center text-sm font-light"
                        aria-describedby="totals">
                        <thead>
                          <tr>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody class="w-full">
                          <tr class="border-b">
                            <td
                              class="px-2 text-start whitespace-nowrap border-r font-medium">
                              Cantidad
                            </td>
                            <td class="whitespace-nowrap border-r">
                              <input
                                type="text"
                                mask="separator.2"
                                thousandSeparator=","
                                [leadZero]="true"
                                formControlName="sold_liters"
                                class="w-full"
                                [ngClass]="{
                                  'border-red-500':
                                    formSale.controls.sold_liters.invalid,
                                  'focus:border-red-500':
                                    formSale.controls.sold_liters.invalid,
                                  'focus:ring-red-500':
                                    formSale.controls.sold_liters.invalid,
                                  'border-0': formSale.controls.sold_liters.valid,
                                  'focus:border-0':
                                    formSale.controls.sold_liters.valid
                                }"
                                (input)="onChangePrice()"
                              />
                            </td>
                          </tr>
    
                          <tr class="border-b">
                            <td
                              class="px-2 text-start whitespace-nowrap border-r font-medium"
                            >
                              Precio Acordado(Lt)
                            </td>
                            <td class="whitespace-nowrap border-r">
                              <input
                                type="text"
                                prefix="$ "
                                mask="separator.6"
                                thousandSeparator=","
                                formControlName="agreed_price"
                                class="w-full"
                                [ngClass]="{
                                  'border-red-500':
                                    formSale.controls.agreed_price.invalid,
                                  'focus:border-red-500':
                                    formSale.controls.agreed_price.invalid,
                                  'focus:ring-red-500':
                                    formSale.controls.agreed_price.invalid,
                                  'border-0':
                                    formSale.controls.agreed_price.valid,
                                  'focus:border-0':
                                    formSale.controls.agreed_price.valid
                                }"
                                (focus)="saveAgreedPriceValue()"
                                (change)="onLeadingZero($event)"
                              />
                            </td>
                          </tr>
    
                          <tr class="border-b">
                            <td
                              class="px-2 text-start whitespace-nowrap border-r font-medium"
                            >
                              Precio Flete(Lt)
                            </td>
                            <td class="whitespace-nowrap border-r">
                              <input
                                type="text"
                                prefix="$ "
                                mask="separator.6"
                                thousandSeparator=","
                                formControlName="shipping_price"
                                class="w-full"
                                [ngClass]="{
                                  'border-red-500':
                                    formSale.controls.shipping_price.invalid,
                                  'focus:border-red-500':
                                    formSale.controls.shipping_price.invalid,
                                  'focus:ring-red-500':
                                    formSale.controls.shipping_price.invalid,
                                  'border-0':
                                    formSale.controls.shipping_price.valid ||
                                    formSale.controls.shipping_price.disable,
                                  'focus:border-0':
                                    formSale.controls.shipping_price.valid ||
                                    formSale.controls.shipping_price.disable
                                }"
                                (change)="onLeadingZero($event)"
                              />
                            </td>
                          </tr>
    
                          <tr class="border-b">
                            <td
                              class="px-2 text-start whitespace-nowrap border-r font-medium"
                            >
                              Repartos
                            </td>
                            <td class="whitespace-nowrap border-r">
                              <input
                                type="number"
                                [value]="shippings.length - 1"
                                class="w-full border-0 focus:border-0"
                              />
                            </td>
                          </tr>
    
                          <tr class="border-b">
                            <td
                              class="px-2 text-start whitespace-nowrap border-r font-medium"
                            >
                              Precio por reparto
                            </td>
                            <td class="whitespace-nowrap border-r">
                              <input
                                type="text"
                                prefix="$ "
                                mask="separator.6"
                                thousandSeparator=","
                                formControlName="distribution_cost"
                                class="w-full"
                                [ngClass]="{
                                  'border-red-500':
                                    formSale.controls.distribution_cost.invalid,
                                  'focus:border-red-500':
                                    formSale.controls.distribution_cost.invalid,
                                  'focus:ring-red-500':
                                    formSale.controls.distribution_cost.invalid,
                                  'border-0':
                                    formSale.controls.distribution_cost.valid ||
                                    formSale.controls.distribution_cost.disable,
                                  'focus:border-0':
                                    formSale.controls.distribution_cost.valid ||
                                    formSale.controls.distribution_cost.disable
                                }"
                                (change)="onLeadingZero($event)"
                              />
                            </td>
                          </tr>
    
                          <tr class="border-b">
                            <td
                              class="px-2 text-start whitespace-nowrap border-r font-medium"
                            >
                              Comisión Interna
                            </td>
                            <td class="whitespace-nowrap border-r">
                              <input
                                type="text"
                                prefix="$ "
                                mask="separator.6"
                                thousandSeparator=","
                                formControlName="internal_commision"
                                class="w-full border-none"
                                [ngClass]="{
                                  'border-red-500':
                                    formSale.controls.internal_commision.invalid,
                                  'focus:border-red-500':
                                    formSale.controls.internal_commision.invalid,
                                  'focus:ring-red-500':
                                    formSale.controls.internal_commision.invalid,
                                  'border-0':
                                    formSale.controls.internal_commision.valid,
                                  'focus:border-0':
                                    formSale.controls.internal_commision.valid
                                }"
                                (change)="onLeadingZero($event)"
                              />
                            </td>
                          </tr>
    
                          <tr class="border-b" *ngIf="hasExternalComission">
                            <td
                              class="px-2 text-start whitespace-nowrap border-r font-medium">
                              Comisión Externa
                            </td>
                            <td class="whitespace-nowrap border-r">
                              <input
                                type="text"
                                prefix="$ "
                                mask="separator.6"
                                thousandSeparator=","
                                formControlName="external_commision"
                                class="w-full border-0 focus:border-0"
                                (change)="onLeadingZero($event)"/>
                            </td>
                          </tr>
    
                          <tr class="border-b">
                            <td
                              class="px-2 text-start whitespace-nowrap border-r font-medium"
                            >
                              Descuento por cliente
                            </td>
                            <td class="whitespace-nowrap border-r">
                              <input
                                type="text"
                                prefix="$ "
                                mask="separator.6"
                                thousandSeparator=","
                                formControlName="discount"
                                class="w-full"
                                [ngClass]="{
                                  'border-red-500':
                                    formSale.controls.discount.invalid,
                                  'focus:border-red-500':
                                    formSale.controls.discount.invalid,
                                  'focus:ring-red-500':
                                    formSale.controls.discount.invalid,
                                  'border-0': formSale.controls.discount.valid,
                                  'focus:border-0':
                                    formSale.controls.discount.valid
                                }"
                                (change)="onLeadingZero($event)"
                              />
                            </td>
                          </tr>
    
                          <tr class="border-b">
                            <td
                              class="px-2 text-start whitespace-nowrap border-r font-medium"
                            >
                              Precio sin descuento
                            </td>
                            <td class="whitespace-nowrap border-r">
                              <input
                                type="text"
                                prefix="$ "
                                mask="separator.2"
                                [leadZero]="true"
                                thousandSeparator=","
                                class="w-full border-0 focus:border-0"
                                formControlName="price_without_discount"
                              />
                            </td>
                          </tr>
    
                          <tr class="border-b">
                            <td
                              class="px-2 text-start whitespace-nowrap border-r font-medium"
                            >
                              Precio Final
                            </td>
                            <td class="whitespace-nowrap border-r">
                              <input
                                type="text"
                                prefix="$ "
                                mask="separator.2"
                                [leadZero]="true"
                                thousandSeparator=","
                                class="w-full border-0 focus:border-0"
                                formControlName="total_price_liter"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="flex flex-row w-full smallViewfather">
                <div class="overflow-x-auto sm:-mx-6 lg:-mx-8 w-full">
                  <div class="inline-block py-2 sm:px-6 lg:px-8 w-full">
                    <div class="overflow-hidden rounded w-full">
                      <table
                        class="w-full border text-center text-sm font-light"
                        aria-describedby="totals"
                      >
                        <thead>
                          <th></th>
                        </thead>
                        <tbody class="w-full">
                          <tr class="border-b">
                            <td
                              class="px-2 text-start whitespace-nowrap border-r font-medium"
                            >
                              IMPORTE
                            </td>
                            <td class="whitespace-nowrap border-r">
                              <input
                                type="text"
                                prefix="$ "
                                mask="separator.2"
                                [leadZero]="true"
                                thousandSeparator=","
                                formControlName="amount"
                                class="w-full border-0 focus:border-0"
                              />
                            </td>
                          </tr>
    
                          <tr class="border-b">
                            <td
                              class="px-2 text-start whitespace-nowrap border-r font-medium"
                            >
                              DESCUENTO TOTAL
                            </td>
                            <td class="whitespace-nowrap border-r">
                              <input
                                type="text"
                                prefix="$ "
                                mask="separator.2"
                                [leadZero]="true"
                                thousandSeparator=","
                                formControlName="total_discount"
                                class="w-full border-0 focus:border-0"
                              />
                            </td>
                          </tr>
    
                          <tr class="border-b">
                            <td
                              class="px-2 text-start whitespace-nowrap border-r font-medium"
                            >
                              A FACTURAR
                            </td>
                            <td class="whitespace-nowrap border-r">
                              <input
                                type="text"
                                prefix="$ "
                                mask="separator.2"
                                [leadZero]="true"
                                thousandSeparator=","
                                formControlName="total"
                                class="w-full border-0 focus:border-0"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
    
              <div class="flex flex-row w-full ">
                <div class="overflow-x-auto sm:-mx-6 lg:-mx-8 w-full">
                  <div class="inline-block py-2 sm:px-6 lg:px-8 w-full">
                    <div class="overflow-hidden rounded w-full">
                      <div class="mb-0.5 block py-4 min-h-6 pl-2">
                        <input
                          class="relative float-left ml-1 mr-1 mt-1 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-1 after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s]"
                          type="radio"
                          name="flexRadioTax"
                          checked
                          id="radioIva"
                          (change)="changeTax($event, 'iva')"
                          [checked]="!checkedIeps"/>
                        <label
                          class="mt-px inline-block pl-2 hover:cursor-pointer"
                          for="radioIva">
                          IVA
                        </label>
                      </div>
                      <div class="mb-1 block min-h-6 pl-2">
                        <input
                          class="relative float-left ml-1 mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s]"
                          type="radio"
                          name="flexRadioTax"
                          id="radioIeps"
                          (change)="changeTax($event, 'ieps')"
                          [checked]="checkedIeps"/>
                        <label
                          class="mt-px inline-block pl-2 hover:cursor-pointer"
                          for="radioIeps">
                          IEPS
                        </label>
    
                        <p
                          *ngIf="showIepsMsg"
                          class="px-3 inline-block text-start whitespace-nowrap text-red-500 font-medium">
                          El producto seleccionado no tiene IEPS
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
    
              <div class="flex flex-row w-full smallViewfather">
                <div class="overflow-x-auto sm:-mx-6 lg:-mx-8 w-full">
                  <div class="inline-block py-2 sm:px-6 lg:px-8 w-full">
                    <div class="overflow-hidden rounded w-full">
                      <table
                        class="w-full border text-center text-sm font-light"
                        aria-describedby="totals">
                        <thead>
                          <th></th>
                        </thead>
                        <tbody class="w-full">
                          <tr class="border-b">
                            <td
                              class="px-2 text-start whitespace-nowrap border-r font-medium">
                              SUBTOTAL
                            </td>
                            <td class="whitespace-nowrap border-r">
                              <input
                                type="text"
                                prefix="$ "
                                mask="separator.2"
                                [leadZero]="true"
                                thousandSeparator=","
                                formControlName="subtotal"
                                class="w-full border-0 focus:border-0"
                              />
                            </td>
                          </tr>
    
                          <tr class="border-b">
                            <td
                              class="px-2 text-start whitespace-nowrap border-r font-medium">
                              IVA
                            </td>
                            <td class="whitespace-nowrap border-r">
                              <input
                                type="text"
                                prefix="$ "
                                mask="separator.2"
                                [leadZero]="true"
                                thousandSeparator=","
                                formControlName="tax"
                                class="w-full border-0 focus:border-0"/>
                            </td>
                          </tr>
    
                          <tr class="border-b">
                            <td
                              class="px-2 text-start whitespace-nowrap border-r font-medium">
                              IEPS
                            </td>
                            <td class="whitespace-nowrap border-r">
                              <input
                                type="text"
                                prefix="$ "
                                mask="separator.2"
                                [leadZero]="true"
                                thousandSeparator=","
                                formControlName="ieps"
                                class="w-full border-0 focus:border-0"
                              />
                            </td>
                          </tr>
    
                          <tr class="border-b">
                            <td
                              class="px-2 text-start whitespace-nowrap border-r font-medium">
                              TOTAL
                            </td>
                            <td class="whitespace-nowrap border-r">
                              <input
                                type="text"
                                prefix="$ "
                                mask="separator.2"
                                [leadZero]="true"
                                thousandSeparator=","
                                formControlName="total"
                                class="w-full border-0 focus:border-0"/>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div
      class="flex items-center justify-end absolute bottom-0 right-0 pb-3 pr-3">
      <button
        class="bg-slate-500 text-white active:bg-slate-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        (click)="goToStatsData()">
        Atras
      </button>

      <button
        class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        (click)="addSale()">
        {{ edit ? "Editar" : "Agregar" }}
      </button>
    </div>
    </div>
  </div>
</div>
