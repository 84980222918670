<div class="container bg-white mx-auto px-4 h-full py-4">

  <div class="justify-between rounded-t mb-0 px-4 py-3 border-0 pt-6 bg-white">
    <div class="text-center">
      <h3 class="font-semibold text-lg">
        {{dialogTitle}}
      </h3>
    </div>
  </div>

  <form class="mx-4 my-6 bg-white" [formGroup]="form">


    <div class="relative w-full mb-3 flex">
      <div class="container px-2">
        <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="name">
          Nombre
        </label>
        <input type="text" name="name" formControlName="name" [ngClass]="{
                    'border-red-500': form.controls.name.invalid,
                    'focus:border-red-500': form.controls.name.invalid,
                    'focus:ring-red-500': form.controls.name.invalid
                }" class="border-gray-300 px-3 py-3
                placeholder-slate-300 border-2
                text-slate-600 bg-white rounded
                text-sm focus:outline-none focus:ring
                w-full ease-linear transition-all duration-150" />
        <div *ngFor="let validation of validationMessages.name">
          <span *ngIf="formService.field(form, validation.type, 'name')" class="mt-2 text-sm text-red-500">
            {{ validation.message }}
          </span>
        </div>
      </div>
    </div>


    <div class="relative w-full mb-3 flex">
      <div class="container px-2">
        <label class="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="description">
          Descripción
        </label>
        <textarea name="description" formControlName="description" class="border-gray-300 px-3 py-3
              placeholder-slate-300 border-2
              text-slate-600 bg-white rounded
              text-sm focus:outline-none focus:ring
              w-full ease-linear transition-all duration-150">
          </textarea>
      </div>
    </div>
  </form>

  <div class="flex items-center justify-end">
    <button
      class="bg-slate-500 text-white active:bg-slate-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      (click)="closeModal()">
      Cancelar
    </button>
    <button
      class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      [ngClass]="{ 'disabled:opacity-25': form.invalid }" [disabled]="form.invalid" (click)="save()">
      {{ edit ? 'Actualizar' : 'Agregar' }}
    </button>
  </div>
</div>