import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { View } from "app/models/view";
import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";
import { ModalConfirmationComponent } from "../modals/modal-confirmation/modal-confirmation.component";
import { FormValidatorService } from "app/core/service/form-validator.service";
import { Category } from "app/models/category";
import { CatalogServicesService } from "app/apiServices/catalog-services.service";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { NotificationsComponent } from "../alerts/notifications/notifications.component";
import { DataService } from "app/services/data.service";
import { RoleViews } from "app/models/role-view";

@Component({
  selector: "app-vistas",
  templateUrl: "./vistas.component.html",
  styleUrls: ["../../../../src/styles.css", "./vistas.component.css"],
})
export class VistasComponent implements OnInit {
  rows: View[] = [];

  objects: View[];
  columnMapValues = [
    ["id", "id"],
    ["nombre", "name"],
    ["categoria", "category"],
    ["orden", "order"],
    ["icono", "icon"],
    ["ruta", "path"],
  ] as const;
  columnMap: Map<string, string> = new Map(this.columnMapValues);
  loading: boolean = false;

  temp: any[] = [];
  showAlert: boolean = false;
  alertTitle: string;
  form: FormGroup;

  @ViewChild(DatatableComponent) table: DatatableComponent;
  columnMode = ColumnMode;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    public apiService: CatalogServicesService
  ) {
    this.form = this.fb.group({
      rowsPerPage: [10],
    });
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.objects = [];
    this.temp = [];
    this.loading = true;
    this.apiService.readViews().subscribe(
      (r) => {
        this.loading = false;
        this.objects = r.data;
        this.temp = this.objects;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  updateFilter(event: Event) {
    const val: string = (event.target as HTMLInputElement).value.toLowerCase();
    const temp: View[] = this.temp.filter((d: View) => {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.objects = temp;
    this.table.offset = 0;
  }

  openDialog(data: any, action: string) {
    this.alertTitle =
      action == "store"
        ? "Categoria agregada con éxito"
        : "Categoria editada con éxito";

    const dialogRef = this.dialog.open(VistasForm, {
      width: "600px",
      data: {
        action,
        data,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getData();
    });
  }

  openDialogDelete(roleId: number) {
    const dialogRef = this.dialog.open(ModalConfirmationComponent, {
      width: "600px",
      data: {
        title: "¿Estas seguro de desactivar esta Vista?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.apiService.deleteView(roleId).subscribe(
          (result) => {
            this.getData();
          },
          (error) => {}
        );
      }
    });
  }

  onLimitChange(event: Event): void {
    const limit = (event.target as HTMLSelectElement).value;
    this.changePageLimit(limit);
    this.table.limit = this.form.controls.rowsPerPage.value;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor(
          (this.table.rowCount - 1) / this.table.limit
        );
      }
    });
  }

  onSort(event: any): void {
    const sort = event.sorts[0];
    const column = this.columnMap.get(sort.prop);

    if (column) {
      this.objects.sort((a, b) => {
        if (typeof a[column] === "number") {
          if (sort.dir === "asc") return a[column] - b[column];
          else return b[column] - a[column];
        } else if (typeof a[column] === "string") {
          return (
            a[column].localeCompare(b[column]) * (sort.dir === "desc" ? -1 : 1)
          );
        } else if (typeof a[column] === "object") {
          return (
            a[column].name.localeCompare(b[column].name) *
            (sort.dir === "desc" ? -1 : 1)
          );
        }
      });

      this.objects = [...this.objects];
    }
  }

  changePageLimit(limit: any): void {
    this.form.controls.rowsPerPage.setValue(parseInt(limit, 10));
  }

  closeAlert(event: boolean) {
    this.showAlert = event;
  }

  delete(roleId: number) {
    this.openDialogDelete(roleId);
  }
}

@Component({
  selector: "vistas-form",
  templateUrl: "vistasForm.html",
  styleUrls: ["../../../../src/styles.css"],
})
export class VistasForm implements OnInit {
  categories: Category[] = [];

  form: FormGroup;
  dataSource: View;
  edit = false;
  dialogTitle: string;

  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";

  validationMessages = {
    name: [{ type: "required", message: "Ingrese un nombre" }],
    category_id: [
      { type: "required", message: "Debe llevar una clase de category" },
    ],
    active: [
      { type: "required", message: "Necesitamos saber si estara activo" },
    ],
    order: [{ type: "required", message: "Necesitamos un order" }],
    icon: [{ type: "required", message: "Ingrese el texto del icono" }],
    path: [{ type: "required", message: "Ingrese el path de la ruta" }],
    component: [{ type: "required", message: "Necesitamos el componente" }],
  };

  constructor(
    private dialogRef: MatDialogRef<VistasComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dataService: DataService,
    public formService: FormValidatorService,
    public apiService: CatalogServicesService,
    private alert: MatSnackBar,
    private _snack: MatSnackBar
  ) {
    this.data = data;

    if (data.action === "edit") {
      this.dialogTitle = "Editar Categoria";
      this.dataSource = data.data;
      this.edit = true;
    } else {
      this.dialogTitle = "Agregar Categoria";
      this.dataSource = new View({});
    }
    this.form = this.createForm();
    this.getCategories();
  }

  ngOnInit() {}

  getCategories() {
    this.apiService.readCategories().subscribe((r) => {
      r.data.forEach((element) => {
        if (element.active) this.categories.push(element);
      });
    });
  }

  createForm(): FormGroup {
    return this.fb.group({
      id: [this.dataSource.id],
      name: [this.dataSource.name, [Validators.required]],
      category_id: [this.dataSource.category.id, [Validators.required]],
      active: [this.dataSource.active, [Validators.required]],
      order: [this.dataSource.order, [Validators.required]],
      icon: [this.dataSource.icon, [Validators.required]],
      path: [this.dataSource.path, [Validators.required]],
      component: [this.dataSource.component, []],
    });
  }

  save(): void {
    if (this.form.valid) {
      this.dataSource = this.form.value;
      this.storeUpdate(this.edit ? "update" : "store");
    } else {
      this.formService.allFields(this.form);
      alert("Datos incorrectos");
    }
  }

  storeUpdate(method: string): void {
    if (method === "store") {
      this.apiService.createView(this.dataSource).subscribe(
        (res) => {
          this.closeModal();

          if (res.status === "Éxito") this.getRoleViews();
        },
        (error) => {
          this.showAlert(error.error.message);
        }
      );
    } else {
      this.apiService.updateView(this.dataSource).subscribe(
        (res) => {
          this.closeModal();

          if (res.status === "Éxito") this.getRoleViews();
        },
        (error) => {
          this.showAlert(error.error.message);
        }
      );
    }
  }

  showAlert(message: string) {
    const config = {
      duration: 5000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    };
    this.openSnack("snack-error", message);
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  private getRoleViews(): void {
    this.apiService.readRoleViews().subscribe(
      (res) => {
        const currentRoleViews = res.data.find(
          (role: RoleViews) =>
            role.id === this.dataService.userValue.roles[0].id
        );
        const currentCategories = currentRoleViews.views.map(
          (view: View) => view.category
        );
        const sortedCategories =
          this.distinctAndSortCategories(currentCategories);
        this.viewsByCategory(sortedCategories, currentRoleViews.views);
      },
      (error) => {
        // console.log(error);
      }
    );
  }

  private distinctAndSortCategories(categories: Category[]): Category[] {
    const result = [];
    const map = new Map();
    for (const item of categories) {
      if (!map.has(item.id)) {
        map.set(item.id, true);
        result.push({
          id: item.id,
          name: item.name,
          order: item.order,
        });
      }
    }

    return result.sort((a: Category, b: Category) => {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    });
  }

  viewsByCategory(categories: Category[], views: View[]) {
    categories.forEach((category) => {
      category.views = views.filter(
        (view: View) =>
          view.category.id === category.id &&
          !view.path.includes("existence-types")
      );

      category.views = category.views.sort((a: View, b: View) => {
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      });
    });

    this.dataService.setCategoriesValue(categories);
  }
}
