import { Category } from "./category";

export class View {
    id?: number;
    category_id?: number;
    name?: string;
    category?: Category;
    active?: boolean;
    path?: string;
    component?: string;
    order?: number;
    icon?: string;
    route?: string;

    constructor(view: View) {
      {
        this.id = view.id || undefined;
        this.category_id = view.category_id || undefined;
        this.name = view.name || '';
        this.category = view.category || {id: 0, name: '', order: 0};
        this.active = view.active || false;
        this.path = view.path || undefined;
        this.component = view.component || undefined;
        this.order = view.order || 0;
        this.icon = view.icon || '';
        this.route = view.route || '';
      }
    }
  }
