import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ClientsService } from "app/apiServices/clients.service";
import { InventoryService } from "app/apiServices/inventory.service";
import { UserServicesService } from "app/apiServices/user-services.service";
import { FormValidatorService } from "app/core/service/form-validator.service";
import { InUserRes } from "app/interfaces/user.interface";
import { Company } from "app/models/company";
import { Product } from "app/models/product";
import { User } from "app/models/user";

@Component({
  selector: "app-commisions",
  templateUrl: "./commisions.component.html",
  styleUrls: ["./commisions.component.css"],
})
export class CommisionsComponent implements OnInit {
  dataSource: Company;
  form: FormGroup;

  products: Product[];
  vendors: User[] = [];
  clientId: number;

  validationMessages = {
    product_id: [{ type: "required", message: "" }],
    client_id: [{ type: "required", message: "" }],
    cost_per_liter: [{ type: "required", message: "" }],
    supplier_id: [{ type: "required", message: "" }],
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CommisionsComponent>,
    public formService: FormValidatorService,
    public apiService: InventoryService,
    public clientService: ClientsService,
    public userService: UserServicesService,
    public fb: FormBuilder
  ) {
    this.dataSource = this.data.data;
    this.clientId = this.data.clientId;
    this.form = this.createForm();
  }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.apiService.getProducts().subscribe((r) => {
      this.products = r.data;
    });

    this.userService.readUsers().subscribe((res: InUserRes) => {
      if (res.status === "Éxito") {
        const users = res.data;
        this.vendors = users.filter((user) => {
          if (user.roles) {
            return user.roles[0].id === 4;
          }
        });
      }
    });
  }

  createForm() {
    return this.fb.group({
      id: [this.dataSource.id],
      product_id: [null, [Validators.required]],
      user_id: [null, [Validators.required]],
      client_id: [this.clientId, [Validators.required]],
      cost_per_liter: [0, [Validators.required]],
    });
  }

  save() {
    if (this.form.valid) {
      const obj = this.form.value;
      obj.client_id = this.dataSource.clients[0].id;
      console.log(obj);
      this.clientService.createCommision(obj).subscribe((r) => {
        this.closeModal();
      });
    }
  }

  closeModal() {
    this.dialogRef.close();
  }
}
